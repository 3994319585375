export const webConfig = {

    /* Core Doc Url */
    BECoreDoc : "https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B2D8FEBF0-4EAD-43A0-A5F1-93A68B2D831C%7D&file=BE%20-%20E2E%20Lifecycle%20-Proof%20reading_UpdatedFeb2024.docx&action=default&mobileredirect=true",
    CookieCoreDoc: "https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B0A44EA52-B76C-48D2-9A72-8A0E304288A7%7D&file=E2E%20-%20Core%20doc%20-%20Cookies.docx&action=default&mobileredirect=true",
    IVACoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B5AB033AF-1B8E-471A-BF6A-BA004B6F247C%7D&file=E2E%20-%20Core%20Doc%20-%20IVA_updatedFeb2024.docx&action=default&mobileredirect=true",
    PassportCoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B755D3EDC-18A9-4D88-BCD7-5AF40F785B57%7D&file=E2E%20-%20Core%20Doc%20-%20Lilly%20Passport.docx&action=default&mobileredirect=true",
    PMCoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B121F7A89-1E5E-49EF-B7AC-1D381EEFE12E%7D&file=E2E%20-%20Core%20Doc%20-%20Physical%20materials.docx&action=default&mobileredirect=true",
    PrivacyCoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B35B6F2A4-6B91-4AE4-B10B-E7E8C0EDC99E%7D&file=E2E%20-%20Core%20doc%20-%20Privacy%20Notice%20Policy.docx&action=default&mobileredirect=true",
    VAECoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B31602689-75ED-48CA-993F-D46FA70F1E1C%7D&file=VAE%20-%20E2E%20Lifecycle%20-%20V3%2019Dec%20for%20review_UpdatedFeb2024.docx&action=default&mobileredirect=true",
    WebsiteCoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B1ADEC31A-AAE4-429F-AE25-23E433FCA9F5%7D&file=E2E%20-%20Core%20Doc-%20Websites_UpdatedFeb2024.docx&action=default&mobileredirect=true",
    SymposiumMeetingCoreDoc :"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7BFFBD0B07-BD0D-41F7-B38C-2FA620C22A0E%7D&file=E2E-%20Core%20Doc%20-%20Independent%20meetings%20-%20Symposium%20Face%20to%20face%20or%20Virtual.docx&action=default&mobileredirect=true",
    StandBoothDetailingCoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7BA188407A-FD3D-46C7-9335-4F1E7B0BBC7B%7D&file=E2E-core%20Doc%20-%20Independent%20meetings%20-Stand%20Booth.docx&action=default&mobileredirect=true",
    HeadOfficeMeetingCoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B3FDBFC4C-717C-40A2-87C1-D1753E4754E2%7D&file=E2E%20-%20Core%20Doc%20-%20Head%20Office%20Face%20To%20Face%20Meetings.docx&action=default&mobileredirect=true",
    LocalWebinarCoreDoc :"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B319E3F36-6C0D-4D33-BC66-F3D77C17E52E%7D&file=E2E%20-%20Core%20Doc%20-%20Meetings%20-%20Local%20Webinar%20(eP2P).docx&action=default&mobileredirect=true",
    LocalOn24CoreDoc :"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B934D5F68-86D5-442B-9C88-A01477D7AB65%7D&file=E2E%20-%20Core%20Doc%20-%20Meetings%20-%20Local%20Webinar%20(eP2P)%20-%20ON24%20Process.docx&action=default&mobileredirect=true",
    IbuWebinarCoreDoc : "https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B03A393E5-E03A-4040-9AFA-8441B50FE70E%7D&file=E2E%20-%20Core%20Doc%20-%20%20Head%20Office%20-%20IBU%20Webinar%20(eP2P).docx&action=default&mobileredirect=true",
    IbuOn24CoreDoc :"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7BBCDA2EEF-52AC-45C9-99B1-A14D8D437238%7D&file=E2E%20-%20Core%20Doc%20-%20IBU%20Webinar%20(eP2P)%20-%20%20ON24%20Platform.docx&action=default&mobileredirect=true",
    SlideDecksCoreDoc :"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B3364D038-D433-48C8-A0CA-5D19A8F67A6D%7D&file=E2E%20-%20Core%20Doc%20-%20Speaker%20or%20Lilly%20Created%20Slide%20Decks.docx&action=default&mobileredirect=true",
    SOACoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B762B3C83-561F-4DFE-BA57-15BF65FD18CC%7D&file=E2E%20-Core%20Doc%20-%20SOA%20Landing%20Page.docx&action=default&mobileredirect=true",
    SOABannersCoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B2AADC074-EB65-457E-8841-ED04B476EB79%7D&file=SOA%20Banners.docx&action=default&mobileredirect=true",
    SOAEmailsCoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7BF8C1203C-DE93-4EBD-AF63-6CBDC722E287%7D&file=SOA%20Email%20-%20E2E%20Lifecycle%20V2%209Feb.docx&action=default&mobileredirect=true",
    SOAMicrositesCoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B80C397DC-A086-4A07-91B4-3092F6B3C9F4%7D&file=SOA%20Microsites.docx&action=default&mobileredirect=true",
    InternalBriefingCoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B0DBA8CE1-3BEB-4C3E-AD33-460FB0123D6A%7D&file=E2E%20-%20Core%20Doc%20-%20Internal%20Briefing.docx&action=default&mobileredirect=true",
    PMCCoreDoc:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7BA8090EA2-9B28-447B-A0A2-04B07E363114%7D&file=E2E%20-%20Core%20Doc%20-%20Permission%20Managed%20content-updated%20Feb2024.docx&action=default&mobileredirect=true",

    /* Common Page Url */
    PMAPProcedure:"https://now.lilly.com/procedure/global-promotional-and-educational-materials",
    PMAPReference:"https://collab.lilly.com/sites/PMAPNEHUB/SitePages/PMAP-Reference-page.aspx",
    PMAPForums:"https://collab.lilly.com/sites/PMAPNEHUB/SitePages/PMAP-Forums.aspx",
    MedicalSPCCategorisation:"https://collab.lilly.com/sites/PMAPNEHUB/Lists/Medical%20SPC%20Categorization/AllItems.aspx",
    PMAPJobAid : "https://collab.lilly.com/:w:/r/sites/PMAPNEHUB/_layouts/15/Doc.aspx?sourcedoc=%7BF43A288F-640F-4940-BF40-A71B82A7B7B6%7D&file=NE%20HUB%20PMAP%20Job%20Aid%20February%202024%20FINAL.docx&action=default&mobileredirect=true",
    LillyPlanning: "https://collab.lilly.com/sites/PMAPNEHUB/SitePages/Planning.aspx",
    DSTRequestPowerApp : "https://apps.powerapps.com/play/e/default-18a59a81-eea8-4c30-948a-d8824cdc2580/a/40d817b3-8c5d-4201-8b29-38db5ef4b513?tenantId=18a59a81-eea8-4c30-948a-d8824cdc2580&source=portal&screenColor=RGBA(0%2C176%2C240%2C1)",
    OperationalQuarterlyReviewGuidance : "https://collab.lilly.com/:p:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B393C671F-33F0-47FE-A76A-8C6DD9AF7AA0%7D&file=Operational%20Review%20Slides%20-%20Veeva%20Quarterly%20Review.pptx&action=edit&mobileredirect=true",
    NordicPMAPGuidance : "https://collab.lilly.com/sites/PMAPNEHUB/SiteAssets/Forms/AllItems.aspx?id=%2Fsites%2FPMAPNEHUB%2FSiteAssets%2FSitePages%2FPMAP%2F3190526881Nordic%20PMAP%20guidance%20February%202024%2Epdf&parent=%2Fsites%2FPMAPNEHUB%2FSiteAssets%2FSitePages%2FPMAP",
    StandardFooter : "https://collab.lilly.com/:x:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7BFB180170-BDD2-4DA8-878A-F651B38E7D8B%7D&file=Standard%20footer%20for%20NE%20Hub%20-%20Jan%202023%20JS.xlsx&action=default&mobileredirect=true",
    JardianceFooter : "https://collab.lilly.com/:x:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B1E708967-A824-46FC-B780-150BD97696D9%7D&file=Standard%20footer%20for%20UK&IE%20Jardiance%20-%20April%202022%20MDK.xlsx=&action=default&mobileredirect=true",
    EmailVolumeGuidance : "https://collab.lilly.com/sites/MaterialLifecycleHosedDocuments/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMaterialLifecycleHosedDocuments%2FShared%20Documents%2FDocs%20from%20OCE%20Sharepoint%2FBE%20Flow%2FEmail%20volume%20Guidance%2FNE%20HUB%20Guidance%20on%20Email%20volume%2Epdf&viewid=1aa47839%2D5f07%2D4d8c%2Da8c1%2Dfc42872f979e&parent=%2Fsites%2FMaterialLifecycleHosedDocuments%2FShared%20Documents%2FDocs%20from%20OCE%20Sharepoint%2FBE%20Flow%2FEmail%20volume%20Guidance",
    NEHubEmailVolumeandGuidance :"https://collab.lilly.com/sites/MaterialLifecycleHosedDocuments/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMaterialLifecycleHosedDocuments%2FShared%20Documents%2FDocs%20from%20OCE%20Sharepoint%2FBE%20Flow%2FEmail%20volume%20Guidance%2FNE%20HUB%20Guidance%20on%20Email%20volume%2Epdf&viewid=1aa47839%2D5f07%2D4d8c%2Da8c1%2Dfc42872f979e&parent=%2Fsites%2FMaterialLifecycleHosedDocuments%2FShared%20Documents%2FDocs%20from%20OCE%20Sharepoint%2FBE%20Flow%2FEmail%20volume%20Guidance",
    StandardFooterTemplate : "https://collab.lilly.com/sites/MaterialLifecycleHosedDocuments/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=7P4y4u&cid=7f5ddff4%2Dbdae%2D4a13%2Db423%2D7f26d4839ebb&FolderCTID=0x012000EABF23A00216EE40B70D10D117326467&id=%2Fsites%2FMaterialLifecycleHosedDocuments%2FShared%20Documents%2FDocs%20from%20OCE%20Sharepoint%2FBE%20Flow%2FStandard%20footer%20template%20library",
    PMAPCoreClaimsAllDocuments : "https://collab.lilly.com/sites/PMAPNEHUB/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=EVrBy9&cid=2092386e%2D80ea%2D4b02%2D88e7%2D9ece00e0f0fc&RootFolder=%2Fsites%2FPMAPNEHUB%2FShared%20Documents%2FCore%20Claims&FolderCTID=0x012000CEE82E8C998AFB4788B0031CC8A6EBFF",
    
    
    /* BE Page */
    AudienceRequestForm : "https://forms.office.com/Pages/ResponsePage.aspx?id=gZqlGKjuMEyUitiCTNwlgHGYa9_7IuFPnEbikkW2_CxUOTQ3OTNKTjU0SzRZU0o4MFJNNFVQV05VOCQlQCN0PWcu",
    AudienceListRequestForm : "https://forms.office.com/Pages/ResponsePage.aspx?id=gZqlGKjuMEyUitiCTNwlgHGYa9_7IuFPnEbikkW2_CxUOTQ3OTNKTjU0SzRZU0o4MFJNNFVQV05VOCQlQCN0PWcu&wdLOR=cD451B84C-FDA4-4C42-AFCA-3CB7F9E051A7",  
    StandardFooterTemplateLibrary : "https://collab.lilly.com/sites/MaterialLifecycleHosedDocuments/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=7P4y4u&cid=5f81aa7f%2D1e1b%2D4b2a%2Da382%2D2fedf52a9300&RootFolder=%2Fsites%2FMaterialLifecycleHosedDocuments%2FShared%20Documents%2FDocs%20from%20OCE%20Sharepoint%2FBE%20Flow%2FStandard%20footer%20template%20library&FolderCTID=0x012000EABF23A00216EE40B70D10D117326467",
    IBU360TableauServer : "https://tableau-edb.aws.lilly.com/#/site/IBU-CAT/views/IBU360/IBU360?:iid=1",
    IBUOCEHeadquarters : "https://collab.lilly.com/sites/WelcometoIBUMCE/SitePages/Home.aspx",
    VeevaDigitalPublishingGuidance: "https://collab.lilly.com/sites/MaterialLifecycleHosedDocuments/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMaterialLifecycleHosedDocuments%2FShared%20Documents%2FDocs%20from%20OCE%20Sharepoint%2FBE%20Flow%2FVeeva%20Digital%20Publishing%20Guide%2FVeeva%20Digital%20Publishing%20Guidance%5FMay%202024%2Epdf&viewid=1aa47839%2D5f07%2D4d8c%2Da8c1%2Dfc42872f979e&parent=%2Fsites%2FMaterialLifecycleHosedDocuments%2FShared%20Documents%2FDocs%20from%20OCE%20Sharepoint%2FBE%20Flow%2FVeeva%20Digital%20Publishing%20Guide",

    
    /* Cookie */
    ChangeSummarySheet : "https://collab.lilly.com/:w:/r/sites/UKHubMCE/_layouts/15/Doc.aspx?sourcedoc=%7B30C7099B-A9F8-4A66-A3F0-9DD2FFA84418%7D&file=Information%20Sheet%20Template.docx&action=default&mobileredirect=true",
    
    
    /* HighLevelProcess */
    IPA:"https://collab.lilly.com/sites/NEMarketing/SitePages/IPA.aspx?csf=1&web=1&e=33T6JO&cid=9c78ad11-5e09-4243-8fe2-9f95b57a2b81",
    CET:"https://cet.lilly.com",
    NEMarketing:"https://collab.lilly.com/sites/NEMarketing",


    /*IVA*/
    VaulttoCLMOwnerandGatekeeperTraining : "https://collab.lilly.com/sites/GCOTraining/Shared%20Documents/Forms/AllItems.aspx?RootFolder=%2Fsites%2FGCOTraining%2FShared%20Documents%2FVeeva%5FPromoMats%2FCLM",
    VeevaCLMAccountRequestForm : "https://teamgco.lilly.com/process/On-Off_Boarding_Process",
    IVAAutoPackagePresentationGuide : "https://collab.lilly.com/:p:/r/sites/GCOTraining/_layouts/15/Doc.aspx?sourcedoc=%7BAF1D904D-CFF4-4229-B26A-8852389CB27F%7D&file=CLM_Owner_Gatekeeper_Vault_Training_Version_4.pptx&action=edit&mobileredirect=true",
    

    /*Passport*/
    IBULillyPassportPlaybook : "https://collab.lilly.com/sites/WelcometoIBUMCE/Best%20Practices%20%20Playbooks/Forms/AllItems.aspx?id=%2Fsites%2FWelcometoIBUMCE%2FBest%20Practices%20%20Playbooks%2FLilly%5FPassport%5F2%2E0%5FPlaybook%2Epdf&parent=%2Fsites%2FWelcometoIBUMCE%2FBest%20Practices%20%20Playbooks",
    NEHubOCECurrentlyunderdevelopmentandapproval : "https://collab.lilly.com/sites/UKHubMCE/Shared%20Documents/Forms/Website.aspx?id=%2Fsites%2FUKHubMCE%2FShared%20Documents%2FAPPROVALS%2FLilly%20Passport%2FUnder%20development%20and%20approval&viewid=4be318fe%2Dfbcf%2D4594%2D8890%2D5f5219d0e826",
    NEHubOCECurrentlyapprovedfordistribution : "https://collab.lilly.com/sites/UKHubMCE/Shared%20Documents/Forms/Website.aspx?newTargetListUrl=%2Fsites%2FUKHubMCE%2FShared%20Documents&viewpath=%2Fsites%2FUKHubMCE%2FShared%20Documents%2FForms%2FWebsite%2Easpx&id=%2Fsites%2FUKHubMCE%2FShared%20Documents%2FAPPROVALS%2FLilly%20Passport%2FCurrently%20approved%20for%20distribution&viewid=4be318fe%2Dfbcf%2D4594%2D8890%2D5f5219d0e826",
    NEHubOCEPreviousVersionsWebsite : "https://collab.lilly.com/sites/UKHubMCE/Shared%20Documents/Forms/Website.aspx?newTargetListUrl=%2Fsites%2FUKHubMCE%2FShared%20Documents&viewpath=%2Fsites%2FUKHubMCE%2FShared%20Documents%2FForms%2FWebsite%2Easpx&id=%2Fsites%2FUKHubMCE%2FShared%20Documents%2FAPPROVALS%2FLilly%20Passport%2FPrevious%20Versions&viewid=4be318fe%2Dfbcf%2D4594%2D8890%2D5f5219d0e826",
    
    
    /*PM*/
    NEHubWarehouseContacts : "https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B13951EF1-CF21-4428-BD2F-0DE0F3379D23%7D&file=NE-HUB-Warehouse-contacts_V1-April-2023_Final.docx&action=default&mobileredirect=true",
    PMAPPhysicalStorageProcess:"https://collab.lilly.com/sites/MaterialLifecycleHosedDocuments/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FMaterialLifecycleHosedDocuments%2FShared%20Documents%2FDocs%20from%20OCE%20Sharepoint%2FPhysical%20Materials%2FPhysical%2Dstorage%2Dprocess%2Epdf&parent=%2Fsites%2FMaterialLifecycleHosedDocuments%2FShared%20Documents%2FDocs%20from%20OCE%20Sharepoint%2FPhysical%20Materials",
    UKRoIHardcopyArchiveProcess:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B8F304A10-18B1-4C43-9C9C-AD8D7697B414%7D&file=UKRoI%20Hardcopy%20Archive%20Process.docx&action=default&mobileredirect=true",
    NEHUBProductionofPhysicalMaterials :"https://collab.lilly.com/:p:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7BCE9380A0-22F7-41E1-BEBF-6AF82A6184B0%7D&file=NE-HUB-Production-of-Physical-Materials--V2-April-2023.pptx&action=edit&mobileredirect=true",
    MaRSNEHUBSite:"https://collab.lilly.com/sites/MaRSNEHUBSite/_layouts/15/AccessDenied.aspx?Source=https%3A%2F%2Fcollab%2Elilly%2Ecom%2Fsites%2FMaRSNEHUBSite&correlation=ba41ffa0%2D307b%2D4000%2Db7c2%2D3542ba648822",
    PrintingBriefChecklist:"https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B6814A143-84D4-491A-A51B-9BD943076876%7D&file=Indicia%20Print%20Brief%20Checklist_GB_IE_Feb%202024.docx&action=default&mobileredirect=true",
    
    
    /*PRIVACY*/
    GlobalPrivacyProgram: "https://now.lilly.com/landing/global-global-privacy-program/global-globalprivacyprogramoverview",
    lillyPrivacyUkEn : "https://www.lillyprivacy.com/uk-en/hcp",
    lillyPrivacyIEen : "https://www.lillyprivacy.com/IE-en/hcp",
    lillyPrivacyFIfi : "https://www.lillyprivacy.com/FI-fi/hcp",
    lillyPrivacyNono : "https://www.lillyprivacy.com/No-no/hcp",
    lillyPrivacySEsv : "https://www.lillyprivacy.com/SE-sv/hcp",
    lillyPrivacyDKda : "https://www.lillyprivacy.com/DK-da/hcp",
    UKHomecarePrivacyNotice: "https://dw250ad2fwsz1.cloudfront.net/962a1cfe-f9f4-4134-8c11-1f2de1c4386d/a8d5c514-c1b3-4ae8-8bb9-439c8bb59ab0/a8d5c514-c1b3-4ae8-8bb9-439c8bb59ab0_source__v.pdf",
    UKPrivacyNoticeHCPforsamplesPP : "https://lilly-promomats.veevavault.com/ui/#doc_info/917525/1/0",
    LillyPrivacyPolicy : "https://meetings.lilly.com/event/97f18cbc-5b27-4056-828a-8b54bf651f60/websitePage:8e820e76-eed8-49e5-bcfe-d2ca454860cf",
    
    

    /*VAE*/
    GatekeeperTraining : "https://collab.lilly.com/:p:/r/sites/GCOTraining/_layouts/15/Doc.aspx?sourcedoc=%7B1EDDBFD1-AB11-4522-B340-59DF20A3532F%7D&file=Approved%20Email%20Owner_Reviewer%20Training.pptx&action=edit&mobileredirect=true",
    VAEBriefingGuideStandardTemplate : "https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7BF5529C5F-776A-4EE2-AC65-98BC0283A228%7D&file=VAE%20briefing%20template%20Uk-IE-May%202023.docx&action=default&mobileredirect=true",
    VeevaDigitalPublishingGuide:"https://collab.lilly.com/sites/UKHubMCE/Shared%20Documents/Forms/Website.aspx?RootFolder=%2Fsites%2FUKHubMCE%2FShared%20Documents%2F2023%20%2D%20Toolbox%2FVeeva%20Digital%20Publishing&FolderCTID=0x012000B5BB42143FE7344DACF8C39B198D5753",



    /* Website*/
    GCOGlobalWebsiteOwnerTrainingforWebsites : "https://elilily.plateau.com/learning/user/common/dispatchItemDetailActions.do?OWASP_CSRFTOKEN=G16K%2dYZ6H%2dPDUO%2dI94R%2dFIO8%2dURBO%2dB9GS%2dR4CN&studentID=2264761&studSysGuid=68EE9318DE712783E053A328080A3E45&actionName=launchContent&componentTypeID=COURSE&componentID=9204719&revisionDateMilliseconds=1598879820000&revisionDate=1598879820000&sku=SKU-1903210&cpntClassification=CONTINUOUS%20ONLINE%20ACCESS&addUser=P&forumID=&accessNewRevision=false&returnLink=%2Flearning%2Fuser%2Fcommon%2FviewItemDetails.do%3FstudentID%3D2264761%26componentTypeID%3DCOURSE%26componentID%3D9204719%26revisionDate%3D1598879820000%26fromDevPlan%3Dtrue%26hideActionBtns%3Dfalse%26fromApprovalsPage%3Dfalse%26fromHistory%3Dfalse%26checklistID%3Dnull%26encodedExternalParam%3Dundefined%26isExternalRequestFromCDP%3Dfalse%26goalID%3D%26componentKey%3D2703750&menuGroup=&menuItem=&fromDevPlan=true&fromCurrDetailsRequirements=false&quantity=1&shoppingAccountID=SHPNGACCTID-1211832&sortOrder=&sortBy=&showPage=showForum&titleLabelKey=title.Item.f133fe93edf541cf9f428f26cc0acd24&backto=%2Fuser%2Fcommon%2FviewItemDetails.do%3FstudentID%3D2264761%26componentTypeID%3DCOURSE%26componentID%3D9204719%26revisionDate%3D1598879820000%26fromDevPlan%3Dtrue%26hideActionBtns%3Dfalse%26fromApprovalsPage%3Dfalse%26fromHistory%3Dfalse%26checklistID%3Dnull%26encodedExternalParam%3Dundefined%26isExternalRequestFromCDP%3Dfalse%26goalID%3D%26componentKey%3D2703750&checklistID=&studCpntID=60528445&enrollSeatID=&openSection=none",
    LillyClarity : "https://clarity.lilly.com/",
    NEHubCorporateWebsiteChangeRequest : "https://forms.office.com/Pages/ResponsePage.aspx?id=gZqlGKjuMEyUitiCTNwlgPO8H0Kl8v5Jk_pxLf_3esdUMUJBOVA4NE5PUjYzMVIxTjFLUkFWMUE3UiQlQCN0PWcu&wdLOR=cA10FC381-0CC6-4A6E-A981-A607894A87E7",
    ContentHostingRequestForm : "https://forms.office.com/Pages/ResponsePage.aspx?id=gZqlGKjuMEyUitiCTNwlgIVAp79T_bFCsj8G0QxhxNpUOUw2WVFCQTlCWU84WlpRMVk4OVAwUlpOQy4u&wdLOR=c1D5F9DD6-75B9-49D4-A877-894C762CA274",
    trademarkslillycom : "mailto:trademarks@lilly.com",
    SeeCodeCasehere : "https://www.pmcpa.org.uk/cases/completed-cases/auth32701019-complainant-v-bayer/",      
    lillycouk : "https://www.lilly.com/uk/",



    
    /*Meetings */
    MeetingsLogisticsJobAid : "https://now.lilly.com/procedure/global-meetings-logistics",
    FMVSOP : "https://now.lilly.com/procedure/global-determining-fair-market-value-for-hcps",
    LMCMercurySupportSite : "https://collab.lilly.com/sites/LCMSMeetings/SitePages/Mercury.aspx",
    SpeakerTrainingJobAid : "https://now.lilly.com/procedure/global-hcp-speaker-training",
    SpeakerBriefingJobAid : "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/2968827/2/0?state_type=steady_state__v",
    MeetingsRelatedInformation : "https://now.lilly.com/page/global-meetingswithhcps",
    GBSTrainedSpeakerListPowerApps :"https://apps.powerapps.com/play/e/default-18a59a81-eea8-4c30-948a-d8824cdc2580/a/460bd4f6-4be6-4f4a-a719-885bbea85dc4?tenantId=18a59a81-eea8-4c30-948a-d8824cdc2580&skipAppMetadata=true",
    HEHUBLMCOrgChart:"https://collab.lilly.com/sites/UKHubCMSOpsmeeting/Shared%20Documents/LCMS%20SharePoint%20docs/HE%20HUB%20LMC%20Org%20Chart_Oct%202023.png?csf=1&web=1&e=8QRH8N&cid=da80bd3a-2c31-4f45-95d7-644669f7e81e",
    SponsoringIndependentMeetingsEventsorInitiativesforNonHCPs : "https://now.lilly.com/procedure/global-sponsoring-independent-meetings-events-or-initiatives-for-non-hcps",
    SponsoringIndependentMeetingsEventsorInitiativesforHCPs : "https://now.lilly.com/procedure/global-sponsoring-independent-meetings-for-hcps",
    PlanningandExecutingExhibitsatCongresses : "https://now.lilly.com/procedure/global-planning-and-executing-exhibits-at-congresses",
    IndependentMeetingEventInitiativeApprovalForm : "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/2971417/3/0",
    powerapp : "https://forms.office.com/pages/responsepage.aspx?id=gZqlGKjuMEyUitiCTNwlgDwgkxY0Od5Po6-n88m1xM5UN0pXVTVTTDA4S1BCNUZBSlZXRkNVMlRNVCQlQCN0PWcu",
    MeetingRequestForm : "http://cventmeetingrequests.lilly.com/",
    UKDigitalRoomAVBookingService:"https://collab.lilly.com/sites/UKITPortal/SitePages/How-to-book-an-Audio-Visual-Service.aspx?csf=1&web=1&e=ZUWTPe",
    UKHubGuidanceDocument : "https://collab.lilly.com/sites/PMAPNEHUB/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FPMAPNEHUB%2FShared%20Documents%2F1%2E%20Planning%2FSpeaker%20Slides%2FStandards%20Speaker%20Slides%2FUK%20Hub%20Guidance%20Document%20for%20Speaker%20Events%20%2D%20FINAL%202020%20v1%2E4%20%28004%29%2Epdf&parent=%2Fsites%2FPMAPNEHUB%2FShared%20Documents%2F1%2E%20Planning%2FSpeaker%20Slides%2FStandards%20Speaker%20Slides",
    ON24RequestForm:"https://forms.office.com/r/D3uXADDwb1",

    /*Slide Decks*/
    SpeakerTrainingCoreSlides : "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/3395043/2/0?state_type=steady_state__v",
    PMAPSite : "https://collab.lilly.com/sites/PMAPNEHUB",

    
    /*SOA*/
    VeevaVault : "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/2971389/4/0?state_type=steady_state__v&anQS=page1&annotate=false",
    PCoEJob : "https://lilly-qualitydocs.veevavault.com/ui/#doc_info/2968824/2/0?anQS=page4&annotate=false",
    Trulicity: "https://collab.lilly.com/:p:/r/sites/UKHubMCE/_layouts/15/Doc.aspx?sourcedoc=%7BDCF72185-88DB-4A40-9E4D-1452BAAA3B98%7D&file=Trulicity%20online%20influence%20work%20project%20summary%20-%20Copy.pptx&action=edit&mobileredirect=true",
    PreferredSupplierList : "https://collab.lilly.com/:x:/r/sites/Global_Procurement/_layouts/15/Doc.aspx?sourcedoc=%7B74F49FB5-0BDA-4A3C-B8A1-936C08C2EEF1%7D&file=Preferred%20Supplier%20List.xlsx&action=default&mobileredirect=true&cid=bc407a6d-18b9-4cf8-ab98-43de2fb4a02b",
    SupplierCreationPowerApp: "https://apps.powerapps.com/play/e/default-18a59a81-eea8-4c30-948a-d8824cdc2580/a/b3f1bad9-8540-4f0e-93b1-a845502a2991?tenantId=18a59a81-eea8-4c30-948a-d8824cdc2580&amp%3bscreenname=sifdsp&amp%3bCaseID=270423092649",
    SOABriefingTemplate: "https://collab.lilly.com/:x:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B7C8E75EA-D10F-42F3-B764-6452BDF9D25D%7D&file=SOA%20Briefing%20Template.xlsx&action=default&mobileredirect=true",
    ThirdPartyRiskChecklist: "https://collab.lilly.com/sites/Global_Procurement/SitePages/UK-How-to-Buy-Wizard.aspx",
    KPItemplate: "https://collab.lilly.com/:x:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B568BAD7C-4AE2-419A-A85B-E39E9EDB847D%7D&file=KPI%20template.xlsx&action=default&mobileredirect=true",   
    UploadingwithdrawalemailtoVeeva: "https://collab.lilly.com/:p:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7BB866698F-B2D8-462D-869B-299F317BD379%7D&file=Uploading%20withdrawal%20email%20to%20Veeva.pptx&action=edit&mobileredirect=true",
    LillyNDAtemplate: "https://collab.lilly.com/:w:/r/sites/MaterialLifecycleHosedDocuments/_layouts/15/Doc.aspx?sourcedoc=%7B49CF1477-DD77-4817-BF68-FD07A8EDB482%7D&file=Lilly%20NDA%20template.doc&action=default&mobileredirect=true",   
    SOANavigatorHome : "https://app.powerbi.com/groups/me/reports/1b1c076e-8a91-4a80-9e4f-6c6e9ae42cd6/ReportSection2ebafa512d490abe5cda?experience=power-bi",
    SupplierInformationForm : "https://collab.lilly.com/:w:/r/sites/Global_Procurement/_layouts/15/Doc.aspx?sourcedoc=%7B477FD017-C048-424F-896A-D1E76DFE73DB%7D&file=Supplier-Information-Form---Z2.docx&action=default&mobileredirect=true",
    ElilillyssoAravo : "https://elilillysso.aravo.com",
    AribaGuidedBuying : "https://s1.ariba.com/gb/?realm=lilly-1&locale=en_US",
    SOANavigator : "https://app.powerbi.com/groups/me/reports/a86b65eb-cc34-48f2-b951-8bba3139264a/ReportSection65d22f2de09c3353b6b2?ctid=18a59a81-eea8-4c30-948a-d8824cdc2580",
    TemplatesLillyDocuments:"https://collab.lilly.com/sites/PMAPNEHUB/Shared%20Documents/Forms/AllItems.aspx?RootFolder=%2Fsites%2FPMAPNEHUB%2FShared%20Documents%2F2%2E%20Upload%2FTemplates&FolderCTID=0x012000CEE82E8C998AFB4788B0031CC8A6EBFF",
    

    /*PMC*/
    ArticleRequestsite :"https://collab.lilly.com/sites/ArticleRequestSystem",
    TrainingDeck:"https://collab.lilly.com/:p:/r/sites/ArticleRequestSystem/_layouts/15/Doc.aspx?sourcedoc=%7B4D9B65CE-1DBC-4E11-AA6E-FAFD0BD21189%7D&file=Copyright%20Article%20Request%20System%20Training%20Narrated.pptx&action=edit&mobileredirect=true",

    /*Contact US*/
    MeetTheTeam : "https://collab.lilly.com/sites/MaterialLifecycleHosedDocuments",
    
    /*Useful links*/
    MHRAVetting : "https://collab.lilly.com/sites/PMAPNEHUB/SitePages/MHRA-V.aspx "
}    