import React from "react";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {webConfig} from '../configs/webConfig'
import { Link } from 'react-router-dom';
import "../css/SOA.css";
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/SOA_Phases_SVG/SOA_Phase_1.svg";




const SOA = () => {
  const navigate = useNavigate();
  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("phase1rect1");


  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    const rect8 = svg.getElementById("rect8");
    const rect9 = svg.getElementById("rect9");
    const rect11 = svg.getElementById("rect11");
    const rect12 = svg.getElementById("rect12");
    const rect13 = svg.getElementById("rect13");
    
  
    rect1.addEventListener("click", () => {
      setP1visibleSection("phase1rect1");
    });
    rect2.addEventListener("click", () => {
      setP1visibleSection("phase1rect2");
    });
    rect3.addEventListener("click", () => {
      setP1visibleSection("phase1rect3");
    });
    rect4.addEventListener("click", () => {
      setP1visibleSection("phase1rect4");
    });
    rect5.addEventListener("click", () => {
      setP1visibleSection("phase1rect5");
    });
    rect6.addEventListener("click", () => {
      setP1visibleSection("phase1rect6");
    });
    rect7.addEventListener("click", () => {
      setP1visibleSection("phase1rect7");
    });
    rect8.addEventListener("click", () => {
      setP1visibleSection("phase1rect8");
    });
    rect9.addEventListener("click", () => {
      setP1visibleSection("phase1rect9");
    });
    rect11.addEventListener("click", () => {
      navigate('/soa/soa-microsites')
    });
    rect12.addEventListener("click", () => {
      navigate('/soa/soa-banners')
    });
    rect13.addEventListener("click", () => {
      navigate('/soa/soa-emails')
    });
    

  }, []);



  return (

    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    Source of Authority (SOA)  
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">Source of Authority (SOA) </h2>
            <p>
              <em>
                A Third Party Source of Authority (SOA) website is defined as an independent community of 
                healthcare professionals (HCPs) coming together with the objective of professional development 
                for themselves and the broader medical community to improve patient care and treatment outcomes. 
                The HCP community might be managed in one of the following ways: 
              </em>
            </p>
            <em>
              <ul className="ul_padding">
                <li>
                   Private Third Party SOAs: Examples are Medscape, M3, SERMO, Unidad’s, The Lancet, Wiley, Nature, BMJ.
                </li>
                <li>
                   HCP Managed Third Party SOAs: Examples are the British Society of Rheumatology (BSR), The Association 
                    of British Clinical Diabetologists (ABCD), the British Association of Dermatology (BAD). 
                </li>
              </ul>
            </em>
            <p>
              <em>
                  Lilly partners with the Third Party SOAs on different materials (email, microsite, webinar, banner, etc.). 
                  This document outlines the SOA engagement process, i.e., procedures to be followed before engaging with an SOA. 
              </em>
            </p>
          </div>
        </div>
      </div>

      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link navLinkTab active"
              data-bs-toggle="tab"
              href="#mainSoaActivities"
            >
              Main SOA 
              <br />
              Activities
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navLinkTab" data-bs-toggle="tab" href="#trainingRequirements">
              Training <br /> Requirements 
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navLinkTab" data-bs-toggle="tab" href="#trainingResources">
              Training <br /> Resources 
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navLinkTab" data-bs-toggle="tab" href="#SOAChecklist">
              Third Party SOA Engagement Checklist
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navLinkTab" data-bs-toggle="tab" href="#HelpfulResources">
              Helpful Resources and Templates
            </a>
          </li>
        </ul>
        
        <div id="tabContent" className="tab-content">
            <div id="mainSoaActivities" className="tab-pane fade in active tabs_box tabContent">
                <div>
                  <p className='tabContent'>
                    SOA activities can be high in risk due to multiple factors. Different SOA activities have different briefing requirements and compliance guidelines. The requirements for each tactic type being used must be reviewed in detail before preparing for any activity. 
                  </p>
                  <p className='tabContent'>
                    <strong>Microsite:</strong> A microsite is a branded/unbranded content site that lives outside of the Lilly homepage and/or brand URL. Microsites can be closed or open access (e.g., BMJ). Closed access microsites require credentials to log-in and view the page, whereas open access microsites do not require a log-in. 
                  </p>
                  <p className='tabContent'>
                    <strong>Banner:</strong> Banners are also known as display banner advertisements or display advertisements. They are clickable digital advertisements embedded into the SOA websites to drive traffic to a microsite, webinar registration or scientific content. 
                  </p>
                  <p className='tabContent'>
                    <strong>Email:</strong> SOA emails are emails distributed by SOAs to the target Audience. It can either be a bespoke email or a newsletter. Bespoke emails are unique to Lilly; SOA email is fully dedicated to our content. In comparison, newsletter type of email involves content from the SOA or other companies. 
                  </p>
                  <p className='tabContent'>
                    <strong>Webinar:</strong> A webinar is a live or recorded, web-based video conference. It can be hosted on the SOA website or Lilly-owned platforms such as ON24 to an Audience. Please note that Lilly does not have data rights for the webinar that takes place on SOA’s platform. It is encouraged to execute webinar events on Lilly’s ON24 platform as it gives insights on the attendee list. 
                  </p>
                </div>
            </div>
            <div id="trainingRequirements" className="tab-pane fade tabs_box tabContent">
               <p className='tabContent'>
                 There are several considerations when engaging with the SOAs. A lot of these trainings are a part of E&C or Procurement curriculum that is added to the learning plan for all marketers. Please take the time to review the list of training resources in the next tab.  
               </p>
            </div>
            <div id="trainingResources" className="tab-pane fade tabs_box tabContent">
                <table className="table table-striped mt-3">
                  <thead>
                      <tr>
                        <th>Course ID</th>
                        <th>Course Name</th>
                        <th>Content Summary</th>
                        <th>Link to the Training</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>9360837</td>
                      <td>PR: Working with Third Parties: Program Overview</td>
                      <td>Recommended: General knowledge about working with Third Parties.</td>
                      <td>Core Curriculum ID 9360837</td>
                    </tr>
                    <tr>
                      <td>9077882</td>
                      <td>EC-PROC: Contracting and Paying a Third Party</td>
                      <td>Required: Third Party is a company or an individual that works on behalf of, in partnership or collaboration with, or provides products, services, or capabilities to Lilly but is (are) not Lilly employee(s), including subsidiaries and affiliates. This procedure outlines the principles of contracting with a Third Party.</td>
                      <td>
                        <Link 
                          to={webConfig.VeevaVault}
                          target="_blank"
                        >
                          Veeva Vault
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>9076895</td>
                      <td>EC-PROC: Partnering with External Organizations</td>
                      <td>Required: The procedure applies to partnership engagements with external organizations (patient advocacy groups, professional associations, or similar groups [e.g., consumer, special interest, charitable or community organizations]).</td>
                      <td>Core curriculum ID 9076895 </td>
                    </tr>
                    <tr>
                      <td>9116908</td>
                      <td>EC-PROC: Respecting Privacy</td>
                      <td>Required: Working with PI, also known as processing PI, means doing anything with PI, such as collecting, recording, organizing, storing, using, transferring, viewing, combining, deleting and/or erasing PI. This procedure is a guide for the NE Hub on working with PI.</td>
                      <td>Core Curriculum ID 9116908  </td>
                    </tr>
                    <tr>
                      <td>9350269</td>
                      <td>EC-PROC: Global Company-Controlled Content on Third Party Platforms</td>
                      <td>Recommended: This procedure applies to any Lilly-owned or controlled content placed on Third Party platforms (i.e., websites, mobile applications, podcasts, etc.) that are viewable or accessible by external Audiences.</td>
                      <td>Core Curriculum ID 9350269 </td>
                    </tr>
                    <tr>
                      <td>9348523</td>
                      <td>NE Hub: Procurement Engagement and Contracting Processes</td>
                      <td>Recommended: Standardized guidance for the NE Hub on Procurement Engagement and Contracting Processes.</td>
                      <td>
                        <Link 
                          to={webConfig.PCoEJob} 
                          target="_blank"> 
                            Veeva Vault - PCoE Job Following FRAP Procurement Engagement and Contracting Process EN NE HUB, Page 4
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>N/A</td>
                      <td>N/A</td>
                      <td>Recommended</td>
                      <td>
                        <Link 
                          to={webConfig.Trulicity} 
                          target="_blank"> Trulicity online influence work project summary - Copy.pptx (lilly.com)
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div id="SOAChecklist" className="tab-pane fade tabs_box tabContent">
                <p className='tabContent'>
                  Many different variations are possible when engaging with the SOA. These depend on whether the SOA Vendor is on the Preferred Vendor list or a New Vendor onboarding is required. It also depends on whether the tactic was conducted by them before. Additionally, there are cost threshold considerations. Refer to the table below to understand what is required in which Scenario.  
                </p>
                <table className="table table-striped mt-3">
                  <thead>
                      <tr>
                        <th>Items</th>
                        <th>New SOA</th>
                        <th>Existing SOA</th>
                        <th>Existing SOA but a New Tactic Type </th>
                        <th>Comments</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Risk Assessment</td>
                      <td>&#10004;</td>
                      <td>Not needed</td>
                      <td>&#10004;</td>
                      <td>Contact Procurement for further consultation</td>
                    </tr>
                    <tr>
                      <td>Informing Procurement of campaign cost above the threshold</td>
                      <td>&#10004;</td>
                      <td>&#10004;</td>
                      <td>&#10004;</td>
                      <td>The threshold is £25K/$30K/€30K for all NE Hub countries</td>
                    </tr>
                    <tr>
                      <td>MSA</td>
                      <td>Not needed, but could be an option if it is going to be used frequently on an ongoing basis</td>
                      <td>Not needed</td>
                      <td>Not needed</td>
                      <td>Vendors with an overarching long-term contract (MSA), with agreed rates and discounts: M3 (doctors.net), Omniamed, BMJ, Medscape, Elsevier, Wiley, Sermo​, Terve Media. Work with these SOAs using a PO. ​Contact Procurement for further details </td>
                    </tr>
                    <tr>
                      <td>Single Project Agreement (SPA)</td>
                      <td>&#10004;</td>
                      <td>&#10004;</td>
                      <td>&#10004;</td>
                      <td>Needed if there is NO MSA in place. Contact Procurement for further information</td>
                    </tr>
                    <tr>
                      <td>WWTP</td>
                      <td>&#10004;</td>
                      <td>Not needed</td>
                      <td>&#10004;</td>
                      <td>Contact Procurement or Third Party Engagement Team for further information</td>
                    </tr>
                    <tr>
                      <td>Raising Work Order (WO) </td>
                      <td>Only if an MSA is in place </td>
                      <td>Only if an MSA is in place </td>
                      <td>Only if an MSA is in place </td>
                      <td>WOs sit under MSAs. A WO without an MSA is an SPA. </td>
                    </tr>
                    <tr>
                      <td>Raising PO </td>
                      <td>&#10004;</td>
                      <td>&#10004;</td>
                      <td>&#10004;</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Privacy Impact Assessment (PIA)</td>
                      <td>&#10004;</td>
                      <td>Not needed</td>
                      <td>Depends on the new tactic. </td>
                      <td>It is required if we work with Personal Information. Consult with E&C when in doubt.</td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div id="HelpfulResources" className="tab-pane fade tabs_box tabContent">
              <p className='tabContent'>
                <Link to={webConfig.PreferredSupplierList} 
                      target="_blank">Preferred Supplier List.xlsx (lilly.com):
                </Link> The list of preferred Vendors for NE Hub countries is available here. Material Owners are encouraged with the Vendor in the list.  
              </p>
              <p className='tabContent'>
                <Link to={webConfig.SupplierCreationPowerApp} 
                      target="_blank">Supplier Creation Power App:
                </Link> We can work with a New Vendor if there is a business need. This form should be filled by the Campaign Owner and submitted to the Procurement if we want to include a New Vendor to Lilly Procurement system. 
              </p>
              <p className='tabContent'>
                <Link to={webConfig.SOABriefingTemplate} 
                      target="_blank">SOA Briefing Template.xlsx (lilly.com):
                </Link> The briefing template covers the main elements needed during the initial communication with the SOA. 
              </p>
              <p className='tabContent'>
                <Link to={webConfig.ThirdPartyRiskChecklist} 
                      target="_blank">Third Party Risk Checklist (lilly.com):
                </Link> The Campaign Owner needs to review the Third Party Risk Checklist at the beginning of the campaign. They should contact Procurement if there is a risk. 
              </p>
              <p className='tabContent'>
                <Link to={webConfig.KPItemplate} 
                      target="_blank">KPI template.xlsx (lilly.com):
                </Link> This is a standard template to be filled by the Third Party SOAs monthly to see the campaign results. 
              </p>
              <p className='tabContent'>
                <Link to={webConfig.UploadingwithdrawalemailtoVeeva} 
                      target="_blank">Uploading withdrawal email to Veeva.pptx (lilly.com):
                </Link> This template illustrates how to upload the SOA Campaign withdrawal email to VVPM. 
              </p>
              <p className='tabContent'>
                <Link to={webConfig.LillyNDAtemplate} 
                      target="_blank">Lilly NDA template.doc:
                </Link> Non-Disclosure Agreement should be signed before sharing any information with the Third Party Vendor. 
              </p>
            </div>
        </div>
      </div>
      
      {/*Add verticle Tab*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
            <button
              className="nav-link"
              id="v-pills-faqs-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-faqs"
              type="button"
              role="tab"
              aria-controls="v-pills-faqs"
              aria-selected="false"
            >
              FAQs
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">SOA Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: ENGAGING WITH SOA "
                    defaultOpen={true}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                         <Phase1 ref={svgRef1} className="soaShape1 main-content" />
                      </div>
                      <div className="col-lg-7 planning p_font pt-3 flowsSecondPart side-content">
                      <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect1" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">Selecting the 3rd Party SOA </p>
                          <p className="flowsContent">
                            With so many Vendors to choose from, it is important to understand the answers to the following questions to select the best option for the campaign.
                            Find answer to all these questions (and more!) on {' '}
                            <Link 
                              to={webConfig.SOANavigatorHome}
                              target="_blank"
                            >SOA Navigator SOA-Navigator - Home (lilly.com).</Link>
                          </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> Which SOA facilitates what kind of tactics?  </li>
                            <li> What is their reach within the Target customer base?  </li>
                            <li> How much could the campaign cost?   </li>
                            <li> What level of customer engagement could be generated?   </li>
                            <li> What specification of artwork is required?   </li>
                            <li>  What documents will be required for Medical, Legal, Regulatory (MLR) approval of tactics?  </li>
                          </ul>
                          <p className="flowsContent">
                             Additionally, consider talking to Procurement about running a formal Request for Proposal (RFP) – please note that this can extend the selection process by up to a month. Alternatively, simply pull together a brief and a succinct specification of what is needed and ask the shortlisted agencies for a quote.  
                          </p>
                          <p className="flowsHeading">Remember! </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> The Campaign Owners are additionally encouraged to work with the Vendors in the Preferred Vendor List
                                (<Link 
                                  to={webConfig.PreferredSupplierList}
                                  target="_blank"
                                >Preferred Supplier List.xlsx (lilly.com)</Link>), unless there is a real business need to work with a New SOA Vendor (Vendor that is not on the Preferred Supplier list).   </li>
                            <li>If working with a ‘New’ Vendor, the Campaign Owner should not commit to any work with the Third Party SOA before the Risk Assessment has been conducted and the Procurement team has confirmed completion of the New Vendor process.  </li>
                          </ul>  
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect2"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">High-Level Briefing to Third Party SOA  </p>
                          <p className="flowsContent">The Campaign Owner gives a briefing to the Third Party SOA to get a cost quote. Following details should be additionally provided.  </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> Name of the Material Owner    </li>
                            <li> Target specialties: Example: General Practitioners + Endocrinologist    </li>
                            <li> Target Countries: Example: UK </li>
                            <li> Goal of the campaign: Example: Create disease awareness and amplify brand Share of Voice (SoV).  </li>
                            <li> Lilly assets available: Example: Mass-SOA – Educational mass email to be sent out through the SOA, Landing page (interactive tool). </li>
                            <li> External assets predefined: Example:  Mass-SOA email (4), Educational video with Key opinion Leader (KOL), Landing page and Banners. </li>
                          </ul>
                          <p className="flowsHeading">Remember! </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> Refer to
                                <Link 
                                  to={webConfig.SOABriefingTemplate}
                                  target="_blank"
                                > SOA Briefing Template.xlsx (lilly.com)</Link> as guidance.</li>
                          </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect3"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Receive Cost Quote   </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> The cost quote received from the SOA based on the briefing should be itemized according to the materials and/or services.     </li>
                            <li> The potential result of the campaign by the type of tactics should be seen in the cost quote and included in the contracting document. Providing the expected result of a campaign is a kind of commitment from the SOA, giving us the opportunity to push them to get the expected results.      </li>
                            <li> It is recommended to agree with the SOA specific back-up activities to ensure that they can deliver the results expected. For instance, if results are below the expectation after a month, the SOA can develop some tactic with no cost for Lilly to ensure the results committed.  </li>
                            <li> The cost quote should cover Key Performance Indicators (KPI) per activity. Please refer to {' '}
                                <Link 
                                  to={webConfig.SOANavigatorHome}
                                  target="_blank"
                                >  SOA-Navigator - Home (lilly.com)</Link>{' '} to see the expected metrics from different SOA materials.  </li>
                          </ul>
                          <p className="flowsHeading">Remember! </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> Sometimes it is not possible to have the information (exceptional cases). In those cases, use a conservative standard benchmark like:  </li>
                            <ol>
                              <li>OPEN RATE for emails – 20%  </li>
                              <li>Click Through Rate (CTR) for emails – 3%  </li>
                              <li>CTR for banners – 0.4%   </li>
                            </ol>  
                          </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect4"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">When Selecting a New Vendor </p>
                          <p className="flowsContent">Contact the OCE Manager if there is a business reason to work with a New (outside the preferred Vendor list) Third Party SOA. </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> New Third Party Vendors must be approved through the Lilly Working with Third Parties Tool (WWTP). Initiate engaging with Third Party process to complete the WWTP.  </li>
                            <li> Before any Lilly company sensitive information is shared with a potential New Supplier, the Material Owner must ensure that a Confidentiality Agreement is in place {' '}
                                (<Link 
                                  to={webConfig.LillyNDAtemplate}
                                  target="_blank"
                                >  Lilly NDA template.doc</Link>).</li>
                            <li> New Third Party SOA must be added to the NE Hub Vendor list. The Material Owner must contact Procurement to get approval to add the New Vendor, start the Supplier Addition Process by getting the Supplier to complete this form {' '} 
                                (<Link 
                                  to={webConfig.SupplierInformationForm}
                                  target="_blank"
                                > Supplier-Information-Form---Z2.docx (lilly.com)</Link>) and include the Vendor to the Vendor list.</li>
                          </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect5"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Risk Assessment  </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> The Campaign Owner must check the risks related to the SOA campaign materials {' '}
                                <Link 
                                  to={webConfig.ThirdPartyRiskChecklist}
                                  target="_blank"
                                >Third Party Risk Checklist (lilly.com)</Link>. Contact Procurement if the assessments reveal risk or to take support.  </li>
                            <li> Please note that involvement of the Procurement team would be needed for all 
                                <strong> cases above the threshold and for all cases irrespective of the contract value where sharing/working on our behalf with Confidential Information (CI) or Personal Information (PI) is involved.</strong> </li>
                            <li> Involvement of the Procurement is NOT needed if the SOA has been used in the affiliate before (by any brand) for the type of tactic they undertake.  </li>
                          </ul>
                          <p className="flowsHeading">For Example:</p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> The Rheumatology brand team has already worked with M3 on a microsite and wants to do it again. <strong>Risk Assessment is NOT needed here.</strong> </li>
                            <li> The Diabetes Team has never worked with M3 before, but they see the effectiveness of the Rheumatology Microsite campaign and want to do the same. <strong>Risk Assessment is NOT needed here. </strong> </li>
                            <li> Diabetes wants to do a webinar with M3 that will be hosted on M3’s site. No one in Lilly UK has done it before. <strong>Risk Assessment is NEEDED here.</strong> </li>
                            <li> The Oncology team wants to work with a New SOA Vendor which is not on the Lilly preferred Vendor list. In such a case <strong>Risk Assessment is NEEDED</strong>, in addition to completing the Working with Third Parties process (
                              <Link 
                                  to={webConfig.ElilillyssoAravo}
                                  target="_blank"
                                >https://elilillysso.aravo.com</Link>) and following the Procurement process. </li>
                          </ul>
                          <p className="flowsHeading">REMEMBER!  </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> The Material Owner should not commit to any work with the Third Party SOA before completing Risk Assessments and until an Agreement or Purchase Order (PO) is in place. </li>
                          </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect6"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Threshold Check </p>
                          <p className="flowsContent">The documentation and steps that are required to be in place when engaging with the SOA is mentioned below: </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> The Procurement engagement threshold is £25K/$30K/€30k for the Northern European Hub. If the program's total cost exceeds it, the Material Owner should include the Procurement in the buying process as early as possible. </li>
                            <li>
                                 The Campaign Owner does NOT have to inform Procurement if: 
                                <ul>
                                  <li>the total cost is below the threshold limits and if an existing Supplier available in System Application and Products (SAP) App in that country is to be used. </li>
                                  <li>
                                    there is no risk associated (i.e. PI exchange) with the campaign; subject to {' '}
                                      <Link 
                                        to={webConfig.ThirdPartyRiskChecklist}
                                        target="_blank"
                                      >Third Party Risk Checklist (lilly.com) </Link></li>
                                </ul>
                            </li>
                          </ul>  
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect7"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Involving Procurement</p>
                          <p className="flowsContent">There are three types of contracts Lilly uses while buying goods/services: </p>
                          <ol className="ul_padding ul_margin_bottom flowsContent">
                            <li> Work Order (under a Master Services Agreement [MSA]): </li>
                            <ul className="ul_list px-3">
                              <li>a. It is used when there is an MSA in place. </li>
                              <li>b. It is used for projects which are above the threshold or contain some risk element that requires specific contract wording.  </li>
                            </ul>
                            <li>Single Project Agreement: </li>
                            <ul className="ul_list px-3">
                              <li>a. It is used when there is no MSA in place. </li>
                              <li>b. It is used for projects above the threshold or with some risk element that requires specific contract wording.  </li>
                            </ul>
                            <li>Purchase Order (PO):   </li>
                            <ul className="ul_list px-3">
                              <li>a. It is required for <strong>all</strong> purchases to enable invoicing/payment. </li>
                              <li>b. It can be used as the sole contract for low-value and/or low-risk projects.   </li>
                            </ul>
                          </ol>  
                          <p className="flowsHeading">REMEMBER!  </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> Either a Work Order (under an MSA) or a Single Project Agreement must be signed by both the Vendor and a valid contract signatory for the Lilly affiliate buying the goods/services for the Business Owner to be able to raise a PO.  </li>
                          </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect8"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Finalising Work Order With SOA  </p>
                          <p className="flowsContent">If the campaign is of low value/low risk, then the only contract in place will be the PO. For all contracts to be signed, Authority to Spend (ATS) must be received from a Business Owner with an appropriate ATS level, and Authority to Contract (ATC) from a Procurement representative with an appropriate level of ATC. </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> The SOA should be informed that we expect the campaign metrics monthly via the{' '} 
                                <Link
                                  to={webConfig.KPItemplate}
                                  target="_blank"
                                >
                                  KPI template.xlsx (lilly.com)
                                </Link>
                            </li>
                            <li>
                                 For further help on how it all ties together, scroll up to see the Third Party SOA Engagement Checklist.  
                            </li>
                          </ul>  
                          <p className="flowsHeading">REMEMBER!  </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li>  The expected metrics/KPIs must be captured in the Procurement documents (Work Order or Single Project Agreement) to follow the program's success.  </li>
                            <li>  Also, pay close attention to the program cancelation terms and the payment schedule, ensuring we pay milestones after the deliverables have been received. </li>
                          </ul>        
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect9"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Campaign owner raises PO   </p>
                          <p className="flowsContent">
                            Quote to be included in the PO request {' '}
                            <Link
                              to={webConfig.AribaGuidedBuying}
                              target="_blank"
                            >
                              Ariba Guided Buying
                            </Link>
                          </p>
                          <p className="flowsContent">Campaign should be added to the CET if not already there</p>
                                 
                        </div>
                      </div>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            
            {/*End Lifecycle */}
            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Role </th>
                        <th>Responsibility</th>
                        </tr>
                    </thead>  
                    <tbody>
                      <tr>
                         <td className="tb">
                              <h6>SOA Lead</h6>
                          </td>
                          <td className='tb'>
                            <ul className="ul_list">Responsible for:
                                <li>1. Overall reach and capability of SOA across the Hub.</li>
                                <li>2.  Must be consulted as well as procurement when working with new SOA</li>
                            </ul>
                          </td>
                      </tr>
                      <tr>
                         <td className="tb">
                              <h6>Campaign Owner (Brand Manager or OCE Manager) </h6>
                          </td>
                          <td className='tb'>
                            <ul className="ul_list">Responsible for:
                                <li>1. Acting as main point of contact for the SOA campaign.</li>
                                <li>2. Ensuring involvement of SOA lead and procurement where appropriate</li>
                                <li>3. Managing approval process </li>
                                <li>4. Updating CET and reporting campaign results to SOA lead </li>
                                <li>5. Withdrawal </li>
                            </ul>
                          </td>
                      </tr>
                      <tr>
                         <td className="tb">
                              <h6>Procurement </h6>
                          </td>
                          <td className='tb'>
                            <ul className="ul_list">Responsible for:
                                <li>1. Contracting process and negotiation for any activity over &gt;25k.</li>
                                <li>2. Risk assessment</li>
                            </ul>
                          </td>
                      </tr>
                      <tr>
                         <td className="tb">
                              <h6>DST</h6>
                          </td>
                          <td className='tb'>
                            <ul className="ul_list">Responsible for:
                                <li>1. Artwork as per material originator brief (if not using SOA or agency) </li>
                                <li>2. Creating HTML package as per material originator brief and providing to the originator to share with the SOA</li>
                            </ul>
                          </td>
                      </tr>
                      <tr>
                         <td className="tb">
                              <h6>Veeva Owner</h6>
                          </td>
                          <td className='tb'>
                              <p>
                              The Veeva Owner is also known as the Uploader (please see 'Veeva uploader') 
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator</h6>
                          </td>
                          <td>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Having overall responsibility and accountability for the material</li>
                                  <li>2. Ensure that no item is used without the necessary approvals</li>
                                  <li>3. Ensuring timely change and/or withdrawal, where needed</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a>
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Uploader</h6>
                          </td>
                          <td>
                              <p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA
                                  teams, and is assigned the Veeva Owner role.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Uploading the material to Veeva</li>
                                  <li>2. Performing all activities related to upload</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Co-ordinator</h6>
                          </td>
                          <td>
                              <p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager (or similar
                                  role). </p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Reviewing the item before sending for MLR review</li>
                                  <li>2. Managing the document workflow</li>
                                  <li>3. Assigning reviewers</li>
                                  <li>4. Managing expiry or periodic review</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Business Reviewer</h6>
                          </td>
                          <td>
                              <p>Business Reviewer is an experienced commercial employee.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring all promotional materials align with the brand strategy, Lilly Brand Book, Product Brand
                                      Book</li>
                                  <li>2. Ensuring all materials are of good quality, have correct Veeva metadata and adhere to internal
                                      policies and local codes</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Medical Certifier</h6>
                          </td>
                          <td>
                              <p>Registered medical practitioner or UK-registered Pharmacist.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring that the material is in accordance with the requirements of the local code</li>
                                  <li>2. Ensuring that the material is not inconsistent with the marketing authorisation/SmPC (if applicable)
                                  </li>
                                  <li>3. Ensuring that the material is a fair and truthful representation of the facts about the medicine (if
                                      applicable)</li>
                                  <li>4. Approving the material in its final form</li>
                                  <li>5. Ensuring the material has the appropriate reviewers and that the Veeva metadata is correct.</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for ensuring that the Veeva metadata is correct and that the PMC
                                  documents are included (if applicable).</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordic Code Approver</h6>
                          </td>
                          <td>
                              <p>Code Approver must be a medical practitioner or a pharmacist (except for in Sweden) and is responsible for
                                  ensuring that the material is in line with the local Codes and that the material being approved has the
                                  appropriate reviewers; and ensuring that the Veeva metadata is correct.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordics Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for reviewing the material from a medical perspective.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Indegene</h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1. Creating artwork as per material originator brief</li>
                                  <li>2. Creating formatted PI PDFs</li>
                              </ul>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>What it Stands for </strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                    Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Stand</td>
                    <td className="tb"></td>
                    <td className="tb">
                       Designated space purchased by Lilly for a congress or event. This space can feature a stand with promotional imagery and pull-up banners.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Booth</td>
                    <td className="tb"></td>
                    <td className="tb">
                       Designated space purchased by Lilly for a congress or event. This space can feature a stand with promotional imagery and pull-up banners.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool </td>
                    <td className="tb">Planning and execution tools combine to form the CET. With CET, customer plans can be created and campaigns can be managed in one place. </td>
                  </tr>
                  <tr>
                    <td className="tb">Conversion Metric </td>
                    <td className="tb"></td>
                    <td className="tb">Metrics used to measure the number of valuable actions customers take through a digital marketing channel. </td>
                  </tr>
                  <tr>
                    <td className="tb">CSA </td>
                    <td className="tb">Control Self Assessment </td>
                    <td className="tb">A quality check performed by process owners quarterly. The results are shared and discussed with HLT with measures put in place to mitigate any identified issues.  </td>
                  </tr>
                  <tr>
                    <td className="tb">CTA  </td>
                    <td className="tb">Call to Action </td>
                    <td className="tb">The next step a Marketer wants its audience or reader to take. For example, a button or video in an email that the recipient can click on.</td>
                  </tr>
                  <tr>
                    <td className="tb">CTOR  </td>
                    <td className="tb">Click-through-to-open rate  </td>
                    <td className="tb">Email conversion metric used to review the effectiveness of the email content. This measures the number of people who clicked on an email link to the total number of people who opened the email. </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey </td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>
                  <tr>
                    <td>DST</td>
                    <td>Digital Solutions Team</td>
                    <td>Team of content developers dedicated to digital asset production in NE Hub.</td>
                  </tr>
                  <tr>
                    <td>Hyperlink </td>
                    <td></td>
                    <td>A link within one digital item to another location, activated by clicking on a highlighted word or image. </td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">Goals or Metrics set behind a campaign and or tactic.</td>
                  </tr>
                  <tr>
                    <td>OCE</td>
                    <td>Omichannel Engagement</td>
                    <td>Omnichannel engagement campaigns engage effectively with the right HCPs and patients at the right time with the appropriate channel mix (e.g. SOA, eP2P and VOD).</td>
                  </tr>
                  <tr>
                    <td>OR</td>
                    <td>Open rate</td>
                    <td>Email conversion metric used to review the effectiveness of the email content. This measures the number of people who opened an email to the total number of people the email was delivered to.</td>
                  </tr>
                  <tr>
                    <td className="tb">PIA</td>
                    <td className="tb">Privacy Impact Assessment </td>
                    <td className="tb">A thorough review and understanding of how Personal data will be processed, stored, managed.</td>
                  </tr>
                  <tr>
                    <td className="tb">PMC</td>
                    <td className="tb">Permissions Managed Content </td>
                    <td className="tb">An image which is owned by an entity other than Lilly and for which permission is given to use under a specifc set of circumstances as defined in a contractural agreement between Lilly and the Content Owner. </td>
                  </tr>
                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"> </td>
                    <td className="tb">Tactics are specific to selected channels to drive customers to reach a piece of content. Tactics are associated with estimated KPI metric (goal) and result reports (actuals).  </td>
                  </tr>
                  <tr>
                    <td className="tb">Touchpoint </td>
                    <td className="tb"></td>
                    <td className="tb">Touchpoints is terminology used to identify the volume and frequency our tactics reach customers. These touchpoints can be deep (often more in-depth pieces of content, more likely to drive behavioural change) or light (may be transactional or driving to 1 specific action or view).  </td>
                  </tr>
                  <tr>
                    <td className="tb">VVPM</td>
                    <td className="tb">Veeva Vault PromoMat</td>
                    <td className="tb">Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems. PromoMats is a Vault application to manage the process of creating, reviewing and approving promotional materials, claims and supporting information.</td>
                  </tr>
                  <tr>
                    <td className="tb">Webpage</td>
                    <td className="tb"></td>
                    <td className="tb">A webpage developed for the purpose of completing a transaction.  Examples:  filling out a form, microsites, splash pages </td>
                  </tr>
                  <tr>
                    <td className="tb">Traffic driver</td>
                    <td className="tb"></td>
                    <td className="tb">A tactic used for promoting another piece of material with the aims of increasing reach of the key content. Traffic drivers can often include emails and banners.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Defination*/}

            {/*View Core Doc*/}
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.SOACoreDoc}
                  target="_blank"
                >
                  SOA document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/*End Core Doc*/}

            {/*Add Faqs*/}
            <div
              className="tab-pane fade"
              id="v-pills-faqs"
              role="tabpanel"
              aria-labelledby="v-pills-faqs-tab"
            >
              <h4 className="h1-font comman_hd">FAQs</h4>
              <div id="FAQ1">
                <Accordion
                  className="faqAccordion"
                  title="What are the important steps while engaging with an SOA?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>There are seven steps while engaging with SOA: </p>
                    <ol>
                      <li>Selecting the SOA</li>
                      <li>Briefing the SOA </li>
                      <li>Conducting Risk Assessments </li>
                      <li>Ensuring correct Procurement procedures are followed </li>
                      <li>Creating materials </li>
                      <li> Managing materials  </li>
                      <li>Withdrawing materials </li>
                    </ol>
                  </div>
                  
                </Accordion>
              </div>
              <div id="FAQ2">
                <Accordion
                  className="faqAccordion"
                  title="How do I know who the best SOA Vendors are to work for my brand or Therapy Area?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>With so many Vendors to choose from, it is important to understand 
                      the answers to the following questions to select the best option 
                      for the campaign. Find the answers to all these questions (and more!) 
                      on SOA Navigator {' '}
                      <Link 
                        to={webConfig.SOANavigatorHome}
                        target="_blank"
                      >
                        SOA-Navigator - Home (lilly.com)
                      </Link>
                    </p>
                    <ul className="ul_padding ul_margin_bottom">
                      <li> Which SOA facilitates what kind of tactics? </li>
                      <li> What is their reach within the Target customer base? </li>
                      <li> What is their reach within the Target customer base? </li>
                      <li> Ensuring correct Procurement procedures are followed </li>
                      <li> What level of customer engagement could be generated? </li>
                      <li> What specification of artwork do they require? </li>
                      <li> What specification of artwork do they require?  </li>
                    </ul>
                  </div>
                  
                </Accordion>
              </div>
              <div id="FAQ3">
                <Accordion
                  className="faqAccordion"
                  title="I am from PRA and/or Medical function, how do I find an SOA relevant to my business requirement and target base?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      SOA Navigator provides {' '}
                      <Link 
                        to={webConfig.SOANavigatorHome}
                        target="_blank"
                      >
                        SOA-Navigator - Home (lilly.com)
                      </Link>{' '} details on Third Party SOA partner for Value 
                      Access Managers (VAMs) and Medical function as well.  
                    </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ4">
                <Accordion
                  className="faqAccordion"
                  title="What is DIM/PIA and when do I need this for SOA campaign?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Data Inventory Management (DIM) or Privacy Impact Assessment (PIA) needs 
                      to be completed if data is being exchanged, stored or processed through SOA 
                      campaign. For any doubt, please get in touch with local E&C when sharing/exchanging 
                      customer data.    
                    </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ5">
                <Accordion
                  className="faqAccordion"
                  title="How do I know if the SOA I want to work with is on the preferred Vendor list? "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                     <Link 
                        to={webConfig.PreferredSupplierList}
                        target="_blank"
                      >
                      Preferred Supplier List.xlsx (lilly.com)
                      </Link>{' '}: You can see the 
                      list of preferred Vendors for NE Hub countries. 
                      Material Owners are encouraged with the Vendor in the list. Alternatively, 
                      discuss this with the Procurement team.  
                    </p>
                  </div>
                  
                </Accordion>
              </div>
              <div id="FAQ6">
                <Accordion
                  className="faqAccordion"
                  title="How do I know if Vendor has completed all the Procurement and compliance requirements?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      If the Vendor is on the Preferred Vendor list for the tactic you are 
                      engaging them for, they would have performed Risk Assessment. 
                      For any clarification, you can either reach out to their 
                      designated Engagement Owner or Procurement. For a New Vendor, 
                      there will need to be an Engagement Owner assigned from Business 
                      (likely to the team that wishes for New Vendor to be onboarded) 
                      and the Engagement Owner will have all the updates on assessments 
                      being completed for the Vendor.    
                    </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ7">
                <Accordion
                  className="faqAccordion"
                  title="My SOA vendor wants to take me for dinner to discuss the campaign. Can I go? "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Probably not. You need to adhere to the Lilly Expenses SOP meal limits, 
                      even though you are not paying for the meal yourself. Refer to the internal policy document.    
                    </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ8">
                <Accordion
                  className="faqAccordion"
                  title="The SOA is committed to meeting certain KPIs, but they haven’t managed to achieve the target goals. What are my options?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                    To ensure that we meet our campaign metrics, it is crucial to follow up on the 
                    performance of the campaign. If the SOA does not meet the expectations, you 
                    should contact the SOA and explore potential alternatives to improve the performance. 
                    Options could be to include an additional traffic driver for the campaign or extend 
                    the campaign period at no extra cost. If the campaign cost is above £25,000, we must 
                    involve the Procurement in our discussion.    
                    </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ9">
                <Accordion
                  className="faqAccordion"
                  title=" SOA is asking for money to be paid in advance. Can I do this? "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Lilly Procurement policy does not support pre-payments.     
                    </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ10">
                <Accordion
                  className="faqAccordion"
                  title=" I need to stop my SOA campaign mid-way through. What are my rights in regard to the refund part of the original Purchase Order (PO)? "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                    You need to review your contract and examine the rights outlined there. 
                    Collaborate closely with the Procurement team to understand your contractual rights.      
                    </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ11">
                <Accordion
                  className="faqAccordion"
                  title="Are SOA emails implicated by the NE Hub email guidance? "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                    Yes. SOA emails must be considered when limiting the number of times, the content is sent to the same customers. 
                    Please refer to the {' '}
                    <Link
                        to={webConfig.NEHubEmailVolumeandGuidance}
                        target="_blank"
                      >
                        NE Hub Email Operations Guidance
                      </Link> {' '}for full details. 
                    </p>
                  </div>
                </Accordion>
              </div>
              
            </div>
            {/*End Faqs*/}
          </div>
        </div>
      </div>

    </div>
  );
};


export default SOA;
