import React, { useRef }from "react";

function Footer() {
  const handleCookiePopup = () => {
    const element1 = document.querySelectorAll('.overlay');
    const element2 = document.querySelectorAll('.popup');
    if (element1[0] && element2[0] ) {
      element1[0].style.visibility = 'visible';
      element1[0].style.opacity = 1;
      element2[0].style.visibility = 'visible';
      element2[0].style.opacity = 1;
    }
  };
  
  return (
    <div id="app-footer" className="bg-footer py-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <img src="/images/logo.png" width="100" />
          </div>
          <div className="col-lg-12 text-center">
            Copyright © 2024 Lilly. All rights reserved.
          </div>
        </div>
        <div id="cookieWidgetLink">
          <a href="#" onClick={handleCookiePopup}>Cookie Settings</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;