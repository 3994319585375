import React, { useState, useRef, useEffect } from "react";
import "../css/VAE.css";
import {webConfig} from '../configs/webConfig'
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/VAE_Phases_SVG/VAE_Phase_1.svg";
import { ReactComponent as Phase2 } from "../SVG/VAE_Phases_SVG/VAE_Phase_2.svg";
import { ReactComponent as Phase3 } from "../SVG/VAE_Phases_SVG/VAE_Phase_3.svg";
import { ReactComponent as Phase4 } from "../SVG/VAE_Phases_SVG/VAE_Phase_4.svg";
import { ReactComponent as Phase5 } from "../SVG/VAE_Phases_SVG/VAE_Phase_5.svg";

const VAE = () => {
  const [isPhase3Open, setIsPhase3Open] = useState();
  const [activeTab, setActiveTab] = useState('processPreRequisites');

  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("CET");

  const svgRef2 = useRef();
  const [P2visibleSection, setP2visibleSection] = useState("Briefing");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("Initiate");

  const svgRef4 = useRef();
  const [P4visibleSection, setP4visibleSection] = useState("Quarterly");

  const svgRef5 = useRef();
  const [P5visibleSection, setP5visibleSection] = useState("Gatekeeper");


  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");

    rect1.addEventListener("click", () => {
      setP1visibleSection("CET");
    });

    rect2.addEventListener("click", () => {
      setP1visibleSection("Pre-Veeva");
    });
  }, []);

  useEffect(() => {
    const svg = svgRef2.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const diam1 = svg.getElementById("diamond1");
    const rect3 = svg.getElementById("rect3");

    rect1.addEventListener("click", () => {
      setP2visibleSection("Briefing");
    });

    rect2.addEventListener("click", () => {
      setP2visibleSection("Artwork");
    });

    diam1.addEventListener("click", () => {
      setP2visibleSection("Expectations");
    });
    const handleScrollP3 = () => {
      const elementToScrollTo = document.getElementById("accordion3");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect3.addEventListener("click", () => {
      handleScrollP3();
      setIsPhase3Open(true);
    });
  }, [isPhase3Open]);

  useEffect(() => {
    const svg = svgRef3.current;
    const rect1 = svg.getElementById("rect1");
    const diam1 = svg.getElementById("diamond1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const diam2 = svg.getElementById("diamond2");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    const rect8 = svg.getElementById("rect8");
    const rect9 = svg.getElementById("rect9");

    rect1.addEventListener("click", () => {
      setP3visibleSection("Initiate");
    });

    diam1.addEventListener("click", () => {
      setP3visibleSection("MlrVerdict");
    });

    rect2.addEventListener("click", () => {
      setP3visibleSection("DST");
    });

    rect3.addEventListener("click", () => {
      setP3visibleSection("InitiateCertification");
    });

    diam2.addEventListener("click", () => {
      setP3visibleSection("CertificationVerdict");
    });
    rect4.addEventListener("click", () => {
      setP3visibleSection("FinalSteps");
    });
    rect5.addEventListener("click", () => {
      setP3visibleSection("AdviseDST");
    });
    rect6.addEventListener("click", () => {
      setP3visibleSection("Approves");
    });
    rect7.addEventListener("click", () => {
      setP3visibleSection("Inform");
    });
    rect8.addEventListener("click", () => {
      //setP3visibleSection("Comment");
    });
    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect9.addEventListener("click", () => {
      handleScrollP2();
    });
  }, []);
  useEffect(() => {
    const svg = svgRef4.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    rect1.addEventListener("click", () => {
      setP4visibleSection("Updated");
    });

    rect2.addEventListener("click", () => {
      setP4visibleSection("Quarterly");
    });

    rect3.addEventListener("click", () => {
      setP4visibleSection("UpdateOfMaterial");
    });
    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect4.addEventListener("click", () => {
      handleScrollP2();
    });
    const handleScrollP5 = () => {
      const elementToScrollTo = document.getElementById("accordion5");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect5.addEventListener("click", () => {
      handleScrollP5();
    });
  }, []);

  useEffect(() => {
    const svg = svgRef5.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    rect1.addEventListener("click", () => {
      setP5visibleSection("Gatekeeper");
    });

    rect2.addEventListener("click", () => {
      setP5visibleSection("Withdraw");
    });

    rect3.addEventListener("click", () => {
      setP5visibleSection("Send");
    });
    rect4.addEventListener("click", () => {
      setP5visibleSection("Veeva");
    });
  }, []);
  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Veeva Approved Emails (VAEs)
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">Veeva Approved Emails (VAEs)</h2>
            <p>
              <em>
              Veeva Approved Email (VAE) is a tool that brings together fully compliant and up-to-date approved content that allows the Sales Representative to start up a one-to-one personalised communication with healthcare professionals (HCPs) to deliver the information that HCPs need and when and how they need it.{" "}
              </em>
            </p>
            <p>
              <em>
              While VAE allows the Sales Representatives to send approved email content directly to customers through iRep, it also enables Brand Managers to manage approved email workflows via Veeva providing full integration between Veeva Customer Relationship Management (CRM) and Veeva Vault.{" "}
              </em>
            </p>
          </div>
        </div>
        <div className="container p-3 mb-5">
          {" "}
          <ul id="tab_panes" className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 'processPreRequisites' ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#processPreRequisites"
                onClick={() => setActiveTab('processPreRequisites')}
              >
                Process
                <br />
                Pre-requisites
              </a>
            </li>
            <li className="nav-item">
              <a 
                className={`nav-link ${activeTab === 'vvpmJobBag' ? 'active' : ''}`} 
                data-bs-toggle="tab" href="#vvpmJobBag" 
                onClick={() => setActiveTab('vvpmJobBag')}>
                VVPM Job Bag Standards
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 'emailvolumeguidance' ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#emailvolumeguidance"
                onClick={() => setActiveTab('emailvolumeguidance')}
              >
                Email Volume Guidance
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 'standardFooters' ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#standardFooters"
                onClick={() => setActiveTab('standardFooters')}
              >
                Standard
                <br />
                Footers
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 'vaedocumentstatus' ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#vaedocumentstatus"
                onClick={() => setActiveTab('vaedocumentstatus')}
              >
                VAE Document Status
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${activeTab === 'vaefreetextfields' ? 'active' : ''}`}
                data-bs-toggle="tab"
                href="#vaefreetextfields"
                onClick={() => setActiveTab('vaefreetextfields')}
              >
                VAE Free Text Fields
              </a>
            </li>
          </ul>
          <div id="tabContent" className="tab-content">
            <div
              id="processPreRequisites"
              className="tab-pane fade in active tabs_box"
              style={{ display: activeTab === 'processPreRequisites' ? 'block' : 'none' }}
            >
              <ul className="ul_padding">
                <li className="tabContent">
                   Process subject to a named ‘Gatekeeper’ within each
                  brand/each affiliate. Please see{" "}
                  <a
                    href={webConfig.GatekeeperTraining}
                    target="_blank"
                  >
                    VAE Gatekeeper Training
                  </a>
                  - At least one individual from the brand team (or Pricing, Reimbursement and 
                    Access [PRA]/Medical function) needs to be assigned as Gatekeeper.
                    They must complete this training before requesting 
                    access to <strong>Veeva Vault</strong> and <strong>PF1</strong> for VAEs.
                </li>
                <li className="tabContent">
                   Process subject to{" "}
                  <strong></strong>
                  <a 
                     href={webConfig.NEHubEmailVolumeandGuidance}
                     target="_blank"
                  >NE Hub Email Guidance Document</a>
                </li>
                <li className="tabContent">
                   Process subject to mandate on{" "}
                  <strong></strong>
                  <a 
                     href="#vaefreetextfields" 
                     onClick={() => setActiveTab('vaefreetextfields')}
                  >VAE Free Text Fields.</a>
                </li>
                <li className="tabContent">
                   The{" "}
                  <a
                    href={webConfig.VAEBriefingGuideStandardTemplate}
                    target="_blank"
                  >
                    VAE Briefing Guide Standard Template
                  </a>{" "}
                  must be used.
                </li>
              </ul>
              <p className="tabsContentHeading">UK</p>
              <p className="tabContent">
                Process subject to additional material being approved, for example
                Internal Briefing Guide for Customer Facing Teams (for each new
                VAE template and each new VAE fragment).
              </p>
            </div>
            <div 
              id="vvpmJobBag" 
              className="tab-pane fade tabs_box"
              style={{ display: activeTab === 'vvpmJobBag' ? 'block' : 'none' }}
            >
              <p className="tabsContentHeading">Name:</p>
              <p className="tabContent">
              The naming convention from the {" "}<a href={webConfig.PMAPJobAid} target="_blank">PMAP Job Aid</a>{" "}
                section 6.3 (a) must be followed.
              </p>
              <table className="table">
                <thead></thead>
                <tbody>
                  <tr>
                    <td className="tabContent text-center">
                      <strong>Country Code</strong>
                      <br /> 
                      (UK, IE, SE etc)
                    </td>
                    <td className="tabContent text-center">
                      <strong>Therapy Area/ Product Indication/ Function</strong>
                      <br />
                      (i.e., Jardiance) 
                    </td>
                    <td className="tabContent text-center">
                      <strong>Material</strong> <br /> (a brief description)
                    </td>
                    <td className="tabContent text-center">
                      <strong>Date</strong> <br />(month and year of preparation)
                    </td>
                    <td className="tabContent text-center">
                      <strong>Job Bag Code</strong> <br />(Optional)
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="tabContent">
              For example: <em>‘UKIE Taltz PSA_Patient Brochure_APR23’</em>
              </p>
              <p className="tabsContentHeading">Title:</p>
              <p className="tabContent">
              The title of the VAE must be the exact same as the title on the Veeva CRM asset.
              </p>
              <p className="tabsContentHeading">Purpose:</p>
              <ul className="ul_padding">
                <li className="tabContent"> Explain the purpose </li>
                <li className="tabContent">
                   Clarify that the HTML version including subject line(s) will be added to the Attachments section at certification.
                </li>
                <li className="tabContent">
                   Test the link and log in to view the online campaigns (if linking to another page).
                </li>
                <li className="tabContent">
                   The purpose field should state the frequency of the email distribution (which has to be in line with the Email volume guidance document) 
                </li>
              </ul>
              <p className="tabsContentHeading">Attachments:</p>
              <ul className="ul_padding">
                <li className="tabContent">Live/HTML version including subject line(s).</li>
              </ul>

              <p className="tabsContentHeading">Linked Documents:</p>
              <ul className="ul_padding">
                <li className="tabContent">
                   Content references (if applicable){" "}
                </li>
                <li className="tabContent" > <strong>UK (New)</strong> - Certified version of the current PI</li>
                <li className="tabContent">
                   <strong>UK and ROI</strong> VAE Briefing guide must be attached under Supporting Documents.{" "}
                </li>
                <li className="tabContent">
                   ET/EF must be linked together in ‘Relationships’ under MLR
                  Approved Piece’{" "}
                </li>
              </ul>

              <p className="tabsContentHeading">
                Related Permission Managed Content:
              </p>
              <ul className="ul_padding">
                <li className="tabContent">
                   Include required Permission Managed Content (PMC) documents.
                </li>
                <li className="tabContent">
                   PMC is not required for <strong>Lilly product images</strong>. Check with
                  Regulatory or Quality Assessment (QA) if it is the correct device image.
                </li>
              </ul>

              <p className="tabsContentHeading">Final Form:</p>
              <ul className="ul_padding">
                <li className="tabContent">
                   Live version including subject lines. Note that VAEs do not have capability to include pre-headers.
                </li>
                <li className="tabContent">
                   Ensure that the source file package (HTML etc.) is saved in the Renditions Query.
                </li>
              </ul>

              <p className="tabsContentHeading">Inventory Information:</p>
              <ul className="ul_padding">
                <li className="tabContent">
                   Mandatory field – Add distribution location as ‘Veeva CRM’ for VAEs.{" "}
                </li>
              </ul>

              <p className="tabsContentHeading">Audience:</p>
              <ul className="ul_padding">
                <li className="tabContent">
                   HCP with explicit consent will be the Audience.
                </li>
              </ul>

              <p className="tabsContentHeading">FINLAND</p>
              <p className="tabContent">
              Nurses should never be included as part of the Audience for promotional communication as they are considered as general public.{" "}
              </p>
            </div>
            <div 
              id="emailvolumeguidance" 
              className="tab-pane fade tabs_box"
              style={{ display: activeTab === 'emailvolumeguidance' ? 'block' : 'none' }}
            >
              <p className="tabContent">
              Email is one of the channels used to keep customers up to date with the latest news and events from Lilly. It is one of the most{" "}
                <strong>
                cost-effective ways of reaching HCPs and one of the key channels to drive them to deep engagement interactions.
                </strong>{" "}
                 HCPs have the benefit of receiving and reading the information at a time convenient to them.
              </p>
              <p className="tabContent">
              While there is a clear benefit of sending information through emails, {" "}
                <strong>
                awareness of Local Code, General Data Protection Regulation (GDPR), Data Privacy and Safety Requirements are also needed by not sending an overwhelming number of emails
                </strong>{" "}
                their way.
              </p>
              <p className="tabContent">
              Lilly has some procedures in place to ensure that it operates with excellence in this channel.
              </p>
              <p className="tabContent">
                Click to see{" "}
                <a
                  href={webConfig.EmailVolumeGuidance}
                  target="_blank"
                >
                  Email Volume Guidance
                </a>{" "}
                 – applicable for VAE, Broadcast Email (BE) and Source of Authority (SOA) emails.{" "}
              </p>
            </div>
            <div 
              id="standardFooters" 
              className="tab-pane fade tabs_box"
              style={{ display: activeTab === 'standardFooters' ? 'block' : 'none' }}
            >
              <p className="tabContent">
                The NE Hub has{" "}
                <a
                  href={webConfig.StandardFooter}
                  target="_blank"
                >
                  Standard Footers
                </a>{" "}
                 for each affiliate within the Hub. These have been translated to local languages and reviewed by Ethics and Compliance (E&C) and Medical Reviewers and Legal. These footers have been tailored for{" "}
                <strong>
                use in promotional messages, non-promotional messages and apply to BE, VAE and SOA emails.
                </strong>
              </p>
              <p className="tabContent">
              Approved wordings and all the advised formatting should be used. 
              </p>
              <p className="tabContent">
                Please note{" "}
                <a
                  href={webConfig.JardianceFooter}
                  target="_blank"
                >
                  Jardiance Footer
                </a>{" "}
                requirements are different to the standard.
              </p>
            </div>
            <div 
              id="vaedocumentstatus" 
              className="tab-pane fade tabs_box"
              style={{ display: activeTab === 'vaedocumentstatus' ? 'block' : 'none' }}
            >
              <p className="tabsContentHeading">Draft:</p>
              <p className="tabContent">
              The content is originally uploaded in a draft state on Veeva.
              </p>
              <p className="tabsContentHeading">Staged:</p>
              <p className="tabContent">
              Allows the capability to view the approved email in Veeva CRM Sandbox/PF1, where  emails can be tested for certification.
              </p>
              <p className="tabsContentHeading">Approved:</p>
              <p className="tabContent">
              Content has reached ‘Approved’ after Gatekeeper approval – VAE is now live and available to be pushed to Customer-Facing teams.
              </p>
              <p className="tabsContentHeading">Cancelled:</p>
              <p className="tabContent">
              The Veeva workflow has been cancelled. The material is still available in VVPM for it to be copied if needed.
              </p>

              <p className="tabsContentHeading">Withdrawn:</p>
              <p className="tabContent">
              Content that is withdrawn is removed from Production and is not available to the Customer-Facing teams. Use <strong>‘Withdraw’</strong> to
              temporarily remove, make updates that do not impact final form of the piece (e.g., amending a broken link) and push live again.
                <strong>Updates that require amending the content or final form will require expiring the ET/EF</strong> and re-initiating the
                VAE process.
              </p>

              <p className="tabsContentHeading">Expired:</p>
              <p className="tabContent">
              Content that is expired is no longer visible in either staging or production environments. Use <strong>‘Expire’</strong> to permanently remove a VAE from production.
              </p>
            </div>
            <div 
              id="vaefreetextfields" 
              className="tab-pane fade tabs_box"
              style={{ display: activeTab === 'vaefreetextfields' ? 'block' : 'none' }}
            >
            <p className="tabsContentHeading">Mandate on VAE Free Text Fields</p>
              <table className="table table-striped">
                <thead></thead>
                <tbody>
                  <tr>
                    <td className="tabsContentHeading text-center">
                      VAE ELEMENT
                    </td>
                    <td className="tabsContentHeading text-center">
                      APPROVED SOLUTION
                    </td>
                  </tr>
                  <tr>
                    <td className="tabContent text-center">Subject Line</td>
                    <td className="tabContent text-center">
                      Fixed text
                    </td>
                  </tr>
                  <tr>
                    <td className="tabContent text-center">
                      Salutation (or any other token field)
                    </td>
                    <td className="tabContent text-center">
                      Fixed text or drop down menu
                    </td>
                  </tr>
                  <tr>
                    <td className="tabContent text-center">Speaker Name</td>
                    <td className="tabContent text-center">
                      Free text (80 characters MAX)
                    </td>
                  </tr>
                  <tr>
                    <td className="tabContent text-center">Location</td>
                    <td className="tabContent text-center">
                      Free text (80 characters MAX)
                    </td>
                  </tr>
                  <tr>
                    <td className="tabContent text-center">
                      Date (DD:Month:Year)
                    </td>
                    <td className="tabContent text-center">
                      Drop down menu (1-31 Date); Drop down menu (List
                      of months); Drop down menu (2019,2020,2021 Year)
                    </td>
                  </tr>
                  <tr>
                    <td className="tabContent text-center">Time</td>
                    <td className="tabContent text-center">
                      Drop down menu (1-12 Hour); Drop Down menu (00;15;30;45
                      minutes); Drop Down menu AM/PM
                    </td>
                  </tr>
                  <tr>
                    <td className="tabContent text-center">Links</td>
                    <td className="tabContent text-center">
                      Free text (80 characters MAX)
                    </td>
                  </tr>
                  <tr>
                    <td className="tabContent text-center">RSVP</td>
                    <td className="tabContent text-center">
                      Please RSVP by replying to this email/CTA button
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
            <button
              className="nav-link"
              id="v-pills-faqs-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-faqs"
              type="button"
              role="tab"
              aria-controls="v-pills-faqs"
              aria-selected="false"
            >
              FAQs
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">VAE Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Planning"
                    defaultOpen={true}
                  >
                    {" "}
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-4 planning">
                        <div className="comman_width_e">
                          <Phase1 ref={svgRef1} className="VAEshape" />
                        </div>
                      </div>
                      <div className="col-lg-8 planning p_font pt-3 flowsSecondPart">
                        <div
                          id="campaign_listed_cet"
                          style={{
                            display:
                              P1visibleSection === "CET" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">CAMPAIGN LISTED ON CET</p>
                          <p className="flowsContent">
                            Campaigns should be listed on CET as part of IPA
                            process. Check if this material is part of your
                            plans, review the set KPIs and tactic links on CET.{" "}
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "Pre-Veeva"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">PRE-VEEVA MEETING</p>
                          <p className="flowsContent">
                            Planning is everything! The core objectives of a
                            pre-Veeva discussion should not be overlooked.These
                            are to ensure that:
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               The reviewer is never presented with a material in Veeva Vault PromoMats (VVPM) that they have no background information on, and{" "}
                            </li>
                            <li className="flowsContent">
                               The review team understands its current priorities for review.
                            </li>
                          </ul>
                          <p className="flowsContent">Together these aim to
                              provide a smoother and quicker approval process
                              for materials.</p>
                          <p className="flowsContent">Visit{" "}
                              <a href={webConfig.LillyPlanning} target="_blank">
                                Planning (lilly.com)
                              </a>{" "}
                              to learn more about pre-Veeva meetings.</p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion2" />
                  <Accordion
                    className="accordion2"
                    title="Phase 2: Material Readiness"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                        <div className="comman_width">
                          <Phase2 ref={svgRef2} className="VAEshape2" />
                        </div>
                      </div>
                      <div className="col-lg-7 planning pt-4 flowsSecondPart">
                        <div
                          id="briefing"
                          style={{
                            display:
                              P2visibleSection === "Briefing"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">BRIEFING</p>
                          <p
                            className="pop flowsContent"
                            style={{ marginBottom: "0px" }}
                          >
                            <p className="flowsContent">
                              Ensure that the team working on material
                              development fully understands the brief. Often a
                              call is best way to move things forward quickly. A
                              good brief to the agency means that the Material
                              Administrator provides all necessary visuals, texts
                              and links upfront. Briefing could be completed by
                              Material Administrator. The creative agency should
                              advise on delivery timelines after the briefing is
                              received.{" "}
                            </p>

                            <ul className="ul_padding ul_margin_bottom">
                              <li className="flowsContent">
                                 The Digital Solutions Team (DST) is the preferred partner for working on emails. Briefing must be provided to them using the template provided through their{" "}
                                <a 
                                  href={webConfig.DSTRequestPowerApp} 
                                  target="_blank">
                                  DST Request PowerApp
                                </a>
                              </li>
                              <li className="flowsContent">
                                 The NE Hub has a{" "}
                                <a 
                                  href={webConfig.StandardFooterTemplate} 
                                  target="_blank">
                                  Standard Footer Template library
                                </a>{" "}
                                already built in for each affiliate.
                                When completing a DST request, choose the correct footer from the drop-down.
                              </li>
                            </ul>
                            <p className="flowsContent">
                            No other agency is trained or approved to develop HTML versions or run test emails for VAE across the NE Hub. On a rare occasion the asset might need artwork to be designed and for the design part only, any Lilly preferred vendor for creative design could be used. The artwork file along with the source file must be passed onto the DST for HTML production.
                            </p>
                          </p>
                          <p className="flowsHeading briefingRemember">
                            Remember
                          </p>
                          <p className="flowsContent">
                            VAEs require additional metadata fields to be specified on the asset such as <strong>Salesforce Code, Email Template and Email Fragments (ET/EF) Codes</strong>,
                            etc – Please include all the information when
                            submitting the brief to DST.
                          </p>
                        </div>
                        <div
                          id="artwork_delivered"
                          style={{
                            display:
                              P2visibleSection === "Artwork" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">ARTWORK DELIVERED</p>
                          <p className="flowsContent">
                          It is the responsibility of the Material Administrators to review the artwork provided for accuracy before submitting to Medical, Legal, Regulatory (MLR) for approval. {" "}
                          </p>
                          <p className="flowsHeading">REMEMBER</p>
                          <ul className="ul_padding">
                            <li className="flowsContent">
                               <strong>Subject Line</strong> and/or{" "}
                              <strong>Pre-Header</strong> must be included
                              within the PDF (and not to be added in Purpose field).
                            </li>
                            <li className="flowsContent">
                               Delivery Timeline – The DST has an agreed timeline for the artwork PDF (4 business days). If there are modifications to be made vs. the original brief, do factor in more days to expected delivery time.
                            </li>
                          </ul>
                        </div>
                        <div
                          id="meets_expectations"
                          style={{
                            display:
                              P2visibleSection === "Expectations"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            ARTWORK MEETS EXPECTATIONS?
                          </p>
                          <p className="flowsContent">If the artwork meets expectations, then initiate MLR. If the artwork does not meet expectations, then it is always advised to book a call with the agency to go through the changes required.</p>
                          <p className="flowsContent">(Back-and-forth through email communication has proven to be time consuming!) </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion3" />
                  <Accordion
                    className="accordion3"
                    title="Phase 3: Material Approval & Launch"
                    defaultOpen={false}
                    isOpen={isPhase3Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-8 planning main-content">
                        <Phase3 ref={svgRef3} className="VAEshape3" />
                      </div>
                      <div className="col-lg-4 planning flowsSecondPart side-content">
                        <div
                          id="link4"
                          style={{
                            display:
                              P3visibleSection === "Initiate"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">INITIATE MLR APPROVAL</p>
                          <p className="flowsContent">
                          This is the stage where the Veeva Coordinator uploads material to VVPM for reviewers to provide a verdict on it. Scroll up to see the VVPM Job Bag Standards.
                          </p>

                          <p className="flowsHeading">REMEMBER</p>
                          <ul className="ul_padding">
                            <li className="flowsContent">
                               <strong>Subject line</strong> must be included within the PDF file for MLR approval. 
                            </li>
                            <li className="flowsContent">
                               If creating a VAE fragment, the applicable VAE template should be identified in <strong>'Related Pieces'</strong> in Veeva. 
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link5"
                          style={{
                            display:
                              P3visibleSection === "MlrVerdict"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">MLR VERDICT</p>
                          <p className="flowsContent">
                          There are three types of verdicts that the material could get:{" "}
                          </p>
                          <p className="flowsContent">
                            <strong>1. Approved for Production</strong> – The material has been blessed by the reviewers. Go ahead and ask for the material to be developed into final format.
                          </p>
                          <p className="flowsContent">
                            <strong>2. Approved with changes</strong> – The material has some minor errors/updates required (like spelling, grammatical errors). Incorporate the advised updates/corrections on the material and ask for the material to be developed into final format.{" "}
                          </p>
                          <p className="flowsContent">
                            <strong>3. Revise and Resubmit</strong> – The material has too many problems and the reviewer advises the Veeva Coordinator to rework on it and submit again. Go ahead and ask the agency to re-do the artwork. 
                          </p>
                        </div>
                        <div
                          id="link6"
                          style={{
                            display:
                              P3visibleSection === "DST" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            DST DELIVERS HTML PACKAGE
                          </p>
                          <p className="flowsContent">
                          The DST will produce and deliver the HTML and test email along with the ET/EF number.
                          </p>
                          <p className="flowsContent">
                          Note that the DST is the only approved partner for NE Hub to produce HTML for emails. If the artwork file was created through an agency, now is the time to provide the source file of artwork to the DST!{" "}
                          </p>
                          <ul className="ul_padding">
                            <li className="flowsContent">
                               <strong>ET/EF</strong> – They are the parts/types of VAE. It is important to understand the distinction. Refer to the VAE{" "}<a href={webConfig.GatekeeperTraining} target="_blank">Gatekeeper Training</a>{" "}Deck to understand more.{" "}
                            </li>
                            <li className="flowsContent">
                               The DST creates the VAE HTML and generates an ET/EF number, which is different from a VVPM job bag code. The DST Manager working on the material will add the job bag code under the ET/EF Number metadata in VVPM. 
                            </li>
                            <li className="flowsContent">
                               Veeva Coordinators <strong>must link the job bag code and ET/EF Number</strong> on VVPM by inserting the ET/EF number to the job bag’s <strong>‘Supporting Documents’</strong> section in VVPM metadata (this helps with compliant withdrawal).
                            </li>
                            <li className="flowsContent">
                               Once the HTML is produced, the DST will send a test email to the person named on the DST request template. Test emails will display how the subject line, email body and footer will be displayed to the recipient. This is the final form that is to be submitted for certification.{" "}
                            </li>
                          </ul>
                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">
                            Delivery Timeline - The DST has an agreed delivery timeline of 4 working days to produce HTML from the PDF content. Plan to avoid delays, especially if changes are to be made.
                          </p>
                        </div>
                        <div
                          id="link7"
                          style={{
                            display:
                              P3visibleSection === "InitiateCertification"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">INITIATE CERTIFICATION</p>
                          <p className="flowsContent">
                            This is the stage where Veeva Coordinators upload final form of the material to VVPM for Certifiers to provide a verdict on the material.
                          </p>
                          <p className="flowsHeading">Remember</p>
                          <ul className="ul_padding">
                            <li className="flowsContent">
                               The test email should be uploaded on VVPM to{" "}
                              <strong>‘Attachments’</strong>.{" "}
                            </li>
                            <li className="flowsContent">
                               Material Administrators should check the
                              Salesforce code is added correctly so that it is
                              only available to the relevant team.{" "}
                            </li>
                          </ul>
                          <p className="flowsHeading">UK</p>
                          <p className="flowsContent">
                            Please remember the need for the certification of a briefing guide (for Customer-Facing teams).  
                          </p>
                        </div>
                        <div
                          id="link8"
                          style={{
                            display:
                              P3visibleSection === "CertificationVerdict"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CERTIFICATION VERDICT</p>
                          <p className="flowsContent">
                          There are three types of verdicts that the material may get:
                          </p>
                          <p className="flowsContent">
                            <strong>1. Certified</strong> – The material is Approved for Distribution now. Go ahead to review/set Inventory Information on VVPM and set the{" "}
                            <strong>Actual Date of First Use</strong>.{" "}
                          </p>
                          <p className="flowsContent">
                            <strong>2. Revise and Resubmit </strong> (for
                            certification) – The material has errors and/or requires updates. Please request the DST to make the advised changes, trigger a new test email and re-submit for certification.
                          </p>
                          <p className="flowsContent">
                            <strong>3. Not Certified</strong> – The material has too many problems and the Certifier advises the Material Administrator to rework on it and start a new MLR workflow. Go ahead and ask the agency to re-do the artwork.
                          </p>
                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">The verdict <strong>‘Certified’</strong> does not mean that the material is now live. Additional steps are needed to move it to the environment where it will be available to the end user.</p>
                        </div>
                        <div
                          id="link9"
                          style={{
                            display:
                              P3visibleSection === "FinalSteps"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            FINAL STEPS BEFORE 'GO-LIVE'
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               <strong>Actual Date of First Use</strong> is set
                              by Veeva Coordinators and is typically put as the
                              date the material has been certified in VVPM,
                              unless there’s a specific date of when the usage of a
                              material will start (for example launch date,
                              after the salesforce training etc.)
                            </li>
                            <li className="flowsContent">
                              <strong> Inventory Information</strong> – Veeva Coordinators would have added this earlier to VVPM metadata and it should be checked when the material goes live. This step will support efficient material management and withdrawal.{" "}
                            </li>
                            <li className="flowsContent">
                               The <strong>Expiration Date</strong> is usually
                              set for 2 years. However, if the material is classified in the metadata as ‘One-time use’ or for a defined time, set the expiration date accordingly.
                            </li>
                            <li className="flowsContent">
                               <strong>Related Pieces</strong> – Veeva Coordinators should check that any relevant related pieces are linked to the VVPM job bag.
                            </li>
                          </ul>
                          <p className="flowsHeading">Remember</p>
                          <ul className="ul_padding">
                            <li className="flowsContent">
                               The <strong>Expiration Date</strong> for ET/EFs
                              should match the Expiration Date of the piece.{" "}
                            </li>
                            <li className="flowsContent">
                               List all the fragment titles associated with the
                              VAE template and add any{" "}
                              <strong>Related Pieces</strong>.{" "}
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link10"
                          style={{
                            display:
                              P3visibleSection === "AdviseDST"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            ADVISE DST TO PUSH ASSET FOR VAE GATEKEEPER APPROVAL
                          </p>
                          <p className="flowsContent">
                          The DST does not have visibility over VVPM approval status. It is the Material Administrator’s responsibility to inform the DST that an asset has been certified. Ask the DST to push the asset for VAE Gatekeeper approval.
                          </p>

                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">
                          The access right required for an IVA Gatekeeper will be different from that for a <strong>‘VAE Gatekeeper’</strong>.
                          </p>
                        </div>
                        <div
                          id="link11"
                          style={{
                            display:
                              P3visibleSection === "Approves"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            GATEKEEPER APPROVES IN VEEVA VAULT
                          </p>
                          <p className="flowsContent">
                            The VAE gatekeepers must review the functionality in
                            Sandbox/PF1.
                          </p>
                          <p className="flowsContent">
                            Before completing the approval task in VVPM, VAE
                            Gatekeepers{" "}
                            <strong>
                              must
                            </strong>{" "}
                            check the following:{" "}
                          </p>{" "}
                            <p className="flowsContent">
                              1. The Salesforce Code is correct in the VVPM <strong>Assignment Criteria</strong>. {" "}
                            </p>
                            <p className="flowsContent">
                              2. The <strong>Expiration Date</strong> matches with the Expiration Date in the certified job bag.
                            </p>
                            <p className="flowsContent">
                              3. Correct fragments and templates are placed in <strong>Related Pieces</strong> of the initial job bag and vice versa.{" "}
                            </p>
                            <p className="flowsContent">
                              4. Relevant fragments are linked to relevant certified template.{" "}
                            </p>
                          <p className="flowsHeading">Remember</p>
                          <ul className="ul_padding">
                            <li className="flowsContent">
                               Only a designated VAE gatekeeper with special
                              access and{" "}
                              <a
                                href={webConfig.GatekeeperTraining}
                                target="_blank"
                              >
                                training
                              </a>{" "}
                              can do this.
                            </li>
                            <li className="flowsContent">
                               Gatekeeper is a vital role that must be
                              undertaken by a Lilly employee from marketing &
                              PRA functions
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link12"
                          style={{
                            display:
                              P3visibleSection === "Inform" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            INFORM THE CUSTOMER FACING TEAM
                          </p>
                          <p className="flowsContent"><strong>Inform the Customer-Facing team that the VAE and briefing guide are now Available for Use.</strong></p>
                          <p className="flowsContent">
                          The VAE will be visible once their iPads have been re-synced. Syncing needs to be manually triggered by the Customer-Facing team and may take ~1 hour. (The Customer-Facing team would have been advised to sync daily.){" "}
                          </p>
                        </div>
                        <div
                          id="link12"
                          style={{
                            display:
                              P3visibleSection === "Comment" ? "block" : "none",
                          }}
                        >
                          <h5>
                            <em>Comment</em>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion4" />
                  <Accordion
                    className="accordion4"
                    title="Phase 4: Material Management"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning">
                        <Phase4 ref={svgRef4} className="VAEshape4" />
                      </div>
                      <div className="col-lg-6 planning p-3 flowsSecondPart">
                        <div
                          id="link14"
                          style={{
                            display:
                              P4visibleSection === "Updated" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">UPDATE OF CET</p>
                          <p className="flowsContent">
                            Ensure that tactic metrics are updated on CET. If
                            the BE tactic is linked appropriately, results will
                            update automatically.
                          </p>
                        </div>
                        <div
                          id="link15"
                          style={{
                            display:
                              P4visibleSection === "Quarterly"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            QUARTERLY INVENTORY REVIEWS BY BRAND TEAM
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               For Operational Excellence in Material
                              Management, the NE Hub has a quarterly Operational
                              Review of Inventory. The Brand Leader/Business
                              Unit Director should lead this exercise with their
                              team to review the list of material in VVPM and
                              decide whether to update the material, leave it as
                              it is for now or withdraw it from VVPM. Understand
                              more in the{" "}
                              <a
                                href={webConfig.OperationalQuarterlyReviewGuidance}
                                target="_blank"
                              >
                                Operational Quarterly Review Guidance
                              </a>.
                            </li>
                            <li className="flowsContent">
                               Always <strong>check inventory information</strong> to see if any
                              metadata needs to be updated.
                            </li>
                            <li className="flowsContent">
                               <strong>Review briefing guides</strong> for any update required
                              and storage locations.
                            </li>
                          </ul>
                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">
                            In VVPM, the job bag is always connected to an ET/EF
                            version.
                          </p>
                        </div>
                        <div
                          id="link160"
                          style={{
                            display:
                              P4visibleSection === "UpdateOfMaterial"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">UPDATE OF MATERIAL</p>
                          <p className="flowsContent">
                            An outcome of the quarterly material review
                            (or other reasons such as Prescribing Information [PI] update) 
                            might result in a need to update the material. Updates in the 
                            material initiates the process right from the beginning, i.e., 
                            ‘briefing’ and the withdrawal process of the existing version.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion5" />
                  <Accordion
                    className="accordion5"
                    title="Phase 5: Material Withdrawal"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning p-3">
                        <div className="comman_width_e">
                          <Phase5 ref={svgRef5} className="VAEshape5" />
                        </div>
                      </div>
                      <div className="col-lg-6 planning p-3 flowsSecondPart">
                        <div
                          id="withdrawal_phase"
                          style={{
                            display:
                              P5visibleSection === "Gatekeeper"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            MATERIAL WITHDRAWAL PHASE
                          </p>
                          <p className="flowsContent">
                            Material Withdrawal phase starts with the brand
                            team´s decision to discontinue a material and it
                            ends with completed and documented destruction of
                            the material.{" "}
                          </p>
                          <p className="flowsContent">
                           The materials need to be removed from all storage
                           locations and circulation before initiating the 
                           withdrawal in the Withdrawal Tool (wherever applicable). 
                           The <strong>Inventory Information</strong> field in VVPM will help the 
                           Material Administrator to capture all storage 
                           locations for effective destruction.{" "}
                          </p>
                          <p className="flowsHeading">
                            Withdrawal and Retirement 
                          </p>
                          <p className="flowsContent">
                            Material Administrators initiate the retirement process by removing items from being accessed as per the inventory list in VVPM. 
                          </p>
                          <ol>
                            <li className="flowsContent">
                              <strong>Gatekeeper withdraws ET/EF from CRM </strong>
                              <br />
                              VAE Gatekeeper withdraws or expires ET/EF version of VAE in VVPM. This action removes the live version from iPads of Customer-Facing teams (after their iPad has been synced). If a VAE template is being retired, ensure retirement of relevant fragments.  
                            </li>
                            <li className="flowsContent">
                              <strong>UK: Withdraw associated briefing guide  </strong>
                              <br />
                              Send withdrawal notification – Material Administrators initiate withdrawal notification to the Customer-Facing team. Customer-Facing team acknowledges the withdrawal by the set date. Veeva Coordinator then retires the job bag in VVPM. 
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td className="tb">
                        <h6>Veeva Owner </h6>
                      </td>
                      <td className="tb">
                        The Veeva Owner is also known as the Uploader (please
                        see 'Veeva uploader'){" "}
                      </td>
                    </tr>
                    <tr>
                        <td className="tb">
                            <h6>Material Administrator</h6>
                        </td>
                        <td>
                          This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                        </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Material Originator</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:{" "}
                          <ul className="ul_list">
                            <li>
                              1. Having overall responsibility and
                              accountability for the material{" "}
                            </li>
                            <li>
                              2. Ensure that no item is used without the
                              necessary approvals
                            </li>
                            <li>
                              3. Ensuring timely change and/or withdrawal, where
                              needed
                            </li>
                          </ul>
                          For further information, please see{" "}
                          <a
                            href={webConfig.PMAPJobAid}
                            target="_blank"
                          >
                            PMAP Job Aid.
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Veeva Uploader</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Person who uploads the material to Veeva, such as an
                          assigned member from the marketing, medical or PRA
                          teams, and is assigned the Veeva Owner role.
                          <br />
                          Responsible for:
                          <ul className="ul_list">
                            <li>1. Uploading the material to Veeva</li>
                            <li>
                              2. Performing all activities related to upload
                            </li>
                          </ul>
                          For further information, please see{" "}
                          <a
                            href={webConfig.PMAPJobAid}
                            target="_blank"
                          >
                            PMAP Job Aid.
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Veeva Co-ordinator</h6>
                      </td>
                      <td className="tb">
                        <p>
                          The Coordinator is always a Lilly employee. The Veeva
                          Coordinator can only be the Brand Manager (or similar
                          role).
                          <br />
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Reviewing the item before sending for MLR
                              review
                            </li>
                            <li>2. Managing the document workflow</li>
                            <li>3. Assigning reviewers</li>
                            <li>4. Managing expiry or periodic review</li>
                          </ul>
                          For further information, please see{" "}
                          <a
                            href={webConfig.PMAPJobAid}
                            target="_blank"
                          >
                            PMAP Job Aid.
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Business Reviewer</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Business Reviewer is an experienced commercial
                          employee.
                          <br />
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Ensuring all promotional materials align with
                              the brand strategy, Lilly Brand Book, Product
                              Brand Book
                            </li>
                            <li>
                              2. Ensuring all materials are of good quality,
                              have correct Veeva metadata and adhere to internal
                              policies and local codes
                            </li>
                          </ul>
                          For further information, please see{" "}
                          <a
                            href={webConfig.PMAPJobAid}
                            target="_blank"
                          >
                            PMAP Job Aid.
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>UK Medical Certifier</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Registered medical practicioner or UK-registered
                          Pharmacist
                          <br />
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Ensuring that the material is in accordance
                              with the requirements of the local code;
                            </li>
                            <li>
                              2. Ensuring that the material is not inconsistent
                              with the marketing autorisation/SmPC (if
                              applicable);
                            </li>
                            <li>
                              3. Ensuring that the material is a fair and
                              truthful representation of the facts about the
                              medicine (if applicable);
                            </li>
                            <li>4. Approving the material in its final form</li>
                            <li>
                              5. Ensuring the material has the appropriate
                              reviewewers and that the Veeva metadata is
                              correct.
                            </li>
                          </ul>
                          For further information, please see{" "}
                          <a
                            href={webConfig.PMAPJobAid}
                            target="_blank"
                          >
                            PMAP Job Aid.
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>UK Technical Medical Reviewer</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Person who is responsible for ensuring that the
                          material contains accurate information in line with
                          the references that have been used and for ensuring
                          that the Veeva metadata is correct and that the PMC
                          documents are included (if applicable).
                          <ul className="ul_list">
                            <li>
                              {" "}
                              For further information, please see{" "}
                              <a
                                href={webConfig.PMAPJobAid}
                                target="_blank"
                              >
                                PMAP Job Aid.
                              </a>
                            </li>
                          </ul>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Nordic Code Approver</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Code Approver must be a medical practitioner or a
                          pharmacist (except for Sweden) and is responsible for:
                          ensuring that the material is in line with the local
                          Codes; ensuring that the material being approved has
                          the appropriate reviewers; and ensuring that the Veeva
                          metadata is correct.
                          <ul className="ul_list">
                            <li>
                              {" "}
                              For further information, please see{" "}
                              <a
                                href={webConfig.PMAPJobAid}
                                target="_blank"
                              >
                                PMAP Job Aid.
                              </a>
                            </li>
                          </ul>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Nordics Technical Medical Reviewer</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Person who is responsible for ensuring that the
                          material contains accurate information in line with
                          the references that have been used and for reviewing
                          the material from a medical perspective.
                          <ul className="ul_list">
                            <li>
                              {" "}
                              For further information, please see{" "}
                              <a
                                href={webConfig.PMAPJobAid}
                                target="_blank"
                              >
                                PMAP Job Aid.
                              </a>
                            </li>
                          </ul>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Indegene</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Creating artwork as per material originator
                              brief
                            </li>
                            <li>
                              2. Creating formatted PI PDFs (for both UK and Nordics)
                            </li>
                          </ul>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>CLM Owner</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Creating CLM presentation and accurate
                              completion of CLM metadata
                            </li>
                            <li>2. Deploying for testing</li>
                            <li>
                              3. Pushing asset for gatekeeper approval upon
                              confirmation of successful testing
                            </li>
                          </ul>
                          May also be material originator and gatekeeper.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>GCO (Global Customer Operations) </h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Platforms and services that connects Lilly's
                              operations with customers. E.g. Veeva, e-consent
                              platforms etc.
                            </li>
                          </ul>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>DST (Digital Solutions Team) </h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Creating artwork, HTML and ET/EF job bags as
                              per material originator brief
                            </li>
                            <li>
                              2. Push asset to gatekeeper approval upon
                              confirmation of approval by material originator
                            </li>
                          </ul>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Gatekeeper </h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Ensuring the email matches final form in Veeva,
                              functions correctly and that the distribution list
                              is appropriate
                            </li>
                            <li>
                              2. Withdrawing/expiring the email when requested by material originator
                            </li>
                          </ul>
                          Material originator and gatekeeper may be the same
                          person.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Material Originator </h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Content conceptualisation and brief for DST
                            </li>
                            <li>
                              2.Managing the approval process, including
                              provision of appropriate subject lines and
                              advising DST to push asset for gatekeeper
                              approval.
                            </li>
                            <li>3. Roll out to customer-facing teams</li>
                            <li>
                              4. Informing gatekeeper when withdrawal is
                              required.
                            </li>
                          </ul>
                          Material originator and gatekeeper may be the same
                          person. The material originator may be the Veeva
                          Uploader and the Veeva Co-ordinator, or the Veeva
                          Co-ordinator solely.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>

              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>Expanded</strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                      Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">BE</td>
                    <td className="tb">Broadcast Email</td>
                    <td className="tb">
                      Approved emails created in HTML form that are sent to a
                      selected HCP audience group via head office on SFMC
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool</td>
                    <td className="tb">
                      Planning and execution tools combine to form the CET. 
                      With CET, customer plans can be created and campaigns can 
                      be managed in one place. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Conversion Metric </td>
                    <td className="tb"></td>
                    <td className="tb">
                      Metrics used to measure the number of valuable actions 
                      customers take through a digital marketing channel. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CTA</td>
                    <td className="tb">Call to Action </td>
                    <td className="tb">
                      The next step a Marketer wants its audience or reader to take. For example, a button or video in an email that the recipient can click on.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CTOR</td>
                    <td className="tb">Click-through-to-open rate </td>
                    <td className="tb">
                      Email conversion metric used to review the effectiveness
                      of the email content. This measures the number of people
                      who clicked on an email link to the total number of people
                      who opened the email.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey</td>
                    <td className="tb"></td>
                    <td className="tb">
                      The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Defined text field </td>
                    <td className="tb"></td>
                    <td className="tb">
                      {" "}
                      Text entry fields on a website where entries are
                      restricted to a specific character limit.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">DST </td>
                    <td className="tb">Digital Solutions Team </td>
                    <td className="tb">Team of content developers dedicated to digital asset production in NE Hub.</td>
                  </tr>
                  <tr>
                    <td className="tb">E-consent </td>
                    <td className="tb">Email consent </td>
                    <td className="tb">
                      Electronic version of consent allowing Lilly to send digital communications to customers who have opted in to receive content.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">EF-code </td>
                    <td className="tb">Email Fragment-code </td>
                    <td className="tb">
                    Email fragment contains the content of the email and is inserted 
                    into the email template. Approved Email fragments are HTML files 
                    managed by Vault PromoMats. End users can select one or more 
                    fragments to add to an email. Fragments display in the email in the 
                    order they are arranged by the end user. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">ET-code </td>
                    <td className="tb">Email Template-code </td>
                    <td className="tb">
                      Email template is the framework for  the email. It normally contains a header, 
                      some text options, a signature and a footer. It can either be related one or 
                      several fragments that you instert into the template or it can be a stand alone 
                      email template with static content. Approved Email templates are HTML files managed 
                      by Vault PromoMats. End users of Approved Email select templates as a starting point 
                      when composing an email to send to accounts.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">GCO </td>
                    <td className="tb">Global Customer Operations </td>
                    <td className="tb">
                      A global team responsible for customer management tools
                      and operations.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Hyperlink </td>
                    <td className="tb"></td>
                    <td className="tb">
                      A link within one digital item to another location,
                      activated by clicking on a highlighted word or image.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">IBU </td>
                    <td className="tb">International Business Unit </td>
                    <td className="tb">
                      The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Interstitial </td>
                    <td className="tb"></td>
                    <td className="tb">
                      An interstitial is a pop-up or webpage that appears when a
                      user attempts to navigate from a Lilly website to an
                      external website. AN interstitial provides notice to the
                      user that the location they are navigating to is not
                      controlled or maintained by Lilly
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">
                    Goals or Metrics set behind a campaign and or tactic.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">OCE </td>
                    <td className="tb">Omnichannel Engagement </td>
                    <td className="tb">
                      Omnichannel engagement  campaigns engage effectively with
                      the right HCPs and patients at the right time with the
                      appropriate channel mix (e.g. SOA, eP2P and VOD).
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Open text fields </td>
                    <td className="tb"></td>
                    <td className="tb">
                      Places on a website where a user can enter data freely
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Opt-in</td>
                    <td className="tb"></td>
                    <td className="tb">
                      A customer provides informed consent to be contacted by Lilly through email.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Opt-out </td>
                    <td className="tb">Unsubscribe </td>
                    <td className="tb">
                      A customer revokes their informed consent to be contacted by Lilly through the specific channel. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">OR </td>
                    <td className="tb">Open Rate </td>
                    <td className="tb">
                      Email conversion metric used to review the effectiveness
                      of the email content. This measures the number of people
                      who opened an email to the total number of people the
                      email was delivered to.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">PF1 </td>
                    <td className="tb">Partner Force Login </td>
                    <td className="tb">
                      Veeva's sandbox environment for testing of VAEs.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">PMC</td>
                    <td className="tb">Permissions Managed Content</td>
                    <td className="tb">
                      An image which is owned by an entity other than Lilly and for which 
                      permission is given to use under a specific set of circumstances as 
                      defined in a contractual agreement between Lilly and the Content Owner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Privacy Policy </td>
                    <td className="tb"> </td>
                    <td className="tb">
                      A Privacy Policy is a statement or legal document that
                      disclosed the way that Lilly gathers, uses, discloses and
                      managed a customer or client's date. A page containing
                      this information can often be hyperlinked on digital
                      content.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Sandbox</td>
                    <td className="tb"> </td>
                    <td className="tb">
                      A testing environment in a computer system in which new or
                      untested software can be run securely
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Source File/HTML </td>
                    <td className="tb">Hypertext Markup Language file </td>
                    <td className="tb">
                      An HTML file contains hypertext markup language that
                      formats the structure of a webpage or email.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">SSO </td>
                    <td className="tb">Single Sign On </td>
                    <td className="tb">
                      Single Sign-on (SSO) is a process that allows users to
                      access multiple authorized applications without having to
                      log in separately to each application. SSO allows
                      organizations to validate user names and passwords against
                      a corporate user database. A Lilly SSO solution for HCPs
                      is referred to as HCP Direct.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Subject line </td>
                    <td className="tb"> </td>
                    <td className="tb">
                      Limited text field at the top of an email identifying the
                      email intent to the customer before they open.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"></td>
                    <td className="tb">
                      Tactics are specific to selected channels to drive customers to 
                      reach a piece of content. Tactics are associated with estimated 
                      KPI metric (goal) and result reports (actuals).  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Touchpoint </td>
                    <td className="tb"> </td>
                    <td className="tb">
                      Touchpoints is terminology used to identify the volume and
                      frequency our tactics reach customers. These touchpoints
                      can be deep (often more in-depth pieces of content, more
                      likely to drive behavioural change) or light (may be
                      transactional or driving to 1 specific action or view).
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">URL Builder </td>
                    <td className="tb"> </td>
                    <td className="tb">
                      An administrative tool allowing an authorised Lilly user
                      to create a URL or links to embed in digital
                      communications. For example, an MCE Operations Manager
                      will use URL builder to produce HCP Direct links.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">VAE</td>
                    <td className="tb">Veeva Approved Email </td>
                    <td className="tb">
                      Approved email created in HTML form that are sent to the HCP 
                      through Sales Representatives 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Veeva CLM </td>
                    <td className="tb"> Closed Loop Marketing </td>
                    <td className="tb">
                      Veeva's closed loop marketing (CLM) functionality allows
                      commercial and medical reps to engage with HCPs and key
                      opinion leaders hosted in Veeva Vault. Users can display
                      marketing or informational material during calls and track
                      CLM activity for accounts, allowing marketers to gain
                      insight into their targets and improve the content to
                      maximize relevance and effectiveness.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Veeva CRM </td>
                    <td className="tb"> Customer Relationship Manager </td>
                    <td className="tb">
                      Veeva's customer relation manager (CRM) is an application
                      built on the Salesforce platform designed for the
                      pharmaceutical and biotechnology industries. Veeva CRM
                      includes a data model, application logic and tailored user
                      interface controls to support the pharmaceutical and
                      biotechnology industries while leveraging much of the
                      standard Salesforce platform functionality, reporting,
                      configuration and maintenance capabilities.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">VAE Gatekeeper </td>
                    <td className="tb">Veeva Approved Email </td>
                    <td className="tb">
                      Lilly employees with special access to be able to push VAEs from Veeva Vault to Veeva 
                      CRM environment,hence making it available to the sales reps   
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Veeva Suggestions </td>
                    <td className="tb"> </td>
                    <td className="tb">
                      An automated system built into SFMC journey builder to
                      allow Sales Representatives to receive post-event
                      recomendations or other follow-up suggestions based on a
                      customer's actions. Currently available for UK only.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">VVPM</td>
                    <td className="tb">Veeva Vault PromoMat</td>
                    <td className="tb">
                      Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems. 
                      PromoMats is a Vault application to manage the process of creating, reviewing and 
                      approving promotional materials, claims and supporting information. 
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.VAECoreDoc}
                  target="_blank"
                >
                  VAE core document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-faqs"
              role="tabpanel"
              aria-labelledby="v-pills-faqs-tab"
            >
              <h4 className="h1-font comman_hd">FAQs</h4>
              <div id="FAQ1">
                <Accordion
                  className="faqAccordion"
                  title="How do I get the Gatekeeper access? "
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    To request for the VAE Gatekeeper access: Please have 
                    your Manager/Supervisor complete the {' '}
                    <a
                      className="CoreURL"
                      href={webConfig.VAECoreDoc}
                      target="_blank"
                    >
                      GCO Onboarding Request
                    </a>{''}. To adhere to the audit requirements only a recognized 
                    Approver or a Line Manager can complete this request on 
                    behalf of their employee for training and access to the GCO 
                    platforms. An employee will not be able to submit a request 
                    themselves, as the correct process to request VAE/CLM training/access 
                    is via the GCO Onboarding Request form. Training will be assigned to 
                    users once the request has been made. 
                  </p>
                </Accordion>
              </div>
              <div id="FAQ2">
                <Accordion
                  className="faqAccordion"
                  title="How do I get in touch with the helpdesk?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    Globally, Lilly has a dedicated helpdesk for VAEs. 
                    The helpdesk grants access, can transfer ownership 
                    rights and generally reports on VAEs. You can reach 
                    them at <strong>VeevaApprovedEmail_helpdesk@lilly.com.</strong>
                  </p>
                </Accordion>
              </div>

              <div id="FAQ3">
                <Accordion
                  className="faqAccordion"
                  title="What are fragments and templates or ETs and EFs?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    <strong>EMAIL TEMPLATES (ETs)</strong>
                    <ul>
                      <li>
                        An ET is the framework for the email and sits in the 
                        background providing dynamic capabilities for name, email 
                        address, etc. The data provided in the template should be 
                        generic in nature.  
                      </li>
                      <li>
                        <strong>Please note: Subject lines</strong> are a part of 
                        the template and care must be taken to select relevant and 
                        approved subject lines for the development of the template.
                      </li>
                    </ul>
                    <br />
                    <strong>EMAIL FRAGMENTS (EFs)</strong>
                    <ul>
                      <li>
                        EFs contain product or indication specific
                        information, for example product images and/or campaign
                        information.
                        <br />
                        <div className="FAQImage">
                          <img src="images/FaqQ3VAE.png" />
                        </div>
                        <br />
                        There are limited use cases for the utility of a single
                        template with multiple fragments. These are where you
                        can cluster similar content types to a fixed template
                        <strong> with one subject line</strong>. This means that 
                        the brand team will need to develop and approve: One template 
                        with a single subject line, single/multiple fragment pieces 
                        and a briefing guide for Customer-Facing teams.  
                        <br />
                        <strong>Example use-cases</strong>
                        <ul>
                          <li> Meetings in a Box Invitations</li>
                          <li> Patient Information</li>
                          <li> Healthcare Professional Information</li>
                        </ul>
                      </li>
                    </ul>
                    <ul>
                      <li>
                         Your <strong>medical certifier needs to agree</strong> with the
                        approach of multiple fragment combinations.
                      </li>
                      <li>
                        {" "}
                        <strong>It is not advised to have multiple subject lines</strong>{" "}
                        with multiple fragments as this can lead to a large matrix of different 
                        email combinations of the VAE. Moreover, each combination will then need 
                        an approval.  
                      </li>
                    </ul>
                  </p>
                </Accordion>
              </div>
              <div id="FAQ4">
                <Accordion
                  className="faqAccordion"
                  title="How do I find a VAE (the ET code) in VVPM?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    <br />
                    <div className="FAQImage">
                      <img src="images/FaqQ4VAE1.png" width="50%" />
                    </div>
                    <br />
                    <div className="FAQImage">
                      <img src="images/FaqQ4VAE2.png" width="75%" />
                    </div>
                    <br />
                  </p>
                </Accordion>
              </div>
              <div id="FAQ5">
                <Accordion
                  className="faqAccordion"
                  title="How do I find the live VAEs in VVPM? "
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    Instructions from the answer above (question 4) will lead
                    you to see the full list of the VAEs. Then <strong>filter</strong> 
                    <strong>status</strong> to <strong>‘Approved’</strong> to see the 
                    list of live VAEs. <strong>Click “Save ViewAs” </strong>
                    for easy access in the future
                  </p>
                </Accordion>
              </div>
              <div id="FAQ6">
                <Accordion
                  className="faqAccordion"
                  title="Gatekeeper has approved my VAE – How do I check if it is live?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      You can check the status of a VAE by reviewing the ET file
                      in VVPM, if the ET/EF document has an <strong> “Approved” </strong>
                      (see example below) then the asset is live for the selected field
                      force.
                    </p>
                    <div className="FAQImage">
                      <img src="images/FaqQ6VAE.png" />
                    </div>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ7">
                <Accordion
                  className="faqAccordion"
                  title="How do I test a VAE online (PF1)?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    The VAE staging environment can be accessed online or via
                    the CRM app. Brand managers will need to complete gatekeeper
                    training and request access beforehand (See question 1)
                    <ol>
                      <li>
                        {" "}
                        Login to www.test.salesforce.com/ (or access Veeva CRM
                        iPad app). For login support please contact salesforce
                        administrators
                        iRep_Multimedia_Presentations_NonSalesRep@lilly.com.{" "}
                      </li>
                      <li>
                        Click My Accounts, choose an account and then send
                        email.
                      </li>
                      <li>
                        Send the desired VAE and test email received in your
                        outlook inbox.
                      </li>
                    </ol>
                  </p>
                </Accordion>
              </div>
              <div id="FAQ8">
                <Accordion
                  className="faqAccordion"
                  title="How do I find out about who the Gatekeeper for my brand is?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    Email the VAE helpdesk at
                    VeevaApprovedEmail_helpdesk@lilly.com, requesting a list of
                    the current Gatekeeper approvers for your locality and you
                    will be able to see who the Gatekeepers are for your brand.
                  </p>
                </Accordion>
              </div>
              <div id="FAQ9">
                <Accordion
                  className="faqAccordion"
                  title="How do I push a VAE live if a Gatekeeper is on leave?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    If your gatekeeper is away, please reach out to your OCE
                    Manager or another Gatekeeper on the list (see question
                    above).
                  </p>
                </Accordion>
              </div>
              <div id="FAQ10">
                <Accordion
                  className="faqAccordion"
                  title="How do you create subject lines for a VAE template with multiple fragments?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    When using multiple fragments in a VAE template, brand teams
                    are advised to:
                    <ul>
                      <li>
                         <strong>Use one subject line.</strong> Using multiple subject
                        line creates confusion and leads to manual errors that
                        would be non-compliant.{" "}
                      </li>
                      <li>
                         <strong>Include a table</strong> in the briefing guide 
                        outlining which subject line is approved for which fragment
                        <strong> if more than one subject line is to be used.</strong>
                      </li>
                    </ul>
                  </p>
                </Accordion>
              </div>
              <div id="FAQ11">
                <Accordion
                  className="faqAccordion"
                  title="What is Digital Publishing?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    If you are hosting PDFs linked to this asset, you must use{" "}
                    <strong>Veeva Digital Publishing (DP).</strong> Digital Publishing
                    automates the publishing of the latest approved version of
                    content to various digital channels and supports automatic
                    withdraw of expired or obsolete content. The system does
                    this by allowing users to select approved VVPM documents to
                    be published as a digital PDF on an externally accessible
                    location. For full details on the steps to allow DP, please 
                    follow the {' '}
                    <a
                      className="CoreURL"
                      href={webConfig.VeevaDigitalPublishingGuide}
                      target="_blank"
                    >
                      Veeva Digital Publishing Guide
                    </a> 
                    <br />
                    <strong> What are the benefits of Digital Publishing?  </strong>
                    <ul>
                      <li>
                         When a DP enabled document is Approved for
                        Distribution, Vault will automatically publish the
                        approved version the file to an externally accessible
                        location and generate a URL to the document.
                      </li>
                      <li>
                         When updates to a DP enabled document are approved,
                        Vault will automatically publish the new, approved
                        version without the need to change the URL (i.e. on the
                        website).
                      </li>
                      <li>
                         When a DP enabled document is
                        expired/retired/withdrawn the document will be removed
                        from the externally accessible location.
                      </li>
                    </ul>
                    <br />
                    <strong>
                      Can I let my assets auto-expire?{" "}
                    </strong>
                    <br />
                    In short no, this tool gives us greater compliance control
                    over the materials that available in the live environment.
                    However, it is important that we continue to track all of
                    our materials in order to replace broken links that will
                    occur as we withdraw or expire assets.
                    <br />
                    Each brand team must consider the ripple effects of a
                    material withdrawal and put mitigation plans in place to
                    swiftly amend links (if applicable) or change content on
                    relevant pages if a campaign is being retired.
                  </p>
                </Accordion>
              </div>
              <br />
            </div>
          </div>
        </div>
        {/*UK at top */}
        <div
          id="popup_flight_travlDil1039"
          className="overlay_flight_traveldil"
        >
          <div className="popup_flight_travlDil">
            <p className="flowsHeading">UK</p>
            <p className="flowsContent">
            A separate approval for an internal briefing guide to be added as a supporting document must be initiated – see the flow for internal documents.
            </p>
            <a className="close_flight_travelDl" href="#/">
              &times;
            </a>
          </div>
        </div>

        {/*Noridcs under  Cert */}
        <div
          id="popup_flight_travlDil1040"
          className="overlay_flight_traveldil"
        >
          <div className="popup_flight_travlDil">
            <p className="flowsHeading">NORDICS</p>
            <p className="flowsContent">
              Please check the{" "}
              <a
                className="URLColour"
                href={webConfig.NordicPMAPGuidance}
                target="_blank"
              >
                Nordic PMAP Guidance
              </a>{" "}
              for detailed information on statutory copy submission.
            </p>
            <a className="close_flight_travelDl" href="#/">
              &times;
            </a>
          </div>
        </div>

        {/*Noridcs next to Cert */}
        <div
          id="popup_flight_travlDil1041"
          className="overlay_flight_traveldil"
        >
          <div className="popup_flight_travlDil">
            <p className="flowsHeading">NORDICS</p>
            <p className="flowsContent">
              {" "}
              If the material gets the verdict <strong>
                ‘Not Certified’
              </strong>{" "}
               use the <strong>‘Create Draft’</strong> function in VVPM to
              use the same job bag code for the updated version of the material.{" "}
            </p>
            <a className="close_flight_travelDl" href="#/">
              &times;
            </a>
          </div>
      </div>

      {/*UK second to last */}
      <div id="popup_flight_travlDil1042" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">UK</p>
          <p className="flowsContent">
            An approved Briefing Guide is sent to Sales Representatives via
            email, in addition to the VAE.
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>
      {/*UK  last */}
      <div id="popup_flight_travlDil1043" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">UK</p>
          <p className="flowsContent">
            VAE briefing guide links and go-live communications are also to be
            uploaded on the Microsoft Teams channel that the brand team has
            created for Sales Representatives.
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>

      {/*UK  Materials */}
      <div id="popup_flight_travlDil1044" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">UK</p>
          <p className="flowsContent">
          Consider if the original briefing guide for Sales Representatives covers the update made. Material Owners should either re-send the original briefing guide or arrange for the briefing guide to be re-worked, re-approved and re-deployed with the latest version of the material. 
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>
      {/*UK  Withdrawl */}
      <div id="popup_flight_travlDil1045" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">UK</p>
          <p className="flowsContent">
            Withdraw the briefing template from agreed storage and distribution
            location.
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>
    </div>
    </div>
  );
};

export default VAE;
