import React from "react";
import { useState, useRef, useEffect } from "react";
import {webConfig} from '../configs/webConfig'
import { Link } from 'react-router-dom';
import "../css/InternalBriefing.css";
import Accordion from "../components/Accordion.js";
import { ReactComponent as Phase1 } from "../SVG/IB_Phases_SVG/IB-Phase-1.svg";
import { ReactComponent as Phase2 } from "../SVG/IB_Phases_SVG/IB-Phase-2.svg";
import { ReactComponent as Phase3 } from "../SVG/IB_Phases_SVG/IB-Phase-3.svg";
import { ReactComponent as Phase4 } from "../SVG/IB_Phases_SVG/IB-Phase-4.svg";

import { handleScroll } from "../utils/FlowHelper.js";



const InternalBriefing = () => {

  const [isPhase1Open, setIsPhase1Open] = useState();
  const [isPhase2Open, setIsPhase2Open] = useState();
  const [isPhase3Open, setIsPhase3Open] = useState();
  const [isPhase4Open, setIsPhase4Open] = useState();

  const svgRefPhase1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("rect-1");

  const svgRefPhase2 = useRef();
  const [P2visibleSection, setP2visibleSection] = useState("rect-1");

  const svgRefPhase3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("rect-1");

  const svgRefPhase4 = useRef();
  const [P4visibleSection, setP4visibleSection] = useState("rect-1");


  function setVisibility(phaseName, elemName, elemId) {
    switch (phaseName) {
      case "phase-1":
        elemName.addEventListener("click", () => {
          setP1visibleSection(elemId);
        })
        break;

      case "phase-2":
        elemName.addEventListener("click", () => {
          setP2visibleSection(elemId);
        })
        break;
      case "phase-3":
        elemName.addEventListener("click", () => {
          setP3visibleSection(elemId);
        })
        break;
      case "phase-4":
        elemName.addEventListener("click", () => {
          setP4visibleSection(elemId);
        })
        break;
      default:
        break;
    }
  }

  function dynamicElement(pahseNo, svg, rectNo) {
    for (let i = 1; i <= rectNo; i++) {
      let rect = svg.getElementById(`rect-${i}`);
      setVisibility(pahseNo, rect, `rect-${i}`)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const svg = svgRefPhase1.current;
    dynamicElement("phase-1", svg, 1)

    const rect2 = svg.getElementById("rect-2");
    rect2.addEventListener("click", () => {
      handleScroll("accordion2");
      setIsPhase2Open(true);
    });

  }, []);

  useEffect(() => {
    const svg = svgRefPhase2.current;
    dynamicElement("phase-2", svg, 5)

    const rect6 = svg.getElementById("rect-6");
    rect6.addEventListener("click", () => {
      handleScroll("accordion1");
      setIsPhase2Open(false);
      setIsPhase1Open(true);
    });

  }, []);

  useEffect(() => {
    const svg = svgRefPhase3.current;
    dynamicElement("phase-3", svg, 1)

    const rect3 = svg.getElementById("rect-3");
    rect3.addEventListener("click", () => {
      handleScroll("accordion1");
      setIsPhase2Open(false);
      setIsPhase3Open(false);
      setIsPhase1Open(true);
    });
    
    const rect4 = svg.getElementById("rect-4");
    rect4.addEventListener("click", () => {
      handleScroll("accordion4");
      setIsPhase1Open(false);
      setIsPhase2Open(false);
      setIsPhase3Open(false);
      setIsPhase4Open(true);
    });


  }, []);

  return (
    <div className="be_wrapper">
      <div id="soa-ib" className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
               
                <li className="breadcrumb-item active" aria-current="page">
                Internal Documents Briefs
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* Page Headings */}
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">  Internal Documents and Briefs </h2>
            <p>
              <em>
              Internal documents play an important role in compliantly documenting our guidance provided to the Customer-Facing team. An easy identifier for internal documents would be the job bag code assigned in Veeva Vault, as these job bags will always begin with ‘INT-’. Internal documents can be created internally and do not require enlisting the support of agencies. The format of these documents is typically a PDF or a video. Briefing documents are not required in the <b><u>NORDIC COUNTRIES</u></b>, except for Interactive Visual Aid (IVA).  
              </em>
            </p>
            <p>Two common forms of internal documents include: </p>
            <p>  
                <b>Briefing guide:</b> Document intended to supplement the Customer-Facing team Employee’s use of a material. This guide will detail the purpose, guidance and best practice for using the material as an effective, compliant brief. A briefing guide can also include a verbatim: 
                <ul className="ib-unorder-list"> 
                    <li>
                        <b>Verbatim:</b> A detailed response from Customer-Facing teams to a specific question or specific circumstance, in response to a healthcare professional (HCP) or other relevant decision makers (ORDM). A verbatim should always be provided within the context of a briefing guide.  
                    </li>
                </ul>
            </p>
            <p>
                Potential materials that would require a briefing guide include, but are not limited to: 
                <ul className="ib-unorder-list"> 
                    <li>
                        Veeva Approved Email (VAE)  
                    </li>
                    <li>IVA – Required for both <b><u>NORDIC COUNTRIES AND UK</u></b>. </li>
                    <li>Print </li>
                    <li>Digital PDF  </li>
                </ul>
            </p>
           <p>
           <b>Training content:</b> Content used to train the Customer-Facing team Employees for a large scale update, such as Prescribing Information (PI). This could be shared in different formats, such as PDF, slides or video.
           </p>
            <p><b>Please note:</b> Temporary staff such as Students and Contract employees are unable to create or push job bags for internal documents through Veeva Vault. The only action students can take is creating the PDF outside of Veeva and providing this to another member of the Brand Team for Medical, Legal, Regulatory (MLR) review. </p>
          </div>
        </div>
      </div>
      {/* Horozontal Tabs */}
      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-bs-toggle="tab"
              href="#microsites-tab-1"
            >
              Briefing Guide Field Examples
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              href="#microsites-tab-2"
            >
              VAE Briefing Guide Template
            </a>
          </li>
          
        </ul>

        <div id="tabContent" className="tab-content">
          <div id="microsites-tab-1" className="tab-pane fade in active tabs_box tabContent">
              <ul className="ul_padding micro-unorder-list">

                <li>Who is the brief for? </li>
                <li>Material background and purpose </li>
                <li>Description of the briefing document  </li>
                <li>What is the material? </li>
                <li>Intended customers/who should it be used with?  </li>
                <li>How should the material be used? </li>
                <li>Method of dissemination/where can I find the material?  </li>
                
              </ul>
            
          </div>
          <div id="microsites-tab-2" className="tab-pane fade tabs_box tabContent">
              <p className="tabContent">
              Please refer to the PDF template for VAE briefing guides <a href={webConfig.VAEBriefingGuideStandardTemplate}
              target="__blank">
              here
              </a> 
              </p>
          </div>

        </div>
      </div>

      {/*Add verticle Tab -  Accordians*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
            <button
              className="nav-link tab-hide"
              id="v-pills-faqs-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-faqs"
              type="button"
              role="tab"
              aria-controls="v-pills-faqs"
              aria-selected="false"
            >
              FAQs
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">Internal Documents and Briefs  Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1">
                    <Accordion className="accordion1"
                      title="Phase 1: Material Rediness"
                      defaultOpen={true}
                      isOpen={isPhase1Open}
                    >
                      <div className="row" style={{ margin: "0px" }}>
                        <div id="local-on24" className="col-lg-5 planning">
                          <div className="comman_width_e">
                            <Phase1 ref={svgRefPhase1} className="ib-svg-phase-1" />
                          </div>
                        </div>
                        <div className="col-lg-7 planning p_font pt-3 flowsSecondPart">
                          <div
                            style={{
                              display:
                                P1visibleSection === "rect-1" ? "block" : "none",
                            }}
                          >
                            <p className="flowsHeading">Briefing </p>
                            <p className="flowsContent">
                            During the process of briefing in a new material, if the material is being used by a Customer-Facing team Employee, a briefing document must also be prepared. For producing verbatims or training documents, it is also advised to begin discussing plans for this during pre-Veeva discussions.  
                            </p>
                            
                            <p className="flowsContent">
                            Internal documents can be created internally and do not require enlisting the support of agencies. The format of these documents is typically a PDF or video.  
                            </p>
                            <p className="flowsHeading">
                            REMEMBER  
                            </p>
                            <p className="flowsContent">
                            Internal training materials are considered non-promotional in the <b><u>
                            NORDICS (DK, FI, NO, SE)</u></b>. The UK Medical Certifiers will approve internal sales training materials in line with the UK Code only, but these job bags can be used for Nordic sales training. For an already approved job bag, if the countries are not listed under “Country Approved for Use”, please upload an attachment (i.e., .doc file) which specifies all the countries it is used in. 
                            </p>
                          </div>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                  <div id="accordion2">
                    <Accordion className="accordion2"
                      title="Phase 2: Material Approval Phase  "
                      defaultOpen={false}
                      isOpen={isPhase2Open}
                    >
                      <div className="row" style={{ margin: "0px" }}>
                        <div id="local-on24" className="col-lg-7 planning main-content">
                          <Phase2 ref={svgRefPhase2} className="ib-svg-phase-2" />
                        </div>
                        <div className="col-lg-5 planning p_font pt-3 flowsSecondPart side-content">
                          <div
                            style={{
                              display:
                                P2visibleSection === "rect-1" ? "block" : "none",
                            }}
                          >
                            <p className="flowsHeading"> <string><u>Initiate MLR  </u></string>  </p>
                            <p className="flowsContent">
                            MLR can be initiated in parallel with MLR for the material being briefed. However, the main material must be certified before finalising certification of the associated briefing. A briefing guide will always be created in association with another material.  
                            </p>
                            <p className="flowContent">
                            This is the stage where the Material Owner uploads Material of the internal material to VVPM for reviewers to provide a verdict.   
                            </p>
                            <p className="flowsHeading">REMEMBER  </p>
            <p className="flowsContent">
            <ul className="ul_padding ul_margin_bottom micro-unorder-list">
                <li>
                All internal communication documents must have ‘Company Confidential: Internal use only’ in the footer. 
                </li>
                <li>
                Briefing guides do not follow stringent guidance, however, for a Sales item, e.g., IVA or leave piece, the briefing document should contain information about the objectives and key messages contained on each page.
                </li>
                <li>
                When verbatims are provided, they should be factual and as succinct as possible. 
                </li>
                <li>
                Another possible briefing document format is an email: the email is provided as a ‘test’ to the attachments of the job bag prior to certification. 
                </li>
                <li>
                As per the recent UK Prescription Medicine Code of Practice (PMCPA) cases, any communication to Customer-Facing teams that provides direction about interaction with an HCP or other relevant decision makers (ORDM) requires certification. 
                </li>
              </ul>
            </p>

                          </div>
                          <div
                            style={{
                              display:
                                P2visibleSection === "rect-2"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <p className="flowsHeading">MLR Verdict  </p>

                            <p className="flowsContent">There are three types of verdicts that the document could get:   
                            </p>
                            <ul className="ul_padding ul_margin_bottom ib-decimal-list">
                                <li className="flowsContent">
                                  <strong>Approved for Production –</strong> &nbsp; The document has been blessed by the reviewer.  
                                </li>
                                <li className="flowContent"><strong>Approved with Changes –</strong> &nbsp; The document has some minor errors/updates required (like
                                  spelling, grammatical errors).</li>
                                <li className="flowContent">
                                  <strong>Revise and Resubmit – </strong> &nbsp; The document has too many problems and the reviewer advises the Material Coordinator to rework on it and submit again.
                                </li>
                              </ul>
                          </div>
                          <div
                            style={{
                              display:
                                P2visibleSection === "rect-3"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <p className="flowsHeading">Initiate Certification </p>

                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom ib-unorder-list">
                                <li>
                                  <p className="flowsContent">
                                  This is the stage where the Material Coordinator uploads the final form of the material to VVPM for reviewers to provide a verdict on the material. Complete guide on PMAP certification is available here at <a target="_blank" href={webConfig.PMAPJobAid}>PMAP Job Aid</a>. 
                                  </p>
                                </li>
                              </ul>
                            </p>
                          </div>
                          
                          <div
                            style={{
                              display:
                                P2visibleSection === "rect-4"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <p className="flowsHeading">Certification Verdict</p>
                            <p className="flowsContent">
                            There are three types of verdicts that the material could get:   
                            </p>
                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom ib-decimal-list">
                                <li>
                                  <p className="flowsContent">
                                  <b>Certified –</b> The material is <b>Approved for Use </b>now.  Go ahead to review/set Inventory Information on VVPM and set the Actual Date of First Use.   
                                  </p>
                                </li>
                                <li>
                                  <p className="flowsContent">
                                  <b>Revise and Resubmit</b> (for certification) – The document has errors (like broken links) and/or requires updates.  
                                  </p>
                                </li>
                                <li>
                                  <p className="flowsContent">
                                  <b>Not Certified – </b> The material has too many problems, and the reviewer advises the Material Coordinator to rework it and start a new MLR workflow.
                                  </p>
                                </li>
                              </ul>
                            </p>
                            <p className="flowsHeading">REMEMBER  </p>
                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom ib-unorder-list">
                                <li>
                                  <p className="flowsContent">
                                  The verdict <b>‘Certified’</b> does not mean that the material is now live. Additional steps are needed to move it to the environment where it will be visible to the end user. See the Material ‘Go-Live’ phase below.  
                                  </p>
                                </li>
                                <li>
                                  <p className="flowContent">
                                  There is more information available on Verdicts (see <a href={webConfig.PMAPJobAid} target="_blank">PMAP Job Aid</a>) 
                                  </p>
                                </li>
                              </ul>
                            </p>
                            <p className="flowsContent">
                            <b><u>Set the ‘Actual Date of First Use’, Review Inventory Information and Expiry Date </u></b> 
                            </p>
                            <p className="flowContent">
                            <ul className="ul_padding ul_margin_bottom ib-unorder-list">
                                <li><b>Actual Date of First Use</b> is typically put as the date of first send or as the date the material has been certified on Veeva if date of first send is uncertain.</li>
                                <li>
                                <b>Expiration Date</b> can be set for 2 years, or shorter if the associated material has a specific use time-period. 
                                </li>
                            </ul>
                            </p>
                            <p className="flowsHeading">
                            REMEMBER  
                            </p>
                            <p className="flowContent">
                            <ul className="ul_padding ul_margin_bottom ib-unorder-list">
                                <li>There is no Inventory Information field in metadata for Internal Documents. Therefore, it is crucial that the Brand Team keeps all INT documents in one place, such as a SharePoint.  </li>
                                <li>
                                It is important to have clear ownership and inventory management for hosting of the briefing guides. Brand teams should conduct a regular audit of these sites to ensure that all briefing guides are retired in a timely manner. 
                                </li>
                            </ul>
                            </p>
                          </div>

                          <div
                            style={{
                              display:
                                P2visibleSection === "rect-5"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <p className="flowsHeading">Material to ‘Go-Live’ Phase</p>
                            <p className="flowsContent">
                            <ul className="ul_padding ul_margin_bottom ib-unorder-list">
                                <li>Ensure that the briefing guide is hosted in an easily accessible location for the Customer- Facing teams and is maintained regularly. </li>
                                <li>
                                For more complex material briefings, the initial briefing can be shared on a call or face-to-face. 
                                </li>
                            </ul>
                            </p>
                          </div>

                        </div>
                      </div>
                    </Accordion>
                  </div>
                  <div id="accordion3">
                    <Accordion className="accordion3"
                      title="Phase 3: Material Management Phase "
                      defaultOpen={false}
                      isOpen={isPhase3Open}
                    >
                      <div className="row" style={{ margin: "0px" }}>
                        <div id="local-on24" className="col-lg-5 planning">
                          <Phase3 ref={svgRefPhase3} className="ib-svg-phase-3" />
                        </div>
                        <div className="col-lg-7 planning p_font pt-3 flowsSecondPart">
                          <div
                            style={{
                              display:
                                P3visibleSection === "rect-1" ? "block" : "none",
                            }}
                          >
                            <p className="flowsHeading">Quarterly Material Review by Brand Team  </p>
                            <p className="flowsContent">
                            For operational excellence in material management, the NE Hub has a quarterly Operational Review of Inventory. The Brand Leader/Business Unit Director should lead this exercise with the team.&nbsp; When required to re-approve a material, review any associated briefing guides/verbatims/training content to check whether this will also require re-approval.   
                            </p>
                            <p className="flowsHeading">Remember!</p>
                            <p className="flowContent">
                            <ul className="ul_padding ul_margin_bottom ib-unorder-list">
                                <li>A good and frequent material inventory management reduces errors and risks! </li>
                                <li>
                                Brand teams should check the expiry date of the material. Acting early on helps reduce the last-minute pressure!  
                                </li>
                            </ul>
                            </p>

                          </div>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                  <div id="accordion4">
                    <Accordion className="accordion4"
                      title="Phase 4: Material Withdrawal Phase "
                      defaultOpen={false}
                      isOpen={isPhase4Open}
                    >
                      <div className="row" style={{ margin: "0px" }}>
                        <div id="local-on24" className="col-lg-5 planning">
                          <Phase4 ref={svgRefPhase4} className="ib-svg-phase-4" />
                        </div>
                        <div className="col-lg-7 planning p_font pt-3 flowsSecondPart">

                          <div
                            style={{
                              display:
                                P4visibleSection === "rect-1"
                                  ? "block"
                                  : "none",
                            }}>
                            <p className="flowsHeading">
                              Withdraw/Retire the Asset (Job Bag) from Veeva Vault   
                            </p>
                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom ib-unorder-list">
                                <li>Remove the document from its designated repository and initiate MaRs, if needed.  </li>
                              </ul>
                            </p>
                          </div>

                          


                        </div>
                      </div>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
            {/*End Lifecycle */}

            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Role </th>
                      <th>Responsibility</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr>
                      <td className="tb">
                        <h6>Veeva Owner</h6>
                      </td>
                      <td>
                        <p>The Veeva Owner is also known as the Uploader (please see 'Veeva uploader').</p>
                      </td>
                    </tr>
                    
                    <tr>
                        <td className="tb">
                            <h6>Material Administrator</h6>
                        </td>
                        <td>
                          This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                        </td>
                    </tr>

                    <tr>
                      <td className="tb">
                        <h6>Material Originator</h6>
                      </td>
                      <td>
                        <ul className="ul_list micro-decimal-list">Responsible for:
                          <li> Having overall responsibility and accountability of the material.</li>
                          <li> Ensuring that no item is used without the necessary approvals.</li>
                          <li> Ensuring timely change and/or withdrawal, where needed.</li>
                        </ul>
                        <p>For further information, please see <a href={webConfig.PMAPJobAid} target="_blank">PMAP Job Aid</a> .</p>
                      </td>
                    </tr>

                    <tr>
                      <td className="tb">
                        <h6>Veeva Uploader</h6>
                      </td>
                      <td>
                        <p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA
                          teams, and is assigned the Veeva Owner.</p>
                        <ul className="ul_list micro-decimal-list" >Responsible for:
                          <li> Uploading the material to Veeva</li>
                          <li> Performing all activities related to upload</li>
                        </ul>
                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>

                    <tr>
                      <td className="tb">
                        <h6>Veeva Co-ordinator</h6>
                      </td>
                      <td>
                        <p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager (or similar
                          role). </p>
                        <ul className="ul_list micro-decimal-list">
                          <li>Responsible for:</li>
                          <li> Reviewing the item before sending for MLR review</li>
                          <li> Managing the document workflow</li>
                          <li> Assigning reviewers</li>
                          <li> Managing expiry or periodic review</li>
                        </ul>
                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Business Reviewer</h6>
                      </td>
                      <td>
                        <p>Business Reviewer is an experienced commercial employee.</p>
                        <ul className="ul_list micro-decimal-list">
                          <li>Responsible for:</li>
                          <li> Ensuring all promotional materials align with the brand strategy, Lilly Brand Book, Product Brand
                            Book</li>
                          <li> Ensuring all materials are of good quality, have correct Veeva metadata and adhere to internal
                            policies and local codes</li>
                        </ul>
                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>UK Medical Certifier</h6>
                      </td>
                      <td>
                        <p>Registered medical practitioner or UK-registered Pharmacist.</p>
                        <ul className="ul_list micro-decimal-list">
                          <li>Responsible for:</li>
                          <li> Ensuring that the material is in accordance with the requirements of the local code.</li>
                          <li> Ensuring that the material is not inconsistent with the marketing authorisation/SmPC (if applicable).
                          </li>
                          <li> Ensuring that the material is a fair and truthful representation of the facts about the medicine (if
                            applicable).</li>
                          <li> Approving the material in its final form.</li>
                          <li> Ensuring the material has the appropriate reviewers and that the Veeva metadata is correct.</li>
                        </ul>
                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>UK Technical Medical Reviewer</h6>
                      </td>
                      <td>
                        <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                          references that have been used and for ensuring that the Veeva metadata is correct and that the PMC
                          documents are included (if applicable).</p>

                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Nordic Code Approver</h6>
                      </td>
                      <td>
                        <p>Code Approver must be a medical practitioner or a pharmacist (except for in Sweden) and is responsible for
                          ensuring that the material is in line with the local Codes and that the material being approved has the
                          appropriate reviewers; and ensuring that the Veeva metadata is correct.</p>

                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Nordics Technical Medical Reviewer</h6>
                      </td>
                      <td>
                        <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                          references that have been used and for reviewing the material from a medical perspective.</p>

                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Indegene</h6>
                      </td>
                      <td>
                        <p>Responsible for:</p>
                        <ul className="ul_list micro-decimal-list">
                          <li> Creating artwork as per material originator's brief</li>
                          <li> Creating formatted PI PDFs  (UK only and Nordics as well)</li>
                        </ul>
                      </td>
                    </tr>


                  </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>What it Stands for  </strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                      Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                 
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool </td>
                    <td className="tb">Planning and execution tools combine to form the CET. With CET, customer plans can be created and campaigns can be managed in one place. </td>
                  </tr>

                  <tr>
                    <td className="tb">Conversion Metric </td>
                    <td className="tb"></td>
                    <td className="tb">Metrics used to measure the number of valuable actions customers take through a digital marketing channel. </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey </td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">Goals or Metrics set behind a campaign and or tactic.</td>
                  </tr>
                  <tr>
                    <td className="tb">PMC</td>
                    <td className="tb">Permissions Managed Content </td>
                    <td className="tb">An image which is owned by an entity other than Lilly and for which permission is given to use under a specifc set of circumstances as defined in a contractural agreement between Lilly and the Content Owner. </td>
                  </tr>

                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"> </td>
                    <td className="tb">Tactics are specific to selected channels to drive customers to reach a piece of content. Tactics are associated with estimated KPI metric (goal) and result reports (actuals).  </td>
                  </tr>
                 
                  <tr>
                    <td className="tb">VVPM</td>
                    <td className="tb">Veeva Vault PromoMat</td>
                    <td className="tb">Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems. PromoMats is a Vault application to manage the process of creating, reviewing and approving promotional materials, claims and supporting information.</td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
            {/*End Defination*/}
{/* Start Core Docs */}
<div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Documents</h4>
<br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.InternalBriefingCoreDoc}
                  target="_blank"
                >
                  Internal Documents and Briefs document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/* End Core Docs */}
            {/*Add Faqs*/}
            <div
              className="tab-pane fade tab-hide"
              id="v-pills-faqs"
              role="tabpanel"
              aria-labelledby="v-pills-faqs-tab"
            >
              <h4 className="h1-font comman_hd">FAQs</h4>
              <div id="FAQ1">
                <Accordion
                  className="faqAccordion"
                  title="What are the important steps while engaging with an SOA?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      There are seven steps while engaging with SOA:
                    </p>
                    <ul className="ul_padding microsites-decimal-list">
                      <li> Selecting the SOA
                      </li>
                      <li>
                        Briefing the SOA
                      </li>
                      <li>
                        Conducting Risk Assessments
                      </li>
                      <li> Ensuring correct Procurement procedures are followed </li>
                      <li> Creating materials </li>
                      <li> Managing materials  </li>
                      <li> Withdrawing materials</li>
                    </ul>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ2">
                <Accordion
                  className="faqAccordion"
                  title="How do I know who the best SOA Vendors are to work for my brand or Therapy Area?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                    With so many Vendors to choose from, it is important to understand the answers to the following questions to select the best option for the campaign. Find the answers to all these questions (and more!) on SOA Navigator <a href={webConfig.SOANavigatorHome} target="_blank">SOA-Navigator - Home (lilly.com)</a>. 
                    </p>
                    <ul className="ul_padding microsites-unorder-list">
                      <li>Which SOA facilitates what kind of tactics? </li>
                      <li>What is their reach within the Target customer base? </li>
                      <li>How much could the campaign cost? </li>
                      <li>What level of customer engagement could be generated? </li>
                      <li>What specification of artwork do they require?</li>
                      <li>What documents will be required for MLR approval of your tactics?</li>
                    </ul>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ3">
                <Accordion
                  className="faqAccordion"
                  title="I am from PRA and/or Medical function, how do I find an SOA relevant to my business requirement and target base?  "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>SOA Navigator provides SOA Navigator <a href={webConfig.SOANavigatorHome} target="_blank">SOA-Navigator - Home (lilly.com)</a> details on Third Party SOA partner for Key Account Managers (KAMs) and Medical function as well.  </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ4">
                <Accordion
                  className="faqAccordion"
                  title="What is DIM/PIA and when do I need this for SOA campaign? "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>Data Inventory Management (DIM) or Privacy Impact Assessment (PIA) needs to be completed if data is being exchanged, stored or processed through SOA campaign. For any doubt, please get in touch with local E&C when sharing/exchanging customer data.    </p>
                  </div>
                </Accordion>
              </div>
              
              <div id="FAQ5">
                <Accordion
                  className="faqAccordion"
                  title="How do I know if the SOA I want to work with is on the preferred Vendor list?   "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p><a href={webConfig.PreferredSupplierList} target="_blank">Preferred Supplier List.xlsx (lilly.com)</a>: You can see the list of preferred Vendors for NE Hub countries. Material Owners are encouraged with the Vendor in the list. Alternatively, discuss this with the Procurement team.  </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ6">
                <Accordion
                  className="faqAccordion"
                  title="How do I know if Vendor has completed all the Procurement and compliance requirements?  "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>If the Vendor is on the Preferred Vendor list for the tactic you are engaging them for, they would have performed Risk Assessment. For any clarification, you can either reach out to their designated Engagement Owner or Procurement. For a New Vendor, there will need to be an Engagement Owner assigned from Business (likely to the team that wishes for New Vendor to be onboarded) and the Engagement Owner will have all the updates on assessments being completed for the Vendor.    </p>
                  </div>
                </Accordion>
              </div>

              <div id="FAQ7">
                <Accordion
                  className="faqAccordion"
                  title="My SOA vendor wants to take me for dinner to discuss the campaign. Can I go?  "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>A. Probably not. You need to adhere to the Lilly Expenses SOP meal limits, even though you are not paying for the meal yourself. Refer to the internal policy document.</p>
                  </div>
                </Accordion>
              </div>

              <div id="FAQ8">
                <Accordion
                  className="faqAccordion"
                  title="The SOA is committed to meeting certain KPIs, but they haven’t managed to achieve the target goals. What are my options?  "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>A. To ensure that we meet our campaign metrics, it is crucial to follow up on the performance of the campaign. If the SOA does not meet the expectations, you should contact the SOA and explore potential alternatives to improve the performance. Options could be to include an additional traffic driver for the campaign or extend the campaign period at no extra cost. If the campaign cost is above £25,000, we must involve the Procurement in our discussion.</p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ9">
                <Accordion
                  className="faqAccordion"
                  title="SOA is asking for money to be paid in advance. Can I do this?  "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>A. Lilly Procurement policy does not support pre-payments. </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ10">
                <Accordion
                  className="faqAccordion"
                  title="I need to stop my SOA campaign mid-way through. What are my rights in regard to the refund part of the original Purchase Order (PO)?   "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>A. You need to review your contract and examine the rights outlined there. Collaborate closely with the Procurement team to understand your contractual rights.   </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ11">
                <Accordion
                  className="faqAccordion"
                  title="Are SOA emails implicated by the NE Hub email guidance?    "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>A. Yes. SOA emails must be considered when limiting the number of times, the content is sent to the same customers. Please refer to the <a href={webConfig.NEHubEmailVolumeandGuidance} target="_blank">NE Hub Email Operations Guidance</a> for full details. </p>
                  </div>
                </Accordion>
              </div>


              
            </div>
            {/*End Faqs*/}
          </div>
        </div>
      </div>

      {/* popup -1 */}
      <div id="ib-popup-1" className="overlay_flight_traveldil">
          <div className="popup_flight_travlDil">
          <a className="close_flight_travelDl" href="#/">
              &times;
            </a>
            <p className="flowContent mt-4">
            UK: You must initiate a separate approval for an internal briefing guide to be added as a supporting document – see the flow for internal documents. 
            </p>
          </div>
        </div>
        {/* popup - 2 */}
        <div id="ib-popup-2" className="overlay_flight_traveldil">
          <div className="popup_flight_travlDil">
          <a className="close_flight_travelDl" href="#/">
              &times;
            </a>
            <p className="flowContent mt-4">
            <b>NORDICS - </b>If the material gets the verdict ‘not certified’ you can use ‘create draft’ function in VVPM to use the same job bag code for the updated material.  
            </p>
            
            
          </div>
        </div>
        {/* popup - 3 */}
        <div id="ib-popup-3" className="overlay_flight_traveldil">
          <div className="popup_flight_travlDil">
          <a className="close_flight_travelDl" href="#/">
              &times;
            </a>
            <p className="flowContent mt-4">
            <b>NORDICS - </b>Please check the <a target="_blank" href={webConfig.NordicPMAPGuidance}>Nordic PMAP Guidance</a> for detailed information on statutory copy submission. 
            </p>
            
            
          </div>
        </div>
    </div>
  );
};


export default InternalBriefing;
