import React from "react";
import { Link } from 'react-router-dom';
import {webConfig} from '../configs/webConfig'

function Navbar() {
 return (
    <ul id="navBar" className="nav nav-tabs" role="tablist">
      <li className="nav-item logoImage">
        <img src="/images/E2E_logo.png" width="50" />
      </li>
      <li className="nav-item">
        <a className="nav-menu-link nav-link active" href="/">
          Home
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-menu-link nav-link" href="/HighLevelProcess">
          High Level Process
        </a>
      </li>
      <li className="nav-item dropdown">
        <a
          className="nav-menu-link nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          href="#"
        >   
          Tactics, Processes and Materials
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <li>
            <a className="nav-dropdown-link dropdown-item" href="/be">
              Broadcast Emails (BE)
            </a>
          </li>
          <li>
            <a className="nav-dropdown-link dropdown-item" href="/cookie">
              Cookie Policy
            </a>
          </li>
          <li>
            <a className="nav-dropdown-link dropdown-item" href="/IVA">
              Interactive Visual Aids (IVA)
            </a>
          </li>
          <li>
            <a className="nav-dropdown-link dropdown-item" href="/internal-briefing">
              Internal Briefing Guides
            </a>
          </li>
          <li>
            <a className="nav-dropdown-link dropdown-item" href="/passport">
              Lilly Passport
            </a>
          </li>
          <li className="dropend"> 
            <a
              className="nav-dropdown-link dropdown-item"
              data-bs-toggle="dropdown"
              href="#"
            >
              Meetings
              <span className="dropdown-toggle"></span>
            </a>
            <ul className="dropdown-menu"> 
              <li>
                <a className="nav-dropdown-link dropdown-item" href="/meetings">
                  Meetings
                </a>
              </li>
              <li>
                <a className="nav-dropdown-link dropdown-item" href="/meetings/symposium-meeting">
                  Symposium–Face-to-Face or Virtual
                </a>
              </li>
              <li>
                <a className="nav-dropdown-link dropdown-item" href="/meetings/stand-booth-detailing">
                  Stand or Booth Detailing
                </a>
              </li>
              <li>
                <a className="nav-dropdown-link dropdown-item" href="/meetings/head-office-meetings">
                  Head Office Face to Face Meetings
                </a>
              </li>
              <li>
                <a className="nav-dropdown-link dropdown-item" href="/meetings/local-webinar">
                  Local Webinar / eP2P
                </a>
              </li>
              <li>
                <a className="nav-dropdown-link dropdown-item" href="/meetings/ibu-webinar">
                  IBU Webinar / eP2P
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a className="nav-dropdown-link dropdown-item" href="/pmc">
              Permission Managed Content (PMC)
            </a>
          </li>
          <li>
            <a className="nav-dropdown-link dropdown-item" href="/Pm">
              Physical Material 
            </a>
          </li>
          <li>
            <a className="nav-dropdown-link dropdown-item" href="/privacy">
              Privacy Policy Notice
            </a>
          </li>
          <li>
            <a className="nav-dropdown-link dropdown-item" href="/slidedecs">
              Slide Deck Creation
            </a>
          </li>
          <li className="dropend"> 
            <a
              className="nav-dropdown-link dropdown-item"
              data-bs-toggle="dropdown"
              href="#"
            >
              Source of Authority (SOA)
              <span className="dropdown-toggle"></span>
            </a>
            <ul className="dropdown-menu">
              <li>
                <a className="nav-dropdown-link dropdown-item" href="/soa">
                  Source of Authority (SOAs)
                </a>
              </li> 
              <li>
                <a className="nav-dropdown-link dropdown-item" href="/soa/soa-microsites">
                  SOA Microsites
                </a>
              </li>
              <li>
                <a className="nav-dropdown-link dropdown-item" href="/soa/soa-banners">
                  SOA Banners
                </a>
              </li>
              <li>
                <a className="nav-dropdown-link dropdown-item" href="/soa/soa-emails">
                  SOA Emails
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a className="nav-dropdown-link dropdown-item" href="/VAE">
              Veeva Approved Emails (VAE)
            </a>
          </li>
          <li>
            <a className="nav-dropdown-link dropdown-item" href="/video">
              Video
            </a>
          </li>
          <li>
            <a className="nav-dropdown-link dropdown-item" href="/Website">
              Websites
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item">
        <a
          className="nav-menu-link nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          href="#"
        >
          Useful links
        </a>
        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <li>
            <a
              className="nav-dropdown-link dropdown-item"
              href={webConfig.PMAPProcedure}
              target="_blank"
            >
              PMAP Procedure
            </a>
          </li>
          <li>
            <a
              className="nav-dropdown-link dropdown-item"
              href={webConfig.PMAPReference}
              target="_blank"
            >
              PMAP Reference
            </a>
          </li>
          <li>
            <a
              className="nav-dropdown-link dropdown-item"
              href={webConfig.PMAPForums}
              target="_blank"
            >
              PMAP Forums
            </a>
          </li>
          <li>
            <a
              className="nav-dropdown-link dropdown-item"
              href={webConfig.MedicalSPCCategorisation}
              target="_blank"
            >
              Medical SPC Categorisation
            </a>
          </li>
          <li>
            <a
              className="nav-dropdown-link dropdown-item"
              href={webConfig.MHRAVetting}
              target="_blank"
            >
              MHRA Vetting (lilly.com)
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item">
        <a className="nav-menu-link nav-link" href="/ContactUs">
          Contact Us
        </a>
      </li>
  </ul>
  );
}

export default Navbar;

