import React from "react";
import {webConfig} from '../configs/webConfig'

const ContactUs = () => {
  return (
    <>
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact Us
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">Contact Us</h2>
            <p>
              <a href={webConfig.MeetTheTeam} target="__blank">Meet the team</a>
            </p>
            <p>For any queries related to the content of this portal, please reach out to your local Champion, OCE Manager or Medical Certifier.</p>
            <p>For any technical queries related to the functionality of the Portal, please contact Tech@Lilly. </p>
            <p>Thank you! </p>
          </div>
        </div>
      </div>
    </>
  )
};
export default ContactUs;
