import "./css/App.css";
import CookieConsent from "./components/cookieWidgetMixins";
import { PropTypes } from "prop-types";
import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate,Navigate } from "react-router-dom";
import BE from "./pages/BE";
import Cookie from "./pages/Cookie";
import ContactUs from "./pages/ContactUs";
import HighLevelProcess from "./pages/HighLevelProcess";
import Home from "./pages/Home";
import IVA from "./pages/IVA";
import Meetings from "./pages/Meetings";
import SymposiumMeeting from "./pages/SymposiumMeeting";
import HeadOfficeMeetings from "./pages/HeadOfficeMeetings";
import StandBoothDetailing from "./pages/StandBoothDetailing";
import IbuWebinar from "./pages/IbuWebinar";
import IbuOn24 from "./pages/IbuOn24";
import LocalWebinar from "./pages/LocalWebinar";
import Physicalmaterial from "./pages/Pm";
import Passport from "./pages/Passport";
import Privacy from "./pages/Privacy";
import SOA from "./pages/SOA";
import SlideDecs from "./pages/SlideDecs";
import PMC from "./pages/PMC";
import VAE from "./pages/VAE";
import Website_front from "./pages/Website_front";
import Website from "./pages/Website";
import NavBar from "./components/Navbar";
import Footer from "./components/Footer";
import { CustomNavigationClient } from "./utils/NavigationClient";
import MsalAuthLayout from "./utils/MsalAuthLayout";
import { MsalProvider } from "@azure/msal-react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import LocalOn24 from "./pages/LocalOn24";
import SoaMicroSites from "./pages/SoaMicroSites";
import SoaBanners from "./pages/SoaBanners";
import SoaEmails from "./pages/SoaEmails";
import InternalBriefing from "./pages/InternalBriefing";
import Video from "./pages/Video";


const App = ({ pca }) => {
  const cookieBannerConsent = CookieConsent()
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);
  
  return (
    <div id="app">
      <MsalProvider instance={pca}>
        <AuthenticatedTemplate>
            <NavBar />
        </AuthenticatedTemplate>
        <main>
          <Routes>
            <Route element={<MsalAuthLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/HighLevelProcess" element={<HighLevelProcess />} />
                <Route path="/VAE" element={<VAE />} />
                <Route path="/BE" element={<BE />} />
                <Route path="/IVA" element={<IVA />} />
                <Route path="/Meetings" element={<Meetings/>} />
                <Route path="/Meetings/symposium-meeting" element={<SymposiumMeeting/>} />
                <Route path="/Meetings/stand-booth-detailing" element={<StandBoothDetailing/>} />
                <Route path="/Meetings/local-webinar" element={<LocalWebinar/>} />
                <Route path="/Meetings/local-webinar/local-on24" element={<LocalOn24/>} />
                <Route path="/Meetings/ibu-webinar" element={<IbuWebinar/>} />
                <Route path="/Meetings/ibu-webinar/IbuOn24" element={<IbuOn24/>} />
                <Route path="/Meetings/head-office-meetings" element={<HeadOfficeMeetings/>} />
                <Route path="/soa/soa-microsites" element={<SoaMicroSites/>} />
                <Route path="/soa/soa-banners" element={<SoaBanners/>} />
                <Route path="/soa/soa-emails" element={<SoaEmails/>} />
                <Route path="/internal-briefing" element={<InternalBriefing/>} />
                <Route path="/ContactUs" element={<ContactUs />} />
                <Route path="/Pm" element={<Physicalmaterial />} />
                <Route path="/Website_front" element={<Website_front />} />
                <Route path="/Website" element={<Website/>} />
                <Route path="/Passport" element={<Passport/>} />
                <Route path="/Privacy" element={<Privacy/>} />
                <Route path="/Cookie" element={<Cookie/>} />
                <Route path="/SOA" element={<SOA />} />
                <Route path="/SlideDecs" element={<SlideDecs />} />
                <Route path="/PMC" element={<PMC />} />
                <Route path="/Video" element={<Video />} />
                <Route path="*" element={<Navigate to="/" replace/>} />
            </Route>
          </Routes>
        </main>
        <AuthenticatedTemplate>
            <Footer />
        </AuthenticatedTemplate>
      </MsalProvider>
    </div>  
  );
};

export default App;

App.propTypes = {
  pca: PropTypes.any
};