import React from "react";
import { useState, useRef, useEffect } from "react";
import {webConfig} from '../configs/webConfig'
import { Link } from 'react-router-dom';
import LendingCard from "../components/LendingCard.js";
import "../css/LocalWebinar.css";
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/Meetings_Phases_SVG/Local_Webinar_Phase_1.svg";
import { ReactComponent as Phase3 } from "../SVG/Meetings_Phases_SVG/Local_Webinar_Phase_3.svg";
import { ReactComponent as Phase4 } from "../SVG/Meetings_Phases_SVG/Local_Webinar_Phase_4.svg";



const LocalWebinar = () => {
  const [isPhase3Open, setIsPhase3Open] = useState();

  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("TLMAP");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("Pooling");

  const svgRef4 = useRef();
  const [P4visibleSection, setP4visibleSection] = useState("OCE");

  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    
    rect1.addEventListener("click", () => {
      setP1visibleSection("TLMAP");
    });
    rect2.addEventListener("click", () => {
      setP1visibleSection("CETMeeting");
    });
    rect3.addEventListener("click", () => {
        setP1visibleSection("RegisterMeetingInMercury");
    });
    rect5.addEventListener("click", () => {
        setP1visibleSection("Production");
    });
    rect6.addEventListener("click", () => {
        setP1visibleSection("SpeakerContracting");
    });
    rect7.addEventListener("click", () => {
        setP1visibleSection("TraningAndBriefing");
    });

  }, []);

  useEffect(() => {
    const svg = svgRef3.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    rect1.addEventListener("click", () => {
      setP3visibleSection("Pooling");
    });
    rect2.addEventListener("click", () => {
        setP3visibleSection("FinalBriefing");
    });
  }, []);

  useEffect(() => {
    const svg = svgRef4.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    rect1.addEventListener("click", () => {
      setP4visibleSection("OCE");
    });
    rect2.addEventListener("click", () => {
      setP4visibleSection("AttendeeDataToLMC");
    });
    rect3.addEventListener("click", () => {
        setP4visibleSection("AttendeeToMercury");
    });
    rect4.addEventListener("click", () => {
        setP4visibleSection("DataFlow");
    });
    rect5.addEventListener("click", () => {
        setP4visibleSection("NBESuggestions");
    });
    rect6.addEventListener("click", () => {
        setP4visibleSection("AttendeeDataToCFT");
    });
  }, []);


  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/meetings">Meetings</Link>  
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    Local Webinar (eP2P)   
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">  Local Webinar (eP2P)  </h2>
            <p>
              <em>
                    Local Webinar or eP2P is a Lilly organised Meeting set up in a way so that 
                    the Speaker presents virtually from comfort of their own office/home to a group 
                    of healthcare professionals (HCPs) attending virtually from different locations. 
                    These events or Meetings are arranged and owned locally by the affiliate 
                    (opposed to International Business Unit [IBU] or Global), usually by Marketing or 
                    Medical and can be Meetings to promote or Meetings to educate. 
              </em>
            </p>
            <p>
              <em>
                    Meetings to promote provide HCPs with appropriate and accurate information for 
                    prescribing, dispensing, supplying, purchasing, accessing and/or reimbursing 
                    and/or using of a Lilly product.  
              </em>
            </p>
            <p>
              <em>
                    Meetings to educate provide relevant, accurate and balanced high-quality education 
                    to HCPs to further their professional development.    
              </em>
            </p>
            
          </div>
        </div>
      </div>

      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link navLinkTab active"
              data-bs-toggle="tab"
              href="#processPreRequisites"
            >
              Process
              <br />
              Pre-requisites
            </a>
          </li>
          <li className="nav-item">
            <a
                className="nav-link navLinkTab"
                data-bs-toggle="tab"
                href="#VeevaJobBagStandards"
            >
                Veeva Job 
                <br />
                Bag Standards 
            </a>
          </li>
        </ul>
        
        <div id="tabContent" className="tab-content">
            <div id="processPreRequisites" className="tab-pane fade in active tabs_box tabContent">
                <div>
                  <ul className="ul_padding">
                    <li className='tabContent'> Process subject to approval of activity in TL-MAP. </li>
                    <li className='tabContent'>
                         Process subject to completion of NE HUB CMS: Head-Office Meeting Training (Course 9397172) and associated procedures.
                         (All Meetings related procedures can be found at {' '}
                         <Link
                            to={webConfig.MeetingsRelatedInformation}
                            target="_blank"
                          > Meetings Related Information </Link> {' '} .) 
                    </li>
                    <li className='tabContent'>
                         Process subject to Mercury training and access – contact Lilly Meetings & Congresses team (LMC) at <strong>LMC_HO@lilly.com </strong>. 
                    </li>
                  </ul>
                </div>
            </div>
            <div id="VeevaJobBagStandards" className="tab-pane fade tabs_box tabContent">
                <p className="tabsContentHeading">Piece:</p>
                <p>
                   The webinar/webcast materials can be approved as one piece or separately as follows: 
                </p>
                <ul className="ul_padding">
                    <li>
                         Screenshot of the console and PI (ensure that all widgets are screenshot). 
                    </li>
                    <li>
                         Screenshot of registration page, lobby page and automated/administrative registration confirmation emails that are triggered by ON24 platform. 
                    </li>
                </ul>

                <p className="tabsContentHeading">Title field:</p>
                <p>Webinar/Meeting Title – This should be same as the actual meeting title and should match the input on the Customer Engagement Tool (CET) as well.  </p>

                <p className="tabsContentHeading">Purpose Field:</p>
                <ul className="ul_padding">
                   <li>  Explain the purpose of the webinar. </li>
                </ul>

                <p className="tabsContentHeading">Method of Dissemination:</p>
                <p>In the method of Dissemination Details field, outline how traffic will be driven to the sites (e.g., print invitation, broadcast email etc.).  </p>
                <ul className="ul_padding">
                   <li>   If the Method of Dissemination in the metadata states that the slides can be downloaded or sent via email to healthcare professionals (HCPs), the following disclaimer is to be added to the first slide: 
                        <em> “The content of these slides is Lilly property. You are permitted to download and use these slides for your own personal use only. Under no circumstances should these slides be distributed to third parties.”</em>  
                    </li>
                </ul> 

                <p className="tabsContentHeading">Attachments: </p>
                <ul className="ul_padding">
                   <li>  Change Document Template (if applicable).  </li>
                   <li>  Document with links to live sites (put the registration, lobby and console page hyperlinks in a document and add to Attachments of the piece).  </li>
                </ul> 

                <p className="tabsContentHeading">Linked Documents:  </p>
                <ul className="ul_padding">
                   <li>  Current PI (if PI is included on Video rather than a link to PI)</li>
                   <li>  Content references anchored appropriately (if applicable) </li>
                   <li>  Link any other resources available within the console, e.g., workbooks or certificates. </li>
                   <li>  Slides or pre-recorded video   </li>
                </ul>  

                <p className="tabsContentHeading">Related Permission Managed Content:  </p>
                <ul className="ul_padding">
                   <li>  Include required Permission Managed Content (PMC) documents, for example, speaker’s photo.  </li>
                   <li>  PMC is not required for <strong>Lilly product images</strong>. Check with Regulatory or Quality Assessment (QA) if it is the correct device image.  </li>
                </ul>  

                <p className="tabsContentHeading">Registration Page:   </p>
                <ul className="ul_padding">
                   <li>  If the webinar is running at two or three timeslots during the day, only screenshot one and add a note to state the second/third time on the piece. </li>
                   <li>  At certification, Approvers need to see two/three separate times with links which <strong>should</strong> be in the Attachments.  </li>
                   <li>  If using Lilly Passport, screenshot the page as part of the job bag and link the Related Documents to the Lilly Passport job bag.  </li>
                </ul> 

                <p className="tabsContentHeading">Lobby Page:  </p>
                <ul className="ul_padding">
                   <li>  Screenshot the lobby page as part of the piece if the webinar is at one time. </li>
                   <li>  If the webinar has two or three timeslots, add a note to the piece that states the extra time. The only change is the time of the webinar.  </li>
                   <li> 
                         Ensure that the following statements are on the lobby page:
                        <ul>
                            <li> Disclaimer for funding of events </li>
                            <li> “This calendar notification is for the sole use of the intended recipients and should not be forwarded.” </li>
                        </ul>  

                   </li>
                </ul>  

                <p className="tabsContentHeading">Console:</p>
                <p className="tabsContentHeading">Fully label the console page, and signpost everything on the page.  </p>
                <ul className="ul_padding">
                   <li>  If there is a third-party site being linked, ensure that the following disclaimer is added to the console page <em>“The x widget will take you to a site maintained by a third party who is solely responsible for the content. Eli Lilly and Company does not control or endorse this website and is not responsible for its privacy policy.”</em> </li>
                   <li>  When using polling, the poll questions <strong>should</strong> be certified as part of the speaker slides. Attach the screenshot of format of the poll to see how it looks as a part of the console.  </li>
                   <li>  Link slides to the Related Piece as part of the job bag.  </li>
                   <li>  Check if the Privacy Notice is present.    </li>
                </ul>

                <p className="tabsContentHeading">Email Notifications:   </p>
                <ul className="ul_padding">
                   <li>  Adverse Event/Product Complaint (AE/PC) wording to be on all promotional emails. </li>
                   <li>  If the product name is mentioned, it needs the PI.  </li>
                   <li>  Add all emails in Attachments. </li>
                   <li>  Include screenshot of the opt-out page.    </li>
                </ul> 

                <p className="tabsContentHeading">Calendar Notifications:   </p>
                <ul className="ul_padding">
                   <li>  Screenshot one and signpost change if there is more than one timeslot. </li>
                   <li>  Ensure that the following statement is added on calendar notifications: “This calendar notification is for the sole use of the intended recipients and should not be forwarded."   </li>
                   <li>  Screenshot the outlook and chrome calendar visuals. </li>
                   <li>  Include screenshot of Notification(s)   </li>
                </ul> 

                <p className="tabsContentHeading">Subject Line:  </p>
                <ul className="ul_padding">
                   <li>  No need for “promotional webinar” in subject line; however, it may be appropriate to include “Lilly meeting/event invitation” in the subject line. </li>
                   <li>  When sending an Invitation Email, same email content with different subject line and/or pre-header will require a separate job bag.  </li>
                   <li>  Sender name on platform emails should have Lilly mentioned (e.g., Lilly Dermatology/Lilly Diabetes). </li>
                </ul> 

                <p className="tabsContentHeading">Final Form: </p>
                <ul className="ul_padding">
                   <li>  Live test sites with working functionality. </li>
                </ul> 

                <p className="tabsContentHeading">Notes:</p>
                <ul className="ul_padding">
                   <li>  The Originator <strong>should</strong> check the customer journey and all links before putting the job bag into approval.</li>
                </ul>


            </div>
        </div>
      </div>
      
      {/*Add verticle Tab*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
            <button
              className="nav-link"
              id="v-pills-faqs-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-faqs"
              type="button"
              role="tab"
              aria-controls="v-pills-faqs"
              aria-selected="false"
            >
              FAQs
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">Local Webinar (eP2P) Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Pre-Meeting Planning"
                    defaultOpen={true}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning main-content">
                        <div className="comman_width_e">
                         <Phase1 ref={svgRef1} className="localWebinarphase1Shape1" />
                        </div> 
                      </div>
                      <div className="col-lg-7 planning p_font pt-3 flowsSecondPart side-content">
                      <div
                          style={{
                            display:
                              P1visibleSection === "TLMAP" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">Complete TL-Map Approval   </p>
                          <p className="flowsContent">
                             Ideally, this should be completed before in the calendar year as part of all the TL activity. 
                          </p>
                        </div> 
                        <div
                          style={{
                            display:
                              P1visibleSection === "CETMeeting" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">Meeting Details on CET  </p>
                          <p className="flowsContent">
                             Ideally, the Meeting should already be on the Customer Engagement Tool (CET) as part of the Annual Operational Planning process for the year. Review Meeting details, adjust KPIs (if needed) and link it with Mercury. 
                          </p>
                        </div>      
                        <div
                          style={{
                            display:
                              P1visibleSection === "Production"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Webinar Production   </p>
                          <p className="flowsContent">In all instances, Webinars should be held with the assistance of a production team. External agencies can be used if they have been approved and are preferred vendors, such as 7Stream or Creo.    </p>
                          <p className="flowsContent">The approved platform for Head-Office Webinars is ON24 as it provides better in-event functionality and compliance as well as easier post-event data capture.  </p>
                          <p className="flowsHeading">Remember!</p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                                First point of call should be the Digital Room (TECH@LILLY) which is now a fully functional Digital Room with filming/production capabilities.
                            </li>
                            <li className="flowsContent">
                                Information on the Digital Room and booking procedures can be found at {' '} 
                               <Link 
                                  to={webConfig.UKDigitalRoomAVBookingService}
                                  target='_blank'
                               >UK Digital Room A/V Booking Service</Link> {' '}. The request should be made minimum one month prior to the Meeting date.  
                            </li>
                            <li className="flowsContent">
                                The approved external agency or internal Digital Room team will hold a pre-event dry run with speakers to ensure a high-quality Meeting. It is advisable to fix a date with the production team and contracted Speakers in advance.  
                            </li>
                          </ul>
                        </div>

                        <div
                          style={{
                            display:
                              P1visibleSection === "SpeakerContracting" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">Speaker Contracting  </p>
                            <p className="flowsContent">
                                 Once a Speaker has been identified and confirmed, the Meeting 
                                 Owner should initiate contracting through Mercury. Prior to initiation, 
                                 the Meeting Owner should have confirmed with the Speaker on the proposed preparation, engagement 
                                 hours and the honorarium (as per the {' '}
                                <Link 
                                to={webConfig.FMVSOP}
                                target="_blank" 
                                >  FMV SOP 
                                </Link>{' '} ).
                                 If the Speaker is required to travel for the Meeting, 
                                 the Meeting Owner should capture their travel and accommodation requirements for entry into Mercury.  
                            </p>
                            <p className="flowsContent">
                               Contracting <strong>must</strong> be completed (the Master Service Agreement [MSA] and Work Order or Single Event Agreement are signed) prior to initiating any activity with the healthcare professional (HCP).  
                            </p>
                            <p className="flowsHeading">Remember!</p>
                            <p className="flowsContent">
                                The Meeting Owner must adhere to the following lead times when initiating contracting, or else an exception must be gained: 
                            </p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                   &gt; 9 weeks for a service provider without an MSA in place 
                                </li>
                                <li>
                                    &gt; 6 weeks for a service provider with an expired MSA  
                                </li>
                                <li>
                                    &gt; 3 weeks for a service provider with an MSA in place  
                                </li>

                            </ul>
                            <p className="flowsContent">
                                For information on Mercury and step-by-step guides on how to set up Speaker contracting, 
                                visit the <Link 
                                to={webConfig.LMCMercurySupportSite}
                                target="_blank" 
                                >  LMC Mercury Support Site </Link>{' '}.  
                            </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "RegisterMeetingInMercury" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">Register in Mercury    </p>
                            <p className="flowsContent">
                                All Meetings should be registered in Mercury by the Meeting Owner as soon as the date of the event is known. Registration requires the following details to be known:  
                            </p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                 Start Date and Duration 
                                </li>
                                <li className="flowsContent">
                                 Meeting Location 
                                </li>
                                <li className="flowsContent">
                                 Meeting Owner 
                                </li>
                                <li className="flowsContent">
                                 Estimated Budget Amount 
                                </li>
                                <li className="flowsContent">
                                 TL MAP ID 
                                </li>
                                <li className="flowsContent">
                                 Number of Estimated Participants  
                                </li>
                                <li className="flowsContent">
                                 Meeting Space Requirements 
                                </li>
                                <li className="flowsContent">
                                 Meeting Type
                                </li>
                                <li className="flowsContent">
                                 Cost Centre 
                                </li>
                                <li className="flowsContent">
                                 Proposed Agenda 
                                </li>
                            </ul>
                            <p className="flowsHeading">Remember!</p>
                            <p className="flowsContent">
                                Lead times for registration vary depending on estimated budget and Meeting size ranging from 3 months to 9 months lead time, so planning early is essential. 
                            </p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                 For more information on registration and lead times, review the {' '}
                                <Link 
                                to={webConfig.MeetingsLogisticsJobAid}
                                target="_blank" 
                                > Meetings Logistics Job Aid  
                                </Link>{' '}.
                                    
                                </li>
                                <li>
                                   For information on Mercury and step-by-step guides on how to register the Meeting, visit the {' '}
                                    <Link 
                                    to={webConfig.LMCMercurySupportSite}
                                    target="_blank" 
                                    > LMC Mercury Support Site
                                    </Link>{' '}.  
                                </li>

                            </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "TraningAndBriefing" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">Speaker Training and Briefing      </p>
                            <p className="flowsContent">
                               <strong>Speaker training</strong> is a global requirement to deliver certain information to a contracted HCP prior to them speaking on behalf of Lilly. Speaker training consists of training on product information, handling off-label questions, disclosing conflicts of interest and requirements for adverse events and/or product complaints reporting as well as any key local code requirements.  
                            </p>
                           
                            <p className="flowsHeading">Remember!</p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                   The Meeting Owner is responsible and accountable for the planning, implementation and documentation of Speaker training for Head-Office Meetings.  
                                </li>
                                <li>
                                    Medical are accountable for delivering the scientific elements of Speaker training.  
                                </li>
                                <li>
                                    For more information on Speaker training review the NE Hub HCP Speaker Training 
                                  procedure {' '}
                                  <Link 
                                    to={webConfig.SpeakerTrainingJobAid}
                                    target="_blank" 
                                    >  job aid</Link>. 
                                </li>
                            </ul>
                            <p className="flowsContent">
                               <strong>Speaker briefing</strong> is the process of verbal discussion. A document is sent by the Meeting Owner to the contracted service provider, prior to the Meeting taking place. The brief contains relevant logistical Meeting information, objectives, guidance on presentation and code obligations.   
                            </p>
                            <p className="flowsHeading">Remember!</p>
                            <ul className="ul_padding">
                                <li className="flowsContent">
                                    Each brand will have limits on the number of Speakers who may be trained on a product. Contact your brand team before any new Speaker training is planned. 
                                </li>
                                <li>
                                    Medical are accountable for delivering the scientific elements of Speaker training.  
                                </li>
                                <li>
                                    For more information on Speaker briefing review the Speaker Briefing
                                  <Link 
                                    to={webConfig.SpeakerBriefingJobAid}
                                    target="_blank" 
                                    >  job aid 
                                    </Link>{' '} . 
                                </li>
                            </ul>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion2" />
                  <Accordion
                    className="accordion2"
                    title="Phase 2: Pre-Meeting Activities – Material Readiness"
                    defaultOpen={false}
                  >
                    <div className= "accordion-heading mb-4">
                      <p>
                          Some tactics require attention prior to the Meeting to ensure that the Meeting can go ahead, and Meeting promotion/registration can commence. 
                      </p> 
                      <p>
                          For Head-Office Meeting, tactics that should be started at an early stage include: 
                      </p> 
                      <ul className="list-inline">
                          <li className="list-inline-item">&bull; Slide Deck</li>
                          <li className="list-inline-item">&bull; Video Recording</li>
                          <li className="list-inline-item">&bull; Webinar Platform (ON24)</li>
                          <li className="list-inline-item">&bull; Meeting Promotion</li>
                      </ul>
                    </div>
                    <div className="row  justify-content-center" style={{ margin: "0px" }}>

                        <LendingCard
                            {...{
                            title: "Slide Deck",
                            htmlString: `
                              <div className="card-content">
                                <p>Slide deck represents the content created for the webinar. The slides should be created way in advance and approved / certified through PMAP process. There are 2 types of slide deck:</p>
                                <ul className="ul_padding">
                                  <li>Lilly-created slides are produced without the involvement of an external speaker.</li>
                                  <li>Speaker-created slides are those which are researched and developed by the HCP who is going to present them.</li>
                                </ul>
                                <p className="mb-0">REMEMBER!</p>
                                <p > 
                                  The slide deck should be uploaded for Veeva Vault PromoMats (VVPM) approval at least 4 weeks before the event, however, good practice advises this timeline to be 6 weeks.
                                </p>
                            </div>
                            `,
                            links: [
                              { label: "Lilly Owned / Speaker Owned <br/> Slide deck", url: "/slidedecs" },
                            ],
                            flexColSize : "col-lg-3"
                            }}
                        />

                        <LendingCard
                            {...{
                            title: "Video Recording ",
                            htmlString: `
                              <div className="card-content">
                                <p>Webinar can be a live event (Speaker is live) or a pre-recorded event (some or all part of the Webinar is pre-recorded).  </p>
                              </div>
                            `,
                            links: [
                                { label: "Video creation and approval", url: "/video" },
                            ],
                            flexColSize : "col-lg-3"
                            }}
                        />
                        <LendingCard
                            {...{
                            title: "Webinar Platform (ON24) ",
                            htmlString: `
                              <div className="card-content">
                                <p>ON24 is the only NE Hub approved platform to host Head-Office lead Webinars. 
                                Developing the platform with considerations to the digital requirements imposed by 
                                the Code should always be a priority. The OCE Operations Manager creates the ON24 Platform. 
                                click on the tab below to understand the overall process.  
                                </p>
                              </div>
                            `,
                            links: [
                                { label: "Local On24 platform creation and approval", url: "/meetings/local-webinar/local-on24" },
                            ],
                            flexColSize : "col-lg-3"
                            }}
                        />
                        <LendingCard
                            {...{
                            title: "Meeting Promotion",
                            htmlString:`
                                <div className="card-content">
                                <p>These Webinars can be promoted through a variety of tactics such as: </p>
                                <ul className="ul_padding">
                                  <li> Broadcast Email - It is recommended to use a BE as 90% of registrations in the NE Hub come through this channel. Templates for invitations through BE are available with the Digital Solutions Team (DST).</li>
                                  <li> Veeva Approved Email (VAE) - VAEs are particularly important if the Webinar will also be available as a satellite Meeting for representatives to stream locally.</li>
                                  <li> Source of Authority (SOA)- SOA can have a wider reach to HCPs. Use SOAs to maximise Webinar attendance. Consider Opex implication as SOA promotion could be costly.</li>
                                  <li> Print Material - It is used by Customer-Facing teams to promote the Webinar. It is especially useful for a new brand where HCP database or e-consent might not be present with them.</li>
                                </ul>
                                </div>
                                `,
                            links: [
                                { label: "Physical Material For Print Invites", url: "/pm" },
                                { label: "Veeva Approved Emails", url: "/VAE" },
                                { label: "Broadcast Email", url: "/be" },
                                { label: "SOA", url: "/soa" },
                            ],
                            flexColSize : "col-lg-3"
                            }}
                        />
                      
                    </div>
                  </Accordion>

                  <div id="accordion3" />
                  <Accordion
                    className="accordion3"
                    title="Phase 3: Material Approval and Meeting Readiness"
                    defaultOpen={false}
                    isOpen={isPhase3Open}
                  >
                    <div className= "accordion-heading">
                        <p>
                            Depending upon the Pre-Event activities, material approval process will differ and depend on the materials selected. Different materials will have distinct approval processes. Once all promotional and other 'Pre-Event Activity' materials are certified and distributed, consider the following for smooth execution of the Meeting. 
                        </p> 
                    </div>
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                          <div className="comman_width_e">
                            <Phase3 ref={svgRef3} className="localWebinarShape3" />
                          </div>  
                      </div>
                      <div className="col-lg-7 planning flowsSecondPart">
                      <div
                          id="link1"
                          style={{
                            display:
                              P3visibleSection === "Pooling"
                                ? "block"
                                : "none",
                          }}
                        >
                            <p className="flowsHeading">Submit Polling Questions  </p>
                            <p  className="flowsContent">
                                Polling questions are certified as a separate job bag. Once certified, the Meeting Owner should provide these to the OCE Operations Manager for building into the ON24 platform. This should be done minimum of 3 working days prior to the event. 
                            </p>
                        </div>
                        <div
                          id="link1"
                          style={{
                            display:
                              P3visibleSection === "FinalBriefing"
                                ? "block"
                                : "none",
                          }}
                        >
                            <p className="flowsHeading">Final Briefing with Speaker    </p>
                            <ul className="ul_padding">
                                <li className="flowsContent">
                                    Ensure that the Speaker is briefed on compliance. This is done by a Medical team member.  
                                </li>
                                <li  className="flowsContent">
                                    The Speaker needs to complete a form confirming they have completed this training. The form is uploaded on Mercury.  
                                </li >
                                <li  className="flowsContent">
                                    National Therapeutic Advisor (NTA) or Brand Manager (BM) may do a strategic Meeting or a slide walk-through before the live event.   
                                </li>
                            </ul>
                            <p className="flowsHeading">REMEMBER </p>
                            <ul className="ul_padding">
                                <li className="flowsContent">
                                    The best practice is for the Meeting Owner to organise time and date with the Speaker and digital studio and/or production agency for a dry run. 
                                </li>
                                <li  className="flowsContent">
                                    Request for a dry-run platform to be created by the OCE Operations Manager if this is being considered. 
                                </li >
                            </ul>
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  {/*Accordion4*/}
                  <div id="accordion4" />
                  <Accordion
                    className="accordion4"
                    title="Phase 4: Post-Event Operations"
                    defaultOpen={false}
                  >
                    <div className= "accordion-heading">
                        <p>
                           There are some actions required to effectively close the Meeting. It is important to reconcile customer data. Reconciliation is the process of identifying attendees and capturing that attendance in Mercury. 
                        </p> 
                    </div>
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                        <Phase4 ref={svgRef4} className="localWebinarShape4" />
                      </div>
                      <div className="col-lg-7 planning p-3 flowsSecondPart">
                        <div
                          style={{
                            display:
                              P4visibleSection === "OCE"
                                ? "block"
                                : "none",
                          }}
                        >
                            <p className="flowsHeading">
                               Provide Meeting Details to OCE Operations Manager  
                            </p>
                            <p className="flowsContent">
                                OCE Operations Manager initiates Reconciliation (reconciliation is the process of identifying attendees and capturing that attendance in Mercury) of customer data. To complete this exercise, they should be provided with:
                            </p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                 Event Title 
                                </li>
                                <li className="flowsContent">
                                 Event Date 
                                </li>
                                <li className="flowsContent">
                                 Mercury ID   
                                </li>
                            </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P4visibleSection === "AttendeeDataToLMC"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">OCE Operations Manager Submits Attendee Data to LMC   </p>
                          <p className="flowsContent">Before submitting to LMC, OCE Operations Manager will download reports from ON24 and: </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Filter attendees to only show customers (taking out any test emails or Lilly employees). 
                            </li>
                            <li className="flowsContent">
                               Find any HCP IDs that are missing from the report.  
                            </li>
                          </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P4visibleSection === "AttendeeToMercury"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">LMC Uploads Attendee Details to Mercury  </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Wherever HCP ID is available, the upload on Mercury is enabled through a Bot. If HCP is not reconciled, LMC will attempt a manual look-up and manual reconciliation. 
                            </li>
                            <li className="flowsContent">
                               If the HCP is not identified, the delegate detail cannot be reflected on dashboards.   
                            </li>
                          </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P4visibleSection === "DataFlow"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Data Flows to Dashboards </p>
                          <p className="flowsContent">Mercury is connected to dashboards, so marketing teams can understand/analyse attendee segment by looking at Brand 360. Mercury is also linked to CET. If CET tactic ID is correctly linked, the CET dashboard will also auto-update. </p>
                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">
                               For majority of dashboards, the Meeting data will only be added either when the Meeting is marked as ‘closed’ on Mercury, or if the Meeting took place more than 4 weeks ago.  
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               It is also important that Meeting is entered into Mercury with all the required fields completed.  
                            </li>
                            <li className="flowsContent">
                               If Meeting details are not visible on dashboards, check DHATA 360 dashboard to see if there was any data input missing.  
                            </li>
                          </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P4visibleSection === "NBESuggestions"
                                ? "block"
                                : "none",
                          }}
                        >
                            <p className="flowsHeading">NBE Suggestions Triggered to CFT  </p>
                            <p className="flowsContent">
                                Next Best Engagement (NBE) is triggered as suggestions on Veeva for Customer-Facing teams (CFTs) to follow up with the target customers. This is an automated process enabled through a link between Mercury and Veeva CRM.
                            </p>
                            <p className="flowsHeading">Remember</p>
                            <ul className="ul_padding">
                              <li className="flowsContent">
                                  Customer-Facing teams will receive NBE only for target HCPs in their region. 
                              </li>
                            </ul>  
                        </div>
                        <div
                          style={{
                            display:
                              P4visibleSection === "AttendeeDataToCFT"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">OCE Operations Manager Shares Attendee List with CFT </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Customer-Facing teams receive Veeva suggestions through NBE. NBE prompts are limited to target HCPs in their region.  
                            </li>
                            <li className="flowsContent">
                                To keep the Customer-Facing teams aware of all the attendees within their customer universe, OCE Operations Manager shares the list of all attendees. 
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion5" />
                  <Accordion
                    className="accordion5"
                    title="Phase 5: Material Withdrawal"
                    defaultOpen={false}
                  >
                    <div className="row p-3" style={{ margin: "0px" }}>
                        <p className="flowsContent">
                         Different materials included in the planning of the Meeting should follow their distinct withdrawal process as applicable. A good inventory management of VVPM material job bags will include: 
                        </p>
                        <ul className="ul_margin_bottom">
                            <li className="flowsContent">
                               <strong>Invitation Emails</strong> (BE, Veeva Approved Email [VAE], SOA emails) are to be marked for ‘One-Time’ use and allowed to auto-expire after the event date. 
                            </li>
                            <li className="flowsContent">
                               <strong>Physical Material</strong> with specific Meeting date and details should be marked for ‘One-Time’ use and allowed to auto-expire. <strong>Remember</strong> to trigger Material and Retirement Status Application (MaRS) withdrawal notification for the Physical Material to be destroyed by the Customer-Facing team.  
                            </li>
                            <li className="flowsContent">
                               <strong>Meeting Slide Decks</strong> are usually marked for ‘One-Time’ use unless there is an intent to leverage the same slides for future Meetings. In this scenario, the VVPM metadata should reflect details in relevant fields.
                            </li>
                            <li className="flowsContent">
                               <strong>ON24 Platform</strong> After the event, the platform is withdrawn by ON24 through an automated system which replaces the previous details of registration page, lobby page and console with a disclaimer: "Thank you for your interest. This Webinar is no longer available." It is the responsibility of the OCE Operations Manager to check that the platform has been successfully withdrawn. 
                            </li>
                        </ul>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            {/*End Lifecycle */}

            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Role </th>
                        <th>Responsibility</th>
                        </tr>
                    </thead>  
                    <tbody>
                      <tr>
                         <td className="tb">
                              <h6>Veeva Owner</h6>
                          </td>
                          <td className='tb'>
                              <p>
                                  The Veeva Owner is also known as the Uploader (please see 'Veeva uploader')
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator</h6>
                          </td>
                          <td>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Having overall responsibility and accountability for the material</li>
                                  <li>2. Ensure that no item is used without the necessary approvals</li>
                                  <li>3. Ensuring timely change and/or withdrawal, where needed</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a>
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Uploader</h6>
                          </td>
                          <td>
                              <p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA
                                  teams, and is assigned the Veeva Owner role.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Uploading the material to Veeva</li>
                                  <li>2. Performing all activities related to upload</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Co-ordinator</h6>
                          </td>
                          <td>
                              <p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager (or similar
                                  role). </p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Reviewing the item before sending for MLR review</li>
                                  <li>2. Managing the document workflow</li>
                                  <li>3. Assigning reviewers</li>
                                  <li>4. Managing expiry or periodic review</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Business Reviewer</h6>
                          </td>
                          <td>
                              <p>Business Reviewer is an experienced commercial employee.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring all promotional materials align with the brand strategy, Lilly Brand Book, Product Brand
                                      Book</li>
                                  <li>2. Ensuring all materials are of good quality, have correct Veeva metadata and adhere to internal
                                      policies and local codes</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Medical Certifier</h6>
                          </td>
                          <td>
                              <p>Registered medical practitioner or UK-registered Pharmacist.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring that the material is in accordance with the requirements of the local code</li>
                                  <li>2. Ensuring that the material is not inconsistent with the marketing authorisation/SmPC (if applicable)
                                  </li>
                                  <li>3. Ensuring that the material is a fair and truthful representation of the facts about the medicine (if
                                      applicable)</li>
                                  <li>4. Approving the material in its final form</li>
                                  <li>5. Ensuring the material has the appropriate reviewers and that the Veeva metadata is correct.</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for ensuring that the Veeva metadata is correct and that the PMC
                                  documents are included (if applicable).</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordic Code Approver</h6>
                          </td>
                          <td>
                              <p>Code Approver must be a medical practitioner or a pharmacist (except for in Sweden) and is responsible for
                                  ensuring that the material is in line with the local Codes and that the material being approved has the
                                  appropriate reviewers; and ensuring that the Veeva metadata is correct.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordics Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for reviewing the material from a medical perspective.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Indegene</h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1. Creating artwork as per material originator brief</li>
                                  <li>2. Creating formatted PI PDFs</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Content conceptualisation</li>
                                  <li>2.  Brief to Indegene </li>
                                  <li>3.  Managing approval proces </li>
                                  <li>4.  Ensuring that a print request is raised with KonicaMinolta (or for simple materials, printed in the office) to provide hard copies for certification</li>
                                  <li>5.  Ensuring final print request is raised and material is delivered to final destination (e.g., warehouse)</li>
                                  <li>6.  Withdrawal </li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Global Librarian  </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Archiving Physical Material as per the request from the Material Originator</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Marketing Services  </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Business relationship owner for the printing agency (Indicia), creation agency (Indegene) and warehouse supplier across Hub (STACI, UK; Velox, FI, SE and DK and Logistikkhuset, NO)</li>
                                  <li>2.  Primary point of contact for Marketing teams in regards to feedback to above mentioned suppliers </li>
                                  <li>3.  Delivering trainings for (new) employees relevant to third tools and related material management processes  </li>
                                  <li>4.  Oversight and support role through material management process including material withdrawal process</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Konica Minolta  </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Providing printing proofs prior to print</li>
                                  <li>2.  Printing as per print request and delivering to defined location </li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator   </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Submitting online request form to build On24 platform</li>
                                  <li>2.  Managing approval process </li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>OCE Operations Manager   </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Building On24 platform as per timelines and the provided brief </li>
                                  <li>2.  Downloading attendance report from On24 and submitting to the LMC team for customer reconciliation in Mercury </li>
                              </ul>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>What it Stands for  </strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                    Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Stand</td>
                    <td className="tb"></td>
                    <td className="tb">
                       Designated space purchased by Lilly for a congress or event. This space can feature a stand with promotional imagery and pull-up banners.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Booth</td>
                    <td className="tb"></td>
                    <td className="tb">
                       Designated space purchased by Lilly for a congress or event. This space can feature a stand with promotional imagery and pull-up banners.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool </td>
                    <td className="tb">Planning and execution tools combine to form the CET. With CET, customer plans can be created and campaigns can be managed in one place. </td>
                  </tr>
                  <tr>
                    <td className="tb">Congress </td>
                    <td className="tb"></td>
                    <td className="tb">
                      Third party organised events in which Lilly purchases a sponsorship package for. This can include a stand, booth and/or symposium
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Conversion Metric </td>
                    <td className="tb"></td>
                    <td className="tb">Metrics used to measure the number of valuable actions customers take through a digital marketing channel. </td>
                  </tr>
                  <tr>
                    <td className="tb">CSA </td>
                    <td className="tb">Control Self Assessment </td>
                    <td className="tb">A quality check performed by process owners quarterly. The results are shared and discussed with HLT with measures put in place to mitigate any identified issues.  </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey </td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>
                  <tr>
                    <td className="tb">Cvent </td>
                    <td className="tb"></td>
                    <td className="tb">Hopsitality management platform that allows capture of customer details for meetings and events, such as registration details and logistics. </td>
                  </tr>
                  <tr>
                    <td className="tb">eP2P</td>
                    <td className="tb">Electronic peer to peer </td>
                    <td className="tb"> eP2P is the video broadcasting of P2P programs transmitted via internet either live or hosted (video recording) on Lilly or 3rd party sites. </td>
                  </tr>
                  <tr>
                    <td className="tb">HO</td>
                    <td className="tb">Head Office</td>
                    <td className="tb">A prefix to tactics owned by the teams who work from a Lilly office, such as medical and marketing.  </td>
                  </tr>
                  <tr>
                    <td className="tb">Hybrid P2P </td>
                    <td className="tb">Hybrid Peer to Peer  </td>
                    <td className="tb">A Lilly organized eP2P set up in such a way that some customers attend the meeting individually while others gather in groups to attend the meeting. (ie. Hub + spoke, satellite groups)  </td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">Goals or Metrics set behind a campaign and or tactic.</td>
                  </tr>
                  <tr>
                    <td className="tb">Leave piece </td>
                    <td className="tb"></td>
                    <td className="tb">A material which can be left at the HCP's desk.</td>
                  </tr>
                  <tr>
                    <td className="tb">Mercury</td>
                    <td className="tb"></td>
                    <td className="tb">Salesforce Marketing Cloud application for the planning, submission and approval of meetings.</td>
                  </tr>
                   <tr>
                    <td className="tb">P2P</td>
                    <td className="tb">Peer to peer </td>
                    <td className="tb">Lilly organized programs where an HCP (external or internal speaker) engages with other HCPs on disease state and therapeutic options through lectures and/or case study discussions. This can also be referred to as F2F events (face to face).  </td>
                  </tr>
                  <tr>
                    <td className="tb">PMC</td>
                    <td className="tb">Permissions Managed Content </td>
                    <td className="tb">An image which is owned by an entity other than Lilly and for which permission is given to use under a specifc set of circumstances as defined in a contractural agreement between Lilly and the Content Owner. </td>
                  </tr>
                  <tr>
                    <td className="tb">Symposium</td>
                    <td className="tb"> </td>
                    <td className="tb">A Lilly-owned lecture at a third party event or congress. </td>
                  </tr>
                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"> </td>
                    <td className="tb">Tactics are specific to selected channels to drive customers to reach a piece of content. Tactics are associated with estimated KPI metric (goal) and result reports (actuals).  </td>
                  </tr>
                  <tr>
                    <td className="tb">TL</td>
                    <td className="tb">Thought Leader</td>
                    <td className="tb">Thought Leaders influence thinking and/or treatment practice beyond his/her local market or practice on a regional, national or global basis. TLs are respected and acknowledged by their peers, other experts and key audiences as leaders within a particular subject matter. The term includes customers from clinical to academic to research to regulatory and the payer space.  </td>
                  </tr>
                  <tr>
                    <td className="tb">TL-MaP</td>
                    <td className="tb">Thought Leader Mapping and Planning Tool </td>
                    <td className="tb">The only approved global platform for planning and tracking contracted Thought Leader engagements. Among many other benefits, TL-MaP allows for increased line-of-sight for accountable leadership to mitigating risk when contracting TLs.  </td>
                  </tr>
                  <tr>
                    <td className="tb">VVPM</td>
                    <td className="tb">Veeva Vault PromoMat</td>
                    <td className="tb">Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems. PromoMats is a Vault application to manage the process of creating, reviewing and approving promotional materials, claims and supporting information.</td>
                  </tr>
                  <tr>
                    <td className="tb">Warehouse/Staci </td>
                    <td className="tb"></td>
                    <td className="tb">Location where all physical print materials are stored for distribution. The warehouse/staci online system allows management and monitoring of stock across brands.  </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Defination*/}

            {/*View Core Doc*/}
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <Link
                  className="CoreURL"
                  to={webConfig.LocalWebinarCoreDoc}
                  target="_blank"
                >
                  Local Webinar (eP2P) document here.{" "}
                </Link>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/*End Core Doc*/}

             {/*Add Faqs*/}
             <div
              className="tab-pane fade"
              id="v-pills-faqs"
              role="tabpanel"
              aria-labelledby="v-pills-faqs-tab"
            >
              <h4 className="h1-font comman_hd">FAQs</h4>
              <div id="FAQ1">
                <Accordion
                  className="faqAccordion"
                  title="Can I use Teams instead of ON24 for my upcoming Head-Office lead eP2P?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">  
                  <p>
                  No, ON24 is the only approved platform used for marketing lead webinars/eP2P. There are several reasons why:
                  </p>
                  <ul  className="ul_padding">
                    <li>
                        ON24 enables automated customer journey to send invitation emails. This reduces the work for you and for teams supporting you in running the webinar. 
                    </li>
                    <li>
                        Only ON24 is integrated with Mercury, Customer Engagement Tool (CET) and Data Dashboards. This means your attendee data is automatically uploaded on relevant places. This again, reduces work post the event. 
                    </li>
                    <li>
                        There is a potential risk in Teams where the Personal Information (Name and email) of attendees, might be visible to other attendees. 
                    </li>
                    <li>
                        ON24 has a better control over withdrawal of the material. No manual action is needed from your end. This again, saves time.  
                    </li>
                  </ul>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ2">
                <Accordion
                  className="faqAccordion"
                  title="What happens to the webinar video and the console after the webinar is over?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                    ON24 platform auto-withdraws the webinar details and the material. It is archived offline. 
                    </p>
                    <p>The action to do so is built in when the console is created by the OCE Operations Manager.  </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ3">
                <Accordion
                  className="faqAccordion"
                  title="How can I get trained on Mercury?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Mercury training videos and checklists are available here {' '}
                      <Link
                        className="CoreURL"
                        to={webConfig.LMCMercurySupportSite}
                        target="_blank"
                      >
                        Mercury support site (lilly.com)
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ4">
                <Accordion
                  className="faqAccordion"
                  title="What are the useful procedures for meetings?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Please visit this page {' '}
                      <Link
                        className="CoreURL"
                        to={webConfig.MeetingsRelatedInformation}
                        target="_blank"
                      >
                        Meetings Related Information (lilly.com)
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ5">
                <Accordion
                  className="faqAccordion"
                  title="How do I check my speaker training status?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      This can be found in Mercury and Veeva and on the PowerApp {' '}
                      <Link
                        className="CoreURL"
                        to={webConfig.GBSTrainedSpeakerListPowerApps}
                        target="_blank"
                      >
                        GBS - Trained Speaker List - Power Apps
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ6">
                <Accordion
                  className="faqAccordion"
                  title="Who is my POC in the Lilly Meetings and Congresses Team?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Please see org chart here:{' '}
                      <Link
                        className="CoreURL"
                        to={webConfig.HEHUBLMCOrgChart}
                        target="_blank"
                      >
                        HE HUB LMC Org Chart_Oct 2023.png (1920×1080) (lilly.com)
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
            </div>
            {/*End Faqs*/}
          </div>
        </div>
      </div>

    </div>
  );
};


export default LocalWebinar;
