import React from "react";
import "../css/Meetings.css";
import LendingCard from "../components/LendingCard.js";

const Meetings = () => {
  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Meetings
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">Meetings</h2>
            <p>
              <em>
                Meetings is a broad term used in Lilly for healthcare
                professional (HCP) interactions where Transfer of Value (TOV) is
                required to facilitate HCP interactions. This TOV could be to a
                speaker, an event organizer or to the venue and must be captured
                through the Customer Meeting Service (CMS) process.
              </em>
            </p>
            <em>
              <ul className="ul_padding">
                <li> A Meeting could be Promotional or Educational.</li>
                <li>
                   A Meeting could be owned by Sales or by the Head Office
                  team.
                </li>
                <li>
                   Meeting owned by Customer-Facing teams (Sales, Medical,
                  Value Access Manager [VAM]) could be Face-to-Face (called
                  P2P) or Virtual (called eP2P).
                </li>
              </ul>
            </em>
            <p>
              <em>
                The process flows included here capture End-to-End Lifecycle for
                different types of{" "}
                <strong>Meetings owned by the Head Office.</strong> These
                Meetings can be divided into several categories. Different
                Meetings will have different process and training requirements.
                Click on each Meeting type to understand these requirements.
              </em>
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <LendingCard
            {...{
              title: "Independent Meetings / Congress Events",
              htmlString: `
                <p>Independent meetings are non-Lilly owned events where we sponsor events or engagements through Stand Detailing, Delegate Sponsorship or a Promotional Symposium.</p>
                <p>Click on the relevant button to learn more about each Meeting type in this section.</p>
              `,
              links: [
                { label: "Symposium – <br/> Face-to-Face or Virtual", url: "/meetings/symposium-meeting" },
                { label: "Stand or Booth Detailing", url: "/meetings/stand-booth-detailing" },
              ],
              flexColSize : "col-lg-3"
            }}
          />

          <LendingCard
            {...{
              title: "Head Office Face-to-Face Meetings",
              htmlString: `
                <p>These events are organized by NE Hub teams as in-person meetings to promote or educate.</p>
                <p>Click on the tab to learn more about the process.</p>
              `,
              links: [
                {
                  label: "Head Office Face to Face Meetings",
                  url: "/meetings/head-office-meetings",
                },
              ],
              flexColSize : "col-lg-3"
            }}
          />

          <LendingCard
            {...{
              title: "Head Office Webinars",
              htmlString:`
                 <p>Webinar or eP2P is a Lilly organized Meeting set up in a way so that the Speaker presents virtually from the comfort of their own office/home to a group of HCPs in different locations. These events or Meetings are arranged and usually owned by Marketing or Medical and can be meetings to promote or meetings to educate.</p>
                 <p>A Webinar can be organized locally by the affiliate (Local eP2P) or by IBU teams for multiple geographies (IBU eP2P). Click on the relevant tab to learn more about each process.</p>
              `,
              links: [
                { label: "Local Webinar/ eP2P", url: "/meetings/local-webinar" },
                { label: "IBU Webinar  / eP2P", url: "/meetings/ibu-webinar" },
              ],
              flexColSize : "col-lg-4"
            }}
          />
        </div>
      </div>
    </div>
  );    
};

export default Meetings;


