import React from "react";

const Website = () => {
  return (
    <>
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Website
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font">Lilly Websites</h2>
            <p>
              <em>
                For this document and process detail, the term ‘website’ applies
                to Lilly owned websites, webpages and web tools. Each Lilly
                controlled website, webpage and tool must have a Lilly employee
                designated as its owner. The Website Owner is defined as the
                accountable person for the website. Please note that a website
                owner is a different role than a material owner. Website owner
                may be a Lilly employee ranging from brand associate, a
                marketer, or any other job title, depending on the country.
                Material owner will partner with a website custodian. A website
                custodian is the accountable person for the TECH@LILLY
                requirements of the website, webpage, or tool. The role of
                custodian may be played by different teams, for example
                Custodian for EWI websites is from global EWI team, custodian
                for locally hosted pages is from local TECH@LILLY team.{" "}
              </em>
            </p>
            <p>
              <em>
                A website that is not carefully designed, tested, maintained,
                and assessed poses a serious external risk to Lilly.
              </em>
            </p>
            <h5>
              <i>Terms used in this document</i>
            </h5>
            <ul className="ul_padding">
              <li>
                ●{" "}
                <i>
                  <b>Website</b> owner and <b>website custodian</b> is a
                  designated role that is identified for each website and listed
                  in Lilly Inventory management platform. Click{" "}
                  <a href="#" target="_blank">
                    here
                  </a>{" "}
                  to understand R&R of a website owner and a website custodian
                </i>
              </li>
              <li>
                ●{" "}
                <i>
                  <b>Delivery Manager</b> - Depending on the website/web
                  platforms, there will be different teams involved in
                  production of the asset. The term <b>‘Delivery manager’</b> is
                  used in this document as a generic role to cover any
                  individuals/teams that are working on producing the website,
                  web page or web tool. For example, EWI, TECH@LILLY, or agency
                  such as TCS could be the support team that helps develop the
                  webpage/website. They are called as Delivery Manager in this
                  document. Click here to see who the delivery team/manager for
                  different website types is.
                </i>
              </li>
              <li>
                ●{" "}
                <i>
                  <b>Tickets</b> – There are different ways to reach different
                  delivery teams. A form needs to fill in in most of the cases
                  and this is called creating a ticket. Click here to access the
                  link to these forms to create a ticket for desired work.
                </i>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container p-3 mb-5 tabs_class">
        <div className="row">
          <div className="tabs">
            <input
              type="radio"
              name="tabs"
              id="tabone"
              defaultChecked="checked"
            />
            <label htmlFor="tabone" id="tabone-content">
              Process Requisites
            </label>
            <div className="tab" id="tabone-content-1">
              <h1></h1>
              <p>
                <ul className="ul_padding">
                  <li>
                    &#9679; Stakeholder involvement - Process requires you to
                    involve your Tech@Lilly manager. Additional stakeholders are
                    needed when making major changes either to content or
                    wireframe of the website{" "}
                  </li>
                  <li>
                    (a). Website owners review needed
                    <br />
                    (b). Consider patient safety and reach out to GPS for review
                    <br />
                    (c). Review website checklist{" "}
                    <a href="" target="_blank">
                      here
                    </a>
                  </li>
                  <br />
                  <li>&#9679; Process requires technical training</li>
                  <li>
                    (a).{" "}
                    <a
                      href="https://elilily.plateau.com/learning/user/common/dispatchItemDetailActions.do?OWASP_CSRFTOKEN=G16K%2dYZ6H%2dPDUO%2dI94R%2dFIO8%2dURBO%2dB9GS%2dR4CN&studentID=2264761&studSysGuid=68EE9318DE712783E053A328080A3E45&actionName=launchContent&componentTypeID=COURSE&componentID=9204719&revisionDateMilliseconds=1598879820000&revisionDate=1598879820000&sku=SKU-1903210&cpntClassification=CONTINUOUS%20ONLINE%20ACCESS&addUser=P&forumID=&accessNewRevision=false&returnLink=%2Flearning%2Fuser%2Fcommon%2FviewItemDetails.do%3FstudentID%3D2264761%26componentTypeID%3DCOURSE%26componentID%3D9204719%26revisionDate%3D1598879820000%26fromDevPlan%3Dtrue%26hideActionBtns%3Dfalse%26fromApprovalsPage%3Dfalse%26fromHistory%3Dfalse%26checklistID%3Dnull%26encodedExternalParam%3Dundefined%26isExternalRequestFromCDP%3Dfalse%26goalID%3D%26componentKey%3D2703750&menuGroup=&menuItem=&fromDevPlan=true&fromCurrDetailsRequirements=false&quantity=1&shoppingAccountID=SHPNGACCTID-1211832&sortOrder=&sortBy=&showPage=showForum&titleLabelKey=title.Item.f133fe93edf541cf9f428f26cc0acd24&backto=%2Fuser%2Fcommon%2FviewItemDetails.do%3FstudentID%3D2264761%26componentTypeID%3DCOURSE%26componentID%3D9204719%26revisionDate%3D1598879820000%26fromDevPlan%3Dtrue%26hideActionBtns%3Dfalse%26fromApprovalsPage%3Dfalse%26fromHistory%3Dfalse%26checklistID%3Dnull%26encodedExternalParam%3Dundefined%26isExternalRequestFromCDP%3Dfalse%26goalID%3D%26componentKey%3D2703750&checklistID=&studCpntID=60528445&enrollSeatID=&openSection=none"
                      target="_blank" rel="noreferrer"
                    >
                      GCO: Global Website Owner Training for Websites, Webpages
                      and Tools COURSE 9204719{" "}
                    </a>
                    – All individuals working with EWI websites should take this
                    training. For a website owner, this is a mandatory training
                    to be taken annually (prompted by global S&C team) <br />
                    (b). Know about different types of websites, webpages – See
                    the details here.
                  </li>
                </ul>
                <h3></h3>
              </p>
            </div>
            <input type="radio" name="tabs" id="tabtwo" />
            <label htmlFor="tabtwo" id="tabtwo-content">
              VVPM Website Standards
            </label>
            <div className="tab" id="tabtwo-content-2">
              <h5>
                <em>Veeva approval standard practices for websites</em>
              </h5>
              <p>Piece:</p>
              <ul className="ul_padding">
                <li>
                  &#9679; For UK, IRELAND - Desktop and mobile site screenshots
                  of all pages including functionality (in the development
                  stage) The screenshots only need to be of the site with the
                  ‘hamburger’ menu (if final form is same) – you do not need
                  screenshots of what it looks like on an iPad, iPhone, Android
                  etc. unless you have asked for the website to be optimised for
                  a particular device Pop-ups{" "}
                </li>
                <li>
                  (a). If final forms are different (including colours and font
                  styles) then a separate job bag number is required for desktop
                  and mobile version.
                </li>
                <li>
                  &#9679; OR NORDICS, screenshots from desktop version are
                  sufficient (screenshots of mobile version not needed as the
                  final form for certification is not a requirement){" "}
                </li>
              </ul>

              <p>Purpose Field:</p>
              <ul className="ul_padding">
                <li>&#9679; Explain purpose</li>
                <li>
                  &#9679; Provide link to the test site with a username and
                  password included in purpose field.
                </li>
                <li>
                  &#9679; Provide Contract ID under purpose (if applicable). If
                  HCP contract ID is added, then PMC documents for AV consent do
                  not need to be added.  Under Details, choose PMC documents
                  attached as ‘No’
                </li>
              </ul>

              <p>Attachment:</p>
              <ul className="ul_padding">
                <li>
                  &#9679; LMeta-tag information, i.e. screenshot of how website
                  keyword search appears on google.
                </li>
                <li>
                  &#9679; For UK - SEO or paid keyword promotion is not
                  permissible.
                </li>
                <li>
                  &#9679; For Nordic - SEO or paid keyword promotion is
                  permissible but must be approved (Raisa to check)
                </li>
                <li>&#9679; Change Control Document (if applicable)</li>
                <li>
                  &#9679; For UK And ROI - Mobile site and tablet site
                  screenshots (if its same final form)
                </li>
                <li>
                  &#9679; Email from global patient safety team confirming
                  review and/or GPS checklist. This is a Lilly Web procedure
                  requirement and hence applicable to all countries.
                </li>
                <li>
                  &#9679; If it’s a new website or major functionality change,
                  then UAT (User accessibility test) checklist is needed.
                </li>
                <li>
                  &#9679; Material originators are responsible to check the
                  website when it goes live that the content matches the
                  certified final form version from Veeva. This confirmation
                  email must be uploaded on Veeva immediately post go-live’
                </li>
              </ul>

              <p>Related Permission managed content:</p>
              <ul className="ul_padding">
                <li>&#9679; Consent documents for PMC.</li>
                <li>
                  &#9679; If HCP contract ID is added to Purpose, then no PMC
                  documents need to be added.  Under Details, choose PMC
                  documents attached as No
                </li>
              </ul>

              <p>Related Pieces:</p>
              <ul className="ul_padding">
                <li>
                  &#9679; Include existing and related approved
                  assets/materials.
                </li>
              </ul>

              <p>Linked Documents:</p>
              <ul className="ul_padding">
                <li>
                  &#9679; FOR UK & ROI - Include current PI if PI is on website
                  rather than just a PI hyperlink.
                </li>
                <li>&#9679; Content references</li>
              </ul>

              <p>Final Form:</p>
              <ul className="ul_padding">
                <li>&#9679; Working test site with full functionality</li>
                <li>
                  &#9679; Material originators are responsible to check the
                  website when it goes live that the content matches the
                  certified final form version from Veeva. This confirmation
                  email must be uploaded on veeva post go-live’{" "}
                </li>
              </ul>
              <h5>
                <em>
                  APPENDIX 1 Website Owner and Website Custodian – R&R
                  Understanding Roles and responsibilities
                </em>
              </h5>
              <p>
                Website owner and custodians are identified and logged in
                Lilly’s Website Inventory database before a site is launched.
                Please ask local TECH@LILLY manager if you need to know who the
                owner/custodian for any website is.{" "}
              </p>
              <table className="table table-striped">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>
                      <b>
                        <i>Website owner</i>
                      </b>
                    </td>
                    <td>
                      <b>
                        <i>Website custodian</i>
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>Content accuracy, approvals, and compliance</td>
                    <td>
                      Website is built, tested and documented according to
                      ITC-SOP – Public Facing Website
                    </td>
                  </tr>

                  <tr>
                    <td>Privacy and patient safety compliance</td>
                    <td>
                      Functionality works as designed and aligns to Lilly
                      TECH@LILLY quality and privacy procedures
                    </td>
                  </tr>

                  <tr>
                    <td>Website requirements</td>
                    <td>CI record management and Website inventory </td>
                  </tr>

                  <tr>
                    <td>
                      Completing and delegating required training to individuals
                      working on the website.
                    </td>
                    <td>
                      Implementing key website controls (e.g., prevent caching,
                      no cross-site search (i.e., search functionality that
                      supports searches outside the website domain must be
                      disabled), no orphan content, encryption of data
                      collected/transferred, etc.).
                    </td>
                  </tr>

                  <tr>
                    <td>Site measurement and KPIs</td>
                    <td>
                      Follow all applicable Protect Lilly policies, procedures,
                      and process steps.
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>
                      Ensuring any open text field on a website, webpage, or
                      tool has a reasonable number of characters allowed (not
                      unlimited) for the type of information being collected
                      (for example: name, phone number, address).{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <input type="radio" name="tabs" id="tabthree" />
            <label htmlFor="tabthree" id="tab3-content">
              Website types
            </label>
            <div className="tab" id="tab3-content-1">
              <h5>
                <em>
                  APPENDIX 2 - Different website types, delivery teams and
                  request forms
                </em>
              </h5>
              <p>
                Individuals from different teams will work on different type of
                websites. The term ‘Delivery manager’ is used in this document
                as a generic role performed by individual working on technical
                development of your site. This would be the support team that
                helps develop the webpage/website. Here's the list of
                'responsible' functions for different website types.
              </p>

              <table className="table table-striped">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>
                      <b>
                        <i>Website type</i>
                      </b>
                    </td>
                    <td>
                      <b>
                        <i>Website owner</i>
                      </b>
                    </td>
                    <td>
                      <b>
                        <i>Constodian</i>
                      </b>
                    </td>
                    <td>
                      <b>
                        <i>Delivery team/agency</i>
                      </b>
                    </td>
                    <td>
                      <b>
                        <i>Link to request form (Tickets)</i>
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>EWI Site for products</td>
                    <td>Brand or functional leader </td>
                    <td>Key Account Manager (KAM) from EWI team </td>
                    <td>EWI</td>
                    <td>
                      <a href="https://clarity.lilly.com/" target="_blank" rel="noreferrer">
                        Lilly | Clarity
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>Corporate website</td>
                    <td>Corporate or country leader</td>
                    <td>EWI development lead</td>
                    <td>TECH@LILLY/local webmasters</td>
                    <td>
                      <a
                        href="https://forms.office.com/Pages/ResponsePage.aspx?id=gZqlGKjuMEyUitiCTNwlgPO8H0Kl8v5Jk_pxLf_3esdUMUJBOVA4NE5PUjYzMVIxTjFLUkFWMUE3UiQlQCN0PWcu&wdLOR=cA10FC381-0CC6-4A6E-A981-A607894A87E7"
                        target="_blank" rel="noreferrer"
                      >
                        NE Hub Corporate Website Change Request (office.com)
                        Separate forms for /hcp sections
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>Lilly campaign pages</td>
                    <td>Local TECH@LILLY Manager</td>
                    <td>Local TECH@LILLY Champion</td>
                    <td>TECH@LILLY/TCS</td>
                    <td>
                      <a
                        href="https://forms.office.com/Pages/ResponsePage.aspx?id=gZqlGKjuMEyUitiCTNwlgIVAp79T_bFCsj8G0QxhxNpUOUw2WVFCQTlCWU84WlpRMVk4OVAwUlpOQy4u&wdLOR=c1D5F9DD6-75B9-49D4-A877-894C762CA274"
                        target="_blank" rel="noreferrer"
                      >
                        NE Hub Corporate Website Change Request
                        (office.com)Content Hosting Request Form (office.com)
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>Digital publishing</td>
                    <td>Brand or functional leader</td>
                    <td>Veeva item owner</td>
                    <td>Veeva item owner</td>
                    <td>Direct contact to Veeva item owner</td>
                  </tr>

                  <tr>
                    <td>SFMC Cloud pages</td>
                    <td>Brand or functional leader</td>
                    <td>Local TECH@LILLY Champion</td>
                    <td>DST/TECH@LILLY</td>
                  </tr>

                  <tr>
                    <td>Custom Websites/Web Tools</td>
                    <td>Non-IT Lilly employee</td>
                    <td>IT Lilly employee</td>
                    <td>Situational</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <input type="radio" name="tabs" id="tab4" />
            <label htmlFor="tab4" id="tabfour-content">
              Website Checklist
            </label>
            <div className="tab" id="tabfour-content-4">
              <h5>
                <em>AAppendix 3 – Website checklist</em>
              </h5>
              <table className="table table-striped">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>
                      <b>
                        <i>Checklist items</i>
                      </b>
                    </td>
                    <td>
                      <b>
                        <i>Checks to be made</i>
                      </b>
                    </td>
                    <td>
                      <b>
                        <i>Help extended by</i>
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>Privacy Policy</td>
                    <td>Must be available in each page</td>
                    <td>
                      Consult local TECH@LILLY for most up to date policy link.
                      Consult E&C if you need specific changes to the privacy
                      policy wording{" "}
                    </td>
                  </tr>

                  <tr>
                    <td>Copyright</td>
                    <td>Must be available in each page</td>
                    <td>
                      Consult local TECH@LILLY for most up to date policy link
                    </td>
                  </tr>

                  <tr>
                    <td>Terms of use</td>
                    <td>
                      Must be available in each page. Can be a part of copyright
                      statement
                    </td>
                    <td>
                      Consult local TECH@LILLY for most up to date policy link
                    </td>
                  </tr>

                  <tr>
                    <td>Age disclaimer</td>
                    <td>
                      Not needed for HCP facing website Must be available on
                      each page, when applicable{" "}
                    </td>
                    <td>
                      Consult local TECH@LILLY for most up to date policy link
                    </td>
                  </tr>

                  <tr>
                    <td>Target audience disclaimer</td>
                    <td>
                      Must be available at the entry point or landing page of
                      the site
                    </td>
                  </tr>

                  <tr>
                    <td>Cookie banner</td>
                    <td>
                      Must be available at the entry point or landing page of
                      the site
                    </td>
                  </tr>

                  <tr>
                    <td>Global patient safety review</td>
                    <td>
                      Needed for - major functionality, content, claim changes.
                      -if there is interactivity such as free text field, chat
                      box, link to social media sites{" "}
                    </td>
                    <td>Consult with Global patient safety manager</td>
                  </tr>

                  <tr>
                    <td>Site business owner’s review</td>
                    <td>
                      Needed for - major functionality, content, claim changes.
                      -if there is interactivity such as free text field, chat
                      box, link to social media sites{" "}
                    </td>
                    <td>Consult with the business owner of the website</td>
                  </tr>

                  <tr>
                    <td>Monitoring plan</td>
                    <td>
                      Needed when website provides an email address or free text
                      fields, chat box
                    </td>
                    <td>Consult with Global patient safety manager</td>
                  </tr>
                  <tr>
                    <td>PIA</td>
                    <td>Needed if PI is being collected from users</td>
                    <td>
                      Consult with E&C and GPO. Complete the assessment form
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <input type="radio" name="tabs" id="tab5" />
            <label htmlFor="tab5" id="tabfive-content">
              Website Monitoring
            </label>
            <div className="tab" id="tabfive-content-1">
              <h5>
                <em>
                  APPENDIX 4– Website Monitoring activities and who is
                  responsible
                </em>
              </h5>
              <p>
                Below is the list of all the reviews/submissions related to
                website monitoring. Website owner will partner with NE Hub
                TECH@LILLY to perform following checks.
              </p>
              <table className="table table-striped">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>
                      <b>
                        <i>Type of monitoring</i>
                      </b>
                    </td>
                    <td>
                      <b>
                        <i>Frequency</i>
                      </b>
                    </td>
                    <td>
                      <b>
                        <i>Accountability of</i>
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Monitor for AEs/PCs if there are open text field, Contact
                      Us fields, email address
                    </td>
                    <td>Once per business day</td>
                    <td>Website owner</td>
                  </tr>

                  <tr>
                    <td>Pro-active review of content accuracy and relevancy</td>
                    <td>Once per quarter</td>
                    <td>Website owner</td>
                  </tr>

                  <tr>
                    <td>
                      Pro-active review of Veeva meta data for website (example,
                      all related pieces linked, inventory information up to
                      date, audience still relevant)
                    </td>
                    <td>Once per quarter</td>
                    <td>Website owner</td>
                  </tr>

                  <tr>
                    <td>
                      Pro-active review of functional links, footer policy links
                    </td>
                    <td>Once per quarter</td>
                    <td>Website custodian/Local TECH@LILLY manager</td>
                  </tr>

                  <tr>
                    <td>
                      When site is audited by IBU monitoring team, provide
                      answers and resolutions within defined timelines
                    </td>
                    <td>As and when the site is monitored</td>
                    <td>Website owner</td>
                  </tr>

                  <tr>
                    <td>
                      Change the website copyright to the current year (provided
                      websites’ content was updated)
                    </td>
                    <td>Annually</td>
                    <td>Website custodian/TECH@LILLY manager</td>
                  </tr>

                  <tr>
                    <td>
                      Annual Sustain and control audit (Complete annual training
                      and follow instructions as advised by S&C team)
                    </td>
                    <td>Annually </td>
                    <td>Website owner and website control</td>
                  </tr>

                  <tr>
                    <td>
                      Submit website Inventory list (PSMF) & details to PhV team
                    </td>
                    <td>Quarterly</td>
                    <td>TECH@LILLY Manager</td>
                  </tr>

                  <tr>
                    <td>
                      FOR NORWAY ONLY - Local code requires list of all
                      websites, web platforms to be submitted by pharma
                      companies to the authorities every 6 months
                    </td>
                    <td>Bi-annually (typically in April and October)</td>
                    <td>
                      TECH@LILLY Manager creates the list of web inventory Local
                      Brand associate submits the list to local authorities{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <input type="radio" name="tabs" id="tab6" />
            <label htmlFor="tab6" id="tabsix-content">
              Patient Safety{" "}
            </label>
            <div className="tab" id="tabsix-content-1">
              <h5>
                <em>Appendix 5 – Consider patient safety</em>
              </h5>
              <p>
                Consider patient safety
                <br />
                Examine if the content update has patient safety considerations.
                These could be (but not limited to) major content/claim updates,
                adding new product or indication, products with black triangle,
                open text fields, interactivity or a new website.{" "}
              </p>

              <ul className="ul_padding">
                <li>
                  &#9679; YES - GPS (global patient safety) review will be
                  needed. Depending on the significance of update, GPS manager
                  will decide the level of scrutiny needed in the review. For
                  example, GPS manager may decide to complete a checklist that
                  needs to be uploaded on Veeva or they may review changes on a
                  call with you and provide there ‘ok’ via email.
                </li>
                <li>&#9679; NO – initiate MLR without involving GPS </li>
              </ul>
              <h5>REMEMBER</h5>
              <p>
                Whether GPS completes the checklist or provide their approval on
                email, the document needs to be uploaded on Veeva.
              </p>
            </div>

            <input type="radio" name="tabs" id="tab7" />
            <label htmlFor="tab7" id="tabseven-content">
              Website Monitoring
            </label>
            <div className="tab" id="tabseven-content-1">
              <h5>
                <em>AAPPENDIX 7– Metatags & SEO </em>
              </h5>
              <p>
                Meta tags are on search engines such as Google. These are
                snippets of text that describe a page's content; the meta tags
                don't appear on the page itself, but only in the page's source
                code.
              </p>
              <ul className="ul_padding">
                <li>
                  &#9679; Implement metatags purposefully and get them approved.
                  If material owner does not implement metatags for the site,
                  search engine will formulate them for you, which could pose
                  risk both for non-promotional and promotional sites.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="list-group">
              <button
                type="button"
                className="list-group-item list-group-item-action active"
                aria-current="true"
              >
                Useful Links
              </button>
              <button
                type="button"
                className="list-group-item list-group-item-action"
              >
                <a
                  href="https://collab.lilly.com/:x:/r/sites/ROME-RiskOptimizationMaterialExcellence/_layouts/15/Doc.aspx?sourcedoc=%7B1BA0CF30-DE25-41D2-9165-B9D98CFD74D8%7D&amp;file=E2E%20Roles%20&amp;%20Responsibilities.xlsx=&amp;action=default&amp;mobileredirect=true"
                  target="_blank" rel="noreferrer"
                >
                  Roles and Responsibilities
                </a>
              </button>
              <button
                type="button"
                className="list-group-item list-group-item-action"
              >
                <a href="#" target="_blank">
                  FAQs
                </a>
              </button>
              <button
                type="button"
                className="list-group-item list-group-item-action"
              >
                <a
                  href="https://collab.lilly.com/:x:/r/sites/ROME-RiskOptimizationMaterialExcellence/Shared%20Documents/Material%20Lifecycle%20end-to-end%20process/Phase%202%20work%20-%20Core%20documents%20-%20Wave%201/E2E%20Definition%20masterfile.xlsx?d=wa9320724532443dbb9d0a392abca8703&amp;csf=1&amp;web=1&amp;e=6YdDAk"
                  target="_blank" rel="noreferrer"
                >
                  Definitions
                </a>
              </button>
              <button
                type="button"
                className="list-group-item list-group-item-action"
              >
                <a
                  href="https://collab.lilly.com/:w:/r/sites/ROME-RiskOptimizationMaterialExcellence/_layouts/15/Doc.aspx?sourcedoc=%7B380DD7E5-189C-445F-AB55-E1C505F828DE%7D&amp;file=VAE%20-%20E2E%20Lifecycle%20-%20V3%2019Dec.docx&amp;action=default&amp;mobileredirect=true"
                  target="_blank" rel="noreferrer"
                >
                  Download Core Document
                </a>
              </button>
              <button
                type="button"
                className="list-group-item list-group-item-action"
              >
                External Links-1
              </button>
              <button
                type="button"
                className="list-group-item list-group-item-action"
              >
                External Links-2
              </button>
              <button
                type="button"
                className="list-group-item list-group-item-action"
              >
                External Links-3
              </button>
            </div>
          </div>
          <div className="col-lg-10">
            <h4 className="h1-font">Website Flow Phases</h4>
            <div className="bg_accordian mt-3">
              <div>
                <details open>
                  <summary>Phase 0: Planning</summary>
                  <div>
                    <div className="Container">
                      <div className="row">
                        <div className="col-lg-4 planning p_font">
                          Request audience list for campaign from data analytics
                          team using link to{" "}
                          <b>Audience Request Form (10 working days)</b>
                        </div>
                        <div className="col-lg-8 planning p_font">
                          Planning is everything! The core objectives of a
                          pre-Veeva discussion should not be overlooked. These
                          are to; · ensure that a reviewer is never presented
                          with material in VVPM which they have no background
                          information on, and · that a review team understand
                          their current priorities for review. Together these
                          aim to provide a smoother and quicker approval process
                          for materials. VisitPlanning (lilly.com)to learn more
                          about pre-Veeva meetings.
                        </div>
                      </div>
                    </div>
                  </div>
                </details>
                <details className="First_check">
                  <summary>Phase 1: Material Readiness</summary>
                  <div>
                    <div className="Container">
                      <div className="row">
                        <div className="col-lg-4 brief">
                          <div className="comman_width">
                            <svg id="Layer_1" viewBox="0 0 273.47 503.18">
                              <defs>
                                <linearGradient
                                  id="linear-gradient"
                                  x1="643.54"
                                  y1="164.24"
                                  x2="643.54"
                                  y2="48.61"
                                  gradientTransform="translate(-523.85 416.92) scale(1 -1)"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop offset="0" stopColor="#edf5ff" />
                                  <stop offset=".54" stopColor="#6db1ff" />
                                  <stop offset="1" stopColor="#1071e5" />
                                </linearGradient>
                              </defs>
                              <a href="#link1">
                                <path
                                  className="cls-1"
                                  d="m20.5,6.5c0-3.31,2.69-6,6-6h188c3.31,0,6,2.69,6,6h0v68c0,3.31-2.69,6-6,6H26.5c-3.31,0-6-2.69-6-6V6.5Z"
                                />
                              </a>
                              <g id="a">
                                <path
                                  id="m"
                                  className="cls-9"
                                  d="m98.35,37.31c1.73.25,3.01,1.14,3.01,3.01,0,4.3-5.23,3.36-9.43,3.46v-12.25c3.75.15,8.74-.84,8.74,2.96,0,1.63-.94,2.47-2.32,2.81Zm-4.79-.54c2.47-.05,5.43.44,5.43-2.07,0-2.32-3.11-1.78-5.43-1.83v3.9Zm0,5.68c2.72-.1,6.12.69,6.12-2.22s-3.46-2.07-6.12-2.17v4.4"
                                />
                                <path
                                  id="n"
                                  className="cls-9"
                                  d="m107.93,35.73c-3.85-.79-2.62,4.49-2.81,8.05h-1.58l-.05-9.38h1.48c.05.59-.05,1.43.1,1.93.3-1.33,1.14-2.42,2.86-2.02v1.43"
                                />
                                <path
                                  id="o"
                                  className="cls-9"
                                  d="m109.36,32.38v-1.48h1.58v1.48h-1.58Zm0,11.41v-9.38h1.58v9.38h-1.58"
                                />
                                <path
                                  id="p"
                                  className="cls-9"
                                  d="m117.01,34.2c3.11,0,4.25,2.07,4.15,5.23h-6.67c0,1.98.69,3.31,2.62,3.36,1.28.05,2.12-.59,2.42-1.43l1.38.4c-.54,1.38-1.83,2.22-3.8,2.22-2.86,0-4.35-1.83-4.3-4.94.05-3.01,1.28-4.84,4.2-4.84Zm2.57,4c.3-2.96-3.75-3.8-4.79-1.38-.15.35-.3.84-.3,1.38h5.09"
                                />
                                <path
                                  id="q"
                                  className="cls-9"
                                  d="m126.94,32.23c-1.53-.44-2.07.49-1.88,2.17h1.88v1.14h-1.88v8.25h-1.53v-8.25h-1.33v-1.14h1.33c-.35-2.57.84-4.05,3.41-3.36v1.19"
                                />
                                <path
                                  id="o-2"
                                  className="cls-9"
                                  d="m128.08,32.38v-1.48h1.58v1.48h-1.58Zm0,11.41v-9.38h1.58v9.38h-1.58"
                                />
                                <path
                                  id="r"
                                  className="cls-9"
                                  d="m136.57,34.2c4.4-.2,2.62,5.73,2.96,9.58h-1.58v-5.98c0-1.53-.4-2.42-1.93-2.37-3.56.1-2.17,5.04-2.42,8.35h-1.58l-.05-9.38h1.48c.05.49-.05,1.19.1,1.58.54-1.09,1.43-1.73,3.01-1.78"
                                />
                                <path
                                  id="s"
                                  className="cls-9"
                                  d="m149.41,34.4c-.49,6.17,2.02,14.47-5.43,12.89-1.14-.3-1.88-.99-2.17-2.12l1.58-.25c.74,2.32,4.94,1.58,4.4-1.38v-1.48c-.54,1.04-1.43,1.78-3.01,1.78-2.67,0-3.36-2.02-3.36-4.74s.79-4.79,3.51-4.84c1.43-.05,2.37.79,2.91,1.73.05-.49,0-1.14.1-1.58h1.48Zm-4.1,8.3c1.78,0,2.47-1.58,2.47-3.6s-.69-3.7-2.47-3.7c-1.93,0-2.27,1.68-2.27,3.7s.3,3.6,2.27,3.6"
                                />
                              </g>
                              <path className="cls-4" d="m119.99,81.5v30.94" />
                              <path
                                className="cls-3"
                                d="m120.49,81.52h-1v-.52h1v.52Z"
                              />
                              <path
                                className="cls-2"
                                d="m119.99,127.2l-4.63-14.26h9.28l-4.65,14.26Z"
                              />
                              <path
                                className="cls-3"
                                d="m120.19,426.3h-1v-22.9h1v22.9Zm0-41.57h-1v-17.5h1v17.5Z"
                              />
                              <path
                                className="cls-7"
                                d="m119.69,366.72l.5-.05v.55h-1v-.55l.5.05Z"
                              />
                              <path
                                className="cls-3"
                                d="m119.69,366.68l.53-.03v.6h-1.05v-.6l.52.03Zm-.47.5h.95v-.48l-.48.03-.47-.02v.47Z"
                              />
                              <path
                                className="cls-7"
                                d="m119.69,441.07l-4.63-14.27h9.28l-4.65,14.27Z"
                              />
                              <path
                                className="cls-3"
                                d="m119.69,442.68l-5.32-16.38h10.64l-5.32,16.38Zm-3.95-15.38l3.94,12.15,3.95-12.15h-7.89Z"
                              />
                              <g id="b">
                                <path
                                  id="t"
                                  className="cls-11"
                                  d="m110.06,392.72v4.45h-1.47v-4.45l-4.1-6.27h1.6l3.24,5.1,3.24-5.1h1.6"
                                />
                                <path
                                  id="u"
                                  className="cls-11"
                                  d="m115.81,397.17v-10.72h8.08v1.21h-6.65v3.41h6.22v1.17h-6.22v3.76h7v1.17h-8.43"
                                />
                                <path
                                  id="v"
                                  className="cls-11"
                                  d="m132.87,389.01c-.22-2.07-5.31-2.33-5.36.09.6,3.24,6.83.6,7.04,5.14.13,3.37-5.23,3.76-7.56,2.38-.73-.43-1.21-1.12-1.43-1.99l1.43-.3c.22,2.42,6.09,2.72,6.09-.04,0-3.37-6.7-.6-7-5.1-.22-3.54,6.27-3.63,7.73-1.47.22.3.35.69.48,1.08"
                                />
                              </g>
                              <path className="cls-4" d="m119.69,209.82v28.1" />
                              <path
                                className="cls-3"
                                d="m120.19,209.82h-1v-.5h1v.5Z"
                              />
                              <path
                                className="cls-2"
                                d="m119.69,252.67l-4.63-14.26h9.28l-4.65,14.26Z"
                              />
                              <a href="#link3">
                                <path
                                  className="cls-5"
                                  d="m115.01,256.44c2.73-2.19,6.63-2.19,9.36,0l62.75,50.3c2.07,1.65,2.41,4.68.75,6.75-.22.28-.48.53-.75.75l-62.75,50.32c-2.73,2.19-6.63,2.19-9.36,0l-62.75-50.3c-2.07-1.65-2.41-4.68-.75-6.75.22-.28.48-.53.75-.75l62.75-50.32Z"
                                />
                              </a>
                              <g id="d">
                                <path
                                  id="w"
                                  className="cls-6"
                                  d="m86.45,292.54c3.76.04,8.25-.65,8.25,3.24,0,3.37-3.33,3.46-6.83,3.28v4.19h-1.43v-10.72Zm1.43,5.4c2.46,0,5.36.48,5.36-2.16s-2.94-2.03-5.36-2.07v4.23"
                                />
                                <path
                                  id="x"
                                  className="cls-6"
                                  d="m96.82,292.54c5.1-.3,9.33.35,9.2,5.27-.13,3.37-1.86,5.44-5.23,5.44h-3.98v-10.72Zm1.43,9.55c3.85.35,6.31-.69,6.31-4.28s-2.59-4.36-6.31-4.1v8.38"
                                />
                                <path
                                  id="y"
                                  className="cls-6"
                                  d="m109.43,293.75v3.98h5.96v1.21h-5.96v4.32h-1.43v-10.72h7.56v1.21h-6.14"
                                />
                              </g>
                              <g id="e">
                                <path
                                  id="z"
                                  className="cls-6"
                                  d="m130.87,303.25l.09-9.42c-.99,3.28-2.33,6.27-3.46,9.42h-.99l-3.5-9.42.04,9.42h-1.25v-10.72h1.9l3.33,9.12c.91-3.24,2.2-6.05,3.28-9.12h1.86v10.72h-1.3"
                                />
                                <path
                                  id="A"
                                  className="cls-6"
                                  d="m137.74,294.87c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                                />
                                <path
                                  id="A-2"
                                  className="cls-6"
                                  d="m146.38,294.87c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                                />
                                <path
                                  id="B"
                                  className="cls-6"
                                  d="m153.25,301.22c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                                />
                              </g>
                              <g id="f">
                                <path
                                  id="C"
                                  className="cls-6"
                                  d="m76.9,321.92v-10.72h8.08v1.21h-6.65v3.41h6.22v1.17h-6.22v3.76h7v1.17h-8.43"
                                />
                                <path
                                  id="D"
                                  className="cls-6"
                                  d="m92.06,321.92l-2.2-3.37-2.25,3.37h-1.47l2.94-4.23-2.81-3.98h1.51l2.07,3.2,2.03-3.2h1.51l-2.77,3.98,2.94,4.23h-1.51"
                                />
                                <path
                                  id="E"
                                  className="cls-6"
                                  d="m98.72,313.54c2.38.04,3.02,1.77,3.02,4.23s-.69,4.23-3.02,4.32c-1.34,0-2.12-.56-2.59-1.47l.04,4.54h-1.38l-.04-11.45h1.34l.09,1.3c.43-.91,1.21-1.47,2.55-1.47Zm-.35,7.52c1.73,0,1.94-1.47,1.94-3.24s-.26-3.15-1.94-3.2c-1.81,0-2.2,1.38-2.2,3.28s.43,3.15,2.2,3.15"
                                />
                                <path
                                  id="A-3"
                                  className="cls-6"
                                  d="m106.71,313.54c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                                />
                                <path
                                  id="F"
                                  className="cls-6"
                                  d="m115.18,314.62c-1.73,0-2.07,1.43-2.07,3.15s.39,3.24,2.07,3.24c1.04,0,1.77-.6,1.86-1.64l1.38.09c-.26,1.6-1.34,2.64-3.2,2.64-2.55,0-3.28-1.77-3.54-4.28-.43-4.02,4.36-5.66,6.35-2.77.17.3.22.6.3.95l-1.38.13c-.17-.91-.69-1.51-1.77-1.51"
                                />
                                <path
                                  id="B-2"
                                  className="cls-6"
                                  d="m121.36,319.89c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                                />
                                <path
                                  id="G"
                                  className="cls-6"
                                  d="m129.22,320.37c-.65.91-1.34,1.77-2.94,1.73-1.56-.04-2.51-.91-2.51-2.46-.04-2.77,2.72-2.72,5.4-2.72.13-1.51-.39-2.33-1.77-2.33-1.04.04-1.77.3-1.81,1.34l-1.43-.13c.22-1.6,1.43-2.25,3.28-2.25s3.11.86,3.11,2.77v3.54c-.04.86.3,1.38,1.21,1.17v.86c-1.34.39-2.64-.09-2.55-1.51Zm-4.02-.73c0,.86.52,1.43,1.38,1.43,1.77-.13,2.72-1.25,2.59-3.2-1.86.09-3.98-.22-3.98,1.77"
                                />
                                <path
                                  id="B-3"
                                  className="cls-6"
                                  d="m134.32,319.89c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                                />
                                <path
                                  id="H"
                                  className="cls-6"
                                  d="m137.13,311.94v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                                />
                                <path
                                  id="I"
                                  className="cls-6"
                                  d="m143.83,313.54c2.68-.04,3.67,1.6,3.67,4.28.04,2.72-1.17,4.28-3.72,4.28s-3.59-1.69-3.63-4.28c0-2.85,1.21-4.28,3.67-4.28Zm-.04,7.52c1.9.04,2.29-1.34,2.29-3.24s-.35-3.24-2.2-3.24-2.29,1.38-2.29,3.24.43,3.2,2.2,3.24"
                                />
                                <path
                                  id="J"
                                  className="cls-6"
                                  d="m153.21,313.54c3.85-.17,2.29,5.01,2.59,8.38h-1.38v-5.23c0-1.34-.35-2.12-1.69-2.07-3.11.09-1.9,4.41-2.12,7.3h-1.38l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.48-.95,1.25-1.51,2.64-1.56"
                                />
                                <path
                                  id="K"
                                  className="cls-6"
                                  d="m162.63,315.74c-.13-1.47-3.72-1.64-3.76,0,.65,2.29,4.97.52,5.14,3.89s-6.48,3.2-6.78.35l1.21-.22c.17,1.56,4.19,1.94,4.23,0-.43-2.42-4.88-.65-5.1-3.89-.17-2.46,3.54-2.72,5.27-1.81.52.3.91.82,1.04,1.51"
                                />
                              </g>
                              <path
                                className="cls-3"
                                d="m261.5,304.52l-.08,1-.24,1-.4.93-.52.87-.66.78-.77.66-.87.53-.94.4-1,.23-1,.08h-65.1v-1h65.07l.88-.07.84-.2.8-.32.74-.45.65-.56.56-.66.45-.72.33-.8.2-.84.07-.88v-102.55h1v102.57Zm-5.5-264.44l1,.24.95.4.87.52.77.66.67.78.54.87.4.94.23,1,.08,1v134.13h-1V46.52l-.07-.88-.2-.84-.34-.8-.45-.73-.57-.66-.65-.57-.74-.45-.8-.33-.83-.2-.87-.07h-17.6v-1h17.63l.98.09Z"
                              />
                              <path
                                className="cls-7"
                                d="m189.95,311h-.57l.04-.5-.04-.5h.56v1Z"
                              />
                              <path
                                className="cls-3"
                                d="m189.95,311.02h-.6l.05-.52-.05-.53h.6v1.05Zm-.54-1l.04.48-.04.47h.5v-.95h-.5Z"
                              />
                              <path
                                className="cls-7"
                                d="m236.88,45.14l-14.27-4.64,14.27-4.64v9.28Z"
                              />
                              <path
                                className="cls-3"
                                d="m237.38,45.82l-16.38-5.32,16.38-5.32v10.64Zm-13.15-5.32l12.15,3.95v-7.9l-12.15,3.95Z"
                              />
                              <g id="g">
                                <path
                                  id="L"
                                  className="cls-10"
                                  d="m257.07,194.84l-6.52-10.42.05,10.42h-1.43v-12.25h1.93l6.57,10.52-.1-10.52h1.53v12.25h-2.02"
                                />
                                <path
                                  id="M"
                                  className="cls-10"
                                  d="m267.4,182.45c4,0,6.07,2.27,6.07,6.22s-2.17,6.37-6.07,6.37-6.07-2.42-6.07-6.37,2.07-6.22,6.07-6.22Zm0,11.21c3.11,0,4.4-2.02,4.4-4.99s-1.43-4.89-4.4-4.89-4.4,1.93-4.4,4.89,1.38,4.94,4.4,4.99"
                                />
                              </g>
                              <a href="#link2">
                                <path
                                  className="cls-1"
                                  d="m18.73,134.82c0-3.31,2.69-6,6-6h188c3.31,0,6,2.69,6,6v68c0,3.31-2.69,6-6,6H24.73c-3.31,0-6-2.69-6-6v-68Z"
                                />
                              </a>
                              <g id="h">
                                <path
                                  id="N"
                                  className="cls-9"
                                  d="m66.39,172.51l-1.21-3.11h-4.88l-1.21,3.11h-1.51l4.36-10.72h1.64l4.28,10.72h-1.47Zm-1.64-4.28l-2.03-5.31c-.52,1.94-1.34,3.54-1.99,5.31h4.02"
                                />
                                <path
                                  id="n-2"
                                  className="cls-9"
                                  d="m72.82,165.47c-3.37-.69-2.29,3.93-2.46,7.04h-1.38l-.04-8.21h1.3c.04.52-.04,1.25.09,1.69.26-1.17.99-2.12,2.51-1.77v1.25"
                                />
                                <path
                                  id="O"
                                  className="cls-9"
                                  d="m75.59,170.48c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                                />
                                <path
                                  id="P"
                                  className="cls-9"
                                  d="m86.26,172.51h-1.56l-1.73-7.09-1.77,7.09h-1.56l-2.33-8.21h1.38l1.69,7.09,1.86-7.09h1.47l1.77,7.09,1.81-7.09h1.34"
                                />
                                <path
                                  id="Q"
                                  className="cls-9"
                                  d="m92.87,164.13c2.68-.04,3.67,1.6,3.67,4.28.04,2.72-1.17,4.28-3.72,4.28s-3.59-1.69-3.63-4.28c0-2.85,1.21-4.28,3.67-4.28Zm-.04,7.52c1.9.04,2.29-1.34,2.29-3.24s-.35-3.24-2.2-3.24-2.29,1.38-2.29,3.24.43,3.2,2.2,3.24"
                                />
                                <path
                                  id="n-3"
                                  className="cls-9"
                                  d="m102.12,165.47c-3.37-.69-2.29,3.93-2.46,7.04h-1.38l-.04-8.21h1.3c.04.52-.04,1.25.09,1.69.26-1.17.99-2.12,2.51-1.77v1.25"
                                />
                                <path
                                  id="R"
                                  className="cls-9"
                                  d="m108.52,172.51l-2.77-3.76-.99.82v2.94h-1.38v-11.28h1.38v7.04l3.59-3.98h1.6l-3.33,3.54,3.54,4.67h-1.64"
                                />
                              </g>
                              <g id="i">
                                <path
                                  id="S"
                                  className="cls-9"
                                  d="m115.73,161.8c5.1-.3,9.33.35,9.2,5.27-.13,3.37-1.86,5.44-5.23,5.44h-3.98v-10.72Zm1.43,9.55c3.85.35,6.31-.69,6.31-4.28s-2.59-4.36-6.31-4.1v8.38"
                                />
                                <path
                                  id="p-2"
                                  className="cls-9"
                                  d="m129.95,164.13c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                                />
                                <path
                                  id="T"
                                  className="cls-9"
                                  d="m135.31,172.51v-11.28h1.38v11.28h-1.38"
                                />
                                <path
                                  id="o-3"
                                  className="cls-9"
                                  d="m138.72,162.53v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                                />
                                <path
                                  id="U"
                                  className="cls-9"
                                  d="m145.76,172.51h-1.64l-2.98-8.21h1.47l2.33,7.13,2.42-7.13h1.47"
                                />
                                <path
                                  id="p-3"
                                  className="cls-9"
                                  d="m153.19,164.13c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                                />
                                <path
                                  id="n-4"
                                  className="cls-9"
                                  d="m162.44,165.47c-3.37-.69-2.29,3.93-2.46,7.04h-1.38l-.04-8.21h1.3c.04.52-.04,1.25.09,1.69.26-1.17.99-2.12,2.51-1.77v1.25"
                                />
                                <path
                                  id="p-4"
                                  className="cls-9"
                                  d="m166.98,164.13c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                                />
                                <path
                                  id="V"
                                  className="cls-9"
                                  d="m174.97,164.13c1.34,0,2.07.56,2.59,1.43l-.04-4.32h1.38l.04,11.28h-1.3c-.09-.43,0-.99-.13-1.34-.43.99-1.21,1.51-2.55,1.51-2.29,0-2.98-1.69-3.02-4.23,0-2.85.99-4.32,3.02-4.32Zm.39,1.04c-1.73,0-1.99,1.47-1.99,3.24s.26,3.2,1.94,3.2c1.81,0,2.2-1.38,2.2-3.28s-.39-3.2-2.16-3.15"
                                />
                              </g>
                              <path
                                className="cls-8"
                                d="m.5,448.68c0-3.31,2.69-6,6-6h228c3.31,0,6,2.69,6,6v48c0,3.31-2.69,6-6,6H6.5c-3.31,0-6-2.69-6-6v-48Z"
                              />
                              <g id="j">
                                <path
                                  id="W"
                                  className="cls-9"
                                  d="m73.55,465.68c-.28-2.67-6.83-3-6.89.11.78,4.17,8.78.78,9.06,6.61.17,4.33-6.72,4.83-9.72,3.06-.94-.56-1.56-1.44-1.83-2.56l1.83-.39c.28,3.11,7.83,3.5,7.83-.06,0-4.33-8.61-.78-9-6.56-.28-4.56,8.06-4.67,9.94-1.89.28.39.44.89.61,1.39"
                                />
                                <path
                                  id="p-5"
                                  className="cls-9"
                                  d="m82.16,465.41c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                                />
                                <path
                                  id="p-6"
                                  className="cls-9"
                                  d="m93.27,465.41c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                                />
                              </g>
                              <g id="k">
                                <path
                                  id="X"
                                  className="cls-9"
                                  d="m106.05,462.41c4.83.06,10.61-.83,10.61,4.17,0,4.33-4.28,4.44-8.78,4.22v5.39h-1.83v-13.78Zm1.83,6.94c3.17,0,6.89.61,6.89-2.78s-3.78-2.61-6.89-2.67v5.44"
                                />
                                <path
                                  id="Y"
                                  className="cls-9"
                                  d="m123.61,466.8c-4,0-2.44,5.67-2.72,9.39h-1.78v-14.5h1.78l-.06,5.72c.67-1.17,1.56-2,3.39-2,4.94,0,2.94,6.44,3.33,10.78h-1.78v-6.72c.11-1.78-.44-2.72-2.17-2.67"
                                />
                                <path
                                  id="Z"
                                  className="cls-9"
                                  d="m136.66,474.18c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                                />
                                <path
                                  id="aa"
                                  className="cls-9"
                                  d="m147.44,468.24c-.17-1.89-4.78-2.11-4.83,0,.83,2.94,6.39.67,6.61,5s-8.33,4.11-8.72.44l1.56-.28c.22,2,5.39,2.5,5.44,0-.56-3.11-6.28-.83-6.56-5-.22-3.17,4.56-3.5,6.78-2.33.67.39,1.17,1.06,1.33,1.94"
                                />
                                <path
                                  id="p-7"
                                  className="cls-9"
                                  d="m155.5,465.41c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                                />
                              </g>
                              <g id="l">
                                <path
                                  id="ab"
                                  className="cls-9"
                                  d="m172.22,462.24c4.56-.39,5.17,4.83,2.39,7.33l-3.44,3.06c-.61.61-1.28,1.22-1.61,2.06h7.17v1.5h-9.11c.11-5.5,7.11-5.22,7.11-10.11,0-1.56-.89-2.39-2.5-2.39s-2.56.83-2.72,2.28l-1.78-.17c.33-2.28,1.89-3.33,4.5-3.56"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div className="col-lg-8 brief">
                          <div className="tab">
                            <div id="link1">
                              <p className="pop">
                                <em>
                                  Ensure that the team working on material
                                  development fully understands the brief. Often
                                  a call is best way to move things quickly
                                  forward. A good brief to the agency means that
                                  the material administrator provides all
                                  necessary visuals, text and links upfront.
                                  Briefing could be completed by material
                                  administrator. Creative agency should advise
                                  on delivery timelines after the briefing is
                                  received.{" "}
                                </em>
                              </p>
                              <p>
                                <ul className="ul_padding">
                                  <li className="comman_font_pharasize">
                                    &#9679; KAM from EWI team will be the key
                                    contact to deliver on your brief. A request
                                    needs to be initiated by raising a ticket
                                    through{" "}
                                    <a
                                      href="https://clarity.lilly.com/"
                                      target="_blank" rel="noreferrer"
                                    >
                                      Clarity
                                    </a>{" "}
                                    platform{" "}
                                  </li>
                                  <li className="comman_font_pharasize">
                                    &#9679; Once clarity request is received,
                                    KAM will be in touch with you to fully
                                    understand the scope and seek
                                    clarifications.
                                  </li>
                                  <li className="comman_font_pharasize">
                                    &#9679; If significant updates are required
                                    to EWI site content and wireframe, the
                                    website owner should be informed
                                  </li>
                                  <li className="comman_font_pharasize">
                                    <strong>a</strong>. Why do this? Lilly has 1
                                    designated owner for each site. A Website
                                    owner receives thorough training on Lilly’s
                                    website processes and policies. Critical
                                    mistakes have been made in past as tactic
                                    co-ordinator asked for updates that were not
                                    compliant to the procedures. Your website
                                    owner will help you understand if your brief
                                    poses any risk.
                                  </li>
                                </ul>
                              </p>
                            </div>

                            <div id="link2">
                              <h5>
                                <em>Artwork delivered</em>
                              </h5>
                              <p className="p_font">
                                It is responsibility of material administrator
                                to review the artwork provided for accuracy
                                before submitting to MLR. At this stage, it is
                                best practice to get aligned with the reviewers
                                before submitting the material to MLR.
                              </p>
                              <h5>
                                <em>Remeber!</em>
                              </h5>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; Artwork should be delivered within the
                                  existing/ agreed wireframe of the website.
                                </li>
                              </ul>
                            </div>

                            <div id="link3">
                              <h5>
                                <em>Artwork meets expectation?</em>
                              </h5>
                              <p>
                                <ul className="ul_padding">
                                  <li className="p_font">
                                    &#9679; YES – initiate MLR
                                  </li>
                                  <li className="p_font">
                                    &#9679; NO – it is always advised to book a
                                    call with agency to go through changes
                                    required (back &amp; forth through email
                                    communication has proven to be time
                                    consuming!){" "}
                                  </li>
                                </ul>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </details>
                <details>
                  <summary>Phase 2: Approval And Launch</summary>
                  <div>
                    <div className="Container">
                      <div className="row">
                        <div className="col-lg-8 approval">
                          <svg id="Layer_1" viewBox="0 0 989.39 1285.93">
                            <defs>
                              <linearGradient
                                id="linear-gradient-be1"
                                x1="443.83"
                                y1="1725.2"
                                x2="443.83"
                                y2="1605.2"
                                gradientTransform="translate(37.33 1865.69) scale(1 -1)"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stopColor="#b8f5ed" />
                                <stop offset=".29" stopColor="#00c2a8" />
                                <stop offset=".47" stopColor="#00c2a8" />
                                <stop offset="1" stopColor="#008573" />
                              </linearGradient>
                              <linearGradient
                                id="linear-gradient-be2"
                                x1="53.11"
                                y1="1705.19"
                                x2="53.11"
                                y2="1625.19"
                                gradientTransform="translate(37.33 1865.69) scale(1 -1)"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stopColor="#edf5ff" />
                                <stop offset=".05" stopColor="#cfe4ff" />
                                <stop offset="1" stopColor="#1071e5" />
                              </linearGradient>
                              <linearGradient
                                id="linear-gradient-be3"
                                x1="443.83"
                                y1="1240.33"
                                x2="443.83"
                                y2="1120.33"
                                gradientTransform="translate(37.33 1865.69) scale(1 -1)"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop offset="0" stopColor="#b8f5ed" />
                                <stop offset=".29" stopColor="#00c2a8" />
                                <stop offset=".47" stopColor="#00c2a8" />
                                <stop offset="1" stopColor="#008573" />
                              </linearGradient>
                            </defs>
                            <a href="#link12">
                              <path
                                className="cls-12-be1"
                                d="m366.63,1222.5c0-3.31,2.69-6,6-6h224.2c3.31,0,6,2.69,6,6v56.93c0,3.31-2.69,6-6,6h-224.2c-3.31,0-6-2.69-6-6v-56.93Z"
                              />
                            </a>
                            <g id="a">
                              <path
                                id="ap"
                                className="cls-6-be1"
                                d="m386.47,1252.37v-10.72h1.47v10.72h-1.47"
                              />
                              <path
                                id="aq"
                                className="cls-6-be1"
                                d="m394.42,1243.99c3.85-.17,2.29,5.01,2.59,8.38h-1.38v-5.23c0-1.34-.35-2.12-1.69-2.07-3.11.09-1.9,4.41-2.12,7.3h-1.38l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.48-.95,1.25-1.51,2.64-1.56"
                              />
                              <path
                                id="ar"
                                className="cls-6-be1"
                                d="m402.37,1242.26c-1.34-.39-1.81.43-1.64,1.9h1.64v.99h-1.64v7.22h-1.34v-7.22h-1.17v-.99h1.17c-.3-2.25.73-3.54,2.98-2.94v1.04"
                              />
                              <path
                                id="as"
                                className="cls-6-be1"
                                d="m406.65,1243.99c2.68-.04,3.67,1.6,3.67,4.28.04,2.72-1.17,4.28-3.72,4.28s-3.59-1.69-3.63-4.28c0-2.85,1.21-4.28,3.67-4.28Zm-.04,7.52c1.9.04,2.29-1.34,2.29-3.24s-.35-3.24-2.2-3.24-2.29,1.38-2.29,3.24.43,3.2,2.2,3.24"
                              />
                              <path
                                id="at"
                                className="cls-6-be1"
                                d="m415.9,1245.33c-3.37-.69-2.29,3.93-2.46,7.04h-1.38l-.04-8.21h1.3c.04.52-.04,1.25.09,1.69.26-1.17.99-2.12,2.51-1.77v1.25"
                              />
                              <path
                                id="au"
                                className="cls-6-be1"
                                d="m425.19,1245.07c-2.9.13-1.64,4.54-1.9,7.3h-1.34v-5.23c0-1.25-.22-2.16-1.51-2.07-2.85.17-1.64,4.49-1.9,7.3h-1.34l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.43-1.9,4.28-2.16,4.62,0,.48-.91,1.17-1.51,2.51-1.56,3.67-.09,2.03,5.14,2.38,8.38h-1.34v-5.23c0-1.25-.22-2.12-1.51-2.07"
                              />
                            </g>
                            <g id="b">
                              <path
                                id="av"
                                className="cls-6-be1"
                                d="m442.52,1244.64c-.43-1.21-1.51-1.94-3.15-1.94-2.55,0-3.76,1.73-3.76,4.28s1.25,4.36,3.85,4.36c1.86,0,2.68-1.04,3.37-2.25l1.17.6c-.78,1.64-2.2,2.85-4.62,2.85-3.46,0-5.06-2.16-5.23-5.57-.26-4.49,4.28-6.61,8.08-4.8.82.39,1.34,1.12,1.69,1.99"
                              />
                              <path
                                id="aw"
                                className="cls-6-be1"
                                d="m448.18,1252.54c-3.85.17-2.33-5.01-2.64-8.38h1.38v5.19c0,1.34.3,2.16,1.69,2.12,3.11-.09,1.94-4.36,2.16-7.3h1.34l.04,8.21h-1.3c-.04-.43.04-1.08-.09-1.43-.48.95-1.21,1.56-2.59,1.6"
                              />
                              <path
                                id="ax"
                                className="cls-6-be1"
                                d="m459.02,1246.19c-.13-1.47-3.72-1.64-3.76,0,.65,2.29,4.97.52,5.14,3.89s-6.48,3.2-6.78.35l1.21-.22c.17,1.56,4.19,1.94,4.23,0-.43-2.42-4.88-.65-5.1-3.89-.17-2.46,3.54-2.72,5.27-1.81.52.3.91.82,1.04,1.51"
                              />
                              <path
                                id="ay"
                                className="cls-6-be1"
                                d="m463.52,1250.34c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="as-2"
                                className="cls-6-be1"
                                d="m469.61,1243.99c2.68-.04,3.67,1.6,3.67,4.28.04,2.72-1.17,4.28-3.72,4.28s-3.59-1.69-3.63-4.28c0-2.85,1.21-4.28,3.67-4.28Zm-.04,7.52c1.9.04,2.29-1.34,2.29-3.24s-.35-3.24-2.2-3.24-2.29,1.38-2.29,3.24.43,3.2,2.2,3.24"
                              />
                              <path
                                id="au-2"
                                className="cls-6-be1"
                                d="m483,1245.07c-2.9.13-1.64,4.54-1.9,7.3h-1.34v-5.23c0-1.25-.22-2.16-1.51-2.07-2.85.17-1.64,4.49-1.9,7.3h-1.34l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.43-1.9,4.28-2.16,4.62,0,.48-.91,1.17-1.51,2.51-1.56,3.67-.09,2.03,5.14,2.38,8.38h-1.34v-5.23c0-1.25-.22-2.12-1.51-2.07"
                              />
                              <path
                                id="az"
                                className="cls-6-be1"
                                d="m491.17,1243.99c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                              <path
                                id="at-2"
                                className="cls-6-be1"
                                d="m500.42,1245.33c-3.37-.69-2.29,3.93-2.46,7.04h-1.38l-.04-8.21h1.3c.04.52-.04,1.25.09,1.69.26-1.17.99-2.12,2.51-1.77v1.25"
                              />
                            </g>
                            <g id="c">
                              <path
                                id="ar-2"
                                className="cls-6-be1"
                                d="m509.32,1242.26c-1.34-.39-1.81.43-1.64,1.9h1.64v.99h-1.64v7.22h-1.34v-7.22h-1.17v-.99h1.17c-.3-2.25.73-3.54,2.98-2.94v1.04"
                              />
                              <path
                                id="aA"
                                className="cls-6-be1"
                                d="m515.37,1250.82c-.65.91-1.34,1.77-2.94,1.73-1.56-.04-2.51-.91-2.51-2.46-.04-2.77,2.72-2.72,5.4-2.72.13-1.51-.39-2.33-1.77-2.33-1.04.04-1.77.3-1.81,1.34l-1.43-.13c.22-1.6,1.43-2.25,3.28-2.25s3.11.86,3.11,2.77v3.54c-.04.86.3,1.38,1.21,1.17v.86c-1.34.39-2.64-.09-2.55-1.51Zm-4.02-.73c0,.86.52,1.43,1.38,1.43,1.77-.13,2.72-1.25,2.59-3.2-1.86.09-3.98-.22-3.98,1.77"
                              />
                              <path
                                id="aB"
                                className="cls-6-be1"
                                d="m522.07,1245.07c-1.73,0-2.07,1.43-2.07,3.15s.39,3.24,2.07,3.24c1.04,0,1.77-.6,1.86-1.64l1.38.09c-.26,1.6-1.34,2.64-3.2,2.64-2.55,0-3.28-1.77-3.54-4.28-.43-4.02,4.36-5.66,6.35-2.77.17.3.22.6.3.95l-1.38.13c-.17-.91-.69-1.51-1.77-1.51"
                              />
                              <path
                                id="aC"
                                className="cls-6-be1"
                                d="m526.73,1242.39v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                              />
                              <path
                                id="aq-2"
                                className="cls-6-be1"
                                d="m534.16,1243.99c3.85-.17,2.29,5.01,2.59,8.38h-1.38v-5.23c0-1.34-.35-2.12-1.69-2.07-3.11.09-1.9,4.41-2.12,7.3h-1.38l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.48-.95,1.25-1.51,2.64-1.56"
                              />
                              <path
                                id="aD"
                                className="cls-6-be1"
                                d="m545.4,1244.16c-.43,5.4,1.77,12.66-4.75,11.28-.99-.26-1.64-.86-1.9-1.86l1.38-.22c.65,2.03,4.32,1.38,3.85-1.21v-1.3c-.48.91-1.25,1.56-2.64,1.56-2.33,0-2.94-1.77-2.94-4.15s.69-4.19,3.07-4.23c1.25-.04,2.07.69,2.55,1.51.04-.43,0-.99.09-1.38h1.3Zm-3.59,7.26c1.56,0,2.16-1.38,2.16-3.15s-.6-3.24-2.16-3.24c-1.69,0-1.99,1.47-1.99,3.24s.26,3.15,1.99,3.15"
                              />
                            </g>
                            <g id="d">
                              <path
                                id="ay-2"
                                className="cls-6-be1"
                                d="m553.26,1250.34c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="az-2"
                                className="cls-6-be1"
                                d="m559.36,1243.99c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                              <path
                                id="aA-2"
                                className="cls-6-be1"
                                d="m569.77,1250.82c-.65.91-1.34,1.77-2.94,1.73-1.56-.04-2.51-.91-2.51-2.46-.04-2.77,2.72-2.72,5.4-2.72.13-1.51-.39-2.33-1.77-2.33-1.04.04-1.77.3-1.81,1.34l-1.43-.13c.22-1.6,1.43-2.25,3.28-2.25s3.11.86,3.11,2.77v3.54c-.04.86.3,1.38,1.21,1.17v.86c-1.34.39-2.64-.09-2.55-1.51Zm-4.02-.73c0,.86.52,1.43,1.38,1.43,1.77-.13,2.72-1.25,2.59-3.2-1.86.09-3.98-.22-3.98,1.77"
                              />
                              <path
                                id="au-3"
                                className="cls-6-be1"
                                d="m581.39,1245.07c-2.9.13-1.64,4.54-1.9,7.3h-1.34v-5.23c0-1.25-.22-2.16-1.51-2.07-2.85.17-1.64,4.49-1.9,7.3h-1.34l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.43-1.9,4.28-2.16,4.62,0,.48-.91,1.17-1.51,2.51-1.56,3.67-.09,2.03,5.14,2.38,8.38h-1.34v-5.23c0-1.25-.22-2.12-1.51-2.07"
                              />
                            </g>
                            <a href="#link4">
                              <path
                                className="cls-12-be1"
                                d="m358.96,6.5c0-3.31,2.69-6,6-6h231.87c3.31,0,6,2.69,6,6v68c0,3.31-2.69,6-6,6h-231.86c-3.31,0-6-2.69-6-6V6.5Z"
                              />
                            </a>
                            <g id="e">
                              <path
                                id="ap-2"
                                className="cls-6-be1"
                                d="m441,44.18v-10.72h1.47v10.72h-1.47"
                              />
                              <path
                                id="aq-3"
                                className="cls-6-be1"
                                d="m448.95,35.8c3.85-.17,2.29,5.01,2.59,8.38h-1.38v-5.23c0-1.34-.35-2.12-1.69-2.07-3.11.09-1.9,4.41-2.12,7.3h-1.38l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.48-.95,1.25-1.51,2.64-1.56"
                              />
                              <path
                                id="aC-2"
                                className="cls-6-be1"
                                d="m453.57,34.2v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                              />
                              <path
                                id="ay-3"
                                className="cls-6-be1"
                                d="m458.5,42.15c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="aC-3"
                                className="cls-6-be1"
                                d="m461.3,34.2v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                              />
                              <path
                                id="aA-3"
                                className="cls-6-be1"
                                d="m469.77,42.63c-.65.91-1.34,1.77-2.94,1.73-1.56-.04-2.51-.91-2.51-2.46-.04-2.77,2.72-2.72,5.4-2.72.13-1.51-.39-2.33-1.77-2.33-1.04.04-1.77.3-1.81,1.34l-1.43-.13c.22-1.6,1.43-2.25,3.28-2.25s3.11.86,3.11,2.77v3.54c-.04.86.3,1.38,1.21,1.17v.86c-1.34.39-2.64-.09-2.55-1.51Zm-4.02-.73c0,.86.52,1.43,1.38,1.43,1.77-.13,2.72-1.25,2.59-3.2-1.86.09-3.98-.22-3.98,1.77"
                              />
                              <path
                                id="ay-4"
                                className="cls-6-be1"
                                d="m474.87,42.15c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="az-3"
                                className="cls-6-be1"
                                d="m480.97,35.8c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                            </g>
                            <g id="f">
                              <path
                                id="aE"
                                className="cls-6-be1"
                                d="m499.98,44.18l.09-9.42c-.99,3.28-2.33,6.27-3.46,9.42h-.99l-3.5-9.42.04,9.42h-1.25v-10.72h1.9l3.33,9.12c.91-3.24,2.2-6.05,3.28-9.12h1.86v10.72h-1.3"
                              />
                              <path
                                id="aF"
                                className="cls-6-be1"
                                d="m503.82,44.18v-10.72h1.43v9.55h5.4v1.17h-6.83"
                              />
                              <path
                                id="aG"
                                className="cls-6-be1"
                                d="m521.24,36.53c-.04,1.77-.99,2.77-2.59,3.02l3.02,4.62h-1.64l-2.81-4.45h-3.33v4.45h-1.43v-10.72c3.8.13,8.86-.91,8.77,3.07Zm-7.35,2.07c2.59-.09,5.92.56,5.92-2.03s-3.46-1.81-5.92-1.94v3.98"
                              />
                            </g>
                            <a href="#link5">
                              <path
                                className="cls-14-be1"
                                d="m476.53,144.3c2.69-2.21,6.56-2.21,9.25,0l63.44,52.37c2.11,1.74,2.41,4.86.67,6.97-.2.25-.43.47-.67.67l-63.44,52.37c-2.69,2.22-6.57,2.22-9.26,0l-63.43-52.36c-2.11-1.74-2.4-4.86-.66-6.97.2-.24.42-.46.66-.66l63.44-52.39Z"
                              />
                            </a>
                            <g id="i">
                              <path
                                id="aH"
                                className="cls-5-be1"
                                d="m444.07,204.4l.1-10.07-3.16,10.07h-1.83l-3.11-10.07.1,10.07h-2.27v-12.25h3.46l2.77,9.14,2.81-9.14h3.41v12.25h-2.27"
                              />
                              <path
                                id="aI"
                                className="cls-5-be1"
                                d="m448.66,204.4v-12.25h2.57v10.27h6.57v1.98h-9.14"
                              />
                              <path
                                id="aJ"
                                className="cls-5-be1"
                                d="m470.15,195.8c0,1.98-1.14,3.01-2.67,3.46l3.31,5.14h-2.91l-2.81-4.64h-3.01v4.64h-2.57v-12.25c4.59.2,10.72-1.14,10.67,3.65Zm-8.1,1.98c2.37-.1,5.53.59,5.53-1.88s-3.26-1.58-5.53-1.73v3.6"
                              />
                            </g>
                            <g id="j">
                              <path
                                id="aK"
                                className="cls-5-be1"
                                d="m483.28,204.4h-2.62l-4.54-12.25h2.72l3.16,10.17c.84-3.56,2.07-6.77,3.11-10.17h2.67"
                              />
                              <path
                                id="aL"
                                className="cls-5-be1"
                                d="m496.02,202.03c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="aM"
                                className="cls-5-be1"
                                d="m503.43,196.99c-1.93-.59-2.96.64-2.96,2.81v4.59h-2.47l-.05-9.38h2.32c.1.64-.05,1.43.15,1.98.3-1.38,1.33-2.62,3.01-2.02v2.02"
                              />
                              <path
                                id="aN"
                                className="cls-5-be1"
                                d="m508.02,194.82c1.53-.05,2.27.74,2.86,1.68l-.05-4.99h2.47l.05,12.89h-2.37c-.1-.49,0-1.14-.15-1.53-.54,1.14-1.43,1.73-3.01,1.73-2.57,0-3.36-2.22-3.41-4.89,0-2.77.94-4.79,3.6-4.89Zm.84,8.1c1.63,0,1.98-1.48,2.02-3.26.05-1.83-.44-3.16-2.02-3.16s-1.88,1.48-1.93,3.21c0,2.12.64,3.21,1.93,3.21"
                              />
                              <path
                                id="aO"
                                className="cls-5-be1"
                                d="m515.73,193.33v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="aP"
                                className="cls-5-be1"
                                d="m528.81,201.29c-.35,2.07-1.88,3.31-4.25,3.31-2.91,0-4.15-1.88-4.44-4.84-.59-5.43,7.6-6.77,8.59-1.78l-2.42.1c-.1-.94-.74-1.58-1.73-1.58-1.48,0-1.73,1.38-1.88,3.16-.3,3.65,3.21,4.3,3.65,1.48"
                              />
                              <path
                                id="aQ"
                                className="cls-5-be1"
                                d="m534.99,204.25c-1.78.69-4.3.35-4.3-2.07v-5.53h-1.19v-1.63h1.33l.74-2.22h1.53v2.22h1.78v1.63h-1.78v4.89c-.05,1.14.79,1.53,1.88,1.23v1.48"
                              />
                            </g>
                            <path
                              className="cls-13-be1"
                              d="m480.9,81.5l.2,44.76"
                            />
                            <path
                              className="cls-8-be1"
                              d="m481.4,81.5h-1v-.5h1v.5Z"
                            />
                            <path
                              className="cls-9-be1"
                              d="m481.15,141.02l-4.7-14.24,9.28-.04-4.58,14.28Z"
                            />
                            <a href="#link6">
                              <path
                                className="cls-12-be1"
                                d="m361.4,364c0-3.31,2.69-6,6-6h229.43c3.31,0,6,2.69,6,6v68c0,3.31-2.69,6-6,6h-229.42c-3.31,0-6-2.69-6-6v-68Z"
                              />
                            </a>
                            <g id="k">
                              <path
                                id="aR"
                                className="cls-6-be1"
                                d="m378.83,390.98c5.1-.3,9.33.35,9.2,5.27-.13,3.37-1.86,5.44-5.23,5.44h-3.98v-10.72Zm1.43,9.55c3.85.35,6.31-.69,6.31-4.28s-2.59-4.36-6.31-4.1v8.38"
                              />
                              <path
                                id="aS"
                                className="cls-6-be1"
                                d="m396.72,393.53c-.22-2.07-5.31-2.33-5.36.09.6,3.24,6.83.6,7.04,5.14.13,3.37-5.23,3.76-7.56,2.38-.73-.43-1.21-1.12-1.43-1.99l1.43-.3c.22,2.42,6.09,2.72,6.09-.04,0-3.37-6.7-.6-7-5.1-.22-3.54,6.27-3.63,7.73-1.47.22.3.35.69.48,1.08"
                              />
                              <path
                                id="aT"
                                className="cls-6-be1"
                                d="m404.58,392.19v9.51h-1.47v-9.51h-3.67v-1.21h8.81v1.21h-3.67"
                              />
                            </g>
                            <g id="l">
                              <path
                                id="aU"
                                className="cls-6-be1"
                                d="m417.55,393.32c2.38.04,3.02,1.77,3.02,4.23s-.69,4.23-3.02,4.32c-1.34,0-2.12-.56-2.59-1.47l.04,4.54h-1.38l-.04-11.45h1.34l.09,1.3c.43-.91,1.21-1.47,2.55-1.47Zm-.35,7.52c1.73,0,1.94-1.47,1.94-3.24s-.26-3.15-1.94-3.2c-1.81,0-2.2,1.38-2.2,3.28s.43,3.15,2.2,3.15"
                              />
                              <path
                                id="at-3"
                                className="cls-6-be1"
                                d="m426.15,394.66c-3.37-.69-2.29,3.93-2.46,7.04h-1.38l-.04-8.21h1.3c.04.52-.04,1.25.09,1.69.26-1.17.99-2.12,2.51-1.77v1.25"
                              />
                              <path
                                id="as-3"
                                className="cls-6-be1"
                                d="m430.68,393.32c2.68-.04,3.67,1.6,3.67,4.28.04,2.72-1.17,4.28-3.72,4.28s-3.59-1.69-3.63-4.28c0-2.85,1.21-4.28,3.67-4.28Zm-.04,7.52c1.9.04,2.29-1.34,2.29-3.24s-.35-3.24-2.2-3.24-2.29,1.38-2.29,3.24.43,3.2,2.2,3.24"
                              />
                              <path
                                id="aV"
                                className="cls-6-be1"
                                d="m438.68,393.32c1.34,0,2.07.56,2.59,1.43l-.04-4.32h1.38l.04,11.28h-1.3c-.09-.43,0-.99-.13-1.34-.43.99-1.21,1.51-2.55,1.51-2.29,0-2.98-1.69-3.02-4.23,0-2.85.99-4.32,3.02-4.32Zm.39,1.04c-1.73,0-1.99,1.47-1.99,3.24s.26,3.2,1.94,3.2c1.81,0,2.2-1.38,2.2-3.28s-.39-3.2-2.16-3.15"
                              />
                              <path
                                id="aw-2"
                                className="cls-6-be1"
                                d="m447.28,401.87c-3.85.17-2.33-5.01-2.64-8.38h1.38v5.19c0,1.34.3,2.16,1.69,2.12,3.11-.09,1.94-4.36,2.16-7.3h1.34l.04,8.21h-1.3c-.04-.43.04-1.08-.09-1.43-.48.95-1.21,1.56-2.59,1.6"
                              />
                              <path
                                id="aB-2"
                                className="cls-6-be1"
                                d="m456.44,394.4c-1.73,0-2.07,1.43-2.07,3.15s.39,3.24,2.07,3.24c1.04,0,1.77-.6,1.86-1.64l1.38.09c-.26,1.6-1.34,2.64-3.2,2.64-2.55,0-3.28-1.77-3.54-4.28-.43-4.02,4.36-5.66,6.35-2.77.17.3.22.6.3.95l-1.38.13c-.17-.91-.69-1.51-1.77-1.51"
                              />
                              <path
                                id="az-4"
                                className="cls-6-be1"
                                d="m464.39,393.32c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                              <path
                                id="ax-2"
                                className="cls-6-be1"
                                d="m474.54,395.52c-.13-1.47-3.72-1.64-3.76,0,.65,2.29,4.97.52,5.14,3.89s-6.48,3.2-6.78.35l1.21-.22c.17,1.56,4.19,1.94,4.23,0-.43-2.42-4.88-.65-5.1-3.89-.17-2.46,3.54-2.72,5.27-1.81.52.3.91.82,1.04,1.51"
                              />
                            </g>
                            <g id="m">
                              <path
                                id="aW"
                                className="cls-6-be1"
                                d="m489.32,401.7v-4.97h-5.79v4.97h-1.43v-10.72h1.43v4.54h5.79v-4.54h1.47v10.72h-1.47"
                              />
                              <path
                                id="aT-2"
                                className="cls-6-be1"
                                d="m497.48,392.19v9.51h-1.47v-9.51h-3.67v-1.21h8.81v1.21h-3.67"
                              />
                              <path
                                id="aE-2"
                                className="cls-6-be1"
                                d="m511.83,401.7l.09-9.42c-.99,3.28-2.33,6.27-3.46,9.42h-.99l-3.5-9.42.04,9.42h-1.25v-10.72h1.9l3.33,9.12c.91-3.24,2.2-6.05,3.28-9.12h1.86v10.72h-1.3"
                              />
                              <path
                                id="aF-2"
                                className="cls-6-be1"
                                d="m515.68,401.7v-10.72h1.43v9.55h5.4v1.17h-6.83"
                              />
                            </g>
                            <g id="n">
                              <path
                                id="aX"
                                className="cls-6-be1"
                                d="m528.08,390.98c3.76.04,8.25-.65,8.25,3.24,0,3.37-3.33,3.46-6.83,3.28v4.19h-1.43v-10.72Zm1.43,5.4c2.46,0,5.36.48,5.36-2.16s-2.94-2.03-5.36-2.07v4.23"
                              />
                              <path
                                id="aA-4"
                                className="cls-6-be1"
                                d="m543.24,400.15c-.65.91-1.34,1.77-2.94,1.73-1.56-.04-2.51-.91-2.51-2.46-.04-2.77,2.72-2.72,5.4-2.72.13-1.51-.39-2.33-1.77-2.33-1.04.04-1.77.3-1.81,1.34l-1.43-.13c.22-1.6,1.43-2.25,3.28-2.25s3.11.86,3.11,2.77v3.54c-.04.86.3,1.38,1.21,1.17v.86c-1.34.39-2.64-.09-2.55-1.51Zm-4.02-.73c0,.86.52,1.43,1.38,1.43,1.77-.13,2.72-1.25,2.59-3.2-1.86.09-3.98-.22-3.98,1.77"
                              />
                              <path
                                id="aB-3"
                                className="cls-6-be1"
                                d="m549.94,394.4c-1.73,0-2.07,1.43-2.07,3.15s.39,3.24,2.07,3.24c1.04,0,1.77-.6,1.86-1.64l1.38.09c-.26,1.6-1.34,2.64-3.2,2.64-2.55,0-3.28-1.77-3.54-4.28-.43-4.02,4.36-5.66,6.35-2.77.17.3.22.6.3.95l-1.38.13c-.17-.91-.69-1.51-1.77-1.51"
                              />
                              <path
                                id="aY"
                                className="cls-6-be1"
                                d="m559.75,401.7l-2.77-3.76-.99.82v2.94h-1.38v-11.28h1.38v7.04l3.59-3.98h1.6l-3.33,3.54,3.54,4.67h-1.64"
                              />
                              <path
                                id="aA-5"
                                className="cls-6-be1"
                                d="m567.44,400.15c-.65.91-1.34,1.77-2.94,1.73-1.56-.04-2.51-.91-2.51-2.46-.04-2.77,2.72-2.72,5.4-2.72.13-1.51-.39-2.33-1.77-2.33-1.04.04-1.77.3-1.81,1.34l-1.43-.13c.22-1.6,1.43-2.25,3.28-2.25s3.11.86,3.11,2.77v3.54c-.04.86.3,1.38,1.21,1.17v.86c-1.34.39-2.64-.09-2.55-1.51Zm-4.02-.73c0,.86.52,1.43,1.38,1.43,1.77-.13,2.72-1.25,2.59-3.2-1.86.09-3.98-.22-3.98,1.77"
                              />
                              <path
                                id="aD-2"
                                className="cls-6-be1"
                                d="m577.64,393.49c-.43,5.4,1.77,12.66-4.75,11.28-.99-.26-1.64-.86-1.9-1.86l1.38-.22c.65,2.03,4.32,1.38,3.85-1.21v-1.3c-.48.91-1.25,1.56-2.64,1.56-2.33,0-2.94-1.77-2.94-4.15s.69-4.19,3.07-4.23c1.25-.04,2.07.69,2.55,1.51.04-.43,0-.99.09-1.38h1.3Zm-3.59,7.26c1.56,0,2.16-1.38,2.16-3.15s-.6-3.24-2.16-3.24c-1.69,0-1.99,1.47-1.99,3.24s.26,3.15,1.99,3.15"
                              />
                              <path
                                id="az-5"
                                className="cls-6-be1"
                                d="m582.95,393.32c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                            </g>
                            <path
                              className="cls-10-be1"
                              d="m.5,166.5c0-3.31,2.69-6,6-6h167.87c3.31,0,6,2.69,6,6v68c0,3.31-2.69,6-6,6H6.5c-3.31,0-6-2.69-6-6v-68Z"
                            />
                            <g id="p">
                              <path
                                id="aZ"
                                className="cls-5-be1"
                                d="m36.15,193.9c-.06-2.65-5.8-2.84-5.99-.19,1.11,4.07,9.32.62,9.51,7.04.19,5.86-10.19,5.74-12.59,2.22-.37-.49-.62-1.17-.74-1.85l3.09-.49c.19,2.84,6.91,3.46,7.16.31-1.05-4.26-9.26-.62-9.51-7.04-.25-5.37,9.44-5.43,11.6-2.16.31.49.49,1.11.62,1.73"
                              />
                              <path
                                id="aL-2"
                                className="cls-5-be1"
                                d="m51.95,202.18c-.8,1.85-2.28,3.27-5.06,3.21-3.7-.12-5.49-2.28-5.49-6.17s1.85-6.05,5.56-6.05,5.12,2.78,5.19,6.67h-7.53c0,1.91.49,3.4,2.41,3.46,1.11,0,1.85-.43,2.1-1.36l2.84.25Zm-2.78-4.26c.31-2.84-3.52-3.89-4.32-1.3-.12.37-.25.8-.25,1.3h4.57"
                              />
                              <path
                                id="aL-3"
                                className="cls-5-be1"
                                d="m64.3,202.18c-.8,1.85-2.28,3.27-5.06,3.21-3.7-.12-5.49-2.28-5.49-6.17s1.85-6.05,5.56-6.05,5.12,2.78,5.19,6.67h-7.53c0,1.91.49,3.4,2.41,3.46,1.11,0,1.85-.43,2.1-1.36l2.84.25Zm-2.78-4.26c.31-2.84-3.52-3.89-4.32-1.3-.12.37-.25.8-.25,1.3h4.57"
                              />
                            </g>
                            <g id="q">
                              <path
                                id="ba"
                                className="cls-5-be1"
                                d="m72.88,189.83c5.74.06,12.72-.99,12.59,4.88-.06,4.63-4.26,5.43-9.38,5.06v5.37h-3.21v-15.31Zm3.21,7.47c2.9,0,6.17.43,6.17-2.53s-3.33-2.41-6.17-2.41v4.94"
                              />
                              <path
                                id="bb"
                                className="cls-5-be1"
                                d="m93.25,195.45c-3.64,0-2.1,5.99-2.41,9.69h-3.09v-16.11h3.09l-.06,6.73c.74-1.6,1.73-2.53,3.77-2.59,5.31-.06,3.58,6.98,3.89,11.98h-3.09c-.43-3.52,1.42-9.69-2.1-9.69"
                              />
                              <path
                                id="bc"
                                className="cls-5-be1"
                                d="m107.94,203.04c-.99,1.17-1.85,2.41-3.95,2.35-2.28-.06-3.58-1.23-3.58-3.58-.06-3.7,3.4-3.89,7.16-3.77.06-1.6-.19-2.9-1.73-2.9-1.11.06-1.6.56-1.73,1.67l-3.21-.12c.43-2.35,2.22-3.58,5.06-3.52s4.57,1.36,4.63,4.2l.06,5.06c-.06.86.74,1.11,1.54.93v1.67c-1.85.49-4.38.31-4.26-1.98Zm-2.96.19c1.79,0,2.65-1.48,2.59-3.52-1.98,0-4.07-.19-4.01,1.85,0,1.05.49,1.67,1.42,1.67"
                              />
                              <path
                                id="bd"
                                className="cls-5-be1"
                                d="m120.53,196.62c.06-1.79-4.32-2.1-4.38-.25.93,2.84,7.28.43,7.35,5.31.06,5.12-10.12,4.69-10.62.56l2.65-.43c.25,1.17,1.23,1.54,2.72,1.54,2.04.49,3.52-1.85,1.48-2.53-2.65-.86-6.3-.68-6.42-4.07-.12-4.94,9.51-4.57,9.94-.43"
                              />
                              <path
                                id="aL-4"
                                className="cls-5-be1"
                                d="m135.84,202.18c-.8,1.85-2.28,3.27-5.06,3.21-3.7-.12-5.49-2.28-5.49-6.17s1.85-6.05,5.56-6.05,5.12,2.78,5.19,6.67h-7.53c0,1.91.49,3.4,2.41,3.46,1.11,0,1.85-.43,2.1-1.36l2.84.25Zm-2.78-4.26c.31-2.84-3.52-3.89-4.32-1.3-.12.37-.25.8-.25,1.3h4.57"
                              />
                            </g>
                            <g id="r">
                              <path
                                id="be"
                                className="cls-5-be1"
                                d="m144.36,205.14v-2.28h3.77v-10.43l-3.64,2.28v-2.35l3.83-2.53h2.84v13.02h3.52v2.28h-10.31"
                              />
                            </g>
                            <path
                              className="cls-12-be1"
                              d="m798.83,166.5c0-3.31,2.69-6,6-6h152.94c3.31,0,6,2.69,6,6v68c0,3.31-2.69,6-6,6h-152.94c-3.31,0-6-2.69-6-6v-68Z"
                            />
                            <g id="s">
                              <path
                                id="av-2"
                                className="cls-6-be1"
                                d="m817.13,176.76c-.43-1.21-1.51-1.94-3.15-1.94-2.55,0-3.76,1.73-3.76,4.28s1.25,4.36,3.85,4.36c1.86,0,2.68-1.04,3.37-2.25l1.17.6c-.78,1.64-2.2,2.85-4.62,2.85-3.46,0-5.06-2.16-5.23-5.57-.26-4.49,4.28-6.61,8.08-4.8.82.39,1.34,1.12,1.69,1.99"
                              />
                              <path
                                id="as-4"
                                className="cls-6-be1"
                                d="m823.48,176.11c2.68-.04,3.67,1.6,3.67,4.28.04,2.72-1.17,4.28-3.72,4.28s-3.59-1.69-3.63-4.28c0-2.85,1.21-4.28,3.67-4.28Zm-.04,7.52c1.9.04,2.29-1.34,2.29-3.24s-.35-3.24-2.2-3.24-2.29,1.38-2.29,3.24.43,3.2,2.2,3.24"
                              />
                              <path
                                id="au-4"
                                className="cls-6-be1"
                                d="m836.88,177.19c-2.9.13-1.64,4.54-1.9,7.3h-1.34v-5.23c0-1.25-.22-2.16-1.51-2.07-2.85.17-1.64,4.49-1.9,7.3h-1.34l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.43-1.9,4.28-2.16,4.62,0,.48-.91,1.17-1.51,2.51-1.56,3.67-.09,2.03,5.14,2.38,8.38h-1.34v-5.23c0-1.25-.22-2.12-1.51-2.07"
                              />
                              <path
                                id="au-5"
                                className="cls-6-be1"
                                d="m849.8,177.19c-2.9.13-1.64,4.54-1.9,7.3h-1.34v-5.23c0-1.25-.22-2.16-1.51-2.07-2.85.17-1.64,4.49-1.9,7.3h-1.34l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.43-1.9,4.28-2.16,4.62,0,.48-.91,1.17-1.51,2.51-1.56,3.67-.09,2.03,5.14,2.38,8.38h-1.34v-5.23c0-1.25-.22-2.12-1.51-2.07"
                              />
                              <path
                                id="az-6"
                                className="cls-6-be1"
                                d="m857.96,176.11c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                              <path
                                id="aq-4"
                                className="cls-6-be1"
                                d="m867.34,176.11c3.85-.17,2.29,5.01,2.59,8.38h-1.38v-5.23c0-1.34-.35-2.12-1.69-2.07-3.11.09-1.9,4.41-2.12,7.3h-1.38l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.48-.95,1.25-1.51,2.64-1.56"
                              />
                              <path
                                id="ay-5"
                                className="cls-6-be1"
                                d="m873.48,182.46c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="ax-3"
                                className="cls-6-be1"
                                d="m881.08,178.31c-.13-1.47-3.72-1.64-3.76,0,.65,2.29,4.97.52,5.14,3.89s-6.48,3.2-6.78.35l1.21-.22c.17,1.56,4.19,1.94,4.23,0-.43-2.42-4.88-.65-5.1-3.89-.17-2.46,3.54-2.72,5.27-1.81.52.3.91.82,1.04,1.51"
                              />
                            </g>
                            <g id="t">
                              <path
                                id="ax-4"
                                className="cls-6-be1"
                                d="m893.18,178.31c-.13-1.47-3.72-1.64-3.76,0,.65,2.29,4.97.52,5.14,3.89s-6.48,3.2-6.78.35l1.21-.22c.17,1.56,4.19,1.94,4.23,0-.43-2.42-4.88-.65-5.1-3.89-.17-2.46,3.54-2.72,5.27-1.81.52.3.91.82,1.04,1.51"
                              />
                              <path
                                id="aw-3"
                                className="cls-6-be1"
                                d="m898.75,184.67c-3.85.17-2.33-5.01-2.64-8.38h1.38v5.19c0,1.34.3,2.16,1.69,2.12,3.11-.09,1.94-4.36,2.16-7.3h1.34l.04,8.21h-1.3c-.04-.43.04-1.08-.09-1.43-.48.95-1.21,1.56-2.59,1.6"
                              />
                              <path
                                id="bf"
                                className="cls-6-be1"
                                d="m908.74,176.11c2.29,0,2.98,1.69,3.02,4.23,0,2.85-.99,4.32-3.02,4.32-1.34-.04-2.12-.48-2.55-1.47l-.09,1.3h-1.34l.04-11.28h1.38v4.36c.43-.99,1.21-1.47,2.55-1.47Zm-.35,7.52c1.73,0,1.94-1.47,1.94-3.24s-.22-3.24-1.94-3.2c-1.81,0-2.2,1.38-2.2,3.28s.43,3.15,2.2,3.15"
                              />
                              <path
                                id="au-6"
                                className="cls-6-be1"
                                d="m921.48,177.19c-2.9.13-1.64,4.54-1.9,7.3h-1.34v-5.23c0-1.25-.22-2.16-1.51-2.07-2.85.17-1.64,4.49-1.9,7.3h-1.34l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.43-1.9,4.28-2.16,4.62,0,.48-.91,1.17-1.51,2.51-1.56,3.67-.09,2.03,5.14,2.38,8.38h-1.34v-5.23c0-1.25-.22-2.12-1.51-2.07"
                              />
                              <path
                                id="aC-4"
                                className="cls-6-be1"
                                d="m926.37,174.51v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                              />
                              <path
                                id="ay-6"
                                className="cls-6-be1"
                                d="m931.29,182.46c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="ay-7"
                                className="cls-6-be1"
                                d="m935.61,182.46c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="az-7"
                                className="cls-6-be1"
                                d="m941.71,176.11c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                              <path
                                id="aV-2"
                                className="cls-6-be1"
                                d="m949.7,176.11c1.34,0,2.07.56,2.59,1.43l-.04-4.32h1.38l.04,11.28h-1.3c-.09-.43,0-.99-.13-1.34-.43.99-1.21,1.51-2.55,1.51-2.29,0-2.98-1.69-3.02-4.23,0-2.85.99-4.32,3.02-4.32Zm.39,1.04c-1.73,0-1.99,1.47-1.99,3.24s.26,3.2,1.94,3.2c1.81,0,2.2-1.38,2.2-3.28s-.39-3.2-2.16-3.15"
                              />
                            </g>
                            <g id="u">
                              <path
                                id="ay-8"
                                className="cls-6-be1"
                                d="m812.38,201.13c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="as-5"
                                className="cls-6-be1"
                                d="m818.47,194.78c2.68-.04,3.67,1.6,3.67,4.28.04,2.72-1.17,4.28-3.72,4.28s-3.59-1.69-3.63-4.28c0-2.85,1.21-4.28,3.67-4.28Zm-.04,7.52c1.9.04,2.29-1.34,2.29-3.24s-.35-3.24-2.2-3.24-2.29,1.38-2.29,3.24.43,3.2,2.2,3.24"
                              />
                            </g>
                            <g id="v">
                              <path
                                id="aR-2"
                                className="cls-6-be1"
                                d="m828.41,192.44c5.1-.3,9.33.35,9.2,5.27-.13,3.37-1.86,5.44-5.23,5.44h-3.98v-10.72Zm1.43,9.55c3.85.35,6.31-.69,6.31-4.28s-2.59-4.36-6.31-4.1v8.38"
                              />
                              <path
                                id="aS-2"
                                className="cls-6-be1"
                                d="m846.3,194.99c-.22-2.07-5.31-2.33-5.36.09.6,3.24,6.83.6,7.04,5.14.13,3.37-5.23,3.76-7.56,2.38-.73-.43-1.21-1.12-1.43-1.99l1.43-.3c.22,2.42,6.09,2.72,6.09-.04,0-3.37-6.7-.6-7-5.1-.22-3.54,6.27-3.63,7.73-1.47.22.3.35.69.48,1.08"
                              />
                              <path
                                id="aT-3"
                                className="cls-6-be1"
                                d="m854.16,193.65v9.51h-1.47v-9.51h-3.67v-1.21h8.81v1.21h-3.67"
                              />
                            </g>
                            <g id="w">
                              <path
                                id="as-6"
                                className="cls-6-be1"
                                d="m866.48,194.78c2.68-.04,3.67,1.6,3.67,4.28.04,2.72-1.17,4.28-3.72,4.28s-3.59-1.69-3.63-4.28c0-2.85,1.21-4.28,3.67-4.28Zm-.04,7.52c1.9.04,2.29-1.34,2.29-3.24s-.35-3.24-2.2-3.24-2.29,1.38-2.29,3.24.43,3.2,2.2,3.24"
                              />
                              <path
                                id="at-4"
                                className="cls-6-be1"
                                d="m875.72,196.12c-3.37-.69-2.29,3.93-2.46,7.04h-1.38l-.04-8.21h1.3c.04.52-.04,1.25.09,1.69.26-1.17.99-2.12,2.51-1.77v1.25"
                              />
                            </g>
                            <g id="x">
                              <path
                                id="aA-6"
                                className="cls-6-be1"
                                d="m886.35,201.6c-.65.91-1.34,1.77-2.94,1.73-1.56-.04-2.51-.91-2.51-2.46-.04-2.77,2.72-2.72,5.4-2.72.13-1.51-.39-2.33-1.77-2.33-1.04.04-1.77.3-1.81,1.34l-1.43-.13c.22-1.6,1.43-2.25,3.28-2.25s3.11.86,3.11,2.77v3.54c-.04.86.3,1.38,1.21,1.17v.86c-1.34.39-2.64-.09-2.55-1.51Zm-4.02-.73c0,.86.52,1.43,1.38,1.43,1.77-.13,2.72-1.25,2.59-3.2-1.86.09-3.98-.22-3.98,1.77"
                              />
                              <path
                                id="aD-3"
                                className="cls-6-be1"
                                d="m896.55,194.95c-.43,5.4,1.77,12.66-4.75,11.28-.99-.26-1.64-.86-1.9-1.86l1.38-.22c.65,2.03,4.32,1.38,3.85-1.21v-1.3c-.48.91-1.25,1.56-2.64,1.56-2.33,0-2.94-1.77-2.94-4.15s.69-4.19,3.07-4.23c1.25-.04,2.07.69,2.55,1.51.04-.43,0-.99.09-1.38h1.3Zm-3.59,7.26c1.56,0,2.16-1.38,2.16-3.15s-.6-3.24-2.16-3.24c-1.69,0-1.99,1.47-1.99,3.24s.26,3.15,1.99,3.15"
                              />
                              <path
                                id="az-8"
                                className="cls-6-be1"
                                d="m901.87,194.78c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                              <path
                                id="aq-5"
                                className="cls-6-be1"
                                d="m911.24,194.78c3.85-.17,2.29,5.01,2.59,8.38h-1.38v-5.23c0-1.34-.35-2.12-1.69-2.07-3.11.09-1.9,4.41-2.12,7.3h-1.38l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.48-.95,1.25-1.51,2.64-1.56"
                              />
                              <path
                                id="aB-4"
                                className="cls-6-be1"
                                d="m918.98,195.86c-1.73,0-2.07,1.43-2.07,3.15s.39,3.24,2.07,3.24c1.04,0,1.77-.6,1.86-1.64l1.38.09c-.26,1.6-1.34,2.64-3.2,2.64-2.55,0-3.28-1.77-3.54-4.28-.43-4.02,4.36-5.66,6.35-2.77.17.3.22.6.3.95l-1.38.13c-.17-.91-.69-1.51-1.77-1.51"
                              />
                              <path
                                id="bg"
                                className="cls-6-be1"
                                d="m930.34,194.95l-3.72,9.55c-.6,1.21-1.6,2.2-3.5,1.81v-1.04c1.69.26,2.29-.86,2.77-2.16l-3.24-8.17h1.47l2.46,6.74,2.33-6.74h1.43"
                              />
                            </g>
                            <g id="y">
                              <path
                                id="ar-3"
                                className="cls-6-be1"
                                d="m939.07,193.05c-1.34-.39-1.81.43-1.64,1.9h1.64v.99h-1.64v7.22h-1.34v-7.22h-1.17v-.99h1.17c-.3-2.25.73-3.54,2.98-2.94v1.04"
                              />
                              <path
                                id="as-7"
                                className="cls-6-be1"
                                d="m943.35,194.78c2.68-.04,3.67,1.6,3.67,4.28.04,2.72-1.17,4.28-3.72,4.28s-3.59-1.69-3.63-4.28c0-2.85,1.21-4.28,3.67-4.28Zm-.04,7.52c1.9.04,2.29-1.34,2.29-3.24s-.35-3.24-2.2-3.24-2.29,1.38-2.29,3.24.43,3.2,2.2,3.24"
                              />
                              <path
                                id="at-5"
                                className="cls-6-be1"
                                d="m952.59,196.12c-3.37-.69-2.29,3.93-2.46,7.04h-1.38l-.04-8.21h1.3c.04.52-.04,1.25.09,1.69.26-1.17.99-2.12,2.51-1.77v1.25"
                              />
                            </g>
                            <g id="z">
                              <path
                                id="ar-4"
                                className="cls-6-be1"
                                d="m833.46,211.72c-1.34-.39-1.81.43-1.64,1.9h1.64v.99h-1.64v7.22h-1.34v-7.22h-1.17v-.99h1.17c-.3-2.25.73-3.54,2.98-2.94v1.04"
                              />
                              <path
                                id="aw-4"
                                className="cls-6-be1"
                                d="m837.05,222c-3.85.17-2.33-5.01-2.64-8.38h1.38v5.19c0,1.34.3,2.16,1.69,2.12,3.11-.09,1.94-4.36,2.16-7.3h1.34l.04,8.21h-1.3c-.04-.43.04-1.08-.09-1.43-.48.95-1.21,1.56-2.59,1.6"
                              />
                              <path
                                id="at-6"
                                className="cls-6-be1"
                                d="m846.99,214.78c-3.37-.69-2.29,3.93-2.46,7.04h-1.38l-.04-8.21h1.3c.04.52-.04,1.25.09,1.69.26-1.17.99-2.12,2.51-1.77v1.25"
                              />
                              <path
                                id="ay-9"
                                className="cls-6-be1"
                                d="m849.75,219.8c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="bh"
                                className="cls-6-be1"
                                d="m856.11,214.52c-3.11,0-1.9,4.41-2.12,7.3h-1.38v-11.28h1.38l-.04,4.45c.52-.91,1.21-1.56,2.64-1.56,3.85,0,2.29,5.01,2.59,8.38h-1.38v-5.23c.09-1.38-.35-2.12-1.69-2.07"
                              />
                              <path
                                id="az-9"
                                className="cls-6-be1"
                                d="m864.49,213.44c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                              <path
                                id="at-7"
                                className="cls-6-be1"
                                d="m873.74,214.78c-3.37-.69-2.29,3.93-2.46,7.04h-1.38l-.04-8.21h1.3c.04.52-.04,1.25.09,1.69.26-1.17.99-2.12,2.51-1.77v1.25"
                              />
                            </g>
                            <g id="A">
                              <path
                                id="aA-7"
                                className="cls-6-be1"
                                d="m884.37,220.27c-.65.91-1.34,1.77-2.94,1.73-1.56-.04-2.51-.91-2.51-2.46-.04-2.77,2.72-2.72,5.4-2.72.13-1.51-.39-2.33-1.77-2.33-1.04.04-1.77.3-1.81,1.34l-1.43-.13c.22-1.6,1.43-2.25,3.28-2.25s3.11.86,3.11,2.77v3.54c-.04.86.3,1.38,1.21,1.17v.86c-1.34.39-2.64-.09-2.55-1.51Zm-4.02-.73c0,.86.52,1.43,1.38,1.43,1.77-.13,2.72-1.25,2.59-3.2-1.86.09-3.98-.22-3.98,1.77"
                              />
                              <path
                                id="au-7"
                                className="cls-6-be1"
                                d="m895.99,214.52c-2.9.13-1.64,4.54-1.9,7.3h-1.34v-5.23c0-1.25-.22-2.16-1.51-2.07-2.85.17-1.64,4.49-1.9,7.3h-1.34l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.43-1.9,4.28-2.16,4.62,0,.48-.91,1.17-1.51,2.51-1.56,3.67-.09,2.03,5.14,2.38,8.38h-1.34v-5.23c0-1.25-.22-2.12-1.51-2.07"
                              />
                              <path
                                id="az-10"
                                className="cls-6-be1"
                                d="m904.16,213.44c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                              <path
                                id="aq-6"
                                className="cls-6-be1"
                                d="m913.53,213.44c3.85-.17,2.29,5.01,2.59,8.38h-1.38v-5.23c0-1.34-.35-2.12-1.69-2.07-3.11.09-1.9,4.41-2.12,7.3h-1.38l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.48-.95,1.25-1.51,2.64-1.56"
                              />
                              <path
                                id="aV-3"
                                className="cls-6-be1"
                                d="m920.79,213.44c1.34,0,2.07.56,2.59,1.43l-.04-4.32h1.38l.04,11.28h-1.3c-.09-.43,0-.99-.13-1.34-.43.99-1.21,1.51-2.55,1.51-2.29,0-2.98-1.69-3.02-4.23,0-2.85.99-4.32,3.02-4.32Zm.39,1.04c-1.73,0-1.99,1.47-1.99,3.24s.26,3.2,1.94,3.2c1.81,0,2.2-1.38,2.2-3.28s-.39-3.2-2.16-3.15"
                              />
                              <path
                                id="ax-5"
                                className="cls-6-be1"
                                d="m931.59,215.65c-.13-1.47-3.72-1.64-3.76,0,.65,2.29,4.97.52,5.14,3.89s-6.48,3.2-6.78.35l1.21-.22c.17,1.56,4.19,1.94,4.23,0-.43-2.42-4.88-.65-5.1-3.89-.17-2.46,3.54-2.72,5.27-1.81.52.3.91.82,1.04,1.51"
                              />
                            </g>
                            <path
                              className="cls-11-be1"
                              d="m218.31,201h-21.06v-1h21.06v1Zm192.5,0h-13.1v-1h13.1v1Z"
                            />
                            <path
                              className="cls-7-be1"
                              d="m411.3,200.5l.05.5h-.56v-1h.56l-.05.5Z"
                            />
                            <path
                              className="cls-11-be1"
                              d="m411.33,200.5l.04.5h-.6v-1.04h.6l-.04.54Zm-.52-.5v.96h.5l-.03-.47.04-.5h-.51Z"
                            />
                            <path
                              className="cls-7-be1"
                              d="m196.75,205.12l-14.26-4.63,14.26-4.65v9.28Z"
                            />
                            <path
                              className="cls-11-be1"
                              d="m197.25,205.8l-16.38-5.3,16.38-5.34v10.64Zm-13.15-5.3l12.15,3.93v-7.9l-12.15,3.97Z"
                            />
                            <g id="B">
                              <path
                                id="bi"
                                className="cls-2-be1"
                                d="m230.17,195.45c0,1.98-1.14,3.01-2.67,3.46l3.31,5.14h-2.91l-2.81-4.64h-3.01v4.64h-2.57v-12.25c4.59.2,10.72-1.14,10.67,3.65Zm-8.1,1.98c2.37-.1,5.53.59,5.53-1.88s-3.26-1.58-5.53-1.73v3.6"
                              />
                              <path
                                id="bj"
                                className="cls-2-be1"
                                d="m240.24,201.67c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="bk"
                                className="cls-2-be1"
                                d="m247.31,204.04h-2.91l-3.36-9.38h2.62l2.22,7.41,2.37-7.41h2.57"
                              />
                              <path
                                id="bl"
                                className="cls-2-be1"
                                d="m252.1,192.98v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="bm"
                                className="cls-2-be1"
                                d="m262.57,197.23c.05-1.43-3.46-1.68-3.51-.2.74,2.27,5.83.35,5.88,4.25.05,4.1-8.1,3.75-8.49.44l2.12-.35c.2.94.99,1.23,2.17,1.23,1.63.4,2.81-1.48,1.19-2.02-2.12-.69-5.04-.54-5.14-3.26-.1-3.95,7.6-3.65,7.95-.35"
                              />
                              <path
                                id="bj-2"
                                className="cls-2-be1"
                                d="m274.81,201.67c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                            </g>
                            <g id="C">
                              <path
                                id="bn"
                                className="cls-2-be1"
                                d="m287.06,202.36c-.79.94-1.48,1.93-3.16,1.88-1.83-.05-2.86-.99-2.86-2.86-.05-2.96,2.72-3.11,5.73-3.01.05-1.28-.15-2.32-1.38-2.32-.89.05-1.28.44-1.38,1.33l-2.57-.1c.35-1.88,1.78-2.86,4.05-2.81s3.65,1.09,3.7,3.36l.05,4.05c-.05.69.59.89,1.23.74v1.33c-1.48.4-3.51.25-3.41-1.58Zm-2.37.15c1.43,0,2.12-1.19,2.07-2.81-1.58,0-3.26-.15-3.21,1.48,0,.84.4,1.33,1.14,1.33"
                              />
                              <path
                                id="bo"
                                className="cls-2-be1"
                                d="m297.03,194.46c4.3-.05,2.86,5.58,3.11,9.58h-2.47c-.35-2.81,1.14-7.75-1.68-7.75s-1.68,4.79-1.93,7.75h-2.47l-.05-9.38h2.32c.1.59-.05,1.38.15,1.88.59-1.28,1.38-2.02,3.01-2.07"
                              />
                              <path
                                id="bp"
                                className="cls-2-be1"
                                d="m305.53,194.46c1.53-.05,2.27.74,2.86,1.68l-.05-4.99h2.47l.05,12.89h-2.37c-.1-.49,0-1.14-.15-1.53-.54,1.14-1.43,1.73-3.01,1.73-2.57,0-3.36-2.22-3.41-4.89,0-2.77.94-4.79,3.6-4.89Zm.84,8.1c1.63,0,1.98-1.48,2.02-3.26.05-1.83-.44-3.16-2.02-3.16s-1.88,1.48-1.93,3.21c0,2.12.64,3.21,1.93,3.21"
                              />
                            </g>
                            <g id="D">
                              <path
                                id="bi-2"
                                className="cls-2-be1"
                                d="m328.79,195.45c0,1.98-1.14,3.01-2.67,3.46l3.31,5.14h-2.91l-2.81-4.64h-3.01v4.64h-2.57v-12.25c4.59.2,10.72-1.14,10.67,3.65Zm-8.1,1.98c2.37-.1,5.53.59,5.53-1.88s-3.26-1.58-5.53-1.73v3.6"
                              />
                              <path
                                id="bj-3"
                                className="cls-2-be1"
                                d="m338.86,201.67c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="bm-2"
                                className="cls-2-be1"
                                d="m346.37,197.23c.05-1.43-3.46-1.68-3.51-.2.74,2.27,5.83.35,5.88,4.25.05,4.1-8.1,3.75-8.49.44l2.12-.35c.2.94.99,1.23,2.17,1.23,1.63.4,2.81-1.48,1.19-2.02-2.12-.69-5.04-.54-5.14-3.26-.1-3.95,7.6-3.65,7.95-.35"
                              />
                              <path
                                id="bq"
                                className="cls-2-be1"
                                d="m353.68,204.24c-4.3.05-2.86-5.58-3.11-9.58h2.47c.35,2.81-1.14,7.41,1.63,7.75,2.96-.25,1.73-4.79,1.98-7.75h2.47l.05,9.38h-2.32c-.1-.59.05-1.38-.15-1.88-.59,1.23-1.38,2.07-3.01,2.07"
                              />
                              <path
                                id="br"
                                className="cls-2-be1"
                                d="m366.96,194.46c2.57,0,3.46,2.12,3.46,4.84s-.94,4.89-3.6,4.94c-1.48.05-2.27-.74-2.86-1.73l-.1,1.53h-2.37l.05-12.89h2.47v5.14c.54-1.14,1.43-1.83,2.96-1.83Zm-1.04,8.1c1.53,0,1.98-1.33,1.98-3.26s-.35-3.11-1.93-3.11-2.02,1.38-2.02,3.21.4,3.16,1.98,3.16"
                              />
                              <path
                                id="bs"
                                className="cls-2-be1"
                                d="m381.97,196.29c-2.62.44-1.38,4.94-1.68,7.75h-2.42v-5.28c.05-1.33-.25-2.42-1.43-2.47-2.62.49-1.33,4.94-1.63,7.75h-2.47l-.05-9.38h2.32c.1.59-.05,1.38.15,1.88.49-2.62,4.99-2.77,5.33,0,.64-1.09,1.19-2.12,2.91-2.07,4.05.05,2.52,5.73,2.81,9.58h-2.42v-5.28c-.05-1.23-.25-2.37-1.43-2.47"
                              />
                              <path
                                id="bl-2"
                                className="cls-2-be1"
                                d="m388.15,192.98v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="bt"
                                className="cls-2-be1"
                                d="m397.53,203.89c-1.78.69-4.3.35-4.3-2.07v-5.53h-1.19v-1.63h1.33l.74-2.22h1.53v2.22h1.78v1.63h-1.78v4.89c-.05,1.14.79,1.53,1.88,1.23v1.48"
                              />
                            </g>
                            <path
                              className="cls-8-be1"
                              d="m574.67,201h-23.16v-1h23.16v1Zm207.27,0h-7.26v-1h7.27v1Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m551.53,201h-.56l.04-.5-.04-.5h.56v1Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m551.55,201h-.6l.04-.5-.05-.54h.62v1.04Zm-.55-1l.04.5-.03.46h.5v-.95h-.51Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m796.7,200.5l-14.26,4.62v-9.27l14.26,4.65Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m798.33,200.5l-16.4,5.3v-10.64l16.4,5.34Zm-15.4,3.93l12.15-3.94-12.14-3.96v7.9Z"
                            />
                            <g id="E">
                              <path
                                id="bu"
                                className="cls-2-be1"
                                d="m584.5,204.04l-1.09-3.11h-4.64l-1.09,3.11h-2.57l4.44-12.25h3.01l4.44,12.25h-2.52Zm-1.63-5.04l-1.78-5.33c-.49,1.88-1.19,3.56-1.78,5.33h3.56"
                              />
                              <path
                                id="bv"
                                className="cls-2-be1"
                                d="m594.13,194.46c2.62,0,3.46,2.17,3.46,4.84s-.94,4.84-3.6,4.94c-1.53.05-2.22-.84-2.91-1.68.15,1.63.1,3.41.1,5.19h-2.47l-.05-13.09h2.37c.1.49,0,1.14.15,1.53.54-1.19,1.43-1.73,2.96-1.73Zm-1.04,8.1c1.63,0,1.93-1.53,1.98-3.26,0-1.88-.44-3.16-1.98-3.16-2.77,0-2.72,6.42,0,6.42"
                              />
                              <path
                                id="bv-2"
                                className="cls-2-be1"
                                d="m604.94,194.46c2.62,0,3.46,2.17,3.46,4.84s-.94,4.84-3.6,4.94c-1.53.05-2.22-.84-2.91-1.68.15,1.63.1,3.41.1,5.19h-2.47l-.05-13.09h2.37c.1.49,0,1.14.15,1.53.54-1.19,1.43-1.73,2.96-1.73Zm-1.04,8.1c1.63,0,1.93-1.53,1.98-3.26,0-1.88-.44-3.16-1.98-3.16-2.77,0-2.72,6.42,0,6.42"
                              />
                              <path
                                id="bw"
                                className="cls-2-be1"
                                d="m615.76,196.63c-1.93-.59-2.96.64-2.96,2.81v4.59h-2.47l-.05-9.38h2.32c.1.64-.05,1.43.15,1.98.3-1.38,1.33-2.62,3.01-2.02v2.02"
                              />
                              <path
                                id="bx"
                                className="cls-2-be1"
                                d="m621.44,194.46c3.16,0,4.74,1.78,4.74,4.89s-1.73,4.89-4.79,4.89-4.69-1.78-4.69-4.89,1.68-4.89,4.74-4.89Zm-.05,8.1c1.73,0,2.22-1.38,2.22-3.21,0-1.98-.49-3.21-2.12-3.21s-2.22,1.28-2.22,3.21c0,1.78.49,3.21,2.12,3.21"
                              />
                              <path
                                id="bk-2"
                                className="cls-2-be1"
                                d="m633.14,204.04h-2.91l-3.36-9.38h2.62l2.22,7.41,2.37-7.41h2.57"
                              />
                              <path
                                id="bj-4"
                                className="cls-2-be1"
                                d="m645.83,201.67c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="bp-2"
                                className="cls-2-be1"
                                d="m650.92,194.46c1.53-.05,2.27.74,2.86,1.68l-.05-4.99h2.47l.05,12.89h-2.37c-.1-.49,0-1.14-.15-1.53-.54,1.14-1.43,1.73-3.01,1.73-2.57,0-3.36-2.22-3.41-4.89,0-2.77.94-4.79,3.6-4.89Zm.84,8.1c1.63,0,1.98-1.48,2.02-3.26.05-1.83-.44-3.16-2.02-3.16s-1.88,1.48-1.93,3.21c0,2.12.64,3.21,1.93,3.21"
                              />
                            </g>
                            <g id="F">
                              <path
                                id="by"
                                className="cls-2-be1"
                                d="m673.73,204.04h-2.57l-1.93-7.65-1.98,7.65h-2.57l-2.42-9.38h2.27l1.58,7.16c.44-2.57,1.19-4.79,1.78-7.16h2.62l1.83,7.16,1.58-7.16h2.27"
                              />
                              <path
                                id="bl-3"
                                className="cls-2-be1"
                                d="m677.39,192.98v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="bt-2"
                                className="cls-2-be1"
                                d="m686.77,203.89c-1.78.69-4.3.35-4.3-2.07v-5.53h-1.19v-1.63h1.33l.74-2.22h1.53v2.22h1.78v1.63h-1.78v4.89c-.05,1.14.79,1.53,1.88,1.23v1.48"
                              />
                              <path
                                id="bz"
                                className="cls-2-be1"
                                d="m692.6,196.29c-2.91,0-1.68,4.79-1.93,7.75h-2.47v-12.89h2.47l-.05,5.38c.59-1.28,1.38-2.02,3.01-2.07,4.25-.05,2.86,5.58,3.11,9.58h-2.47c-.35-2.81,1.14-7.75-1.68-7.75"
                              />
                            </g>
                            <g id="G">
                              <path
                                id="bA"
                                className="cls-2-be1"
                                d="m712.1,200.93c-.35,2.07-1.88,3.31-4.25,3.31-2.91,0-4.15-1.88-4.44-4.84-.59-5.43,7.6-6.77,8.59-1.78l-2.42.1c-.1-.94-.74-1.58-1.73-1.58-1.48,0-1.73,1.38-1.88,3.16-.3,3.65,3.21,4.3,3.65,1.48"
                              />
                              <path
                                id="bz-2"
                                className="cls-2-be1"
                                d="m718.23,196.29c-2.91,0-1.68,4.79-1.93,7.75h-2.47v-12.89h2.47l-.05,5.38c.59-1.28,1.38-2.02,3.01-2.07,4.25-.05,2.86,5.58,3.11,9.58h-2.47c-.35-2.81,1.14-7.75-1.68-7.75"
                              />
                              <path
                                id="bn-2"
                                className="cls-2-be1"
                                d="m729.98,202.36c-.79.94-1.48,1.93-3.16,1.88-1.83-.05-2.86-.99-2.86-2.86-.05-2.96,2.72-3.11,5.73-3.01.05-1.28-.15-2.32-1.38-2.32-.89.05-1.28.44-1.38,1.33l-2.57-.1c.35-1.88,1.78-2.86,4.05-2.81s3.65,1.09,3.7,3.36l.05,4.05c-.05.69.59.89,1.23.74v1.33c-1.48.4-3.51.25-3.41-1.58Zm-2.37.15c1.43,0,2.12-1.19,2.07-2.81-1.58,0-3.26-.15-3.21,1.48,0,.84.4,1.33,1.14,1.33"
                              />
                              <path
                                id="bo-2"
                                className="cls-2-be1"
                                d="m739.96,194.46c4.3-.05,2.86,5.58,3.11,9.58h-2.47c-.35-2.81,1.14-7.75-1.68-7.75s-1.68,4.79-1.93,7.75h-2.47l-.05-9.38h2.32c.1.59-.05,1.38.15,1.88.59-1.28,1.38-2.02,3.01-2.07"
                              />
                              <path
                                id="bB"
                                className="cls-2-be1"
                                d="m753.73,203.75c.54,4.35-5.93,5.23-8.1,2.57-.2-.3-.3-.64-.4-1.04l2.42-.3c.15.79.79,1.19,1.68,1.23,1.98,0,2.07-1.83,1.98-3.9-.54,1.09-1.48,1.73-3.01,1.73-2.62,0-3.46-2.12-3.46-4.79s.89-4.74,3.6-4.79c1.48,0,2.27.69,2.91,1.68l.1-1.48h2.32l-.05,9.09Zm-4.44-1.43c1.58,0,2.02-1.33,2.02-3.11s-.44-3.06-1.98-3.06-1.93,1.43-1.98,3.11c0,1.78.44,3.06,1.93,3.06"
                              />
                              <path
                                id="bj-5"
                                className="cls-2-be1"
                                d="m764.06,201.67c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="bm-3"
                                className="cls-2-be1"
                                d="m771.56,197.23c.05-1.43-3.46-1.68-3.51-.2.74,2.27,5.83.35,5.88,4.25.05,4.1-8.1,3.75-8.49.44l2.12-.35c.2.94.99,1.23,2.17,1.23,1.63.4,2.81-1.48,1.19-2.02-2.12-.69-5.04-.54-5.14-3.26-.1-3.95,7.6-3.65,7.95-.35"
                              />
                            </g>
                            <path
                              className="cls-8-be1"
                              d="m481.66,341.12h-1v-41.64h1v41.64Zm0-62.97h-1v-19.32h1v19.32Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m481.16,258.33l.5-.03v.54h-1v-.54l.5.03Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m481.16,258.3l.52-.03v.6h-1.05v-.6l.53.03Zm-.48.52h.95v-.5l-.47.03-.48-.03v.5Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m481.16,355.9l-4.64-14.28h9.26l-4.62,14.28Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m481.16,357.5l-5.32-16.38h10.65l-5.33,16.38Zm-3.95-15.38l3.95,12.15,3.94-12.15h-7.89Z"
                            />
                            <g id="H">
                              <path
                                id="bC"
                                className="cls-4-be1"
                                d="m386.13,292.37l-1.09-3.11h-4.64l-1.09,3.11h-2.57l4.44-12.25h3.01l4.44,12.25h-2.52Zm-1.63-5.04l-1.78-5.33c-.49,1.88-1.19,3.56-1.78,5.33h3.56"
                              />
                              <path
                                id="bD"
                                className="cls-4-be1"
                                d="m395.76,282.79c2.62,0,3.46,2.17,3.46,4.84s-.94,4.84-3.6,4.94c-1.53.05-2.22-.84-2.91-1.68.15,1.63.1,3.41.1,5.19h-2.47l-.05-13.09h2.37c.1.49,0,1.14.15,1.53.54-1.19,1.43-1.73,2.96-1.73Zm-1.04,8.1c1.63,0,1.93-1.53,1.98-3.26,0-1.88-.44-3.16-1.98-3.16-2.77,0-2.72,6.42,0,6.42"
                              />
                              <path
                                id="bD-2"
                                className="cls-4-be1"
                                d="m406.57,282.79c2.62,0,3.46,2.17,3.46,4.84s-.94,4.84-3.6,4.94c-1.53.05-2.22-.84-2.91-1.68.15,1.63.1,3.41.1,5.19h-2.47l-.05-13.09h2.37c.1.49,0,1.14.15,1.53.54-1.19,1.43-1.73,2.96-1.73Zm-1.04,8.1c1.63,0,1.93-1.53,1.98-3.26,0-1.88-.44-3.16-1.98-3.16-2.77,0-2.72,6.42,0,6.42"
                              />
                              <path
                                id="bE"
                                className="cls-4-be1"
                                d="m417.39,284.96c-1.93-.59-2.96.64-2.96,2.81v4.59h-2.47l-.05-9.38h2.32c.1.64-.05,1.43.15,1.98.3-1.38,1.33-2.62,3.01-2.02v2.02"
                              />
                              <path
                                id="bF"
                                className="cls-4-be1"
                                d="m423.06,282.79c3.16,0,4.74,1.78,4.74,4.89s-1.73,4.89-4.79,4.89-4.69-1.78-4.69-4.89,1.68-4.89,4.74-4.89Zm-.05,8.1c1.73,0,2.22-1.38,2.22-3.21,0-1.98-.49-3.21-2.12-3.21s-2.22,1.28-2.22,3.21c0,1.78.49,3.21,2.12,3.21"
                              />
                              <path
                                id="bG"
                                className="cls-4-be1"
                                d="m434.77,292.37h-2.91l-3.36-9.38h2.62l2.22,7.41,2.37-7.41h2.57"
                              />
                              <path
                                id="bH"
                                className="cls-4-be1"
                                d="m447.46,290c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="bI"
                                className="cls-4-be1"
                                d="m452.55,282.79c1.53-.05,2.27.74,2.86,1.68l-.05-4.99h2.47l.05,12.89h-2.37c-.1-.49,0-1.14-.15-1.53-.54,1.14-1.43,1.73-3.01,1.73-2.57,0-3.36-2.22-3.41-4.89,0-2.77.94-4.79,3.6-4.89Zm.84,8.1c1.63,0,1.98-1.48,2.02-3.26.05-1.83-.44-3.16-2.02-3.16s-1.88,1.48-1.93,3.21c0,2.12.64,3.21,1.93,3.21"
                              />
                            </g>
                            <g id="I">
                              <path
                                id="bJ"
                                className="cls-4-be1"
                                d="m469.93,281.21c-1.33-.35-2.12.25-1.88,1.78h1.88v1.63h-1.88v7.75h-2.42v-7.75h-1.38v-1.63h1.38c-.44-2.91,1.58-4,4.3-3.36v1.58"
                              />
                              <path
                                id="bF-2"
                                className="cls-4-be1"
                                d="m475.26,282.79c3.16,0,4.74,1.78,4.74,4.89s-1.73,4.89-4.79,4.89-4.69-1.78-4.69-4.89,1.68-4.89,4.74-4.89Zm-.05,8.1c1.73,0,2.22-1.38,2.22-3.21,0-1.98-.49-3.21-2.12-3.21s-2.22,1.28-2.22,3.21c0,1.78.49,3.21,2.12,3.21"
                              />
                              <path
                                id="bE-2"
                                className="cls-4-be1"
                                d="m487.31,284.96c-1.93-.59-2.96.64-2.96,2.81v4.59h-2.47l-.05-9.38h2.32c.1.64-.05,1.43.15,1.98.3-1.38,1.33-2.62,3.01-2.02v2.02"
                              />
                            </g>
                            <g id="J">
                              <path
                                id="bK"
                                className="cls-4-be1"
                                d="m493.68,280.12c4.59.05,10.17-.79,10.07,3.9-.05,3.7-3.41,4.35-7.51,4.05v4.3h-2.57v-12.25Zm2.57,5.98c2.32,0,4.94.35,4.94-2.02s-2.67-1.93-4.94-1.93v3.95"
                              />
                              <path
                                id="bE-3"
                                className="cls-4-be1"
                                d="m511.02,284.96c-1.93-.59-2.96.64-2.96,2.81v4.59h-2.47l-.05-9.38h2.32c.1.64-.05,1.43.15,1.98.3-1.38,1.33-2.62,3.01-2.02v2.02"
                              />
                              <path
                                id="bF-3"
                                className="cls-4-be1"
                                d="m516.69,282.79c3.16,0,4.74,1.78,4.74,4.89s-1.73,4.89-4.79,4.89-4.69-1.78-4.69-4.89,1.68-4.89,4.74-4.89Zm-.05,8.1c1.73,0,2.22-1.38,2.22-3.21,0-1.98-.49-3.21-2.12-3.21s-2.22,1.28-2.22,3.21c0,1.78.49,3.21,2.12,3.21"
                              />
                              <path
                                id="bI-2"
                                className="cls-4-be1"
                                d="m526.42,282.79c1.53-.05,2.27.74,2.86,1.68l-.05-4.99h2.47l.05,12.89h-2.37c-.1-.49,0-1.14-.15-1.53-.54,1.14-1.43,1.73-3.01,1.73-2.57,0-3.36-2.22-3.41-4.89,0-2.77.94-4.79,3.6-4.89Zm.84,8.1c1.63,0,1.98-1.48,2.02-3.26.05-1.83-.44-3.16-2.02-3.16s-1.88,1.48-1.93,3.21c0,2.12.64,3.21,1.93,3.21"
                              />
                              <path
                                id="bL"
                                className="cls-4-be1"
                                d="m537.09,292.57c-4.3.05-2.86-5.58-3.11-9.58h2.47c.35,2.81-1.14,7.41,1.63,7.75,2.96-.25,1.73-4.79,1.98-7.75h2.47l.05,9.38h-2.32c-.1-.59.05-1.38-.15-1.88-.59,1.23-1.38,2.07-3.01,2.07"
                              />
                              <path
                                id="bM"
                                className="cls-4-be1"
                                d="m553.09,289.26c-.35,2.07-1.88,3.31-4.25,3.31-2.91,0-4.15-1.88-4.44-4.84-.59-5.43,7.6-6.77,8.59-1.78l-2.42.1c-.1-.94-.74-1.58-1.73-1.58-1.48,0-1.73,1.38-1.88,3.16-.3,3.65,3.21,4.3,3.65,1.48"
                              />
                              <path
                                id="bN"
                                className="cls-4-be1"
                                d="m559.26,292.22c-1.78.69-4.3.35-4.3-2.07v-5.53h-1.19v-1.63h1.33l.74-2.22h1.53v2.22h1.78v1.63h-1.78v4.89c-.05,1.14.79,1.53,1.88,1.23v1.48"
                              />
                              <path
                                id="bO"
                                className="cls-4-be1"
                                d="m560.69,281.31v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="bF-4"
                                className="cls-4-be1"
                                d="m569.83,282.79c3.16,0,4.74,1.78,4.74,4.89s-1.73,4.89-4.79,4.89-4.69-1.78-4.69-4.89,1.68-4.89,4.74-4.89Zm-.05,8.1c1.73,0,2.22-1.38,2.22-3.21,0-1.98-.49-3.21-2.12-3.21s-2.22,1.28-2.22,3.21c0,1.78.49,3.21,2.12,3.21"
                              />
                              <path
                                id="bP"
                                className="cls-4-be1"
                                d="m581.88,282.79c4.3-.05,2.86,5.58,3.11,9.58h-2.47c-.35-2.81,1.14-7.75-1.68-7.75s-1.68,4.79-1.93,7.75h-2.47l-.05-9.38h2.32c.1.59-.05,1.38.15,1.88.59-1.28,1.38-2.02,3.01-2.07"
                              />
                            </g>
                            <a href="#link7">
                              <path
                                className="cls-12-be1"
                                d="m361.4,524c0-3.31,2.69-6,6-6h229.43c3.31,0,6,2.69,6,6v68c0,3.31-2.69,6-6,6h-229.42c-3.31,0-6-2.69-6-6v-68Z"
                              />
                            </a>
                            <g id="e-2">
                              <path
                                id="ap-3"
                                className="cls-6-be1"
                                d="m417.87,561.7v-10.72h1.47v10.72h-1.47"
                              />
                              <path
                                id="aq-7"
                                className="cls-6-be1"
                                d="m425.82,553.32c3.85-.17,2.29,5.01,2.59,8.38h-1.38v-5.23c0-1.34-.35-2.12-1.69-2.07-3.11.09-1.9,4.41-2.12,7.3h-1.38l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.48-.95,1.25-1.51,2.64-1.56"
                              />
                              <path
                                id="aC-5"
                                className="cls-6-be1"
                                d="m430.44,551.72v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                              />
                              <path
                                id="ay-10"
                                className="cls-6-be1"
                                d="m435.37,559.67c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="aC-6"
                                className="cls-6-be1"
                                d="m438.18,551.72v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                              />
                              <path
                                id="aA-8"
                                className="cls-6-be1"
                                d="m446.65,560.15c-.65.91-1.34,1.77-2.94,1.73-1.56-.04-2.51-.91-2.51-2.46-.04-2.77,2.72-2.72,5.4-2.72.13-1.51-.39-2.33-1.77-2.33-1.04.04-1.77.3-1.81,1.34l-1.43-.13c.22-1.6,1.43-2.25,3.28-2.25s3.11.86,3.11,2.77v3.54c-.04.86.3,1.38,1.21,1.17v.86c-1.34.39-2.64-.09-2.55-1.51Zm-4.02-.73c0,.86.52,1.43,1.38,1.43,1.77-.13,2.72-1.25,2.59-3.2-1.86.09-3.98-.22-3.98,1.77"
                              />
                              <path
                                id="ay-11"
                                className="cls-6-be1"
                                d="m451.75,559.67c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="az-11"
                                className="cls-6-be1"
                                d="m457.84,553.32c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                            </g>
                            <g id="K">
                              <path
                                id="av-3"
                                className="cls-6-be1"
                                d="m475.64,553.97c-.43-1.21-1.51-1.94-3.15-1.94-2.55,0-3.76,1.73-3.76,4.28s1.25,4.36,3.85,4.36c1.86,0,2.68-1.04,3.37-2.25l1.17.6c-.78,1.64-2.2,2.85-4.62,2.85-3.46,0-5.06-2.16-5.23-5.57-.26-4.49,4.28-6.61,8.08-4.8.82.39,1.34,1.12,1.69,1.99"
                              />
                              <path
                                id="az-12"
                                className="cls-6-be1"
                                d="m481.99,553.32c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                              <path
                                id="at-8"
                                className="cls-6-be1"
                                d="m491.24,554.66c-3.37-.69-2.29,3.93-2.46,7.04h-1.38l-.04-8.21h1.3c.04.52-.04,1.25.09,1.69.26-1.17.99-2.12,2.51-1.77v1.25"
                              />
                              <path
                                id="ay-12"
                                className="cls-6-be1"
                                d="m494.01,559.67c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="aC-7"
                                className="cls-6-be1"
                                d="m496.82,551.72v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                              />
                              <path
                                id="ar-5"
                                className="cls-6-be1"
                                d="m503.56,551.59c-1.34-.39-1.81.43-1.64,1.9h1.64v.99h-1.64v7.22h-1.34v-7.22h-1.17v-.99h1.17c-.3-2.25.73-3.54,2.98-2.94v1.04"
                              />
                              <path
                                id="aC-8"
                                className="cls-6-be1"
                                d="m504.55,551.72v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                              />
                              <path
                                id="aB-5"
                                className="cls-6-be1"
                                d="m511.07,554.4c-1.73,0-2.07,1.43-2.07,3.15s.39,3.24,2.07,3.24c1.04,0,1.77-.6,1.86-1.64l1.38.09c-.26,1.6-1.34,2.64-3.2,2.64-2.55,0-3.28-1.77-3.54-4.28-.43-4.02,4.36-5.66,6.35-2.77.17.3.22.6.3.95l-1.38.13c-.17-.91-.69-1.51-1.77-1.51"
                              />
                              <path
                                id="aA-9"
                                className="cls-6-be1"
                                d="m520.8,560.15c-.65.91-1.34,1.77-2.94,1.73-1.56-.04-2.51-.91-2.51-2.46-.04-2.77,2.72-2.72,5.4-2.72.13-1.51-.39-2.33-1.77-2.33-1.04.04-1.77.3-1.81,1.34l-1.43-.13c.22-1.6,1.43-2.25,3.28-2.25s3.11.86,3.11,2.77v3.54c-.04.86.3,1.38,1.21,1.17v.86c-1.34.39-2.64-.09-2.55-1.51Zm-4.02-.73c0,.86.52,1.43,1.38,1.43,1.77-.13,2.72-1.25,2.59-3.2-1.86.09-3.98-.22-3.98,1.77"
                              />
                              <path
                                id="ay-13"
                                className="cls-6-be1"
                                d="m525.9,559.67c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="aC-9"
                                className="cls-6-be1"
                                d="m528.7,551.72v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                              />
                              <path
                                id="as-8"
                                className="cls-6-be1"
                                d="m535.4,553.32c2.68-.04,3.67,1.6,3.67,4.28.04,2.72-1.17,4.28-3.72,4.28s-3.59-1.69-3.63-4.28c0-2.85,1.21-4.28,3.67-4.28Zm-.04,7.52c1.9.04,2.29-1.34,2.29-3.24s-.35-3.24-2.2-3.24-2.29,1.38-2.29,3.24.43,3.2,2.2,3.24"
                              />
                              <path
                                id="aq-8"
                                className="cls-6-be1"
                                d="m544.78,553.32c3.85-.17,2.29,5.01,2.59,8.38h-1.38v-5.23c0-1.34-.35-2.12-1.69-2.07-3.11.09-1.9,4.41-2.12,7.3h-1.38l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.48-.95,1.25-1.51,2.64-1.56"
                              />
                            </g>
                            <path
                              className="cls-13-be1"
                              d="m482.13,439v62.12"
                            />
                            <path
                              className="cls-8-be1"
                              d="m482.63,439.02h-1v-.5h1v.5Z"
                            />
                            <path
                              className="cls-9-be1"
                              d="m482.13,515.9l-4.64-14.28h9.27l-4.63,14.28Z"
                            />
                            <a href="#link8">
                              <path
                                className="cls-15-be1"
                                d="m476.53,629.18c2.69-2.21,6.56-2.21,9.25,0l63.44,52.36c2.11,1.74,2.41,4.86.67,6.97-.2.25-.43.47-.67.67l-63.44,52.36c-2.69,2.22-6.57,2.22-9.26,0l-63.43-52.36c-2.11-1.74-2.41-4.86-.67-6.97.2-.25.43-.47.67-.67l63.44-52.36Z"
                              />
                            </a>
                            <g id="M">
                              <path
                                id="bQ"
                                className="cls-5-be1"
                                d="m432.58,676.42c0,2.62,1.04,4.3,3.6,4.35,1.83.05,2.67-1.09,3.21-2.32l2.22.84c-.84,2.07-2.52,3.51-5.43,3.51-4.05,0-5.93-2.27-6.17-6.37-.35-5.43,5.38-7.7,9.68-5.28.89.49,1.43,1.43,1.78,2.47l-2.27.59c-.4-1.23-1.48-2.02-3.06-2.02-2.57,0-3.51,1.68-3.56,4.25"
                              />
                              <path
                                id="aL-5"
                                className="cls-5-be1"
                                d="m451.2,680.22c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="aM-2"
                                className="cls-5-be1"
                                d="m458.61,675.18c-1.93-.59-2.96.64-2.96,2.81v4.59h-2.47l-.05-9.38h2.32c.1.64-.05,1.43.15,1.98.3-1.38,1.33-2.62,3.01-2.02v2.02"
                              />
                              <path
                                id="aQ-2"
                                className="cls-5-be1"
                                d="m464.53,682.44c-1.78.69-4.3.35-4.3-2.07v-5.53h-1.19v-1.63h1.33l.74-2.22h1.53v2.22h1.78v1.63h-1.78v4.89c-.05,1.14.79,1.53,1.88,1.23v1.48"
                              />
                              <path
                                id="aO-2"
                                className="cls-5-be1"
                                d="m465.97,671.53v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="bR"
                                className="cls-5-be1"
                                d="m475.65,671.43c-1.33-.35-2.12.25-1.88,1.78h1.88v1.63h-1.88v7.75h-2.42v-7.75h-1.38v-1.63h1.38c-.44-2.91,1.58-4,4.3-3.36v1.58"
                              />
                              <path
                                id="aO-3"
                                className="cls-5-be1"
                                d="m476.78,671.53v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="aP-2"
                                className="cls-5-be1"
                                d="m489.87,679.48c-.35,2.07-1.88,3.31-4.25,3.31-2.91,0-4.15-1.88-4.44-4.84-.59-5.43,7.6-6.77,8.59-1.78l-2.42.1c-.1-.94-.74-1.58-1.73-1.58-1.48,0-1.73,1.38-1.88,3.16-.3,3.65,3.21,4.3,3.65,1.48"
                              />
                              <path
                                id="bc-2"
                                className="cls-5-be1"
                                d="m496.93,680.91c-.79.94-1.48,1.93-3.16,1.88-1.83-.05-2.86-.99-2.86-2.86-.05-2.96,2.72-3.11,5.73-3.01.05-1.28-.15-2.32-1.38-2.32-.89.05-1.28.44-1.38,1.33l-2.57-.1c.35-1.88,1.78-2.86,4.05-2.81s3.65,1.09,3.7,3.36l.05,4.05c-.05.69.59.89,1.23.74v1.33c-1.48.4-3.51.25-3.41-1.58Zm-2.37.15c1.43,0,2.12-1.19,2.07-2.81-1.58,0-3.26-.15-3.21,1.48,0,.84.4,1.33,1.14,1.33"
                              />
                              <path
                                id="aQ-3"
                                className="cls-5-be1"
                                d="m505.92,682.44c-1.78.69-4.3.35-4.3-2.07v-5.53h-1.19v-1.63h1.33l.74-2.22h1.53v2.22h1.78v1.63h-1.78v4.89c-.05,1.14.79,1.53,1.88,1.23v1.48"
                              />
                              <path
                                id="aO-4"
                                className="cls-5-be1"
                                d="m507.35,671.53v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="bS"
                                className="cls-5-be1"
                                d="m516.49,673.01c3.16,0,4.74,1.78,4.74,4.89s-1.73,4.89-4.79,4.89-4.69-1.78-4.69-4.89,1.68-4.89,4.74-4.89Zm-.05,8.1c1.73,0,2.22-1.38,2.22-3.21,0-1.98-.49-3.21-2.12-3.21s-2.22,1.28-2.22,3.21c0,1.78.49,3.21,2.12,3.21"
                              />
                              <path
                                id="bT"
                                className="cls-5-be1"
                                d="m528.53,673.01c4.3-.05,2.86,5.58,3.11,9.58h-2.47c-.35-2.81,1.14-7.75-1.68-7.75s-1.68,4.79-1.93,7.75h-2.47l-.05-9.38h2.32c.1.59-.05,1.38.15,1.88.59-1.28,1.38-2.02,3.01-2.07"
                              />
                            </g>
                            <g id="N">
                              <path
                                id="aK-2"
                                className="cls-5-be1"
                                d="m458.66,703.92h-2.62l-4.54-12.25h2.72l3.16,10.17c.84-3.56,2.07-6.77,3.11-10.17h2.67"
                              />
                              <path
                                id="aL-6"
                                className="cls-5-be1"
                                d="m471.4,701.55c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="aM-3"
                                className="cls-5-be1"
                                d="m478.81,696.51c-1.93-.59-2.96.64-2.96,2.81v4.59h-2.47l-.05-9.38h2.32c.1.64-.05,1.43.15,1.98.3-1.38,1.33-2.62,3.01-2.02v2.02"
                              />
                              <path
                                id="aN-2"
                                className="cls-5-be1"
                                d="m483.4,694.34c1.53-.05,2.27.74,2.86,1.68l-.05-4.99h2.47l.05,12.89h-2.37c-.1-.49,0-1.14-.15-1.53-.54,1.14-1.43,1.73-3.01,1.73-2.57,0-3.36-2.22-3.41-4.89,0-2.77.94-4.79,3.6-4.89Zm.84,8.1c1.63,0,1.98-1.48,2.02-3.26.05-1.83-.44-3.16-2.02-3.16s-1.88,1.48-1.93,3.21c0,2.12.64,3.21,1.93,3.21"
                              />
                              <path
                                id="aO-5"
                                className="cls-5-be1"
                                d="m491.1,692.86v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="aP-3"
                                className="cls-5-be1"
                                d="m504.19,700.81c-.35,2.07-1.88,3.31-4.25,3.31-2.91,0-4.15-1.88-4.44-4.84-.59-5.43,7.6-6.77,8.59-1.78l-2.42.1c-.1-.94-.74-1.58-1.73-1.58-1.48,0-1.73,1.38-1.88,3.16-.3,3.65,3.21,4.3,3.65,1.48"
                              />
                              <path
                                id="aQ-4"
                                className="cls-5-be1"
                                d="m510.36,703.77c-1.78.69-4.3.35-4.3-2.07v-5.53h-1.19v-1.63h1.33l.74-2.22h1.53v2.22h1.78v1.63h-1.78v4.89c-.05,1.14.79,1.53,1.88,1.23v1.48"
                              />
                            </g>
                            <path
                              className="cls-13-be1"
                              d="m482.1,599l-.4,12.14"
                            />
                            <path
                              className="cls-8-be1"
                              d="m482.6,599.04l-1-.04.02-.5h1l-.02.54Z"
                            />
                            <path
                              className="cls-9-be1"
                              d="m481.21,625.9l-4.16-14.42,9.27.3-5.11,14.12Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m90.93,679.34l.07.88.2.84.33.8.45.73.57.64.65.56.74.46.8.33.84.2.88.06h116.23v1h-116.28l-1-.08-.98-.24-.95-.4-.87-.52-.77-.67-.66-.77-.53-.87-.38-.93-.24-1-.08-1v-422h1l-.02,421.98Zm327.96,5.52v1h-102.8v-1h102.8Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m419.39,685.86h-.52v-1h.5l.02,1Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m95.07,256.87h-9.27l4.63-14.27,4.64,14.27Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m95.76,257.37h-10.65l5.32-16.38,5.33,16.38Zm-9.27-1h7.9l-3.96-12.15-3.94,12.15Z"
                            />
                            <g id="O">
                              <path
                                id="bU"
                                className="cls-2-be1"
                                d="m221.32,688.91l-5.33-9.43c.3,2.86.1,6.32.15,9.43h-2.27v-12.25h2.91l5.43,9.53c-.3-2.86-.1-6.37-.15-9.53h2.27v12.25h-3.01"
                              />
                              <path
                                id="bx-2"
                                className="cls-2-be1"
                                d="m230.9,679.33c3.16,0,4.74,1.78,4.74,4.89s-1.73,4.89-4.79,4.89-4.69-1.78-4.69-4.89,1.68-4.89,4.74-4.89Zm-.05,8.1c1.73,0,2.22-1.38,2.22-3.21,0-1.98-.49-3.21-2.12-3.21s-2.22,1.28-2.22,3.21c0,1.78.49,3.21,2.12,3.21"
                              />
                              <path
                                id="bt-3"
                                className="cls-2-be1"
                                d="m241.97,688.76c-1.78.69-4.3.35-4.3-2.07v-5.53h-1.19v-1.63h1.33l.74-2.22h1.53v2.22h1.78v1.63h-1.78v4.89c-.05,1.14.79,1.53,1.88,1.23v1.48"
                              />
                            </g>
                            <g id="P">
                              <path
                                id="bA-2"
                                className="cls-2-be1"
                                d="m256.48,685.8c-.35,2.07-1.88,3.31-4.25,3.31-2.91,0-4.15-1.88-4.44-4.84-.59-5.43,7.6-6.77,8.59-1.78l-2.42.1c-.1-.94-.74-1.58-1.73-1.58-1.48,0-1.73,1.38-1.88,3.16-.3,3.65,3.21,4.3,3.65,1.48"
                              />
                              <path
                                id="bj-6"
                                className="cls-2-be1"
                                d="m266.11,686.54c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="bw-2"
                                className="cls-2-be1"
                                d="m273.52,681.5c-1.93-.59-2.96.64-2.96,2.81v4.59h-2.47l-.05-9.38h2.32c.1.64-.05,1.43.15,1.98.3-1.38,1.33-2.62,3.01-2.02v2.02"
                              />
                              <path
                                id="bt-4"
                                className="cls-2-be1"
                                d="m279.45,688.76c-1.78.69-4.3.35-4.3-2.07v-5.53h-1.19v-1.63h1.33l.74-2.22h1.53v2.22h1.78v1.63h-1.78v4.89c-.05,1.14.79,1.53,1.88,1.23v1.48"
                              />
                              <path
                                id="bl-4"
                                className="cls-2-be1"
                                d="m280.88,677.85v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="bV"
                                className="cls-2-be1"
                                d="m290.56,677.75c-1.33-.35-2.12.25-1.88,1.78h1.88v1.63h-1.88v7.75h-2.42v-7.75h-1.38v-1.63h1.38c-.44-2.91,1.58-4,4.3-3.36v1.58"
                              />
                              <path
                                id="bl-5"
                                className="cls-2-be1"
                                d="m291.69,677.85v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="bj-7"
                                className="cls-2-be1"
                                d="m304.53,686.54c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="bp-3"
                                className="cls-2-be1"
                                d="m309.62,679.33c1.53-.05,2.27.74,2.86,1.68l-.05-4.99h2.47l.05,12.89h-2.37c-.1-.49,0-1.14-.15-1.53-.54,1.14-1.43,1.73-3.01,1.73-2.57,0-3.36-2.22-3.41-4.89,0-2.77.94-4.79,3.6-4.89Zm.84,8.1c1.63,0,1.98-1.48,2.02-3.26.05-1.83-.44-3.16-2.02-3.16s-1.88,1.48-1.93,3.21c0,2.12.64,3.21,1.93,3.21"
                              />
                            </g>
                            <path
                              className="cls-8-be1"
                              d="m687.19,685.86h-135.68v-1h135.68v1Zm296.65-485.8l1,.24.94.4.87.53.8.66.65.76.53.88.4.94.23,1,.1,1v472.9l-.1,1-.23.98-.4.95-.52.88-.66.77-.8.67-.86.53-.94.4-1,.23-1,.08h-116.24v-1h116.2l.88-.07.84-.2.8-.34.72-.46.66-.56.56-.65.45-.75.32-.8.2-.83.07-.88V206.5l-.08-.87-.2-.84-.33-.8-.46-.75-.56-.65-.66-.56-.73-.45-.8-.34-.85-.2-.88-.07h-2.15v-1h2.2l1.03.09Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m551.53,685.86h-.56l.04-.5-.04-.5h.56v1Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m551.55,685.88h-.6l.04-.52-.05-.53h.62v1.05Zm-.55-1l.04.48-.03.47h.5v-.95h-.51Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m980.15,205.12l-14.27-4.63,14.27-4.65v9.28Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m980.65,205.8l-16.38-5.3,16.38-5.34v10.64Zm-13.15-5.3l12.15,3.93v-7.9l-12.15,3.97Z"
                            />
                            <g id="B-2">
                              <path
                                id="bi-3"
                                className="cls-2-be1"
                                d="m699.05,680.32c0,1.98-1.14,3.01-2.67,3.46l3.31,5.14h-2.91l-2.81-4.64h-3.01v4.64h-2.57v-12.25c4.59.2,10.72-1.14,10.67,3.65Zm-8.1,1.98c2.37-.1,5.53.59,5.53-1.88s-3.26-1.58-5.53-1.73v3.6"
                              />
                              <path
                                id="bj-8"
                                className="cls-2-be1"
                                d="m709.13,686.54c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="bk-3"
                                className="cls-2-be1"
                                d="m716.19,688.91h-2.91l-3.36-9.38h2.62l2.22,7.41,2.37-7.41h2.57"
                              />
                              <path
                                id="bl-6"
                                className="cls-2-be1"
                                d="m720.98,677.85v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="bm-4"
                                className="cls-2-be1"
                                d="m731.45,682.1c.05-1.43-3.46-1.68-3.51-.2.74,2.27,5.83.35,5.88,4.25.05,4.1-8.1,3.75-8.49.44l2.12-.35c.2.94.99,1.23,2.17,1.23,1.63.4,2.81-1.48,1.19-2.02-2.12-.69-5.04-.54-5.14-3.26-.1-3.95,7.6-3.65,7.95-.35"
                              />
                              <path
                                id="bj-9"
                                className="cls-2-be1"
                                d="m743.69,686.54c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                            </g>
                            <g id="C-2">
                              <path
                                id="bn-3"
                                className="cls-2-be1"
                                d="m755.94,687.23c-.79.94-1.48,1.93-3.16,1.88-1.83-.05-2.86-.99-2.86-2.86-.05-2.96,2.72-3.11,5.73-3.01.05-1.28-.15-2.32-1.38-2.32-.89.05-1.28.44-1.38,1.33l-2.57-.1c.35-1.88,1.78-2.86,4.05-2.81s3.65,1.09,3.7,3.36l.05,4.05c-.05.69.59.89,1.23.74v1.33c-1.48.4-3.51.25-3.41-1.58Zm-2.37.15c1.43,0,2.12-1.19,2.07-2.81-1.58,0-3.26-.15-3.21,1.48,0,.84.4,1.33,1.14,1.33"
                              />
                              <path
                                id="bo-3"
                                className="cls-2-be1"
                                d="m765.92,679.33c4.3-.05,2.86,5.58,3.11,9.58h-2.47c-.35-2.81,1.14-7.75-1.68-7.75s-1.68,4.79-1.93,7.75h-2.47l-.05-9.38h2.32c.1.59-.05,1.38.15,1.88.59-1.28,1.38-2.02,3.01-2.07"
                              />
                              <path
                                id="bp-4"
                                className="cls-2-be1"
                                d="m774.41,679.33c1.53-.05,2.27.74,2.86,1.68l-.05-4.99h2.47l.05,12.89h-2.37c-.1-.49,0-1.14-.15-1.53-.54,1.14-1.43,1.73-3.01,1.73-2.57,0-3.36-2.22-3.41-4.89,0-2.77.94-4.79,3.6-4.89Zm.84,8.1c1.63,0,1.98-1.48,2.02-3.26.05-1.83-.44-3.16-2.02-3.16s-1.88,1.48-1.93,3.21c0,2.12.64,3.21,1.93,3.21"
                              />
                            </g>
                            <g id="Q">
                              <path
                                id="bi-4"
                                className="cls-2-be1"
                                d="m797.67,680.32c0,1.98-1.14,3.01-2.67,3.46l3.31,5.14h-2.91l-2.81-4.64h-3.01v4.64h-2.57v-12.25c4.59.2,10.72-1.14,10.67,3.65Zm-8.1,1.98c2.37-.1,5.53.59,5.53-1.88s-3.26-1.58-5.53-1.73v3.6"
                              />
                              <path
                                id="bj-10"
                                className="cls-2-be1"
                                d="m807.74,686.54c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="bm-5"
                                className="cls-2-be1"
                                d="m815.25,682.1c.05-1.43-3.46-1.68-3.51-.2.74,2.27,5.83.35,5.88,4.25.05,4.1-8.1,3.75-8.49.44l2.12-.35c.2.94.99,1.23,2.17,1.23,1.63.4,2.81-1.48,1.19-2.02-2.12-.69-5.04-.54-5.14-3.26-.1-3.95,7.6-3.65,7.95-.35"
                              />
                              <path
                                id="bq-2"
                                className="cls-2-be1"
                                d="m822.56,689.11c-4.3.05-2.86-5.58-3.11-9.58h2.47c.35,2.81-1.14,7.41,1.63,7.75,2.96-.25,1.73-4.79,1.98-7.75h2.47l.05,9.38h-2.32c-.1-.59.05-1.38-.15-1.88-.59,1.23-1.38,2.07-3.01,2.07"
                              />
                              <path
                                id="br-2"
                                className="cls-2-be1"
                                d="m835.84,679.33c2.57,0,3.46,2.12,3.46,4.84s-.94,4.89-3.6,4.94c-1.48.05-2.27-.74-2.86-1.73l-.1,1.53h-2.37l.05-12.89h2.47v5.14c.54-1.14,1.43-1.83,2.96-1.83Zm-1.04,8.1c1.53,0,1.98-1.33,1.98-3.26s-.35-3.11-1.93-3.11-2.02,1.38-2.02,3.21.4,3.16,1.98,3.16"
                              />
                              <path
                                id="bs-2"
                                className="cls-2-be1"
                                d="m850.85,681.16c-2.62.44-1.38,4.94-1.68,7.75h-2.42v-5.28c.05-1.33-.25-2.42-1.43-2.47-2.62.49-1.33,4.94-1.63,7.75h-2.47l-.05-9.38h2.32c.1.59-.05,1.38.15,1.88.49-2.62,4.99-2.77,5.33,0,.64-1.09,1.19-2.12,2.91-2.07,4.05.05,2.52,5.73,2.81,9.58h-2.42v-5.28c-.05-1.23-.25-2.37-1.43-2.47"
                              />
                              <path
                                id="bl-7"
                                className="cls-2-be1"
                                d="m857.03,677.85v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="bt-5"
                                className="cls-2-be1"
                                d="m866.41,688.76c-1.78.69-4.3.35-4.3-2.07v-5.53h-1.19v-1.63h1.33l.74-2.22h1.53v2.22h1.78v1.63h-1.78v4.89c-.05,1.14.79,1.53,1.88,1.23v1.48"
                              />
                            </g>
                            <a href="#link9">
                              <path
                                className="cls-12-be1"
                                d="m363.06,805.7c0-3.31,2.69-6,6-6h224.2c3.31,0,6,2.69,6,6v83.33c0,3.31-2.69,6-6,6h-224.2c-3.31,0-6-2.69-6-6v-83.33Z"
                              />
                            </a>
                            <g id="R">
                              <path
                                id="bW"
                                className="cls-6-be1"
                                d="m390.3,842.58v3.98h5.96v1.21h-5.96v4.32h-1.43v-10.72h7.56v1.21h-6.14"
                              />
                              <path
                                id="aC-10"
                                className="cls-6-be1"
                                d="m398.08,842.1v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                              />
                              <path
                                id="aq-9"
                                className="cls-6-be1"
                                d="m405.51,843.7c3.85-.17,2.29,5.01,2.59,8.38h-1.38v-5.23c0-1.34-.35-2.12-1.69-2.07-3.11.09-1.9,4.41-2.12,7.3h-1.38l-.04-8.21h1.3c.04.43-.04,1.04.09,1.38.48-.95,1.25-1.51,2.64-1.56"
                              />
                              <path
                                id="aA-10"
                                className="cls-6-be1"
                                d="m415.19,850.53c-.65.91-1.34,1.77-2.94,1.73-1.56-.04-2.51-.91-2.51-2.46-.04-2.77,2.72-2.72,5.4-2.72.13-1.51-.39-2.33-1.77-2.33-1.04.04-1.77.3-1.81,1.34l-1.43-.13c.22-1.6,1.43-2.25,3.28-2.25s3.11.86,3.11,2.77v3.54c-.04.86.3,1.38,1.21,1.17v.86c-1.34.39-2.64-.09-2.55-1.51Zm-4.02-.73c0,.86.52,1.43,1.38,1.43,1.77-.13,2.72-1.25,2.59-3.2-1.86.09-3.98-.22-3.98,1.77"
                              />
                              <path
                                id="bX"
                                className="cls-6-be1"
                                d="m418.78,852.08v-11.28h1.38v11.28h-1.38"
                              />
                            </g>
                            <g id="S">
                              <path
                                id="ax-6"
                                className="cls-6-be1"
                                d="m431.31,845.9c-.13-1.47-3.72-1.64-3.76,0,.65,2.29,4.97.52,5.14,3.89s-6.48,3.2-6.78.35l1.21-.22c.17,1.56,4.19,1.94,4.23,0-.43-2.42-4.88-.65-5.1-3.89-.17-2.46,3.54-2.72,5.27-1.81.52.3.91.82,1.04,1.51"
                              />
                              <path
                                id="ay-14"
                                className="cls-6-be1"
                                d="m435.8,850.05c-.09,1.04.78,1.25,1.64.95v1.04c-1.43.43-3.02.22-3.02-1.69v-5.49h-.95v-.99h1.04l.39-1.86h.91v1.86h1.51v.99h-1.51v5.19"
                              />
                              <path
                                id="az-13"
                                className="cls-6-be1"
                                d="m441.89,843.7c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                              <path
                                id="aU-2"
                                className="cls-6-be1"
                                d="m451.18,843.7c2.38.04,3.02,1.77,3.02,4.23s-.69,4.23-3.02,4.32c-1.34,0-2.12-.56-2.59-1.47l.04,4.54h-1.38l-.04-11.45h1.34l.09,1.3c.43-.91,1.21-1.47,2.55-1.47Zm-.35,7.52c1.73,0,1.94-1.47,1.94-3.24s-.26-3.15-1.94-3.2c-1.81,0-2.2,1.38-2.2,3.28s.43,3.15,2.2,3.15"
                              />
                              <path
                                id="ax-7"
                                className="cls-6-be1"
                                d="m460.69,845.9c-.13-1.47-3.72-1.64-3.76,0,.65,2.29,4.97.52,5.14,3.89s-6.48,3.2-6.78.35l1.21-.22c.17,1.56,4.19,1.94,4.23,0-.43-2.42-4.88-.65-5.1-3.89-.17-2.46,3.54-2.72,5.27-1.81.52.3.91.82,1.04,1.51"
                              />
                            </g>
                            <g id="T">
                              <path
                                id="bf-2"
                                className="cls-6-be1"
                                d="m471.93,843.7c2.29,0,2.98,1.69,3.02,4.23,0,2.85-.99,4.32-3.02,4.32-1.34-.04-2.12-.48-2.55-1.47l-.09,1.3h-1.34l.04-11.28h1.38v4.36c.43-.99,1.21-1.47,2.55-1.47Zm-.35,7.52c1.73,0,1.94-1.47,1.94-3.24s-.22-3.24-1.94-3.2c-1.81,0-2.2,1.38-2.2,3.28s.43,3.15,2.2,3.15"
                              />
                              <path
                                id="az-14"
                                className="cls-6-be1"
                                d="m479.92,843.7c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                              <path
                                id="ar-6"
                                className="cls-6-be1"
                                d="m488.6,841.97c-1.34-.39-1.81.43-1.64,1.9h1.64v.99h-1.64v7.22h-1.34v-7.22h-1.17v-.99h1.17c-.3-2.25.73-3.54,2.98-2.94v1.04"
                              />
                              <path
                                id="as-9"
                                className="cls-6-be1"
                                d="m492.88,843.7c2.68-.04,3.67,1.6,3.67,4.28.04,2.72-1.17,4.28-3.72,4.28s-3.59-1.69-3.63-4.28c0-2.85,1.21-4.28,3.67-4.28Zm-.04,7.52c1.9.04,2.29-1.34,2.29-3.24s-.35-3.24-2.2-3.24-2.29,1.38-2.29,3.24.43,3.2,2.2,3.24"
                              />
                              <path
                                id="at-9"
                                className="cls-6-be1"
                                d="m502.13,845.04c-3.37-.69-2.29,3.93-2.46,7.04h-1.38l-.04-8.21h1.3c.04.52-.04,1.25.09,1.69.26-1.17.99-2.12,2.51-1.77v1.25"
                              />
                              <path
                                id="az-15"
                                className="cls-6-be1"
                                d="m506.67,843.7c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                            </g>
                            <g id="U">
                              <path
                                id="bY"
                                className="cls-6-be1"
                                d="m517.34,844.74h-1.08l-.17-3.37h1.43"
                              />
                              <path
                                id="bZ"
                                className="cls-6-be1"
                                d="m524.43,852.25c-3.54,0-5.23-2.07-5.4-5.57-.22-4.62,4.32-6.65,8.34-4.8.73.35,1.25,1.08,1.6,1.86l-1.38.39c-.56-1.08-1.6-1.73-3.28-1.73-2.64,0-3.8,1.69-3.8,4.28s1.25,4.32,3.93,4.36c1.51,0,2.68-.48,3.41-1.17v-1.94h-3.2v-1.21h4.54v3.72c-1.08,1.08-2.64,1.81-4.75,1.81"
                              />
                              <path
                                id="as-10"
                                className="cls-6-be1"
                                d="m534.67,843.7c2.68-.04,3.67,1.6,3.67,4.28.04,2.72-1.17,4.28-3.72,4.28s-3.59-1.69-3.63-4.28c0-2.85,1.21-4.28,3.67-4.28Zm-.04,7.52c1.9.04,2.29-1.34,2.29-3.24s-.35-3.24-2.2-3.24-2.29,1.38-2.29,3.24.43,3.2,2.2,3.24"
                              />
                              <path
                                id="ca"
                                className="cls-6-be1"
                                d="m539.68,848.54v-1.21h3.8v1.21h-3.8"
                              />
                              <path
                                id="aF-3"
                                className="cls-6-be1"
                                d="m545.43,852.08v-10.72h1.43v9.55h5.4v1.17h-6.83"
                              />
                              <path
                                id="aC-11"
                                className="cls-6-be1"
                                d="m553.81,842.1v-1.3h1.38v1.3h-1.38Zm0,9.98v-8.21h1.38v8.21h-1.38"
                              />
                              <path
                                id="cb"
                                className="cls-6-be1"
                                d="m560.85,852.08h-1.64l-2.98-8.21h1.47l2.33,7.13,2.42-7.13h1.47"
                              />
                              <path
                                id="az-16"
                                className="cls-6-be1"
                                d="m568.28,843.7c2.72,0,3.72,1.81,3.63,4.58h-5.83c0,1.73.6,2.9,2.29,2.94,1.12.04,1.86-.52,2.12-1.25l1.21.35c-.48,1.21-1.6,1.94-3.33,1.94-2.51,0-3.8-1.6-3.76-4.32.04-2.64,1.12-4.23,3.67-4.23Zm2.25,3.5c.26-2.59-3.28-3.33-4.19-1.21-.13.3-.26.73-.26,1.21h4.45"
                              />
                              <path
                                id="bY-2"
                                className="cls-6-be1"
                                d="m574.64,844.74h-1.08l-.17-3.37h1.43"
                              />
                            </g>
                            <path
                              className="cls-8-be1"
                              d="m481.66,782.82h-1v-8.4h1v8.4Zm0-32.4h-1v-6.72h1v6.72Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m481.16,743.2l.5-.03v.54h-1v-.53l.5.02Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m481.16,743.17l.52-.03v.6h-1.05v-.6l.53.03Zm-.48.52h.95v-.5l-.47.02-.48-.03v.51Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m481.16,797.58l-4.64-14.26h9.26l-4.62,14.26Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m481.16,799.2l-5.32-16.38h10.65l-5.33,16.38Zm-3.95-15.38l3.95,12.14,3.94-12.14h-7.89Z"
                            />
                            <g id="V">
                              <path
                                id="cc"
                                className="cls-4-be1"
                                d="m444.44,759.47c0,2.94,1.17,4.83,4.06,4.89,2.06.06,3-1.22,3.61-2.61l2.5.94c-.94,2.33-2.83,3.94-6.11,3.94-4.56,0-6.67-2.56-6.94-7.17-.39-6.11,6.06-8.67,10.89-5.94,1,.56,1.61,1.61,2,2.78l-2.56.67c-.44-1.39-1.67-2.28-3.44-2.28-2.89,0-3.94,1.89-4,4.78"
                              />
                              <path
                                id="bH-2"
                                className="cls-4-be1"
                                d="m465.39,763.75c-.72,1.67-2.06,2.94-4.56,2.89-3.33-.11-4.94-2.06-4.94-5.56s1.67-5.44,5-5.44,4.61,2.5,4.67,6h-6.78c0,1.72.44,3.06,2.17,3.11,1,0,1.67-.39,1.89-1.22l2.56.22Zm-2.5-3.83c.28-2.56-3.17-3.5-3.89-1.17-.11.33-.22.72-.22,1.17h4.11"
                              />
                              <path
                                id="bE-4"
                                className="cls-4-be1"
                                d="m473.72,758.08c-2.17-.67-3.33.72-3.33,3.17v5.17h-2.78l-.06-10.56h2.61c.11.72-.06,1.61.17,2.22.33-1.56,1.5-2.94,3.39-2.28v2.28"
                              />
                              <path
                                id="bN-2"
                                className="cls-4-be1"
                                d="m480.39,766.25c-2,.78-4.83.39-4.83-2.33v-6.22h-1.33v-1.83h1.5l.83-2.5h1.72v2.5h2v1.83h-2v5.5c-.06,1.28.89,1.72,2.11,1.39v1.67"
                              />
                              <path
                                id="bO-2"
                                className="cls-4-be1"
                                d="m482,753.97v-2.06h2.78v2.06h-2.78Zm0,12.44v-10.56h2.78v10.56h-2.78"
                              />
                              <path
                                id="bJ-2"
                                className="cls-4-be1"
                                d="m492.89,753.86c-1.5-.39-2.39.28-2.11,2h2.11v1.83h-2.11v8.72h-2.72v-8.72h-1.56v-1.83h1.56c-.5-3.28,1.78-4.5,4.83-3.78v1.78"
                              />
                              <path
                                id="bO-3"
                                className="cls-4-be1"
                                d="m494.16,753.97v-2.06h2.78v2.06h-2.78Zm0,12.44v-10.56h2.78v10.56h-2.78"
                              />
                              <path
                                id="bH-3"
                                className="cls-4-be1"
                                d="m508.61,763.75c-.72,1.67-2.06,2.94-4.56,2.89-3.33-.11-4.94-2.06-4.94-5.56s1.67-5.44,5-5.44,4.61,2.5,4.67,6h-6.78c0,1.72.44,3.06,2.17,3.11,1,0,1.67-.39,1.89-1.22l2.56.22Zm-2.5-3.83c.28-2.56-3.17-3.5-3.89-1.17-.11.33-.22.72-.22,1.17h4.11"
                              />
                              <path
                                id="bI-3"
                                className="cls-4-be1"
                                d="m514.33,755.64c1.72-.06,2.56.83,3.22,1.89l-.06-5.61h2.78l.06,14.5h-2.67c-.11-.56,0-1.28-.17-1.72-.61,1.28-1.61,1.94-3.39,1.94-2.89,0-3.78-2.5-3.83-5.5,0-3.11,1.06-5.39,4.06-5.5Zm.94,9.11c1.83,0,2.22-1.67,2.28-3.67.06-2.06-.5-3.56-2.28-3.56s-2.11,1.67-2.17,3.61c0,2.39.72,3.61,2.17,3.61"
                              />
                            </g>
                            <path
                              className="cls-8-be1"
                              d="m481.66,937.52h-1v-7.6h1v7.6Zm0-26.28h-1v-15.2h1v15.2Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m481.66,896.05h-1v-.52h1v.52Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m481.68,896.07h-1.05v-.56h1.05v.56Zm-1-.5v.45h.95v-.46h-.95Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m481.16,952.3l-4.64-14.28h9.26l-4.62,14.28Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m481.16,953.9l-5.32-16.38h10.65l-5.33,16.38Zm-3.95-15.38l3.95,12.15,3.94-12.15h-7.89Z"
                            />
                            <g id="W">
                              <path
                                id="bC-2"
                                className="cls-4-be1"
                                d="m395.43,923.68l-.95-2.72h-4.06l-.95,2.72h-2.25l3.89-10.72h2.64l3.89,10.72h-2.2Zm-1.43-4.41l-1.56-4.67c-.43,1.64-1.04,3.11-1.56,4.67h3.11"
                              />
                              <path
                                id="bD-3"
                                className="cls-4-be1"
                                d="m403.86,915.3c2.29,0,3.02,1.9,3.02,4.23s-.82,4.23-3.15,4.32c-1.34.04-1.94-.73-2.55-1.47.13,1.43.09,2.98.09,4.54h-2.16l-.04-11.45h2.07c.09.43,0,.99.13,1.34.48-1.04,1.25-1.51,2.59-1.51Zm-.91,7.09c1.43,0,1.69-1.34,1.73-2.85,0-1.64-.39-2.77-1.73-2.77-2.42,0-2.38,5.62,0,5.62"
                              />
                              <path
                                id="bD-4"
                                className="cls-4-be1"
                                d="m413.32,915.3c2.29,0,3.02,1.9,3.02,4.23s-.82,4.23-3.15,4.32c-1.34.04-1.94-.73-2.55-1.47.13,1.43.09,2.98.09,4.54h-2.16l-.04-11.45h2.07c.09.43,0,.99.13,1.34.48-1.04,1.25-1.51,2.59-1.51Zm-.91,7.09c1.43,0,1.69-1.34,1.73-2.85,0-1.64-.39-2.77-1.73-2.77-2.42,0-2.38,5.62,0,5.62"
                              />
                              <path
                                id="bE-5"
                                className="cls-4-be1"
                                d="m422.79,917.2c-1.69-.52-2.59.56-2.59,2.46v4.02h-2.16l-.04-8.21h2.03c.09.56-.04,1.25.13,1.73.26-1.21,1.17-2.29,2.64-1.77v1.77"
                              />
                              <path
                                id="bF-5"
                                className="cls-4-be1"
                                d="m427.76,915.3c2.77,0,4.15,1.56,4.15,4.28s-1.51,4.28-4.19,4.28-4.1-1.56-4.1-4.28,1.47-4.28,4.15-4.28Zm-.04,7.09c1.51,0,1.94-1.21,1.94-2.81,0-1.73-.43-2.81-1.86-2.81s-1.94,1.12-1.94,2.81c0,1.56.43,2.81,1.86,2.81"
                              />
                              <path
                                id="bG-2"
                                className="cls-4-be1"
                                d="m438,923.68h-2.55l-2.94-8.21h2.29l1.94,6.48,2.07-6.48h2.25"
                              />
                              <path
                                id="bH-4"
                                className="cls-4-be1"
                                d="m449.1,921.61c-.56,1.3-1.6,2.29-3.54,2.25-2.59-.09-3.85-1.6-3.85-4.32s1.3-4.23,3.89-4.23,3.59,1.94,3.63,4.67h-5.27c0,1.34.35,2.38,1.69,2.42.78,0,1.3-.3,1.47-.95l1.99.17Zm-1.94-2.98c.22-1.99-2.46-2.72-3.02-.91-.09.26-.17.56-.17.91h3.2"
                              />
                              <path
                                id="bI-4"
                                className="cls-4-be1"
                                d="m453.55,915.3c1.34-.04,1.99.65,2.51,1.47l-.04-4.36h2.16l.04,11.28h-2.07c-.09-.43,0-.99-.13-1.34-.48.99-1.25,1.51-2.64,1.51-2.25,0-2.94-1.94-2.98-4.28,0-2.42.82-4.19,3.15-4.28Zm.73,7.09c1.43,0,1.73-1.3,1.77-2.85.04-1.6-.39-2.77-1.77-2.77s-1.64,1.3-1.69,2.81c0,1.86.56,2.81,1.69,2.81"
                              />
                            </g>
                            <g id="X">
                              <path
                                id="bJ-3"
                                className="cls-4-be1"
                                d="m468.76,913.92c-1.17-.3-1.86.22-1.64,1.56h1.64v1.43h-1.64v6.78h-2.12v-6.78h-1.21v-1.43h1.21c-.39-2.55,1.38-3.5,3.76-2.94v1.38"
                              />
                              <path
                                id="bF-6"
                                className="cls-4-be1"
                                d="m473.43,915.3c2.77,0,4.15,1.56,4.15,4.28s-1.51,4.28-4.19,4.28-4.1-1.56-4.1-4.28,1.47-4.28,4.15-4.28Zm-.04,7.09c1.51,0,1.94-1.21,1.94-2.81,0-1.73-.43-2.81-1.86-2.81s-1.94,1.12-1.94,2.81c0,1.56.43,2.81,1.86,2.81"
                              />
                              <path
                                id="bE-6"
                                className="cls-4-be1"
                                d="m483.97,917.2c-1.69-.52-2.59.56-2.59,2.46v4.02h-2.16l-.04-8.21h2.03c.09.56-.04,1.25.13,1.73.26-1.21,1.17-2.29,2.64-1.77v1.77"
                              />
                            </g>
                            <g id="Y">
                              <path
                                id="cd"
                                className="cls-4-be1"
                                d="m489.55,912.97c5.19-.3,9.64.22,9.55,5.27-.04,3.46-1.9,5.44-5.23,5.44h-4.32v-10.72Zm2.25,8.99c3.2.3,5.06-.78,5.06-3.72s-1.94-3.8-5.06-3.54v7.26"
                              />
                              <path
                                id="bO-4"
                                className="cls-4-be1"
                                d="m500.78,914.01v-1.6h2.16v1.6h-2.16Zm0,9.68v-8.21h2.16v8.21h-2.16"
                              />
                              <path
                                id="ce"
                                className="cls-4-be1"
                                d="m509.94,917.72c.04-1.25-3.02-1.47-3.07-.17.65,1.99,5.1.3,5.14,3.72.04,3.59-7.09,3.28-7.43.39l1.86-.3c.17.82.86,1.08,1.9,1.08,1.43.35,2.46-1.3,1.04-1.77-1.86-.6-4.41-.48-4.49-2.85-.09-3.46,6.65-3.2,6.96-.3"
                              />
                              <path
                                id="bN-3"
                                className="cls-4-be1"
                                d="m517.63,923.55c-1.56.6-3.76.3-3.76-1.81v-4.84h-1.04v-1.43h1.17l.65-1.94h1.34v1.94h1.56v1.43h-1.56v4.28c-.04.99.69,1.34,1.64,1.08v1.3"
                              />
                              <path
                                id="bE-7"
                                className="cls-4-be1"
                                d="m523.64,917.2c-1.69-.52-2.59.56-2.59,2.46v4.02h-2.16l-.04-8.21h2.03c.09.56-.04,1.25.13,1.73.26-1.21,1.17-2.29,2.64-1.77v1.77"
                              />
                              <path
                                id="bO-5"
                                className="cls-4-be1"
                                d="m524.93,914.01v-1.6h2.16v1.6h-2.16Zm0,9.68v-8.21h2.16v8.21h-2.16"
                              />
                              <path
                                id="cf"
                                className="cls-4-be1"
                                d="m534.01,915.3c2.25,0,3.02,1.86,3.02,4.23s-.82,4.28-3.15,4.32c-1.3.04-1.99-.65-2.51-1.51l-.09,1.34h-2.07l.04-11.28h2.16v4.49c.48-.99,1.25-1.6,2.59-1.6Zm-.91,7.09c1.34,0,1.73-1.17,1.73-2.85s-.3-2.72-1.69-2.72-1.77,1.21-1.77,2.81.35,2.77,1.73,2.77"
                              />
                              <path
                                id="bL-2"
                                className="cls-4-be1"
                                d="m541.31,923.86c-3.76.04-2.51-4.88-2.72-8.38h2.16c.3,2.46-.99,6.48,1.43,6.78,2.59-.22,1.51-4.19,1.73-6.78h2.16l.04,8.21h-2.03c-.09-.52.04-1.21-.13-1.64-.52,1.08-1.21,1.81-2.64,1.81"
                              />
                              <path
                                id="bN-4"
                                className="cls-4-be1"
                                d="m552.07,923.55c-1.56.6-3.76.3-3.76-1.81v-4.84h-1.04v-1.43h1.17l.65-1.94h1.34v1.94h1.56v1.43h-1.56v4.28c-.04.99.69,1.34,1.64,1.08v1.3"
                              />
                              <path
                                id="bO-6"
                                className="cls-4-be1"
                                d="m553.32,914.01v-1.6h2.16v1.6h-2.16Zm0,9.68v-8.21h2.16v8.21h-2.16"
                              />
                              <path
                                id="bF-7"
                                className="cls-4-be1"
                                d="m561.32,915.3c2.77,0,4.15,1.56,4.15,4.28s-1.51,4.28-4.19,4.28-4.1-1.56-4.1-4.28,1.47-4.28,4.15-4.28Zm-.04,7.09c1.51,0,1.94-1.21,1.94-2.81,0-1.73-.43-2.81-1.86-2.81s-1.94,1.12-1.94,2.81c0,1.56.43,2.81,1.86,2.81"
                              />
                              <path
                                id="bP-2"
                                className="cls-4-be1"
                                d="m571.86,915.3c3.76-.04,2.51,4.88,2.72,8.38h-2.16c-.3-2.46.99-6.78-1.47-6.78s-1.47,4.19-1.69,6.78h-2.16l-.04-8.21h2.03c.09.52-.04,1.21.13,1.64.52-1.12,1.21-1.77,2.64-1.81"
                              />
                            </g>
                            <a href="#link11">
                              <path
                                className="cls-12-be1"
                                d="m366.63,1102.5c0-3.31,2.69-6,6-6h224.2c3.31,0,6,2.69,6,6v74.16c0,3.31-2.69,6-6,6h-224.2c-3.31,0-6-2.69-6-6v-74.16Z"
                              />
                            </a>
                            <g id="Z">
                              <path
                                id="cg"
                                className="cls-2-be1"
                                d="m382.35,1123.47c-.26-2.49-6.38-2.8-6.43.1.73,3.89,8.19.73,8.45,6.17.16,4.04-6.27,4.51-9.07,2.85-.88-.52-1.45-1.35-1.71-2.39l1.71-.36c.26,2.9,7.31,3.27,7.31-.05,0-4.04-8.04-.73-8.4-6.12-.26-4.25,7.52-4.36,9.28-1.76.26.36.41.83.57,1.3"
                              />
                              <path
                                id="ch"
                                className="cls-2-be1"
                                d="m390.18,1124.5c-2.07,0-2.49,1.71-2.49,3.79s.47,3.89,2.49,3.89c1.24,0,2.13-.73,2.23-1.97l1.66.1c-.31,1.92-1.61,3.16-3.84,3.16-3.06,0-3.94-2.13-4.25-5.13-.52-4.82,5.24-6.79,7.62-3.32.21.36.26.73.36,1.14l-1.66.16c-.21-1.09-.83-1.81-2.13-1.81"
                              />
                              <path
                                id="ci"
                                className="cls-2-be1"
                                d="m400.03,1124.5c-3.73,0-2.28,5.29-2.54,8.76h-1.66v-13.53h1.66l-.05,5.34c.62-1.09,1.45-1.87,3.16-1.87,4.61,0,2.75,6.01,3.11,10.06h-1.66v-6.27c.1-1.66-.41-2.54-2.02-2.49"
                              />

                              <path
                                id="cj"
                                className="cls-2-be1"
                                d="m410.09,1123.21c3.27,0,4.46,2.18,4.36,5.5h-7c0,2.07.73,3.47,2.75,3.53,1.35.05,2.23-.62,2.54-1.5l1.45.41c-.57,1.45-1.92,2.33-3.99,2.33-3.01,0-4.56-1.92-4.51-5.19.05-3.16,1.35-5.08,4.41-5.08Zm2.7,4.2c.31-3.11-3.94-3.99-5.03-1.45-.16.36-.31.88-.31,1.45h5.34"
                              />
                              <path
                                id="ck"
                                className="cls-2-be1"
                                d="m419.68,1123.21c1.61,0,2.49.67,3.11,1.71l-.05-5.19h1.66l.05,13.53h-1.56c-.1-.52,0-1.19-.16-1.61-.52,1.19-1.45,1.81-3.06,1.81-2.75,0-3.58-2.02-3.63-5.08,0-3.42,1.19-5.19,3.63-5.19Zm.47,1.24c-2.07,0-2.39,1.76-2.39,3.89s.31,3.84,2.33,3.84c2.18,0,2.64-1.66,2.64-3.94s-.47-3.84-2.59-3.79"
                              />
                              <path
                                id="cl"
                                className="cls-2-be1"
                                d="m430,1133.47c-4.61.21-2.8-6.01-3.16-10.06h1.66v6.22c0,1.61.36,2.59,2.02,2.54,3.73-.1,2.33-5.24,2.59-8.76h1.61l.05,9.85h-1.56c-.05-.52.05-1.3-.1-1.71-.57,1.14-1.45,1.87-3.11,1.92"
                              />
                              <path
                                id="cm"
                                className="cls-2-be1"
                                d="m437.26,1133.27v-13.53h1.66v13.53h-1.66"
                              />
                              <path
                                id="cj-2"
                                className="cls-2-be1"
                                d="m445.3,1123.21c3.27,0,4.46,2.18,4.36,5.5h-7c0,2.07.73,3.47,2.75,3.53,1.35.05,2.23-.62,2.54-1.5l1.45.41c-.57,1.45-1.92,2.33-3.99,2.33-3.01,0-4.56-1.92-4.51-5.19.05-3.16,1.35-5.08,4.41-5.08Zm2.7,4.2c.31-3.11-3.94-3.99-5.03-1.45-.16.36-.31.88-.31,1.45h5.34"
                              />
                              <path
                                id="ck-2"
                                className="cls-2-be1"
                                d="m454.89,1123.21c1.61,0,2.49.67,3.11,1.71l-.05-5.19h1.66l.05,13.53h-1.56c-.1-.52,0-1.19-.16-1.61-.52,1.19-1.45,1.81-3.06,1.81-2.75,0-3.58-2.02-3.63-5.08,0-3.42,1.19-5.19,3.63-5.19Zm.47,1.24c-2.07,0-2.39,1.76-2.39,3.89s.31,3.84,2.33,3.84c2.18,0,2.64-1.66,2.64-3.94s-.47-3.84-2.59-3.79"
                              />
                            </g>
                            <g id="aa">
                              <path
                                id="cn"
                                className="cls-2-be1"
                                d="m472,1123.21c2.75,0,3.58,2.02,3.63,5.08,0,3.42-1.19,5.19-3.63,5.19-1.61-.05-2.54-.57-3.06-1.76l-.1,1.56h-1.61l.05-13.53h1.66v5.24c.52-1.19,1.45-1.76,3.06-1.76Zm-.41,9.02c2.07,0,2.33-1.76,2.33-3.89s-.26-3.89-2.33-3.84c-2.18,0-2.64,1.66-2.64,3.94s.52,3.79,2.64,3.79"
                              />
                              <path
                                id="co"
                                className="cls-2-be1"
                                d="m482.32,1124.81c-4.04-.83-2.75,4.72-2.96,8.45h-1.66l-.05-9.85h1.56c.05.62-.05,1.5.1,2.02.31-1.4,1.19-2.54,3.01-2.13v1.5"
                              />
                              <path
                                id="cp"
                                className="cls-2-be1"
                                d="m487.76,1123.21c3.21-.05,4.41,1.92,4.41,5.13.05,3.27-1.4,5.13-4.46,5.13s-4.3-2.02-4.36-5.13c0-3.42,1.45-5.13,4.41-5.13Zm-.05,9.02c2.28.05,2.75-1.61,2.75-3.89s-.41-3.89-2.64-3.89-2.75,1.66-2.75,3.89.52,3.84,2.64,3.89"
                              />
                              <path
                                id="cq"
                                className="cls-2-be1"
                                d="m500.26,1131.4c-.78,1.09-1.61,2.13-3.53,2.07-1.87-.05-3.01-1.09-3.01-2.96-.05-3.32,3.27-3.27,6.48-3.27.16-1.81-.47-2.8-2.13-2.8-1.24.05-2.13.36-2.18,1.61l-1.71-.16c.26-1.92,1.71-2.7,3.94-2.7s3.73,1.04,3.73,3.32v4.25c-.05,1.04.36,1.66,1.45,1.4v1.04c-1.61.47-3.16-.1-3.06-1.81Zm-4.82-.88c0,1.04.62,1.71,1.66,1.71,2.13-.16,3.27-1.5,3.11-3.84-2.23.1-4.77-.26-4.77,2.13"
                              />
                              <path
                                id="ck-3"
                                className="cls-2-be1"
                                d="m507.72,1123.21c1.61,0,2.49.67,3.11,1.71l-.05-5.19h1.66l.05,13.53h-1.56c-.1-.52,0-1.19-.16-1.61-.52,1.19-1.45,1.81-3.06,1.81-2.75,0-3.58-2.02-3.63-5.08,0-3.42,1.19-5.19,3.63-5.19Zm.47,1.24c-2.07,0-2.39,1.76-2.39,3.89s.31,3.84,2.33,3.84c2.18,0,2.64-1.66,2.64-3.94s-.47-3.84-2.59-3.79"
                              />
                              <path
                                id="ch-2"
                                className="cls-2-be1"
                                d="m518.67,1124.5c-2.07,0-2.49,1.71-2.49,3.79s.47,3.89,2.49,3.89c1.24,0,2.13-.73,2.23-1.97l1.66.1c-.31,1.92-1.61,3.16-3.84,3.16-3.06,0-3.94-2.13-4.25-5.13-.52-4.82,5.24-6.79,7.62-3.32.21.36.26.73.36,1.14l-1.66.16c-.21-1.09-.83-1.81-2.13-1.81"
                              />
                              <path
                                id="cq-2"
                                className="cls-2-be1"
                                d="m530.33,1131.4c-.78,1.09-1.61,2.13-3.53,2.07-1.87-.05-3.01-1.09-3.01-2.96-.05-3.32,3.27-3.27,6.48-3.27.16-1.81-.47-2.8-2.13-2.8-1.24.05-2.13.36-2.18,1.61l-1.71-.16c.26-1.92,1.71-2.7,3.94-2.7s3.73,1.04,3.73,3.32v4.25c-.05,1.04.36,1.66,1.45,1.4v1.04c-1.61.47-3.16-.1-3.06-1.81Zm-4.82-.88c0,1.04.62,1.71,1.66,1.71,2.13-.16,3.27-1.5,3.11-3.84-2.23.1-4.77-.26-4.77,2.13"
                              />
                              <path
                                id="cr"
                                className="cls-2-be1"
                                d="m540.39,1125.85c-.16-1.76-4.46-1.97-4.51,0,.78,2.75,5.96.62,6.17,4.67s-7.78,3.84-8.14.41l1.45-.26c.21,1.87,5.03,2.33,5.08,0-.52-2.9-5.86-.78-6.12-4.67-.21-2.96,4.25-3.27,6.33-2.18.62.36,1.09.99,1.24,1.81"
                              />
                              <path
                                id="cs"
                                className="cls-2-be1"
                                d="m545.78,1130.83c-.1,1.24.93,1.5,1.97,1.14v1.24c-1.71.52-3.63.26-3.63-2.02v-6.59h-1.14v-1.19h1.24l.47-2.23h1.09v2.23h1.81v1.19h-1.81v6.22"
                              />
                            </g>
                            <g id="ab">
                              <path
                                id="cj-3"
                                className="cls-2-be1"
                                d="m558.28,1123.21c3.27,0,4.46,2.18,4.36,5.5h-7c0,2.07.73,3.47,2.75,3.53,1.35.05,2.23-.62,2.54-1.5l1.45.41c-.57,1.45-1.92,2.33-3.99,2.33-3.01,0-4.56-1.92-4.51-5.19.05-3.16,1.35-5.08,4.41-5.08Zm2.7,4.2c.31-3.11-3.94-3.99-5.03-1.45-.16.36-.31.88-.31,1.45h5.34"
                              />
                              <path
                                id="ct"
                                className="cls-2-be1"
                                d="m574.35,1124.5c-3.47.16-1.97,5.44-2.28,8.76h-1.61v-6.27c0-1.5-.26-2.59-1.81-2.49-3.42.21-1.97,5.39-2.28,8.76h-1.61l-.05-9.85h1.56c.05.52-.05,1.24.1,1.66.52-2.28,5.13-2.59,5.55,0,.57-1.09,1.4-1.81,3.01-1.87,4.41-.1,2.44,6.17,2.85,10.06h-1.61v-6.27c0-1.5-.26-2.54-1.81-2.49"
                              />
                              <path
                                id="cq-3"
                                className="cls-2-be1"
                                d="m586.28,1131.4c-.78,1.09-1.61,2.13-3.53,2.07-1.87-.05-3.01-1.09-3.01-2.96-.05-3.32,3.27-3.27,6.48-3.27.16-1.81-.47-2.8-2.13-2.8-1.24.05-2.13.36-2.18,1.61l-1.71-.16c.26-1.92,1.71-2.7,3.94-2.7s3.73,1.04,3.73,3.32v4.25c-.05,1.04.36,1.66,1.45,1.4v1.04c-1.61.47-3.16-.1-3.06-1.81Zm-4.82-.88c0,1.04.62,1.71,1.66,1.71,2.13-.16,3.27-1.5,3.11-3.84-2.23.1-4.77-.26-4.77,2.13"
                              />
                              <path
                                id="cu"
                                className="cls-2-be1"
                                d="m590.58,1121.29v-1.56h1.66v1.56h-1.66Zm0,11.98v-9.85h1.66v9.85h-1.66"
                              />
                              <path
                                id="cm-2"
                                className="cls-2-be1"
                                d="m594.68,1133.27v-13.53h1.66v13.53h-1.66"
                              />
                            </g>
                            <g id="ac">
                              <path
                                id="cu-2"
                                className="cls-2-be1"
                                d="m443.9,1141.26v-1.56h1.66v1.56h-1.66Zm0,11.98v-9.85h1.66v9.85h-1.66"
                              />
                              <path
                                id="cr-2"
                                className="cls-2-be1"
                                d="m453.75,1145.82c-.16-1.76-4.46-1.97-4.51,0,.78,2.75,5.96.62,6.17,4.67s-7.78,3.84-8.14.41l1.45-.26c.21,1.87,5.03,2.33,5.08,0-.52-2.9-5.86-.78-6.12-4.67-.21-2.96,4.25-3.27,6.33-2.18.62.36,1.09.99,1.24,1.81"
                              />
                            </g>
                            <g id="ad">
                              <path
                                id="cr-3"
                                className="cls-2-be1"
                                d="m468.27,1145.82c-.16-1.76-4.46-1.97-4.51,0,.78,2.75,5.96.62,6.17,4.67s-7.78,3.84-8.14.41l1.45-.26c.21,1.87,5.03,2.33,5.08,0-.52-2.9-5.86-.78-6.12-4.67-.21-2.96,4.25-3.27,6.33-2.18.62.36,1.09.99,1.24,1.81"
                              />
                              <path
                                id="cj-4"
                                className="cls-2-be1"
                                d="m475.78,1143.18c3.27,0,4.46,2.18,4.36,5.5h-7c0,2.07.73,3.47,2.75,3.53,1.35.05,2.23-.62,2.54-1.5l1.45.41c-.57,1.45-1.92,2.33-3.99,2.33-3.01,0-4.56-1.92-4.51-5.19.05-3.16,1.35-5.08,4.41-5.08Zm2.7,4.2c.31-3.11-3.94-3.99-5.03-1.45-.16.36-.31.88-.31,1.45h5.34"
                              />
                              <path
                                id="cv"
                                className="cls-2-be1"
                                d="m487.04,1143.18c4.61-.21,2.75,6.01,3.11,10.06h-1.66v-6.27c0-1.61-.41-2.54-2.02-2.49-3.73.1-2.28,5.29-2.54,8.76h-1.66l-.05-9.85h1.56c.05.52-.05,1.24.1,1.66.57-1.14,1.5-1.81,3.16-1.87"
                              />
                              <path
                                id="cs-2"
                                className="cls-2-be1"
                                d="m494.4,1150.8c-.1,1.24.93,1.5,1.97,1.14v1.24c-1.71.52-3.63.26-3.63-2.02v-6.59h-1.14v-1.19h1.24l.47-2.23h1.09v2.23h1.81v1.19h-1.81v6.22"
                              />
                            </g>
                            <g id="ae">
                              <path
                                id="cp-2"
                                className="cls-2-be1"
                                d="m506.9,1143.18c3.21-.05,4.41,1.92,4.41,5.13.05,3.27-1.4,5.13-4.46,5.13s-4.3-2.02-4.36-5.13c0-3.42,1.45-5.13,4.41-5.13Zm-.05,9.02c2.28.05,2.75-1.61,2.75-3.89s-.41-3.89-2.64-3.89-2.75,1.66-2.75,3.89.52,3.84,2.64,3.89"
                              />
                              <path
                                id="cl-2"
                                className="cls-2-be1"
                                d="m516.44,1153.45c-4.61.21-2.8-6.01-3.16-10.06h1.66v6.22c0,1.61.36,2.59,2.02,2.54,3.73-.1,2.33-5.24,2.59-8.76h1.61l.05,9.85h-1.56c-.05-.52.05-1.3-.1-1.71-.57,1.14-1.45,1.87-3.11,1.92"
                              />
                              <path
                                id="cs-3"
                                className="cls-2-be1"
                                d="m525.51,1150.8c-.1,1.24.93,1.5,1.97,1.14v1.24c-1.71.52-3.63.26-3.63-2.02v-6.59h-1.14v-1.19h1.24l.47-2.23h1.09v2.23h1.81v1.19h-1.81v6.22"
                              />
                            </g>
                            <a href="#link10">
                              <path
                                className="cls-12-be1"
                                d="m358.81,961.6c0-3.31,2.69-6,6-6h224.2c3.31,0,6,2.69,6,6v82.6c0,3.31-2.69,6-6,6h-224.2c-3.31,0-6-2.69-6-6v-82.6Z"
                              />
                            </a>
                            <g id="af">
                              <path
                                id="cw"
                                className="cls-2-be1"
                                d="m380.49,997.66l-1.28-3.29h-5.16l-1.28,3.29h-1.6l4.61-11.33h1.74l4.52,11.33h-1.55Zm-1.74-4.52l-2.15-5.62c-.55,2.06-1.42,3.75-2.1,5.62h4.25"
                              />
                              <path
                                id="ck-4"
                                className="cls-2-be1"
                                d="m385.97,988.8c1.42,0,2.19.59,2.74,1.51l-.05-4.57h1.46l.05,11.92h-1.37c-.09-.46,0-1.05-.14-1.42-.46,1.05-1.28,1.6-2.7,1.6-2.42,0-3.15-1.78-3.2-4.48,0-3.01,1.05-4.57,3.2-4.57Zm.41,1.1c-1.83,0-2.1,1.55-2.1,3.43s.27,3.38,2.06,3.38c1.92,0,2.33-1.46,2.33-3.47s-.41-3.38-2.28-3.33"
                              />
                              <path
                                id="cx"
                                className="cls-2-be1"
                                d="m396.16,997.66h-1.74l-3.15-8.68h1.55l2.47,7.54,2.56-7.54h1.55"
                              />
                              <path
                                id="cu-3"
                                className="cls-2-be1"
                                d="m400.54,987.11v-1.37h1.46v1.37h-1.46Zm0,10.55v-8.68h1.46v8.68h-1.46"
                              />
                              <path
                                id="cr-4"
                                className="cls-2-be1"
                                d="m409.22,991.13c-.14-1.55-3.93-1.74-3.97,0,.69,2.42,5.25.55,5.44,4.11s-6.85,3.38-7.17.37l1.28-.23c.18,1.64,4.43,2.06,4.48,0-.46-2.56-5.16-.69-5.39-4.11-.18-2.6,3.75-2.88,5.57-1.92.55.32.96.87,1.1,1.6"
                              />
                              <path
                                id="cj-5"
                                className="cls-2-be1"
                                d="m415.84,988.8c2.88,0,3.93,1.92,3.84,4.84h-6.17c0,1.83.64,3.06,2.42,3.11,1.19.05,1.96-.55,2.24-1.32l1.28.37c-.5,1.28-1.69,2.06-3.52,2.06-2.65,0-4.02-1.69-3.97-4.57.05-2.79,1.19-4.48,3.88-4.48Zm2.38,3.7c.27-2.74-3.47-3.52-4.43-1.28-.14.32-.27.78-.27,1.28h4.7"
                              />
                            </g>
                            <g id="ag">
                              <path
                                id="cy"
                                className="cls-2-be1"
                                d="m426.35,986.33c5.39-.32,9.87.37,9.73,5.57-.14,3.56-1.96,5.76-5.53,5.76h-4.2v-11.33Zm1.51,10.1c4.07.37,6.67-.73,6.67-4.52s-2.74-4.61-6.67-4.34v8.86"
                              />
                              <path
                                id="cg-2"
                                className="cls-2-be1"
                                d="m445.26,989.03c-.23-2.19-5.62-2.47-5.66.09.64,3.43,7.22.64,7.45,5.44.14,3.56-5.53,3.97-7.99,2.51-.78-.46-1.28-1.19-1.51-2.1l1.51-.32c.23,2.56,6.44,2.88,6.44-.05,0-3.56-7.08-.64-7.4-5.39-.23-3.75,6.62-3.84,8.18-1.55.23.32.37.73.5,1.14"
                              />
                              <path
                                id="cz"
                                className="cls-2-be1"
                                d="m453.58,987.61v10.05h-1.55v-10.05h-3.88v-1.28h9.32v1.28h-3.88"
                              />
                            </g>
                            <g id="ah">
                              <path
                                id="cs-4"
                                className="cls-2-be1"
                                d="m464.72,995.52c-.09,1.1.82,1.32,1.74,1v1.1c-1.51.46-3.2.23-3.2-1.78v-5.8h-1v-1.05h1.1l.41-1.96h.96v1.96h1.6v1.05h-1.6v5.48"
                              />
                              <path
                                id="cp-3"
                                className="cls-2-be1"
                                d="m471.16,988.8c2.83-.05,3.88,1.69,3.88,4.52.05,2.88-1.23,4.52-3.93,4.52s-3.79-1.78-3.84-4.52c0-3.01,1.28-4.52,3.88-4.52Zm-.05,7.95c2.01.05,2.42-1.42,2.42-3.43s-.37-3.43-2.33-3.43-2.42,1.46-2.42,3.43.46,3.38,2.33,3.43"
                              />
                            </g>
                            <g id="ai">
                              <path
                                id="cA"
                                className="cls-2-be1"
                                d="m485.55,988.8c2.51.05,3.2,1.87,3.2,4.48s-.73,4.48-3.2,4.57c-1.42,0-2.24-.59-2.74-1.55l.05,4.8h-1.46l-.05-12.1h1.42l.09,1.37c.46-.96,1.28-1.55,2.7-1.55Zm-.37,7.95c1.83,0,2.06-1.55,2.06-3.43s-.27-3.33-2.06-3.38c-1.92,0-2.33,1.46-2.33,3.47s.46,3.33,2.33,3.33"
                              />
                              <path
                                id="cl-3"
                                className="cls-2-be1"
                                d="m493.27,997.84c-4.07.18-2.47-5.3-2.79-8.86h1.46v5.48c0,1.42.32,2.28,1.78,2.24,3.29-.09,2.06-4.61,2.28-7.72h1.42l.05,8.68h-1.37c-.05-.46.05-1.14-.09-1.51-.5,1-1.28,1.64-2.74,1.69"
                              />
                              <path
                                id="cr-5"
                                className="cls-2-be1"
                                d="m504.74,991.13c-.14-1.55-3.93-1.74-3.97,0,.69,2.42,5.25.55,5.44,4.11s-6.85,3.38-7.17.37l1.28-.23c.18,1.64,4.43,2.06,4.48,0-.46-2.56-5.16-.69-5.39-4.11-.18-2.6,3.75-2.88,5.57-1.92.55.32.96.87,1.1,1.6"
                              />
                              <path
                                id="ci-2"
                                className="cls-2-be1"
                                d="m511.63,989.94c-3.29,0-2.01,4.66-2.24,7.72h-1.46v-11.92h1.46l-.05,4.7c.55-.96,1.28-1.64,2.79-1.64,4.07,0,2.42,5.3,2.74,8.86h-1.46v-5.53c.09-1.46-.37-2.24-1.78-2.19"
                              />
                            </g>
                            <g id="aj">
                              <path
                                id="cq-4"
                                className="cls-2-be1"
                                d="m526.94,996.02c-.69.96-1.42,1.87-3.11,1.83-1.64-.05-2.65-.96-2.65-2.6-.05-2.92,2.88-2.88,5.71-2.88.14-1.6-.41-2.47-1.87-2.47-1.1.05-1.87.32-1.92,1.42l-1.51-.14c.23-1.69,1.51-2.38,3.47-2.38s3.29.91,3.29,2.92v3.75c-.05.91.32,1.46,1.28,1.23v.91c-1.42.41-2.79-.09-2.7-1.6Zm-4.25-.78c0,.91.55,1.51,1.46,1.51,1.87-.14,2.88-1.32,2.74-3.38-1.96.09-4.2-.23-4.2,1.87"
                              />
                              <path
                                id="cr-6"
                                className="cls-2-be1"
                                d="m535.8,991.13c-.14-1.55-3.93-1.74-3.97,0,.69,2.42,5.25.55,5.44,4.11s-6.85,3.38-7.17.37l1.28-.23c.18,1.64,4.43,2.06,4.48,0-.46-2.56-5.16-.69-5.39-4.11-.18-2.6,3.75-2.88,5.57-1.92.55.32.96.87,1.1,1.6"
                              />
                              <path
                                id="cr-7"
                                className="cls-2-be1"
                                d="m544.02,991.13c-.14-1.55-3.93-1.74-3.97,0,.69,2.42,5.25.55,5.44,4.11s-6.85,3.38-7.17.37l1.28-.23c.18,1.64,4.43,2.06,4.48,0-.46-2.56-5.16-.69-5.39-4.11-.18-2.6,3.75-2.88,5.57-1.92.55.32.96.87,1.1,1.6"
                              />
                              <path
                                id="cj-6"
                                className="cls-2-be1"
                                d="m550.64,988.8c2.88,0,3.93,1.92,3.84,4.84h-6.17c0,1.83.64,3.06,2.42,3.11,1.19.05,1.96-.55,2.24-1.32l1.28.37c-.5,1.28-1.69,2.06-3.52,2.06-2.65,0-4.02-1.69-3.97-4.57.05-2.79,1.19-4.48,3.88-4.48Zm2.38,3.7c.27-2.74-3.47-3.52-4.43-1.28-.14.32-.27.78-.27,1.28h4.7"
                              />
                              <path
                                id="cs-5"
                                className="cls-2-be1"
                                d="m557.91,995.52c-.09,1.1.82,1.32,1.74,1v1.1c-1.51.46-3.2.23-3.2-1.78v-5.8h-1v-1.05h1.1l.41-1.96h.96v1.96h1.6v1.05h-1.6v5.48"
                              />
                            </g>
                            <g id="ak">
                              <path
                                id="cB"
                                className="cls-2-be1"
                                d="m568.96,986.97c-1.42-.41-1.92.46-1.74,2.01h1.74v1.05h-1.74v7.63h-1.42v-7.63h-1.23v-1.05h1.23c-.32-2.38.78-3.75,3.15-3.11v1.1"
                              />
                              <path
                                id="cp-4"
                                className="cls-2-be1"
                                d="m573.48,988.8c2.83-.05,3.88,1.69,3.88,4.52.05,2.88-1.23,4.52-3.93,4.52s-3.79-1.78-3.84-4.52c0-3.01,1.28-4.52,3.88-4.52Zm-.05,7.95c2.01.05,2.42-1.42,2.42-3.43s-.37-3.43-2.33-3.43-2.42,1.46-2.42,3.43.46,3.38,2.33,3.43"
                              />
                              <path
                                id="co-2"
                                className="cls-2-be1"
                                d="m583.26,990.22c-3.56-.73-2.42,4.16-2.6,7.45h-1.46l-.05-8.68h1.37c.05.55-.05,1.32.09,1.78.27-1.23,1.05-2.24,2.65-1.87v1.32"
                              />
                            </g>
                            <g id="al">
                              <path
                                id="cg-3"
                                className="cls-2-be1"
                                d="m428.77,1006.62c-.23-2.19-5.62-2.47-5.66.09.64,3.43,7.22.64,7.45,5.44.14,3.56-5.53,3.97-7.99,2.51-.78-.46-1.28-1.19-1.51-2.1l1.51-.32c.23,2.56,6.44,2.88,6.44-.05,0-3.56-7.08-.64-7.4-5.39-.23-3.75,6.62-3.84,8.18-1.55.23.32.37.73.5,1.14"
                              />
                              <path
                                id="cC"
                                className="cls-2-be1"
                                d="m434.16,1005.21v4.2h6.3v1.28h-6.3v4.57h-1.51v-11.33h7.99v1.28h-6.49"
                              />
                              <path
                                id="cD"
                                className="cls-2-be1"
                                d="m452.25,1015.26l.09-9.96c-1.05,3.47-2.47,6.62-3.65,9.96h-1.05l-3.7-9.96.05,9.96h-1.32v-11.33h2.01l3.52,9.64c.96-3.43,2.33-6.4,3.47-9.64h1.96v11.33h-1.37"
                              />
                              <path
                                id="cE"
                                className="cls-2-be1"
                                d="m464.63,1007.08c-.46-1.28-1.6-2.06-3.33-2.06-2.7,0-3.97,1.83-3.97,4.52s1.32,4.61,4.07,4.61c1.96,0,2.83-1.1,3.56-2.38l1.23.64c-.82,1.74-2.33,3.01-4.89,3.01-3.65,0-5.34-2.28-5.53-5.89-.27-4.75,4.52-6.99,8.54-5.07.87.41,1.42,1.19,1.78,2.1"
                              />
                            </g>
                            <g id="am">
                              <path
                                id="cq-5"
                                className="cls-2-be1"
                                d="m477.78,1013.61c-.69.96-1.42,1.87-3.11,1.83-1.64-.05-2.65-.96-2.65-2.6-.05-2.92,2.88-2.88,5.71-2.88.14-1.6-.41-2.47-1.87-2.47-1.1.05-1.87.32-1.92,1.42l-1.51-.14c.23-1.69,1.51-2.38,3.47-2.38s3.29.91,3.29,2.92v3.75c-.05.91.32,1.46,1.28,1.23v.91c-1.42.41-2.79-.09-2.7-1.6Zm-4.25-.78c0,.91.55,1.51,1.46,1.51,1.87-.14,2.88-1.32,2.74-3.38-1.96.09-4.2-.23-4.2,1.87"
                              />
                              <path
                                id="cA-2"
                                className="cls-2-be1"
                                d="m485.73,1006.4c2.51.05,3.2,1.87,3.2,4.48s-.73,4.48-3.2,4.57c-1.42,0-2.24-.59-2.74-1.55l.05,4.8h-1.46l-.05-12.1h1.42l.09,1.37c.46-.96,1.28-1.55,2.7-1.55Zm-.37,7.95c1.83,0,2.06-1.55,2.06-3.43s-.27-3.33-2.06-3.38c-1.92,0-2.33,1.46-2.33,3.47s.46,3.33,2.33,3.33"
                              />
                              <path
                                id="cA-3"
                                className="cls-2-be1"
                                d="m494.87,1006.4c2.51.05,3.2,1.87,3.2,4.48s-.73,4.48-3.2,4.57c-1.42,0-2.24-.59-2.74-1.55l.05,4.8h-1.46l-.05-12.1h1.42l.09,1.37c.46-.96,1.28-1.55,2.7-1.55Zm-.37,7.95c1.83,0,2.06-1.55,2.06-3.43s-.27-3.33-2.06-3.38c-1.92,0-2.33,1.46-2.33,3.47s.46,3.33,2.33,3.33"
                              />
                              <path
                                id="co-3"
                                className="cls-2-be1"
                                d="m503.96,1007.81c-3.56-.73-2.42,4.16-2.6,7.45h-1.46l-.05-8.68h1.37c.05.55-.05,1.32.09,1.78.27-1.23,1.05-2.24,2.65-1.87v1.32"
                              />
                              <path
                                id="cp-5"
                                className="cls-2-be1"
                                d="m508.76,1006.4c2.83-.05,3.88,1.69,3.88,4.52.05,2.88-1.23,4.52-3.93,4.52s-3.79-1.78-3.84-4.52c0-3.01,1.28-4.52,3.88-4.52Zm-.05,7.95c2.01.05,2.42-1.42,2.42-3.43s-.37-3.43-2.33-3.43-2.42,1.46-2.42,3.43.46,3.38,2.33,3.43"
                              />
                              <path
                                id="cx-2"
                                className="cls-2-be1"
                                d="m518.26,1015.26h-1.74l-3.15-8.68h1.55l2.47,7.54,2.56-7.54h1.55"
                              />
                              <path
                                id="cq-6"
                                className="cls-2-be1"
                                d="m527.99,1013.61c-.69.96-1.42,1.87-3.11,1.83-1.64-.05-2.65-.96-2.65-2.6-.05-2.92,2.88-2.88,5.71-2.88.14-1.6-.41-2.47-1.87-2.47-1.1.05-1.87.32-1.92,1.42l-1.51-.14c.23-1.69,1.51-2.38,3.47-2.38s3.29.91,3.29,2.92v3.75c-.05.91.32,1.46,1.28,1.23v.91c-1.42.41-2.79-.09-2.7-1.6Zm-4.25-.78c0,.91.55,1.51,1.46,1.51,1.87-.14,2.88-1.32,2.74-3.38-1.96.09-4.2-.23-4.2,1.87"
                              />
                              <path
                                id="cm-3"
                                className="cls-2-be1"
                                d="m531.78,1015.26v-11.92h1.46v11.92h-1.46"
                              />
                            </g>
                            <path
                              className="cls-8-be1"
                              d="m881.79,402.22l-262.08-3.5.02-1,261.06,3.5v-159.73h1v160.73Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m881.79,241.5h-1v-.5h1v.5Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m881.83,241.52h-1.06v-.56h1.05v.56Zm-1-.5v.45h.94v-.46h-.94Z"
                            />
                            <path
                              className="cls-1-be1"
                              d="m619.16,402.85l-14.2-4.82,14.32-4.45-.12,9.27Z"
                            />
                            <path
                              className="cls-8-be1"
                              d="m619.65,403.55l-16.3-5.54,16.44-5.1-.14,10.64Zm-13.08-5.5l12.1,4.1.1-7.88-12.2,3.78Z"
                            />
                            <path
                              className="cls-13-be1"
                              d="m476.9,1051.2v23.83"
                            />
                            <path
                              className="cls-8-be1"
                              d="m477.4,1051.23h-1v-.53h1v.53Z"
                            />
                            <path
                              className="cls-9-be1"
                              d="m476.9,1089.8l-4.63-14.27h9.27l-4.64,14.27Z"
                            />
                            <path
                              className="cls-13-be1"
                              d="m484.73,1183.66v17.04"
                            />
                            <path
                              className="cls-8-be1"
                              d="m485.23,1183.68h-1v-.52h1v.52Z"
                            />
                            <path
                              className="cls-9-be1"
                              d="m484.73,1215.46l-4.63-14.26h9.28l-4.65,14.26Z"
                            />
                            <path
                              className="cls-3-be1"
                              d="m159.8,665.47h50.4v29.15h-50.4v-29.15Z"
                            />
                            <path
                              className="cls-3-be1"
                              d="m308.4,830.52h50.4v29.16h-50.4v-29.16Z"
                            />
                          </svg>
                        </div>
                        <div className="col-lg-4 approval">
                          <div className="tab">
                            <div id="link4">
                              <h5>
                                <em>Initiate MLR Approval</em>
                              </h5>
                              <p className="p_font">
                                This is the stage where Veeva coordinator
                                uploads material to VVPM for reviewers to
                                provide verdict on it.{" "}
                                <a href="#/" target="_blank">
                                  Click here
                                </a>{" "}
                                to refer to the PMAP job aid
                              </p>
                              <h5>
                                <em>Remember</em>
                              </h5>
                              <ul className="p_font ul_padding">
                                <li>
                                  &#9679; Contact GPS if it includes elements
                                  related to patient safety (for example, open
                                  text field, interactivity). Click here to
                                  learn more
                                </li>
                              </ul>
                            </div>

                            <div id="link5">
                              <h5>
                                <em>MLR Verdict</em>
                              </h5>
                              <p className="p_font">
                                There are 3 types of verdicts that the material
                                could get
                              </p>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  <u>Approved for production</u> – the document
                                  has been blessed by the reviewer.
                                </li>
                              </ul>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; Go ahead and ask for the asset to be
                                  developed into HTML or another final format!
                                </li>
                              </ul>

                              <ul className="ul_padding">
                                <li className="p_font">
                                  <u>Approved with changes</u> – the document
                                  has some minor errors/updates required (like
                                  spelling, grammatical errors).
                                </li>
                              </ul>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; Go ahead & ask for the asset to be
                                  developed into HTML or other final format BUT
                                  remember to incorporate the advised
                                  updates/corrections on your document.
                                </li>
                              </ul>

                              <ul className="ul_padding">
                                <li className="p_font">
                                  <u>Revise & Resubmit</u> – Oops! Reviewer
                                  found too many problems with the document and
                                  advises the material co-ordinator to rework it
                                  and submit again.
                                </li>
                              </ul>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; Go ahead and talk to the agency to
                                  re-do the artwork.
                                </li>
                              </ul>
                            </div>

                            <div id="link6">
                              <h5>
                                <em>DST produces HTML</em>
                              </h5>
                              <p className="p_font">
                                Note that DST is the only approved partner for
                                NE hub to produce HTML for emails. If the
                                artwork file was created through an agency, now
                                is the time to provide the source file of
                                artwork to DST!{" "}
                              </p>
                              <p>
                                <ul className="ul_padding">
                                  <li className="p_font">
                                    &#9679; Once HTML is produced and linking
                                    completed, DST will send the test email to
                                    material co-ordinator/owner displaying how
                                    the subject line, the email body, header and
                                    the footer as it will be displayed to the
                                    recipient. This is the final form that is to
                                    be submitted for certification.{" "}
                                  </li>
                                </ul>
                              </p>
                              <h5>
                                <em>Remember</em>
                              </h5>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; Delivery timeline - DST has agreed
                                  delivery timeline of 4 working days to produce
                                  HTML from PDF. Plan your timing to avoid
                                  delays. If there are changes vs your original
                                  brief, add more days to the delivery timeline.{" "}
                                </li>
                              </ul>
                            </div>

                            <div id="link7">
                              <h5>
                                <em>Initiate Certification</em>
                              </h5>
                              <p className="p_font">
                                This is the stage where Veeva coordinator
                                uploads final form of the material to VVPM for
                                certifiers to provide verdict on the material.{" "}
                              </p>
                              <h5>
                                <em>Remeber!</em>
                              </h5>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; The test email should be uploaded on
                                  VVPM to ‘attachments’{" "}
                                </li>
                              </ul>
                            </div>

                            <div id="link8">
                              <h5>
                                <em>Certification Verdict</em>
                              </h5>
                              <p className="p_font">
                                There are 3 types of verdicts you could get
                                Certified – the material is approved for
                                distribution now. Go ahead to review/set
                                inventory information on VVPM and set actual
                                date of first use.{" "}
                              </p>
                              <p className="p_font">
                                <u>Revise &amp; Resubmit for certification</u> –
                                The material has errors and/or requires updates
                              </p>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; Go ahead &amp; ask DST to make the
                                  advised changes, trigger a new test email and
                                  re-submit for certification.
                                </li>
                              </ul>
                              <p className="p_font">
                                <u>Not certified</u> – Certifier found too many
                                problems with the material and advises material
                                administrator to rework it and start a new MLR
                                flow.
                              </p>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; Go ahead and talk to the agency to
                                  re-do the artwork.
                                </li>
                                <li>
                                  &#9679; For the Nordics – If the material gets
                                  the verdict ‘not certified’ you can use
                                  ‘create draft’ function in VVPM to use the
                                  same job bag code for the updated material.
                                </li>
                              </ul>
                              <h5>
                                <em>Remeber!</em>
                              </h5>
                              <ul className="ul_padding">
                                <li>
                                  &#9679; The verdict ‘Certified’ does not mean
                                  that the material is now live. Additional
                                  steps are needed to move it to the environment
                                  where it will be available to the end user.
                                </li>
                              </ul>
                            </div>

                            <div id="link9">
                              <h5>
                                <em>Final steps before ‘Go-Live’</em>
                              </h5>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679;{" "}
                                  <strong>Actual date of first use</strong> is
                                  set by Veeva coordinator and is typically put
                                  as the date the material has been certified on
                                  VVPM, unless there’s a specific date when the
                                  usage of material will start (for example
                                  launch date, after the salesforce training
                                  etc.)
                                </li>
                                <li className="p_font">
                                  <strong>Inventory information</strong> – Veeva
                                  coordinator would have added this earlier to
                                  VVPM meta data and it should be checked when
                                  material goes live. This step will help a lot
                                  for efficient material management and
                                  withdrawal.{" "}
                                </li>
                                <li className="p_font">
                                  <strong>Expiry date</strong> is usually set
                                  for 2 years. However, if the material is a
                                  one-time use or for defined time, set the
                                  expiry date accordingly.
                                </li>
                                <li>
                                  <strong>Related pieces</strong> – Veeva
                                  coordinator should check that any relevant
                                  related pieces are linked to the VVPM job bag
                                </li>
                              </ul>

                              <h5>
                                <em>Remeber!</em>
                              </h5>
                              <ul className="ul_padding">
                                <li>
                                  &#9679; For Nordic countries: Please check the
                                  Nordic PMAP Guidance for detailed information
                                  on statutory copy submission
                                </li>
                              </ul>
                            </div>

                            <div id="link10">
                              <h5>
                                <em>
                                  Advise DST to push asset for – SFMC approval
                                </em>
                              </h5>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; DST does not have visibility over VVPM
                                  approval status. It’s material administrator’s
                                  responsibility to inform DST that asset has
                                  been certified and ask them to push the asset
                                  for approval on salesforce marketing cloud
                                  (SFMC).{" "}
                                </li>
                                <li className="p_font">
                                  &#9679; The approver for SFMC is a dedicated
                                  individual within OCE team.
                                </li>
                                <li className="p_font">
                                  &#9679; It is responsibility of SFMC approver
                                  to ensure{" "}
                                </li>
                                <ul className="ul_padding">
                                  <li>
                                    &#8226; The broadcast email is approved in
                                    VVPM
                                  </li>
                                  <li>
                                    &#8226; correct audience list has been used
                                    and correct footer links have been used.
                                  </li>
                                  <li>
                                    &#8226; If email is part of a journey, SFMC
                                    approver is additionally responsible to
                                    review journey logic that has been applied.
                                  </li>
                                </ul>
                              </ul>
                            </div>

                            <div id="link11">
                              <h5>
                                <em>Scheduled broadcast email is sent out</em>
                              </h5>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; Once approved, DST can schedule a send
                                  out on the agreed date(s) and time(s) to the
                                  designated audience.
                                </li>
                              </ul>
                            </div>
                            <div id="link12">
                              <h5>
                                <em>Inform customer facing team</em>
                              </h5>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; It is good practice to inform the
                                  customer facing team when the material is
                                  available.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </details>
                <details>
                  <summary>Phase 3: Material Management</summary>
                  <div>
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-4 material">
                          <div className="">
                            <svg
                              id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 617.48 461.5"
                            >
                              <defs>
                                <linearGradient
                                  id="linear-gradient-lq"
                                  x1="687.45"
                                  y1="-26.42"
                                  x2="687.45"
                                  y2="-114.28"
                                  gradientTransform="translate(-382.48 346.72) scale(1 -1)"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop offset="0" stopColor="#edf5ff" />
                                  <stop offset=".05" stopColor="#cfe4ff" />
                                  <stop offset="1" stopColor="#1071e5" />
                                </linearGradient>
                              </defs>
                              <a href="#link14">
                                <path
                                  className="cls-3lq"
                                  d="m190.46,7c0-3.31,2.69-6,6-6h217.02c3.31,0,6,2.69,6,6v75.87c0,3.31-2.69,6-6,6h-217.02c-3.31,0-6-2.69-6-6V7Z"
                                />
                              </a>
                              <g id="a">
                                <path
                                  id="l"
                                  className="cls-5lq"
                                  d="m232.91,22.9c4.94.06,10.85-.85,10.85,4.26,0,4.43-4.37,4.54-8.97,4.32v5.51h-1.87v-14.08Zm1.87,7.1c3.24,0,7.04.62,7.04-2.84s-3.86-2.67-7.04-2.73v5.57"
                                />
                                <path
                                  id="m"
                                  className="cls-5lq"
                                  d="m250.52,25.97c3.58,0,4.88,2.39,4.77,6.02h-7.67c0,2.27.8,3.8,3.01,3.86,1.48.06,2.44-.68,2.78-1.65l1.59.45c-.62,1.59-2.1,2.56-4.37,2.56-3.29,0-5-2.1-4.94-5.68.06-3.46,1.48-5.57,4.83-5.57Zm2.95,4.6c.34-3.41-4.32-4.37-5.51-1.59-.17.4-.34.97-.34,1.59h5.85"
                                />
                                <path
                                  id="n"
                                  className="cls-5lq"
                                  d="m262.67,27.73c-4.43-.91-3.01,5.17-3.24,9.26h-1.82l-.06-10.79h1.7c.06.68-.06,1.65.11,2.21.34-1.53,1.31-2.78,3.29-2.33v1.65"
                                />
                                <path
                                  id="o"
                                  className="cls-5lq"
                                  d="m264.32,23.87v-1.7h1.82v1.7h-1.82Zm0,13.12v-10.79h1.82v10.79h-1.82"
                                />
                                <path
                                  id="p"
                                  className="cls-5lq"
                                  d="m273.12,25.97c3.52-.06,4.83,2.1,4.83,5.62.06,3.58-1.53,5.62-4.88,5.62s-4.71-2.21-4.77-5.62c0-3.75,1.59-5.62,4.83-5.62Zm-.06,9.88c2.5.06,3.01-1.76,3.01-4.26s-.45-4.26-2.9-4.26-3.01,1.82-3.01,4.26.57,4.2,2.9,4.26"
                                />
                                <path
                                  id="q"
                                  className="cls-5lq"
                                  d="m283.63,25.97c1.76,0,2.73.74,3.41,1.87l-.06-5.68h1.82l.06,14.82h-1.7c-.11-.57,0-1.31-.17-1.76-.57,1.31-1.59,1.99-3.35,1.99-3.01,0-3.92-2.21-3.98-5.57,0-3.75,1.31-5.68,3.98-5.68Zm.51,1.36c-2.27,0-2.61,1.93-2.61,4.26s.34,4.2,2.56,4.2c2.39,0,2.9-1.82,2.9-4.32s-.51-4.2-2.84-4.15"
                                />
                                <path
                                  id="o-2"
                                  className="cls-5lq"
                                  d="m291.52,23.87v-1.7h1.82v1.7h-1.82Zm0,13.12v-10.79h1.82v10.79h-1.82"
                                />
                                <path
                                  id="r"
                                  className="cls-5lq"
                                  d="m300.1,27.39c-2.27,0-2.73,1.87-2.73,4.15s.51,4.26,2.73,4.26c1.36,0,2.33-.8,2.44-2.16l1.82.11c-.34,2.1-1.76,3.46-4.2,3.46-3.35,0-4.32-2.33-4.66-5.62-.57-5.28,5.74-7.44,8.35-3.63.23.4.28.8.4,1.25l-1.82.17c-.23-1.19-.91-1.99-2.33-1.99"
                                />
                              </g>
                              <g id="b">
                                <path
                                  id="s"
                                  className="cls-5lq"
                                  d="m322.24,36.98h-2.04l-2.27-9.31-2.33,9.31h-2.04l-3.07-10.79h1.82l2.21,9.31,2.44-9.31h1.93l2.33,9.31,2.39-9.31h1.76"
                                />
                                <path
                                  id="m-2"
                                  className="cls-5lq"
                                  d="m330.93,25.97c3.58,0,4.88,2.39,4.77,6.02h-7.67c0,2.27.8,3.8,3.01,3.86,1.48.06,2.44-.68,2.78-1.65l1.59.45c-.62,1.59-2.1,2.56-4.37,2.56-3.29,0-5-2.1-4.94-5.68.06-3.46,1.48-5.57,4.83-5.57Zm2.95,4.6c.34-3.41-4.32-4.37-5.51-1.59-.17.4-.34.97-.34,1.59h5.85"
                                />
                                <path
                                  id="t"
                                  className="cls-5lq"
                                  d="m343.14,25.97c3.01,0,3.92,2.21,3.98,5.57,0,3.75-1.31,5.68-3.98,5.68-1.76-.06-2.78-.62-3.35-1.93l-.11,1.7h-1.76l.06-14.82h1.82v5.74c.57-1.31,1.59-1.93,3.35-1.93Zm-.45,9.88c2.27,0,2.56-1.93,2.56-4.26s-.28-4.26-2.56-4.2c-2.39,0-2.9,1.82-2.9,4.32s.57,4.15,2.9,4.15"
                                />
                                <path
                                  id="u"
                                  className="cls-5lq"
                                  d="m355.64,28.86c-.17-1.93-4.88-2.16-4.94,0,.85,3.01,6.53.68,6.76,5.11s-8.52,4.2-8.92.45l1.59-.28c.23,2.04,5.51,2.56,5.57,0-.57-3.18-6.42-.85-6.7-5.11-.23-3.24,4.66-3.58,6.93-2.39.68.4,1.19,1.08,1.36,1.99"
                                />
                                <path
                                  id="o-3"
                                  className="cls-5lq"
                                  d="m359.55,23.87v-1.7h1.82v1.7h-1.82Zm0,13.12v-10.79h1.82v10.79h-1.82"
                                />
                                <path
                                  id="v"
                                  className="cls-5lq"
                                  d="m366.03,34.31c-.11,1.36,1.02,1.65,2.16,1.25v1.36c-1.87.57-3.98.28-3.98-2.21v-7.21h-1.25v-1.31h1.36l.51-2.44h1.19v2.44h1.99v1.31h-1.99v6.81"
                                />
                                <path
                                  id="m-3"
                                  className="cls-5lq"
                                  d="m374.04,25.97c3.58,0,4.88,2.39,4.77,6.02h-7.67c0,2.27.8,3.8,3.01,3.86,1.48.06,2.44-.68,2.78-1.65l1.59.45c-.62,1.59-2.1,2.56-4.37,2.56-3.29,0-5-2.1-4.94-5.68.06-3.46,1.48-5.57,4.83-5.57Zm2.95,4.6c.34-3.41-4.32-4.37-5.51-1.59-.17.4-.34.97-.34,1.59h5.85"
                                />
                              </g>
                              <g id="c">
                                <path
                                  id="w"
                                  className="cls-5lq"
                                  d="m258.98,57.02c-.85,1.19-1.76,2.33-3.86,2.27-2.04-.06-3.29-1.19-3.29-3.24-.06-3.63,3.58-3.58,7.1-3.58.17-1.99-.51-3.07-2.33-3.07-1.36.06-2.33.4-2.39,1.76l-1.87-.17c.28-2.1,1.87-2.95,4.32-2.95s4.09,1.14,4.09,3.63v4.66c-.06,1.14.4,1.82,1.59,1.53v1.14c-1.76.51-3.46-.11-3.35-1.99Zm-5.28-.97c0,1.14.68,1.87,1.82,1.87,2.33-.17,3.58-1.65,3.41-4.2-2.44.11-5.22-.28-5.22,2.33"
                                />
                                <path
                                  id="u-2"
                                  className="cls-5lq"
                                  d="m270,50.94c-.17-1.93-4.88-2.16-4.94,0,.85,3.01,6.53.68,6.76,5.11s-8.52,4.2-8.92.45l1.59-.28c.23,2.04,5.51,2.56,5.57,0-.57-3.18-6.42-.85-6.7-5.11-.23-3.24,4.66-3.58,6.93-2.39.68.4,1.19,1.08,1.36,1.99"
                                />
                                <path
                                  id="u-3"
                                  className="cls-5lq"
                                  d="m280.22,50.94c-.17-1.93-4.88-2.16-4.94,0,.85,3.01,6.53.68,6.76,5.11s-8.52,4.2-8.92.45l1.59-.28c.23,2.04,5.51,2.56,5.57,0-.57-3.18-6.42-.85-6.7-5.11-.23-3.24,4.66-3.58,6.93-2.39.68.4,1.19,1.08,1.36,1.99"
                                />
                                <path
                                  id="m-4"
                                  className="cls-5lq"
                                  d="m288.45,48.05c3.58,0,4.88,2.39,4.77,6.02h-7.67c0,2.27.8,3.8,3.01,3.86,1.48.06,2.44-.68,2.78-1.65l1.59.45c-.62,1.59-2.1,2.56-4.37,2.56-3.29,0-5-2.1-4.94-5.68.06-3.46,1.48-5.57,4.83-5.57Zm2.95,4.6c.34-3.41-4.32-4.37-5.51-1.59-.17.4-.34.97-.34,1.59h5.85"
                                />
                                <path
                                  id="u-4"
                                  className="cls-5lq"
                                  d="m301.8,50.94c-.17-1.93-4.88-2.16-4.94,0,.85,3.01,6.53.68,6.76,5.11s-8.52,4.2-8.92.45l1.59-.28c.23,2.04,5.51,2.56,5.57,0-.57-3.18-6.42-.85-6.7-5.11-.23-3.24,4.66-3.58,6.93-2.39.68.4,1.19,1.08,1.36,1.99"
                                />
                                <path
                                  id="u-5"
                                  className="cls-5lq"
                                  d="m312.02,50.94c-.17-1.93-4.88-2.16-4.94,0,.85,3.01,6.53.68,6.76,5.11s-8.52,4.2-8.92.45l1.59-.28c.23,2.04,5.51,2.56,5.57,0-.57-3.18-6.42-.85-6.7-5.11-.23-3.24,4.66-3.58,6.93-2.39.68.4,1.19,1.08,1.36,1.99"
                                />
                                <path
                                  id="x"
                                  className="cls-5lq"
                                  d="m326.5,49.47c-3.8.17-2.16,5.96-2.5,9.6h-1.76v-6.87c0-1.65-.28-2.84-1.99-2.73-3.75.23-2.16,5.91-2.5,9.6h-1.76l-.06-10.79h1.7c.06.57-.06,1.36.11,1.82.57-2.5,5.62-2.84,6.08,0,.62-1.19,1.53-1.99,3.29-2.04,4.83-.11,2.67,6.76,3.12,11.02h-1.76v-6.87c0-1.65-.28-2.78-1.99-2.73"
                                />
                                <path
                                  id="m-5"
                                  className="cls-5lq"
                                  d="m337.24,48.05c3.58,0,4.88,2.39,4.77,6.02h-7.67c0,2.27.8,3.8,3.01,3.86,1.48.06,2.44-.68,2.78-1.65l1.59.45c-.62,1.59-2.1,2.56-4.37,2.56-3.29,0-5-2.1-4.94-5.68.06-3.46,1.48-5.57,4.83-5.57Zm2.95,4.6c.34-3.41-4.32-4.37-5.51-1.59-.17.4-.34.97-.34,1.59h5.85"
                                />
                                <path
                                  id="y"
                                  className="cls-5lq"
                                  d="m349.56,48.05c5.05-.23,3.01,6.59,3.41,11.02h-1.82v-6.87c0-1.76-.45-2.78-2.21-2.73-4.09.11-2.5,5.79-2.78,9.6h-1.82l-.06-10.79h1.7c.06.57-.06,1.36.11,1.82.62-1.25,1.65-1.99,3.46-2.04"
                                />
                                <path
                                  id="v-2"
                                  className="cls-5lq"
                                  d="m357.62,56.39c-.11,1.36,1.02,1.65,2.16,1.25v1.36c-1.87.57-3.98.28-3.98-2.21v-7.21h-1.25v-1.31h1.36l.51-2.44h1.19v2.44h1.99v1.31h-1.99v6.81"
                                />
                              </g>
                              <a href="#link15">
                                <path
                                  className="cls-3lq"
                                  d="m1,159.48c0-3.31,2.69-6,6-6h217.02c3.31,0,6,2.69,6,6v75.86c0,3.31-2.69,6-6,6H7c-3.31,0-6-2.69-6-6v-75.86Z"
                                />
                              </a>
                              <g id="d">
                                <path
                                  id="z"
                                  className="cls-7lq"
                                  d="m58.48,196.12c-.06,3.61-2.22,5.39-5.78,5.39s-5.56-1.78-5.56-5.22v-8.78h1.83c.44,4.94-1.83,12.44,3.72,12.44s3.56-7.39,3.94-12.44h1.83v8.61"
                                />
                                <path
                                  id="A"
                                  className="cls-7lq"
                                  d="m66.37,190.51c3.06.06,3.89,2.28,3.89,5.44s-.89,5.44-3.89,5.56c-1.72,0-2.72-.72-3.33-1.89l.06,5.83h-1.78l-.06-14.72h1.72l.11,1.67c.56-1.17,1.56-1.89,3.28-1.89Zm-.44,9.67c2.22,0,2.5-1.89,2.5-4.17s-.33-4.06-2.5-4.11c-2.33,0-2.83,1.78-2.83,4.22s.56,4.06,2.83,4.06"
                                />
                                <path
                                  id="B"
                                  className="cls-7lq"
                                  d="m75.81,190.51c1.72,0,2.67.72,3.33,1.83l-.06-5.56h1.78l.06,14.5h-1.67c-.11-.56,0-1.28-.17-1.72-.56,1.28-1.56,1.94-3.28,1.94-2.94,0-3.83-2.17-3.89-5.44,0-3.67,1.28-5.56,3.89-5.56Zm.5,1.33c-2.22,0-2.56,1.89-2.56,4.17s.33,4.11,2.5,4.11c2.33,0,2.83-1.78,2.83-4.22s-.5-4.11-2.78-4.06"
                                />
                                <path
                                  id="C"
                                  className="cls-7lq"
                                  d="m90.03,199.29c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                                />
                                <path
                                  id="D"
                                  className="cls-7lq"
                                  d="m96.59,198.68c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                                />
                                <path
                                  id="E"
                                  className="cls-7lq"
                                  d="m104.42,190.51c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                                />
                              </g>
                              <g id="e">
                                <path
                                  id="F"
                                  className="cls-7lq"
                                  d="m127.2,191.9c-3.72.17-2.11,5.83-2.44,9.39h-1.72v-6.72c0-1.61-.28-2.78-1.94-2.67-3.67.22-2.11,5.78-2.44,9.39h-1.72l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.56-2.44,5.5-2.78,5.94,0,.61-1.17,1.5-1.94,3.22-2,4.72-.11,2.61,6.61,3.06,10.78h-1.72v-6.72c0-1.61-.28-2.72-1.94-2.67"
                                />
                                <path
                                  id="C-2"
                                  className="cls-7lq"
                                  d="m139.98,199.29c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                                />
                                <path
                                  id="D-2"
                                  className="cls-7lq"
                                  d="m146.53,198.68c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                                />
                                <path
                                  id="E-2"
                                  className="cls-7lq"
                                  d="m154.37,190.51c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                                />
                                <path
                                  id="G"
                                  className="cls-7lq"
                                  d="m166.25,192.23c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                                />
                                <path
                                  id="H"
                                  className="cls-7lq"
                                  d="m167.87,188.46v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                                />
                                <path
                                  id="C-3"
                                  className="cls-7lq"
                                  d="m178.75,199.29c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                                />
                                <path
                                  id="I"
                                  className="cls-7lq"
                                  d="m183.37,201.29v-14.5h1.78v14.5h-1.78"
                                />
                              </g>
                              <path
                                className="cls-4lq"
                                d="m115.52,242.84v167.13c0,3.31,2.69,6,6,6h52.06"
                              />
                              <path
                                className="cls-1lq"
                                d="m116.02,242.85h-1v-.5h1v.5Z"
                              />
                              <path
                                className="cls-2lq"
                                d="m188.34,415.97l-14.26,4.64v-9.28l14.26,4.64Z"
                              />
                              <path
                                className="cls-8lq"
                                d="m190.46,379.14c0-3.31,2.69-6,6-6h217.02c3.31,0,6,2.69,6,6v75.86c0,3.31-2.69,6-6,6h-217.02c-3.31,0-6-2.69-6-6v-75.86Z"
                              />
                              <g id="g">
                                <path
                                  id="J"
                                  className="cls-6lq"
                                  d="m251.11,411.49c-.06-2.65-5.8-2.84-5.99-.19,1.11,4.07,9.32.62,9.51,7.04.19,5.86-10.19,5.74-12.59,2.22-.37-.49-.62-1.17-.74-1.85l3.09-.49c.19,2.84,6.91,3.46,7.16.31-1.05-4.26-9.26-.62-9.51-7.04-.25-5.37,9.44-5.43,11.6-2.16.31.49.49,1.11.62,1.73"
                                />
                                <path
                                  id="K"
                                  className="cls-6lq"
                                  d="m266.91,419.77c-.8,1.85-2.28,3.27-5.06,3.21-3.7-.12-5.49-2.28-5.49-6.17s1.85-6.05,5.56-6.05,5.12,2.78,5.19,6.67h-7.53c0,1.91.49,3.4,2.41,3.46,1.11,0,1.85-.43,2.1-1.36l2.84.25Zm-2.78-4.26c.31-2.84-3.52-3.89-4.32-1.3-.12.37-.25.8-.25,1.3h4.57"
                                />
                                <path
                                  id="K-2"
                                  className="cls-6lq"
                                  d="m279.26,419.77c-.8,1.85-2.28,3.27-5.06,3.21-3.7-.12-5.49-2.28-5.49-6.17s1.85-6.05,5.56-6.05,5.12,2.78,5.19,6.67h-7.53c0,1.91.49,3.4,2.41,3.46,1.11,0,1.85-.43,2.1-1.36l2.84.25Zm-2.78-4.26c.31-2.84-3.52-3.89-4.32-1.3-.12.37-.25.8-.25,1.3h4.57"
                                />
                              </g>
                              <g id="h">
                                <path
                                  id="L"
                                  className="cls-6lq"
                                  d="m287.84,407.42c5.74.06,12.72-.99,12.59,4.88-.06,4.63-4.26,5.43-9.38,5.06v5.37h-3.21v-15.31Zm3.21,7.47c2.9,0,6.17.43,6.17-2.53s-3.33-2.41-6.17-2.41v4.94"
                                />
                                <path
                                  id="M"
                                  className="cls-6lq"
                                  d="m308.21,413.04c-3.64,0-2.1,5.99-2.41,9.69h-3.09v-16.11h3.09l-.06,6.73c.74-1.6,1.73-2.53,3.77-2.59,5.31-.06,3.58,6.98,3.89,11.98h-3.09c-.43-3.52,1.42-9.69-2.1-9.69"
                                />
                                <path
                                  id="N"
                                  className="cls-6lq"
                                  d="m322.9,420.63c-.99,1.17-1.85,2.41-3.95,2.35-2.28-.06-3.58-1.23-3.58-3.58-.06-3.7,3.4-3.89,7.16-3.77.06-1.6-.19-2.9-1.73-2.9-1.11.06-1.6.56-1.73,1.67l-3.21-.12c.43-2.35,2.22-3.58,5.06-3.52s4.57,1.36,4.63,4.2l.06,5.06c-.06.86.74,1.11,1.54.93v1.67c-1.85.49-4.38.31-4.26-1.98Zm-2.96.19c1.79,0,2.65-1.48,2.59-3.52-1.98,0-4.07-.19-4.01,1.85,0,1.05.49,1.67,1.42,1.67"
                                />
                                <path
                                  id="O"
                                  className="cls-6lq"
                                  d="m335.49,414.21c.06-1.79-4.32-2.1-4.38-.25.93,2.84,7.28.43,7.35,5.31.06,5.12-10.12,4.69-10.62.56l2.65-.43c.25,1.17,1.23,1.54,2.72,1.54,2.04.49,3.52-1.85,1.48-2.53-2.65-.86-6.3-.68-6.42-4.07-.12-4.94,9.51-4.57,9.94-.43"
                                />
                                <path
                                  id="K-3"
                                  className="cls-6lq"
                                  d="m350.8,419.77c-.8,1.85-2.28,3.27-5.06,3.21-3.7-.12-5.49-2.28-5.49-6.17s1.85-6.05,5.56-6.05,5.12,2.78,5.19,6.67h-7.53c0,1.91.49,3.4,2.41,3.46,1.11,0,1.85-.43,2.1-1.36l2.84.25Zm-2.78-4.26c.31-2.84-3.52-3.89-4.32-1.3-.12.37-.25.8-.25,1.3h4.57"
                                />
                              </g>
                              <g id="i">
                                <path
                                  id="P"
                                  className="cls-6lq"
                                  d="m359.32,422.73v-2.28h3.77v-10.43l-3.64,2.28v-2.35l3.83-2.53h2.84v13.02h3.52v2.28h-10.31"
                                />
                              </g>
                              <a href="#link16">
                                <path
                                  className="cls-3lq"
                                  d="m387.48,159.48c0-3.31,2.69-6,6-6h217c3.31,0,6,2.69,6,6v75.86c0,3.31-2.69,6-6,6h-217c-3.31,0-6-2.69-6-6v-75.86Z"
                                />
                              </a>
                              <g id="j">
                                <path
                                  id="Q"
                                  className="cls-7lq"
                                  d="m463.84,191.46c-.06,2.28-1.28,3.56-3.33,3.89l3.89,5.94h-2.11l-3.61-5.72h-4.28v5.72h-1.83v-13.78c4.89.17,11.39-1.17,11.28,3.94Zm-9.44,2.67c3.33-.11,7.61.72,7.61-2.61s-4.44-2.33-7.61-2.5v5.11"
                                />
                                <path
                                  id="E-3"
                                  className="cls-7lq"
                                  d="m470.84,190.51c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                                />
                                <path
                                  id="D-3"
                                  className="cls-7lq"
                                  d="m479.67,198.68c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                                />
                                <path
                                  id="H-2"
                                  className="cls-7lq"
                                  d="m483.28,188.46v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                                />
                                <path
                                  id="G-2"
                                  className="cls-7lq"
                                  d="m492.67,192.23c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                                />
                                <path
                                  id="E-4"
                                  className="cls-7lq"
                                  d="m498.5,190.51c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                                />
                              </g>
                              <g id="k">
                                <path
                                  id="A-2"
                                  className="cls-7lq"
                                  d="m516,190.51c3.06.06,3.89,2.28,3.89,5.44s-.89,5.44-3.89,5.56c-1.72,0-2.72-.72-3.33-1.89l.06,5.83h-1.78l-.06-14.72h1.72l.11,1.67c.56-1.17,1.56-1.89,3.28-1.89Zm-.44,9.67c2.22,0,2.5-1.89,2.5-4.17s-.33-4.06-2.5-4.11c-2.33,0-2.83,1.78-2.83,4.22s.56,4.06,2.83,4.06"
                                />
                                <path
                                  id="C-4"
                                  className="cls-7lq"
                                  d="m528.56,199.29c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                                />
                                <path
                                  id="R"
                                  className="cls-7lq"
                                  d="m541.67,190.73c-.56,6.94,2.28,16.28-6.11,14.5-1.28-.33-2.11-1.11-2.44-2.39l1.78-.28c.83,2.61,5.56,1.78,4.94-1.56v-1.67c-.61,1.17-1.61,2-3.39,2-3,0-3.78-2.28-3.78-5.33s.89-5.39,3.94-5.44c1.61-.06,2.67.89,3.28,1.94.06-.56,0-1.28.11-1.78h1.67Zm-4.61,9.33c2,0,2.78-1.78,2.78-4.06s-.78-4.17-2.78-4.17c-2.17,0-2.56,1.89-2.56,4.17s.33,4.06,2.56,4.06"
                                />
                                <path
                                  id="E-5"
                                  className="cls-7lq"
                                  d="m548.5,190.51c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                                />
                              </g>
                              <path
                                className="cls-4lq"
                                d="m501.99,242.84v168.23c0,3.31-2.69,6-6,6h-59.63"
                              />
                              <path
                                className="cls-1lq"
                                d="m502.49,242.85h-1v-.5h1v.5Z"
                              />
                              <path
                                className="cls-2lq"
                                d="m421.6,417.07l14.26-4.63v9.28l-14.26-4.65Z"
                              />
                              <path
                                className="cls-4lq"
                                d="m188.96,44.94h-67.44c-3.31,0-6,2.69-6,6v85.15"
                              />
                              <path
                                className="cls-1lq"
                                d="m189.46,45.44h-.5v-1h.5v1Z"
                              />
                              <path
                                className="cls-2lq"
                                d="m115.52,150.86l-4.64-14.27h9.27l-4.63,14.27Z"
                              />
                              <path
                                className="cls-4lq"
                                d="m420.98,44.94h75c3.31,0,6,2.69,6,6v85.15"
                              />
                              <path
                                className="cls-1lq"
                                d="m420.99,45.44h-.5v-1h.5v1Z"
                              />
                              <path
                                className="cls-2lq"
                                d="m501.99,150.86l-4.64-14.27h9.27l-4.63,14.27Z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="col-lg-8 material">
                          <div className="tab">
                            <div id="link14">
                              <h5>
                                <em>Periodic Website assessment</em>
                              </h5>
                              <p className="p_font">
                                The designated website owner is accountable for
                                website maintenance and change control. Lilly
                                websites are periodically reviewed by GCP or E&C
                                to confirm
                              </p>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; Website owner and website custodian
                                  are accurately listed
                                </li>
                                <li className="p_font">
                                  &#9679; That the website content is current
                                  and approved
                                </li>
                                <li className="p_font">
                                  &#9679; That the website is meeting
                                  performance measurements and objectives
                                </li>
                                <li className="p_font">
                                  &#9679; That the website remains compliant
                                  with the Global Procedures on Websites,
                                  Webpages, and Tools
                                </li>
                              </ul>
                              <p className="p_font">
                                Additionally, TECH@LILLY manager in NE hub
                                triggers CSA (Controlled Self-Assessment)
                                advising selected website owners to perform a
                                thorough assessment of their website. Click here
                                to see the list of all monitoring activities
                                across NE hub.
                              </p>
                            </div>
                            <div id="link15">
                              <h5>
                                <i>Update of material </i>
                              </h5>
                              <ul className="ul_padding">
                                <li className="p_font">
                                  &#9679; Several reasons (for example PI
                                  update, new information to be added) might
                                  need you to update your material either as a
                                  follow-up of quarterly material review or any
                                  other time.
                                </li>
                                <li className="p_font">
                                  &#9679; Any update (including adding or
                                  removing a page) initiates the process right
                                  from the beginning, i.e. ‘Brief the
                                  agency/team’{" "}
                                </li>
                                <li className="p_font">
                                  &#9679; FOR UK – Veeva Job bag will always be
                                  updated if the content on the page is being
                                  modified. However, if a link such as PI or
                                  policy links on footer (that have their own
                                  job bag code) are being updated, the website’s
                                  job bag does not need to change.
                                </li>
                                <li className="p_font">
                                  &#9679; For Nordics – Exception applies for
                                  websites where same job bag could be used.
                                  Draft is created and old version moves to
                                  superseded.
                                </li>
                                <li className="p_font">
                                  &#9679; Copyright year needs to be updated by
                                  each calendar year if the content of the
                                  website has been updated in the year.
                                </li>
                              </ul>
                            </div>
                            <div id="link16">
                              <h5>
                                <i>Retire a webpage </i>
                              </h5>
                              <p className="p_font">
                                If it is removal of a single page within a
                                website, its classified as ‘material update’,
                                please follow ‘Material management’ phase for
                                instructions.
                              </p>
                              <p className="p_font">
                                NE hub has a platform called campaign services
                                that can be leveraged to host content when EWI
                                site is not available to host content for a
                                brand/TA. Campaign service platform can host
                                PDFs or HTML webpages.
                              </p>
                              <p className="p_font">
                                Webpages hosted on campaign service should be
                                temporary and only created for marketing
                                campaigns active for maximum of 6 months. This
                                is because these webpages do not have inventory
                                record on global Lilly database called CMDB.
                                Rarely, a campaign may need to be live for over
                                6 months. In such instances, a 3-month extension
                                can be applied by making a request through
                                TECH@LILLY manager.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </details>

                <details>
                  <summary>Phase 4: Material Withdrawal</summary>
                  <div>
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-4 widthdrawal">
                          {" "}
                          <svg
                            id="Layer_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 781.29 1023.44"
                          >
                            <defs></defs>
                            <path
                              className="cls-4kl"
                              d="m478.09,6.5c0-3.31,2.69-6,6-6h290.28c3.31,0,6,2.69,6,6v116.2c0,3.31-2.69,6-6,6h-290.28c-3.31,0-6-2.69-6-6V6.5Z"
                            />
                            <g id="a">
                              <path
                                id="Q"
                                className="cls-7kl"
                                d="m536.93,67.9h-2.47l-3.46-12.96-3.4,12.96h-2.47l-4.51-15.31h2.16l3.64,13.46.93-3.95,2.65-9.51h1.98l3.64,13.46,3.64-13.46h2.16"
                              />
                              <path
                                id="R"
                                className="cls-7kl"
                                d="m542.92,53.64v-1.85h1.98v1.85h-1.98Zm0,14.26v-11.73h1.98v11.73h-1.98"
                              />
                              <path
                                id="S"
                                className="cls-7kl"
                                d="m549.96,65c-.12,1.48,1.11,1.79,2.35,1.36v1.48c-2.04.62-4.32.31-4.32-2.41v-7.84h-1.36v-1.42h1.48l.56-2.65h1.3v2.65h2.16v1.42h-2.16v7.41"
                              />
                              <path
                                id="T"
                                className="cls-7kl"
                                d="m559.03,57.47c-4.44,0-2.72,6.3-3.02,10.43h-1.98v-16.11h1.98l-.06,6.36c.74-1.3,1.73-2.22,3.77-2.22,5.49,0,3.27,7.16,3.7,11.98h-1.98v-7.47c.12-1.98-.49-3.02-2.41-2.96"
                              />
                              <path
                                id="U"
                                className="cls-7kl"
                                d="m570.08,55.93c1.91,0,2.96.8,3.7,2.04l-.06-6.17h1.98l.06,16.11h-1.85c-.12-.62,0-1.42-.19-1.91-.62,1.42-1.73,2.16-3.64,2.16-3.27,0-4.26-2.41-4.32-6.05,0-4.07,1.42-6.17,4.32-6.17Zm.56,1.48c-2.47,0-2.84,2.1-2.84,4.63s.37,4.57,2.78,4.57c2.59,0,3.15-1.98,3.15-4.69s-.56-4.57-3.09-4.51"
                              />
                              <path
                                id="V"
                                className="cls-7kl"
                                d="m584.22,57.84c-4.81-.99-3.27,5.62-3.52,10.06h-1.98l-.06-11.73h1.85c.06.74-.06,1.79.12,2.41.37-1.67,1.42-3.02,3.58-2.53v1.79"
                              />
                              <path
                                id="W"
                                className="cls-7kl"
                                d="m593.23,65.68c-.93,1.3-1.91,2.53-4.2,2.47-2.22-.06-3.58-1.3-3.58-3.52-.06-3.95,3.89-3.89,7.72-3.89.19-2.16-.56-3.33-2.53-3.33-1.48.06-2.53.43-2.59,1.91l-2.04-.19c.31-2.28,2.04-3.21,4.69-3.21s4.44,1.23,4.44,3.95v5.06c-.06,1.23.43,1.98,1.73,1.67v1.23c-1.91.56-3.77-.12-3.64-2.16Zm-5.74-1.05c0,1.23.74,2.04,1.98,2.04,2.53-.19,3.89-1.79,3.7-4.57-2.65.12-5.68-.31-5.68,2.53"
                              />
                              <path
                                id="X"
                                className="cls-7kl"
                                d="m609.59,67.9h-2.22l-2.47-10.12-2.53,10.12h-2.22l-3.33-11.73h1.98l2.41,10.12,2.65-10.12h2.1l2.53,10.12,2.59-10.12h1.91"
                              />
                            </g>
                            <g id="b">
                              <path
                                id="S-2"
                                className="cls-7kl"
                                d="m622.67,65c-.12,1.48,1.11,1.79,2.35,1.36v1.48c-2.04.62-4.32.31-4.32-2.41v-7.84h-1.36v-1.42h1.48l.56-2.65h1.3v2.65h2.16v1.42h-2.16v7.41"
                              />
                              <path
                                id="T-2"
                                className="cls-7kl"
                                d="m631.75,57.47c-4.44,0-2.72,6.3-3.02,10.43h-1.98v-16.11h1.98l-.06,6.36c.74-1.3,1.73-2.22,3.77-2.22,5.49,0,3.27,7.16,3.7,11.98h-1.98v-7.47c.12-1.98-.49-3.02-2.41-2.96"
                              />
                              <path
                                id="Y"
                                className="cls-7kl"
                                d="m643.72,55.93c3.89,0,5.31,2.59,5.19,6.54h-8.33c0,2.47.86,4.14,3.27,4.2,1.6.06,2.65-.74,3.02-1.79l1.73.49c-.68,1.73-2.28,2.78-4.75,2.78-3.58,0-5.43-2.28-5.37-6.17.06-3.77,1.6-6.05,5.25-6.05Zm3.21,5c.37-3.7-4.69-4.75-5.99-1.73-.19.43-.37,1.05-.37,1.73h6.36"
                              />
                            </g>
                            <g id="c">
                              <path
                                id="Z"
                                className="cls-7kl"
                                d="m657.92,67.9v-15.31h11.54v1.73h-9.51v4.88h8.89v1.67h-8.89v5.37h10v1.67h-12.04"
                              />
                              <path
                                id="Q-2"
                                className="cls-7kl"
                                d="m687.3,67.9h-2.47l-3.46-12.96-3.4,12.96h-2.47l-4.51-15.31h2.16l3.64,13.46.93-3.95,2.65-9.51h1.98l3.64,13.46,3.64-13.46h2.16"
                              />
                              <path
                                id="aa"
                                className="cls-7kl"
                                d="m693.85,67.9v-15.31h2.1v15.31h-2.1"
                              />
                            </g>
                            <g id="d">
                              <path
                                id="ab"
                                className="cls-7kl"
                                d="m712.49,59.08c-.19-2.1-5.31-2.35-5.37,0,.93,3.27,7.1.74,7.35,5.56s-9.26,4.57-9.69.49l1.73-.31c.25,2.22,5.99,2.78,6.05,0-.62-3.46-6.98-.93-7.28-5.56-.25-3.52,5.06-3.89,7.53-2.59.74.43,1.3,1.17,1.48,2.16"
                              />
                              <path
                                id="R-2"
                                className="cls-7kl"
                                d="m716.75,53.64v-1.85h1.98v1.85h-1.98Zm0,14.26v-11.73h1.98v11.73h-1.98"
                              />
                              <path
                                id="S-3"
                                className="cls-7kl"
                                d="m723.79,65c-.12,1.48,1.11,1.79,2.35,1.36v1.48c-2.04.62-4.32.31-4.32-2.41v-7.84h-1.36v-1.42h1.48l.56-2.65h1.3v2.65h2.16v1.42h-2.16v7.41"
                              />
                              <path
                                id="Y-2"
                                className="cls-7kl"
                                d="m732.49,55.93c3.89,0,5.31,2.59,5.19,6.54h-8.33c0,2.47.86,4.14,3.27,4.2,1.6.06,2.65-.74,3.02-1.79l1.73.49c-.68,1.73-2.28,2.78-4.75,2.78-3.58,0-5.43-2.28-5.37-6.17.06-3.77,1.6-6.05,5.25-6.05Zm3.21,5c.37-3.7-4.69-4.75-5.99-1.73-.19.43-.37,1.05-.37,1.73h6.36"
                              />
                            </g>
                            <path
                              className="cls-4kl"
                              d="m243.9,900.74c0-3.31,2.69-6,6-6h290.28c3.31,0,6,2.69,6,6v116.2c0,3.31-2.69,6-6,6H249.9c-3.31,0-6-2.69-6-6v-116.2Z"
                            />
                            <g id="e">
                              <path
                                id="ac"
                                className="cls-7kl"
                                d="m296.2,952.27l.11-12.11c-1.28,4.22-3,8.06-4.44,12.11h-1.28l-4.5-12.11.06,12.11h-1.61v-13.78h2.44l4.28,11.72c1.17-4.17,2.83-7.78,4.22-11.72h2.39v13.78h-1.67"
                              />
                              <path
                                id="W-2"
                                className="cls-7kl"
                                d="m307.31,950.27c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                              />
                              <path
                                id="S-4"
                                className="cls-7kl"
                                d="m313.87,949.66c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                              />
                              <path
                                id="Y-3"
                                className="cls-7kl"
                                d="m321.7,941.49c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="V-2"
                                className="cls-7kl"
                                d="m333.59,943.21c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                              />
                              <path
                                id="R-3"
                                className="cls-7kl"
                                d="m335.2,939.44v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                              />
                              <path
                                id="W-3"
                                className="cls-7kl"
                                d="m346.09,950.27c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                              />
                              <path
                                id="ad"
                                className="cls-7kl"
                                d="m350.7,952.27v-14.5h1.78v14.5h-1.78"
                              />
                            </g>
                            <g id="f">
                              <path
                                id="ae"
                                className="cls-7kl"
                                d="m364.87,941.49c3.44-.06,4.72,2.06,4.72,5.5.06,3.5-1.5,5.5-4.78,5.5s-4.61-2.17-4.67-5.5c0-3.67,1.56-5.5,4.72-5.5Zm-.06,9.67c2.44.06,2.94-1.72,2.94-4.17s-.44-4.17-2.83-4.17-2.94,1.78-2.94,4.17.56,4.11,2.83,4.17"
                              />
                              <path
                                id="X-2"
                                className="cls-7kl"
                                d="m381.87,952.27h-2l-2.22-9.11-2.28,9.11h-2l-3-10.56h1.78l2.17,9.11,2.39-9.11h1.89l2.28,9.11,2.33-9.11h1.72"
                              />
                              <path
                                id="af"
                                className="cls-7kl"
                                d="m391.31,941.49c4.94-.22,2.94,6.44,3.33,10.78h-1.78v-6.72c0-1.72-.44-2.72-2.17-2.67-4,.11-2.44,5.67-2.72,9.39h-1.78l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.61-1.22,1.61-1.94,3.39-2"
                              />
                              <path
                                id="Y-4"
                                className="cls-7kl"
                                d="m401.48,941.49c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="V-3"
                                className="cls-7kl"
                                d="m413.37,943.21c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                              />
                            </g>
                            <g id="g">
                              <path
                                id="V-4"
                                className="cls-7kl"
                                d="m425.53,943.21c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                              />
                              <path
                                id="Y-5"
                                className="cls-7kl"
                                d="m431.37,941.49c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="S-5"
                                className="cls-7kl"
                                d="m440.2,949.66c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                              />
                              <path
                                id="R-4"
                                className="cls-7kl"
                                d="m443.81,939.44v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                              />
                              <path
                                id="V-5"
                                className="cls-7kl"
                                d="m453.2,943.21c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                              />
                              <path
                                id="Y-6"
                                className="cls-7kl"
                                d="m459.03,941.49c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="ab-2"
                                className="cls-7kl"
                                d="m472.09,944.33c-.17-1.89-4.78-2.11-4.83,0,.83,2.94,6.39.67,6.61,5s-8.33,4.11-8.72.44l1.56-.28c.22,2,5.39,2.5,5.44,0-.56-3.11-6.28-.83-6.56-5-.22-3.17,4.56-3.5,6.78-2.33.67.39,1.17,1.06,1.33,1.94"
                              />
                            </g>
                            <g id="h">
                              <path
                                id="S-6"
                                className="cls-7kl"
                                d="m483.42,949.66c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                              />
                              <path
                                id="T-3"
                                className="cls-7kl"
                                d="m491.59,942.88c-4,0-2.44,5.67-2.72,9.39h-1.78v-14.5h1.78l-.06,5.72c.67-1.17,1.56-2,3.39-2,4.94,0,2.94,6.44,3.33,10.78h-1.78v-6.72c.11-1.78-.44-2.72-2.17-2.67"
                              />
                              <path
                                id="Y-7"
                                className="cls-7kl"
                                d="m502.37,941.49c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                            </g>
                            <g id="i">
                              <path
                                id="ag"
                                className="cls-7kl"
                                d="m323.51,966.88c-2.22,0-2.67,1.83-2.67,4.06s.5,4.17,2.67,4.17c1.33,0,2.28-.78,2.39-2.11l1.78.11c-.33,2.06-1.72,3.39-4.11,3.39-3.28,0-4.22-2.28-4.56-5.5-.56-5.17,5.61-7.28,8.17-3.56.22.39.28.78.39,1.22l-1.78.17c-.22-1.17-.89-1.94-2.28-1.94"
                              />
                              <path
                                id="ae-2"
                                className="cls-7kl"
                                d="m333.73,965.49c3.44-.06,4.72,2.06,4.72,5.5.06,3.5-1.5,5.5-4.78,5.5s-4.61-2.17-4.67-5.5c0-3.67,1.56-5.5,4.72-5.5Zm-.06,9.67c2.44.06,2.94-1.72,2.94-4.17s-.44-4.17-2.83-4.17-2.94,1.78-2.94,4.17.56,4.11,2.83,4.17"
                              />
                              <path
                                id="af-2"
                                className="cls-7kl"
                                d="m345.78,965.49c4.94-.22,2.94,6.44,3.33,10.78h-1.78v-6.72c0-1.72-.44-2.72-2.17-2.67-4,.11-2.44,5.67-2.72,9.39h-1.78l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.61-1.22,1.61-1.94,3.39-2"
                              />
                              <path
                                id="S-7"
                                className="cls-7kl"
                                d="m353.67,973.66c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                              />
                              <path
                                id="Y-8"
                                className="cls-7kl"
                                d="m361.51,965.49c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="af-3"
                                className="cls-7kl"
                                d="m373.56,965.49c4.94-.22,2.94,6.44,3.33,10.78h-1.78v-6.72c0-1.72-.44-2.72-2.17-2.67-4,.11-2.44,5.67-2.72,9.39h-1.78l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.61-1.22,1.61-1.94,3.39-2"
                              />
                              <path
                                id="S-8"
                                className="cls-7kl"
                                d="m381.45,973.66c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                              />
                            </g>
                            <g id="j">
                              <path
                                id="ae-3"
                                className="cls-7kl"
                                d="m394.84,965.49c3.44-.06,4.72,2.06,4.72,5.5.06,3.5-1.5,5.5-4.78,5.5s-4.61-2.17-4.67-5.5c0-3.67,1.56-5.5,4.72-5.5Zm-.06,9.67c2.44.06,2.94-1.72,2.94-4.17s-.44-4.17-2.83-4.17-2.94,1.78-2.94,4.17.56,4.11,2.83,4.17"
                              />
                              <path
                                id="af-4"
                                className="cls-7kl"
                                d="m406.9,965.49c4.94-.22,2.94,6.44,3.33,10.78h-1.78v-6.72c0-1.72-.44-2.72-2.17-2.67-4,.11-2.44,5.67-2.72,9.39h-1.78l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.61-1.22,1.61-1.94,3.39-2"
                              />
                            </g>
                            <g id="k">
                              <path
                                id="ah"
                                className="cls-7kl"
                                d="m424.67,976.27h-1.89l-5.61-13.78h1.94l4.61,12.11,4.61-12.11h2"
                              />
                              <path
                                id="Y-9"
                                className="cls-7kl"
                                d="m434.84,965.49c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="Y-10"
                                className="cls-7kl"
                                d="m445.95,965.49c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="ai"
                                className="cls-7kl"
                                d="m457.51,976.27h-2.11l-3.83-10.56h1.89l3,9.17,3.11-9.17h1.89"
                              />
                              <path
                                id="W-4"
                                className="cls-7kl"
                                d="m469.34,974.27c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                              />
                            </g>
                            <path
                              className="cls-4kl"
                              d="m241.81,697.08c0-3.31,2.69-6,6-6h290.28c3.31,0,6,2.69,6,6v116.18c0,3.31-2.69,6-6,6H247.81c-3.31,0-6-2.69-6-6v-116.18Z"
                            />
                            <g id="l">
                              <path
                                id="aj"
                                className="cls-7kl"
                                d="m274.14,738.66c-.56-1.56-1.94-2.5-4.06-2.5-3.28,0-4.83,2.22-4.83,5.5s1.61,5.61,4.94,5.61c2.39,0,3.44-1.33,4.33-2.89l1.5.78c-1,2.11-2.83,3.67-5.94,3.67-4.44,0-6.5-2.78-6.72-7.17-.33-5.78,5.5-8.5,10.39-6.17,1.06.5,1.72,1.44,2.17,2.56"
                              />
                              <path
                                id="V-6"
                                className="cls-7kl"
                                d="m283.08,739.55c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                              />
                              <path
                                id="ae-4"
                                className="cls-7kl"
                                d="m288.92,737.83c3.44-.06,4.72,2.06,4.72,5.5.06,3.5-1.5,5.5-4.78,5.5s-4.61-2.17-4.67-5.5c0-3.67,1.56-5.5,4.72-5.5Zm-.06,9.67c2.44.06,2.94-1.72,2.94-4.17s-.44-4.17-2.83-4.17-2.94,1.78-2.94,4.17.56,4.11,2.83,4.17"
                              />
                              <path
                                id="ab-3"
                                className="cls-7kl"
                                d="m301.97,740.66c-.17-1.89-4.78-2.11-4.83,0,.83,2.94,6.39.67,6.61,5s-8.33,4.11-8.72.44l1.56-.28c.22,2,5.39,2.5,5.44,0-.56-3.11-6.28-.83-6.56-5-.22-3.17,4.56-3.5,6.78-2.33.67.39,1.17,1.06,1.33,1.94"
                              />
                              <path
                                id="ab-4"
                                className="cls-7kl"
                                d="m311.97,740.66c-.17-1.89-4.78-2.11-4.83,0,.83,2.94,6.39.67,6.61,5s-8.33,4.11-8.72.44l1.56-.28c.22,2,5.39,2.5,5.44,0-.56-3.11-6.28-.83-6.56-5-.22-3.17,4.56-3.5,6.78-2.33.67.39,1.17,1.06,1.33,1.94"
                              />
                            </g>
                            <g id="m">
                              <path
                                id="ak"
                                className="cls-7kl"
                                d="m325.64,735.6c-1.72-.5-2.33.56-2.11,2.44h2.11v1.28h-2.11v9.28h-1.72v-9.28h-1.5v-1.28h1.5c-.39-2.89.94-4.56,3.83-3.78v1.33"
                              />
                              <path
                                id="al"
                                className="cls-7kl"
                                d="m330.25,748.83c-4.94.22-3-6.44-3.39-10.78h1.78v6.67c0,1.72.39,2.78,2.17,2.72,4-.11,2.5-5.61,2.78-9.39h1.72l.06,10.56h-1.67c-.06-.56.06-1.39-.11-1.83-.61,1.22-1.56,2-3.33,2.06"
                              />
                              <path
                                id="af-5"
                                className="cls-7kl"
                                d="m343.19,737.83c4.94-.22,2.94,6.44,3.33,10.78h-1.78v-6.72c0-1.72-.44-2.72-2.17-2.67-4,.11-2.44,5.67-2.72,9.39h-1.78l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.61-1.22,1.61-1.94,3.39-2"
                              />
                              <path
                                id="ag-2"
                                className="cls-7kl"
                                d="m353.14,739.21c-2.22,0-2.67,1.83-2.67,4.06s.5,4.17,2.67,4.17c1.33,0,2.28-.78,2.39-2.11l1.78.11c-.33,2.06-1.72,3.39-4.11,3.39-3.28,0-4.22-2.28-4.56-5.5-.56-5.17,5.61-7.28,8.17-3.56.22.39.28.78.39,1.22l-1.78.17c-.22-1.17-.89-1.94-2.28-1.94"
                              />
                              <path
                                id="S-9"
                                className="cls-7kl"
                                d="m361.08,745.99c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                              />
                              <path
                                id="R-5"
                                className="cls-7kl"
                                d="m364.69,735.77v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                              />
                              <path
                                id="ae-5"
                                className="cls-7kl"
                                d="m373.31,737.83c3.44-.06,4.72,2.06,4.72,5.5.06,3.5-1.5,5.5-4.78,5.5s-4.61-2.17-4.67-5.5c0-3.67,1.56-5.5,4.72-5.5Zm-.06,9.67c2.44.06,2.94-1.72,2.94-4.17s-.44-4.17-2.83-4.17-2.94,1.78-2.94,4.17.56,4.11,2.83,4.17"
                              />
                              <path
                                id="af-6"
                                className="cls-7kl"
                                d="m385.36,737.83c4.94-.22,2.94,6.44,3.33,10.78h-1.78v-6.72c0-1.72-.44-2.72-2.17-2.67-4,.11-2.44,5.67-2.72,9.39h-1.78l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.61-1.22,1.61-1.94,3.39-2"
                              />
                              <path
                                id="W-5"
                                className="cls-7kl"
                                d="m397.81,746.6c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                              />
                              <path
                                id="ad-2"
                                className="cls-7kl"
                                d="m402.42,748.6v-14.5h1.78v14.5h-1.78"
                              />
                            </g>
                            <g id="n">
                              <path
                                id="ab-5"
                                className="cls-7kl"
                                d="m418.53,740.66c-.17-1.89-4.78-2.11-4.83,0,.83,2.94,6.39.67,6.61,5s-8.33,4.11-8.72.44l1.56-.28c.22,2,5.39,2.5,5.44,0-.56-3.11-6.28-.83-6.56-5-.22-3.17,4.56-3.5,6.78-2.33.67.39,1.17,1.06,1.33,1.94"
                              />
                              <path
                                id="S-10"
                                className="cls-7kl"
                                d="m424.31,745.99c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                              />
                              <path
                                id="W-6"
                                className="cls-7kl"
                                d="m434.42,746.6c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                              />
                              <path
                                id="am"
                                className="cls-7kl"
                                d="m445.64,748.6l-3.56-4.83-1.28,1.06v3.78h-1.78v-14.5h1.78v9.06l4.61-5.11h2.06l-4.28,4.56,4.56,6h-2.11"
                              />
                              <path
                                id="Y-11"
                                className="cls-7kl"
                                d="m453.25,737.83c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="T-4"
                                className="cls-7kl"
                                d="m464.69,739.21c-4,0-2.44,5.67-2.72,9.39h-1.78v-14.5h1.78l-.06,5.72c.67-1.17,1.56-2,3.39-2,4.94,0,2.94,6.44,3.33,10.78h-1.78v-6.72c.11-1.78-.44-2.72-2.17-2.67"
                              />
                              <path
                                id="ae-6"
                                className="cls-7kl"
                                d="m475.47,737.83c3.44-.06,4.72,2.06,4.72,5.5.06,3.5-1.5,5.5-4.78,5.5s-4.61-2.17-4.67-5.5c0-3.67,1.56-5.5,4.72-5.5Zm-.06,9.67c2.44.06,2.94-1.72,2.94-4.17s-.44-4.17-2.83-4.17-2.94,1.78-2.94,4.17.56,4.11,2.83,4.17"
                              />
                              <path
                                id="ad-3"
                                className="cls-7kl"
                                d="m482.36,748.6v-14.5h1.78v14.5h-1.78"
                              />
                              <path
                                id="U-2"
                                className="cls-7kl"
                                d="m490.14,737.83c1.72,0,2.67.72,3.33,1.83l-.06-5.56h1.78l.06,14.5h-1.67c-.11-.56,0-1.28-.17-1.72-.56,1.28-1.56,1.94-3.28,1.94-2.94,0-3.83-2.17-3.89-5.44,0-3.67,1.28-5.56,3.89-5.56Zm.5,1.33c-2.22,0-2.56,1.89-2.56,4.17s.33,4.11,2.5,4.11c2.33,0,2.83-1.78,2.83-4.22s-.5-4.11-2.78-4.06"
                              />
                              <path
                                id="Y-12"
                                className="cls-7kl"
                                d="m502.08,737.83c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="V-7"
                                className="cls-7kl"
                                d="m513.97,739.55c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                              />
                              <path
                                id="ab-6"
                                className="cls-7kl"
                                d="m521.75,740.66c-.17-1.89-4.78-2.11-4.83,0,.83,2.94,6.39.67,6.61,5s-8.33,4.11-8.72.44l1.56-.28c.22,2,5.39,2.5,5.44,0-.56-3.11-6.28-.83-6.56-5-.22-3.17,4.56-3.5,6.78-2.33.67.39,1.17,1.06,1.33,1.94"
                              />
                            </g>
                            <g id="o">
                              <path
                                id="R-6"
                                className="cls-7kl"
                                d="m355.83,759.77v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                              />
                              <path
                                id="af-7"
                                className="cls-7kl"
                                d="m365.39,761.83c4.94-.22,2.94,6.44,3.33,10.78h-1.78v-6.72c0-1.72-.44-2.72-2.17-2.67-4,.11-2.44,5.67-2.72,9.39h-1.78l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.61-1.22,1.61-1.94,3.39-2"
                              />
                              <path
                                id="ak-2"
                                className="cls-7kl"
                                d="m375.61,759.6c-1.72-.5-2.33.56-2.11,2.44h2.11v1.28h-2.11v9.28h-1.72v-9.28h-1.5v-1.28h1.5c-.39-2.89.94-4.56,3.83-3.78v1.33"
                              />
                              <path
                                id="ae-7"
                                className="cls-7kl"
                                d="m381.11,761.83c3.44-.06,4.72,2.06,4.72,5.5.06,3.5-1.5,5.5-4.78,5.5s-4.61-2.17-4.67-5.5c0-3.67,1.56-5.5,4.72-5.5Zm-.06,9.67c2.44.06,2.94-1.72,2.94-4.17s-.44-4.17-2.83-4.17-2.94,1.78-2.94,4.17.56,4.11,2.83,4.17"
                              />
                              <path
                                id="V-8"
                                className="cls-7kl"
                                d="m393,763.55c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                              />
                              <path
                                id="an"
                                className="cls-7kl"
                                d="m404.94,763.21c-3.72.17-2.11,5.83-2.44,9.39h-1.72v-6.72c0-1.61-.28-2.78-1.94-2.67-3.67.22-2.11,5.78-2.44,9.39h-1.72l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.56-2.44,5.5-2.78,5.94,0,.61-1.17,1.5-1.94,3.22-2,4.72-.11,2.61,6.61,3.06,10.78h-1.72v-6.72c0-1.61-.28-2.72-1.94-2.67"
                              />
                              <path
                                id="Y-13"
                                className="cls-7kl"
                                d="m415.44,761.83c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="U-3"
                                className="cls-7kl"
                                d="m425.72,761.83c1.72,0,2.67.72,3.33,1.83l-.06-5.56h1.78l.06,14.5h-1.67c-.11-.56,0-1.28-.17-1.72-.56,1.28-1.56,1.94-3.28,1.94-2.94,0-3.83-2.17-3.89-5.44,0-3.67,1.28-5.56,3.89-5.56Zm.5,1.33c-2.22,0-2.56,1.89-2.56,4.17s.33,4.11,2.5,4.11c2.33,0,2.83-1.78,2.83-4.22s-.5-4.11-2.78-4.06"
                              />
                            </g>
                            <path
                              className="cls-2kl"
                              d="m630.14,749.18l-.08,1-.24,1-.4.94-.53.87-.66.78-.78.67-.87.53-.95.4-1,.23-1,.1h-62.67v-1h62.64l.88-.08.84-.2.8-.34.73-.45.65-.56.56-.65.45-.74.32-.8.2-.83.08-.88v-103h1l.03,103.01Zm0-124.36h-1v-86.42h1v86.42Z"
                            />
                            <path
                              className="cls-5kl"
                              d="m630.14,538.42h-1v-.52h1v.52Z"
                            />
                            <path
                              className="cls-2kl"
                              d="m630.16,538.44h-1.05v-.56h1.05v.56Zm-1-.5v.45h.95v-.45h-.95Z"
                            />
                            <path
                              className="cls-5kl"
                              d="m560.47,759.8l-14.26-4.62,14.26-4.65v9.27Z"
                            />
                            <path
                              className="cls-2kl"
                              d="m560.97,760.48l-16.38-5.3,16.38-5.34v10.64Zm-13.13-5.3l12.13,3.93v-7.9l-12.13,3.97Z"
                            />
                            <g id="p">
                              <path
                                id="ao"
                                className="cls-6kl"
                                d="m569.93,639.04h-3.01l-2.17-9.68-2.17,9.68h-3.06l-3.16-12.25h2.62l2.17,9.83,2.22-9.83h2.86l2.12,9.83,2.17-9.83h2.57"
                              />
                              <path
                                id="ap"
                                className="cls-6kl"
                                d="m581.88,636.67c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="aq"
                                className="cls-6kl"
                                d="m589.29,629.46c2.57,0,3.46,2.12,3.46,4.84s-.94,4.89-3.6,4.94c-1.48.05-2.27-.74-2.86-1.73l-.1,1.53h-2.37l.05-12.89h2.47v5.14c.54-1.14,1.43-1.83,2.96-1.83Zm-1.04,8.1c1.53,0,1.98-1.33,1.98-3.26s-.35-3.11-1.93-3.11-2.02,1.38-2.02,3.21.4,3.16,1.98,3.16"
                              />
                              <path
                                id="ar"
                                className="cls-6kl"
                                d="m600.2,632.22c.05-1.43-3.46-1.68-3.51-.2.74,2.27,5.83.35,5.88,4.25.05,4.1-8.1,3.75-8.49.44l2.12-.35c.2.94.99,1.23,2.17,1.23,1.63.4,2.81-1.48,1.19-2.02-2.12-.69-5.04-.54-5.14-3.26-.1-3.95,7.6-3.65,7.95-.35"
                              />
                              <path
                                id="as"
                                className="cls-6kl"
                                d="m604.55,627.98v-1.83h2.47v1.83h-2.47Zm0,11.06v-9.38h2.47v9.38h-2.47"
                              />
                              <path
                                id="at"
                                className="cls-6kl"
                                d="m613.93,638.89c-1.78.69-4.3.35-4.3-2.07v-5.53h-1.19v-1.63h1.33l.74-2.22h1.53v2.22h1.78v1.63h-1.78v4.89c-.05,1.14.79,1.53,1.88,1.23v1.48"
                              />
                              <path
                                id="ap-2"
                                className="cls-6kl"
                                d="m623.26,636.67c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                            </g>
                            <g id="q">
                              <path
                                id="au"
                                className="cls-6kl"
                                d="m635.61,631.63c-1.93-.59-2.96.64-2.96,2.81v4.59h-2.47l-.05-9.38h2.32c.1.64-.05,1.43.15,1.98.3-1.38,1.33-2.62,3.01-2.02v2.02"
                              />
                              <path
                                id="ap-3"
                                className="cls-6kl"
                                d="m644.99,636.67c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="av"
                                className="cls-6kl"
                                d="m656.6,631.29c-2.62.44-1.38,4.94-1.68,7.75h-2.42v-5.28c.05-1.33-.25-2.42-1.43-2.47-2.62.49-1.33,4.94-1.63,7.75h-2.47l-.05-9.38h2.32c.1.59-.05,1.38.15,1.88.49-2.62,4.99-2.77,5.33,0,.64-1.09,1.19-2.12,2.91-2.07,4.05.05,2.52,5.73,2.81,9.58h-2.42v-5.28c-.05-1.23-.25-2.37-1.43-2.47"
                              />
                              <path
                                id="aw"
                                className="cls-6kl"
                                d="m666.97,629.46c3.16,0,4.74,1.78,4.74,4.89s-1.73,4.89-4.79,4.89-4.69-1.78-4.69-4.89,1.68-4.89,4.74-4.89Zm-.05,8.1c1.73,0,2.22-1.38,2.22-3.21,0-1.98-.49-3.21-2.12-3.21s-2.22,1.28-2.22,3.21c0,1.78.49,3.21,2.12,3.21"
                              />
                              <path
                                id="ax"
                                className="cls-6kl"
                                d="m678.67,639.04h-2.91l-3.36-9.38h2.62l2.22,7.41,2.37-7.41h2.57"
                              />
                              <path
                                id="ap-4"
                                className="cls-6kl"
                                d="m691.36,636.67c-.64,1.48-1.83,2.62-4.05,2.57-2.96-.1-4.4-1.83-4.4-4.94s1.48-4.84,4.44-4.84,4.1,2.22,4.15,5.33h-6.02c0,1.53.4,2.72,1.93,2.77.89,0,1.48-.35,1.68-1.09l2.27.2Zm-2.22-3.41c.25-2.27-2.81-3.11-3.46-1.04-.1.3-.2.64-.2,1.04h3.65"
                              />
                              <path
                                id="ay"
                                className="cls-6kl"
                                d="m696.45,629.46c1.53-.05,2.27.74,2.86,1.68l-.05-4.99h2.47l.05,12.89h-2.37c-.1-.49,0-1.14-.15-1.53-.54,1.14-1.43,1.73-3.01,1.73-2.57,0-3.36-2.22-3.41-4.89,0-2.77.94-4.79,3.6-4.89Zm.84,8.1c1.63,0,1.98-1.48,2.02-3.26.05-1.83-.44-3.16-2.02-3.16s-1.88,1.48-1.93,3.21c0,2.12.64,3.21,1.93,3.21"
                              />
                            </g>
                            <path className="cls-1kl" d="m392.94,820.26v57.6" />
                            <path
                              className="cls-2kl"
                              d="m393.44,820.28h-1v-.52h1v.52Z"
                            />
                            <path
                              className="cls-3kl"
                              d="m392.94,892.62l-4.63-14.27h9.27l-4.64,14.27Z"
                            />
                            <path
                              className="cls-1kl"
                              d="m629.24,129.68v36.43c0,.18-.15.33-.33.33,0,0,0,0-.01,0-.18,0-.33.15-.33.33h0v20.51"
                            />
                            <path
                              className="cls-2kl"
                              d="m629.74,129.7h-1v-.5h1v.5Z"
                            />
                            <path
                              className="cls-3kl"
                              d="m628.57,202.08l-4.63-14.3h9.26l-4.63,14.3Z"
                            />
                            <path
                              className="cls-4kl"
                              d="m477.44,210.18c0-3.31,2.69-6,6-6h290.27c3.31,0,6,2.69,6,6v116.2c0,3.31-2.69,6-6,6h-290.27c-3.31,0-6-2.69-6-6v-116.2Z"
                            />
                            <g id="r">
                              <path
                                id="az"
                                className="cls-7kl"
                                d="m492.26,260.21v-15.31h2.04v13.64h7.72v1.67h-9.75"
                              />
                              <path
                                id="ae-8"
                                className="cls-7kl"
                                d="m508.93,248.24c3.83-.06,5.25,2.28,5.25,6.11.06,3.89-1.67,6.11-5.31,6.11s-5.12-2.41-5.19-6.11c0-4.07,1.73-6.11,5.25-6.11Zm-.06,10.74c2.72.06,3.27-1.91,3.27-4.63s-.49-4.63-3.15-4.63-3.27,1.98-3.27,4.63.62,4.57,3.15,4.63"
                              />
                              <path
                                id="ag-3"
                                className="cls-7kl"
                                d="m521.03,249.78c-2.47,0-2.96,2.04-2.96,4.51s.56,4.63,2.96,4.63c1.48,0,2.53-.86,2.65-2.35l1.98.12c-.37,2.28-1.91,3.77-4.57,3.77-3.64,0-4.69-2.53-5.06-6.11-.62-5.74,6.23-8.09,9.07-3.95.25.43.31.86.43,1.36l-1.98.19c-.25-1.3-.99-2.16-2.53-2.16"
                              />
                              <path
                                id="W-7"
                                className="cls-7kl"
                                d="m534.91,257.99c-.93,1.3-1.91,2.53-4.2,2.47-2.22-.06-3.58-1.3-3.58-3.52-.06-3.95,3.89-3.89,7.72-3.89.19-2.16-.56-3.33-2.53-3.33-1.48.06-2.53.43-2.59,1.91l-2.04-.19c.31-2.28,2.04-3.21,4.69-3.21s4.44,1.23,4.44,3.95v5.06c-.06,1.23.43,1.98,1.73,1.67v1.23c-1.91.56-3.77-.12-3.64-2.16Zm-5.74-1.05c0,1.23.74,2.04,1.98,2.04,2.53-.19,3.89-1.79,3.7-4.57-2.65.12-5.68-.31-5.68,2.53"
                              />
                              <path
                                id="ad-4"
                                className="cls-7kl"
                                d="m540.04,260.21v-16.11h1.98v16.11h-1.98"
                              />
                            </g>
                            <g id="s">
                              <path
                                id="aa-2"
                                className="cls-7kl"
                                d="m551.64,260.21v-15.31h2.1v15.31h-2.1"
                              />
                              <path
                                id="aA"
                                className="cls-7kl"
                                d="m557.63,244.9c7.28-.43,13.33.49,13.15,7.53-.19,4.81-2.65,7.78-7.47,7.78h-5.68v-15.31Zm2.04,13.64c5.49.49,9.01-.99,9.01-6.11s-3.7-6.23-9.01-5.86v11.98"
                              />
                              <path
                                id="aB"
                                className="cls-7kl"
                                d="m583.19,248.55c-.31-2.96-7.59-3.33-7.65.12.86,4.63,9.75.86,10.06,7.35.19,4.81-7.47,5.37-10.8,3.4-1.05-.62-1.73-1.6-2.04-2.84l2.04-.43c.31,3.46,8.7,3.89,8.7-.06,0-4.81-9.57-.86-10-7.28-.31-5.06,8.95-5.19,11.05-2.1.31.43.49.99.68,1.54"
                              />
                              <path
                                id="aC"
                                className="cls-7kl"
                                d="m586.58,260.46l4.44-16.36h1.73l-4.44,16.36h-1.73"
                              />
                            </g>
                            <g id="t">
                              <path
                                id="Q-3"
                                className="cls-7kl"
                                d="m615.35,260.21h-2.47l-3.46-12.96-3.4,12.96h-2.47l-4.51-15.31h2.16l3.64,13.46.93-3.95,2.65-9.51h1.98l3.64,13.46,3.64-13.46h2.16"
                              />
                              <path
                                id="Y-14"
                                className="cls-7kl"
                                d="m625.59,248.24c3.89,0,5.31,2.59,5.19,6.54h-8.33c0,2.47.86,4.14,3.27,4.2,1.6.06,2.65-.74,3.02-1.79l1.73.49c-.68,1.73-2.28,2.78-4.75,2.78-3.58,0-5.43-2.28-5.37-6.17.06-3.77,1.6-6.05,5.25-6.05Zm3.21,5c.37-3.7-4.69-4.75-5.99-1.73-.19.43-.37,1.05-.37,1.73h6.36"
                              />
                              <path
                                id="aD"
                                className="cls-7kl"
                                d="m638.86,248.24c3.27,0,4.26,2.41,4.32,6.05,0,4.07-1.42,6.17-4.32,6.17-1.91-.06-3.02-.68-3.64-2.1l-.12,1.85h-1.91l.06-16.11h1.98v6.23c.62-1.42,1.73-2.1,3.64-2.1Zm-.49,10.74c2.47,0,2.78-2.1,2.78-4.63s-.31-4.63-2.78-4.57c-2.59,0-3.15,1.98-3.15,4.69s.62,4.51,3.15,4.51"
                              />
                              <path
                                id="an-2"
                                className="cls-7kl"
                                d="m657.07,249.78c-4.14.19-2.35,6.48-2.72,10.43h-1.91v-7.47c0-1.79-.31-3.09-2.16-2.96-4.07.25-2.35,6.42-2.72,10.43h-1.91l-.06-11.73h1.85c.06.62-.06,1.48.12,1.98.62-2.72,6.11-3.09,6.6,0,.68-1.3,1.67-2.16,3.58-2.22,5.25-.12,2.9,7.35,3.4,11.98h-1.91v-7.47c0-1.79-.31-3.02-2.16-2.96"
                              />
                              <path
                                id="W-8"
                                className="cls-7kl"
                                d="m671.27,257.99c-.93,1.3-1.91,2.53-4.2,2.47-2.22-.06-3.58-1.3-3.58-3.52-.06-3.95,3.89-3.89,7.72-3.89.19-2.16-.56-3.33-2.53-3.33-1.48.06-2.53.43-2.59,1.91l-2.04-.19c.31-2.28,2.04-3.21,4.69-3.21s4.44,1.23,4.44,3.95v5.06c-.06,1.23.43,1.98,1.73,1.67v1.23c-1.91.56-3.77-.12-3.64-2.16Zm-5.74-1.05c0,1.23.74,2.04,1.98,2.04,2.53-.19,3.89-1.79,3.7-4.57-2.65.12-5.68-.31-5.68,2.53"
                              />
                              <path
                                id="ab-7"
                                className="cls-7kl"
                                d="m683.25,251.38c-.19-2.1-5.31-2.35-5.37,0,.93,3.27,7.1.74,7.35,5.56s-9.26,4.57-9.69.49l1.73-.31c.25,2.22,5.99,2.78,6.05,0-.62-3.46-6.98-.93-7.28-5.56-.25-3.52,5.06-3.89,7.53-2.59.74.43,1.3,1.17,1.48,2.16"
                              />
                              <path
                                id="S-11"
                                className="cls-7kl"
                                d="m689.67,257.31c-.12,1.48,1.11,1.79,2.35,1.36v1.48c-2.04.62-4.32.31-4.32-2.41v-7.84h-1.36v-1.42h1.48l.56-2.65h1.3v2.65h2.16v1.42h-2.16v7.41"
                              />
                              <path
                                id="Y-15"
                                className="cls-7kl"
                                d="m698.37,248.24c3.89,0,5.31,2.59,5.19,6.54h-8.33c0,2.47.86,4.14,3.27,4.2,1.6.06,2.65-.74,3.02-1.79l1.73.49c-.68,1.73-2.28,2.78-4.75,2.78-3.58,0-5.43-2.28-5.37-6.17.06-3.77,1.6-6.05,5.25-6.05Zm3.21,5c.37-3.7-4.69-4.75-5.99-1.73-.19.43-.37,1.05-.37,1.73h6.36"
                              />
                              <path
                                id="V-9"
                                className="cls-7kl"
                                d="m711.58,250.15c-4.81-.99-3.27,5.62-3.52,10.06h-1.98l-.06-11.73h1.85c.06.74-.06,1.79.12,2.41.37-1.67,1.42-3.02,3.58-2.53v1.79"
                              />
                            </g>
                            <g id="u">
                              <path
                                id="S-12"
                                className="cls-7kl"
                                d="m721.7,257.31c-.12,1.48,1.11,1.79,2.35,1.36v1.48c-2.04.62-4.32.31-4.32-2.41v-7.84h-1.36v-1.42h1.48l.56-2.65h1.3v2.65h2.16v1.42h-2.16v7.41"
                              />
                              <path
                                id="ae-9"
                                className="cls-7kl"
                                d="m730.41,248.24c3.83-.06,5.25,2.28,5.25,6.11.06,3.89-1.67,6.11-5.31,6.11s-5.12-2.41-5.19-6.11c0-4.07,1.73-6.11,5.25-6.11Zm-.06,10.74c2.72.06,3.27-1.91,3.27-4.63s-.49-4.63-3.15-4.63-3.27,1.98-3.27,4.63.62,4.57,3.15,4.63"
                              />
                            </g>
                            <g id="v">
                              <path
                                id="aD-2"
                                className="cls-7kl"
                                d="m749.85,248.24c3.27,0,4.26,2.41,4.32,6.05,0,4.07-1.42,6.17-4.32,6.17-1.91-.06-3.02-.68-3.64-2.1l-.12,1.85h-1.91l.06-16.11h1.98v6.23c.62-1.42,1.73-2.1,3.64-2.1Zm-.49,10.74c2.47,0,2.78-2.1,2.78-4.63s-.31-4.63-2.78-4.57c-2.59,0-3.15,1.98-3.15,4.69s.62,4.51,3.15,4.51"
                              />
                              <path
                                id="Y-16"
                                className="cls-7kl"
                                d="m761.27,248.24c3.89,0,5.31,2.59,5.19,6.54h-8.33c0,2.47.86,4.14,3.27,4.2,1.6.06,2.65-.74,3.02-1.79l1.73.49c-.68,1.73-2.28,2.78-4.75,2.78-3.58,0-5.43-2.28-5.37-6.17.06-3.77,1.6-6.05,5.25-6.05Zm3.21,5c.37-3.7-4.69-4.75-5.99-1.73-.19.43-.37,1.05-.37,1.73h6.36"
                              />
                            </g>
                            <g id="w">
                              <path
                                id="R-7"
                                className="cls-7kl"
                                d="m587.29,272.62v-1.85h1.98v1.85h-1.98Zm0,14.26v-11.73h1.98v11.73h-1.98"
                              />
                              <path
                                id="af-8"
                                className="cls-7kl"
                                d="m597.91,274.9c5.49-.25,3.27,7.16,3.7,11.98h-1.98v-7.47c0-1.91-.49-3.02-2.41-2.96-4.44.12-2.72,6.3-3.02,10.43h-1.98l-.06-11.73h1.85c.06.62-.06,1.48.12,1.98.68-1.36,1.79-2.16,3.77-2.22"
                              />
                              <path
                                id="ai-2"
                                className="cls-7kl"
                                d="m609.7,286.88h-2.35l-4.26-11.73h2.1l3.33,10.19,3.46-10.19h2.1"
                              />
                              <path
                                id="R-8"
                                className="cls-7kl"
                                d="m615.62,272.62v-1.85h1.98v1.85h-1.98Zm0,14.26v-11.73h1.98v11.73h-1.98"
                              />
                              <path
                                id="ae-10"
                                className="cls-7kl"
                                d="m625.19,274.9c3.83-.06,5.25,2.28,5.25,6.11.06,3.89-1.67,6.11-5.31,6.11s-5.12-2.41-5.19-6.11c0-4.07,1.73-6.11,5.25-6.11Zm-.06,10.74c2.72.06,3.27-1.91,3.27-4.63s-.49-4.63-3.15-4.63-3.27,1.98-3.27,4.63.62,4.57,3.15,4.63"
                              />
                              <path
                                id="ad-5"
                                className="cls-7kl"
                                d="m632.85,286.88v-16.11h1.98v16.11h-1.98"
                              />
                              <path
                                id="ai-3"
                                className="cls-7kl"
                                d="m642.91,286.88h-2.35l-4.26-11.73h2.1l3.33,10.19,3.46-10.19h2.1"
                              />
                              <path
                                id="Y-17"
                                className="cls-7kl"
                                d="m653.53,274.9c3.89,0,5.31,2.59,5.19,6.54h-8.33c0,2.47.86,4.14,3.27,4.2,1.6.06,2.65-.74,3.02-1.79l1.73.49c-.68,1.73-2.28,2.78-4.75,2.78-3.58,0-5.43-2.28-5.37-6.17.06-3.77,1.6-6.05,5.25-6.05Zm3.21,5c.37-3.7-4.69-4.75-5.99-1.73-.19.43-.37,1.05-.37,1.73h6.36"
                              />
                              <path
                                id="U-4"
                                className="cls-7kl"
                                d="m664.95,274.9c1.91,0,2.96.8,3.7,2.04l-.06-6.17h1.98l.06,16.11h-1.85c-.12-.62,0-1.42-.19-1.91-.62,1.42-1.73,2.16-3.64,2.16-3.27,0-4.26-2.41-4.32-6.05,0-4.07,1.42-6.17,4.32-6.17Zm.56,1.48c-2.47,0-2.84,2.1-2.84,4.63s.37,4.57,2.78,4.57c2.59,0,3.15-1.98,3.15-4.69s-.56-4.57-3.09-4.51"
                              />
                            </g>
                            <path className="cls-1kl" d="m628.57,333.38v58.95" />
                            <path
                              className="cls-2kl"
                              d="m629.07,333.38h-1v-.5h1v.5Z"
                            />
                            <path
                              className="cls-3kl"
                              d="m628.57,407.1l-4.64-14.27h9.26l-4.62,14.27Z"
                            />
                            <path
                              className="cls-4kl"
                              d="m478.49,415.21c0-3.31,2.69-6,6-6h290.3c3.31,0,6,2.69,6,6v116.2c0,3.31-2.69,6-6,6h-290.3c-3.31,0-6-2.69-6-6v-116.2Z"
                            />
                            <g id="x">
                              <path
                                id="Z-2"
                                className="cls-7kl"
                                d="m515.19,466.75v-13.78h10.39v1.56h-8.56v4.39h8v1.5h-8v4.83h9v1.5h-10.83"
                              />
                              <path
                                id="Q-4"
                                className="cls-7kl"
                                d="m541.63,466.75h-2.22l-3.11-11.67-3.06,11.67h-2.22l-4.06-13.78h1.94l3.28,12.11.83-3.56,2.39-8.56h1.78l3.28,12.11,3.28-12.11h1.94"
                              />
                              <path
                                id="aa-3"
                                className="cls-7kl"
                                d="m547.52,466.75v-13.78h1.89v13.78h-1.89"
                              />
                            </g>
                            <g id="y">
                              <path
                                id="aE"
                                className="cls-7kl"
                                d="m567.58,466.75l-5.5-6.67-1.78,1.39v5.28h-1.83v-13.78h1.83v6.89l6.61-6.89h2.22l-5.83,6,6.61,7.78h-2.33"
                              />
                              <path
                                id="aF"
                                className="cls-7kl"
                                d="m581.52,466.75l-1.56-4h-6.28l-1.56,4h-1.94l5.61-13.78h2.11l5.5,13.78h-1.89Zm-2.11-5.5l-2.61-6.83c-.67,2.5-1.72,4.56-2.56,6.83h5.17"
                              />
                              <path
                                id="ac-2"
                                className="cls-7kl"
                                d="m596.8,466.75l.11-12.11c-1.28,4.22-3,8.06-4.44,12.11h-1.28l-4.5-12.11.06,12.11h-1.61v-13.78h2.44l4.28,11.72c1.17-4.17,2.83-7.78,4.22-11.72h2.39v13.78h-1.67"
                              />
                            </g>
                            <g id="z">
                              <path
                                id="R-9"
                                className="cls-7kl"
                                d="m606.96,453.91v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                              />
                              <path
                                id="af-9"
                                className="cls-7kl"
                                d="m616.52,455.97c4.94-.22,2.94,6.44,3.33,10.78h-1.78v-6.72c0-1.72-.44-2.72-2.17-2.67-4,.11-2.44,5.67-2.72,9.39h-1.78l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.61-1.22,1.61-1.94,3.39-2"
                              />
                              <path
                                id="R-10"
                                className="cls-7kl"
                                d="m622.46,453.91v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                              />
                              <path
                                id="S-13"
                                className="cls-7kl"
                                d="m628.8,464.13c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                              />
                              <path
                                id="R-11"
                                className="cls-7kl"
                                d="m632.41,453.91v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                              />
                              <path
                                id="W-9"
                                className="cls-7kl"
                                d="m643.3,464.75c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                              />
                              <path
                                id="S-14"
                                className="cls-7kl"
                                d="m649.85,464.13c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                              />
                              <path
                                id="Y-18"
                                className="cls-7kl"
                                d="m657.69,455.97c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="ab-8"
                                className="cls-7kl"
                                d="m670.74,458.8c-.17-1.89-4.78-2.11-4.83,0,.83,2.94,6.39.67,6.61,5s-8.33,4.11-8.72.44l1.56-.28c.22,2,5.39,2.5,5.44,0-.56-3.11-6.28-.83-6.56-5-.22-3.17,4.56-3.5,6.78-2.33.67.39,1.17,1.06,1.33,1.94"
                              />
                            </g>
                            <g id="A">
                              <path
                                id="X-3"
                                className="cls-7kl"
                                d="m690.24,466.75h-2l-2.22-9.11-2.28,9.11h-2l-3-10.56h1.78l2.17,9.11,2.39-9.11h1.89l2.28,9.11,2.33-9.11h1.72"
                              />
                              <path
                                id="Y-19"
                                className="cls-7kl"
                                d="m698.74,455.97c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="aD-3"
                                className="cls-7kl"
                                d="m710.69,455.97c2.94,0,3.83,2.17,3.89,5.44,0,3.67-1.28,5.56-3.89,5.56-1.72-.06-2.72-.61-3.28-1.89l-.11,1.67h-1.72l.06-14.5h1.78v5.61c.56-1.28,1.56-1.89,3.28-1.89Zm-.44,9.67c2.22,0,2.5-1.89,2.5-4.17s-.28-4.17-2.5-4.11c-2.33,0-2.83,1.78-2.83,4.22s.56,4.06,2.83,4.06"
                              />
                              <path
                                id="ab-9"
                                className="cls-7kl"
                                d="m722.91,458.8c-.17-1.89-4.78-2.11-4.83,0,.83,2.94,6.39.67,6.61,5s-8.33,4.11-8.72.44l1.56-.28c.22,2,5.39,2.5,5.44,0-.56-3.11-6.28-.83-6.56-5-.22-3.17,4.56-3.5,6.78-2.33.67.39,1.17,1.06,1.33,1.94"
                              />
                              <path
                                id="R-12"
                                className="cls-7kl"
                                d="m726.74,453.91v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                              />
                              <path
                                id="S-15"
                                className="cls-7kl"
                                d="m733.08,464.13c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                              />
                              <path
                                id="Y-20"
                                className="cls-7kl"
                                d="m740.91,455.97c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                            </g>
                            <g id="B">
                              <path
                                id="V-10"
                                className="cls-7kl"
                                d="m600.85,481.69c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                              />
                              <path
                                id="Y-21"
                                className="cls-7kl"
                                d="m606.69,479.97c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="an-3"
                                className="cls-7kl"
                                d="m623.91,481.36c-3.72.17-2.11,5.83-2.44,9.39h-1.72v-6.72c0-1.61-.28-2.78-1.94-2.67-3.67.22-2.11,5.78-2.44,9.39h-1.72l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.56-2.44,5.5-2.78,5.94,0,.61-1.17,1.5-1.94,3.22-2,4.72-.11,2.61,6.61,3.06,10.78h-1.72v-6.72c0-1.61-.28-2.72-1.94-2.67"
                              />
                              <path
                                id="ae-11"
                                className="cls-7kl"
                                d="m634.41,479.97c3.44-.06,4.72,2.06,4.72,5.5.06,3.5-1.5,5.5-4.78,5.5s-4.61-2.17-4.67-5.5c0-3.67,1.56-5.5,4.72-5.5Zm-.06,9.67c2.44.06,2.94-1.72,2.94-4.17s-.44-4.17-2.83-4.17-2.94,1.78-2.94,4.17.56,4.11,2.83,4.17"
                              />
                              <path
                                id="ai-4"
                                className="cls-7kl"
                                d="m645.96,490.75h-2.11l-3.83-10.56h1.89l3,9.17,3.11-9.17h1.89"
                              />
                              <path
                                id="W-10"
                                className="cls-7kl"
                                d="m657.8,488.75c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                              />
                              <path
                                id="ad-6"
                                className="cls-7kl"
                                d="m662.41,490.75v-14.5h1.78v14.5h-1.78"
                              />
                            </g>
                            <path
                              className="cls-4kl"
                              d="m.5,210.18c0-3.31,2.69-6,6-6h290.3c3.31,0,6,2.69,6,6v116.2c0,3.31-2.69,6-6,6H6.5c-3.31,0-6-2.69-6-6v-116.2Z"
                            />
                            <g id="C">
                              <path
                                id="az-2"
                                className="cls-7kl"
                                d="m39.95,248.59v-13.78h1.83v12.28h6.94v1.5h-8.78"
                              />
                              <path
                                id="ae-12"
                                className="cls-7kl"
                                d="m54.95,237.81c3.44-.06,4.72,2.06,4.72,5.5.06,3.5-1.5,5.5-4.78,5.5s-4.61-2.17-4.67-5.5c0-3.67,1.56-5.5,4.72-5.5Zm-.06,9.67c2.44.06,2.94-1.72,2.94-4.17s-.44-4.17-2.83-4.17-2.94,1.78-2.94,4.17.56,4.11,2.83,4.17"
                              />
                              <path
                                id="ag-4"
                                className="cls-7kl"
                                d="m65.84,239.2c-2.22,0-2.67,1.83-2.67,4.06s.5,4.17,2.67,4.17c1.33,0,2.28-.78,2.39-2.11l1.78.11c-.33,2.06-1.72,3.39-4.11,3.39-3.28,0-4.22-2.28-4.56-5.5-.56-5.17,5.61-7.28,8.17-3.56.22.39.28.78.39,1.22l-1.78.17c-.22-1.17-.89-1.94-2.28-1.94"
                              />
                              <path
                                id="W-11"
                                className="cls-7kl"
                                d="m78.34,246.59c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                              />
                              <path
                                id="ad-7"
                                className="cls-7kl"
                                d="m82.95,248.59v-14.5h1.78v14.5h-1.78"
                              />
                            </g>
                            <g id="D">
                              <path
                                id="aa-4"
                                className="cls-7kl"
                                d="m93.39,248.59v-13.78h1.89v13.78h-1.89"
                              />
                              <path
                                id="aA-2"
                                className="cls-7kl"
                                d="m98.78,234.81c6.56-.39,12,.44,11.83,6.78-.17,4.33-2.39,7-6.72,7h-5.11v-13.78Zm1.83,12.28c4.94.44,8.11-.89,8.11-5.5s-3.33-5.61-8.11-5.28v10.78"
                              />
                              <path
                                id="aB-2"
                                className="cls-7kl"
                                d="m121.78,238.09c-.28-2.67-6.83-3-6.89.11.78,4.17,8.78.78,9.06,6.61.17,4.33-6.72,4.83-9.72,3.06-.94-.56-1.56-1.44-1.83-2.56l1.83-.39c.28,3.11,7.83,3.5,7.83-.06,0-4.33-8.61-.78-9-6.56-.28-4.56,8.06-4.67,9.94-1.89.28.39.44.89.61,1.39"
                              />
                            </g>
                            <g id="E">
                              <path
                                id="R-13"
                                className="cls-7kl"
                                d="m131.73,235.76v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                              />
                              <path
                                id="af-10"
                                className="cls-7kl"
                                d="m141.28,237.81c4.94-.22,2.94,6.44,3.33,10.78h-1.78v-6.72c0-1.72-.44-2.72-2.17-2.67-4,.11-2.44,5.67-2.72,9.39h-1.78l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.61-1.22,1.61-1.94,3.39-2"
                              />
                              <path
                                id="ak-3"
                                className="cls-7kl"
                                d="m151.5,235.59c-1.72-.5-2.33.56-2.11,2.44h2.11v1.28h-2.11v9.28h-1.72v-9.28h-1.5v-1.28h1.5c-.39-2.89.94-4.56,3.83-3.78v1.33"
                              />
                              <path
                                id="ae-13"
                                className="cls-7kl"
                                d="m157,237.81c3.44-.06,4.72,2.06,4.72,5.5.06,3.5-1.5,5.5-4.78,5.5s-4.61-2.17-4.67-5.5c0-3.67,1.56-5.5,4.72-5.5Zm-.06,9.67c2.44.06,2.94-1.72,2.94-4.17s-.44-4.17-2.83-4.17-2.94,1.78-2.94,4.17.56,4.11,2.83,4.17"
                              />
                              <path
                                id="V-11"
                                className="cls-7kl"
                                d="m168.89,239.53c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                              />
                              <path
                                id="an-4"
                                className="cls-7kl"
                                d="m180.84,239.2c-3.72.17-2.11,5.83-2.44,9.39h-1.72v-6.72c0-1.61-.28-2.78-1.94-2.67-3.67.22-2.11,5.78-2.44,9.39h-1.72l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.56-2.44,5.5-2.78,5.94,0,.61-1.17,1.5-1.94,3.22-2,4.72-.11,2.61,6.61,3.06,10.78h-1.72v-6.72c0-1.61-.28-2.72-1.94-2.67"
                              />
                              <path
                                id="Y-22"
                                className="cls-7kl"
                                d="m191.34,237.81c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="U-5"
                                className="cls-7kl"
                                d="m201.61,237.81c1.72,0,2.67.72,3.33,1.83l-.06-5.56h1.78l.06,14.5h-1.67c-.11-.56,0-1.28-.17-1.72-.56,1.28-1.56,1.94-3.28,1.94-2.94,0-3.83-2.17-3.89-5.44,0-3.67,1.28-5.56,3.89-5.56Zm.5,1.33c-2.22,0-2.56,1.89-2.56,4.17s.33,4.11,2.5,4.11c2.33,0,2.83-1.78,2.83-4.22s-.5-4.11-2.78-4.06"
                              />
                              <path
                                id="aG"
                                className="cls-7kl"
                                d="m211.78,246.48c.06,1.89,0,3.61-.78,4.67h-1.22c.5-.72.94-1.44.94-2.56h-.89v-2.11h1.94"
                              />
                            </g>
                            <g id="F">
                              <path
                                id="S-16"
                                className="cls-7kl"
                                d="m222.39,245.98c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                              />
                              <path
                                id="R-14"
                                className="cls-7kl"
                                d="m226,235.76v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                              />
                              <path
                                id="ag-5"
                                className="cls-7kl"
                                d="m234.39,239.2c-2.22,0-2.67,1.83-2.67,4.06s.5,4.17,2.67,4.17c1.33,0,2.28-.78,2.39-2.11l1.78.11c-.33,2.06-1.72,3.39-4.11,3.39-3.28,0-4.22-2.28-4.56-5.5-.56-5.17,5.61-7.28,8.17-3.56.22.39.28.78.39,1.22l-1.78.17c-.22-1.17-.89-1.94-2.28-1.94"
                              />
                              <path
                                id="am-2"
                                className="cls-7kl"
                                d="m247,248.59l-3.56-4.83-1.28,1.06v3.78h-1.78v-14.5h1.78v9.06l4.61-5.11h2.06l-4.28,4.56,4.56,6h-2.11"
                              />
                              <path
                                id="Y-23"
                                className="cls-7kl"
                                d="m254.61,237.81c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="S-17"
                                className="cls-7kl"
                                d="m263.45,245.98c-.11,1.33,1,1.61,2.11,1.22v1.33c-1.83.56-3.89.28-3.89-2.17v-7.06h-1.22v-1.28h1.33l.5-2.39h1.17v2.39h1.94v1.28h-1.94v6.67"
                              />
                            </g>
                            <g id="G">
                              <path
                                id="V-12"
                                className="cls-7kl"
                                d="m49.56,263.53c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                              />
                              <path
                                id="W-12"
                                className="cls-7kl"
                                d="m57.67,270.59c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                              />
                              <path
                                id="R-15"
                                className="cls-7kl"
                                d="m62.28,259.76v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                              />
                              <path
                                id="ab-10"
                                className="cls-7kl"
                                d="m72.84,264.65c-.17-1.89-4.78-2.11-4.83,0,.83,2.94,6.39.67,6.61,5s-8.33,4.11-8.72.44l1.56-.28c.22,2,5.39,2.5,5.44,0-.56-3.11-6.28-.83-6.56-5-.22-3.17,4.56-3.5,6.78-2.33.67.39,1.17,1.06,1.33,1.94"
                              />
                              <path
                                id="Y-24"
                                className="cls-7kl"
                                d="m80.89,261.81c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="U-6"
                                className="cls-7kl"
                                d="m91.17,261.81c1.72,0,2.67.72,3.33,1.83l-.06-5.56h1.78l.06,14.5h-1.67c-.11-.56,0-1.28-.17-1.72-.56,1.28-1.56,1.94-3.28,1.94-2.94,0-3.83-2.17-3.89-5.44,0-3.67,1.28-5.56,3.89-5.56Zm.5,1.33c-2.22,0-2.56,1.89-2.56,4.17s.33,4.11,2.5,4.11c2.33,0,2.83-1.78,2.83-4.22s-.5-4.11-2.78-4.06"
                              />
                              <path
                                id="aG-2"
                                className="cls-7kl"
                                d="m101.34,270.48c.06,1.89,0,3.61-.78,4.67h-1.22c.5-.72.94-1.44.94-2.56h-.89v-2.11h1.94"
                              />
                            </g>
                            <g id="H">
                              <path
                                id="ag-6"
                                className="cls-7kl"
                                d="m114,263.2c-2.22,0-2.67,1.83-2.67,4.06s.5,4.17,2.67,4.17c1.33,0,2.28-.78,2.39-2.11l1.78.11c-.33,2.06-1.72,3.39-4.11,3.39-3.28,0-4.22-2.28-4.56-5.5-.56-5.17,5.61-7.28,8.17-3.56.22.39.28.78.39,1.22l-1.78.17c-.22-1.17-.89-1.94-2.28-1.94"
                              />
                              <path
                                id="W-13"
                                className="cls-7kl"
                                d="m126.5,270.59c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                              />
                              <path
                                id="an-5"
                                className="cls-7kl"
                                d="m141.45,263.2c-3.72.17-2.11,5.83-2.44,9.39h-1.72v-6.72c0-1.61-.28-2.78-1.94-2.67-3.67.22-2.11,5.78-2.44,9.39h-1.72l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.56-2.44,5.5-2.78,5.94,0,.61-1.17,1.5-1.94,3.22-2,4.72-.11,2.61,6.61,3.06,10.78h-1.72v-6.72c0-1.61-.28-2.72-1.94-2.67"
                              />
                              <path
                                id="aH"
                                className="cls-7kl"
                                d="m152.78,261.81c3.06.06,3.89,2.28,3.89,5.44s-.89,5.44-3.89,5.56c-1.72,0-2.72-.72-3.33-1.89l.06,5.83h-1.78l-.06-14.72h1.72l.11,1.67c.56-1.17,1.56-1.89,3.28-1.89Zm-.44,9.67c2.22,0,2.5-1.89,2.5-4.17s-.33-4.06-2.5-4.11c-2.33,0-2.83,1.78-2.83,4.22s.56,4.06,2.83,4.06"
                              />
                              <path
                                id="W-14"
                                className="cls-7kl"
                                d="m165.34,270.59c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                              />
                              <path
                                id="R-16"
                                className="cls-7kl"
                                d="m169.95,259.76v-1.67h1.78v1.67h-1.78Zm0,12.83v-10.56h1.78v10.56h-1.78"
                              />
                              <path
                                id="aI"
                                className="cls-7kl"
                                d="m182.84,262.03c-.56,6.94,2.28,16.28-6.11,14.5-1.28-.33-2.11-1.11-2.44-2.39l1.78-.28c.83,2.61,5.56,1.78,4.94-1.56v-1.67c-.61,1.17-1.61,2-3.39,2-3,0-3.78-2.28-3.78-5.33s.89-5.39,3.94-5.44c1.61-.06,2.67.89,3.28,1.94.06-.56,0-1.28.11-1.78h1.67Zm-4.61,9.33c2,0,2.78-1.78,2.78-4.06s-.78-4.17-2.78-4.17c-2.17,0-2.56,1.89-2.56,4.17s.33,4.06,2.56,4.06"
                              />
                              <path
                                id="af-11"
                                className="cls-7kl"
                                d="m190.61,261.81c4.94-.22,2.94,6.44,3.33,10.78h-1.78v-6.72c0-1.72-.44-2.72-2.17-2.67-4,.11-2.44,5.67-2.72,9.39h-1.78l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.61-1.22,1.61-1.94,3.39-2"
                              />
                            </g>
                            <g id="I">
                              <path
                                id="aH-2"
                                className="cls-7kl"
                                d="m212.73,261.81c3.06.06,3.89,2.28,3.89,5.44s-.89,5.44-3.89,5.56c-1.72,0-2.72-.72-3.33-1.89l.06,5.83h-1.78l-.06-14.72h1.72l.11,1.67c.56-1.17,1.56-1.89,3.28-1.89Zm-.44,9.67c2.22,0,2.5-1.89,2.5-4.17s-.33-4.06-2.5-4.11c-2.33,0-2.83,1.78-2.83,4.22s.56,4.06,2.83,4.06"
                              />
                              <path
                                id="W-15"
                                className="cls-7kl"
                                d="m225.28,270.59c-.83,1.17-1.72,2.28-3.78,2.22-2-.06-3.22-1.17-3.22-3.17-.06-3.56,3.5-3.5,6.94-3.5.17-1.94-.5-3-2.28-3-1.33.06-2.28.39-2.33,1.72l-1.83-.17c.28-2.06,1.83-2.89,4.22-2.89s4,1.11,4,3.56v4.56c-.06,1.11.39,1.78,1.56,1.5v1.11c-1.72.5-3.39-.11-3.28-1.94Zm-5.17-.94c0,1.11.67,1.83,1.78,1.83,2.28-.17,3.5-1.61,3.33-4.11-2.39.11-5.11-.28-5.11,2.28"
                              />
                              <path
                                id="aI-2"
                                className="cls-7kl"
                                d="m238.39,262.03c-.56,6.94,2.28,16.28-6.11,14.5-1.28-.33-2.11-1.11-2.44-2.39l1.78-.28c.83,2.61,5.56,1.78,4.94-1.56v-1.67c-.61,1.17-1.61,2-3.39,2-3,0-3.78-2.28-3.78-5.33s.89-5.39,3.94-5.44c1.61-.06,2.67.89,3.28,1.94.06-.56,0-1.28.11-1.78h1.67Zm-4.61,9.33c2,0,2.78-1.78,2.78-4.06s-.78-4.17-2.78-4.17c-2.17,0-2.56,1.89-2.56,4.17s.33,4.06,2.56,4.06"
                              />
                              <path
                                id="Y-25"
                                className="cls-7kl"
                                d="m245.23,261.81c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="ab-11"
                                className="cls-7kl"
                                d="m258.28,264.65c-.17-1.89-4.78-2.11-4.83,0,.83,2.94,6.39.67,6.61,5s-8.33,4.11-8.72.44l1.56-.28c.22,2,5.39,2.5,5.44,0-.56-3.11-6.28-.83-6.56-5-.22-3.17,4.56-3.5,6.78-2.33.67.39,1.17,1.06,1.33,1.94"
                              />
                            </g>
                            <g id="J">
                              <path
                                id="V-13"
                                className="cls-7kl"
                                d="m119.5,287.53c-4.33-.89-2.94,5.06-3.17,9.06h-1.78l-.06-10.56h1.67c.06.67-.06,1.61.11,2.17.33-1.5,1.28-2.72,3.22-2.28v1.61"
                              />
                              <path
                                id="Y-26"
                                className="cls-7kl"
                                d="m125.34,285.81c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="an-6"
                                className="cls-7kl"
                                d="m142.56,287.2c-3.72.17-2.11,5.83-2.44,9.39h-1.72v-6.72c0-1.61-.28-2.78-1.94-2.67-3.67.22-2.11,5.78-2.44,9.39h-1.72l-.06-10.56h1.67c.06.56-.06,1.33.11,1.78.56-2.44,5.5-2.78,5.94,0,.61-1.17,1.5-1.94,3.22-2,4.72-.11,2.61,6.61,3.06,10.78h-1.72v-6.72c0-1.61-.28-2.72-1.94-2.67"
                              />
                              <path
                                id="ae-14"
                                className="cls-7kl"
                                d="m153.06,285.81c3.44-.06,4.72,2.06,4.72,5.5.06,3.5-1.5,5.5-4.78,5.5s-4.61-2.17-4.67-5.5c0-3.67,1.56-5.5,4.72-5.5Zm-.06,9.67c2.44.06,2.94-1.72,2.94-4.17s-.44-4.17-2.83-4.17-2.94,1.78-2.94,4.17.56,4.11,2.83,4.17"
                              />
                              <path
                                id="ai-5"
                                className="cls-7kl"
                                d="m164.61,296.59h-2.11l-3.83-10.56h1.89l3,9.17,3.11-9.17h1.89"
                              />
                              <path
                                id="Y-27"
                                className="cls-7kl"
                                d="m174.17,285.81c3.5,0,4.78,2.33,4.67,5.89h-7.5c0,2.22.78,3.72,2.94,3.78,1.44.06,2.39-.67,2.72-1.61l1.56.44c-.61,1.56-2.06,2.5-4.28,2.5-3.22,0-4.89-2.06-4.83-5.56.06-3.39,1.44-5.44,4.72-5.44Zm2.89,4.5c.33-3.33-4.22-4.28-5.39-1.56-.17.39-.33.94-.33,1.56h5.72"
                              />
                              <path
                                id="U-7"
                                className="cls-7kl"
                                d="m184.45,285.81c1.72,0,2.67.72,3.33,1.83l-.06-5.56h1.78l.06,14.5h-1.67c-.11-.56,0-1.28-.17-1.72-.56,1.28-1.56,1.94-3.28,1.94-2.94,0-3.83-2.17-3.89-5.44,0-3.67,1.28-5.56,3.89-5.56Zm.5,1.33c-2.22,0-2.56,1.89-2.56,4.17s.33,4.11,2.5,4.11c2.33,0,2.83-1.78,2.83-4.22s-.5-4.11-2.78-4.06"
                              />
                            </g>
                            <path
                              className="cls-1kl"
                              d="m151.64,538.4v210.78c0,3.31,2.69,6,6,6h67.28"
                            />
                            <path
                              className="cls-2kl"
                              d="m152.14,538.42h-1v-.52h1v.52Z"
                            />
                            <path
                              className="cls-3kl"
                              d="m239.69,755.18l-14.27,4.62v-9.27l14.27,4.65Z"
                            />
                            <path
                              className="cls-4kl"
                              d="m.5,415.21c0-3.31,2.69-6,6-6h290.3c3.31,0,6,2.69,6,6v116.2c0,3.31-2.69,6-6,6H6.5c-3.31,0-6-2.69-6-6v-116.2Z"
                            />
                            <g id="K">
                              <path
                                id="Q-5"
                                className="cls-7kl"
                                d="m42.1,465.46h-2.47l-3.46-12.96-3.4,12.96h-2.47l-4.51-15.31h2.16l3.64,13.46.93-3.95,2.65-9.51h1.98l3.64,13.46,3.64-13.46h2.16"
                              />
                              <path
                                id="R-17"
                                className="cls-7kl"
                                d="m48.08,451.2v-1.85h1.98v1.85h-1.98Zm0,14.26v-11.73h1.98v11.73h-1.98"
                              />
                              <path
                                id="S-18"
                                className="cls-7kl"
                                d="m55.12,462.56c-.12,1.48,1.11,1.79,2.35,1.36v1.48c-2.04.62-4.32.31-4.32-2.41v-7.84h-1.36v-1.42h1.48l.56-2.65h1.3v2.65h2.16v1.42h-2.16v7.41"
                              />
                              <path
                                id="T-5"
                                className="cls-7kl"
                                d="m64.19,455.03c-4.44,0-2.72,6.3-3.02,10.43h-1.98v-16.11h1.98l-.06,6.36c.74-1.3,1.73-2.22,3.77-2.22,5.49,0,3.27,7.16,3.7,11.98h-1.98v-7.47c.12-1.98-.49-3.02-2.41-2.96"
                              />
                              <path
                                id="U-8"
                                className="cls-7kl"
                                d="m75.24,453.49c1.91,0,2.96.8,3.7,2.04l-.06-6.17h1.98l.06,16.11h-1.85c-.12-.62,0-1.42-.19-1.91-.62,1.42-1.73,2.16-3.64,2.16-3.27,0-4.26-2.41-4.32-6.05,0-4.07,1.42-6.17,4.32-6.17Zm.56,1.48c-2.47,0-2.84,2.1-2.84,4.63s.37,4.57,2.78,4.57c2.59,0,3.15-1.98,3.15-4.69s-.56-4.57-3.09-4.51"
                              />
                              <path
                                id="V-14"
                                className="cls-7kl"
                                d="m89.38,455.4c-4.81-.99-3.27,5.62-3.52,10.06h-1.98l-.06-11.73h1.85c.06.74-.06,1.79.12,2.41.37-1.67,1.42-3.02,3.58-2.53v1.79"
                              />
                              <path
                                id="W-16"
                                className="cls-7kl"
                                d="m98.39,463.24c-.93,1.3-1.91,2.53-4.2,2.47-2.22-.06-3.58-1.3-3.58-3.52-.06-3.95,3.89-3.89,7.72-3.89.19-2.16-.56-3.33-2.53-3.33-1.48.06-2.53.43-2.59,1.91l-2.04-.19c.31-2.28,2.04-3.21,4.69-3.21s4.44,1.23,4.44,3.95v5.06c-.06,1.23.43,1.98,1.73,1.67v1.23c-1.91.56-3.77-.12-3.64-2.16Zm-5.74-1.05c0,1.23.74,2.04,1.98,2.04,2.53-.19,3.89-1.79,3.7-4.57-2.65.12-5.68-.31-5.68,2.53"
                              />
                              <path
                                id="X-4"
                                className="cls-7kl"
                                d="m114.75,465.46h-2.22l-2.47-10.12-2.53,10.12h-2.22l-3.33-11.73h1.98l2.41,10.12,2.65-10.12h2.1l2.53,10.12,2.59-10.12h1.91"
                              />
                              <path
                                id="aG-3"
                                className="cls-7kl"
                                d="m120.98,463.12c.06,2.1,0,4.01-.86,5.19h-1.36c.56-.8,1.05-1.6,1.05-2.84h-.99v-2.35h2.16"
                              />
                            </g>
                            <g id="L">
                              <path
                                id="aJ"
                                className="cls-7kl"
                                d="m143.52,454.53c-.06,2.53-1.42,3.95-3.7,4.32l4.32,6.6h-2.35l-4.01-6.36h-4.75v6.36h-2.04v-15.31c5.43.19,12.65-1.3,12.53,4.38Zm-10.49,2.96c3.7-.12,8.46.8,8.46-2.9s-4.94-2.59-8.46-2.78v5.68"
                              />
                              <path
                                id="Y-28"
                                className="cls-7kl"
                                d="m151.29,453.49c3.89,0,5.31,2.59,5.19,6.54h-8.33c0,2.47.86,4.14,3.27,4.2,1.6.06,2.65-.74,3.02-1.79l1.73.49c-.68,1.73-2.28,2.78-4.75,2.78-3.58,0-5.43-2.28-5.37-6.17.06-3.77,1.6-6.05,5.25-6.05Zm3.21,5c.37-3.7-4.69-4.75-5.99-1.73-.19.43-.37,1.05-.37,1.73h6.36"
                              />
                              <path
                                id="S-19"
                                className="cls-7kl"
                                d="m161.11,462.56c-.12,1.48,1.11,1.79,2.35,1.36v1.48c-2.04.62-4.32.31-4.32-2.41v-7.84h-1.36v-1.42h1.48l.56-2.65h1.3v2.65h2.16v1.42h-2.16v7.41"
                              />
                              <path
                                id="R-18"
                                className="cls-7kl"
                                d="m165.12,451.2v-1.85h1.98v1.85h-1.98Zm0,14.26v-11.73h1.98v11.73h-1.98"
                              />
                              <path
                                id="V-15"
                                className="cls-7kl"
                                d="m175.55,455.4c-4.81-.99-3.27,5.62-3.52,10.06h-1.98l-.06-11.73h1.85c.06.74-.06,1.79.12,2.41.37-1.67,1.42-3.02,3.58-2.53v1.79"
                              />
                              <path
                                id="Y-29"
                                className="cls-7kl"
                                d="m182.03,453.49c3.89,0,5.31,2.59,5.19,6.54h-8.33c0,2.47.86,4.14,3.27,4.2,1.6.06,2.65-.74,3.02-1.79l1.73.49c-.68,1.73-2.28,2.78-4.75,2.78-3.58,0-5.43-2.28-5.37-6.17.06-3.77,1.6-6.05,5.25-6.05Zm3.21,5c.37-3.7-4.69-4.75-5.99-1.73-.19.43-.37,1.05-.37,1.73h6.36"
                              />
                            </g>
                            <g id="M">
                              <path
                                id="aK"
                                className="cls-7kl"
                                d="m203.82,450.15c-.49,6.17,2.16,15.56-4.51,15.56-2.72-.06-4.14-1.54-4.57-4.07l1.98-.31c.25,1.54.99,2.59,2.65,2.65,1.67,0,2.41-1.23,2.41-3.02v-9.07h-2.96v-1.73h5"
                              />
                              <path
                                id="aL"
                                className="cls-7kl"
                                d="m214.13,449.97c5,0,7.59,2.84,7.59,7.78s-2.72,7.96-7.59,7.96-7.59-3.02-7.59-7.96,2.59-7.78,7.59-7.78Zm0,14.01c3.89,0,5.49-2.53,5.49-6.23s-1.79-6.11-5.49-6.11-5.49,2.41-5.49,6.11,1.73,6.17,5.49,6.23"
                              />
                              <path
                                id="aD-4"
                                className="cls-7kl"
                                d="m229.87,453.49c3.27,0,4.26,2.41,4.32,6.05,0,4.07-1.42,6.17-4.32,6.17-1.91-.06-3.02-.68-3.64-2.1l-.12,1.85h-1.91l.06-16.11h1.98v6.23c.62-1.42,1.73-2.1,3.64-2.1Zm-.49,10.74c2.47,0,2.78-2.1,2.78-4.63s-.31-4.63-2.78-4.57c-2.59,0-3.15,1.98-3.15,4.69s.62,4.51,3.15,4.51"
                              />
                            </g>
                            <g id="N">
                              <path
                                id="aD-5"
                                className="cls-7kl"
                                d="m248.39,453.49c3.27,0,4.26,2.41,4.32,6.05,0,4.07-1.42,6.17-4.32,6.17-1.91-.06-3.02-.68-3.64-2.1l-.12,1.85h-1.91l.06-16.11h1.98v6.23c.62-1.42,1.73-2.1,3.64-2.1Zm-.49,10.74c2.47,0,2.78-2.1,2.78-4.63s-.31-4.63-2.78-4.57c-2.59,0-3.15,1.98-3.15,4.69s.62,4.51,3.15,4.51"
                              />
                              <path
                                id="W-17"
                                className="cls-7kl"
                                d="m262.34,463.24c-.93,1.3-1.91,2.53-4.2,2.47-2.22-.06-3.58-1.3-3.58-3.52-.06-3.95,3.89-3.89,7.72-3.89.19-2.16-.56-3.33-2.53-3.33-1.48.06-2.53.43-2.59,1.91l-2.04-.19c.31-2.28,2.04-3.21,4.69-3.21s4.44,1.23,4.44,3.95v5.06c-.06,1.23.43,1.98,1.73,1.67v1.23c-1.91.56-3.77-.12-3.64-2.16Zm-5.74-1.05c0,1.23.74,2.04,1.98,2.04,2.53-.19,3.89-1.79,3.7-4.57-2.65.12-5.68-.31-5.68,2.53"
                              />
                              <path
                                id="aI-3"
                                className="cls-7kl"
                                d="m276.91,453.73c-.62,7.72,2.53,18.09-6.79,16.11-1.42-.37-2.35-1.23-2.72-2.65l1.98-.31c.93,2.9,6.17,1.98,5.49-1.73v-1.85c-.68,1.3-1.79,2.22-3.77,2.22-3.33,0-4.2-2.53-4.2-5.93s.99-5.99,4.38-6.05c1.79-.06,2.96.99,3.64,2.16.06-.62,0-1.42.12-1.98h1.85Zm-5.12,10.37c2.22,0,3.09-1.98,3.09-4.51s-.86-4.63-3.09-4.63c-2.41,0-2.84,2.1-2.84,4.63s.37,4.51,2.84,4.51"
                              />
                            </g>
                            <g id="O">
                              <path
                                id="ak-4"
                                className="cls-7kl"
                                d="m102.13,477.68c-1.91-.56-2.59.62-2.35,2.72h2.35v1.42h-2.35v10.31h-1.91v-10.31h-1.67v-1.42h1.67c-.43-3.21,1.05-5.06,4.26-4.2v1.48"
                              />
                              <path
                                id="V-16"
                                className="cls-7kl"
                                d="m109.1,482.07c-4.81-.99-3.27,5.62-3.52,10.06h-1.98l-.06-11.73h1.85c.06.74-.06,1.79.12,2.41.37-1.67,1.42-3.02,3.58-2.53v1.79"
                              />
                              <path
                                id="ae-15"
                                className="cls-7kl"
                                d="m115.58,480.15c3.83-.06,5.25,2.28,5.25,6.11.06,3.89-1.67,6.11-5.31,6.11s-5.12-2.41-5.19-6.11c0-4.07,1.73-6.11,5.25-6.11Zm-.06,10.74c2.72.06,3.27-1.91,3.27-4.63s-.49-4.63-3.15-4.63-3.27,1.98-3.27,4.63.62,4.57,3.15,4.63"
                              />
                              <path
                                id="an-7"
                                className="cls-7kl"
                                d="m134.72,481.7c-4.14.19-2.35,6.48-2.72,10.43h-1.91v-7.47c0-1.79-.31-3.09-2.16-2.96-4.07.25-2.35,6.42-2.72,10.43h-1.91l-.06-11.73h1.85c.06.62-.06,1.48.12,1.98.62-2.72,6.11-3.09,6.6,0,.68-1.3,1.67-2.16,3.58-2.22,5.25-.12,2.9,7.35,3.4,11.98h-1.91v-7.47c0-1.79-.31-3.02-2.16-2.96"
                              />
                            </g>
                            <g id="P">
                              <path
                                id="ah-2"
                                className="cls-7kl"
                                d="m154.84,492.13h-2.1l-6.23-15.31h2.16l5.12,13.46,5.12-13.46h2.22"
                              />
                              <path
                                id="Y-30"
                                className="cls-7kl"
                                d="m166.14,480.15c3.89,0,5.31,2.59,5.19,6.54h-8.33c0,2.47.86,4.14,3.27,4.2,1.6.06,2.65-.74,3.02-1.79l1.73.49c-.68,1.73-2.28,2.78-4.75,2.78-3.58,0-5.43-2.28-5.37-6.17.06-3.77,1.6-6.05,5.25-6.05Zm3.21,5c.37-3.7-4.69-4.75-5.99-1.73-.19.43-.37,1.05-.37,1.73h6.36"
                              />
                              <path
                                id="Y-31"
                                className="cls-7kl"
                                d="m178.48,480.15c3.89,0,5.31,2.59,5.19,6.54h-8.33c0,2.47.86,4.14,3.27,4.2,1.6.06,2.65-.74,3.02-1.79l1.73.49c-.68,1.73-2.28,2.78-4.75,2.78-3.58,0-5.43-2.28-5.37-6.17.06-3.77,1.6-6.05,5.25-6.05Zm3.21,5c.37-3.7-4.69-4.75-5.99-1.73-.19.43-.37,1.05-.37,1.73h6.36"
                              />
                              <path
                                id="ai-6"
                                className="cls-7kl"
                                d="m191.32,492.13h-2.35l-4.26-11.73h2.1l3.33,10.19,3.46-10.19h2.1"
                              />
                              <path
                                id="W-18"
                                className="cls-7kl"
                                d="m204.47,489.91c-.93,1.3-1.91,2.53-4.2,2.47-2.22-.06-3.58-1.3-3.58-3.52-.06-3.95,3.89-3.89,7.72-3.89.19-2.16-.56-3.33-2.53-3.33-1.48.06-2.53.43-2.59,1.91l-2.04-.19c.31-2.28,2.04-3.21,4.69-3.21s4.44,1.23,4.44,3.95v5.06c-.06,1.23.43,1.98,1.73,1.67v1.23c-1.91.56-3.77-.12-3.64-2.16Zm-5.74-1.05c0,1.23.74,2.04,1.98,2.04,2.53-.19,3.89-1.79,3.7-4.57-2.65.12-5.68-.31-5.68,2.53"
                              />
                            </g>
                            <path className="cls-1kl" d="m151.64,333.38v58.95" />
                            <path
                              className="cls-2kl"
                              d="m152.14,333.38h-1v-.5h1v.5Z"
                            />
                            <path
                              className="cls-3kl"
                              d="m151.64,407.1l-4.63-14.27h9.27l-4.64,14.27Z"
                            />
                          </svg>
                        </div>
                        <div className="col-lg-8 widthdrawal">
                          <div>
                            <h5>
                              <i>Material Withdrawal phase</i>
                            </h5>
                            <p className="p_font">
                              Material Withdrawal phase starts with brand team´s
                              decision to discontinue a material and it ends
                              with completed and documented destruction of the
                              material.
                              <br />
                              <br />
                              The materials need to be removed from all storage
                              locations and circulation before initiating the
                              withdrawal in the Withdrawal tool (wherever
                              applicable). The Inventory Information field in
                              VVPM will help the material administrator to
                              capture all storage locations for effective
                              destruction.
                            </p>
                            <p className="p_font">
                              Withdrawal of entire website means pulling out the
                              whole website so it’s no longer visible
                              externally. Site owner has the overall
                              accountability for an effective
                              withdrawal/retirement of website. When retiring a
                              website, contact tech@Lilly and/or system
                              custodian who will be performing following tasks.
                            </p>
                            <ul className="ul_padding">
                              <li className="p_font">
                                ● Tech@Lilly process for disposition and
                                retirement is to be followed.
                              </li>
                              <li className="p_font">
                                ● Tech@Lilly/system custodian to perform check
                                on orphan pages, SEO, caching and related
                                components so that they are all removed from
                                search engines.
                              </li>
                              <li className="p_font">
                                ● URL manager is notified so the website can be
                                removed from the internet
                              </li>
                              <li className="p_font">
                                ● Tech@Lilly process for disposition and
                                retirement is to be followed.
                              </li>
                              <li className="p_font">
                                ● GCO team is notified to make changes in the
                                configuration management database (CMDB)
                              </li>
                              <br />
                              Once the listed actions have been completed,
                              System custodian advises the site owner to retire
                              the job bag on VVPM.
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </details>
              </div>
            </div>
          </div>
          <div className=""></div>
        </div>
      </div>

      <div className="mb-4 bg-secondary-subtle mt-5">
        <div className="container pt-3 pb-3 ">
          <div className="row">
            <div className="col-lg-12 text-center">
              <img src="assets/images/lilly.PNG" width="100" alt="Lilly" />
            </div>
            <div className="col-lg-12 text-center">
              Copyright © 2023 Lilly. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Website;
