import React from "react";
import { useState, useRef, useEffect } from "react";
import { Link } from 'react-router-dom';
import LendingCard from "../components/LendingCard.js";
import {webConfig} from '../configs/webConfig'
import "../css/HeadOfficeMeetings.css";
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/Meetings_Phases_SVG/Head_Office_Meeting_Phase_1.svg";
import { ReactComponent as Phase3 } from "../SVG/Meetings_Phases_SVG/Head_Office_Meeting_Phase_3.svg";
import { ReactComponent as Phase4 } from "../SVG/Meetings_Phases_SVG/Head_Office_Meeting_Phase_4.svg";



const HeadOfficeMeetings = () => {
  const [isPhase3Open, setIsPhase3Open] = useState();

  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("TLMAP");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("FinalBriefing");

  const svgRef4 = useRef();
  const [P4visibleSection, setP4visibleSection] = useState("ADLSubmission");

  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    const rect8 = svg.getElementById("rect8");
    
    rect1.addEventListener("click", () => {
        setP1visibleSection("TLMAP");
    });
    rect2.addEventListener("click", () => {
      setP1visibleSection("CETMeeting");
    });
    rect3.addEventListener("click", () => {
        setP1visibleSection("RegisterMeetingInMercury");
    });
    rect4.addEventListener("click", () => {
        setP1visibleSection("InitiateVenue");
    });
    rect5.addEventListener("click", () => {
        setP1visibleSection("LMCForm");
    });
    rect6.addEventListener("click", () => {
        setP1visibleSection("VVPMApproval");
    });
    rect7.addEventListener("click", () => {
        setP1visibleSection("Contracting");
    });
    rect8.addEventListener("click", () => {
        setP1visibleSection("BriefingAndTraining");
    });

  }, []);

  useEffect(() => {
    const svg = svgRef3.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    rect1.addEventListener("click", () => {
      setP3visibleSection("FinalBriefing");
    });
    rect2.addEventListener("click", () => {
      setP3visibleSection("CrossFuncMeeting");
    });
  }, []);

  useEffect(() => {
    const svg = svgRef4.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    rect1.addEventListener("click", () => {
      setP4visibleSection("ADLSubmission");
    });
    rect2.addEventListener("click", () => {
      setP4visibleSection("LMCDLMUploads");
    });
    rect3.addEventListener("click", () => {
        setP4visibleSection("NBESTCFT");
    });
    rect4.addEventListener("click", () => {
        setP4visibleSection("Dashboards");
    });
  }, []);


  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/meetings">Meetings</Link>  
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                     Head-Office Face-to-Face Meeting  
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd"> Head-Office Face-to-Face Meeting  </h2>
            <p>
              <em>
                 Head-Office Face-to-Face Meetings are organised by NE Hub teams as in-person Meetings to promote or educate.  
              </em>
            </p>
          </div>
        </div>
      </div>

      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link navLinkTab active"
              data-bs-toggle="tab"
              href="#processPreRequisites"
            >
              Process
              <br />
              Pre-requisites
            </a>
          </li>
        </ul>
        
        <div id="tabContent" className="tab-content">
            <div id="processPreRequisites" className="tab-pane fade in active tabs_box tabContent">
                <div>
                  <ul className="ul_padding">
                    <li className='tabContent'> Process subject to approval within the brand team. </li>
                    <li className='tabContent'> Process subject to approval of activity in Thought Leader Map. </li>
                    <li className='tabContent'>
                         Process subject to completion of NE HUB CMS: Head-Office Meeting 
                        Training (Course 9397172) and associated procedures. 
                        (All Meeting related procedures can be found at {' '} 
                        <Link
                                to={webConfig.MeetingsRelatedInformation}
                                target="_blank"
                        >  Meetings Related Information</Link>.)
                        
                    </li>
                    <li className='tabContent'>
                         Process subject to Mercury training and access – contact Lilly Meetings & Congresses team (LMC) at <strong>LMC_HO@lilly.com </strong>. 
                    </li>
                  </ul>
                </div>
            </div>
        </div>
      </div>
      
      {/*Add verticle Tab*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
            <button
              className="nav-link"
              id="v-pills-faqs-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-faqs"
              type="button"
              role="tab"
              aria-controls="v-pills-faqs"
              aria-selected="false"
            >
              FAQs
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">Head-Office Meeting Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Pre-Meeting Planning"
                    defaultOpen={true}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning main-content">
                            <Phase1 ref={svgRef1} className="homShape1" /> 
                      </div>
                      <div className="col-lg-7 planning p_font pt-3 flowsSecondPart side-content">
                        <div
                          style={{
                            display:
                              P1visibleSection === "TLMAP" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">Complete TL-Map Approval   </p>
                          <p className="flowsContent">
                                Ideally, this should be completed before in the calendar year as part of all the TL activity.  
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "CETMeeting" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">Meeting Details on CET  </p>
                          <p className="flowsContent">
                          Ideally, the Meeting should already be on the Customer Engagement Tool (CET) as part of Annual Operational Planning process for the year. Review Meeting details, adjust Key Performance Indicators (KPIs) if needed and link it with Mercury. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "RegisterMeetingInMercury" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">Register in Mercury    </p>
                            <p className="flowsContent">
                                All Meetings should be registered in Mercury by the Meeting Owner as soon as the date of the event is known. Registration requires the following details to be known:  
                            </p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                 Start Date and Duration 
                                </li>
                                <li className="flowsContent">
                                 Meeting Location 
                                </li>
                                <li className="flowsContent">
                                 Meeting Owner 
                                </li>
                                <li className="flowsContent">
                                 Estimated Budget Amount 
                                </li>
                                <li className="flowsContent">
                                 TL MAP ID 
                                </li>
                                <li className="flowsContent">
                                 Number of Estimated Participants  
                                </li>
                                <li className="flowsContent">
                                 Meeting Space Requirements 
                                </li>
                                <li className="flowsContent">
                                 Meeting Type
                                </li>
                                <li className="flowsContent">
                                 Cost Centre 
                                </li>
                                <li className="flowsContent">
                                 Proposed Agenda 
                                </li>
                            </ul>
                            <p className="flowsHeading">Remember!</p>
                            <p className="flowsContent">
                                Lead times for registration vary depending on estimated budget and Meeting size ranging from 3 months to 9 months lead time, so planning early is essential. 
                            </p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                 For more information on registration and lead times, review the {' '}
                                <Link 
                                to={webConfig.MeetingsLogisticsJobAid}
                                target="_blank" 
                                > Meetings Logistics Job Aid  
                                </Link>{' '}.
                                    
                                </li>
                                <li>
                                   For information on Mercury and step-by-step guides on how to register the Meeting, visit the {' '}
                                    <Link 
                                    to={webConfig.LMCMercurySupportSite}
                                    target="_blank" 
                                    > LMC Mercury Support Site
                                    </Link>{' '}.  
                                </li>

                            </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "InitiateVenue"
                                ? "block"
                                : "none",
                          }}
                        >
                            <p className="flowsHeading">Initiate Venue Sourcing </p>
                            <p className="flowsContent">
                                If venue sourcing is required for the Face-to-Face 
                                Meeting, the Meeting Owner must submit a Cvent {' '}
                                <Link to = {webConfig.MeetingRequestForm} target="_blank">
                                    Meeting Request Form
                                </Link> {' '} (MRF). 
                            </p>

                            <p className="flowsContent">
                                LMC, the Meeting Owner and the Meeting Planning Company will have an initial call to understand requirements for the venue and Meeting. The Meeting Planning Company will work closely with LMC and the Meeting Owner to source an appropriate venue and arrange on-site Meeting logistics to ensure a smooth process. 
                            </p>
                            <p className="flowsHeading">Remember!</p>
                            <p className="flowsContent">
                                Ideally, venue sourcing should start as soon as the Meeting is registered on Mercury.  
                            </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "LMCForm" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">LMC Creates CVENT Registration Site    </p>
                          <p className="flowsContent">
                             The AMEX team in LMC creates a registration form for delegates to register their intent to attend the Meeting. The Meeting registration form is standard and non-branded to capture customer data.  
                          </p>
                          <p className="flowsContent">
                             CVENT form will also capture any accommodation requirement for the delegates. 
                          </p>
                          <p className="flowsHeading"> REMEMBER!</p>
                          <p className="flowsContent">
                              This is the only approved method to register delegates for Lilly owned Meetings. Any other method to register delegates for Head-Office Face-to-Face Meetings must not be used. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "VVPMApproval" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">VVPM Approval if Required </p>
                            <p className="flowsContent">
                                The CVENT page is already reviewed/approved as a 'Form'. However, if there is any promotional information on the form, it needs approval through Veeva Vault PromoMats (VVPM). It is ideal to keep this page non-promotional.  
                            </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "Contracting" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">Speaker Contracting  </p>
                            <p className="flowsContent">
                                 Once a Speaker has been identified and confirmed, the Meeting 
                                 Owner should initiate contracting through Mercury. Prior to initiation, 
                                 the Meeting Owner should have confirmed with the Speaker on the proposed preparation, engagement 
                                 hours and the honorarium (as per the {' '}
                                <Link 
                                to={webConfig.FMVSOP}
                                target="_blank" 
                                >  FMV SOP 
                                </Link>{' '} ).
                                 If the Speaker is required to travel for the Meeting, 
                                 the Meeting Owner should capture their travel and accommodation requirements for entry into Mercury.  
                            </p>
                            <p className="flowsContent">
                               Contracting <strong>must</strong> be completed (the Master Service Agreement [MSA] and Work Order or Single Event Agreement are signed) prior to initiating any activity with the healthcare professional (HCP).  
                            </p>
                            <p className="flowsHeading">Remember!</p>
                            <p className="flowsContent">
                                The Meeting Owner must adhere to the following lead times when initiating contracting, or else an exception must be gained: 
                            </p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                   &gt; 9 weeks for a service provider without an MSA in place 
                                </li>
                                <li>
                                    &gt; 6 weeks for a service provider with an expired MSA  
                                </li>
                                <li>
                                    &gt; 3 weeks for a service provider with an MSA in place  
                                </li>

                            </ul>
                            <p className="flowsContent">
                                For information on Mercury and step-by-step guides on how to set up Speaker contracting, 
                                visit the <Link 
                                to={webConfig.LMCMercurySupportSite}
                                target="_blank" 
                                >  LMC Mercury Support Site </Link>{' '}.  
                            </p>
                        </div>    
                        <div
                          style={{
                            display:
                              P1visibleSection === "BriefingAndTraining" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">Speaker Training and Briefing  </p>
                            <p className="flowsContent">
                               <strong>Speaker training</strong> is a global requirement to deliver certain information to a contracted HCP prior to them speaking on behalf of Lilly. Speaker training consists of training on product information, handling off-label questions, disclosing conflicts of interest and requirements for adverse events and/or product complaints reporting as well as any key local code requirements.  
                            </p>
                           
                            <p className="flowsHeading">Remember!</p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                   The Meeting Owner is responsible and accountable for the planning, implementation and documentation of Speaker training for Head-Office Meetings.  
                                </li>
                                <li>
                                    Medical are accountable for delivering the scientific elements of Speaker training.  
                                </li>
                                <li>
                                    For more information on Speaker training review the NE Hub HCP Speaker Training 
                                  procedure {' '}
                                  <Link 
                                    to={webConfig.SpeakerTrainingJobAid}
                                    target="_blank" 
                                    > job aid</Link>. 
                                </li>
                            </ul>
                            <p className="flowsContent">
                               <strong>Speaker briefing</strong> is the process of verbal discussion. A document is sent by the Meeting Owner to the contracted service provider, prior to the Meeting taking place. The brief contains relevant logistical Meeting information, objectives, guidance on presentation and code obligations.   
                            </p>
                            <p className="flowsHeading">Remember!</p>
                            <ul className="ul_padding">
                                <li className="flowsContent">
                                    Each brand will have limits on the number of Speakers who may be trained on a product. Contact your brand team before any new Speaker training is planned. 
                                </li>
                                <li>
                                    For more information on Speaker briefing review the Speaker Briefing {' '}
                                  <Link 
                                    to={webConfig.SpeakerBriefingJobAid}
                                    target="_blank" 
                                    >  job aid 
                                    </Link>{' '} . 
                                </li>
                            </ul>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion2" />
                  <Accordion
                    className="accordion2"
                    title="Phase 2: Pre-Meeting Activities – Material Readiness"
                    defaultOpen={false}
                  >
                    <div className= "accordion-heading mb-4">
                      <p>
                        Some tactics require attention prior to the Meeting to ensure that the Meeting can go ahead, and Meeting promotion/registration can commence. 
                      </p>
                      <p>
                          For Head-Office Meeting, tactics that should be started at an early stage include: 
                      </p> 
                      <ul className="list-inline">
                          <li className="list-inline-item">&bull; Slide Deck</li>
                          <li className="list-inline-item">&bull; Stands/Exhibits and Physical Materials </li>
                          <li className="list-inline-item">&bull; Meeting Promotion </li>
                      </ul>
                    </div>
                    <div className="row  justify-content-center" style={{ margin: "0px" }}>
                        <LendingCard
                            {...{
                            title: "Slide Deck",
                            htmlString: `
                               <div className="card-content">
                                    <p>Slide deck represents the content created for the webinar. The slides should be created way in advance and approved / certified through PMAP process. There are 2 types of slide deck:</p>
                                    <ul className="ul_padding">
                                      <li>Lilly-created slides are produced without the involvement of an external speaker.</li>
                                      <li>Speaker-created slides are those which are researched and developed by the HCP who is going to present them.</li>
                                    </ul>
                                    <p className="mb-0">REMEMBER!</p>
                                    <p > 
                                       The slide deck should be uploaded for Veeva Vault PromoMats (VVPM) approval at least 4 weeks before the event, however, good practice advises this timeline to be 6 weeks.
                                    </p>
                                </div>
                            `,
                            links: [
                                { label: "Lilly Owned / Speaker Owned <br/> Slide deck", url: "/slidedecs" },
                            ],
                            flexColSize : "col-lg-3"
                            }}
                        />

                        <LendingCard
                            {...{
                            title: "Stands/Exhibits and Physical Materials ",
                            htmlString: `
                               <div className="card-content">
                                <p>There may be a variety of Physical Materials during the Meeting. These may either be on display or used as handouts. Visit the Physical Material process flow to understand more.   </p>
                               </div> 
                            `,
                            links: [
                                {
                                label: "Physical Material",
                                url: "/pm",
                                },
                            ],
                            flexColSize : "col-lg-3"
                            }}
                        />

                        <LendingCard
                            {...{
                            title: "Meeting Promotion",
                            htmlString:`
                                <div className="card-content">
                                <p>Head-Office Meetings can be promoted through a variety of tactics such as:</p>
                                <ul className="ul_padding">
                                  <li>Broadcast Email (BE) - It is recommended to use BE as 90% of registrations in the NE Hub come through this channel. Templates for invitations through BE are available with the Digital Solutions Team (DST). Click on the ‘Broadcast Email’ tab to understand about how to create and approve BE.  </li>
                                  <li>Veeva Approved Email (VAE) are particularly important if the webinar will also be available as a satellite Meeting for representatives to stream locally. Click on the VAE tab to understand the process.  </li>
                                  <li>Print Material - This is used by the Customer-Facing Teams to promote webinar. It is especially useful for a new brand where they might not have HCP database or e-consent with them. Click on Print/Physical Material tab to learn more about the process. </li>
                                </ul>
                                </div>
                                `,
                            links: [
                                { label: "Physical Material For Print Invites", url: "/PM" },
                                { label: "Broadcast Email", url: "/BE" },
                                { label: "Veeva Approved Emails", url: "/VAE" },
                            ],
                            flexColSize : "col-lg-5"
                            }}
                        />
                      
                    </div>
                  </Accordion>

                  <div id="accordion3" />
                  <Accordion
                    className="accordion3"
                    title="Phase 3: Material Approval and Meeting Readiness "
                    defaultOpen={false}
                    isOpen={isPhase3Open}
                  >
                    <div className= "accordion-heading">
                        <p>
                            Depending upon the Pre-Event activities, material approval process will differ and depend on the materials selected. Different materials will have their distinct approval processes. Once all promotional and other 'Pre-Event Activity' material is certified and distributed, consider the following for smooth execution of the Meeting. 
                        </p> 
                    </div>
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                          <div className="comman_width_e">
                            <Phase3 ref={svgRef3} className="homShape3" />
                          </div>  
                      </div>
                      <div className="col-lg-7 planning flowsSecondPart">
                        <div
                          id="link1"
                          style={{
                            display:
                              P3visibleSection === "FinalBriefing"
                                ? "block"
                                : "none",
                          }}
                        >
                            <p className="flowsHeading">Final Briefing with Speaker    </p>
                            <ul className="ul_padding">
                                <li className="flowsContent">
                                    Ensure that the Speaker is briefed on compliance. This is done by a Medical team member.  
                                </li>
                                <li className="flowsContent">
                                    The Speaker needs to complete a form confirming they have completed this training. The form is uploaded on Mercury.  
                                </li>
                                <li className="flowsContent">
                                    National Therapeutic Advisor (NTA) or Brand Manager (BM) may do a strategic Meeting or a slide walk-through before the live event.   
                                </li>
                            </ul>
                        </div>
                        <div
                          id="link1"
                          style={{
                            display:
                              P3visibleSection === "CrossFuncMeeting"
                                ? "block"
                                : "none",
                          }}
                        >
                            <p className="flowsHeading">Cross-Functional Meeting    </p>
                            <ul className="ul_padding">
                                <li className="flowsContent">
                                    Set clear goals with the team leading the Meeting. 
                                </li>
                                <li className="flowsContent">
                                    Clarify strategy and objectives.  
                                </li>
                                <li className="flowsContent">
                                    Establish clear roles and responsibilities. 
                                </li>
                                <li className="flowsContent">
                                    Define what good looks like and Post-Event Activity with timelines.   
                                </li>
                            </ul>
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  {/*Accordion4*/}
                  <div id="accordion4" />
                  <Accordion
                    className="accordion4"
                    title="Phase 4: Post-Event Operations"
                    defaultOpen={false}
                  >
                    <div className= "accordion-heading">
                        <p>
                           There are some actions required to effectively close the Meeting. It is important to reconcile customer data. Reconciliation is the process of identifying attendees and capturing that attendance in Mercury. 
                        </p> 
                    </div>
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                        <Phase4 ref={svgRef4} className="homShape4" />
                      </div>
                      <div className="col-lg-7 planning p-3 flowsSecondPart">
                        <div
                          style={{
                            display:
                              P4visibleSection === "ADLSubmission"
                                ? "block"
                                : "none",
                          }}
                        >
                            <p className="flowsHeading">
                                Attendee Details Submitted to LMC  
                            </p>
                            <p className="flowsContent">
                            Meeting Owner organises (through AMEX team) for a registration desk at the event. A delegate list should be captured by a LMC team member which is to be uploaded to Mercury.  
                            </p>
                        </div>
                         <div
                          style={{
                            display:
                              P4visibleSection === "LMCDLMUploads"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">LMC Uploads Delegate List to Mercury  </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Wherever HCP ID is available, the upload on Mercury is enabled through a Bot. If HCP is not reconciled, LMC will attempt a manual look-up and a manual Reconciliation. 
                            </li>
                            <li className="flowsContent">
                               If HCP is not identified, the delegate detail cannot be reflected on dashboards. 
                            </li>
                          </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P4visibleSection === "NBESTCFT"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">NBE Suggestions Triggered to CFT  </p>
                          <p className="flowsContent">Next Best Engagement (NBE) is triggered as suggestions on Veeva for Customer-Facing teams (CFTs) to follow up with the target customers. This is an automated process enabled through a link between Mercury and Veeva Customer Relationship Management (CRM).   </p>
                          <p className="flowsHeading">REMEMBER</p>
                          <p className="flowsContent">Customer-Facing teams will receive NBE only for target HCPs in their region. </p>
                        </div>
                        <div
                          style={{
                            display:
                              P4visibleSection === "Dashboards"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Data Flows to Dashboards   </p>
                          <p className="flowsContent">Mercury is connected to dashboards, so marketing teams can understand/analyse attendee segment by looking at Brand 360. Mercury is also linked to the CET. If CET tactic ID is correctly linked, the CET dashboard will also auto-update.  </p>
                          <p className="flowsHeading">REMEMBER</p>
                          <p className="flowsContent">
                              For majority of dashboards, the Meeting data will only be added either when the Meeting is marked as ‘closed’ on Mercury, or if the Meeting took place more than 4 weeks ago.  
                          </p>
                          <ul className="ul_padding">
                                <li className="flowsContent">
                                    It is also important that the Meeting is entered into Mercury with all the required fields completed.   
                                </li>
                                <li className="flowsContent">
                                    If Meeting details are not visible on dashboards, check DHATA 360 dashboard to check if there was any data input missing.   
                                </li>
                            </ul>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion5" />
                  <Accordion
                    className="accordion5"
                    title="Phase 5: Material Withdrawal"
                    defaultOpen={false}
                  >
                    <div className="row p-3" style={{ margin: "0px" }}>
                        <p className="flowsContent">
                         Different materials included in the planning of the Meeting should follow their distinct withdrawal process as applicable. A good inventory management of VVPM material job bags will include: 
                        </p>
                        <ul className="ul_margin_bottom">
                            <li className="flowsContent">
                               <strong>Invitation Emails</strong> (BE, Veeva Approved Email [VAE], SOA emails) are to be marked for ‘One-Time’ use and allowed to auto-expire after the event date. 
                            </li>
                            <li className="flowsContent">
                               <strong>Physical Material</strong> with specific Meeting date and details should be marked for ‘One-Time’ use and allowed to auto-expire. <strong>Remember</strong> to trigger Material and Retirement Status Application (MaRS) withdrawal notification for the Physical Material to be destroyed by the Customer-Facing team.  
                            </li>
                            <li className="flowsContent">
                               <strong>Meeting Slide Decks</strong> are usually marked for ‘One-Time’ use unless there is an intent to leverage the same slides for future Meetings. In this scenario, the VVPM metadata should reflect details in relevant fields.
                            </li>
                        </ul>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            {/*End Lifecycle */}

            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Role </th>
                        <th>Responsibility</th>
                        </tr>
                    </thead>  
                    <tbody>
                      <tr>
                         <td className="tb">
                              <h6>Veeva Owner</h6>
                          </td>
                          <td className='tb'>
                              <p>
                                  The Veeva Owner is also known as the Uploader (please see 'Veeva uploader')
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator</h6>
                          </td>
                          <td>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Having overall responsibility and accountability for the material</li>
                                  <li>2. Ensure that no item is used without the necessary approvals</li>
                                  <li>3. Ensuring timely change and/or withdrawal, where needed</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a>
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Uploader</h6>
                          </td>
                          <td>
                              <p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA
                                  teams, and is assigned the Veeva Owner role.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Uploading the material to Veeva</li>
                                  <li>2. Performing all activities related to upload</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Co-ordinator</h6>
                          </td>
                          <td>
                              <p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager (or similar
                                  role). </p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Reviewing the item before sending for MLR review</li>
                                  <li>2. Managing the document workflow</li>
                                  <li>3. Assigning reviewers</li>
                                  <li>4. Managing expiry or periodic review</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Business Reviewer</h6>
                          </td>
                          <td>
                              <p>Business Reviewer is an experienced commercial employee.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring all promotional materials align with the brand strategy, Lilly Brand Book, Product Brand
                                      Book</li>
                                  <li>2. Ensuring all materials are of good quality, have correct Veeva metadata and adhere to internal
                                      policies and local codes</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Medical Certifier</h6>
                          </td>
                          <td>
                              <p>Registered medical practitioner or UK-registered Pharmacist.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring that the material is in accordance with the requirements of the local code</li>
                                  <li>2. Ensuring that the material is not inconsistent with the marketing authorisation/SmPC (if applicable)
                                  </li>
                                  <li>3. Ensuring that the material is a fair and truthful representation of the facts about the medicine (if
                                      applicable)</li>
                                  <li>4. Approving the material in its final form</li>
                                  <li>5. Ensuring the material has the appropriate reviewers and that the Veeva metadata is correct.</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for ensuring that the Veeva metadata is correct and that the PMC
                                  documents are included (if applicable).</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordic Code Approver</h6>
                          </td>
                          <td>
                              <p>Code Approver must be a medical practitioner or a pharmacist (except for in Sweden) and is responsible for
                                  ensuring that the material is in line with the local Codes and that the material being approved has the
                                  appropriate reviewers; and ensuring that the Veeva metadata is correct.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordics Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for reviewing the material from a medical perspective.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Indegene</h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1. Creating artwork as per material originator brief</li>
                                  <li>2. Creating formatted PI PDFs</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Content conceptualisation</li>
                                  <li>2.  Brief to Indegene </li>
                                  <li>3.  Managing approval proces </li>
                                  <li>4.  Ensuring that a print request is raised with KonicaMinolta (or for simple materials, printed in the office) to provide hard copies for certification</li>
                                  <li>5.  Ensuring final print request is raised and material is delivered to final destination (e.g., warehouse)</li>
                                  <li>6.  Withdrawal </li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Global Librarian  </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Archiving Physical Material as per the request from the Material Originator</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Marketing Services  </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Business relationship owner for the printing agency (Indicia), creation agency (Indegene) and warehouse supplier across Hub (STACI, UK; Velox, FI, SE and DK and Logistikkhuset, NO)</li>
                                  <li>2.  Primary point of contact for Marketing teams in regards to feedback to above mentioned suppliers </li>
                                  <li>3.  Delivering trainings for (new) employees relevant to third tools and related material management processes  </li>
                                  <li>4.  Oversight and support role through material management process including material withdrawal process</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Konica Minolta  </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Providing printing proofs prior to print</li>
                                  <li>2.  Printing as per print request and delivering to defined location </li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator   </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Submitting online request form to build On24 platform</li>
                                  <li>2.  Managing approval process </li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>OCE Operations Manager   </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Building On24 platform as per timelines and the provided brief </li>
                                  <li>2.  Downloading attendance report from On24 and submitting to the LMC team for customer reconciliation in Mercury </li>
                              </ul>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>What it Stands for  </strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                    Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Stand</td>
                    <td className="tb"></td>
                    <td className="tb">
                       Designated space purchased by Lilly for a congress or event. This space can feature a stand with promotional imagery and pull-up banners.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Booth</td>
                    <td className="tb"></td>
                    <td className="tb">
                       Designated space purchased by Lilly for a congress or event. This space can feature a stand with promotional imagery and pull-up banners.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool </td>
                    <td className="tb">Planning and execution tools combine to form the CET. With CET, customer plans can be created and campaigns can be managed in one place. </td>
                  </tr>
                  <tr>
                    <td className="tb">Congress </td>
                    <td className="tb"></td>
                    <td className="tb">
                      Third party organised events in which Lilly purchases a sponsorship package for. This can include a stand, booth and/or symposium
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Conversion Metric </td>
                    <td className="tb"></td>
                    <td className="tb">Metrics used to measure the number of valuable actions customers take through a digital marketing channel. </td>
                  </tr>
                  <tr>
                    <td className="tb">CSA </td>
                    <td className="tb">Control Self Assessment </td>
                    <td className="tb">A quality check performed by process owners quarterly. The results are shared and discussed with HLT with measures put in place to mitigate any identified issues.  </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey </td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>
                  <tr>
                    <td className="tb">Cvent </td>
                    <td className="tb"></td>
                    <td className="tb">Hopsitality management platform that allows capture of customer details for meetings and events, such as registration details and logistics. </td>
                  </tr>
                  <tr>
                    <td className="tb">eP2P</td>
                    <td className="tb">Electronic peer to peer </td>
                    <td className="tb"> eP2P is the video broadcasting of P2P programs transmitted via internet either live or hosted (video recording) on Lilly or 3rd party sites. </td>
                  </tr>
                  <tr>
                    <td className="tb">HO</td>
                    <td className="tb">Head Office</td>
                    <td className="tb">A prefix to tactics owned by the teams who work from a Lilly office, such as medical and marketing.  </td>
                  </tr>
                  <tr>
                    <td className="tb">Hybrid P2P </td>
                    <td className="tb">Hybrid Peer to Peer  </td>
                    <td className="tb">A Lilly organized eP2P set up in such a way that some customers attend the meeting individually while others gather in groups to attend the meeting. (ie. Hub + spoke, satellite groups)  </td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">Goals or Metrics set behind a campaign and or tactic.</td>
                  </tr>
                  <tr>
                    <td className="tb">Leave piece </td>
                    <td className="tb"></td>
                    <td className="tb">A material which can be left at the HCP's desk.</td>
                  </tr>
                  <tr>
                    <td className="tb">Mercury</td>
                    <td className="tb"></td>
                    <td className="tb">Salesforce Marketing Cloud application for the planning, submission and approval of meetings.</td>
                  </tr>
                   <tr>
                    <td className="tb">P2P</td>
                    <td className="tb">Peer to peer </td>
                    <td className="tb">Lilly organized programs where an HCP (external or internal speaker) engages with other HCPs on disease state and therapeutic options through lectures and/or case study discussions. This can also be referred to as F2F events (face to face).  </td>
                  </tr>
                  <tr>
                    <td className="tb">PMC</td>
                    <td className="tb">Permissions Managed Content </td>
                    <td className="tb">An image which is owned by an entity other than Lilly and for which permission is given to use under a specifc set of circumstances as defined in a contractural agreement between Lilly and the Content Owner. </td>
                  </tr>
                  <tr>
                    <td className="tb">Symposium</td>
                    <td className="tb"> </td>
                    <td className="tb">A Lilly-owned lecture at a third party event or congress. </td>
                  </tr>
                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"> </td>
                    <td className="tb">Tactics are specific to selected channels to drive customers to reach a piece of content. Tactics are associated with estimated KPI metric (goal) and result reports (actuals).  </td>
                  </tr>
                  <tr>
                    <td className="tb">TL</td>
                    <td className="tb">Thought Leader</td>
                    <td className="tb">Thought Leaders influence thinking and/or treatment practice beyond his/her local market or practice on a regional, national or global basis. TLs are respected and acknowledged by their peers, other experts and key audiences as leaders within a particular subject matter. The term includes customers from clinical to academic to research to regulatory and the payer space.  </td>
                  </tr>
                  <tr>
                    <td className="tb">TL-MaP</td>
                    <td className="tb">Thought Leader Mapping and Planning Tool </td>
                    <td className="tb">The only approved global platform for planning and tracking contracted Thought Leader engagements. Among many other benefits, TL-MaP allows for increased line-of-sight for accountable leadership to mitigating risk when contracting TLs.  </td>
                  </tr>
                  <tr>
                    <td className="tb">VVPM</td>
                    <td className="tb">Veeva Vault PromoMat</td>
                    <td className="tb">Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems. PromoMats is a Vault application to manage the process of creating, reviewing and approving promotional materials, claims and supporting information.</td>
                  </tr>
                  <tr>
                    <td className="tb">Warehouse/Staci </td>
                    <td className="tb"></td>
                    <td className="tb">Location where all physical print materials are stored for distribution. The warehouse/staci online system allows management and monitoring of stock across brands.  </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Defination*/}

            {/*View Core Doc*/}
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <Link
                  className="CoreURL"
                  to={webConfig.HeadOfficeMeetingCoreDoc}
                  target="_blank"
                >
                  Head-Office Face-to-Face Meeting document here.{" "}
                </Link>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/*End Core Doc*/}

             {/*Add Faqs*/}
             <div
              className="tab-pane fade"
              id="v-pills-faqs"
              role="tabpanel"
              aria-labelledby="v-pills-faqs-tab"
            >
              <h4 className="h1-font comman_hd">FAQs</h4>
              <div id="FAQ1">
                <Accordion
                  className="faqAccordion"
                  title="Can I use Teams instead of ON24 for my upcoming Head-Office lead eP2P?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">  
                  <p>
                  No, ON24 is the only approved platform used for marketing lead webinars/eP2P. There are several reasons why:
                  </p>
                  <ul  className="ul_padding">
                    <li>
                        ON24 enables automated customer journey to send invitation emails. This reduces the work for you and for teams supporting you in running the webinar. 
                    </li>
                    <li>
                        Only ON24 is integrated with Mercury, Customer Engagement Tool (CET) and Data Dashboards. This means your attendee data is automatically uploaded on relevant places. This again, reduces work post the event. 
                    </li>
                    <li>
                        There is a potential risk in Teams where the Personal Information (Name and email) of attendees, might be visible to other attendees. 
                    </li>
                    <li>
                        ON24 has a better control over withdrawal of the material. No manual action is needed from your end. This again, saves time.  
                    </li>
                  </ul>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ2">
                <Accordion
                  className="faqAccordion"
                  title="What happens to the webinar video and the console after the webinar is over?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                    ON24 platform auto-withdraws the webinar details and the material. It is archived offline. 
                    </p>
                    <p>The action to do so is built in when the console is created by the OCE Operations Manager.  </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ3">
                <Accordion
                  className="faqAccordion"
                  title="How can I get trained on Mercury?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Mercury training videos and checklists are available here {' '}
                      <Link
                        to={webConfig.LMCMercurySupportSite}
                        target="_blank"
                      >
                        Mercury support site (lilly.com)
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ4">
                <Accordion
                  className="faqAccordion"
                  title="What are the useful procedures for meetings?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Please visit this page {' '}
                      <Link
                        to={webConfig.MeetingsRelatedInformation}
                        target="_blank"
                      >
                        Meetings Related Information (lilly.com)
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ5">
                <Accordion
                  className="faqAccordion"
                  title="How do I check my speaker training status?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      This can be found in Mercury and Veeva and on the PowerApp {' '}
                      <Link
                        to={webConfig.GBSTrainedSpeakerListPowerApps}
                        target="_blank"
                      >
                        GBS - Trained Speaker List - Power Apps
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ6">
                <Accordion
                  className="faqAccordion"
                  title="Who is my POC in the Lilly Meetings and Congresses Team?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Please see org chart here:{' '}
                      <Link
                        to={webConfig.HEHUBLMCOrgChart}
                        target="_blank"
                      >
                        HE HUB LMC Org Chart_Oct 2023.png (1920×1080) (lilly.com)
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
            </div>
            {/*End Faqs*/}
          </div>
        </div>
      </div>

    </div>
  );
};


export default HeadOfficeMeetings;
