import React from "react";
import { useState, useRef, useEffect } from "react";
import {webConfig} from '../configs/webConfig'
import { Link } from 'react-router-dom';
import "../css/SoaBanners.css";
import Accordion from "../components/Accordion.js";
import { ReactComponent as Phase1 } from "../SVG/SOA_Phases_SVG/Microsites-phase-1.svg";
import { ReactComponent as Phase2 } from "../SVG/SOA_Phases_SVG/Microsites-phase-2.svg";
import { ReactComponent as Phase3 } from "../SVG/SOA_Phases_SVG/Soa-Phase-3.svg";
import { ReactComponent as Phase4 } from "../SVG/SOA_Phases_SVG/Microsites-phase-4.svg";
import { ReactComponent as Phase5 } from "../SVG/SOA_Phases_SVG/Microsites-phase-5.svg";
import { handleScroll } from "../utils/FlowHelper.js";



const SoaBanners = () => {

  const [isPhase1Open, setIsPhase1Open] = useState();
  const [isPhase2Open, setIsPhase2Open] = useState();
  const [isPhase3Open, setIsPhase3Open] = useState();
  const [isPhase4Open, setIsPhase4Open] = useState();
  const [isPhase5Open, setIsPhase5Open] = useState();

  const svgRefPhase1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("rect-1");

  const svgRefPhase2 = useRef();
  const [P2visibleSection, setP2visibleSection] = useState("rect-1");

  const svgRefPhase3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("rect-1");

  const svgRefPhase4 = useRef();
  const [P4visibleSection, setP4visibleSection] = useState("rect-1");

  const svgRefPhase5 = useRef();
  const [P5visibleSection, setP5visibleSection] = useState("rect-1");

  function setVisibility(phaseName, elemName, elemId) {
    switch (phaseName) {
      case "phase-1":
        elemName.addEventListener("click", () => {
          setP1visibleSection(elemId);
        })
        break;

      case "phase-2":
        elemName.addEventListener("click", () => {
          setP2visibleSection(elemId);
        })
        break;
      case "phase-3":
        elemName.addEventListener("click", () => {
          setP3visibleSection(elemId);
        })
        break;
      case "phase-4":
        elemName.addEventListener("click", () => {
          setP4visibleSection(elemId);
        })
        break;
      case "phase-5":
        elemName.addEventListener("click", () => {
          setP5visibleSection(elemId);
        })
        break;
      default:
        break;
    }
  }

  function dynamicElement(pahseNo, svg, rectNo) {
    for (let i = 1; i <= rectNo; i++) {
      let rect = svg.getElementById(`rect-${i}`);
      setVisibility(pahseNo, rect, `rect-${i}`)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const svg = svgRefPhase1.current;
    dynamicElement("phase-1", svg, 2)
  }, []);

  useEffect(() => {
    const svg = svgRefPhase2.current;
    dynamicElement("phase-2", svg, 3)

    const rect4 = svg.getElementById("rect-4");
    rect4.addEventListener("click", () => {
      handleScroll("accordion3");
      setIsPhase3Open(true);
    });

  }, []);

  useEffect(() => {
    const svg = svgRefPhase3.current;
    dynamicElement("phase-3", svg, 8)

    const rect9 = svg.getElementById("rect-9");
    rect9.addEventListener("click", () => {
      handleScroll("accordion2");
      setIsPhase2Open(true);
      setIsPhase3Open(false);
    });

  }, []);

  useEffect(() => {
    const svg = svgRefPhase4.current;
    dynamicElement("phase-4", svg, 4)

    const rect5 = svg.getElementById("rect-5");
    rect5.addEventListener("click", () => {
      handleScroll("accordion2");
      setIsPhase2Open(true);
      setIsPhase3Open(false);
    });

    const rect6 = svg.getElementById("rect-6");
    rect6.addEventListener("click", () => {
      handleScroll("accordion5");
      setIsPhase5Open(true);
      setIsPhase2Open(false);
      setIsPhase3Open(false);
    });


  }, []);

  return (
    <div className="be_wrapper">
      <div id="soa-banners" className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/soa">SOA</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  SOA Banners
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* Page Headings */}
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">  Source of Authority Banners </h2>
            <p>
              <em>
              Source of Authority (SOA) is a Third-Party website where Lilly might promote content related to a brand or a therapy area. This content is often advertised by creating a microsite and then driving traffic to this microsite through multiple tactics such as banners.  
              </em>
            </p>

          </div>
        </div>
      </div>
      {/* Horozontal Tabs */}
      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-bs-toggle="tab"
              href="#microsites-tab-1"
            >
              Veeva Job Bag Standards
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              href="#microsites-tab-2"
            >
              Useful Links
            </a>
          </li>
        </ul>

        <div id="tabContent" className="tab-content">

          <div id="microsites-tab-1" className="tab-pane fade in active tabs_box tabContent">
            <p>
              <strong>Piece</strong>
            </p>

            <ul className="ul_padding micro-unorder-list">
              <li>
                <p className="tabContent">
                  <strong> <u>FOR UK AND IRELAND</u> - </strong>  &nbsp;
                  Desktop and mobile site screenshots of all pages including functionality (in the development stage). The screenshots only need to be of the site with the ‘hamburger’ menu. If final form is the same, screenshots of what it looks like on an iPad, iPhone, Android etc. are not needed, unless the website needs to be optimised for a particular device.
                </p>

              </li>
              <li>
                <p>
                  <strong>If final forms are different</strong> &nbsp;
                  (including colours and font style) then a separate job bag number is required for desktop and mobile version.
                </p>

              </li>

              <li>
                <p>
                  <strong><u>FOR NORDICS</u>, </strong>
                  screenshots from desktop version are sufficient (screenshots of mobile version not needed) as the final form of certification is not needed.
                </p>

              </li>

            </ul>
            <p><strong>Purpose Field:</strong></p>
            <ul className="ul_padding micro-unorder-list">
              <li>Explain the purpose.</li>
              <li>Provide the link to the test site with a username and password.</li>
            </ul>
            <p><strong>Attachments:</strong></p>
            <ul className="ul_padding micro-unorder-list">
              <li>
                <strong><u>FOR UK AND ROI</u> – </strong> User journey flow showing how the healthcare professional (HCP) gets to the Lilly sponsored content.</li>
              <li>
                <strong><u>FOR UK AND ROI</u> – </strong> Mobile site and tablet site screenshots (if final form is the same).</li>
              <li>Change Document Template (if applicable).</li>
              <li>Screenshot of Third-Party consent wording for any broadcast email sent via a Third Party.</li>
              <li>Material Originators are responsible to check the website if the content matches the certified final form version from Veeva when it goes live. This confirmation email must be uploaded on Veeva <strong>immediately</strong> post ‘Go-Live’</li>
            </ul>
            <p><strong>Related Pieces:</strong></p>
            <ul className="ul_padding micro-unorder-list">
              <li>Include existing and related approved assets/material.</li>
            </ul>
            <p><strong>Linked Documents:</strong></p>
            <ul className="ul_padding micro-unorder-list">
              <li><strong><u>FOR UK AND ROI</u> – </strong> Include link to the certified PI</li>
              <li>Content references</li>
            </ul>
            <p><strong>Final Form:</strong></p>
            <ul className="ul_padding micro-unorder-list">
              <li>Working test site with full functionality.</li>
              <li>Material Originators are responsible to check the website if the content matches the certified final form version from Veeva when it goes live. This confirmation email must be uploaded on Veeva <strong>immediately</strong> post ‘Go-Live’.</li>
              <li>NOTE: With regards to disclaimers on countries Approved for Use. The Suggested wording is “For (GB / Northern Ireland or Ireland) HCPs only. (Delete countries as appropriate)”.</li>
            </ul>



          </div>

          <div id="microsites-tab-2" className="tab-pane fade tabs_box tabContent">
            <p className="tabContent">
              <a href={webConfig.SOANavigatorHome} target="_blank">
              SOA Navigator  </a> : This link helps to choose vendor and the tactic for a specific therapy area/country. It also covers file requirements of main SOAs in the UK.
            </p>
            <p className="tabContent">
              <a href={webConfig.PreferredSupplierList} target="_blank">
                Preferred Supplier List.xlsx (lilly.com)
              </a> :
              This link provides the list of preferred vendors for NE Hub countries. Material Owners are encouraged with the vendor in the list for NE Hub countries.
            </p>
            <p className="tabContent">
              <a href={webConfig.SupplierCreationPowerApp} target="_blank">
              Supplier Creation Power App:
              </a> :
              Working with a new vendor could be an option if there is a business need. This form should be filled if a new vendor is to be included to the Lilly Procurement system. 
            </p>
            <p className="tabContent">
              <a href={webConfig.ThirdPartyRiskChecklist} target="_blank">Third Party Risk Checklist (lilly.com)</a>
              &nbsp; : Material Owners need to review the Third Party Risk Checklist at the beginning of the campaign. They should contact the SOA Lead if there is a risk.
            </p>
            <p className="tabContent">
              <a href={webConfig.KPItemplate} target="_blank">KPI template.xlsx (lilly.com)</a>
              &nbsp; : This Template is a standard Template to be filled by the Third Party SOAs monthly to see the campaign results.
            </p>
          </div>
        </div>
      </div>
      {/*Add verticle Tab -  Accordians*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
            <button
              className="nav-link"
              id="v-pills-faqs-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-faqs"
              type="button"
              role="tab"
              aria-controls="v-pills-faqs"
              aria-selected="false"
            >
              FAQs
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">SOA Banners Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1">
                    <Accordion className="accordion1"
                      title="Phase 1: Planning"
                      defaultOpen={true}

                    >
                      <div className="row" style={{ margin: "0px" }}>
                        <div id="local-on24" className="col-lg-6 planning">
                          <Phase1 ref={svgRefPhase1} className="banners-svg-phase-1" />
                        </div>
                        <div className="col-lg-6 planning p_font pt-3 flowsSecondPart">
                          <div
                            style={{
                              display:
                                P1visibleSection === "rect-1" ? "block" : "none",
                            }}
                          >
                            <p className="flowsHeading">Campaign listed on CET   </p>
                            <p className="flowsContent">
                            Campaigns should be listed on CET as part of IPA process. Check if this material is part of your plans, review the set KPIs and tactic links on CET.
                            </p>
                          </div>
                          <div
                            style={{
                              display:
                                P1visibleSection === "rect-2"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <p className="flowsHeading">Pre-Veeva Meeting</p>
                            <p className="flowsContent">
                              Planning is everything! The core objectives of a pre-Veeva discussion should not be overlooked.
                            </p>
                            <p className="flowsContent">These are to ensure that:</p>
                            <ul className="ul_padding ul_margin_bottom micro-unorder-list">
                              <li className="flowsContent">
                                The reviewer is never presented with a material in Veeva Vault PromoMats (VVPM) that
                                they have no background information on, and
                              </li>
                              <li className="flowsContent">
                                The review team understands its current priorities for review.
                              </li>
                            </ul>
                            <p className="flowsContent">
                              Together these aim to provide a smoother and quicker approval process for materials.
                            </p>

                            <p className="flowsContent">
                              Visit <a href={webConfig.LillyPlanning} target="_blank">Planning (lilly.com)</a> to learn more about pre-Veeva meetings
                            </p>
                            <p className="flowsHeading"><i>REMEMBER</i></p>
                            <ul className="ul_padding ul_margin_bottom micro-unorder-list">
                              <li className="flowsContent">
                                Consider using Core Claims <a href={webConfig.PMAPCoreClaimsAllDocuments} target="_blank">PMAP - Core Claims - All Documents (lilly.com)</a> or Templates PMAP - <a href={webConfig.TemplatesLillyDocuments} target="_blank">Templates - All Documents (lilly.com)</a> as this will result in a quicker review and closer alignment to brand strategy
                              </li>
                              <li className="flowContent">
                                Explanation on both the microsite and chosen traffic drivers (email, banner, social media posts) should be done at the same time so that the reviewers can imagine the overall
                                campaign.
                              </li>
                            </ul>

                          </div>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                  <div id="accordion2">
                    <Accordion className="accordion2"
                      title="Phase 2: Material Readiness"
                      defaultOpen={false}
                      isOpen={isPhase2Open}
                    >
                      <div className="row" style={{ margin: "0px" }}>
                        <div id="local-on24" className="col-lg-6 planning">
                          <Phase2 ref={svgRefPhase2} className="banners-svg-phase-2" />
                        </div>
                        <div className="col-lg-6 planning p_font pt-3 flowsSecondPart">
                          <div
                            style={{
                              display:
                                P2visibleSection === "rect-1" ? "block" : "none",
                            }}
                          >
                            <p className="flowsHeading"> <string><u>Briefing</u></string>  </p>
                            <p className="flowsContent">
                              Ensure that the team working on material development fully understands the brief. Often a call is the best way to move things forward quickly. A good brief to the agency means that the Material
                              Administrator provides all necessary visuals, texts and links upfront. Briefing could be completed by
                              the Material Administrator. The creative agency should advise the Material Administrator on
                              delivery timelines after the briefing is received.
                            </p>
                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom micro-unorder-list">
                                <li className="flowsContent">The Digital Solutions Team (DST), Indegene, or the SOA may create the artwork. This differs
                                  as per the SOA, so ensure that this is aligned before creating a request with the relevant
                                  agency. For example, using Indegene to create the design and then the SOA to create the
                                  HTML from the artwork files. The Material Administrator needs to ensure that they
                                  understand the process which the particular vendor follows.</li>
                                <li className="flowsContent">
                                  If the DST is producing the material, briefing must be provided to them using the Template
                                  provided through the power app.
                                </li>
                                <li className="flowsContent">
                                  It is a good idea to check the <a href={webConfig.SOANavigatorHome} target="_blank">SOA-Navigator - Home (lilly.com)</a>.which covers technical
                                  requirements (e.g., file format they require) for the main SOA vendors.
                                </li>
                              </ul>
                            </p>


                          </div>
                          <div
                            style={{
                              display:
                                P2visibleSection === "rect-2"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <p className="flowsHeading">Artwork PDF Delivered  </p>

                            <p className="flowsContent">It is the responsibility of the Material Administrator to review the artwork provided for accuracy
                              before submitting to Medical, Legal, Regulatory (MLR) for approval. At this stage, it is best practice
                              to align with the reviewers before submitting the material to MRL.</p>
                          </div>
                          <div
                            style={{
                              display:
                                P2visibleSection === "rect-3"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <p className="flowsHeading">Artwork Meets Expectation? </p>

                            <p className="flowsContent">If the artwork meets expectations, then initiate MLR. If the artwork does not meet expectations,
                              then it is always advised to book a call with the agency to go through the changes required. (Backand-forth through email communication has proven to be time consuming!)</p>
                            <p className="flowContent">
                              All stand alone pieces must be approved as a separate job bag (prior to SOA piece
                              certification) - video can be hosted via Kaltura or Vimeo
                            </p>
                          </div>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                  <div id="accordion3">
                    <Accordion className="accordion3"
                      title="Phase 3: Material Approval and Launch"
                      defaultOpen={false}
                      isOpen={isPhase3Open}
                    >
                      <div className="row" style={{ margin: "0px" }}>
                        <div id="local-on24" className="col-lg-7 planning main-content">
                          <Phase3 ref={svgRefPhase3} className="microsites-svg-phase-3" />
                        </div>
                        <div className="col-lg-5 planning p_font pt-3 flowsSecondPart side-content">
                          <div
                            style={{
                              display:
                                P3visibleSection === "rect-1" ? "block" : "none",
                            }}
                          >
                            <p className="flowsHeading">Initiate MLR Approval</p>
                            <p className="flowsContent">
                              This is the stage where the Veeva Coordinator uploads the material to VVPM for reviewers to provide a verdict on it. Scroll up to see the VVPM Job bag Standards for SOA.
                            </p>
                            <p className="flowsHeading">Remember!</p>
                            <p className="flowContent">
                              The job bags of microsite and traffic driving materials should be linked to each other in the Related Pieces section of the metadata.
                            </p>

                          </div>
                          <div
                            style={{
                              display:
                                P3visibleSection === "rect-2"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <p className="flowsHeading">MLR Verdict</p>

                            <p className="flowsContent">There are three types of verdicts that the material could get:</p>

                            <p className="flowContent">
                              <ol className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                  <strong>Approved for Production –</strong> &nbsp; The material has been blessed by the reviewers. Go ahead and
                                  ask for the material to be developed into the final format.
                                </li>
                                <li className="flowContent"><strong>Approved with Changes –</strong> &nbsp; The material has some minor errors/updates required (like
                                  spelling, grammatical errors). Incorporate the advised updates/corrections on the material
                                  and ask for the material to be developed into the final format.</li>
                                <li className="flowContent">
                                  <strong>Revise and Resubmit – </strong> &nbsp; The material has too many problems and the reviewer advises the Veeva Coordinator to rework on it and submit again. Go ahead and ask the agency to re-do the artwork.
                                </li>
                              </ol>
                            </p>
                            <p className="flowContent">
                              Once the vendor has built the site in the final form (most likely HTML in the case of a microsite and
                              hosted behind a log-in on their website), they will provide the link (URL) and log-in details.
                            </p>
                          </div>

                          <div
                            style={{
                              display:
                                P3visibleSection === "rect-3"
                                  ? "block"
                                  : "none",
                            }}>
                            <p className="flowsHeading">SOA Provides Test Link </p>
                            <p className="flowContent">Ideally a link should be provided by SOA. Some SOAs in Nordics might not be able to provide a test
                              link. In such a scenario, screenshots from the SOA's publishing system could be acceptable for
                              certification in Nordics. </p>
                            <p className="flowContent">
                              Sermo/Medshr are unable to provide test link- The solution for UK certifications was to live demo
                              the site on a Teams call with the certifier and SOA.
                            </p>
                          </div>

                          <div
                            style={{
                              display:
                                P3visibleSection === "rect-4"
                                  ? "block"
                                  : "none",
                            }}>
                            <p className="flowsHeading">Initiate Certification  </p>
                            <p className="flowContent">This is the stage where the Veeva Coordinator uploads the final form of the material in VVPM for
                              Certifiers to provide a verdict on it. </p>
                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom micro-unorder-list">
                                <li className="flowsContent">
                                  <b><u>UK:</u></b> A staging link to the final forms of the microsite or banners should be mentioned on
                                  VVPM in the ‘Purpose’ section of the metadata and should include any passwords required
                                  to access the materials if required. Links to the staging sites can also be added as a comment
                                  at the top of the job bag.
                                </li>
                                <li className="flowContent">
                                  <b><u>NORDICS:</u></b> Request a staging link, if possible, to be able to check how the microsite and/or
                                  banner will look like. If it is not possible to get a staging link, upload a PDF for the microsite,
                                  a GIF/HTML for animated banner or a picture file for static banner.
                                </li>
                              </ul>
                            </p>
                            <p className="flowContent"><b><u>Depending on the SOA the certification can be done from:</u></b></p>
                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">  Test link / staging link</li>
                                <li className="flowsContent">  Added links to metadata <br />OR</li>
                                <li className="flowsContent">  PDF / screenshot from publishing system</li>
                              </ul>
                            </p>
                            <p className="flowContent">
                              See workflow step 'review live site(s) for content accuracy' for further documentation required
                            </p>
                          </div>

                          <div
                            style={{
                              display:
                                P3visibleSection === "rect-5"
                                  ? "block"
                                  : "none",
                            }}>
                            <p className="flowsHeading">Certification Verdict   </p>
                            <p className="flowContent">There are three types of verdicts the material could get:</p>
                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom micro-decimal-list">
                                <li className="flowsContent">
                                  <b>Certified –</b> The material is Approved for Distribution now. Go ahead to review/set Inventory
                                  Information in VVPM and set the <b>Actual Date of First Use.</b>
                                </li>
                                <li className="flowsContent">
                                  <b>Revise and Resubmit </b> (for certification) – The material has errors and/or requires updates.
                                  Go ahead and ask the DST to make the advised changes, trigger a new test link and resubmit for certification.
                                </li>
                                <li className="flowsContent">
                                  <b>Not Certified –</b> The material has too many problems, and the Certifier advises the Material
                                  Administrator to rework on it and start a new MLR workflow. Go ahead and talk to the
                                  agency to re-do the artwork.
                                </li>
                              </ul>
                            </p>
                            <p className="flowContent"><b>FOR NORDICS –</b> If the material gets the verdict <b>‘Not Certified’</b>, use the <b>‘Create Draft’</b> function in
                              VVPM to use the same job bag code for the updated material.
                            </p>
                            <p className="flowsHeading">Remember</p>
                            <ul className="ul_padding ul_margin_bottom micro-unorder-list">
                              <li className="flowContent">
                                The verdict <b>‘Certified’</b> does not mean that the material is now live. Additional steps are
                                needed by the SOA vendor to move it to the environment where it will be available to the
                                end user. This will normally be a back-end change made by the SOA vendor at request.
                              </li>
                            </ul>
                          </div>


                          <div
                            style={{
                              display:
                                P3visibleSection === "rect-6"
                                  ? "block"
                                  : "none",
                            }}>
                            <p className="flowsHeading">Final Steps Before Go-Live    </p>
                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom micro-unorder-list">
                                <li className="flowsContent"><strong>Actual Date of First Use</strong> is set by the Veeva coordinator and is typically put as the date the
                                  material has been certified on VVPM, unless there is a specific date of when the usage of a
                                  material will start (example launch date, after the salesforce training).</li>
                                <li className="flowContent">
                                  <strong>Inventory Information –</strong> Veeva Coordinators would have added this earlier to VVPM
                                  metadata and it should be checked when the material goes live. This step will support
                                  efficient material management and withdrawal
                                </li>
                                <li className="flowContent">
                                  <strong>Links to other job bags –</strong> Check that the links to related traffic driver job bags in the ‘Related
                                  Items’ section of the metadata is included.

                                </li>
                                <li className="flowContent">
                                  <strong>Expiry Date</strong> is usually set for 2 years (<b><u>UK AND IRELAND</u></b>) or 1 year (<b><u>NORDIC COUNTRIES</u></b>).
                                  However, if the material is a One-time use or for a defined time, set the expiry date
                                  accordingly. This helps with efficient material (inventory) management and withdrawal.
                                </li>
                              </ul>
                            </p>
                          </div>

                          <div
                            style={{
                              display:
                                P3visibleSection === "rect-7"
                                  ? "block"
                                  : "none",
                            }}>
                            <p className="flowsHeading"> Advise SOA agency to push site(s) live  </p>
                            <p className="flowContent">Advise the SOA vendor to go live.</p>
                          </div>

                          <div
                            style={{
                              display:
                                P3visibleSection === "rect-8"
                                  ? "block"
                                  : "none",
                            }}>
                            <p className="flowsHeading">Review live site(s) for content accuracy </p>
                            <p className="flowContent">The Material Administrator checks if the live site looks exactly like the Certified test site. If satisfied,
                              begin to use the traffic drivers.</p>


                          </div>


                        </div>
                      </div>
                    </Accordion>
                  </div>
                  <div id="accordion4">
                    <Accordion className="accordion4"
                      title="Phase 4: Material Management"
                      defaultOpen={false}
                    >
                      <div className="row" style={{ margin: "0px" }}>
                        <div id="local-on24" className="col-lg-7 planning">
                          <Phase4 ref={svgRefPhase4} className="microsites-svg-phase-4" />
                        </div>
                        <div className="col-lg-5 planning p_font pt-3 flowsSecondPart">

                          <div
                            style={{
                              display:
                                P4visibleSection === "rect-1"
                                  ? "block"
                                  : "none",
                            }}>
                            <p className="flowsHeading">Update CET on a monthly basis    </p>
                            <p className="flowContent">Ensure that tactic metrics are updated on Customer Engagement Tool (CET) on a monthly basis</p>
                          </div>

                          <div
                            style={{
                              display:
                                P4visibleSection === "rect-2"
                                  ? "block"
                                  : "none",
                            }}>
                            <p className="flowsHeading"> SOA Action to Meet Promised Touchpoints/Metrics   </p>
                            <p className="flowContent">
                              Actions taken by SOA could be:
                            </p>
                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">  Additional traffic drivers</li>
                                <li className="flowsContent">  Extended campaign time</li>
                                <li className="flowsContent">  Reduced price</li>
                                <li className="flowsContent">  Amendments to content (most likely requires new jog bag and MLR approval)</li>
                              </ul>
                            </p>
                          </div>

                          <div
                            style={{
                              display:
                                P4visibleSection === "rect-3"
                                  ? "block"
                                  : "none",
                            }}>
                            <p className="flowsHeading"> Quarterly Material Review by Brand Team   </p>
                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom micro-unorder-list">
                                <li className="flowsContent">
                                  For Operational Excellence in material management, NE Hub has a quarterly Review of
                                  Inventory. The Leader/Business Unit Director should lead this exercise with their team to
                                  review the list of materials in VVPM and decide whether to update the material, leave it as it
                                  is for now or withdraw it from VVPM.
                                </li>
                                <li className="flowsContent">Always check Inventory Information to see if any metadata needs to be updated. </li>
                              </ul>
                            </p>
                          </div>

                          <div
                            style={{
                              display:
                                P4visibleSection === "rect-4"
                                  ? "block"
                                  : "none",
                            }}>
                            <p className="flowsHeading"> Update of Material </p>
                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom micro-unorder-list">
                                <li className="flowsContent">An outcome of the quarterly material review (or other reasons such as PI update) might
                                  result in a need to update the material.</li>
                                <li className="flowsContent">Updates in the material initiates the process right from the beginning, i.e., ‘briefing’ and the
                                  withdrawal process of the existing version.</li>
                              </ul>
                            </p>
                          </div>


                        </div>
                      </div>
                    </Accordion>
                  </div>
                  <div id="accordion5">
                    <Accordion className="accordion5"
                      title="Phase 5: Material Withdrawal"
                      defaultOpen={false}
                      isOpen={isPhase5Open}
                    >
                      <div className="row" style={{ margin: "0px" }}>
                        <div id="local-on24" className="col-lg-6 planning">
                          <Phase5 ref={svgRefPhase5} className="microsites-svg-phase-5" />
                        </div>
                        <div className="col-lg-6 planning p_font pt-3 flowsSecondPart">

                          <div
                            style={{
                              display:
                                P5visibleSection === "rect-1" ? "block" : "none",
                            }}
                          >
                            <p className="flowContent">
                              Material Withdrawal phase starts with the brand team´s decision to discontinue a material and it
                              ends with the completed and documented destruction of the material.
                            </p>
                            <p className="flowContent">
                              The materials need to be removed from all storage locations and circulation before initiating the
                              withdrawal in the Withdrawal Tool if applicable. The Inventory Information field in VVPM will help
                              the Material Administrator to capture all storage locations for effective destruction.
                            </p>
                            <p className="flowsHeading">Withdrawal and Retirement </p>
                            <p className="flowContent">
                              <ul className="ul_padding ul_margin_bottom micro-unorder-list">
                                <li className="flowsContent"> Material Administrator reviews Inventory Information on VVPM to check all associated assets</li>
                                <li className="flowContent">
                                  Material Administrator asks the vendor to remove all material relating to the campaign (microsite, banners and email traffic drivers) by providing a list of asset names and job bag codes.

                                </li>
                                <li className="flowContent">
                                  Vendor confirms when the materials have been removed from their platforms
                                </li>
                                <li className="flowContent">
                                  Material Administrator uploads the vendor’s confirmation email into individual job bags on VVPM as ‘Attachment’.
                                </li>
                                <li className="flowContent">
                                  Veeva Coordinator retires the job bags on VVPM.
                                </li>
                              </ul>
                            </p>

                          </div>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
            {/*End Lifecycle */}

            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Role </th>
                      <th>Responsibility</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="tb">
                        <h6>SOA Lead</h6>
                      </td>
                      <td className='tb'>
                        <ul className="ul_list micro-decimal-list">Responsible for:
                          <li>Overall reach and capability of SOA across the Hub. </li>
                          <li>Must be consulted as well as procurement when working with new SOA </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Campaign Owner (Brand Manager or MCE Manager)</h6>
                      </td>
                      <td>
                        <ul className="ul_list micro-decimal-list">Responsible for:
                          <li>Acting as main point of contact for the SOA campaign. </li>
                          <li>Ensuring involvement of SOA lead and procurement where appropriate.</li>
                          <li> Managing approval process. </li>
                          <li> Updating CET and reporting campaign results to SOA lead. </li>
                          <li> Withdrawal</li>
                        </ul>
                      </td>
                    </tr>

                    <tr>
                      <td className="tb">
                        <h6>Procurement</h6>
                      </td>
                      <td>
                        <ul className="ul_list micro-decimal-list">Responsible for:
                          <li> Contracting process and negotiation for any activity over  25k </li>
                          <li> Risk assessment</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>DST</h6>
                      </td>
                      <td>
                        <ul className="ul_list micro-decimal-list">Responsible for:
                          <li> Artwork as per material originator brief (if not using SOA or agency)</li>
                          <li> Creating HTML package as per material originator brief and providing to
                            the originator to share with the SOA</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                        <td className="tb">
                            <h6>Material Administrator</h6>
                        </td>
                        <td>
                          This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                        </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Material Originator</h6>
                      </td>
                      <td>
                        <p>Responsible for content conceptualisation and brand strategy and MLR
                          approval. Agree on KPIs with campaign owner and SOA. Raise PO. Will often
                          act as 'campaign owner' *link to campaign owner def. Overall responsibility
                          for material withdrawal.</p>
                      </td>
                    </tr>


                    <tr>
                      <td className="tb">
                        <h6>Veeva Owner</h6>
                      </td>
                      <td>
                        <p>The Veeva Owner is also known as the Uploader (please see 'Veeva uploader').</p>
                      </td>
                    </tr>

                    <tr>
                      <td className="tb">
                        <h6>Material Originator</h6>
                      </td>
                      <td>
                        <ul className="ul_list micro-decimal-list">Responsible for:
                          <li> Having overall responsibility and accountability of the material.</li>
                          <li> Ensuring that no item is used without the necessary approvals.</li>
                          <li> Ensuring timely change and/or withdrawal, where needed.</li>
                        </ul>
                        <p>For further information, please see <a href={webConfig.PMAPJobAid} target="_blank">PMAP Job Aid</a> .</p>
                      </td>
                    </tr>

                    <tr>
                      <td className="tb">
                        <h6>Veeva Uploader</h6>
                      </td>
                      <td>
                        <p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA
                          teams, and is assigned the Veeva Owner.</p>
                        <ul className="ul_list micro-decimal-list" >Responsible for:
                          <li> Uploading the material to Veeva</li>
                          <li> Performing all activities related to upload</li>
                        </ul>
                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>

                    <tr>
                      <td className="tb">
                        <h6>Veeva Co-ordinator</h6>
                      </td>
                      <td>
                        <p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager (or similar
                          role). </p>
                        <ul className="ul_list micro-decimal-list">
                          <li>Responsible for:</li>
                          <li> Reviewing the item before sending for MLR review</li>
                          <li> Managing the document workflow</li>
                          <li> Assigning reviewers</li>
                          <li> Managing expiry or periodic review</li>
                        </ul>
                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Business Reviewer</h6>
                      </td>
                      <td>
                        <p>Business Reviewer is an experienced commercial employee.</p>
                        <ul className="ul_list micro-decimal-list">
                          <li>Responsible for:</li>
                          <li> Ensuring all promotional materials align with the brand strategy, Lilly Brand Book, Product Brand
                            Book</li>
                          <li> Ensuring all materials are of good quality, have correct Veeva metadata and adhere to internal
                            policies and local codes</li>
                        </ul>
                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>UK Medical Certifier</h6>
                      </td>
                      <td>
                        <p>Registered medical practitioner or UK-registered Pharmacist.</p>
                        <ul className="ul_list micro-decimal-list">
                          <li>Responsible for:</li>
                          <li> Ensuring that the material is in accordance with the requirements of the local code.</li>
                          <li> Ensuring that the material is not inconsistent with the marketing authorisation/SmPC (if applicable).
                          </li>
                          <li> Ensuring that the material is a fair and truthful representation of the facts about the medicine (if
                            applicable).</li>
                          <li> Approving the material in its final form.</li>
                          <li> Ensuring the material has the appropriate reviewers and that the Veeva metadata is correct.</li>
                        </ul>
                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>UK Technical Medical Reviewer</h6>
                      </td>
                      <td>
                        <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                          references that have been used and for ensuring that the Veeva metadata is correct and that the PMC
                          documents are included (if applicable).</p>

                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Nordic Code Approver</h6>
                      </td>
                      <td>
                        <p>Code Approver must be a medical practitioner or a pharmacist (except for in Sweden) and is responsible for
                          ensuring that the material is in line with the local Codes and that the material being approved has the
                          appropriate reviewers; and ensuring that the Veeva metadata is correct.</p>

                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Nordics Technical Medical Reviewer</h6>
                      </td>
                      <td>
                        <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                          references that have been used and for reviewing the material from a medical perspective.</p>

                        <p>For further information, please see <a
                          href={webConfig.PMAPJobAid}
                          target="_blank">PMAP Job Aid.</a></p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Indegene</h6>
                      </td>
                      <td>
                        <p>Responsible for:</p>
                        <ul className="ul_list micro-decimal-list">
                          <li> Creating artwork as per material originator's brief</li>
                          <li> Creating formatted PI PDFs  (UK only and Nordics as well)</li>
                        </ul>
                      </td>
                    </tr>


                  </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>What it Stands for  </strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                      Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Banner </td>
                    <td className="tb"></td>
                    <td className="tb">The use of a graphic display that stretches across the top, bottom or sides of a webpage. Horizontal banners are called a Leaderboard, while vertical banners are called a Skyscraper and positioned on the side of a webpage. Banners can have various formats, the most common being HTML or .gif. HTML banners can be static or animated and can have multiple click-throughs in the content. .GIF banners can also be animated but smaller in size and only allow 1 click-through.</td>
                  </tr>
                  <tr>
                    <td className="tb">Booth</td>
                    <td className="tb"></td>
                    <td className="tb">
                      Designated space purchased by Lilly for a congress or event. This space can feature a stand with promotional imagery and pull-up banners.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool </td>
                    <td className="tb">Planning and execution tools combine to form the CET. With CET, customer plans can be created and campaigns can be managed in one place. </td>
                  </tr>

                  <tr>
                    <td className="tb">Conversion Metric </td>
                    <td className="tb"></td>
                    <td className="tb">Metrics used to measure the number of valuable actions customers take through a digital marketing channel. </td>
                  </tr>
                  <tr>
                    <td className="tb">CSA </td>
                    <td className="tb">Control Self Assessment </td>
                    <td className="tb">A quality check performed by process owners quarterly. The results are shared and discussed with HLT with measures put in place to mitigate any identified issues.  </td>
                  </tr>
                  <tr>
                    <td className="tb">CTA </td>
                    <td className="tb">Call to Action </td>
                    <td className="tb">The next step a Marketer wants its audience or reader to take. For example, a button or video in an email that the recipient can click on.</td>
                  </tr>
                  <tr>
                    <td className="tb">CTOR </td>
                    <td className="tb">Click-through-to-open rate</td>
                    <td className="tb">Email conversion metric used to review the
                      effectiveness of the email content. This
                      measures the number of people who clicked
                      on an email link to the total number of
                      people who opened the email.   </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey </td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>

                  <tr>
                    <td className="tb">DST</td>
                    <td className="tb">Digital Solutions Team </td>
                    <td className="tb"> Team of content developers dedicated to digital asset production in NE Hub. </td>
                  </tr>
                  <tr>
                    <td className="tb">Hyperlink</td>
                    <td className="tb"></td>
                    <td className="tb">A link within one digital item to another
                      location, activated by clicking on a
                      highlighted word or image.</td>
                  </tr>

                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">Goals or Metrics set behind a campaign and or tactic.</td>
                  </tr>

                  <tr>
                    <td className="tb">MCE</td>
                    <td className="tb">Multichannel Engagement</td>
                    <td className="tb">Multichannel engagement campaigns engage
                      effectively with the right HCPs and patients
                      at the right time with the appropriate
                      channel mix (e.g. SOA, eP2P and VOD).</td>
                  </tr>
                  <tr>
                    <td className="tb">OR</td>
                    <td className="tb">Open Rate </td>
                    <td className="tb">Email conversion metric used to review the
                      effectiveness of the email content. This
                      measures the number of people who opened
                      an email to the total number of people the
                      email was delivered to.  </td>
                  </tr>
                  <tr>
                    <td className="tb">PIA</td>
                    <td className="tb">Privacy Impact Assessment</td>
                    <td className="tb">A thorough review and understanding of how Personal data will be processed, stored, managed.</td>
                  </tr>
                  <tr>
                    <td className="tb">PMC</td>
                    <td className="tb">Permissions Managed Content </td>
                    <td className="tb">An image which is owned by an entity other than Lilly and for which permission is given to use under a specifc set of circumstances as defined in a contractural agreement between Lilly and the Content Owner. </td>
                  </tr>
                  <tr>
                    <td className="tb">Stand</td>
                    <td className="tb"></td>
                    <td className="tb">
                      Designated space purchased by Lilly for a congress or event. This space can feature a stand with promotional imagery and pull-up banners.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"> </td>
                    <td className="tb">Tactics are specific to selected channels to drive customers to reach a piece of content. Tactics are associated with estimated KPI metric (goal) and result reports (actuals).  </td>
                  </tr>
                  <tr>
                    <td className="tb">Touchpoint</td>
                    <td className="tb"></td>
                    <td className="tb">Touchpoints is terminology used to identify
                      the volume and frequency our tactics reach
                      customers. These touchpoints can be deep
                      (often more in-depth pieces of content, more
                      likely to drive behavioural change) or light
                      (may be transactional or driving to 1 specific
                      action or view). </td>
                  </tr>
                  <tr>
                    <td className="tb">VVPM</td>
                    <td className="tb">Veeva Vault PromoMat</td>
                    <td className="tb">Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems. PromoMats is a Vault application to manage the process of creating, reviewing and approving promotional materials, claims and supporting information.</td>
                  </tr>
                  <tr>
                    <td className="tb">Webpage </td>
                    <td className="tb"></td>
                    <td className="tb">A webpage developed for the purpose of
                      completing a transaction. Examples: filling
                      out a form, microsites, splashpages.  </td>
                  </tr>
                  <tr>
                    <td className="tb">Traffic driver</td>
                    <td className="tb"></td>
                    <td className="tb">A tactic used for promoting another piece of material with the aims of increasing reach of the key content. Traffic drivers can often include emails and banners.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Defination*/}
            {/* Start Core Docs */}
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Documents</h4>
<br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.SOABannersCoreDoc}
                  target="_blank"
                >
                  SOA document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/* End Core Docs */}

            {/*Add Faqs*/}
            <div
              className="tab-pane fade"
              id="v-pills-faqs"
              role="tabpanel"
              aria-labelledby="v-pills-faqs-tab"
            >
              <h4 className="h1-font comman_hd">FAQs</h4>
              <div id="FAQ1">
                <Accordion
                  className="faqAccordion"
                  title="What are the important steps while engaging with an SOA?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      There are seven steps while engaging with SOA:
                    </p>
                    <ul className="ul_padding microsites-decimal-list">
                      <li> Selecting the SOA
                      </li>
                      <li>
                        Briefing the SOA
                      </li>
                      <li>
                        Conducting Risk Assessments
                      </li>
                      <li> Ensuring correct Procurement procedures are followed </li>
                      <li> Creating materials </li>
                      <li> Managing materials  </li>
                      <li> Withdrawing materials</li>
                    </ul>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ2">
                <Accordion
                  className="faqAccordion"
                  title="How do I know who the best SOA Vendors are to work for my brand or Therapy Area?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                    With so many Vendors to choose from, it is important to understand the answers to the following questions to select the best option for the campaign. Find the answers to all these questions (and more!) on SOA Navigator <a href={webConfig.SOANavigatorHome} target="_blank">SOA-Navigator - Home (lilly.com)</a>. 
                    </p>
                    <ul className="ul_padding microsites-unorder-list">
                      <li>Which SOA facilitates what kind of tactics? </li>
                      <li>What is their reach within the Target customer base? </li>
                      <li>How much could the campaign cost? </li>
                      <li>What level of customer engagement could be generated? </li>
                      <li>What specification of artwork do they require?</li>
                      <li>What documents will be required for MLR approval of your tactics?</li>
                    </ul>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ3">
                <Accordion
                  className="faqAccordion"
                  title="I am from PRA and/or Medical function, how do I find an SOA relevant to my business requirement and target base?  "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>SOA Navigator provides SOA Navigator <a href={webConfig.SOANavigatorHome} target="_blank">SOA-Navigator - Home (lilly.com)</a> details on Third Party SOA partner for Key Account Managers (KAMs) and Medical function as well.  </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ4">
                <Accordion
                  className="faqAccordion"
                  title="What is DIM/PIA and when do I need this for SOA campaign? "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>Data Inventory Management (DIM) or Privacy Impact Assessment (PIA) needs to be completed if data is being exchanged, stored or processed through SOA campaign. For any doubt, please get in touch with local E&C when sharing/exchanging customer data.    </p>
                  </div>
                </Accordion>
              </div>
              
              <div id="FAQ5">
                <Accordion
                  className="faqAccordion"
                  title="How do I know if the SOA I want to work with is on the preferred Vendor list?   "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p><a href={webConfig.PreferredSupplierList} target="_blank">Preferred Supplier List.xlsx (lilly.com)</a>: You can see the list of preferred Vendors for NE Hub countries. Material Owners are encouraged with the Vendor in the list. Alternatively, discuss this with the Procurement team.  </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ6">
                <Accordion
                  className="faqAccordion"
                  title="How do I know if Vendor has completed all the Procurement and compliance requirements?  "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>If the Vendor is on the Preferred Vendor list for the tactic you are engaging them for, they would have performed Risk Assessment. For any clarification, you can either reach out to their designated Engagement Owner or Procurement. For a New Vendor, there will need to be an Engagement Owner assigned from Business (likely to the team that wishes for New Vendor to be onboarded) and the Engagement Owner will have all the updates on assessments being completed for the Vendor.    </p>
                  </div>
                </Accordion>
              </div>

              <div id="FAQ7">
                <Accordion
                  className="faqAccordion"
                  title="My SOA vendor wants to take me for dinner to discuss the campaign. Can I go?  "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>A. Probably not. You need to adhere to the Lilly Expenses SOP meal limits, even though you are not paying for the meal yourself. Refer to the internal policy document.</p>
                  </div>
                </Accordion>
              </div>

              <div id="FAQ8">
                <Accordion
                  className="faqAccordion"
                  title="The SOA is committed to meeting certain KPIs, but they haven’t managed to achieve the target goals. What are my options?  "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>A. To ensure that we meet our campaign metrics, it is crucial to follow up on the performance of the campaign. If the SOA does not meet the expectations, you should contact the SOA and explore potential alternatives to improve the performance. Options could be to include an additional traffic driver for the campaign or extend the campaign period at no extra cost. If the campaign cost is above £25,000, we must involve the Procurement in our discussion.</p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ9">
                <Accordion
                  className="faqAccordion"
                  title="SOA is asking for money to be paid in advance. Can I do this?  "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>A. Lilly Procurement policy does not support pre-payments. </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ10">
                <Accordion
                  className="faqAccordion"
                  title="I need to stop my SOA campaign mid-way through. What are my rights in regard to the refund part of the original Purchase Order (PO)?   "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>A. You need to review your contract and examine the rights outlined there. Collaborate closely with the Procurement team to understand your contractual rights.   </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ11">
                <Accordion
                  className="faqAccordion"
                  title="Are SOA emails implicated by the NE Hub email guidance?    "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                  <p>Yes. SOA emails must be considered when limiting the number of times, the content is sent to the same customers. Please refer to the <a href={webConfig.NEHubEmailVolumeandGuidance} target="_blank"> NE Hub Email Volume Guidance link</a> for full details. </p>
                  </div>
                </Accordion>
              </div>


              
            </div>
            {/*End Faqs*/}
          </div>
        </div>
      </div>
      <div id="microsite-popup-1" className="overlay_flight_traveldil">
          <div className="popup_flight_travlDil">
            <p className="flowsHeading">Remember</p>
            <p className="flowContent">
              <ul className="ul_padding ul_margin_bottom micro-unorder-list">
                <li className="flowContent">
                  <b>FOR NORDIC COUNTRIES:</b> Please check the <a href={webConfig.NordicPMAPGuidance} target="_blank">Nordic PMAP Guidance</a>  for detailed information
                  on statutory copy submission
                </li>
              </ul>
            </p>
            <a className="close_flight_travelDl" href="#/">
              &times;
            </a>
          </div>
        </div>
    </div>
  );
};


export default SoaBanners;
