import React from "react";
import { useState, useRef, useEffect } from "react";
import {webConfig} from '../configs/webConfig'
import { Link } from 'react-router-dom';
import "../css/LocalOn24.css";
import Accordion from "../components/Accordion.js";
import { ReactComponent as Phase1 } from "../SVG/Meetings_Phases_SVG/LOCAL-ON24-PHASE-1.svg";
import { ReactComponent as Phase2 } from "../SVG/Meetings_Phases_SVG/LOCAL-ON24-PHASE-2.svg";
import { ReactComponent as Phase3 } from "../SVG/Meetings_Phases_SVG/LOCAL-ON24-PHASE-3.svg";
import { handleScroll } from "../utils/FlowHelper.js";



const LocalOn24 = () => {

  const [isPhase2Open, setIsPhase2Open] = useState();

  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("rect-1");

  const svgRef2 = useRef();
  const [P2visibleSection, setP2visibleSection] = useState("rect-1");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("rect-1");

  function setVisibility(phaseName, elemName, elemId){
    switch (phaseName) {
      case "phase-1":
        elemName.addEventListener("click" , () => {
          setP1visibleSection(elemId);  
        })  
        break;
    
      case "phase-2":
        elemName.addEventListener("click" , () => {
          setP2visibleSection(elemId);  
        })  
        break;
      case "phase-3":
        elemName.addEventListener("click" , () => {
          setP3visibleSection(elemId);  
        })  
        break;
      default:
        break;
    }
  }

  function dynamicElement(pahseNo,svg, rectNo){
    for(let i = 1; i <= rectNo; i++){
      let rect = svg.getElementById(`rect-${i}`);
      setVisibility(pahseNo,rect, `rect-${i}`)
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    const svg = svgRef1.current;
    dynamicElement("phase-1",svg, 5)

    const rect6 = svg.getElementById("rect-6");
    rect6.addEventListener("click", () => {
      handleScroll("accordion2");
      setIsPhase2Open(true);
    });

  }, []);


  useEffect(() => {
    const svg = svgRef2.current;
    dynamicElement("phase-2", svg, 3)

    const rect4 = svg.getElementById("rect-4");  
    rect4.addEventListener("click", () => {
      handleScroll("accordion1");
      setIsPhase2Open(false);
    });
  }, []);

  useEffect(() => {
    const svg = svgRef3.current;
    dynamicElement("phase-3", svg, 2)
  
  }, []);

  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/meetings">Meetings</Link>  
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                <Link to="/meetings/local-webinar">Local Webinar (eP2P)   </Link>  
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Local ON24 platform creation and approval
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">  Local ON24 Platform Creation and Approval </h2>
            <p className="tab-hide">
              <em>
                    <s>Local Webinar ON24 Platform creation and approvals is a Lilly organised Meeting set up in a way so that 
                    the Speaker presents virtually from comfort of their own office/home to a group 
                    of healthcare professionals (HCPs) attending virtually from different locations. 
                    These events or Meetings are arranged and owned locally by the affiliate 
                    (opposed to International Business Unit [IBU] or Global), usually by Marketing or 
                    Medical and can be Meetings to promote or Meetings to educate. 
                    </s>
              </em>
            </p>
            
          </div>
        </div>
      </div>

      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link navLinkTab active tab-hide"
              data-bs-toggle="tab"
              href="#processPreRequisites"
            >
              Process
              <br />
              Pre-requisites
            </a>
          </li>
          <li className="nav-item">
            <a
                className="nav-link active navLinkTab"
                data-bs-toggle="tab"
                href="#VeevaJobBagStandards"
            >
                Veeva Job 
                <br />
                Bag Standards 
            </a>
          </li>
        </ul>
        
        <div id="tabContent" className="tab-content">
            <div id="processPreRequisites" className="tab-pane tab-hide fade in active tabs_box tabContent">
                <div>
                  <ul className="ul_padding">
                    <li className='tabContent'>● Process subject to approval of activity in TL-MAP. </li>
                    <li className='tabContent'>
                        ● Process subject to completion of NE HUB CMS: Head-Office Meeting Training (Course 9397172) and associated procedures.
                         (All Meetings related procedures can be found at {' '}
                         <Link
                            to={webConfig.MeetingsRelatedInformation}
                            target="_blank"
                          > Meetings Related Information </Link> {' '} .) 
                    </li>
                    <li className='tabContent'>
                        ● Process subject to Mercury training and access – contact Lilly Meeting Centre (LMC) at <strong>LMC_HO@lilly.com </strong>. 
                    </li>
                  </ul>
                </div>
            </div>

            <div id="VeevaJobBagStandards" className="tab-pane fade active tabs_box tabContent">
                <p className="tabsContentHeading">Piece:</p>
                <p>
                   The webinar/webcast materials can be approved as one piece or separately as follows: 
                </p>
                <ul className="ul_padding">
                    <li>
                        ● Screenshot of the console and PI (ensure that all widgets are screenshot). 
                    </li>
                    <li>
                        ● Screenshot of registration page, lobby page and automated/administrative registration confirmation emails that are triggered by ON24 platform. 
                    </li>
                </ul>

                <p className="tabsContentHeading">Title field:</p>
                <p>Webinar/Meeting Title – This should be same as the actual meeting title and should match the input on the Customer Engagement Tool (CET) as well.  </p>

                <p className="tabsContentHeading">Purpose Field:</p>
                <ul className="ul_padding">
                   <li> ● Explain the purpose of the webinar. </li>
                </ul>

                <p className="tabsContentHeading">Method of Dissemination:</p>
                <p>In the method of Dissemination Details field, outline how traffic will be driven to the sites (e.g., print invitation, broadcast email etc.).  </p>
                <ul className="ul_padding">
                   <li> ●  If the Method of Dissemination in the metadata states that the slides can be downloaded or sent via email to healthcare professionals (HCPs), the following disclaimer is to be added to the first slide: <i>“The content of these slides is Lilly property. You are permitted to download and use these slides for your own personal use only. Under no circumstances should these slides be distributed to third parties.”</i>  </li>
                </ul> 

                <p className="tabsContentHeading">Attachments: </p>
                <ul className="ul_padding">
                   <li> ● Change Document Template (if applicable).  </li>
                   <li> ● Document with links to live sites (put the registration, lobby and console page hyperlinks in a document and add to Attachments of the piece).  </li>
                </ul> 

                <p className="tabsContentHeading">Linked Documents:  </p>
                <ul className="ul_padding">
                   <li> ● Current PI (if PI is included on Video rather than a link to PI)</li>
                   <li> ● Content references anchored appropriately (if applicable) </li>
                   <li> ● Link any other resources available within the console, e.g., workbooks or certificates. </li>
                   <li> ● Slides or pre-recorded video   </li>
                </ul>  

                <p className="tabsContentHeading">Related Permission Managed Content:  </p>
                <ul className="ul_padding">
                   <li> ● Include required Permission Managed Content (PMC) documents, for example, speaker’s photo.  </li>
                   <li> ● PMC is not required for <strong>Lilly product images</strong>. Check with Regulatory or Quality Assessment (QA) if it is the correct device image.  </li>
                </ul>  

                <p className="tabsContentHeading">Registration Page:   </p>
                <ul className="ul_padding">
                   <li> ● If the webinar is running at two or three timeslots during the day, only screenshot one and add a note to state the second/third time on the piece. </li>
                   <li> ● At certification, Approvers need to see two/three separate times with links which <strong>should</strong> be in the Attachments.  </li>
                   <li> ● If using Lilly Passport, screenshot the page as part of the job bag and link the Related Documents to the Lilly Passport job bag.  </li>
                </ul> 

                <p className="tabsContentHeading">Lobby Page:  </p>
                <ul className="ul_padding">
                   <li> ● Screenshot the lobby page as part of the piece if the webinar is at one time. </li>
                   <li> ● If the webinar has two or three timeslots, add a note to the piece that states the extra time. The only change is the time of the webinar.  </li>
                   <li> 
                        ● Ensure that the following statements are on the lobby page:
                        <ul>
                            <li> Disclaimer for funding of events.</li>
                            <li> “This calendar notification is for the sole use of the intended recipients and should not be forwarded.” </li>
                        </ul>  

                   </li>
                </ul>  

                <p className="tabsContentHeading">Console:</p>
                <p className="tabsContentHeading">Fully label the console page, and signpost everything on the page.  </p>
                <ul className="ul_padding">
                   <li> ● If there is a third-party site being linked, ensure that the following disclaimer is added to the console page “<i>The x widget will take you to a site maintained by a third party who is solely responsible for the content. Eli Lilly and Company does not control or endorse this website and is not responsible for its privacy policy.</i>” </li>
                   <li> ● When using polling, the poll questions <strong>should</strong> be certified as part of the speaker slides. Attach the screenshot of format of the poll to see how it looks as a part of the console.  </li>
                   <li> ● Link slides to the Related Piece as part of the job bag.  </li>
                   <li> ● Check if the Privacy Notice is present.    </li>
                </ul>

                <p className="tabsContentHeading">Email Notifications:   </p>
                <ul className="ul_padding">
                   <li> ● Adverse Event/Product Complaint (AE/PC) wording to be on all promotional emails. </li>
                   <li> ● If the product name is mentioned, it needs the PI.</li>
                   <li> ● Add all emails in Attachments. </li>
                   <li> ● Include screenshot of the opt-out page.</li>
                </ul> 

                <p className="tabsContentHeading">Calendar Notifications:   </p>
                <ul className="ul_padding">
                   <li> ● Screenshot one and signpost change if there is more than one timeslot. </li>
                   <li> ● Ensure that the following statement is added on calendar notifications: “This calendar notification is for the sole use of the intended recipients and should not be forwarded."   </li>
                   <li> ● Screenshot the outlook and chrome calendar visuals. </li>
                   <li> ● Include screenshot of Notification(s)   </li>
                </ul> 

                <p className="tabsContentHeading">Subject Line:  </p>
                <ul className="ul_padding">
                   <li> ● No need for “promotional webinar” in subject line; however, it may be appropriate to include “Lilly meeting/event invitation” in the subject line. </li>
                   <li> ● When sending an Invitation Email, same email content with different subject line and/or pre-header will require a separate job bag.  </li>
                   <li> ● Sender name on platform emails should have Lilly mentioned (e.g., Lilly Dermatology/Lilly Diabetes). </li>
                </ul> 

                <p className="tabsContentHeading">Final Form: </p>
                <ul className="ul_padding">
                   <li> ● Live test sites with working functionality. </li>
                </ul> 

                <p className="tabsContentHeading">Notes:</p>
                <ul className="ul_padding">
                   <li> ● The Originator <strong>should</strong> check the customer journey and all links before putting the job bag into approval. The Originator <strong>should</strong> check the customer journey and all links before putting the job bag into approval.  </li>
                </ul>


            </div>
        </div>
      </div>
      
      {/*Add verticle Tab*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link tab-hide"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
            <button
              className="nav-link"
              id="v-pills-faqs-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-faqs"
              type="button"
              role="tab"
              aria-controls="v-pills-faqs"
              aria-selected="false"
            >
              FAQs
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">Local ON24 Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1">
                  <Accordion className="accordion1"
                    title="Phase 1: Platform Readiness"
                    defaultOpen={true}
                    
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div id="local-on24" className="col-lg-6 planning main-content">
                         <Phase1 ref={svgRef1} className="localOn24-svg-phase-1" />
                      </div>
                      <div className="col-lg-6 planning p_font pt-3 flowsSecondPart side-content">
                        <div
                          style={{
                            display:
                              P1visibleSection === "rect-1" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">Local eP2P ON24 Platform  </p>
                          <p className="flowsContent">
                          JARDIANCE VARIANCE: For Jardiance & BI Alliance events each section of the ON24 Platform will have a different job bag number. Job bags to be created for each of the: Registration Page, Lobby Page, Confirmation Email, Reminder 1, Reminder 2, Calendar Invite, Console Polling questions.
                          </p>
                        </div>      
                        <div
                          style={{
                            display:
                              P1visibleSection === "rect-2"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Submit ON24 Online request form   </p>
                          <p className="flowsContent">
                            <a href={webConfig.ON24RequestForm} target="_blank">
                              Please use the ON24 Request Form
                            </a>
                          </p>
                          <p className="flowsHeading">Top Tips:</p>
                          <ul className="ul_padding ul_margin_bottom mb-5">
                            <li className="flowsContent">
                               - Have all the details before starting to complete the form. Details include: Event summary description,
speaker details (title, institution, picture, biography), registration page banner, console page banner,
Prescribing Information Links (if applicable), Prescribing Information indication and generic name.
                            </li>
                            <li className="flowsContent">
                            - UK platforms are approved in 4 job bags, please make sure you have the 4 job bag codes ready when
completing the ON24 request form. 
                            </li>
                          </ul>
                          <p className="flowsContent">
                            <strong>If you don't have all of this information, take your time to collect them and then submit your request.
                            Submitting a request without all of the details added to the form and all mandatory fields being
                            completed will only hinder the process. A platform will not be built/process of building won't start
                            until all details are received.</strong>
                          </p>

                        </div>

                        <div
                          style={{
                            display:
                              P1visibleSection === "rect-3" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">OCE Ops Manager follow up  </p>
                            <p className="flowsContent">
                              OCE Ops Manager to confirm receipt and follow up with questions
                            </p>  
                            <p className="flowsHeading">Remember!</p>
                            <p className="flowsContent">
                            Allow <strong>2 weeks</strong> for the creation of an ON24 platform from the moment when the request is being received with all the details  
                            </p>
                            <p className="flowsContent">
                            Please take into consideration this timeline in your planning process
Requesting the platform without all details will not speed up the platform creation will hinder the
progress as teams will also not be able to upload the piece for MLR without full details.
                            </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "rect-4" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">On24 platform built   </p>
                            <p className="flowsContent">
                            Once the platform is built and handed over, the following details will be sent in an excel spreadsheet to the relevant team: 
                            </p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                ● Title , date, time and duration of event
                                </li>
                                <li className="flowsContent">
                                ● Encoders
                                </li>
                                <li className="flowsContent">
                                ● Preview Link
                                </li>
                                <li className="flowsContent">
                                ● Presenter Link
                                </li>
                                <li className="flowsContent">
                                ● Tagged links for registration
                                </li>
                                <li className="flowsContent">
                                ● HCP direct links are created by DST separately and are not part of this handover
                                </li>
                               
                            </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "rect-5" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">Platform review   </p>
                            <p className="flowsContent">
                            It is the responsibility of the team requesting the platform to test the following: 
                            </p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                ● Links
                                </li>
                                <li className="flowsContent">
                                ● Content
                                </li>
                                <li className="flowsContent">
                                ● Accuracy
                                </li>
                                <li className="flowsContent">
                                ● Functionality
                                </li>
                               
                            </ul>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  </div>          
                  <div id="accordion2">
                  <Accordion className="accordion2"
                    title="Phase 2: Platform Approval "
                    defaultOpen={false}
                    isOpen={isPhase2Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div id="local-on24" className="col-lg-7 planning main-content">
                         <Phase2 ref={svgRef2} className="localOn24-svg-phase-2" />
                      </div>
                      <div className="col-lg-5 planning p_font pt-3 flowsSecondPart side-content">
                        <div
                          style={{
                            display:
                            P2visibleSection === "rect-1" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">Initiate MLR  </p>
                          <p className="flowsContent">
                          This is the stage where the Veeva Coordinator uploads material to VVPM for reviewers to provide a verdict on it. Click here to see the VVPM job bag standard.
                          </p>
                          <p className="flowsHeading">Remember!</p>
                          <p className="flowContent">
                            <strong>UK & ROI</strong>
                          </p>
                          <p className="flowContent">
                            The platform will be approved in 4 job bags.
                          </p>
                          <p className="flowContent">
                            Job bag 1: 
                            <ul className="ul_padding ul_margin_bottom mb-2">
                              <li className="flowsContent">Desktop and mobile registration page print screen</li>
                              <li className="flowsContent">Desktop and mobile lobby page print screen</li>
                              <li className="flowsContent">Lilly Passport print screen (if HCP Direct is used for registration)</li>
                            </ul>
                          </p>
                          <p className="flowContent">
                            Job bag 2: 
                            <ul className="ul_padding ul_margin_bottom mb-2">
                              <li className="flowsContent">Desktop and mobile ON24 emails print screens (confirmation email, reminder 1, reminder 2 - if applicable)</li>
                              <li className="flowsContent">Desktop and mobile ON24 calendar invite print screen</li>
                            </ul>
                          </p>
                          <p className="flowContent">
                            Job bag 3: 
                            <ul className="ul_padding ul_margin_bottom mb-2">
                              <li className="flowsContent">Desktop console view print screen</li>
                            </ul>
                          </p>
                          <p className="flowContent">
                            Job bag 4: 
                            <ul className="ul_padding ul_margin_bottom mb-2">
                              <li className="flowsContent">Mobile console view print screen</li>
                            </ul>
                          </p>
                          <p className="flowContent">
                            <strong>Important! </strong>
                            <ul className="ul_padding ul_margin_bottom mb-2">
                              <li className="flowsContent">If the title, agenda and speakers of an event is the same, only submit one set of print screens for the first session of the day. Together with the MLR Document, also provide to the medical certifier the links to all sessions!</li>
                            </ul>
                          </p>
                          <p className="flowContent">
                          <strong>NORDICS </strong>
                            <ul className="ul_padding ul_margin_bottom mb-2">
                              <li className="flowsContent">Each session of the webinar will be approved in 1 job bag. This will include print screens from desktop for: registration page, lobby page, confirmation email, reminder emails, calendar invite, console view.</li>
                            </ul>
                          </p>

                        </div>      
                        <div
                          style={{
                            display:
                            P2visibleSection === "rect-2"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">MLR Verdict </p>
                          
                          <p className="flowsContent">There are three types of verdicts that the material could get: </p>
                          <ul className="ul_padding ul_margin_bottom mb-5">
                            <li className="flowsContent">
                              <strong> 1. Approved for Production –</strong> The material has been blessed by the reviewers. Go ahead and ask for the material to be developed into the final format.
                            </li>
                            
                            <li className="flowsContent">
                              <strong> 2. Approved with Changes – </strong>The material has some minor errors/updates required (like spelling, grammatical errors). Incorporate the advised updates/corrections on the material and ask for the material to be developed into the final format. 
                            </li>
                            <li className="flowsContent">
                              <strong> 3. Revise and Resubmit – </strong> The material has too many problems and the reviewer advises the Veeva Coordinator to rework on it and submit again. Go ahead and ask the agency to re-do the artwork.
                            </li>
                            
                          </ul>
                          

                        </div>
                        <div
                          style={{
                            display:
                            P2visibleSection === "rect-3"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Final Steps before go-live</p>
                          
                          <p className="flowsContent">ON24 platform is live from the moment is created. There is no need for the platform to be pushed live.</p>
                          <ul className="ul_padding ul_margin_bottom mb-5">
                            <li className="flowsContent">
                              <strong> Actual Date if First Use - </strong> is set by the Veeva coordinator and is typically put as the date the material has been certified on VVPM, unless there’s a specific date for when the usage of material will start (for example launch date, after the salesforce training etc.)
                            </li>
                            
                            <li className="flowsContent">
                              <strong> Inventory Information – </strong>Veeva coordinator would have added this earlier to VVPM metadata and it should be checked when material goes live. This step will help increase the efficiency of material management and withdrawal.
                            </li>
                            <li className="flowsContent">
                              <strong> Expiry Date – </strong>is usually set for 2 years. However, if the material is classified in the metadata as ‘one-time use’ or for a defined time, set the expiry date accordingly.
                            </li>
                            <li className="flowsContent">
                              <strong> Related Pieces – </strong> Veeva coordinators should check that any relevant related pieces are linked to the VVPM job bag
                            </li>
                            
                          </ul>
                          

                        </div>
                      </div>
                    </div>
                  </Accordion>    
                  </div>          
                  <div id="accordion3">
                  <Accordion className="accordion3"
                    title="Phase 3: Platform Withdrawal"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div id="local-on24" className="col-lg-6 planning">
                         <Phase3 ref={svgRef3} className="localOn24-svg-phase-3" />
                      </div>
                      <div className="col-lg-6 planning p_font pt-3 flowsSecondPart">
                        <div
                          style={{
                            display:
                            P3visibleSection === "rect-1" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">On24 platform withdrawal  </p>
                          <p className="flowsContent">
                          After 24 hours of the start of the event the platform is withdrawn by ON24 through an automated system which replaces the previous details of registration page, lobby page and console with a disclaimer: "Thank you for your interest. This Webinar is no longer available."
                          </p>
                          <p className="flowsHeading">Remember!</p>
                          <p className="flowContent">
                          It is the responsibility of the OCE Ops Manager to check that the platform has been successfully withdrawn.
                          </p>
                        </div>      
                        <div
                          style={{
                            display:
                            P3visibleSection === "rect-2"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Job bag withdrawal from VVPM </p>
                          
                          <p className="flowsContent">Console should be marked as one time use to allow its auto-expiry.</p>
                        </div>
                        
                      </div>
                    </div>
                  </Accordion>    
                  </div>          
                </div>
              </div>
            </div>
            {/*End Lifecycle */}

            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Role </th>
                        <th>Responsibility</th>
                        </tr>
                    </thead>  
                    <tbody>
                      <tr>
                         <td className="tb">
                              <h6>Veeva Owner</h6>
                          </td>
                          <td className='tb'>
                              <p>
                                  The Veeva Owner is also known as the Uploader (please see 'Veeva uploader')
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator</h6>
                          </td>
                          <td>
                              <ul className="ul_padding">Responsible for:
                                  <li>1. Having overall responsibility and accountability for the material</li>
                                  <li>2. Ensure that no item is used without the necessary approvals</li>
                                  <li>3. Ensuring timely change and/or withdrawal, where needed</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a>
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Uploader</h6>
                          </td>
                          <td>
                              <p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA
                                  teams, and is assigned the Veeva Owner role.</p>
                              <ul className="ul_padding">
                                  <li>Responsible for:</li>
                                  <li>1. Uploading the material to Veeva</li>
                                  <li>2. Performing all activities related to upload</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Co-ordinator</h6>
                          </td>
                          <td>
                              <p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager (or similar
                                  role). </p>
                              <ul className="ul_padding">
                                  <li>Responsible for:</li>
                                  <li>1. Reviewing the item before sending for MLR review</li>
                                  <li>2. Managing the document workflow</li>
                                  <li>3. Assigning reviewers</li>
                                  <li>4. Managing expiry or periodic review</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Business Reviewer</h6>
                          </td>
                          <td>
                              <p>Business Reviewer is an experienced commercial employee.</p>
                              <ul className="ul_padding">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring all promotional materials align with the brand strategy, Lilly Brand Book, Product Brand
                                      Book</li>
                                  <li>2. Ensuring all materials are of good quality, have correct Veeva metadata and adhere to internal
                                      policies and local codes</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Medical Certifier</h6>
                          </td>
                          <td>
                              <p>Registered medical practitioner or UK-registered Pharmacist.</p>
                              <ul className="ul_padding">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring that the material is in accordance with the requirements of the local code</li>
                                  <li>2. Ensuring that the material is not inconsistent with the marketing authorisation/SmPC (if applicable)
                                  </li>
                                  <li>3. Ensuring that the material is a fair and truthful representation of the facts about the medicine (if
                                      applicable)</li>
                                  <li>4. Approving the material in its final form</li>
                                  <li>5. Ensuring the material has the appropriate reviewers and that the Veeva metadata is correct.</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for ensuring that the Veeva metadata is correct and that the PMC
                                  documents are included (if applicable).</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordic Code Approver</h6>
                          </td>
                          <td>
                              <p>Code Approver must be a medical practitioner or a pharmacist (except for in Sweden) and is responsible for
                                  ensuring that the material is in line with the local Codes and that the material being approved has the
                                  appropriate reviewers; and ensuring that the Veeva metadata is correct.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordics Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for reviewing the material from a medical perspective.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Indegene</h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_padding">
                                  <li>1. Creating artwork as per material originator brief</li>
                                  <li>2. Creating formatted PI PDFs</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_padding">
                                  <li>1.  Content conceptualisation</li>
                                  <li>2.  Brief to Indegene </li>
                                  <li>3.  Managing approval proces </li>
                                  <li>4.  Ensuring that a print request is raised with KonicaMinolta (or for simple materials, printed in the office) to provide hard copies for certification</li>
                                  <li>5.  Ensuring final print request is raised and material is delivered to final destination (e.g., warehouse)</li>
                                  <li>6.  Withdrawal </li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Global Librarian  </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_padding">
                                  <li>1.  Archiving Physical Material as per the request from the Material Originator</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Marketing Services  </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_padding">
                                  <li>1.  Business relationship owner for the printing agency (Indicia), creation agency (Indegene) and warehouse supplier across Hub (STACI, UK; Velox, FI, SE and DK and Logistikkhuset, NO)</li>
                                  <li>2.  Primary point of contact for Marketing teams in regards to feedback to above mentioned suppliers </li>
                                  <li>3.  Delivering trainings for (new) employees relevant to third tools and related material management processes  </li>
                                  <li>4.  Oversight and support role through material management process including material withdrawal process</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Konica Minolta  </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_padding">
                                  <li>1.  Providing printing proofs prior to print</li>
                                  <li>2.  Printing as per print request and delivering to defined location </li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator   </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_padding">
                                  <li>1.  Submitting online request form to build On24 platform</li>
                                  <li>2.  Managing approval process </li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>OCE Operations Manager   </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_padding">
                                  <li>1.  Building On24 platform as per timelines and the provided brief </li>
                                  <li>2.  Downloading attendance report from On24 and submitting to LMC Team for customer reconciliation in Mercury </li>
                              </ul>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>What it Stands for  </strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                    Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Stand</td>
                    <td className="tb"></td>
                    <td className="tb">
                       Designated space purchased by Lilly for a congress or event. This space can feature a stand with promotional imagery and pull-up banners.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Booth</td>
                    <td className="tb"></td>
                    <td className="tb">
                       Designated space purchased by Lilly for a congress or event. This space can feature a stand with promotional imagery and pull-up banners.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool </td>
                    <td className="tb">Planning and execution tools combine to form the CET. With CET, customer plans can be created and campaigns can be managed in one place. </td>
                  </tr>
                  <tr>
                    <td className="tb">Congress </td>
                    <td className="tb"></td>
                    <td className="tb">
                      Third party organised events in which Lilly purchases a sponsorship package for. This can include a stand, booth and/or symposium
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Conversion Metric </td>
                    <td className="tb"></td>
                    <td className="tb">Metrics used to measure the number of valuable actions customers take through a digital marketing channel. </td>
                  </tr>
                  <tr>
                    <td className="tb">CSA </td>
                    <td className="tb">Control Self Assessment </td>
                    <td className="tb">A quality check performed by process owners quarterly. The results are shared and discussed with HLT with measures put in place to mitigate any identified issues.  </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey </td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>
                  <tr>
                    <td className="tb">Cvent </td>
                    <td className="tb"></td>
                    <td className="tb">Hopsitality management platform that allows capture of customer details for meetings and events, such as registration details and logistics. </td>
                  </tr>
                  <tr>
                    <td className="tb">eP2P</td>
                    <td className="tb">Electronic peer to peer </td>
                    <td className="tb"> eP2P is the video broadcasting of P2P programs transmitted via internet either live or hosted (video recording) on Lilly or 3rd party sites. </td>
                  </tr>
                  <tr>
                    <td className="tb">HO</td>
                    <td className="tb">Head Office</td>
                    <td className="tb">A prefix to tactics owned by the teams who work from a Lilly office, such as medical and marketing.  </td>
                  </tr>
                  <tr>
                    <td className="tb">Hybrid P2P </td>
                    <td className="tb">Hybrid Peer to Peer  </td>
                    <td className="tb">A Lilly organized eP2P set up in such a way that some customers attend the meeting individually while others gather in groups to attend the meeting. (ie. Hub + spoke, satellite groups)  </td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">Goals or Metrics set behind a campaign and or tactic.</td>
                  </tr>
                  <tr>
                    <td className="tb">Leave piece </td>
                    <td className="tb"></td>
                    <td className="tb">A material which can be left at the HCP's desk.</td>
                  </tr>
                  <tr>
                    <td className="tb">Mercury</td>
                    <td className="tb"></td>
                    <td className="tb">Salesforce Marketing Cloud application for the planning, submission and approval of meetings.</td>
                  </tr>
                   <tr>
                    <td className="tb">P2P</td>
                    <td className="tb">Peer to peer </td>
                    <td className="tb">Lilly organized programs where an HCP (external or internal speaker) engages with other HCPs on disease state and therapeutic options through lectures and/or case study discussions. This can also be referred to as F2F events (face to face).  </td>
                  </tr>
                  <tr>
                    <td className="tb">PMC</td>
                    <td className="tb">Permissions Managed Content </td>
                    <td className="tb">An image which is owned by an entity other than Lilly and for which permission is given to use under a specifc set of circumstances as defined in a contractural agreement between Lilly and the Content Owner. </td>
                  </tr>
                  <tr>
                    <td className="tb">Symposium</td>
                    <td className="tb"> </td>
                    <td className="tb">A Lilly-owned lecture at a third party event or congress. </td>
                  </tr>
                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"> </td>
                    <td className="tb">Tactics are specific to selected channels to drive customers to reach a piece of content. Tactics are associated with estimated KPI metric (goal) and result reports (actuals).  </td>
                  </tr>
                  <tr>
                    <td className="tb">TL</td>
                    <td className="tb">Thought Leader</td>
                    <td className="tb">Thought Leaders influence thinking and/or treatment practice beyond his/her local market or practice on a regional, national or global basis. TLs are respected and acknowledged by their peers, other experts and key audiences as leaders within a particular subject matter. The term includes customers from clinical to academic to research to regulatory and the payer space.  </td>
                  </tr>
                  <tr>
                    <td className="tb">TL-MaP</td>
                    <td className="tb">Thought Leader Mapping and Planning Tool </td>
                    <td className="tb">The only approved global platform for planning and tracking contracted Thought Leader engagements. Among many other benefits, TL-MaP allows for increased line-of-sight for accountable leadership to mitigating risk when contracting TLs.  </td>
                  </tr>
                  <tr>
                    <td className="tb">VVPM</td>
                    <td className="tb">Veeva Vault PromoMat</td>
                    <td className="tb">Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems. PromoMats is a Vault application to manage the process of creating, reviewing and approving promotional materials, claims and supporting information.</td>
                  </tr>
                  <tr>
                    <td className="tb">Warehouse/Staci </td>
                    <td className="tb"></td>
                    <td className="tb">Location where all physical print materials are stored for distribution. The warehouse/staci online system allows management and monitoring of stock across brands.  </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Defination*/}

            {/*View Core Doc*/}
            <div
              className="tab-pane fade tab-hide"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <Link
                  className="CoreURL"
                  to={webConfig.LocalOn24CoreDoc}
                  target="_blank"
                >
                  Local Webinar (eP2P) document here.{" "}
                </Link>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/*End Core Doc*/}

             {/*Add Faqs*/}
             <div
              className="tab-pane fade"
              id="v-pills-faqs"
              role="tabpanel"
              aria-labelledby="v-pills-faqs-tab"
            >
              <h4 className="h1-font comman_hd">FAQs</h4>
              <div id="FAQ1">
                <Accordion
                  className="faqAccordion"
                  title="Can I use Teams instead of ON24 for my upcoming Head-Office lead eP2P?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">  
                  <p>
                  No, ON24 is the only approved platform used for marketing lead webinars/eP2P. There are several reasons why:
                  </p>
                  <ul  className="ul_padding">
                    <li>
                       ● ON24 enables automated customer journey to send invitation emails. This reduces the work for you and for teams supporting you in running the webinar. 
                    </li>
                    <li>
                       ● Only ON24 is integrated with Mercury, Customer Engagement Tool (CET) and Data Dashboards. This means your attendee data is automatically uploaded on relevant places. This again, reduces work post the event. 
                    </li>
                    <li>
                       ● There is a potential risk in Teams where the Personal Information (Name and email) of attendees, might be visible to other attendees. 
                    </li>
                    <li>
                       ● ON24 has a better control over withdrawal of the material. No manual action is needed from your end. This again, saves time.  
                    </li>
                  </ul>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ2">
                <Accordion
                  className="faqAccordion"
                  title="What happens to the webinar video and the console after the webinar is over?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                    ON24 platform auto-withdraws the webinar details and the material. It is archived offline. 
                    </p>
                    <p>The action to do so is built in when the console is created by the OCE Operations Manager.  </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ3">
                <Accordion
                  className="faqAccordion"
                  title="How can I get trained on Mercury?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Mercury training videos and checklists are available here {' '}
                      <Link
                        className="CoreURL"
                        to={webConfig.LMCMercurySupportSite}
                        target="_blank"
                      >
                        Mercury support site (lilly.com)
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ4">
                <Accordion
                  className="faqAccordion"
                  title="What are the useful procedures for meetings?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Please visit this page {' '}
                      <Link
                        className="CoreURL"
                        to={webConfig.MeetingsRelatedInformation}
                        target="_blank"
                      >
                        Meetings Related Information (lilly.com)
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ5">
                <Accordion
                  className="faqAccordion"
                  title="How do I check my speaker training status?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      This can be found in Mercury and Veeva and on the PowerApp {' '}
                      <Link
                        className="CoreURL"
                        to={webConfig.GBSTrainedSpeakerListPowerApps}
                        target="_blank"
                      >
                        GBS - Trained Speaker List - Power Apps
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ6">
                <Accordion
                  className="faqAccordion"
                  title="Who is my POC in the Lilly Meetings and Congresses Team?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Please see org chart here:{' '}
                      <Link
                        className="CoreURL"
                        to={webConfig.HEHUBLMCOrgChart}
                        target="_blank"
                      >
                        HE HUB LMC Org Chart_Oct 2023.png (1920×1080) (lilly.com)
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
            </div>
            {/*End Faqs*/}
          </div>
        </div>
      </div>

    </div>
  );
};


export default LocalOn24;
