import React from "react";
import { Link } from 'react-router-dom';
import "../css/IbuWebinar.css";


const Video = () => {
  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    Video Creation and Approval   
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">  Video Creation and Approval  </h2>
            <p>
              <em>
                Due to the removal of Lilly Play in the NE Hub, the procedure for Video creation and approval is being re-designed. 
              </em>
            </p>
            <p>
              <em>
                If you would like to promote your content through videos, please reach out to your OCE Manager for advice and support. 
              </em>
            </p>
            <p>
              <em>
                Thank you for your understanding!
              </em> 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Video;
