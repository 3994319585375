import React from "react";
import { useState, useRef, useEffect } from "react";
import {webConfig} from '../configs/webConfig'
import "../css/Privacy.css";
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/Privacy_Phases_SVG/Privacy_Phase_1.svg";
import { ReactComponent as Phase2 } from "../SVG/Privacy_Phases_SVG/Privacy_Phase_2.svg";
import { ReactComponent as Phase3 } from "../SVG/Privacy_Phases_SVG/Privacy_Phase_3.svg";



const Privacy = () => {
  const [isPhase3Open, setIsPhase3Open] = useState();

  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("Advice");

  const svgRef2 = useRef();
  const [P2visibleSection, setP2visibleSection] = useState("TransTemplate");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("Custodian");

  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    const diam1 = svg.getElementById("diamond1");
  
    rect1.addEventListener("click", () => {
      setP1visibleSection("Advice");
    });
    diam1.addEventListener("click", () => {
      setP1visibleSection("TRIAGE");
    });

  }, []);


  useEffect(() => {
    const svg = svgRef2.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    
    rect1.addEventListener("click", () => {
      setP2visibleSection("TransTemplate");
    });
    rect2.addEventListener("click", () => {
      setP2visibleSection("TransRvwByPoc");
    });
   
    const handleScrollP3 = () => {
      const elementToScrollTo = document.getElementById("accordion3");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect3.addEventListener("click", () => {
      handleScrollP3();
      setIsPhase3Open(true);
    });
  }, [isPhase3Open]);

  useEffect(() => {
    const svg = svgRef3.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    rect1.addEventListener("click", () => {
      setP3visibleSection("Custodian");
    });
    rect2.addEventListener("click", () => {
      setP3visibleSection("Initiate");
    });
    rect3.addEventListener("click", () => {
      setP3visibleSection("TechnicalEditor");
    });
    rect4.addEventListener("click", () => {
      setP3visibleSection("PrivacyNotice");
    });
  }, []);


  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    Privacy Policy Notice  
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">Privacy Policy Notice  </h2>
            <p>
              <em>
                Privacy notices are documents we provide to customer detailing how Lilly uses the Personal Information (PI) that the customer provides us.  
              </em>
            </p>
            <p>
              <em>
                The Global Privacy Program has been designed to protect the Personal Information (PI) that is collected, used, and managed by Lilly in its business anywhere in the world.
                The program is supported by the Global Privacy Office (GPO) and our company’s commitment to privacy is stated in our Global Policy on Respecting Privacy. 
                Each one of us is responsible for ensuring that we implement the Global Privacy Program in our daily work to protect the PI of our workforce, customers, patients, and any individual who trusts us with this information.
                To learn more about Privacy program and Lilly’s privacy policy,click here {" "}
                <a 
                  href={webConfig.GlobalPrivacyProgram}
                  target="_blank">
                    Global Privacy Program (lilly.com).       
                </a>
              </em>
            </p>
            <p>
              <em>
                 These notices are typically made available on the footer of a website, webpage or any other customer facing digital channel.
                 Scroll down to see all the current privacy notices.  
              </em>
            </p>

          </div>
        </div>
      </div>

      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link navLinkTab active"
              data-bs-toggle="tab"
              href="#processPreRequisites"
            >
              Process
              <br />
              Pre-requisites
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navLinkTab" data-bs-toggle="tab" href="#NEHPNAP">
              NE HUB Privacy Notice Approval Process 
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navLinkTab" data-bs-toggle="tab" href="#PNKD">
              Privacy Notices: Key Decisions
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navLinkTab" data-bs-toggle="tab" href="#PNLIST">
               Privacy 
               <br/>
               Notices List 
            </a>
          </li>
        </ul>
        
        <div id="tabContent" className="tab-content">
            <div id="processPreRequisites" className="tab-pane fade in active tabs_box tabContent">
                <div>
                  <ul class="ul_padding">
                    <li className='tabContent'> Changes to privacy notice can only be advised by GPO or local E&C.</li>
                    <li className='tabContent'> Process requires local IT Custodian to initiate and execute the update of any of the privacy notices.</li>
                  </ul>
                </div>
            </div>
            <div id="NEHPNAP" className="tab-pane fade tabs_box tabContent">
              <ul class="ul_padding">
                <li className='tabContent'>
                   Local IT Custodian uploads the word/PDF document on the Global Customer Consent Platform (GCCP) vault and add ‘Approvers’.  
                </li>
                <li className='tabContent'>
                   For approval, <strong> 2 Business Approvers (E&C + Country Leader who can speak local language) + 1 Technical Editor (from GSS team) + 1 Technical Approval (from MCTO Tech@Lilly) </strong> 
                    are needed.  
                </li>
                <li className='tabContent'>
                   Once Word document is approved, the Technical Editor will convert it into HTML format within GCCP Veeva Vault. 
                </li>
                <li className='tabContent'>
                   Once HTML is created, local IT Custodian acts as a Gatekeeper, reviews the HTML document, and pushes the notice live if it meets expectation.  
                </li>
                <li className='tabContent'>
                   GCCP Veeva Vault is linked to GCCP privacy cloud site. The site updates as soon as the Gatekeeper approves the document to go live. 
                </li>
              </ul>
            </div>
            <div id="PNKD" className="tab-pane fade tabs_box tabContent">
                <p className='tabContent'>Following was decided in Q4 2022. The new approval format was fully established by March 2023.</p>
                <ul class="ul_padding">
                <li className='tabContent'>
                   Instead of 2 separate versions of Privacy notices (HCP notice and General Public notice), there will be single and combined version applicable to all material.  
                </li>
                <li className='tabContent'>
                   Privacy notice does <strong>not</strong> require to be approved through MLR process. Certifier is not needed (agreed with E&C in Q4 2022).   
                </li>
                <li className='tabContent'>
                   In NE hub, we (E&C, local legal, OCE) have discussed and decided that privacy notice should always be translated in local language even if the website content itself is not in English language. This is so that the Privacy notice could be easily understood by a non-English speaker accessing Lilly content. 
                </li>
                <li className='tabContent'>
                   Veeva job bag code is not required. Instead, GCCP code of the Binder document (and date stamp of last update) is taken as the unique reference code for the document. GCCP code always remains same, and the date informs of when the last update was made.   
                </li>
              </ul>
            </div>
            <div id="PNLIST" className="tab-pane fade tabs_box tabContent">
                <p className='tabsContentHeading'>Main Privacy notices - hosted on GCCP Privacy website </p>
                <table class="table table-striped mt-3">
                  <thead>
                      <tr>
                        <th></th>
                        <th>Link</th>
                        <th>Reference code with last update </th>
                        <th>Purpose</th>
                        <th>Who is responsible for managing the links </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>UK</td>
                        <td>
                          <a 
                            href={webConfig.lillyPrivacyUkEn}
                            target="_blank"
                          > https://www.lillyprivacy.com/uk-en/hcp </a>
                        </td>
                        <td>1 August 2022 GCCP_000007</td>
                        <td>Main Privacy notice to be used on all material and platforms (unless a specific variation is needed for business purpose that are either advised by or agreed with E&C) </td>
                        <td>Local IT Custodian</td>
                      </tr>
                      <tr>
                        <td>Ireland</td>
                        <td>
                          <a 
                            href={webConfig.lillyPrivacyIEen}
                            target="_blank"
                          > https://www.lillyprivacy.com/IE-en/hcp  </a>
                        </td>
                        <td>1 August 2022 GCCP_001348 </td>
                        <td>Main Privacy notice to be used on all material and platforms (unless a specific variation is needed for business purpose that are either advised by or agreed with E&C) </td>
                        <td>Local IT Custodian</td>
                      </tr>
                      <tr>
                        <td>Finland</td>
                        <td>
                          <a 
                            href={webConfig.lillyPrivacyFIfi}
                            target="_blank"
                          > https://www.lillyprivacy.com/FI-fi/hcp   </a>
                        </td>
                        <td>1 August 2022 GCCP_001351 </td>
                        <td>Main Privacy notice to be used on all material and platforms (unless a specific variation is needed for business purpose that are either advised by or agreed with E&C) </td>
                        <td>Local IT Custodian</td>
                      </tr>
                      <tr>
                        <td>Norway</td>
                        <td>
                          <a 
                            href={webConfig.lillyPrivacyNono}
                            target="_blank"
                          > https://www.lillyprivacy.com/No-no/hcp    </a>
                        </td>
                        <td>1 August 2022 GCCP_001349 </td>
                        <td>Main Privacy notice to be used on all material and platforms (unless a specific variation is needed for business purpose that are either advised by or agreed with E&C) </td>
                        <td>Local IT Custodian</td>
                      </tr>
                      <tr>
                        <td>Sweden</td>
                        <td>
                          <a 
                            href={webConfig.lillyPrivacySEsv}
                            target="_blank"
                          > https://www.lillyprivacy.com/SE-sv/hcp </a>
                        </td>
                        <td>1 August 2022 GCCP_001268 </td>
                        <td>Main Privacy notice to be used on all material and platforms (unless a specific variation is needed for business purpose that are either advised by or agreed with E&C) </td>
                        <td>Local IT Custodian</td>
                      </tr>
                      <tr>
                        <td>​​​​​​​Denmark</td>
                        <td>
                          <a 
                            href={webConfig.lillyPrivacyDKda}
                            target="_blank"
                          > https://www.lillyprivacy.com/DK-da/hcp </a>
                        </td>
                        <td>1 August 2022 GCCP_001350 </td>
                        <td>Main Privacy notice to be used on all material and platforms (unless a specific variation is needed for business purpose that are either advised by or agreed with E&C) </td>
                        <td>Local IT Custodian</td>
                      </tr>
                      
                  </tbody>
                </table>

                <p className='tabsContentHeading'>Other privacy notices – Not covered under Local IT governance  </p>
                <table class="table table-striped mt-3">
                  <thead>
                      <tr>
                        <th></th>
                        <th>Link</th>
                        <th>Reference code with last update </th>
                        <th>Purpose</th>
                        <th>Who is responsible for managing the links </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td>UK</td>
                        <td>
                          <a 
                            href={webConfig.UKHomecarePrivacyNotice }
                            target="_blank"
                          > UK Homecare Privacy Notice  </a>
                        </td>
                        <td>PP-MG-GB-0394 May 2023</td>
                        <td>Needed for patient support material and terms of data collection are different, hence different version was created for PSP material</td>
                        <td>Rheum/Derm Brand Manage</td>
                      </tr>
                      <tr>
                        <td>UK</td>
                        <td>
                          <a 
                            href={webConfig.LillyPrivacyPolicy}
                            target="_blank"
                          > Privacy Policy - Privacy Policy (lilly.com)  </a>
                        </td>
                        <td>Not Applicable  </td>
                        <td>CVent </td>
                        <td>LMC team </td>
                      </tr>
                      <tr>
                        <td>1 per affiliate </td>
                        <td>
                          GDPR notices sent in paper form  
                        </td>
                        <td>A4_verNov2022 </td>
                        <td> Paper copy of notice sent to each HCP/practice when we their detail on veeva CRM for the first time </td>
                        <td>Local E&C advises on updates and Data Operations Team sends these out  </td>
                      </tr>
                  </tbody>
                </table>
            </div>
        </div>
      </div>
      
      {/*Add verticle Tab*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">Privacy Policy Notice Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Planning"
                    defaultOpen={true}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                         <Phase1 ref={svgRef1} className="privacyShape1" />
                      </div>
                      <div className="col-lg-7 planning p_font pt-3 flowsSecondPart">
                      <div
                          style={{
                            display:
                              P1visibleSection === "Advice" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">Advice to update content </p>
                          <p className="flowsContent">
                          Any advice to update wording on privacy notices <strong>must</strong> come from local E&C or GPO. The advice must be captured and sent to IT Custodian via email – verbal agreements are not sufficient.  
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "TRIAGE"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Triage </p>
                          <p className="flowsContent">Local IT Custodian discusses with E&C/GPO to assess whether the change is: </p>
                          <p className="flowsContent">
                            (a) Time sensitive and needs to be updated urgently. OR,   
                          </p>
                          <p className="flowsContent">
                            (b) Can be taken up in a quarterly/bi-annual update cycle  
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion2" />
                  <Accordion
                    className="accordion2"
                    title="Phase 2: Material Readiness"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                        <div className="comman_width_e">
                         <Phase2 ref={svgRef2} className="privacyShape2" />
                         </div> 
                      </div>
                      <div className="col-lg-7 planning pt-4 flowsSecondPart">
                        <div
                          style={{
                            display:
                              P2visibleSection === "TransTemplate"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Translation template provided  </p>
                          <p
                            className="flowsContent"
                          >
                            Local IT Custodian gets agency to translate content from English to local language for each affiliate within the hub. These are then provided to the local Point of Contact (PoC) for review.  
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "TransRvwByPoc"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading"> Translations reviewed by local PoC  </p>
                          <p
                            className="flowsContent"
                          >
                            Local PoC is usually a Marketing Associate who would review the text for accurate representation of the translated content. Local PoC is to provide written confirmation to the Custodian on accuracy of translated content. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  <div id="accordion3" />
                  <Accordion
                    className="accordion3"
                    title="Phase 3: Material Approval & Launch"
                    defaultOpen={false}
                    isOpen={isPhase3Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                          <div className="comman_width_e">
                            <Phase3 ref={svgRef3} className="privacyShape3" />
                          </div>  
                      </div>
                      <div className="col-lg-7 planning flowsSecondPart">
                        <div
                          id="link1"
                          style={{
                            display:
                              P3visibleSection === "Custodian"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Custodian uploads the document on GCCP vault and adds Approvers  </p>
                          <p className="flowsContent">
                          GCCP is a sperate Binder section within Veeva and access to this section is only granted to necessary stakeholders. A Veeva Co-ordinator will not have access to GCCP and Local IT Custodian performs this action.   
                          </p>
                        </div>
                        <div
                          id="link2"
                          style={{
                            display:
                              P3visibleSection === "Initiate"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Initiate Veeva review on Binder</p>
                          <p className="flowsContent">
                             E&C + County Leader (native speaker) +1 Technical Editor (from Global Service Solution team) + 1 Technical Approver (from MCTO Tech@Lilly) are the approvers. 
                          </p>
                        </div>
                        <div
                          id="link3"
                          style={{
                            display:
                              P3visibleSection === "TechnicalEditor"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Technical Editor converts Word file into HTML document  </p>
                          <p className="flowsContent">
                            The conversion of text into HTML takes place within GCCP. 
                          </p>
                        </div>
                        <div
                          id="link4"
                          style={{
                            display:
                              P3visibleSection === "PrivacyNotice"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Privacy Notice pushed live  </p>
                          <p className="flowsContent">
                             Local IT Custodian reviews HTML and pushes the Notice to live GCCP privacy cloud website.  
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  {/*Accordion4*/}
                  <div id="accordion4" />
                  <Accordion
                    className="accordion4"
                    title="Phase 4: Material Management"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                       <p className="flowsContent p-3">Privacy notice is managed through bi-annual review as part of Controlled Self-Assessment (CSA). </p>
                    </div>
                  </Accordion>
                  <div id="accordion5" />
                  <Accordion
                    className="accordion5"
                    title="Phase 5: Material Withdrawal"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <p className="flowsHeading">Not Required.</p>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            
            {/*End Lifecycle */}
            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Role </th>
                        <th>Responsibility</th>
                        </tr>
                    </thead>  
                    <tbody>
                      <tr>
                         <td class="tb">
                              <h6>Veeva Owner</h6>
                          </td>
                          <td className='tb'>
                              <p>
                              The Veeva Owner is also known as the Uploader (please see 'Veeva uploader') 
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                      </tr>
                      <tr>
                          <td class="tb">
                              <h6>Material Originator</h6>
                          </td>
                          <td>
                              <ul class="ul_list">Responsible for:
                                  <li>1. Having overall responsibility and accountability for the material</li>
                                  <li>2. Ensure that no item is used without the necessary approvals</li>
                                  <li>3. Ensuring timely change and/or withdrawal, where needed</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td class="tb">
                              <h6>Veeva Uploader</h6>
                          </td>
                          <td>
                              <p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA teams, and is assigned the Veeva Owner role. </p>
                          </td>
                      </tr>
                      <tr>
                          <td class="tb">
                              <h6>Veeva Co-ordinator</h6>
                          </td>
                          <td>
                              <p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager
                                 (or similar role). </p>
                          </td>
                      </tr>
                      <tr>
                          <td class="tb">
                              <h6>Business Reviewer</h6>
                          </td>
                          <td>
                              <p>Business Reviewer is an experienced commercial employee.</p>
                          </td>
                      </tr>
                      <tr>
                          <td class="tb">
                              <h6>UK Medical Certifier</h6>
                          </td>
                          <td>
                              <p>Registered medical practitioner or UK-registered Pharmacist.</p>
                          </td>
                      </tr>
                      <tr>
                          <td class="tb">
                              <h6>UK Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for ensuring that the Veeva metadata is correct and that the PMC
                                  documents are included (if applicable).</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td class="tb">
                              <h6>Nordic Code Approver</h6>
                          </td>
                          <td>
                              <p>Code Approver must be a medical practitioner or a pharmacist (except for in Sweden) and is responsible for
                                  ensuring that the material is in line with the local Codes and that the material being approved has the
                                  appropriate reviewers; and ensuring that the Veeva metadata is correct.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td class="tb">
                              <h6>Nordics Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for reviewing the material from a medical perspective.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>What it Stands for </strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">Customer Journey</td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>
                  <tr>
                    <td className="tb">GPS</td>
                    <td className="tb">Global Patient Safety </td>
                    <td className="tb">
                    Global Patient Safety is a fundamental principle of health care1that aims to prevent and reduce risks, errors and harm that occur to patients during provision of health care.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">
                    Goals or Metrics set behind a campaign and or tactic.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">UAT  </td>
                    <td className="tb">User Assured Testing</td>
                    <td className="tb">A type of validation which ensures that the product or the solution works for the user and meets all his/her requirements. It is usually the last step in the software testing process. </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Defination*/}

            {/*View Core Doc*/}
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.PrivacyCoreDoc}
                  target="_blank"
                >
                  Privacy Policy document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/*End Core Doc*/}
          </div>
        </div>
      </div>

    </div>
  );
};


export default Privacy;
