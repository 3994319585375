import React from "react";
import { useState, useRef, useEffect } from "react";
import {webConfig} from '../configs/webConfig'
import "../css/Cookie.css";
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/Cookie_Phases_SVG/Cookie_Phase_1.svg";
import { ReactComponent as Phase2 } from "../SVG/Cookie_Phases_SVG/Cookie_Phase_2.svg";
import { ReactComponent as Phase3 } from "../SVG/Cookie_Phases_SVG/Cookie_Phase_3.svg";



const Cookie = () => {
  const [isPhase1Open, setIsPhase1Open] = useState();
  const [isPhase3Open, setIsPhase3Open] = useState();

  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("Advice");

  const svgRef2 = useRef();
  const [P2visibleSection, setP2visibleSection] = useState("Content");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("Initiate");

  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    const diam1 = svg.getElementById("diamond1");
  
    rect1.addEventListener("click", () => {
      setP1visibleSection("Advice");
    });
    diam1.addEventListener("click", () => {
      setP1visibleSection("TRIAGE");
    });

  }, []);
  
  useEffect(() => {
    const svg = svgRef2.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    const rect8 = svg.getElementById("rect8");
    const rect9 = svg.getElementById("rect9");
    const rect10 = svg.getElementById("rect10");
    const rect11= svg.getElementById("rect11");
    const rect12 = svg.getElementById("rect12");
    const rect13 = svg.getElementById("rect13");
    const diam1 = svg.getElementById("diamond1");
    
    rect1.addEventListener("click", () => {
      setP2visibleSection("Content");
    });
    rect2.addEventListener("click", () => {
      setP2visibleSection("TECHNICAL");
    });
    rect3.addEventListener("click", () => {
      setP2visibleSection("TRANSLATIONBYIBU");
    });
    rect4.addEventListener("click", () => {
      setP2visibleSection("IBUSTAGINGSITE");
    });
    rect5.addEventListener("click", () => {
      setP2visibleSection("TRANSLATIONBYPOC");
    });
    rect6.addEventListener("click", () => {
      setP2visibleSection("CUSTODIANCOMPUAT");
    });
    rect7.addEventListener("click", () => {
      setP2visibleSection("JOBBAGCREATE");
    });
    rect8.addEventListener("click", () => {
      setP2visibleSection("TRANSLATIONPASSESTOIBU");
    });
    rect9.addEventListener("click", () => {
      setP2visibleSection("IBUGCOTESTLINK");
    });
    rect10.addEventListener("click", () => {
      setP2visibleSection("CONFIRMGOLIVE");
    });
    rect11.addEventListener("click", () => {
      setP2visibleSection("CUSTODIANVALIDTECHNGE");
    });
    rect12.addEventListener("click", () => {
      setP2visibleSection("RECORDCHANGELOG");
    });
    diam1.addEventListener("click", () => {
      setP2visibleSection("VEEVEJOBBAG");
    });
    const handleScrollP3 = () => {
      const elementToScrollTo = document.getElementById("accordion3");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect13.addEventListener("click", () => {
      handleScrollP3();
      setIsPhase3Open(true);
    });
  }, [isPhase3Open]);

  useEffect(() => {
    const svg = svgRef3.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    const diam1 = svg.getElementById("diamond1");
    const diam2 = svg.getElementById("diamond2");

    rect1.addEventListener("click", () => {
      setP3visibleSection("Initiate");
    });

    diam1.addEventListener("click", () => {
      setP3visibleSection("MlrVerdict");
    });

    rect2.addEventListener("click", () => {
      setP3visibleSection("EWILink");
    });

    rect3.addEventListener("click", () => {
      setP3visibleSection("InitiateCertification");
    });

    diam2.addEventListener("click", () => {
      setP3visibleSection("CertificationVerdict");
    });

    rect4.addEventListener("click", () => {
      setP3visibleSection("BuisnessGoLive");
    });
    rect5.addEventListener("click", () => {
      setP3visibleSection("ChangesAreLive");
    });
    rect6.addEventListener("click", () => {
      setP3visibleSection("PostGoLiveSteps");
    });

    
    const handleScrollP1 = () => {
      const elementToScrollTo = document.getElementById("accordion1");
      if (elementToScrollTo) {
        const y = elementToScrollTo.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect7.addEventListener("click", () => {
      handleScrollP1();
      setIsPhase1Open(true);
    });
    
  }, []);

  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    Cookie Policy
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">Cookie Policy  </h2>
            <p>
              <em>
              A Cookie Notice (or Cookie Consent Notice) is a document that outlines the types and the use of cookies on a website. Cookie notices appear as a banner when a user lands on a Lilly Website. It informs the user of the Website using cookies and tracking devices that processes information around user’s actions and activities on the Website. It also allows the user to make a choice on whether they want their personal data to be processed/tracked. Cookie notice is outlined as a sub-section of a Privacy Policy Notice provided by Lilly. It’s a compliance requirement to make Cookie Notice available on Websites and Web Platforms that track user journey and their actions on a website.  
              </em>
            </p>
           </div>
        </div>
      </div>

      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-bs-toggle="tab"
              href="#processPreRequisites"
            >
              Process
              <br />
              Pre-requisites
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#cookieSetting">
              Understanding 
              <br />
              Cookie Settings 
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#vvpmJobStandards">
               VVPM Job Bag Standards
            </a>
          </li>
        </ul>
        
        <div id="tabContent" className="tab-content">
            <div id="processPreRequisites" className="tab-pane fade in active tabs_box tabContent">
                <div>
                  <p className="tabContent">Text changes to {' '}
                     <span class="text-decoration-underline"> 
                        Privacy Notice and/or Cookie Notice 
                     </span> {' '}can only be advised by Global Privacy Office (GPO) or local/global E&C.  </p>
                  <ul class="ul_padding">
                    <li className='tabContent'> Process requires local Tech@Lilly Custodian in collaboration with Enterprise Web Initiative (EWI) Team to initiate and execute the update of cookie notice. </li>
                    <li className='tabContent'> Technical changes to Cookie Notice can be advised by EWI when not driven by GPO or E&C. </li>
                  </ul>
                </div>
            </div>
            <div id="cookieSetting" className="tab-pane fade tabs_box tabContent">
              <p className="tabContent">User will see the following 3 options: </p>
              <ul class="ul_padding">
                <li className='tabContent'>
                   <strong className="text-decoration-underline">Accept all Cookies</strong> – Clicking on this tab will allow all Mandatory (strictly necessary), Functional as well as Tracking (performance) cookies for the user. This setting helps Lilly understand how the visitors interact with website.  
                </li>
                <li className='tabContent'>
                   <strong className="text-decoration-underline">Reject Cookies Settings</strong> – Clicking on this tab will reject all ‘optional’ cookies but the ‘strictly necessary’ cookies will still apply as they are mandatory for the website to be functional.  
                </li>
                <li className='tabContent'>
                   <strong className="text-decoration-underline">Confirm my choices</strong> – Clicking on this tab will allow user to select their choices between the different cookie settings.  
                </li>
              </ul>
            </div>
            <div id="vvpmJobStandards" className="tab-pane fade tabs_box tabContent">
              <ul class="ul_padding">
                <li className='tabContent'>
                   Approval process is through VVPM and the job bag should be marked for ‘Examination Only’. 
                </li>
                <li className='tabContent'>
                   Updates can be approved on VVPM by local Business Owner (i.e., OCE Leader).  
                </li>
                <li className='tabContent'>
                   Updates do not need to be approved by a medical certifier as there’s no branded content. 
                </li>
                <li className='tabContent'>
                   The job bags must include the following Attachments.
                  <ol className="mt-2 px-3">
                    <li className="tabContent">
                      <strong className="text-decoration-underline"> User Acceptance Testing (UAT) Test Record</strong>
                      <p className="tabContent">If testing was applicable, completed by a Custodian and supplied to a Veeva Coordinator, all staging links and a written proof of Coordinator approval of the tested link must be attached.  </p>
                    </li>
                    <li className="tabContent">
                      <strong className="text-decoration-underline"> Change Summary Sheet </strong>
                      <p className="tabContent">
                        An information sheet summarising the change/update. The Custodian completes the {' '}
                        <a
                          href={webConfig.ChangeSummarySheet}
                          target="_blank"
                        > Change Summary Sheet
                        </a>{' '} and sends to the Veeva Coordinator to insert as cover page for the job bag.
                      </p>
                    </li>
                    <li className="tabContent">
                      <strong className="text-decoration-underline">Confirmation from E&C </strong>
                      <p className="tabContent">Confirmation from local E&C manager that changes are in line with the advice from GPO.  </p>
                    </li>
                    <li className="tabContent">
                      <strong className="text-decoration-underline"> Additional Attachments </strong>
                      <p className="tabContent"><strong>If the text is not in English,</strong> A translation confirmation mail from the local Point of Contact (PoC) confirming that the translated text is an accurate representation of the English version.   </p>
                    </li>
                  </ol>
                </li>
              </ul>
              
              
            </div>
        </div>
      </div>
      
      {/*Add verticle Tab*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">Cookie Policy Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Planning"
                    defaultOpen={true}
                    isOpen={isPhase1Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                         <Phase1 ref={svgRef1} className="cookieShape1" />
                      </div>
                      <div className="col-lg-7 planning p_font pt-3 flowsSecondPart">
                      <div
                          style={{
                            display:
                              P1visibleSection === "Advice" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">Advice to update content </p>
                          <p className="flowsContent">
                          Any advice to update wording on privacy notices <strong>must</strong> come from local E&C or GPO. The advice must be captured and sent to IT Custodian via email – verbal agreements are not sufficient.  
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "TRIAGE"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Triage </p>
                          <p className="flowsContent">Local IT Custodian discusses with E&C/GPO to assess whether the change is: </p>
                          <p className="flowsContent">
                            (a) Time sensitive and needs to be updated urgently. OR,   
                          </p>
                          <p className="flowsContent">
                            (b) Can be taken up in a quarterly/bi-annual update cycle  
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion2" />
                  <Accordion
                    className="accordion2"
                    title="Phase 2: Material Readiness"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning main-content">
                         <Phase2 ref={svgRef2} className="cookieShape2" />
                      </div>
                      <div className="col-lg-6 planning pt-4 flowsSecondPart side-content">
                        <div
                          style={{
                            display:
                              P2visibleSection === "Content"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CONTENT CHANGES </p>
                          <p
                            className="flowsContent"
                          >
                            Content change is when text is being updated.
                          </p>
                          <p className="flowsContent">
                          NOTE that the text changes can only be advised by GPO or E&C. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "TECHNICAL"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">FUNCTIONAL/TECHNICAL CHANGE  </p>
                          <p
                            className="flowsContent"
                          >
                            Something in the backend of the tool (that does not require a text update) to be changed. 
                          </p>
                          <p className="flowsContent">NOTE that such changes can also be initiated by EWI Team.  </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "TRANSLATIONBYIBU"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">TRANSLATION TEMPLATES PROVIDED BY IBU TEAM </p>
                          <p
                            className="flowsContent"
                          >
                            Local IT Custodian requests the local Point of Contact (PoCs) to either review the translated text from English or translate content from English to local languages, depending on the amount of content being updated.
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "IBUSTAGINGSITE"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">EWI provides the Stage link   </p>
                          <p
                            className="flowsContent"
                          >
                             This is the link where the change has been implemented and is ready to be tested. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "TRANSLATIONBYPOC"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">TRANSLATION REVIEWED BY POC </p>
                          <p
                            className="flowsContent"
                          >
                            The local PoC is usually a Marketing Associate (or two). Their job is to translate and/or confirm accuracy of the text. The PoC must send written communication confirming the accuracy of the translated text.  
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "CUSTODIANCOMPUAT"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Custodian completes User Acceptance Test (UAT) </p>
                          <p
                            className="flowsContent"
                          >
                            Custodian is to run tests to check functionality and document the tests performed (along with the results). 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "JOBBAGCREATE"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">JOB BAG CREATED AND CODE ADDED TO TRANSLATION TEMPLATE  </p>
                          <p
                            className="flowsContent"
                          >
                            New job bag (created by the PoC) codes are always needed for <strong>UK, IE and DK. </strong>
                            Existing job bags can be used for <strong>SE, NO and FI.</strong>
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "TRANSLATIONPASSESTOIBU"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Translation passed back to EWI Team   </p>
                          <p
                            className="flowsContent"
                          >
                            Custodian provides the translation matrix to EWI team enabling the HTML build. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "CONFIRMGOLIVE"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CONFIRM GO-LIVE  </p>
                          <p
                            className="flowsContent"
                          >
                            Communicate changes to relevant stakeholders once the EWI team has pushed changes to the live environment. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "IBUGCOTESTLINK"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">EWI provides the stage link   </p>
                          <p
                            className="flowsContent"
                          >
                             The translations are applied to HTML text and stage link provided by EWI to the local Custodian. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "RECORDCHANGELOG"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">RECORD IN CHANGE LOG  </p>
                          <p
                            className="flowsContent"
                          >
                            It is the responsibility of the local IT Custodian to maintain a change log and record UAT. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "CUSTODIANVALIDTECHNGE"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CUSTODIAN VALIDATES CHANGES   </p>
                          <p
                            className="flowsContent"
                          >
                            Before initiating Veeva approval, Custodian checks that the content has been uploaded as expected. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "VEEVEJOBBAG"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CHANGES AFFECT VEEVA JOB BAG </p>
                          <p
                            className="flowsContent"
                          >
                            <strong className="text-decoration-underline">No</strong> - If changes do not affect the text or the functionality as currently approved on Veeva, re-approval of Job bag will not be needed.  
                            Note that it is the combined responsibility of the Business Owner and the IT Custodian to assess whether VVPM approval is needed. 
                          </p>
                          <p className="flowsContent">
                            <strong className="text-decoration-underline">Yes</strong> - If changes affect the current Job Bag, proceed to Phase 3.  
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  <div id="accordion3" />
                  <Accordion
                    className="accordion3"
                    title="Phase 3: Material Approval & Launch"
                    defaultOpen={false}
                    isOpen={isPhase3Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-7 planning main-content">
                        <Phase3 ref={svgRef3} className="passportShape3" />
                      </div>
                      <div className="col-lg-5 planning flowsSecondPart side-content ">
                        <div
                          id="link1"
                          style={{
                            display:
                              P3visibleSection === "Initiate"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Initiate VVPM Examination </p>
                          <p className="flowsContent">
                              Updates are co-ordinated by the local IT Custodian and uploaded via local PoCs in VVPM. 
                              The job bag should be marked for <strong>‘Examination Only’</strong>.
                              They are then <strong>approved and certified on VVPM by the
                              local Business Owner, i.e., OCE Leader. </strong> 
                              Updates on Lilly Passport <strong>do not need</strong> to be approved by a Medical Certifier as there is no branded content. 
                          </p>
                          <p className="flowsContent">Scroll up to see VVPM job bag standards. All test links and change information must be uploaded to the job bag. </p>
                        </div>
                        <div
                          id="link2"
                          style={{
                            display:
                              P3visibleSection === "MlrVerdict"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">MLR VERDICT</p>
                          <p className="flowsContent">
                            There are three types of verdicts that the material 
                            could get: {" "}
                          </p>
                          <p className="flowsContent">
                            <strong>1. Approved for Production</strong> – The material with all screenshots documenting the change has been approved by the reviewer.  
                          </p>
                          <p className="flowsContent">
                            <strong>2. Approved with changes</strong> – The material has some minor errors/updates required (like spelling, grammatical errors). Incorporate the advised updates/corrections on the material and ask for the staging to be corrected and amend the document appropriately.  
                          </p>
                          <p className="flowsContent">
                            <strong>3. Revise and Resubmit</strong> –  The material has too many issues, and the changes should be discussed again. 
                          </p>
                        </div>
                        <div
                          id="link3"
                          style={{
                            display:
                              P3visibleSection === "EWILink"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">EWI provides new links  </p>
                          <p className="flowsContent">
                             Custodian, Owners and Translators review and/or test the updates made to the test links. 
                          </p>
                        </div>
                        <div
                          id="link4"
                          style={{
                            display:
                              P3visibleSection === "InitiateCertification"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">INITIATE CERTIFICATION  </p>
                          <p className="flowsContent">
                            Initiate certification in Veeva between the Coordinator and the Business Owner. 
                          </p>
                        </div>
                        <div
                          id="link5"
                          style={{
                            display:
                              P3visibleSection === "CertificationVerdict"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CERTIFICATION VERDICT    </p>
                          <p className="flowsContent">
                             There are three types of a verdicts you may get: {" "}  
                          </p>
                          <ul className="ul_padding">
                            <li className="flowsContent">
                              <strong> Certified </strong> – The material is Approved for Distribution now. The local Custodian can now communicate to the EWI team to make the changes live.  
                            </li>
                            <li className="flowsContent">
                              <strong> Revise and Resubmit</strong> (for certification) – The material has errors and/or requires updates. Custodian to ask the EWI team to make changes, if required. 
                            </li>
                            <li className="flowsContent">
                              <strong> Not Certified</strong> –  The material has found too many problems and the OCE Leader/Business Owner advises the team to rework on it and start a new MLR workflow. 
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link6"
                          style={{
                            display:
                              P3visibleSection === "BuisnessGoLive"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">BUSINESS OWNER ADVISES CUSTODIAN TO GO-LIVE    </p>
                          <p className="flowsContent">
                          Local Custodian ensures that all text links, credentials and necessary attachments have been added to the job bag. After which they can advise the EWI team to push the changes to live.
                          </p>
                        </div>
                        <div
                          id="link7"
                          style={{
                            display:
                              P3visibleSection === "ChangesAreLive"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CHANGES ARE LIVE</p>
                          <p className="flowsContent">
                          The Custodian advises EWI team to push changes to the live environment. The EWI team provides a go-live timeframe and a confirmation once changes are live.  
                          </p>
                        </div>
                        <div
                          id="link8"
                          style={{
                            display:
                              P3visibleSection === "PostGoLiveSteps"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">POST GO-LIVE STEPS     </p>
                          <p className="flowsContent">
                             <strong>Once Changes are Live:</strong>  
                          </p>
                          <p className="flowsContent">
                          The Custodian confirms go-live and reviews the content on the live site to ensure that it meets the functionality performance and text accuracy as expected (as documented on VVPM), this review must be completed within 2 working days.  
                          </p>
                          <ul className="ul_padding">
                            <li className="flowsContent">
                              The Custodian confirms completion of live site review to Business Owner (via email) and to the Veeva Coordinator.  
                            </li>
                            <li className="flowsContent">
                              The Veeva Coordinator uploads the confirmation email on VVPM under Attachments. 
                            </li>
                          </ul>  
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  {/*Accordion4*/}
                  <div id="accordion4" />
                  <Accordion
                    className="accordion4"
                    title="Phase 4: Material Management"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                       <p className="flowsContent p-3">
                          Cookie Policy Notice is managed through bi-annual review as part of the Controlled Self-Assessment (CSA).  
                       </p>
                    </div>
                  </Accordion>
                  <div id="accordion5" />
                  <Accordion
                    className="accordion5"
                    title="Phase 5: Material Withdrawal"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <p className="flowsHeading">Not Required.</p>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            
            {/*End Lifecycle */}
            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <ul class="ul_padding mt-2">
                <li> Business owner for Cookie webpage is local OCE leader </li>
                <li> Custodian is local Tech@Lilly manager </li>
                <li> Content is owned by GPO and/or local E&C </li>
                <li> Change is implemented by EWI team </li>
              </ul>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Role </th>
                        <th>Responsibility</th>
                        </tr>
                    </thead>  
                    <tbody>
                      <tr>
                         <td class="tb">
                              <h6>Veeva Owner</h6>
                          </td>
                          <td className='tb'>
                              <p>
                                  The Veeva Owner is also known as the Uploader (please see 'Veeva uploader')
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                      </tr>
                      <tr>
                          <td class="tb">
                              <h6>Material Originator</h6>
                          </td>
                          <td>
                              Responsible for:
                              <ol>
                                  <li>Having overall responsibility and accountability for the material</li>
                                  <li>Ensure that no item is used without the necessary approvals</li>
                                  <li>Ensuring timely change and/or withdrawal, where needed</li>
                              </ol>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a>
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td class="tb">
                              <h6>Veeva Uploader</h6>
                          </td>
                          <td>
                              <p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA
                                  teams, and is assigned the Veeva Owner role.</p>
                          </td>
                      </tr>
                      <tr>
                          <td class="tb">
                              <h6>Veeva Co-ordinator</h6>
                          </td>
                          <td>
                              <p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager (or similar
                                  role). </p>
                          </td>
                      </tr>
                      <tr>
                          <td class="tb">
                              <h6>Business Reviewer</h6>
                          </td>
                          <td>
                              <p>Business Reviewer is an experienced commercial employee.</p>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>Expanded</strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                    Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool </td>
                    <td className="tb">Planning and execution tools combine to form the CET. With CET, customer plans can be created and campaigns can be managed in one place. </td>
                  </tr>
                  <tr>
                    <td className="tb">Conversion Metric </td>
                    <td className="tb"></td>
                    <td className="tb">Metrics used to measure the number of valuable actions customers take through a digital marketing channel. </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey </td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr><tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">Goals or Metrics set behind a campaign and or tactic.</td>
                  </tr>
                  <tr>
                    <td className="tb">PMC</td>
                    <td className="tb">Permissions Managed Content</td>
                    <td className="tb">An image which is owned by an entity other than Lilly and for which permission is given to use under a specific set of circumstances as defined in a contractual agreement between Lilly and the Content Owner.</td>
                  </tr>
                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"></td>
                    <td className="tb">Tactics are specific to selected channels to drive customers to reach a piece of content. Tactics are associated with estimated KPI metric (goal) and result reports (actuals).</td>
                  </tr>
                  <tr>
                    <td className="tb">VVPM</td>
                    <td className="tb">Veeva Vault PromoMat</td>
                    <td className="tb">Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems. PromoMats is a Vault application to manage the process of creating, reviewing and approving promotional materials, claims and supporting information.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Defination*/}

            {/*View Core Doc*/}
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.CookieCoreDoc}
                  target="_blank"
                >
                  Cookie Policy document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/*End Core Doc*/}
          </div>
        </div>
      </div>

    </div>
  );
};


export default Cookie;
