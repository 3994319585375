import React from "react";
import { useState, useRef, useEffect } from "react";
import {webConfig} from '../configs/webConfig'
import { Link,useNavigate } from 'react-router-dom';
import "../css/SlideDecs.css";
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/Slide_Decs_Phases_SVG/Slide_Decs_Phase_1.svg";
import { ReactComponent as Phase2 } from "../SVG/Slide_Decs_Phases_SVG/Slide_Decs_Phase_2.svg";
import { ReactComponent as Phase3 } from "../SVG/Slide_Decs_Phases_SVG/Slide_Decs_Phase_3.svg";
import { ReactComponent as Phase4 } from "../SVG/Slide_Decs_Phases_SVG/Slide_Decs_Phase_4.svg";
import { ReactComponent as Phase5 } from "../SVG/Slide_Decs_Phases_SVG/Slide_Decs_Phase_5.svg";



const SlideDecs = () => {
  const navigate = useNavigate();
  const [isPhase1Open, setIsPhase1Open] = useState();
  const [isPhase2Open, setIsPhase2Open] = useState();
  const [isPhase3Open, setIsPhase3Open] = useState();
  const [isPhase4Open, setIsPhase4Open] = useState();
  const [isPhase5Open, setIsPhase5Open] = useState();
  
  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("phase1rect1");

  const svgRef2 = useRef();
  const [P2visibleSection, setP2visibleSection] = useState("phase2rect1");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("phase3rect1");

  const svgRef4 = useRef();
  const [P4visibleSection, setP4visibleSection] = useState("phase4rect1");

  const svgRef5 = useRef();
  const [P5visibleSection, setP5visibleSection] = useState("Gatekeeper");
  
  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    rect1.addEventListener("click", () => {
      setP1visibleSection("phase1rect1");
    });
  }, []);

  useEffect(() => {
    const svg = svgRef2.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    const rect8 = svg.getElementById("rect8");
    
    rect1.addEventListener("click", () => {
      setP2visibleSection("phase2rect1");
    });
    rect2.addEventListener("click", () => {
      setP2visibleSection("phase2rect2");
    });
    rect3.addEventListener("click", () => {
      setP2visibleSection("phase2rect3");
    });
    rect4.addEventListener("click", () => {
      setP2visibleSection("phase2rect4");
    });
    rect5.addEventListener("click", () => {
      setP2visibleSection("phase2rect5");
    });
    rect6.addEventListener("click", () => {
      setP2visibleSection("phase2rect6");
    });
   
    const handleScrollP3 = () => {
      const elementToScrollTo = document.getElementById("accordion3");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
   
    rect7.addEventListener("click", () => {
      handleScrollP3();
      setIsPhase3Open(true);
    });
    rect8.addEventListener("click", () => {
      handleScrollP3();
      setIsPhase5Open(true);
    });
    
  }, [isPhase3Open]);

  useEffect(() => {
    const svg = svgRef3.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    const rect8 = svg.getElementById("rect8");
    const rect9 = svg.getElementById("rect9");
    
    rect1.addEventListener("click", () => {
      setP3visibleSection("phase3rect1");
    });
    rect2.addEventListener("click", () => {
      setP3visibleSection("phase3rect2");
    });
    rect3.addEventListener("click", () => {
      setP3visibleSection("phase3rect3");
    });
    rect4.addEventListener("click", () => {
      setP3visibleSection("phase3rect4");
    });
    rect5.addEventListener("click", () => {
      setP3visibleSection("phase3rect5");
    });
    rect6.addEventListener("click", () => {
      setP3visibleSection("phase3rect6");
    });

    rect7.addEventListener("click", () => {
      setP3visibleSection("phase3rect7");
    });
    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect8.addEventListener("click", () => {
      handleScrollP2();
      setIsPhase2Open(true);
    });
  }, [isPhase2Open]);
  
  useEffect(() => {
    const svg = svgRef4.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    
    rect1.addEventListener("click", () => {
      setP4visibleSection("phase4rect1");
    });
    rect2.addEventListener("click", () => {
      setP4visibleSection("phase4rect2");
    });

    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    const handleScrollP5 = () => {
      const elementToScrollTo = document.getElementById("accordion5");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    rect3.addEventListener("click", () => {
      handleScrollP2();
      setIsPhase3Open(true);
    });
    rect4.addEventListener("click", () => {
      handleScrollP5();
      setIsPhase5Open(true);
    });
    
  }, [isPhase2Open,isPhase5Open]);



  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    Slide Deck Creation (Lilly Created or Speaker Created)  
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd"> Slide Deck Creation (Lilly Created or Speaker Created) </h2>
            <p>
              <em>
                The Slide Deck represents the content created for the webinar. The slides should be created way in advance and should be approved/certified through PMAP process. There are two types of slide decks:  
              </em>
            </p>
            <em>
              <ul className="ul_padding">
                <li>
                  Lilly-created slides are produced without the involvement of an external Speaker. Examples would include slides which are co-created with the International Business Unit (IBU), those created by a local Medical Agency, or even a deck created by the Brand team.
                </li>
                <li>
                  Speaker-created slides are those which are researched and developed by the healthcare professional (HCP) who is going to present them.  
                </li>
                <li>
                  Speaker Contracts need to be in place before salesforce meetings. 
                </li>
                <li>
                  Slides should always be presented by Lilly employee laptop, not through speakers own device 
                </li>
              </ul>
            </em>
          </div>
        </div>
      </div>

      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link  active"
              data-bs-toggle="tab"
              href="#processPreRequisites"
            >
              Process  
              <br />
              Pre-requisites 
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#VVPMJobBagStd">
               VVPM Job Bag Standards
            </a>
          </li>
        </ul>
        
        <div id="tabContent" className="tab-content">
            <div id="processPreRequisites" className="tab-pane fade in active tabs_box tabContent">
                <ul class="ul_padding">
                  <li className='tabContent'>
                    Several training courses will be required before commencing work on Speaker slide decks.
                  </li>
                  <li className='tabContent'>
                    Speakers should be selected based on their expertise, geography and Meeting objectives. In the context of engaging a Speaker to develop slides, it should be a Brand team’s decision with significant input from the National Therapeutic Advisor (NTA) and/or Medical.   
                  </li>
                  <li className='tabContent'>
                    There is guidance document in place that helps speakers understand Lilly’s expectation when developing a slide. This can be referred to {' '}
                      <Link 
                          to={webConfig.SpeakerTrainingCoreSlides}
                          target="_blank" 
                        >
                        Speaker Training Core Slides.
                      </Link>
                  </li>
                </ul>
            </div>
            <div id="VVPMJobBagStd" class="tab-pane fade in tabs_box" role="tabpanel">
              <p class="tabsContentHeading">Piece:</p>         
              <ul class="ul_padding">
                <li className='tabContent'>Ensure that the correct template is used (e.g., internal use only/copyright statement etc.). </li>
                <li className='tabContent'>Upload the slide deck. </li>
                <li className='tabContent'>When uploading the slides with Speaker notes, go to the action wheel and choose the notes options from the drop-down menu <strong>before MLR</strong> so that the Speaker notes become part of the approval process. This action <strong>must</strong> be done before MLR otherwise the certificate is removed if done post-certification.</li>
                <li className='tabContent'>If the slide deck is to be used by the external Speakers, then an individualised Speaker disclosure slide <strong>must</strong> be created at the beginning of the slide deck.  </li>
              </ul>
              
              <p class="tabsContentHeading">Purpose Field:</p>         
              <ul class="ul_padding">
                <li className='tabContent'>State whether the slide deck is to be used externally/internally and explain the purpose.</li>
              </ul>
                
              <p class="tabsContentHeading">Attachments:</p>         
              <ul class="ul_padding">
                <li className='tabContent'>Change Document Template (if applicable) </li>
              </ul>
                
              <p class="tabsContentHeading">Linked Documents:</p>         
              <ul class="ul_padding"><li className='tabContent'>Current PI (if promotional)</li>
                <li className='tabContent'>Content references anchored appropriately. </li>
              </ul>
              <p class="tabsContentHeading">Related Permission Managed Content:</p>         
              <ul class="ul_padding">
                <li className='tabContent'>Include required Permission Managed Content (PMC) documents.</li>
              </ul>

              <p class="tabsContentHeading">Final Form:</p>         
              <ul class="ul_padding">
                <li className='tabContent'>Slide deck in the format of a PowerPoint document in final rendition.</li>
              </ul>

              <p class="tabsContentHeading">Notes:</p>         
              <ul class="ul_padding">
                <li className='tabContent'>All promotional material requires certification by a Medical Certifier. </li>
                <li className='tabContent'>As part of the process of briefing the Speaker, the Speaker should be advised upfront by the Event Owner that their slides may require some changes to ensure compliance with PMCPA Code of Practice. It is essential that the objectives, aims and the type of event are discussed upfront by the Event Owner with the Speaker before the Speaker commences producing the slides.   </li>
                <li className='tabContent'>LCCI will technically review Lilly generated slides. Clinical Research Scientist (CRS)/Clinical Research Physician (CRP) may be required to provide a scientific or disease area review of Lilly or Speaker generated slides.</li>
                <li className='tabContent'>It is the responsibility of the Originator to check with the Lilly member of staff who supplied the Speaker slides or case studies that the AE/PC has been reported. Add the date reported to the metadata of the job bag.  AE/PCs should still be reported even if the HCP refuses to follow-up. </li>
                <li className='tabContent'>As a result of Brexit, more than one Summary of Product Characteristics (SmPC) may need to be referenced in a briefing document depending on the presentation content, the Audience, and the Brexit status of the product(s). </li>
                <li className='tabContent'>The Speaker/Facilitator/Chair must be briefed as to which country the Audience is from and which SPC(s) will apply.</li>
              </ul>

              <p class="tabsContentHeading">Content of Speaker Slides :</p>         
              <ul class="ul_padding">
                <li className='tabContent'>Presentations <strong>must</strong> not be inconsistent with licensed indication. Audience <strong>must</strong> not be misled with respect to the licensed indication. It is a good practice to include a licensed indication slide.</li>
                <li className='tabContent'>The Speaker <strong>must</strong> include a slide to disclose conflicts of interest. </li>
                <li className='tabContent'>References need to be cited. </li>
                <li className='tabContent'>Speakers may present their own studies or data, which do not have to be substantiated.  This data <strong>must</strong> not be inconsistent with the licensed indication.  The Certification team may ask for further references/supporting data depending on the content.  </li>
                <li className='tabContent'>It is not possible to hide slides; the certified version is the final form which will be delivered to the Audience.  </li>
                <li className='tabContent'>PI <strong>must</strong> be available/provided. Depending on delivery of the promotional event either embedded into the slide deck or one click away for digital events, consult with the Certification team.  The PI as the last slide (if applicable) <strong>must</strong> be left on the screen for long enough so that the Audience can read it.  </li>
                <li className='tabContent'>The quality and quantity of slides <strong>must</strong> be relevant to the time allocated for the event. </li>
                <li className='tabContent'>If the Method of Dissemination in the metadata states that the slides can be downloaded or sent via email to HCPs, the following disclaimer is to be added to the first slide:
                  <em>“The content of these slides is Lilly property. You are permitted to download and use these slides for your own personal use only. Under no circumstances should these slides be distributed to Third Parties.” </em>
                </li>
              </ul>
              <p class="tabsContentHeading">Speaker’s First Slide: </p>         
              <ul class="ul_padding">
                <li className='tabContent'>Include an appropriate Lilly/brand logo on the first slide.</li>
                <li className='tabContent'>The non-proprietary name of the medicine <strong>must</strong> appear immediately adjacent to the most prominent display of the brand name.</li>
                <li className='tabContent'>Include an inverted black triangle adjacent to the most prominent (or first mention in digital materials) name of the medicine as appropriate. </li>
                <li className='tabContent'>Title <strong>must</strong> match the invite. </li>
                <li className='tabContent'>Include Speaker’s name, title and place of work. </li>
                <li className='tabContent'>Include the date of preparation and the job bag number. </li>
                <li className='tabContent'>All promotional materials <strong>must</strong> include a prominent adverse event reporting statement.  </li>
                <li className='tabContent'>Include transparent information with regards to sponsorship arrangements. (See {' '}
                <Link
                  to={webConfig.PMAPJobAid}
                  target="_blank">PMAP Job Aid</Link>{' '}for wording.) </li>
                <li className='tabContent'>Slides should <strong>not</strong> state that “These slides have been developed by the Speaker”.</li>
              </ul>
 
            </div>
            
           
        </div>
      </div>
      
      {/*Add verticle Tab*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">Slide Decs Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Pre-Meeting Planning"
                    defaultOpen={true}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning d-flex justify-content-center">
                        <div className="comman_width_e">
                         <Phase1 ref={svgRef1} className="ibuShape1" />
                        </div> 
                      </div>
                      <div className="col-lg-7 planning p_font pt-3 flowsSecondPart">
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect1" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">Pre-Veeva Meeting   </p>
                          <p className="flowsContent">
                            Planning is everything! The core objectives of a pre-Veeva discussion should not be overlooked. These are to ensure that: 
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">The reviewer is never presented with material in Veeva Vault PromoMats (VVPM) which they have no background information on</li>
                            <li className="flowsContent">The Review team understands its current priorities for review.  </li>
                          </ul>
                          <p>
                            Together these aim to provide a smoother and quicker approval process for materials.
                          </p>
                          <p>
                            Visit {' '} 
                            <Link 
                              to={webConfig.LillyPlanning}
                              target="_blank" 
                            > Planning (lilly.com)
                          </Link> to learn more about pre-Veeva meetings.  
                          </p>
                          <p className="flowsHeading">REMEMBER   </p>
                          <p className="flowsContent">
                            If building a Lilly-created promotional slide deck, consider using Core Claims {' '} 
                            <Link 
                              to={webConfig.PMAPCoreClaimsAllDocuments}
                              target="_blank" 
                            > PMAP - Core Claims - All Documents (lilly.com)
                          </Link> as this will usually mean a speedier review, and a closer alignment to brand strategy. 
                          </p>
                          
                        </div>      
                      </div>
                    </div>
                  </Accordion>

                  <div id="accordion2" />
                  <Accordion
                    className="accordion2"
                    title="Phase 2: Material Readiness"
                    defaultOpen={false}
                    isOpen={isPhase2Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning">
                          <Phase2 ref={svgRef2} className="slideDecsShape2" />
                      </div>
                      <div className="col-lg-6 planning pt-4 flowsSecondPart">
                        <div
                          style={{
                            display:
                              P2visibleSection === "phase2rect2"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Slide Creation – Lilly-Owned Slides  </p>
                          <p className="flowsContent">
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                    Once the Brand team has agreed on the need and the concept, and this has been agreed upon at the pre-Veeva Meeting, slide development may commence. 
                                    <ol className="mb-2">
                                       <li>Material Owner or a local agency, may decide to localise slides provided by the IBU.</li>
                                       <li>A local agency may create the deck on behalf of Lilly. </li>
                                       <li>The Lilly Brand team may build the slides by themselves; where this happens, it is almost always driven by the medical members of the Brand team. </li>
                                       <li>Ensure that the slides meet all compliance requirements including a disclosure slide for the Speaker, full referencing in the Vancouver format, any applicable product indications, Adverse event/Product complaint (AE/PC) reporting information and copyright information. </li>
                                    </ol>
                                </li>
                                <li className="flowsContent">
                                    Once received
                                    <ol className="mb-2">
                                      <li>If the slides meet expectation, then Medical, Legal, Regulatory (MLR) can be commenced. </li>
                                      <li>If the slides do not meet expectation, then the Brand team should re-convene and re-brief the creator. </li>
                                    </ol>
                                </li>
                                <li className="flowsContent mb-2">
                                    It is the responsibility of the Material Coordinator to review the artwork provided for accuracy before submitting to MLR. 
                                </li>
                                <li className="flowsContent mb-2">
                                    If the slides are for use by the sales force at local customer Meetings in the <strong>UK or Ireland</strong>, consider starting the sales force briefing document at this time. 
                                </li>
                            </ul>
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "phase2rect1"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Slide Creation – Speaker-Developed Slides   </p>
                          <p className="flowsContent">
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                  This method of slide creation requires careful management of the Speaker to ensure that the compliance requirements are met. A thorough brief to the Speaker needs to be provided on the following: 
                                  <ol className="ul_margin_bottom">
                                      <li>The timelines required for the process and that the Speaker should not start with the creation of the slides unless an agreement is signed.</li>
                                      <li>Whether any specific slide template should be used. </li>
                                      <li>Whether a Q&A section will need to be planned for.  </li>
                                      <li>The requirement for the slide content to be within the product licence (if applicable).  </li>
                                      <li>The fact that the slides will need to be reviewed by the Lilly Medical team prior to the presentation. </li>
                                      <li>The reference citation must give enough information to allow a reader to readily source the reference. References should be based on the Vancouver Style Referencing Guidelines, using a numerical endnote approach. For each type of source material, different information is required to identify that type of material. It is also important to note that whatever the chosen style is, it should be consistently used throughout a single piece of the material.  </li>
                                      <li>The Speaker is responsible for ensuring that the content of the presentation does not breach any Third-Party intellectual property rights and does not contain anything libellous or defamatory. The Speaker must therefore own the copyright for images and/or content used in the presentation or have the necessary explicit permission to reproduce such images/content for this purpose. Please supply to Lilly ‘Usage Rights’ for consent/copyright for copied graphs, diagrams, charts and extracts from published sources. When using information from websites, check specifically if the Publisher/Owner/Author(s) permit reference citation. If the Speaker does not own the copyright for images/content included in the presentation or has the right to use such images/content for this purpose, it must be removed from the presentation.</li>
                                      <li>Internal body images are not considered to be Personal Information and are fine to share with gender and an age range. External body images in non-fictional patient case studies will require completion of a Lilly Audio-Visual Form and submission to Lilly, together with the related slide deck. A disclaimer should be added in the slide, at the bottom of a photograph. </li>
                                      <li>
                                        The Speaker may be asked by the Lilly Meeting Owner for permission to share the slides with delegates. This request will be confirmed with the Speaker directly. 
                                        <br />
                                        <strong>NORDIC</strong> requirement: Speaker slides that are shared with delegates needs to be handled as its own promotional material. 
                                      </li>
                                      <li>Slides should be submitted by the deadline requested by the Lilly Meeting Owner above. </li>
                                      <li>The Speaker cannot amend the slide deck, once it has been submitted to Lilly for approval, unless Lilly requests changes.</li>
                                      <li>A disclosure slide is needed at the beginning of the presentation. </li>
                                      <li>If an unsolicited off-label question arises from the Audience during the Meeting, the question must be noted by the Speaker. The Speaker must then advise the Audience that the question will be dealt with at the end of the Meeting in a 1:1 or small group setting, if more than one member of the Audience has the same query, Lilly’s Medical Information team can be contacted. </li>
                                  </ol>
                                </li>
                                <p className="flowsContent">
                                  <strong>Important:</strong> The Speaker must never commence work on preparing the presentation until there is a signed contract in place between them and Lilly. Lilly is unable to process any honorarium for preparation time prior to the contract being signed. 
                                </p>
                            </ul>
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "phase2rect3"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Slides Delivered</p>
                          <p className="flowsContent">
                            It is the responsibility of the Material Administrator to review the video provided for accuracy before submitting to MLR. At this stage, it is the best practice to get aligned with the reviewers before submitting the material to MLR.  
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "phase2rect4"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Slides Delivered</p>
                          <p className="flowsContent">
                            It is the responsibility of the Material Administrator to review the video provided for accuracy before submitting to MLR. At this stage, it is the best practice to get aligned with the reviewers before submitting the material to MLR.  
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "phase2rect5"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Slides Meet Expectation</p>
                          <p className="flowsContent">
                            If the slides meet expectations, then initiate MLR.
                          </p>
                          <p className="flowsContent">
                            For Speaker-owned slides either go back to the Speaker and explain the changes which are required. If these relate to content, make minor formatting and cosmetic changes at a Brand team level. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "phase2rect6"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Slides Meet Expectation</p>
                          <p className="flowsContent">
                          If the slides meet expectations, then initiate MLR. If the slides do not meet expectations, then for Lilly-owned slides either go back to the agency and brief them on the changes or make these at the Brand team level. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  <div id="accordion3" />
                  <Accordion
                    className="accordion3"
                    title="Phase 3: Material Approval & Launch"
                    defaultOpen={false}
                    isOpen={isPhase3Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-7 planning main-content">
                        <Phase3 ref={svgRef3} className="slideDecsShape3" />
                      </div>
                      <div className="col-lg-5 planning flowsSecondPart side-content ">
                        <div
                          style={{
                            display:
                              P3visibleSection === "phase3rect1"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">INITIATE MLR APPROVAL</p>
                          <p className="flowsContent">
                            This is the stage where the Veeva Coordinator uploads the material to VVPM for reviewers to provide a verdict on it. Click {" "} 
                            <Link
                              to={webConfig.PMAPSite}
                              target="_blank"
                            >The PMAP Site</Link> to refer to the PMAP job aid.  
                             
                          </p>
                          <p className="flowsHeading">REMEMBER</p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                              <strong>FOR UK –</strong> A separate approval for an internal briefing guide to be added as a supporting document must be initiated – see the flow for internal documents.  
                            </li>
                            <li className="flowsContent">
                              For Speaker-developed slides, add the Speaker contract number in the ‘Purpose’ field.
                            </li>
                            <li className="flowsContent">
                              <strong>NORDICS: </strong> References do not need to be added to the job bag. 
                            </li>
                          </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P3visibleSection === "phase3rect2"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">MLR Verdict </p>
                          <p className="flowsContent">
                            There are three types of verdicts that the material could get:   
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                              <strong>Approved for Production –</strong> The material has been blessed by the reviewers. Go ahead and initiate the certification. 
                            </li>
                            <li className="flowsContent">
                              <strong>Approved with Changes –</strong> The material has some minor errors/updates required (like spelling, grammatical errors).  
                                <br/>
                                Assess whether these are best made by Lilly or the agency (in cases of Lilly-owned slides and minor cosmetic changes to Speaker-owned slides) or by the Speaker themself in the case of content changes to Speaker-owned slides. 
                            </li>
                            <li className="flowsContent">
                              <strong>Revise and Resubmit –</strong> The material has too many problems and the reviewer advises the Veeva Coordinator to rework on it and submit again.  
                              <br/>
                              Assess whether these are best made by Lilly or the agency (in the case of Lilly-owned slides and minor cosmetic changes to Speaker-owned slides) or by the Speaker themself in the case of content changes to Speaker-owned slides. 
                            </li>
                          </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P3visibleSection === "phase3rect4"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Initiate Certification   </p>
                          <p className="flowsContent">
                          This is the stage where the Veeva Coordinator uploads final form of the material to VVPM for Certifiers to provide a verdict on the material.    
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P3visibleSection === "phase3rect5"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Certification Verdict </p>
                          <p className="flowsContent">
                             There are three types of a verdicts you may get: {" "}  
                          </p>
                          <ul class="ul_padding">
                            <li className="flowsContent">
                              <strong> Certified </strong> – The material is Approved for Distribution now. Go ahead to review/set Inventory Information on VVPM and set the Actual Date of First Use.  
                            </li>
                            <li className="flowsContent">
                              <strong> Revise and Resubmit</strong> (for certification) – The material has errors and/or requires updates. Assess whether these are best made by Lilly or the agency (in the case of Lilly-owned slides and minor cosmetic changes to Speaker-owned slides) or by the Speaker themself in the case of content changes to Speaker-owned slides. 
                            </li>
                            <li className="flowsContent">
                              <strong> Not Certified</strong> – The material has too many problems, and the Certifier advises the Material Administrator to rework on it and start a new MLR workflow. Assess whether these are best made by Lilly or the agency or by the Speaker themself in the case of content changes to Speaker-owned slides. 
                            </li>
                          </ul>  
                          <p className="flowsHeading">REMEMBER</p>
                          <p className="flowsContent">
                            The verdict <strong>‘Certified’</strong> does not mean that the material is now live. Additional steps are needed to move it to the environment where it will be available to the end user. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P3visibleSection === "phase3rect6"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Final Steps Before ‘Go-Live’ </p>
                          <ul class="ul_padding">
                            <li className="flowsContent">
                              <strong>Actual Date of First Use</strong> –  is set by the Veeva Coordinator and is typically put as the date the material has been certified on VVPM, unless there is a specific date of when the usage of material will start (for example launch date, after the salesforce training etc.). 
                            </li>
                            <li className="flowsContent">
                              <strong>Inventory Information</strong> – The Veeva Coordinator would have added this earlier to VVPM metadata and it should be checked when the material goes live. This step will support efficient material management and withdrawal.
                            </li>
                            <li className="flowsContent">
                              <strong>Expiration Date</strong> – is usually set for 2 years. However, if the material is a One-time use or for a defined time, set the Expiry Date accordingly.
                            </li>
                            <li className="flowsContent">
                              <strong>Related Pieces</strong> – The Veeva Coordinator should check that any relevant related pieces are linked to the VVPM job bag. 
                            </li>
                          </ul>  
                        </div>
                        <div
                          style={{
                            display:
                              P3visibleSection === "phase3rect7"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Material Go-Live Phase </p>
                          <p className="flowsContent bold mt-3">
                            If the slides are being presented on an external platform   
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                              Ensure that the slides are available for the vendor according to the process agreed on. 
                            </li>
                          </ul>
                          <p className="flowsContent bold mt-3">
                            If the slides have been developed by a local Speaker  
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                              Send them a PDF copy of the final Certified slides for information.  
                            </li>
                            <li className="flowsContent">
                              Inform them that the slides used during the Meeting are hosted on a Lilly computer. 
                            </li>
                          </ul>
                          <p className="flowsContent bold mt-3">
                            For Customer-Facing team slides available for local Meetings 
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                              Communicate the availability of the slide deck by providing a link to their location.  
                            </li>
                            <li className="flowsContent">
                              <strong>FOR THE UK AND ROI: </strong>They should be accompanied by the appropriate briefing guide in the case.  
                            </li>
                            <li className="flowsContent">
                              Instruct Customer-Facing team to send a PDF copy of the final certified slides for information to the Speaker and for completion of the disclosure slides as needed. 
                            </li>
                            <li className="flowsContent">
                              Instruct the Customer-Facing team to inform the Speaker that the slides used during the Meeting are hosted on a Lilly computer. 
                            </li>
                          </ul>
                        </div>
                       
                      </div>
                    </div>
                  </Accordion>

                  {/*Accordion4*/}
                  <div id="accordion4" />
                  <Accordion
                    className="accordion4"
                    title="Phase 4: Material Management"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                        <Phase4 ref={svgRef4} className="slideDecsShape4" />
                      </div>
                      <div className="col-lg-7 planning p-3 flowsSecondPart">
                        <div
                          style={{
                            display:
                              P4visibleSection === "phase4rect1"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            Quarterly Material Review by Brand Team  
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                              For Operational excellence in material management, NE Hub has a quarterly Operational Review of Inventory. The Brand Leader/Business Unit Director should lead this exercise with their team to review the list of materials in VVPM and decide whether to update the material, leave it as it is for now or withdraw it from VVPM.  
                            </li>
                            <li className="flowsContent">
                              Always check Inventory Information to see if any metadata needs to be updated. 
                            </li>
                          </ul>
                        </div>
                         <div
                          style={{
                            display:
                              P4visibleSection === "phase4rect2"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Update of Material </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                              An outcome of the quarterly material review (or other reasons such as Prescribing Information [PI] update) might result in a need to update the material.  
                            </li>
                            <li className="flowsContent">
                              Updates in the material initiates the process right from the beginning, i.e., ‘briefing’ and the withdrawal process of the existing version. 
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </Accordion>
                  <div id="accordion5" />
                  <Accordion
                    className="accordion5"
                    title="Phase 5: Material Withdrawal"
                    defaultOpen={false}
                    isOpen={isPhase5Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning p-3">
                           <Phase5 ref={svgRef5} className="slideDecsShape5" />
                      </div>
                      <div className="col-lg-7 planning p-3 flowsSecondPart">
                        <div
                          id="withdrawal_phase"
                          style={{
                            display:
                              P5visibleSection === "Gatekeeper"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            MATERIAL WITHDRAWAL PHASE
                          </p>
                          <p className="flowsContent">
                              Material Withdrawal phase starts with the brand team´s decision to discontinue a material and it ends with the completed and documented destruction of the material.{" "}
                          </p>
                          <p className="flowsContent">
                             The materials need to be removed from all storage locations and circulation before initiating the withdrawal in the Withdrawal Tool (wherever applicable). The Inventory Information field in VVPM will help the Material Administrator to capture all storage locations for effective destruction.  {" "}
                          </p>
                          <p className="flowsHeading">
                             Withdrawal and Retirement 
                          </p>

                          <p className="flowsContent">
                            Brand team’s <strong>withdrawal</strong> actions include the following: 
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                              The Material Administrator initiates the retirement process by removing items from being accessed/ordered as per inventory list in VVPM. 
                            </li>
                            <li className="flowsContent">
                              The Material Administrator initiates withdrawal notification to the Customer-Facing team if the Customer-Facing teams have access to it. 
                            </li>
                            <li className="flowsContent">
                              The Customer-Facing team acknowledges withdrawal and destruction by the set date. 
                            </li>
                            <li className="flowsContent">
                              The Veeva Coordinator retires the material in VVPM. 
                            </li>
                          </ul>
                          <p className="flowsHeading">
                            Remember 
                          </p>
                          <p className="flowsContent">
                            If the slide decks are with the Speaker, the Material Originator should inform them that these are not to be used anymore. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            
            {/*End Lifecycle */}
            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Role </th>
                        <th>Responsibility</th>
                        </tr>
                    </thead>  
                    <tbody>
                      <tr>
                         <td className="tb">
                              <h6>Veeva Owner</h6>
                          </td>
                          <td className='tb'>
                              <p>
                              The Veeva Owner is also known as the Uploader (please see 'Veeva uploader') 
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator</h6>
                          </td>
                          <td>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Having overall responsibility and accountability for the material</li>
                                  <li>2. Ensure that no item is used without the necessary approvals</li>
                                  <li>3. Ensuring timely change and/or withdrawal, where needed</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a>
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Meeting Owner </h6>
                          </td>
                          <td>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Briefing speaker on requirements</li>
                                  <li>2. Liaising regarding slides</li>
                                  <li>3. Ensuring speaker contracting, briefing and speaker training are completed</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Uploader</h6>
                          </td>
                          <td>
                              <p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA
                                  teams, and is assigned the Veeva Owner role.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Uploading the material to Veeva</li>
                                  <li>2. Performing all activities related to upload</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Co-ordinator</h6>
                          </td>
                          <td>
                              <p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager (or similar
                                  role). </p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Reviewing the item before sending for MLR review</li>
                                  <li>2. Managing the document workflow</li>
                                  <li>3. Assigning reviewers</li>
                                  <li>4. Managing expiry or periodic review</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Business Reviewer</h6>
                          </td>
                          <td>
                              <p>Business Reviewer is an experienced commercial employee.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring all promotional materials align with the brand strategy, Lilly Brand Book, Product Brand
                                      Book</li>
                                  <li>2. Ensuring all materials are of good quality, have correct Veeva metadata and adhere to internal
                                      policies and local codes</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Medical Certifier</h6>
                          </td>
                          <td>
                              <p>Registered medical practitioner or UK-registered Pharmacist.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring that the material is in accordance with the requirements of the local code</li>
                                  <li>2. Ensuring that the material is not inconsistent with the marketing authorisation/SmPC (if applicable)
                                  </li>
                                  <li>3. Ensuring that the material is a fair and truthful representation of the facts about the medicine (if
                                      applicable)</li>
                                  <li>4. Approving the material in its final form</li>
                                  <li>5. Ensuring the material has the appropriate reviewers and that the Veeva metadata is correct.</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for ensuring that the Veeva metadata is correct and that the PMC
                                  documents are included (if applicable).</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordic Code Approver</h6>
                          </td>
                          <td>
                              <p>Code Approver must be a medical practitioner or a pharmacist (except for in Sweden) and is responsible for
                                  ensuring that the material is in line with the local Codes and that the material being approved has the
                                  appropriate reviewers; and ensuring that the Veeva metadata is correct.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordics Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for reviewing the material from a medical perspective.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Indegene</h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1. Creating artwork as per material originator brief</li>
                                  <li>2. Creating formatted PI PDFs</li>
                              </ul>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>What it Stands for </strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                    Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool </td>
                    <td className="tb">Planning and execution tools combine to form the CET. With CET, customer plans can be created and campaigns can be managed in one place. </td>
                  </tr>
                  <tr>
                    <td className="tb">Conversion Metric </td>
                    <td className="tb"></td>
                    <td className="tb">Metrics used to measure the number of valuable actions customers take through a digital marketing channel. </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey </td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">Goals or Metrics set behind a campaign and or tactic.</td>
                  </tr>
                  <tr>
                    <td className="tb">PMC</td>
                    <td className="tb">Permissions Managed Content </td>
                    <td className="tb">An image which is owned by an entity other than Lilly and for which permission is given to use under a specifc set of circumstances as defined in a contractural agreement between Lilly and the Content Owner. </td>
                  </tr>
                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"> </td>
                    <td className="tb">Tactics are specific to selected channels to drive customers to reach a piece of content. Tactics are associated with estimated KPI metric (goal) and result reports (actuals).  </td>
                  </tr>
                  <tr>
                    <td className="tb">VVPM</td>
                    <td className="tb">Veeva Vault PromoMat</td>
                    <td className="tb">Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems. PromoMats is a Vault application to manage the process of creating, reviewing and approving promotional materials, claims and supporting information.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Defination*/}

            {/*View Core Doc*/}
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.SlideDecksCoreDoc}
                  target="_blank"
                >
                  Slide Deck document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/*End Core Doc*/}
          </div>
        </div>
      </div>
      <div id="popup_flight_travlDil30" class="overlay_flight_traveldil">
        <div class="popup_flight_travlDil" >
          <p class="flowsHeading">NORDICS</p>
          <p class="flowsContent">
            If the material gets the verdict <strong>‘Not Certified’</strong>, use <strong>‘Create Draft’</strong> function in VVPM to use the same job bag code for the updated material. 
          </p>
          <a class="close_flight_travelDl" href="#/">&times;</a>
        </div>
      </div>
      <div id="popup_flight_travlDil31" class="overlay_flight_traveldil">
        <div class="popup_flight_travlDil" >
          <p class="flowsHeading">NORDICS</p>
          <p class="flowsContent">
            Please check the {' '}
            <Link
              to={webConfig.NordicPMAPGuidance}
              target="_blank"
            >Nordic PMAP Guidance
            </Link>{' '}for detailed information on statutory copy submission.
          </p>
          <a class="close_flight_travelDl" href="#/">&times;</a>
        </div>
      </div>
      <div id="popup_flight_travlDil40" class="overlay_flight_traveldil">
        <div class="popup_flight_travlDil" >
          <p class="flowsHeading">UK</p>
          <p class="flowsContent">Review the briefing template for any update required and storage locations.</p>
          <a class="close_flight_travelDl" href="#/">&times;</a>
        </div>
      </div>
      <div id="popup_flight_travlDil50" class="overlay_flight_traveldil">
        <div class="popup_flight_travlDil" >
          <p class="flowsHeading">UK</p>
          <p class="flowsContent">Withdraw the briefing template from agreed storage and distribution location </p>
          <a class="close_flight_travelDl" href="#/">&times;</a>
        </div>
      </div>

    </div>
  );
};


export default SlideDecs;
