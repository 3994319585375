import React from "react";
import { useState, useRef, useEffect } from "react";
import "../css/Pm.css";
import {webConfig} from '../configs/webConfig'
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/PM_Phases_SVG/PM_Phase_1.svg";
import { ReactComponent as Phase2 } from "../SVG/PM_Phases_SVG/PM_Phase_2.svg";
import { ReactComponent as Phase3 } from "../SVG/PM_Phases_SVG/PM_Phase_3.svg";
import { ReactComponent as Phase4 } from "../SVG/PM_Phases_SVG/PM_Phase_4.svg";
import { ReactComponent as Phase5 } from "../SVG/PM_Phases_SVG/PM_Phase_5.svg";

const Passport = () => {
  const [isPhase2Open, setIsPhase2Open] = useState();
  const [isPhase3Open, setIsPhase3Open] = useState();
  const [isPhase5Open, setIsPhase5Open] = useState();

  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("CET");

  const svgRef2 = useRef();
  const [P2visibleSection, setP2visibleSection] = useState("Briefing");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("Initiate");

  const svgRef4 = useRef();
  const [P4visibleSection, setP4visibleSection] = useState("WarehouseStockManagement");

  const svgRef5 = useRef();
  const [P5visibleSection, setP5visibleSection] = useState("Gatekeeper");

  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");

    rect1.addEventListener("click", () => {
      setP1visibleSection("CET");
    });

    rect2.addEventListener("click", () => {
      setP1visibleSection("Pre-Veeva");
    });
  }, []);

  useEffect(() => {
    const svg = svgRef2.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const diam1 = svg.getElementById("diamond1");
    const rect3 = svg.getElementById("rect3");

    rect1.addEventListener("click", () => {
      setP2visibleSection("Briefing");
    });

    rect2.addEventListener("click", () => {
      setP2visibleSection("Artwork");
    });

    diam1.addEventListener("click", () => {
      setP2visibleSection("Expectations");
    });
    const handleScrollP3 = () => {
      const elementToScrollTo = document.getElementById("accordion3");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect3.addEventListener("click", () => {
      handleScrollP3();
      setIsPhase3Open(true);
    });
  }, [isPhase3Open]);


  useEffect(() => {
    const svg = svgRef4.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    

    rect1.addEventListener("click", () => {
      setP4visibleSection("WarehouseStockManagement");
    });

    rect2.addEventListener("click", () => {
      setP4visibleSection("QuaterlyInvenoryReviews");
    });

    rect3.addEventListener("click", () => {
      setP4visibleSection("UpdateOfMaterial");
    });


    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y = elementToScrollTo.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect4.addEventListener("click", () => {
      handleScrollP2();
      setIsPhase2Open(true);
    });

    const handleScrollP5 = () => {
      const elementToScrollTo = document.getElementById("accordion5");
      if (elementToScrollTo) {
        const y = elementToScrollTo.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    rect5.addEventListener("click", () => {
      handleScrollP5();
      setIsPhase5Open(true);
    });
  }, [isPhase2Open,isPhase5Open]);

  useEffect(() => {
    const svg = svgRef3.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    const rect8 = svg.getElementById("rect8");
    const diam1 = svg.getElementById("diamond1");
    const diam2 = svg.getElementById("diamond2");

    rect1.addEventListener("click", () => {
      setP3visibleSection("Initiate");
    });

    diam1.addEventListener("click", () => {
      setP3visibleSection("MlrVerdict");
    });

    rect2.addEventListener("click", () => {
      setP3visibleSection("Production");
    });

    rect3.addEventListener("click", () => {
      setP3visibleSection("InitiateCertification");
    });

    diam2.addEventListener("click", () => {
      setP3visibleSection("CertificationVerdict");
    });

    rect4.addEventListener("click", () => {
      setP3visibleSection("FinalStepsBeforeGoLive");
    });
    rect5.addEventListener("click", () => {
      setP3visibleSection("MaterialGoLive");
    });
    rect6.addEventListener("click", () => {
      setP3visibleSection("WarehouseStockManage");
    });
    rect7.addEventListener("click", () => {
      setP3visibleSection("InitiateFinalApproval");
    });

    
    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y = elementToScrollTo.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect8.addEventListener("click", () => {
      handleScrollP2();
      setIsPhase2Open(true);
    });
    
  }, []);

  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    Physical Material
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">Physical Material</h2>
            <p>
              <em>
              Physical materials are traditional printed materials as well as materials used as PDFs in digital channels. They can also be other physical items, e.g., exhibition booth walls or demo items, etc. Physical materials need special attention regarding inventory as there may be numerous locations where they can be stored.
              </em>
            </p>
          </div>
        </div>
      </div>

      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a className="nav-link active" data-bs-toggle="tab" href="#VVPMJobBagStd">
               VVPM Job Bag Standards
            </a>
          </li>
        </ul>
        <div id="tabContent" className="tab-content">
            <div id="VVPMJobBagStd" class="tab-pane fade in active tabs_box" role="tabpanel">
              <p class="tabsContentHeading">Piece:</p>         
              <ul class="ul_padding">
                <li className='tabContent'> PDF of the content or artwork</li>
                <li className='tabContent'> If promotional, ensure it is the current PI.</li>
                <li className='tabContent'> Signpost where PI and adverse event/product complaint (AE/PC) wording can be found</li>
              </ul>
              
              <p class="tabsContentHeading">Purpose Field:</p>         
              <ul class="ul_padding">
                <li className='tabContent'> Explain the purpose and clarify that the item is printed</li>
                <li className='tabContent'> Specify dimensions of the item</li>
              </ul>
                
              <p class="tabsContentHeading">Attachments:</p>         
              <ul class="ul_padding">
                <li className='tabContent'> Change Document Template (if applicable)</li>
                <li  className='tabContent'> Print the ready artwork (for brochures, stands, booths and pull up banners)</li>
              </ul>
                
              <p class="tabsContentHeading">Linked Documents:</p>         
              <ul class="ul_padding"><li className='tabContent'> Certified version of current PI – <strong className="text-decoration-underline">For UK AND ROI</strong></li>
                <li className='tabContent'> Content references anchored appropriately</li>
                <li className='tabContent'> Include any relevant copyright information</li>
              </ul>
              <p class="tabsContentHeading">Related Permission Managed Content:</p>         
              <ul class="ul_padding">
                <li className='tabContent'> Include required Permission Managed Content (PMC) documents</li>
              </ul>

              <p class="tabsContentHeading">Final Form – UK AND ROI only:</p>         
              <ul class="ul_padding">
                <li className='tabContent'> Printer’s proof of the document (e.g., for brochures, leave pieces, etc.) or hard copy of the printed item for Certifier review.</li>
                <li className='tabContent'> Final version must be archived in a pink folder with the certificate and sent to the Global Librarian.</li>
              </ul>
              <p className='tabContent'>
                 <strong>For invitations </strong> to be printed off by representatives, ensure that there
                 is an associated briefing document providing printing instructions on size of 
                 the invite and PI. PI needs to be an integral part of the invitation, not a loose leaf. 
              </p>
              <p  className='tabContent'> 
                <strong>For stands, booths and pull up banners </strong>
                stands should be certified using a final printer’s proof on A3, 
                which must be of sufficient size to ensure that the content is legible
                (provide zoomed in images of these sections if necessary). 
                The A3 copy must be archived in a pink folder with the certificate 
                and sent to the Global Librarian. After the event has taken place, 
                it is a good practice to add a photograph of the stand in situ.
              </p>  
            </div>
        </div>
      </div>
      
      {/*Add verticle Tab*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">Physical Material Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Planning"
                    defaultOpen={true}
                    
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                        <div className="comman_width_e">
                          <Phase1 ref={svgRef1} className="pmShape1" />
                        </div>
                      </div>
                      <div className="col-lg-7 planning p_font pt-3 flowsSecondPart">
                        <div
                          id="campaign_listed_cet"
                          style={{
                            display:
                              P1visibleSection === "CET" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">CAMPAIGN LISTED ON CET</p>
                          <p className="flowsContent">
                            Campaigns should be listed on CET as part of IPA
                            process. Check if this material is part of your
                            plans, review the set KPIs and tactic links on CET.{" "}
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "Pre-Veeva"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">PRE-VEEVA MEETING</p>
                          <p className="flowsContent">
                            Planning is everything! The core objectives of a
                            pre-Veeva discussion should not be overlooked. These
                            are to ensure that:
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               The reviewer is never presented with a
                              material in Veeva Vault PromoMats (VVPM) that they have no background
                              information on, and{" "}
                            </li>
                            <li className="flowsContent">
                               The review team understand its current
                              priorities for review.
                            </li>
                          </ul>
                          <p className="flowsContent">Together these aim to
                              provide a smoother and quicker approval process
                              for materials.</p>
                          <p className="flowsContent">Visit {" "}
                              <a href={webConfig.LillyPlanning} target="_blank">
                               Planning (lilly.com)
                              </a>{" "}
                              to learn more about pre-Veeva meetings.</p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion2" />
                  <Accordion
                    className="accordion2"
                    title="Phase 2: Material Readiness"
                    defaultOpen={false}
                    isOpen={isPhase2Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning main-content">
                         <div className="comman_width_e">
                            <Phase2 ref={svgRef2} className="pmShape2" />
                          </div>  
                      </div>
                      <div className="col-lg-7 planning pt-4 flowsSecondPart">
                        <div
                          id="briefing"
                          style={{
                            display:
                              P2visibleSection === "Briefing"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">BRIEFING</p>
                          <p
                            className="pop flowsContent"
                            style={{ marginBottom: "0px" }}
                          >
                            Ensure that the team working on material development
                            fully understands the brief. Often a call is best
                            way to move things forward quickly. A good brief to
                            the creative agency means that the Material Administrator
                            provides all necessary visuals, texts and links
                            upfront. Briefing could be completed by the Material
                            Administrator. The creative agency should advise on
                            delivery timelines after the briefing is received.
                          </p>
                          <p className="flowsHeading briefingRemember">
                            Remember
                          </p>
                          <p class="flowsContent">Include a Product Quality Representative (PQR) for materials with Quality impact, i.e., Good Manufacturing Practice (GMP) considerations for Promotional Materials such as demo devices and items of potential impact on patient or healthcare professional safety. The NE Hub preferred creative agency (Indegene) has access to the brand portals and images in VVPM. For other agencies, provide the source files (e.g., high-resolution picture files, logos, fonts, colour codes, etc.). </p>	
                        </div>
                        <div
                          id="artwork_delivered"
                          style={{
                            display:
                              P2visibleSection === "Artwork" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">ARTWORK DELIVERED</p>
                          <p className="flowsContent">
                          It is the responsibility of the Material Administrator to review the artwork provided for accuracy before submitting to Medical, Legal, Regulatory (MLR) for approval. At this stage, it is best practice to align with reviewers before submitting the material to MLR.
                          </p>
                        </div>
                        <div
                          id="meets_expectations"
                          style={{
                            display:
                              P2visibleSection === "Expectations"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            PDF MEETS EXPECTATIONS?
                          </p>
                          <p className="flowsContent">
                            If the PDF meets expectations, then initiate MLR. If the PDF does not meet expectations, then it is always advised to book a call with the agency to go through the changes required. (Back-and-forth through email communication has proven to be time consuming!)
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  <div id="accordion3" />
                  <Accordion
                    className="accordion3"
                    title="Phase 3: Material Approval & Launch"
                    defaultOpen={false}
                    isOpen={isPhase3Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-7 planning main-content">
                        <Phase3 ref={svgRef3} className="pmShape3" />
                      </div>
                      <div className="col-lg-5 planning flowsSecondPart side-content ">
                        <div
                          id="link1"
                          style={{
                            display:
                              P3visibleSection === "Initiate"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p class="flowsHeading">INITIATE MLR APPROVAL</p>
                          <p class="flowsContent">
                          This is the stage where the Veeva Coordinator uploads the material to VVPM for reviewers to provide a verdict on it.</p>
                          <p class="flowsHeading">Remember!</p>
                          <p class="flowsContent">For materials with Quality impact, such as demo devices and items of potential impact on patient or healthcare professional safety, remember to include a PQR in the MLR approval as a reviewer. If unsure about whether Quality needs to be involved, check with the local PQR. </p>
                          <p class="flowsHeading">UK</p>
                          <p className='flowsContent'>A separate approval must be initiated for internal briefing guides to be added as a supporting document. To understand this process, see the process for Internal Documents.</p>
                        </div>
                        <div
                          id="link2"
                          style={{
                            display:
                              P3visibleSection === "MlrVerdict"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p class="flowsHeading">MLR VERDICT</p>
                          <p class="flowsContent">There are three types of verdicts that the material could get: </p>
                          <p class="flowsContent"><strong>1. Approved for Production</strong> – The material has been blessed by the reviewers. Go ahead and ask for the material to be developed into the final format.</p>
                          <p class="flowsContent"><strong>2. Approved with changes</strong> – The material has some minor errors/updates required (like spelling, grammatical errors). Incorporate the advised updates/corrections on the material and ask for the material to be developed into the final format.</p>
                          <p class="flowsContent"><strong>3. Revise &amp; Resubmit</strong> – The material has too many problems and the reviewer advises the Veeva Coordinator to rework on it and submit again. Go ahead and ask the agency to re-do the artwork.</p>
                        </div>
                        <div
                          id="link3"
                          style={{
                            display:
                              P3visibleSection === "Production"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className='flowsHeading'>Production</p>
                          <p class="flowsContent">Click here to gain an understanding of the process for <a href={webConfig.NEHUBProductionofPhysicalMaterials} target='_blank'>NE-HUB-Production-of-Physical-Materials--V2-April-2023.pptx (lilly.com).</a></p>
                        </div>
                        <div
                          id="link4"
                          style={{
                            display:
                              P3visibleSection === "InitiateCertification"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p class="flowsHeading">INITIATE CERTIFICATION</p>
                          <p class="flowsContent">This is the stage where the Veeva Coordinator uploads the final form of the material to VVPM for Certifiers to provide a verdict on it. For printed materials, the material can be provided to the Certifier outside of VVPM. </p>
                        </div>
                        <div
                          id="link5"
                          style={{
                            display:
                              P3visibleSection === "CertificationVerdict"
                                ? "block"
                                : "none",
                          }}
                        >
                           <p class="flowsHeading">CERTIFICATION VERDICT</p>
                            <p class="flowsContent">There are three types of verdicts that the material may get:</p>
                            <p class="flowsContent"><strong>1. Certified</strong> – The material is approved for distribution now. Go ahead to review/set inventory information on VVPM and set the <strong>Actual Date of First Use</strong>. </p>
                            <p class="flowsContent"><strong>2. Revise &amp; Resubmit </strong>  (for certification) – The material has errors and/or requires updates. Please request the Digital Solutions Team (DST) to make the advised changes, trigger a new test email and re-submit for certification.</p>
                            <p class="flowsContent"><strong>3. Not Certified</strong> – The material has too many problems and the Certifier advises the Material Administrator to rework on it and start a new MLR workflow.</p>
                            <p class="flowsHeading">Remember</p>
                            <p className='flowsContent'>The verdict <b>‘Certified’</b> does not mean that the material is now live. Additional steps are needed to move it to the environment where it will be available to the end user. </p>
                        </div>
                        <div
                          id="link6"
                          style={{
                            display:
                              P3visibleSection === "FinalStepsBeforeGoLive"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p class="flowsHeading">FINAL STEPS BEFORE 'GO-LIVE'</p>
                          <ul class="ul_padding ul_margin_bottom">
                            <li class="flowsContent"> <strong>Actual Date of First Use</strong> is set by the Veeva Coordinator and is typically put as the date the material has been certified in VVPM, unless there is a specific date of when the usage of a material will start (e.g., launch date, after the salesforce training, etc.).</li>
                            <li class="flowsContent"><strong> Inventory Information</strong> – Veeva Coordinators would have added this earlier to VVPM metadata and it should be checked when the material goes live. This step will support efficient material management and withdrawal.</li>
                            <li class="flowsContent"> <strong>Expiration Date</strong> is usually set for 2 years. However, if the material is classified in Veeva as ‘One-time use’ or for a defined time, set the expiration date accordingly.</li>
                            <li class="flowsContent"> <strong>Related Pieces</strong> Veeva Coordinator should check that any relevant related pieces are linked to the VVPM job bag.</li>
                          </ul>
                        </div>
                        <div
                          id="link7"
                          style={{
                            display:
                              P3visibleSection === "MaterialGoLive"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p class="flowsHeading">Material ‘Go-Live’ Phase</p>
                          <p className='flowsContent'>Now the material is Approved for Distribution, and it can be distributed.</p>
                          <p class="flowsHeading">Inform the Customer-Facing Team</p>
                          <p className='flowsContent'>
                          It is best practice to inform the Customer-Facing team when the material has become available. If the material is to be used as a digital PDF, the Material Administrator should add it onto the agreed storage or distribution point of approved materials for salesforce use. 
                          </p>
                        </div>
                        <div
                          id="link8"
                          style={{
                            display:
                              P3visibleSection === "WarehouseStockManage"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p class="flowsHeading">Warehouse Stock Management</p>
                          <p class="flowsContent">Physical printed materials are shipped straight from the print house and mainly stored at a local warehouse. Each local warehouse has its’ own process for how to get the materials added to their ordering systems. More information on local warehouses and how they operate can be gained from NE Hub Marketing Services. View <a href={webConfig.NEHubWarehouseContacts} target='_blank'>NE Hub Warehouse Contacts.</a> </p>
                        </div>
                        <div
                          id="link9"
                          style={{
                            display:
                              P3visibleSection === "InitiateFinalApproval"
                                ? "block"
                                : "none",
                          }}
                        >
                           <p class="flowsHeading">WAREHOUSE STOCK MANAGEMENT</p>
                            <p class="flowsContent">
                            Hardcopy archive: For the UK, a copy needs to be sent for physical storage. The Material Administrator must initiate physical storage through the VVPM task prompts. Once submitted, the hard copy should be archived in a pink folder with the Global Librarian in the Basingstoke office. Further information on this process can be found below: 
                            </p>
                            <ul class="ul_padding ul_margin_bottom"><li class="flowsContent"> <a href={webConfig.PMAPPhysicalStorageProcess} target='_blank'>PMAP - Physical-storage-process.pdf - All Documents (lilly.com)</a></li>
                            <li class="flowsContent"> <a href={webConfig.UKRoIHardcopyArchiveProcess} target='_blank'>UK RoI Hardcopy Archive Process.docx (lilly.com)</a></li></ul>
                        </div>
                        
                      </div>
                    </div>
                  </Accordion>

                  {/*Accordion4*/}
                  <div id="accordion4" />
                  <Accordion
                    className="accordion4"
                    title="Phase 4: Material Management"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning">
                        <Phase4 ref={svgRef4} className="pmShape4" />
                      </div>
                      <div className="col-lg-6 planning p-3 flowsSecondPart">
                        <div
                          style={{
                            display:
                              P4visibleSection === "WarehouseStockManagement"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p class="flowsHeading">Warehouse Stock Management</p>
                          <p className='flowsContent'>It is the Material Originator’s responsibility to:</p>
                          <ul class="ul_padding ul_margin_bottom">
                            <li class="flowsContent"> Monitor and maintain warehouse stock levels of physical materials so they are in line with current marketing strategy for salesforce, medical information and the broader brand team. Monthly stock level monitoring is recommended for key brand team materials in the NE Hub at least</li>
                            <li class="flowsContent"> Ensure only materials that are <b>Approved for Distribution</b> are available for distribution within the warehouse.</li>
                          </ul>
                        </div>
                        
                        <div
                          style={{
                            display:
                              P4visibleSection === "QuaterlyInvenoryReviews"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p class="flowsHeading">Quarterly Inventory Reviews by Brand Team</p>
                          <ul class="ul_padding ul_margin_bottom">
                            <li class="flowsContent"> For operational excellence in material management, the NE Hub has a quarterly Operational Review of Inventory. The Brand Leader/Business Unit Director should lead this exercise with their team to <b>review the list of materials in VVPM</b> and decide whether to update the material, leave it as it is for now or withdraw it from VVPM. Understand more in the Operational <a href={webConfig.OperationalQuarterlyReviewGuidance} target='_blank'>Quarterly Review Guidance.</a></li>
                            <li class="flowsContent"> Always <b>check Inventory Information</b> to see if any metadata needs to be updated.</li>
                            <li class="flowsContent"> <b>Review briefing guides</b> for any update required and storage locations.</li>
                          </ul>
                        </div>
                         <div
                          style={{
                            display:
                              P4visibleSection === "UpdateOfMaterial"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p class="flowsHeading">Update of Material</p>
                          <p class="flowsContent">An outcome of the quarterly material review (or other reasons such as a Prescribing Information [PI] update) might result in a need to update the material. Updates in the material initiate the process right from the beginning, i.e., ‘briefing’ and the withdrawal process of the existing version.</p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion5" />
                  <Accordion
                    className="accordion5"
                    title="Phase 5: Material Withdrawal"
                    defaultOpen={false}
                    isOpen={isPhase5Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning p-3">
                        <div className="comman_width_e">
                          <Phase5 ref={svgRef5} className="pmShape5" />
                        </div>  
                      </div>
                      <div class="col-lg-6 widthdrawal p-3 flowsSecondPart">             
                        <div class="tab">
                          <p className='flowsContent'>
                            Material Withdrawal phase starts with the brand team´s decision to discontinue a material and it ends with completed and documented destruction of the material.<br/><br/>  
                            The materials need to be removed from all storage locations and circulation before initiating the withdrawal in the Withdrawal Tool (wherever applicable).
                            The <b>Inventory Information</b> field in VVPM will help the Material Administrator to capture all storage locations for effective destruction.
                          </p>
                          <p className='flowsContent'>Brand team’s <b>withdrawal</b> actions consist of a detailed process. <br/>Please see <a href={webConfig.MaRSNEHUBSite} target='_blank'>MaRS NE HUB Site - Home (lilly.com).</a></p>
                          <br></br>
                          <p class="flowsHeading">WITHDRAWAL AND RETIREMENT</p>
                          <ul class="ul_padding ul_margin_bottom">
                          <li class="flowsContent"> Material Administrator removes material from inventory list to restrict order.</li>
                          <li class="flowsContent"> Withdraw associated briefing guide from Customer-Facing teams.</li>
                          <li class="flowsContent"> Material Administrator initiates withdrawal notification to the Customer-Facing team.</li>
                          <li class="flowsContent"> Customer-Facing team acknowledges withdrawal and destruction by the set date.</li>
                          <li class="flowsContent"> Veeva Coordinator retires the material in VVPM.</li>
                          </ul>
                        </div>
                    </div>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            
            {/*End Lifecycle */}
            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Role </th>
                        <th>Responsibility</th>
                        </tr>
                    </thead>  
                    <tbody>
                        <tr>
                        <td class="tb"><h6>Veeva Owner</h6></td>
                        <td className='tb'><p>
                      
                        The Veeva Owner is also known as the Uploader (please see 'Veeva uploader')</p>	
                        </td>
                        </tr>
                        <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                        </tr>
                          
                        <tr>
                        <td class="tb">
                          
                          <h6>Material Originator</h6></td>
                        <td><ul class="ul_list">Responsible for:
                        <li>1. Having overall responsibility and accountability for the material</li>
                        <li>2. Ensure that no item is used without the necessary approvals</li>
                        <li>3. Ensuring timely change and/or withdrawal, where needed</li>		
                        </ul>
                        <p>For further information, please see <a href={webConfig.PMAPJobAid} target="_blank">PMAP Job Aid.</a></p>	
                          
                        </td>
                        </tr>
                      
                        <tr>
                        <td class="tb"><h6>Veeva Uploader</h6></td>
                        <td><p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA teams, and is assigned the Veeva Owner role.</p>
                        <ul class="ul_list">
                        <li>Responsible for:</li>	
                        <li>1. Uploading the material to Veeva</li>
                        <li>2. Performing all activities related to upload</li>
                        </ul>
                        <p>For further information, please see <a href={webConfig.PMAPJobAid} target="_blank">PMAP Job Aid.</a></p>	
                        </td>
                        </tr>	
                          
                        <tr>
                        <td class="tb"><h6>Veeva Co-ordinator</h6></td>
                        <td><p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager (or similar role).  </p>
                        <ul class="ul_list">
                        <li>Responsible for:</li>	
                        <li>1. Reviewing the item before sending for MLR review</li>
                        <li>2. Managing the document workflow</li>
                        <li>3. Assigning reviewers</li>
                        <li>4. Managing expiry or periodic review</li>	
                        </ul>
                        <p>For further information, please see <a href={webConfig.PMAPJobAid} target="_blank">PMAP Job Aid.</a></p>	
                        </td>
                        </tr>
                          
                        <tr>
                        <td class="tb"><h6>Business Reviewer</h6></td>
                        <td><p>Business Reviewer is an experienced commercial employee.</p>
                        <ul class="ul_list">
                        <li>Responsible for:</li>	
                        <li>1. Ensuring all promotional materials align with the brand strategy, Lilly Brand Book, Product Brand Book</li>
                        <li>2. Ensuring all materials are of good quality, have correct Veeva metadata and adhere to internal policies and local codes</li>
                        </ul>
                        <p>For further information, please see <a href={webConfig.PMAPJobAid} target="_blank">PMAP Job Aid.</a></p>	
                        </td>
                        </tr>
                          
                        <tr>
                        <td class="tb"><h6>UK Medical Certifier</h6></td>
                        <td><p>Registered medical practitioner or UK-registered Pharmacist.</p>
                        <ul class="ul_list">
                        <li>Responsible for:</li>	
                        <li>1. Ensuring that the material is in accordance with the requirements of the local code</li>
                        <li>2. Ensuring that the material is not inconsistent with the marketing authorisation/SmPC (if applicable)</li>
                        <li>3. Ensuring that the material is a fair and truthful representation of the facts about the medicine (if applicable)</li>
                        <li>4. Approving the material in its final form</li>
                        <li>5. Ensuring the material has the appropriate reviewers and that the Veeva metadata is correct.</li>	
                        </ul>
                        <p>For further information, please see <a href={webConfig.PMAPJobAid} target="_blank">PMAP Job Aid.</a></p>	
                        </td>
                        </tr>
                          
                        <tr>
                        <td class="tb"><h6>UK Technical Medical Reviewer</h6></td>
                        <td><p>Person who is responsible for ensuring that the material contains accurate information in line with the references that have been used and for ensuring that the Veeva metadata is correct and that the PMC documents are included (if applicable).</p>
                      
                        <p>For further information, please see <a href={webConfig.PMAPJobAid} target="_blank">PMAP Job Aid.</a></p>	
                        </td>
                        </tr>	
                          
                        <tr>
                        <td class="tb"><h6>Nordic Code Approver</h6></td>
                        <td><p>Code Approver must be a medical practitioner or a pharmacist (except for in Sweden) and is responsible for ensuring that the material is in line with the local Codes and that the material being approved has the appropriate reviewers; and ensuring that the Veeva metadata is correct.</p>
                      
                        <p>For further information, please see <a href={webConfig.PMAPJobAid} target="_blank">PMAP Job Aid.</a></p>	
                        </td>
                        </tr>
                          
                        <tr>
                        <td class="tb" ><h6>Nordics Technical Medical Reviewer</h6></td>
                        <td><p>Person who is responsible for ensuring that the material contains accurate information in line with the references that have been used and for reviewing the material from a medical perspective.</p>
                      
                        <p>For further information, please see <a href={webConfig.PMAPJobAid} target="_blank">PMAP Job Aid.</a></p>	
                        </td>
                        </tr>		
                        
                          
                        <tr>
                        <td class="tb"><h6>Indegene</h6></td>
                        <td><p>Responsible for:</p>
                        <ul class="ul_list">
                      
                        <li>1. Creating artwork as per material originator brief</li>
                        <li>2. Creating formatted PI PDFs</li>
                        </ul>
                        </td>
                        </tr>

                        <tr>
                        <td class="tb"><h6>Material Originator</h6></td>
                        <td><p>Responsible for:</p>
                        <ul class="ul_list">
                        <li>1. Content conceptualisation and brief for DST</li>
                        <li>2. Managing the approval process including provision of appropriate subject lines</li>
                        <li>3. Requesting audience list</li>	
                        </ul>
                        <p>Material originators may be the Veeva Uploader and the Veeva Co-ordinator or solely the Veeva Co-ordinator</p>
                        </td>
                        </tr>	


                        <tr>
                        <td class="tb"><h6>Global Librarian</h6></td>
                        <td><p>Responsible for:</p>
                        <ul class="ul_list">
                        <li>1. Archiving physical material as per request by material originator</li>
                        
                        </ul>
                        </td>
                        </tr>	
                            
                        <tr>
                        <td class="tb"><h6>Marketing Services</h6></td>
                        <td><p>Responsible for:</p>
                        <ul class="ul_list">
                        <li>1. Business relationship owner for the printing agency (Indicia), creation agency (Indegene) & warehouse supplier across Hub (STACI, UK; Velox, FI, SE & DK & Logistikkhuset, NO)</li>
                        <li>2. Primary point of contact for marketing teams in re to feedback to above mentioned suppliers</li>
                        <li>3. Delivering trainings for (new) employees relevant third tools & related material management processes</li>	
                        <li>4. Oversight & support role through material management process incl. material withdrawal process</li>	
                        </ul>
                        <p>May also be material originator and gatekeeper.</p>
                        </td>
                        </tr>
                              
                        <tr>
                        <td class="tb"><h6>KonicaMinolta</h6></td>
                        <td><p>Responsible for:</p>
                        <ul class="ul_list">
                        <li>1. Providing printing proofs prior to print</li>
                        <li>2. Printing as per print request and delivering to defined location</li>
                        </ul>
                        </td>
                        </tr>	
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>What it stands for</strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                <tr>
                <td class="tb">BI</td>
                <td class="tb">Boehringer Ingelheim</td>
                <td>Boehringer Ingelheim and Eli Lilly formed an alliance in January 2011 in the field of diabetes. This is also refered to as the Alliance. </td>
                </tr>
                

                <tr>
                <td class="tb">CET</td>
                <td class="tb">Customer Engagement Tool</td>
                <td>Planning and execution tools combine to form the CET. With CET, customer plans can be created and campaigns can be managed in one place.</td>
                </tr>

                <tr>
                <td class="tb">Conversion metric</td>
                <td class="tb"></td>
                <td>Metrics used to measure the number of valuable actions customers take through a digital marketing channel.</td>
                </tr>
                
                <tr>
                <td class="tb">Customer Journey</td>
                <td class="tb"></td>
                <td>The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                </tr>

                <tr>
                <td class="tb">IBU</td>
                <td class="tb">International Business Unit</td>
                <td>The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada). </td>
                </tr>

                <tr>
                  <td className="tb">IPA</td>
                  <td className="tb">Intergrated Planning Approach</td>
                  <td className="tb">
                    The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                  </td>
                </tr>

                <tr>
                <td class="tb">KPI</td>
                <td class="tb">Key Performance Indicator</td>
                <td>Goals or Metrics set behind a campaign and or tactic.</td>
                </tr>

                <tr>
                    <td className="tb">Leave piece </td>
                    <td className="tb"></td>
                    <td className="tb">A material which can be left at the HCP's desk.</td>
                </tr>

                <tr>
                <td class="tb">PMC</td>
                <td class="tb">Permissions Managed Content</td>
                <td>An image which is owned by an entity other than Lilly and which we are given permission to use under a specifc set of circumstances as defined in a contractural agreement between Lilly and the content owner.</td>
                </tr>


                <tr>
                <td class="tb">Tactic</td>
                <td class="tb"></td>
                <td>Tactics are specific to selected channels to drive customers to reach a piece of content. Tactics are associated with estimated KPI metric (goal) and results reports (actuals). </td>
                </tr>
                
                
                <tr>
                <td class="tb">VVPM</td>
                <td class="tb">Veeva Vault PromoMat</td>
                <td>Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems.  PromoMats is a Vault application to manage the process of creating, reviewing, and approving promotional materials, claims, and supporting information.</td>
                </tr>

                  
                </tbody>
              </table>
            </div>
            {/*End Defination*/}

            {/*View Core Doc*/}
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.PMCoreDoc}
                  target="_blank"
                >
                  Physical Material core document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/*End Core Doc*/}
          </div>
        </div>
      </div>

      <div id="popup_flight_travlDil11" class="overlay_flight_traveldil">
        <div class="popup_flight_travlDil" >
          <p class="flowsHeading">UK</p>
          <p class="flowsContent">Consider if the original briefing guide for Sales Representatives covers the update made. Material Owners should either re-send the original briefing guide or arrange for the briefing guide to be re-worked, re-approved and re-deployed with the latest version of the material. </p>
          <a class="close_flight_travelDl" href="#/">&times;</a>
          
        </div>
      </div>

      <div id="popup_flight_travlDil13" class="overlay_flight_traveldil">
        <div class="popup_flight_travlDil" >
          <p class="flowsHeading">UK</p>
          <p class="flowsContent">
          The creative agency provides print files to the Material Administrator who will send these to the print house using the briefing template <a href={webConfig.PrintingBriefChecklist} target='_blank'>Printing Brief Checklist.</a> The preferred print house is Indicia (Konica Minolta). Indicia prints copies (usually five) that the Material Administrator sends to the Certifier. Contact details for Indicia can be found within the Printing Brief Checklist linked above. 
          </p>
          <p class="flowsContent">
          If the material is to be printed from an office printer, the Material Administrator sends a copy from that printer to the Certifier. If the material is to be used as a digital PDF only, it is ready for certification as it is. 
          </p>
          <p class="flowsContent">
          Exhibition stands and pull-up banners should be certified using a final printer’s proof on A3, which must be of sufficient size to ensure that the content is legible (provide zoomed-in images of the sections if needed). The A3 copy must be archived in a pink folder with the certificate and sent to the Global Librarian. 
            </p>

            <p class="flowsContent">
            The final signatory physically checks demonstration pens before they are released to the Sales Representatives. Usually, they will inspect a few from the main shipment, rather than requiring some to be sent in advance. 

            </p>

          <a class="close_flight_travelDl" href="#/">&times;</a>
          
        </div>
      </div>

      <div id="popup_flight_travlDil14" class="overlay_flight_traveldil">
        <div class="popup_flight_travlDil" >
          <p class="flowsHeading">UK</p>
          <p class="flowsContent">Do not forget, a briefing guide (for Customer-Facing teams) needs to be certified as well. </p>
          <a class="close_flight_travelDl" href="#/">&times;</a>
          
        </div>
      </div>

      <div id="popup_flight_travlDil15" class="overlay_flight_traveldil">
        <div class="popup_flight_travlDil" >
          <p class="flowsHeading">NORDICS</p>
          <p class="flowsContent">If the material gets the verdict <b>‘Not Certified,’</b> use <b>‘Create Draft’</b> function in VVPM to use the same job bag code for the updated version of the material. </p>
          <a class="close_flight_travelDl" href="#/">&times;</a>
          
        </div>
      </div>

      <div id="popup_flight_travlDil16" class="overlay_flight_traveldil">
        <div class="popup_flight_travlDil" >
          <p class="flowsHeading">NORDICS</p>
          <p class="flowsContent">Please check the <a href={webConfig.NordicPMAPGuidance} target='_blank'>Nordic PMAP Guidance</a> for detailed information on statutory copy submission.</p>
          <a class="close_flight_travelDl" href="#/">&times;</a>
          
        </div>
      </div>


      <div id="popup_flight_travlDil17" class="overlay_flight_traveldil">
        <div class="popup_flight_travlDil" >
          <p class="flowsHeading">UK AND ROI</p>
          <p class="flowsContent">Briefing guides are to be uploaded on the agreed storage or distribution point that the brand team has created for Sales Representatives. Share the briefing guide at the same time as communicating the existence of the material.</p>
          <a class="close_flight_travelDl" href="#/">&times;</a>
          
        </div>
      </div>

      <div id="popup_flight_travlDil20" class="overlay_flight_traveldil">
        <div class="popup_flight_travlDil" >
          <p class="flowsHeading">NORDICS</p>
          <p class="flowsContent">The creative agency provides print files to the Material Administrator who will send these by email to the print house using the briefing template <a href={webConfig.PrintingBriefChecklist} target='_blank'>Printing Brief Checklist.</a> The preferred print house is Indicia (Konica Minolta). Indicia prints copies that the Material Administrator sends to the Certifier. Contact details for Indicia can be found within the Printing Brief Checklist.</p>
          <p class="flowsContent">Alternatively, the final material printed at the office with an office printer is acceptable.</p>
          <p class="flowsContent">If the material is to be used as a digital PDF only, it is ready for certification as it is.</p>
          <p class="flowsContent">Alternatively, for other items (e.g., exhibition stands, roll ups, demo devices, and items of potential impact on patient or healthcare professional safety), the Material Administrator should agree with the Certifier on the format in which the item will be certified. </p>
          <a class="close_flight_travelDl" href="#/">&times;</a>
          
        </div>
      </div>

    </div>
  );
};


export default Passport;
