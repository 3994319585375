import React from "react";
import { useState, useRef, useEffect } from "react";
import {webConfig} from '../configs/webConfig'
import { Link } from 'react-router-dom';
import LendingCard from "../components/LendingCard.js";
import "../css/StandBoothDetailing.css";
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/Meetings_Phases_SVG/Stand_Booth_Detaling_Phase_1.svg";
import { ReactComponent as Phase3 } from "../SVG/Meetings_Phases_SVG/Stand_Booth_Detaling_Phase_3.svg";
import { ReactComponent as Phase4 } from "../SVG/Meetings_Phases_SVG/Stand_Booth_Detaling_Phase_4.svg";



const StandBoothDetailing = () => {
  const [isPhase3Open, setIsPhase3Open] = useState();

  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("CETMeeting");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("CrossFuncMeeting");

  const svgRef4 = useRef();
  const [P4visibleSection, setP4visibleSection] = useState("Veera");

  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    
  
    rect1.addEventListener("click", () => {
      setP1visibleSection("CETMeeting");
    });
    rect2.addEventListener("click", () => {
        setP1visibleSection("Sponsorship");
    });
    rect3.addEventListener("click", () => {
        setP1visibleSection("SeekApproval");
    });
    rect4.addEventListener("click", () => {
        setP1visibleSection("RegisterMeetingInMercury");
    });
    rect5.addEventListener("click", () => {
        setP1visibleSection("Initialte");
    });
    rect6.addEventListener("click", () => {
        setP1visibleSection("VVPMApproval");
    });

  }, []);

  useEffect(() => {
    const svg = svgRef3.current;
    const rect1 = svg.getElementById("rect1");
    rect1.addEventListener("click", () => {
      setP3visibleSection("CrossFuncMeeting");
    });
  }, []);

  useEffect(() => {
    const svg = svgRef4.current;
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    rect2.addEventListener("click", () => {
      setP4visibleSection("Veera");
    });
    rect3.addEventListener("click", () => {
        setP4visibleSection("Dashboards");
    });
  }, []);


  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="/meetings">Meetings</Link>  
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                     Independent Meeting Stand/Booth  
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd"> Independent Meeting Stand/Booth  </h2>
            <p>
              <em>
                Independent Meetings are non-Lilly owned events where Meetings, events or initiatives are sponsored through a stand, delegate sponsorship or a promotional symposium.  
              </em>
            </p>
          </div>
        </div>
      </div>

      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link navLinkTab active"
              data-bs-toggle="tab"
              href="#processPreRequisites"
            >
              Process
              <br />
              Pre-requisites
            </a>
          </li>
        </ul>
        
        <div id="tabContent" className="tab-content">
            <div id="processPreRequisites" className="tab-pane fade in active tabs_box tabContent">
                <div>
                  <ul className="ul_padding">
                    <li className='tabContent'>Process subject to approval within the brand team. </li>
                    <li className='tabContent'>Process subject to approval of activity in TL-MAP. </li>
                    <li className='tabContent'>
                        Process subject to completion of 
                        <ul>
                            <li className='tabContent'>
                               <Link
                                    to={webConfig.SponsoringIndependentMeetingsEventsorInitiativesforNonHCPs}
                                    target="_blank"
                                >  Sponsoring Independent Meetings, Events, or Initiatives for Non-HCPs (lilly.com) </Link> 
                            </li>
                            <li className='tabContent'>
                               <Link
                                    to={webConfig.SponsoringIndependentMeetingsEventsorInitiativesforHCPs}
                                    target="_blank"
                                > Sponsoring Independent Meetings, Events, or Initiatives for HCPs (lilly.com) </Link> 
                            </li>
                            <li className='tabContent'>
                                <Link
                                    to={webConfig.PlanningandExecutingExhibitsatCongresses}
                                    target="_blank"
                                >Planning and Executing Exhibits at Congresses (lilly.com) </Link> 
                            </li>
                        </ul>
                    </li>
                    <li className='tabContent'>
                        Process subject to Mercury training and access – contact Lilly Meetings & Congresses team (LMC) at <strong>LMC_HO@lilly.com</strong> . 
                    </li>
                  </ul>
                </div>
            </div>
        </div>
      </div>
      
      {/*Add verticle Tab*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
            <button
              className="nav-link"
              id="v-pills-faqs-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-faqs"
              type="button"
              role="tab"
              aria-controls="v-pills-faqs"
              aria-selected="false"
            >
              FAQs
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">Independent Meeting Stand/Booth Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Pre-Meeting Planning"
                    defaultOpen={true}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning main-content">
                        <div className="comman_width_e">
                         <Phase1 ref={svgRef1} className="standBoothShape1" />
                        </div> 
                      </div>
                      <div className="col-lg-7 planning p_font pt-3 flowsSecondPart side-content">
                        <div
                          style={{
                            display:
                              P1visibleSection === "CETMeeting" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">Meeting Details on CET  </p>
                          <p className="flowsContent">
                             Ideally, the Meeting should already be on the Customer Engagement Tool (CET) as part of the Annual Operational Planning process for the year. Review Meeting details, adjust KPIs (if needed) and link it with Mercury. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "Sponsorship"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Complete Sponsorship Paperwork  </p>
                          <p className="flowsContent">When sponsoring an Independent Meeting, the Meeting Owner must confirm if the following five criteria are satisfied:  </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Similar Opportunity – other pharmaceutical companies could sponsor the Meeting. 
                            </li>
                            <li className="flowsContent">
                               Transparent Costs – a budget is provided by the Independent Meeting Owners that demonstrate what Lilly sponsorship will contribute towards. 
                            </li>
                            <li className="flowsContent">
                               Public Disclosure – sponsorship by Lilly is declared in Meeting materials and at the Meeting. 
                            </li>
                            <li className="flowsContent">
                                Payments made to a Healthcare Organisation (HCO) or a Third Party – payments should not be made to individuals. 
                            </li>
                            <li className="flowsContent">
                               Documentation – correct documentation should be provided throughout the process. This is further explained below. 
                            </li>
                          </ul>
                          <p className="flowsHeading">Remember!</p>
                          <p className="flowsContent">
                          The ideal time to complete this step is as soon as the conference organiser publishes the event details. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "SeekApproval" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">Seek Approval to Attend   </p>
                          <p className="flowsContent">
                            Once the criteria are met, the Meeting Owner should complete the Lilly {" "}
                            <Link 
                              to={webConfig.IndependentMeetingEventInitiativeApprovalForm}
                              target="_blank" 
                            > Independent Meeting, Event, Initiative Approval Form 
                            </Link> and submit for Manager approval via the {" "}
                            <Link 
                              to={webConfig.powerapp}
                              target="_blank" 
                            > power app 
                            </Link> {' '} , uploading documentation including: 
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               The Independent meeting, event, initiative approval form
                            </li>
                            <li className="flowsContent">
                               Meeting budget 
                            </li>
                            <li className="flowsContent">
                               Meeting agenda 
                            </li>
                            <li className="flowsContent">
                               Sponsorship request letter 
                            </li>
                          </ul>
                          <p className="flowsContent">
                            When the Line Manager has approved the sponsorship, it will be processed by LMC. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "RegisterMeetingInMercury" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">Register in Mercury    </p>
                            <p className="flowsContent">
                                All Meetings should be registered in Mercury by the Meeting Owner as soon as the date of the event is known. Registration requires the following details to be known:  
                            </p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                Start Date and Duration 
                                </li>
                                <li className="flowsContent">
                                Meeting Location 
                                </li>
                                <li className="flowsContent">
                                Meeting Owner 
                                </li>
                                <li className="flowsContent">
                                Estimated Budget Amount 
                                </li>
                                <li className="flowsContent">
                                TL MAP ID 
                                </li>
                                <li className="flowsContent">
                                Number of Estimated Participants  
                                </li>
                                <li className="flowsContent">
                                Meeting Space Requirements 
                                </li>
                                <li className="flowsContent">
                                Meeting Type
                                </li>
                                <li className="flowsContent">
                                Cost Centre 
                                </li>
                                <li className="flowsContent">
                                Proposed Agenda 
                                </li>
                            </ul>
                            <p className="flowsHeading">Remember!</p>
                            <p className="flowsContent">
                                Lead times for registration vary depending on estimated budget and Meeting size ranging from 3 months to 9 months lead time, so planning early is essential. 
                            </p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                For more information on registration and lead times, review the {' '}
                                <Link 
                                to={webConfig.MeetingsLogisticsJobAid}
                                target="_blank" 
                                > Meetings Logistics Job Aid  
                                </Link>{' '}.
                                    
                                </li>
                                <li>
                                  For information on Mercury and step-by-step guides on how to register the Meeting, visit the {' '}
                                    <Link 
                                    to={webConfig.LMCMercurySupportSite}
                                    target="_blank" 
                                    > LMC Mercury Support Site
                                    </Link>{' '}.  
                                </li>

                            </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "Initialte" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">Initiate Stand Design      </p>
                            <p className="flowsContent">
                            As part of the sponsorship package, the Meeting Owner should receive the size specification for Lilly stand at the Congress event.  
                            </p>
                            <ul className="ul_padding ul_margin_bottom">
                                <li className="flowsContent">
                                  Provide stand specification to the vendor.  
                                </li>
                                <li>
                                   Work on the design and construct of the stand. Indegene can help in creating artwork for stand panels.   
                                </li>
                            </ul>
                            <p className="flowsHeading">Remember!</p>
                            <p className="flowsContent">
                               Only the Approved vendor should be used for stand design and build. 
                            </p>
                           
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "VVPMApproval" ? "block" : "none",
                          }}
                        >
                            <p className="flowsHeading">VVPM Approval of Stand Design     </p>
                            <p className="flowsContent">
                            The artwork design (with content details on panels, detailing booth, etc.) and build must be approved through the VVPM process.   
                            </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion2" />
                  <Accordion
                    className="accordion2"
                    title="Phase 2: Pre-Meeting Activities – Material Readiness"
                    defaultOpen={false}
                  >
                    <div className= "accordion-heading mb-4">
                      <p>
                          Some tactics require attention prior to the Meeting 
                          to ensure that the Meeting can go ahead.
                      </p> 
                      <p>
                            For Independent Meetings, tactics will depend upon the chosen sponsorship package and agreement with the Independent Party but could include: 
                      </p> 
                      <ul className="list-inline">
                          <li className="list-inline-item">&bull; Slide Deck</li>
                          <li className="list-inline-item">&bull; Stands/Exhibits and Physical Materials</li>
                          <li className="list-inline-item">&bull; Meeting Promotion</li>
                      </ul>
                    </div>
                    <div className="row  justify-content-center" style={{ margin: "0px" }}>
                        <LendingCard
                            {...{
                            title: "Stands/Exhibits and Physical <br/> Materials ",
                            htmlString: `
                                <div className="card-content">
                                    <p>There may be a variety of Physical Materials during the Meeting. They may either be on display or used as handouts. Click on the tab below to understand the end-to-end process flow for Physical Material. </p>
                                </div>
                            `,
                            links: [
                                { label: "Physical Material", url: "/pm" },
                            ],
                            flexColSize : "col-lg-4"
                            }}
                        />

                        <LendingCard
                            {...{
                            title: "Meeting Promotion",
                            htmlString: `
                               <div className="card-content">
                                <p>In many cases, Lilly does not promote attendance to Independent Meetings. In some scenarios, Lilly might drive attendance to our own sponsored symposium through: </p>
                                <ul className="ul_padding mb-1">
                                  <li><strong>Delegate Email/Source of Authority (SOA) Email –</strong></li>
                                </ul>
                                <p>In some instances, as part of the sponsorship package to the Conference/Congress it might include promotion through delegate email. This email could be used to promote attendance to the sponsored symposium. This would be classed as an SOA email. Click on the SOA Email tab to learn more about the process.</p>
                               </div> 
                            `,
                            links: [
                                {
                                label: "Delegate Email / SOA Email",
                                url: "/soa/soa-emails",
                                },
                            ],
                            flexColSize : "col-lg-4"
                            }}
                        />

                    </div>
                  </Accordion>

                  <div id="accordion3" />
                  <Accordion
                    className="accordion3"
                    title="Phase 3: Material Approval and Meeting Readiness "
                    defaultOpen={false}
                    isOpen={isPhase3Open}
                  >
                    <div className= "accordion-heading">
                      <p>
                      Depending upon the Pre-Event activities, the material approval process will differ and depend on the materials selected. Different materials will have distinct approval processes. Once all promotional and other 'Pre-Event Activity' material are certified and distributed, consider the following for smooth execution of the Meeting. 
                      </p> 
                    </div>
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                          <div className="comman_width_e">
                            <Phase3 ref={svgRef3} className="standBoothShape3" />
                          </div>  
                      </div>
                      <div className="col-lg-7 planning flowsSecondPart">
                        <div
                          id="link1"
                          style={{
                            display:
                              P3visibleSection === "FinalBriefing"
                                ? "block"
                                : "none",
                          }}
                        >
                            <p className="flowsHeading">Final Briefing with Speaker    </p>
                            <ul className="ul_padding">
                                <li className="flowsContent">
                                   Ensure that the Speaker is briefed on compliance. This is done by a Medical team member.  
                                </li>
                                <li>
                                   The Speaker needs to complete a form confirming they have completed this training. The form is uploaded on Mercury.  
                                </li>
                                <li>
                                   National Therapeutic Advisor (NTA) or Brand Manager (BM) may do a strategic Meeting or a slide walk-through before the live event.   
                                </li>
                            </ul>
                        </div>
                        <div
                          id="link1"
                          style={{
                            display:
                              P3visibleSection === "CrossFuncMeeting"
                                ? "block"
                                : "none",
                          }}
                        >
                            <p className="flowsHeading">Cross-Functional Meeting    </p>
                            <ul className="ul_padding flowsContent">
                                <li>
                                   Set clear goals with the team leading the Meeting. 
                                </li>
                                <li>
                                   Clarify strategy and objectives.  
                                </li>
                                <li>
                                   Establish clear roles and responsibilities. 
                                </li>
                                <li>
                                   Define what good looks like and Post-Event Activity with timelines.   
                                </li>
                            </ul>
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  {/*Accordion4*/}
                  <div id="accordion4" />
                  <Accordion
                    className="accordion4"
                    title="Phase 4: Post Event Operations"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                         <div className="comman_width_e">
                           <Phase4 ref={svgRef4} className="standBoothShape4" />
                          </div> 
                      </div>
                      <div className="col-lg-7 planning p-3 flowsSecondPart">
                        <div
                          style={{
                            display:
                              P4visibleSection === "Veera"
                                ? "block"
                                : "none",
                          }}
                        >
                            <p className="flowsHeading">
                               Delegate Details Added on Veeva CRM 
                            </p>
                            <p className="flowsContent">
                            Lilly Representative on the stand would delegate a detail and capture the interaction detail directly on Veeva Customer Relationship Management (CRM) under the Mercury Meeting ID.  
                            </p>
                        </div>
                         <div
                          style={{
                            display:
                              P4visibleSection === "Dashboards"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Data Flows to the Dashboards   </p>
                          <p className="flowsContent">
                          Veeva CRM is connected to Mercury (through Mercury ID) and to dashboards. The interaction details will update on the dashboards.  
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion5" />
                  <Accordion
                    className="accordion5"
                    title="Phase 5: Material Withdrawal"
                    defaultOpen={false}
                  >
                    <div className="row p-3" style={{ margin: "0px" }}>
                        <p className="flowsContent">
                           Different materials included in the planning of the Meeting should follow their distinct withdrawal process as applicable. A good inventory management of VVPM material job bags will include: 
                        </p>
                        <ul className="ul_margin_bottom">
                            <li className="flowsContent">
                              <strong>Physical Material</strong>   specific to the Meeting/Event date and details should be marked for ‘One-Time’ use and allowed to auto-expire.  
                            </li>
                        </ul>
                        <p className="flowsHeading">Remember!</p>
                        <p className="flowsContent">
                            Trigger Material and Retirement Status Application (MaRs) withdrawal notification for Physical Material to be destroyed by the Customer-Facing team.  
                        </p>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            {/*End Lifecycle */}

            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Role </th>
                        <th>Responsibility</th>
                        </tr>
                    </thead>  
                    <tbody>
                      <tr>
                         <td className="tb">
                              <h6>Veeva Owner</h6>
                          </td>
                          <td className='tb'>
                              <p>
                                  The Veeva Owner is also known as the Uploader (please see 'Veeva uploader')
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator</h6>
                          </td>
                          <td>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Having overall responsibility and accountability for the material</li>
                                  <li>2. Ensure that no item is used without the necessary approvals</li>
                                  <li>3. Ensuring timely change and/or withdrawal, where needed</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a>
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Uploader</h6>
                          </td>
                          <td>
                              <p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA
                                  teams, and is assigned the Veeva Owner role.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Uploading the material to Veeva</li>
                                  <li>2. Performing all activities related to upload</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Co-ordinator</h6>
                          </td>
                          <td>
                              <p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager (or similar
                                  role). </p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Reviewing the item before sending for MLR review</li>
                                  <li>2. Managing the document workflow</li>
                                  <li>3. Assigning reviewers</li>
                                  <li>4. Managing expiry or periodic review</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Business Reviewer</h6>
                          </td>
                          <td>
                              <p>Business Reviewer is an experienced commercial employee.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring all promotional materials align with the brand strategy, Lilly Brand Book, Product Brand
                                      Book</li>
                                  <li>2. Ensuring all materials are of good quality, have correct Veeva metadata and adhere to internal
                                      policies and local codes</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Medical Certifier</h6>
                          </td>
                          <td>
                              <p>Registered medical practitioner or UK-registered Pharmacist.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring that the material is in accordance with the requirements of the local code</li>
                                  <li>2. Ensuring that the material is not inconsistent with the marketing authorisation/SmPC (if applicable)
                                  </li>
                                  <li>3. Ensuring that the material is a fair and truthful representation of the facts about the medicine (if
                                      applicable)</li>
                                  <li>4. Approving the material in its final form</li>
                                  <li>5. Ensuring the material has the appropriate reviewers and that the Veeva metadata is correct.</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for ensuring that the Veeva metadata is correct and that the PMC
                                  documents are included (if applicable).</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordic Code Approver</h6>
                          </td>
                          <td>
                              <p>Code Approver must be a medical practitioner or a pharmacist (except for in Sweden) and is responsible for
                                  ensuring that the material is in line with the local Codes and that the material being approved has the
                                  appropriate reviewers; and ensuring that the Veeva metadata is correct.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordics Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for reviewing the material from a medical perspective.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Indegene</h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1. Creating artwork as per material originator brief</li>
                                  <li>2. Creating formatted PI PDFs</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Content conceptualisation</li>
                                  <li>2.  Brief to Indegene </li>
                                  <li>3.  Managing approval proces </li>
                                  <li>4.  Ensuring that a print request is raised with KonicaMinolta (or for simple materials, printed in the office) to provide hard copies for certification</li>
                                  <li>5.  Ensuring final print request is raised and material is delivered to final destination (e.g., warehouse)</li>
                                  <li>6.  Withdrawal </li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Global Librarian  </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Archiving Physical Material as per the request from the Material Originator</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Marketing Services  </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Business relationship owner for the printing agency (Indicia), creation agency (Indegene) and warehouse supplier across Hub (STACI, UK; Velox, FI, SE and DK and Logistikkhuset, NO)</li>
                                  <li>2.  Primary point of contact for Marketing teams in regards to feedback to above mentioned suppliers </li>
                                  <li>3.  Delivering trainings for (new) employees relevant to third tools and related material management processes  </li>
                                  <li>4.  Oversight and support role through material management process including material withdrawal process</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Konica Minolta  </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Providing printing proofs prior to print</li>
                                  <li>2.  Printing as per print request and delivering to defined location </li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator   </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Submitting online request form to build On24 platform</li>
                                  <li>2.  Managing approval process </li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>OCE Operations Manager   </h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1.  Building On24 platform as per timelines and the provided brief </li>
                                  <li>2.  Downloading attendance report from On24 and submitting to the LMC Team for customer reconciliation in Mercury </li>
                              </ul>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>What it Stands for  </strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                    Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Stand</td>
                    <td className="tb"></td>
                    <td className="tb">
                       Designated space purchased by Lilly for a congress or event. This space can feature a stand with promotional imagery and pull-up banners.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Booth</td>
                    <td className="tb"></td>
                    <td className="tb">
                       Designated space purchased by Lilly for a congress or event. This space can feature a stand with promotional imagery and pull-up banners.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool </td>
                    <td className="tb">Planning and execution tools combine to form the CET. With CET, customer plans can be created and campaigns can be managed in one place. </td>
                  </tr>
                  <tr>
                    <td className="tb">Congress </td>
                    <td className="tb"></td>
                    <td className="tb">
                      Third party organised events in which Lilly purchases a sponsorship package for. This can include a stand, booth and/or symposium
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Conversion Metric </td>
                    <td className="tb"></td>
                    <td className="tb">Metrics used to measure the number of valuable actions customers take through a digital marketing channel. </td>
                  </tr>
                  <tr>
                    <td className="tb">CSA </td>
                    <td className="tb">Control Self Assessment </td>
                    <td className="tb">A quality check performed by process owners quarterly. The results are shared and discussed with HLT with measures put in place to mitigate any identified issues.  </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey </td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>
                  <tr>
                    <td className="tb">Cvent </td>
                    <td className="tb"></td>
                    <td className="tb">Hopsitality management platform that allows capture of customer details for meetings and events, such as registration details and logistics. </td>
                  </tr>
                  <tr>
                    <td className="tb">eP2P</td>
                    <td className="tb">Electronic peer to peer </td>
                    <td className="tb"> eP2P is the video broadcasting of P2P programs transmitted via internet either live or hosted (video recording) on Lilly or 3rd party sites. </td>
                  </tr>
                  <tr>
                    <td className="tb">HO</td>
                    <td className="tb">Head Office</td>
                    <td className="tb">A prefix to tactics owned by the teams who work from a Lilly office, such as medical and marketing.  </td>
                  </tr>
                  <tr>
                    <td className="tb">Hybrid P2P </td>
                    <td className="tb">Hybrid Peer to Peer  </td>
                    <td className="tb">A Lilly organized eP2P set up in such a way that some customers attend the meeting individually while others gather in groups to attend the meeting. (ie. Hub + spoke, satellite groups)  </td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">Goals or Metrics set behind a campaign and or tactic.</td>
                  </tr>
                  <tr>
                    <td className="tb">Mercury</td>
                    <td className="tb"></td>
                    <td className="tb">Salesforce Marketing Cloud application for the planning, submission and approval of meetings.</td>
                  </tr>
                  <tr>
                    <td className="tb">ON24</td>
                    <td className="tb"></td>
                    <td className="tb">The approved video broadcasting platform for Lilly eP2Ps/webinars.  </td>
                  </tr>
                  <tr>
                    <td className="tb">ON24 Console</td>
                    <td className="tb"></td>
                    <td className="tb">Online platform that allows customers access to content based on a specific topic or a virtual event.</td>
                  </tr>
                  <tr>
                    <td className="tb">ON24 registration page </td>
                    <td className="tb"></td>
                    <td className="tb">Web based page that allows customers to register for an upcoming webinar/event.  </td>
                  </tr>
                   <tr>
                    <td className="tb">P2P</td>
                    <td className="tb">Peer to peer </td>
                    <td className="tb">Lilly organized programs where an HCP (external or internal speaker) engages with other HCPs on disease state and therapeutic options through lectures and/or case study discussions. This can also be referred to as F2F events (face to face).  </td>
                  </tr>
                  <tr>
                    <td className="tb">PMC</td>
                    <td className="tb">Permissions Managed Content </td>
                    <td className="tb">An image which is owned by an entity other than Lilly and for which permission is given to use under a specifc set of circumstances as defined in a contractural agreement between Lilly and the Content Owner. </td>
                  </tr>
                  <tr>
                    <td className="tb">Symposium</td>
                    <td className="tb"> </td>
                    <td className="tb">A Lilly-owned lecture at a third party event or congress. </td>
                  </tr>
                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"> </td>
                    <td className="tb">Tactics are specific to selected channels to drive customers to reach a piece of content. Tactics are associated with estimated KPI metric (goal) and result reports (actuals).  </td>
                  </tr>
                  <tr>
                    <td className="tb">TL</td>
                    <td className="tb">Thought Leader</td>
                    <td className="tb">Thought Leaders influence thinking and/or treatment practice beyond his/her local market or practice on a regional, national or global basis. TLs are respected and acknowledged by their peers, other experts and key audiences as leaders within a particular subject matter. The term includes customers from clinical to academic to research to regulatory and the payer space.  </td>
                  </tr>
                  <tr>
                    <td className="tb">TL-MaP</td>
                    <td className="tb">Thought Leader Mapping and Planning Tool </td>
                    <td className="tb">The only approved global platform for planning and tracking contracted Thought Leader engagements. Among many other benefits, TL-MaP allows for increased line-of-sight for accountable leadership to mitigating risk when contracting TLs.  </td>
                  </tr>
                  <tr>
                    <td className="tb">VVPM</td>
                    <td className="tb">Veeva Vault PromoMat</td>
                    <td className="tb">Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems. PromoMats is a Vault application to manage the process of creating, reviewing and approving promotional materials, claims and supporting information.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Defination*/}

            {/*View Core Doc*/}
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <Link
                  className="CoreURL"
                  to={webConfig.StandBoothDetailingCoreDoc}
                  target="_blank"
                >
                  Independent Meeting Stand/Booth document here.{" "}
                </Link>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/*End Core Doc*/}

             {/*Add Faqs*/}
             <div
              className="tab-pane fade"
              id="v-pills-faqs"
              role="tabpanel"
              aria-labelledby="v-pills-faqs-tab"
            >
              <h4 className="h1-font comman_hd">FAQs</h4>
              <div id="FAQ1">
                <Accordion
                  className="faqAccordion"
                  title="Can I use Teams instead of ON24 for my upcoming Head-Office lead eP2P?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">  
                  <p>
                      No, ON24 is the only approved platform used for marketing lead webinars/eP2P. There are several reasons why:
                  </p>
                  <ul  className="ul_padding">
                    <li>
                       ON24 enables automated customer journey to send invitation emails. This reduces the work for you and for teams supporting you in running the webinar. 
                    </li>
                    <li>
                       Only ON24 is integrated with Mercury, Customer Engagement Tool (CET) and Data Dashboards. This means your attendee data is automatically uploaded on relevant places. This again, reduces work post the event. 
                    </li>
                    <li>
                       There is a potential risk in Teams where the Personal Information (Name and email) of attendees, might be visible to other attendees. 
                    </li>
                    <li>
                       ON24 has a better control over withdrawal of the material. No manual action is needed from your end. This again, saves time.  
                    </li>
                  </ul>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ2">
                <Accordion
                  className="faqAccordion"
                  title="What happens to the webinar video and the console after the webinar is over?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      ON24 platform auto-withdraws the webinar details and the material. It is archived offline. 
                    </p>
                    <p>The action to do so is built in when the console is created by the OCE Operations Manager.  </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ3">
                <Accordion
                  className="faqAccordion"
                  title="How can I get trained on Mercury?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Mercury training videos and checklists are available here {' '}
                      <Link
                        className="CoreURL"
                        to={webConfig.LMCMercurySupportSite}
                        target="_blank"
                      >
                        Mercury support site (lilly.com)
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ4">
                <Accordion
                  className="faqAccordion"
                  title="What are the useful procedures for meetings?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Please visit this page {' '}
                      <Link
                        className="CoreURL"
                        to={webConfig.MeetingsRelatedInformation}
                        target="_blank"
                      >
                        Meetings Related Information (lilly.com)
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ5">
                <Accordion
                  className="faqAccordion"
                  title="How do I check my speaker training status?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      This can be found in Mercury and Veeva and on the PowerApp {' '}
                      <Link
                        className="CoreURL"
                        to={webConfig.GBSTrainedSpeakerListPowerApps}
                        target="_blank"
                      >
                        GBS - Trained Speaker List - Power Apps
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
              <div id="FAQ6">
                <Accordion
                  className="faqAccordion"
                  title="Who is my POC in the Lilly Meetings and Congresses Team?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>
                      Please see org chart here:{' '}
                      <Link
                        className="CoreURL"
                        to={webConfig.HEHUBLMCOrgChart}
                        target="_blank"
                      >
                        HE HUB LMC Org Chart_Oct 2023.png (1920×1080) (lilly.com)
                      </Link>
                    </p>
                  </div>  
                </Accordion>
              </div>
            </div>
            {/*End Faqs*/}
          </div>
        </div>
      </div>

    </div>
  );
};


export default StandBoothDetailing;
