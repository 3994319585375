import React, { useEffect } from 'react';

function CookieConsent() {
    useEffect(()=>{
        window.OnCookieWidgetLoaded =()=>{
            const savePreference = document.getElementById('save_preference');
            // New Reject-All Button
            const rejectButton = document.createElement('BUTTON');
            rejectButton.innerHTML = 'Refuse all cookies';
            rejectButton.classList.add('save');
            rejectButton.setAttribute('id', 'reject-new');
            savePreference.before(savePreference.appendChild(rejectButton));
    
            // New Accept-All Button
            const acceptButton = document.createElement('BUTTON');
            acceptButton.innerHTML = 'Accept all cookies';
            acceptButton.classList.add('save');
            acceptButton.setAttribute('id', 'accept-new');
            savePreference.after(savePreference.appendChild(acceptButton));
    
            // New Div For Extra Space
            const div = document.getElementById('accept-new');
            const divElement = document.createElement('DIV');
            divElement.classList.add('lastdiv');
            divElement.setAttribute('id', 'lastdiv-id');
            div.after(div.appendChild(divElement));
    
            document.getElementById('accept-new').onclick = function acceptall() {
              // eslint-disable-next-line no-undef
              Syrenis_AcceptAllCookies();
              // eslint-disable-next-line no-undef
              Syrenis_CloseCookieOptionsModal();
            };
    
            document.getElementById('reject-new').onclick = function rejectall() {
              // eslint-disable-next-line no-undef
              Syrenis_RejectAllCookies();
              // eslint-disable-next-line no-undef
              Syrenis_CloseCookieOptionsModal();
            };
        }    
    },[])   
  return null; 
}
export default CookieConsent;
