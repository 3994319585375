import { Outlet } from 'react-router-dom';
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../configs/authConfig";

const  MsalAuthLayout = ()=>{
  const authRequest = {
      ...loginRequest
  };

  return (
      <MsalAuthenticationTemplate 
          interactionType={InteractionType.Redirect} 
          authenticationRequest={authRequest} 
          // errorComponent={ErrorComponent} 
          // loadingComponent={Loading}
      >
          <Outlet />
      </MsalAuthenticationTemplate>
    )
};

export default MsalAuthLayout;