import React from "react";
import { useState, useRef, useEffect } from "react";
import "../css/Passport.css";
import {webConfig} from '../configs/webConfig'
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/Passport_Phases_SVG/Passport_Phase_1.svg";
import { ReactComponent as Phase2 } from "../SVG/Passport_Phases_SVG/Passport_Phase_2.svg";
import { ReactComponent as Phase3 } from "../SVG/Passport_Phases_SVG/Passport_Phase_3.svg";
import { ReactComponent as Phase4 } from "../SVG/Passport_Phases_SVG/Passport_Phase_4.svg";
import { ReactComponent as Phase5 } from "../SVG/Websites_Phases_SVG/Websites_Phase_5.svg";

const Passport = () => {
  const [isPhase2Open, setIsPhase2Open] = useState();
  const [isPhase3Open, setIsPhase3Open] = useState();

  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("LOCALAFFILATE");

  const svgRef2 = useRef();
  const [P2visibleSection, setP2visibleSection] = useState("Content");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("Initiate");

  const svgRef4 = useRef();
  const [P4visibleSection, setP4visibleSection] = useState("BiAnnualSelfAssesment");

  const svgRef5 = useRef();
  const [P5visibleSection, setP5visibleSection] = useState("Gatekeeper");

  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");

    rect1.addEventListener("click", () => {
      setP1visibleSection("IBU");
    });

    rect2.addEventListener("click", () => {
      setP1visibleSection("TRIAGE");
    });

    rect3.addEventListener("click", () => {
      setP1visibleSection("LOCALAFFILATE");
    });
    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect4.addEventListener("click", () => {
      handleScrollP2();
      setIsPhase2Open(true);
    });
  }, []);

  useEffect(() => {
    const svg = svgRef2.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    const rect8 = svg.getElementById("rect8");
    const rect9 = svg.getElementById("rect9");
    const rect10 = svg.getElementById("rect10");
    const rect11= svg.getElementById("rect11");
    const rect12 = svg.getElementById("rect12");
    const rect13 = svg.getElementById("rect13");
    const diam1 = svg.getElementById("diamond1");
    
    rect1.addEventListener("click", () => {
      setP2visibleSection("Content");
    });
    rect2.addEventListener("click", () => {
      setP2visibleSection("TECHNICAL");
    });
    rect3.addEventListener("click", () => {
      setP2visibleSection("TRANSLATIONBYIBU");
    });
    rect4.addEventListener("click", () => {
      setP2visibleSection("IBUSTAGINGSITE");
    });
    rect5.addEventListener("click", () => {
      setP2visibleSection("TRANSLATIONBYPOC");
    });
    rect6.addEventListener("click", () => {
      setP2visibleSection("CUSTODIANCOMPUAT");
    });
    rect7.addEventListener("click", () => {
      setP2visibleSection("DOCUMENTCODE");
    });
    rect8.addEventListener("click", () => {
      setP2visibleSection("TRANSLATIONPASSESTOIBU");
    });
    rect9.addEventListener("click", () => {
      setP2visibleSection("IBUGCOTESTLINK");
    });
    rect10.addEventListener("click", () => {
      setP2visibleSection("CONFIRMGOLIVE");
    });
    rect11.addEventListener("click", () => {
      setP2visibleSection("CUSTODIANVALIDTECHNGE");
    });
    rect12.addEventListener("click", () => {
      setP2visibleSection("RECORDCHANGELOG");
    });
    diam1.addEventListener("click", () => {
      setP2visibleSection("VEEVEJOBBAG");
    });
    const handleScrollP3 = () => {
      const elementToScrollTo = document.getElementById("accordion3");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect13.addEventListener("click", () => {
      handleScrollP3();
      setIsPhase3Open(true);
    });
  }, [isPhase3Open]);

  useEffect(() => {
    const svg = svgRef4.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    

    rect1.addEventListener("click", () => {
      setP4visibleSection("BiAnnualSelfAssesment");
    });

    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y = elementToScrollTo.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect3.addEventListener("click", () => {
      handleScrollP2();
      setIsPhase2Open(true);
    });
  }, [isPhase2Open]);

  useEffect(() => {
    const svg = svgRef3.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    

    rect1.addEventListener("click", () => {
      setP3visibleSection("Initiate");
    });
    rect2.addEventListener("click", () => {
      setP3visibleSection("Approval");
    });
    rect3.addEventListener("click", () => {
      setP3visibleSection("BuisnessAdvise");
    });
    rect4.addEventListener("click", () => {
      setP3visibleSection("ChangesAreLive");
    });
    rect5.addEventListener("click", () => {
      setP3visibleSection("PostGoLiveSteps");
    });

  }, []);
  
  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    Lilly Passport 
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">Lilly Passport </h2>
            <p>
              <em>
                  Lilly Passport is a global platform for Lilly that allows healthcare professionals (HCPs) to create a profile and set up an account with Lilly. By creating an account, the HCP is creating a username and password to access Lilly content that is locked behind password protection. There is a unique Lilly Passport form for each country. It provides a single-entry point for all Lilly websites that require authentication capabilities, using Okta as identity manager and security Layer.
              </em>
            </p>
            <p>
              <em>
                  Lilly Passport is not storing or processing any information, it is only collecting and transferring (dispatching) the data to Enterprise Profile Hub (EPH), Global Customer Consent Platform (GCCP) and Okta.
              </em>
            </p>
            <p>
              <em>
                 Here is a link to the {" "}
                 <a 
                  href={webConfig.IBULillyPassportPlaybook}
                  target="_blank">
                    IBU Lilly Passport Playbook      
                </a> {" "}
                 for more information.  
              </em>
            </p>

          </div>
        </div>
      </div>

      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-bs-toggle="tab"
              href="#processPreRequisites"
            >
              Process
              <br />
              Pre-requisites
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#typeOfConsents">
               Types of Consents on Lilly Passport
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#InformationSheet">
              INFORMATION <br/>SHEET
            </a>
          </li>
        </ul>
        
        <div id="tabContent" className="tab-content">
            <div id="processPreRequisites" className="tab-pane fade in active tabs_box tabContent">
                <div>
                    <p>
                      The OCE Leader is the designated <strong>Business Owner</strong> for this website and the TECH@LILLY Manager is the <strong>Custodian</strong> for Lilly Passport. 
                    </p>
                    <p className="tabsContentSubHeading">Initiation</p>
                    <p>
                      Change can only be initiated once a TECH@LILLY Local Custodian and a Business Owner have agreed for the change to be implemented.  
                    </p>
                    <p className="tabsContentSubHeading">Execution</p>
                    <p>
                      Change can only be agreed with and executed by the TECH@LILLY Passport Custodian.
                    </p>  
                    <p className="tabsContentSubHeading">Approvals</p>
                    <p>
                      Approval Process changed in February 2024 allowing this review and approval to take place outside Veeva and managed through sharepoint site with admin rights only available to owner and custodian. 
                    </p>
                </div>
            </div>
            <div id="typeOfConsents" className="tab-pane fade tabs_box tabContent">
                <p>
                  In addition to creating a profile, <strong>two consent types are also available to HCPs.</strong> These are: 
                </p>
                <p className="tabsContentSubHeading">Privacy opt-in</p>
                <p>
                   HCP sees a check box and a short description on Lilly’s privacy policy. The HCP <strong>must</strong> Opt-in this notice to be able to register/create an account with Lilly. This is essential to indicate that an HCP agrees with Lilly’s privacy policies and/or how the HCP data is handled.
                </p>
                <p className="tabsContentSubHeading">E-permission</p>
                <p>
                  HCPs also have the option to give consent to receive medical and marketing communication from Lilly. <strong>This consent is optional. </strong>
                </p>
            </div>
            <div id="InformationSheet" className="tab-pane fade tabs_box tabContent">
                <p>
                  Please complete all fields of this sheet for each approval.  
                </p>
                <table className="table table-striped">
                    <thead>
                        <tr>
                        </tr>
                    </thead>  
                    <tbody>
                      <tr>
                        <td className="tb">
                          Name of the Material
                        </td>
                        <td className='tb'></td>
                      </tr>
                      <tr>
                        <td className="tb">
                          Material Code
                        </td>
                        <td className='tb'></td>
                      </tr>
                      <tr>
                        <td className="tb">
                          Estimated date/month of Go-Live
                        </td>
                        <td className='tb'></td>
                      </tr>
                      <tr>
                        <td className="tb">
                          Name of the Custodian
                        </td>
                        <td className='tb'></td>
                      </tr>
                      <tr>
                        <td className="tb">
                          Name of the Business Approver
                        </td>
                        <td className='tb'></td>
                      </tr>
                      <tr>
                        <td className="tb">
                          Additional approvers, if needed
                        </td>
                        <td className='tb'>
                            <p>In some scenarios, Business Owner may request Medical Reviewer or E&C to review the material. </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="tb">
                          Nature of the update – Text, Functionality or Both?
                        </td>
                        <td className='tb'></td>
                      </tr>
                      <tr>
                        <td className="tb">
                          Describe the change(s)
                        </td>
                        <td className='tb'></td>
                      </tr>
                      <tr>
                        <td className="tb">
                          UAT required – Yes or No or Not Applicable?
                        </td>
                        <td className='tb'>
                          <p>Please explain the reason if UAT is marked as Not applicable.</p>
                        </td>
                      </tr>
                      <tr>
                        <td className="tb">
                          Individuals who performed the UAT?
                        </td>
                        <td className='tb'></td>
                      </tr>
                      <tr>
                        <td className="tb">
                          UAT period (date range)
                        </td>
                        <td className='tb'></td>
                      </tr>
                      <tr>
                        <td className="tb">
                          Test/stage link for Review and approval
                        </td>
                        <td className='tb'></td>
                      </tr>
                      <tr>
                        <td className="tb">
                          Log-in credentials for Test link
                        </td>
                        <td className='tb'></td>
                      </tr>
                      <tr>
                        <td className="tb">
                          Date of submission of this Information Sheet
                        </td>
                        <td className='tb'></td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
      
      {/*Add verticle Tab*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">Lilly Passport Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Planning"
                    defaultOpen={true}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                         <Phase1 ref={svgRef1} className="passportShape1" />
                      </div>
                      <div className="col-lg-7 planning p_font pt-3 flowsSecondPart">
                      <div
                          style={{
                            display:
                              P1visibleSection === "LOCALAFFILATE" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">CHANGE REQUESTED BY LOCAL AFFILIATE  </p>
                          <p className="flowsContent">
                             Local affiliates may request a text or functional update.
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "IBU" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">ENHANCEMENTS FROM IBU </p>
                          <p className="flowsContent">
                            Changes made to Lilly Passport for the NE Hub are governed by the <strong>IBU Level Passport team</strong>. These are communicated and managed quarterly.
                          </p>
                          <p className="flowsContent">
                            Updates may be announced in IBU Community of Practice (COP) calls or communicated via mail by the Passport teams to the TECH@LILLY Custodian. These may be content changes and/or functional/technical changes.
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "TRIAGE"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">TRIAGE</p>
                          <p className="flowsContent">
                               Initial Impact discussed and triaged between 
                               the Business Owner and the TECH@LILLY Custodian 
                               for Lilly Passport. Ethics and Compliance may be 
                               involved if needed. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion2" />
                  <Accordion
                    className="accordion2"
                    title="Phase 2: Material Readiness"
                    defaultOpen={false}
                    isOpen={isPhase2Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning main-content">
                         <Phase2 ref={svgRef2} className="passportShape2" />
                      </div>
                      <div className="col-lg-6 planning pt-4 flowsSecondPart side-content">
                        <div
                          style={{
                            display:
                              P2visibleSection === "Content"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CONTENT CHANGE </p>
                          <p
                            className="flowsContent"
                          >
                            i.e. Text or registration fields are being updated
                          </p>
                          <p className="flowsContent">
                             If the text change is on Opt-in/short text, then the Privacy Policy update procedure needs to be additionally followed.
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "TECHNICAL"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">FUNCTIONAL/TECHNICAL CHANGE  </p>
                          <p
                            className="flowsContent"
                          >
                            Something in the backend of the tool 
                            (that does not require a text update) to be changed. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "TRANSLATIONBYIBU"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">TRANSLATION TEMPLATES PROVIDED BY IBU TEAM </p>
                          <p
                            className="flowsContent"
                          >
                            TECH@LILLY Custodian requests that local POCs translate content from English to local languages as per their countries.
                          </p>
                          <p className="flowsContent">
                          If the change is coming from the local affiliate, translations would need to be provided to the IBU team via the Custodian.
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "IBUSTAGINGSITE"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">IBU/GCO PROVIDES STAGING SITE  </p>
                          <p
                            className="flowsContent"
                          >
                             This is the link where the change has been implemented and is ready to be tested. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "TRANSLATIONBYPOC"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">TRANSLATION REVIEWED BY POC </p>
                          <p
                            className="flowsContent"
                          >
                            The local POC (point of contact) is usually a Marketing Associate (or two). Their job is to translate, confirm accuracy and approve of test links provided by the IBU team and the TECH@LILLY Custodian. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "CUSTODIANCOMPUAT"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CUSTODIAN COMPLETES UAT </p>
                          <p
                            className="flowsContent"
                          >
                            Custodian is to run through the testing and document tests performed and the results. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "DOCUMENTCODE"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">DOCUMENT CODE CREATED AND ADDED TO TRANSLATION TEMPLATE  </p>
                          <p
                            className="flowsContent"
                          >
                            For clear version control, a new document number will need to be created for each update. It is responsbility of Custodian to generate this code as per the agreed code (LP_CountryCode_MonthYear) Example – (LP_UK_0224) 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "TRANSLATIONPASSESTOIBU"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">TRANSLATION PASSED BACK TO IBU/GCO  </p>
                          <p
                            className="flowsContent"
                          >
                            Custodian provides the translation matrix to the IBU or GCO teams. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "CONFIRMGOLIVE"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CONFIRM GO-LIVE  </p>
                          <p
                            className="flowsContent"
                          >
                            Communicate changes to relevant parties once the IBU team has pushed changes to the live environment.
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "IBUGCOTESTLINK"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">IBU/GCO PROVIDES TEST LINKS  </p>
                          <p
                            className="flowsContent"
                          >
                             The translations are applied to Lilly Passport and staging (test link) is sent to the local Custodian. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "RECORDCHANGELOG"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">RECORD IN CHANGE LOG  </p>
                          <p
                            className="flowsContent"
                          >
                            It is the responsibility of the Custodian to maintain a change log and record a UAT. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "CUSTODIANVALIDTECHNGE"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CUSTODIAN VALIDATES CHANGES   </p>
                          <p
                            className="flowsContent"
                          >
                            Before initiating approval, custodian checks that content has been uploaded as expected, creates PDF file and highlight changes.
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P2visibleSection === "VEEVEJOBBAG"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CHANGES AFFECT VEEVA JOB BAG </p>
                          <p
                            className="flowsContent"
                          >
                            If changes do not affect the text or approved functionality of the cookie notice, VVPM approval is not needed. It is the combined responsibility of the Business Owner and the TECH@LILLY Custodian to assess whether VVPM approval is needed.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  <div id="accordion3" />
                  <Accordion
                    className="accordion3"
                    title="Phase 3: Material Approval & Launch"
                    defaultOpen={false}
                    isOpen={isPhase3Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning main-content">
                        <div className="comman_width_e">
                        <Phase3 ref={svgRef3} className="passportShape3" />
                        </div>
                      </div>
                      <div className="col-lg-6 planning flowsSecondPart side-content ">
                        <div
                          id="link1"
                          style={{
                            display:
                              P3visibleSection === "Initiate"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">INITIATE EXAMINATION </p>
                          <p className="flowsContent">
                            Updates are co-ordinated by the local Tech@Lilly custodian. The new approval process took effect in February 2024 in agreement with Medical Certifiers and E&C that material approval will be outside Veeva and managed through SharePoint site.  
                          </p>
                          <p className="flowsContent">
                            Updates on Lilly passport do not need to be approved by a medical certifier as there’s no branded content.
                          </p>
                          <p className="flowsContent">
                            Custodian <strong>submits the PDF</strong> of all the changes highlighting the sections that have been updated. The PDF should be marked with comments regarding nature of change <strong className="text-decoration-underline">in ‘From’ ‘To’ format.</strong>  
                          </p>
                          <p className="flowsContent">
                             Following documents are additionally required to be submitted for approval. 
                          </p>
                          <p className="flowsContent">
                            Passport job bags must include the following attachments: 
                          </p>
                    
                          <h6 className=" flowsContent text-decoration-underline">1. UAT Test Record</h6>
                          <p className="flowsContent">
                              If testing was applicable and <strong>completed by a custodian and supplied to Veeva co-ordinator</strong>. All staging links and written proof of co-ordinator approval of the tested link must be attached.
                          </p>

                          <h6 className="flowsContent text-decoration-underline">2. Test Link</h6>
                          <p className="flowsContent">
                            Stage or test link to be used for checking the updates
                          </p>

                          <h6 className="flowsContent text-decoration-underline">3. Information Sheet </h6>
                          <p className="flowsContent">
                            An information sheet summarising the change/update. <strong>Custodian completes the table provided and sends to Veeva co-ordinator to insert as cover page for the job bag.</strong> 
                          </p>

                          <h6 className="flowsContent text-decoration-underline">4. Test Link</h6>
                          <ul className="ul_padding flowsContent">
                            <li>
                              <strong> If text is not in English</strong>
                              <p>A translation confirmation mail from the local POC confirming that translated text is accurate representation of English version</p>
                            </li>
                            <li>
                              <strong> If a text update is made to Opt-in short description changes</strong>
                              <p>An email from E&C Manager to confirming that the changes are accurate and as expected.</p>
                            </li>
                            <li>
                              <strong> If Update relates to interactivit</strong>
                              <p>Open text fields or other patient safety related points, GPS review/approval is needed for all NE Hub countries. </p>
                              <p>These documents are to be uploaded within {' '}
                                <a
                                  href={webConfig.NEHubOCECurrentlyunderdevelopmentandapproval}
                                  target="_blank">
                                    NE Hub - OCE - Currently under development and approval - Website (lilly.com)
                                </a>{' '} as a folder with all the required documents in one place. 
                              </p>  
                            </li>
                          </ul>


                        </div>
                        <div
                          id="link2"
                          style={{
                            display:
                              P3visibleSection === "Approval"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Approval</p>
                          <p className="flowsContent">
                            Business owner should complete the review within 5 business days post submission. Depending upon the observations, they will either advise Custodian to send get further changes made or to advise IBU to go-live.  
                          </p>
                          <p className="flowsContent">
                            Approval <strong>confirmation from Business owner must in writing</strong> and such email is to be uploaded as Proof of approval.  
                          </p>
                        </div>
                        <div
                          id="link3"
                          style={{
                            display:
                              P3visibleSection === "BuisnessAdvise"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">BUSINESS OWNER ADVISES CUSTODIAN TO GO-LIVE   </p>
                          <p className="flowsContent">
                            Business owner should complete the review within 5 business days post submission. Depending upon the observations, they will either advise Custodian to send get further changes made or to advise to go-live. 
                          </p>
                          <p className="flowsContent">
                            Approval <strong>confirmation from Business owner must in writing</strong> and such email is to be uploaded as Proof of approval.  
                          </p>
                          <p className="flowsContent">
                            Tech@Lilly custodian ensures all text links, credentials and necessary attachments have been added to the approval folder. 
                          </p>
                        </div>
                        <div
                          id="link6"
                          style={{
                            display:
                              P3visibleSection === "ChangesGoLive"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CHANGES ARE LIVE  </p>
                          <p className="flowsContent">
                            Custodian communicates to IBU team to push changes to live environment. IBU Team provide a go-live timeframe and confirmation once we are live.  
                          </p>
                        </div>
                        <div
                          id="link7"
                          style={{
                            display:
                              P3visibleSection === "ChangesAreLive"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CHANGES ARE LIVE</p>
                          <p className="flowsContent">
                             Custodian communicates to IBU team to push changes to live environment. IBU Team provide a go-live timeframe and confirmation once we are live.  
                          </p>
                        </div>
                        <div
                          id="link8"
                          style={{
                            display:
                              P3visibleSection === "PostGoLiveSteps"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">POST GO-LIVE STEPS     </p>
                          <p className="flowsContent">
                             <strong>Once Changes are Live:</strong>  
                          </p>
                          <p className="flowsContent">
                            Custodian reviews the content on live site to ensure it meets functionality performance and text accuracy as expected (as documented on at Approval stage), This review <strong>must be completed within 2 working days</strong>.  
                          </p>
                          <p className="flowsContent">
                            Custodian confirms completion of live site review to Business Owner. This confirmation must be in writing and the email saved as proof of post Go-Live Review.  
                          </p>
                          <p className="flowsContent">
                            Custodian must upload following documents on {' '}
                            <a
                              href={webConfig.NEHubOCECurrentlyapprovedfordistribution}
                              target="_blank">
                                NE Hub - OCE - Currently approved for distribution - Website (lilly.com)
                            </a> 
                          </p>
                          <ul className="ul_padding">
                            <li className="flowsContent">
                                Original Email from Business owner to Custodian confirming approval and advising Go-Live.  
                            </li>
                            <li className="flowsContent">
                                Confirmation Email from Custodian to Business confirming that the changes are live along with the confirmation that these changes have been reviewed on the live site for accuracy. 
                            </li>
                            <li className="flowsContent">
                                Information Sheet, UAT record, and any additional attachments, as submitted during Approval process, should also be moved to this folder.  
                            </li>
                          </ul>
                          <p className="flowsContent">
                            As the custodian moves the files to this folder, the pervious or superseded version should be moved to {' '}
                            <a
                              href={webConfig.NEHubOCEPreviousVersionsWebsite}
                              target="_blank">
                                NE Hub - OCE - Previous Versions - Website (lilly.com)
                            </a> 
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  {/*Accordion4*/}
                  <div id="accordion4" />
                  <Accordion
                    className="accordion4"
                    title="Phase 4: Material Management"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning">
                        <Phase4 ref={svgRef4} className="passportShape4" />
                      </div>
                      <div className="col-lg-6 planning p-3 flowsSecondPart">
                        <div
                          style={{
                            display:
                              P4visibleSection === "BiAnnualSelfAssesment"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                              BI-ANNUAL CONTROLLED SELF ASSESSMENTS COMPLETED 
                          </p>
                          <p className="flowsContent">
                              As part of the quarterly material governance, Passport job bags are matched to the live environments bi-annually and reported back to the governance team. 
                          </p>
                        </div>
                         <div
                          style={{
                            display:
                              P4visibleSection === "UpdateOfMaterial"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">UPDATE OF MATERIAL</p>
                          <p className="flowsContent">
                             Should any content be missed from the job bag, a Veeva Passport document update may be required. This should cover any and all text/visual content with screenshots. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion5" />
                  <Accordion
                    className="accordion5"
                    title="Phase 5: Material Withdrawal"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <p className="flowsHeading">Not Required.</p>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            
            {/*End Lifecycle */}
            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Role </th>
                        <th>Responsibility</th>
                        </tr>
                    </thead>  
                    <tbody>
                      <tr>
                          <td className="tb">
                              <h6>Business Reviewer</h6>
                          </td>
                          <td>
                              <p>Business Reviewer is an experienced commercial employee.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring all promotional materials align with the brand strategy, Lilly Brand Book, Product Brand
                                      Book</li>
                                  <li>2. Ensuring all materials are of good quality, have correct Veeva metadata and adhere to internal
                                      policies and local codes</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>Expanded</strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">Customer Journey</td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">
                    Goals or Metrics set behind a campaign and or tactic.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Lilly Passport</td>
                    <td className="tb"></td>
                    <td className="tb">Lilly Passport is a standalone software to authenticate healthcare professionals. It provides a single-entry point for all Lilly websites that require authentication (e.g., Lilly Play and ON24) using Okta as an identity manager and security layer.</td>
                  </tr>
                  <tr>
                    <td className="tb">UAT</td>
                    <td className="tb">User Assured Testing</td>
                    <td className="tb">A type of validation which ensures that the product or the solution works for the user and meets all his/her requirements. It is usually the last step in the software testing process.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Defination*/}

            {/*View Core Doc*/}
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.PassportCoreDoc}
                  target="_blank"
                >
                  Passport document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/*End Core Doc*/}
          </div>
        </div>
      </div>

    </div>
  );
};


export default Passport;
