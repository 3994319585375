import React from "react";
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="container">
      <div className="tab-content">
        <div id="hometab" className="container tab-pane active">
          <br />
          <p className="pageHeading">The Material Lifecycle Portal</p>
          <div className="row">
            <div className="col-lg-12 HomeIntro">
              <p className="pageSubHeading">Welcome!</p>
              <p>
                When engaging with customers, we all want to create the 
                <strong>best-in-class experience</strong>. The materials we use
                for our campaigns play a <strong>vital</strong> role. As a
                marketer, you have all the <strong>creativity</strong> to make 
                <strong>engaging content</strong> for your customers. Sometimes,
                understanding processes may get in your way. This portal is here
                to help you 
                <strong>walk through end-to-end planning</strong> and management
                of a material or tactic.
              </p>
              <p>
                Campaign execution and material development comes with inherent
                risks. You can <strong>mitigate risks</strong> with a clear
                understanding of different phases in a material’s lifecycle. For
                an effective and compliant execution, you need to understand all
                these phases and different steps within each phase.
              </p>
              <p>
                <strong>
                  Click on the Tactic or Material type that you would like to
                  walk through today.
                </strong>
              </p>
            </div>
            {/* <div className="col-lg-4 text-center">
              <img
                className="material_lifecycle_flow"
                src="images/material_lifecycle_illustration.png"
              />
              <p>
                <em>Visit the High Level Process to learn more!</em>
              </p>
            </div> */}
          </div>
          <div className="row my-4">
            <img
              className="material_lifecycle_steps"
              src="images/material_lifecycle_phases.png"
            />
            <span className="tactic-text">
              Click {' '} 
              <Link 
                id="hlpLinkBtn"
                to="/HighLevelProcess"
                target="_blank"
              > here </Link>{' '} to learn more about the High Level Process! 
            </span>
          </div>
          <br />
          <div className="card headerCard">
            <div className="card-body">Tactics and Processes</div>
          </div>
          <div className="card tacticsContent">
            <div className="card-body row">
              <div className="col">
                <p className="tacticsSubHeading">Customer Facing Tactics</p>
                <div className="d-flex justify-content-center flex-wrap">
                  <div className="tacticsBlocks">
                    <a href="/BE" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                          <tr className="imageRow">
                            <td className="tacticsCell">
                              <img className="tacticsIcons" src="images/BE.PNG" />
                            </td>
                          </tr>
                          <tr className="titleRow">
                            <td className="tacticsCell">
                              <p className="tacticTitle">
                                Broadcast
                                <br />
                                Emails (BE)
                              </p>
                            </td>
                          </tr>
                        </tbody>  
                      </table>
                    </a>
                  </div>
                  <div className="tacticsBlocks">
                    <a href="/vae" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/VAE.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">
                              Veeva Approved Emails (VAE)
                            </p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                  <div className="tacticsBlocks">
                    <a href="./IVA" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/IVA.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">
                              Interactive
                              <br />
                              Visual Aids (IVA)
                            </p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                  <div className="tacticsBlocks">
                    <a href="./soa" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/SOA.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">Source of Authority (SOA)</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                  <div className="tacticsBlocks">
                    <a href="./Pm" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/physical_material.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">
                              Physical
                              <br />
                              Material
                            </p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                  <div className="tacticsBlocks">
                    <a href="./meetings" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/meetings.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">Meetings</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                  <div className="tacticsBlocks">
                    <a href="/video" className="tacticsInnerBlock tacticsDisabled">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/video.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">Video</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                  <div className="tacticsBlocks">
                    <a href="/website" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/websites.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">Websites</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col">
                <p className="tacticsSubHeading">Other Materials</p>
                <div className="d-flex justify-content-center flex-wrap">
                  <div className="tacticsBlocks">
                    <a href="/pmc" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/PMC.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">
                              Permission Managed..(PMC)
                            </p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                  <div className="tacticsBlocks">
                    <a href="./internal-briefing" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/internal_briefing_guides.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">
                              Internal Briefing Guides
                            </p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                  <div className="tacticsBlocks">
                    <a href="/slidedecs" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/slide_decks.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">Slide Decks</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                  <div className="tacticsBlocks">
                    <a href="/passport" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/lilly_passport.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">
                              Lilly Passport
                            </p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                  <div className="tacticsBlocks">
                    <a href="/cookie" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/cookies.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">Cookie Policy</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                  <div className="tacticsBlocks">
                    <a href="/privacy" className="tacticsInnerBlock">
                      <table>
                        <tbody>
                        <tr className="imageRow">
                          <td className="tacticsCell">
                            <img
                              className="tacticsIcons"
                              src="images/privacy_policy.PNG"
                            />
                          </td>
                        </tr>
                        <tr className="titleRow">
                          <td className="tacticsCell">
                            <p className="tacticTitle">Privacy Policy Notice</p>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
