function handleScroll(elemId){
    const elementToScrollTo = document.getElementById(elemId);
    if (elementToScrollTo) {
      const y =
        elementToScrollTo.getBoundingClientRect().top +
        window.pageYOffset -
        window.innerHeight / 2;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }
module.exports = {
    handleScroll
}