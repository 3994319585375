import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Accordion.css";

export default function Accordion(props) {
  // internalIsOpen is a state variable, setInternalIsOpen is the function to update it
  // useState is a React Hook that lets you add React state to function components
  const [internalIsOpen, setInternalIsOpen] = useState(
    props.defaultOpen || false
  );

  // useEffect is a React Hook that lets you perform side effects in function components
  // Here it's used to update internalIsOpen when props.isOpen changes
  useEffect(() => {
    if (props.isOpen !== undefined) {
      setInternalIsOpen(props.isOpen);
    }
  }, [props.isOpen]);

  // handleToggle is a function that toggles the internalIsOpen state
  // and calls the onToggle prop if it's provided
  const handleToggle = () => {
    setInternalIsOpen(!internalIsOpen);
    if (props.onToggle) {
      props.onToggle(!internalIsOpen);
    }
  };

  // The return statement defines the JSX that will be rendered by the component
  return (
    <div className={`accordionWrapper ${props.className}`}>
      <button
        {...(props.className.indexOf('accordion') !== -1 && { id: props.className.replace("accordion", "accordion_phase")})}
        className={`accordionTitle ${
          internalIsOpen ? "accordionTitleOpen" : ""
        }`}
        onClick={handleToggle}
        style={{ backgroundColor: props.color }}
      >
        {props.title}
      </button>

      <div
        className={`accordionContent ${
          internalIsOpen ? "accordionContentOpen" : "accordionContentClose"
        }`}
      >
        {props.children}
      </div>
    </div>
  );
}

// PropTypes is used for type checking in React
// It checks that the props passed to the component are of the correct type
Accordion.propTypes = {
  defaultOpen: PropTypes.bool,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
