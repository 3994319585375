import React from "react";
import { useState, useRef, useEffect } from "react";
import {webConfig} from '../configs/webConfig'
import "../css/Website.css";
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/Websites_Phases_SVG/Websites_Phase_1.svg";
import { ReactComponent as Phase2 } from "../SVG/Websites_Phases_SVG/Websites_Phase_2.svg";
import { ReactComponent as Phase3 } from "../SVG/Websites_Phases_SVG/Websites_Phase_3.svg";
import { ReactComponent as Phase4 } from "../SVG/Websites_Phases_SVG/Websites_Phase_4.svg";
import { ReactComponent as Phase5 } from "../SVG/Websites_Phases_SVG/Websites_Phase_5.svg";

const Website = () => {
  const [isPhase2Open, setIsPhase2Open] = useState();
  const [isPhase3Open, setIsPhase3Open] = useState();

  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("CET");

  const svgRef2 = useRef();
  const [P2visibleSection, setP2visibleSection] = useState("Briefing");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("Initiate");

  const svgRef4 = useRef();
  const [P4visibleSection, setP4visibleSection] = useState("PeriodicWebAssesment");

  const svgRef5 = useRef();
  const [P5visibleSection, setP5visibleSection] = useState("Gatekeeper");

  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");

    rect1.addEventListener("click", () => {
      setP1visibleSection("CET");
    });

    rect2.addEventListener("click", () => {
      setP1visibleSection("Pre-Veeva");
    });
  }, []);

  useEffect(() => {
    const svg = svgRef2.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const diam1 = svg.getElementById("diamond1");
    const rect3 = svg.getElementById("rect3");

    rect1.addEventListener("click", () => {
      setP2visibleSection("Briefing");
    });

    rect2.addEventListener("click", () => {
      setP2visibleSection("Artwork");
    });

    diam1.addEventListener("click", () => {
      setP2visibleSection("Expectations");
    });
    const handleScrollP3 = () => {
      const elementToScrollTo = document.getElementById("accordion3");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect3.addEventListener("click", () => {
      handleScrollP3();
      setIsPhase3Open(true);
    });
  }, [isPhase3Open]);

  useEffect(() => {
    const svg = svgRef4.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");

    rect1.addEventListener("click", () => {
      setP4visibleSection("PeriodicWebAssesment");
    });

    rect2.addEventListener("click", () => {
      setP4visibleSection("UpdateOfMaterial");
    });

    rect3.addEventListener("click", () => {
      setP4visibleSection("RetirePage");
    });
    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y = elementToScrollTo.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect4.addEventListener("click", () => {
      handleScrollP2();
      setIsPhase2Open(true);
    });
  }, [isPhase2Open]);

  useEffect(() => {
    const svg = svgRef3.current;
    const rect1 = svg.getElementById("rect1");
    const diam1 = svg.getElementById("diamond1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const diam2 = svg.getElementById("diamond2");
    const rect4 = svg.getElementById("rect4");
    const diam3 = svg.getElementById("diamond3");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    const rect8 = svg.getElementById("rect8");
    const rect9 = svg.getElementById("rect9");

    rect1.addEventListener("click", () => {
      setP3visibleSection("Initiate");
    });

    diam1.addEventListener("click", () => {
      setP3visibleSection("MlrVerdict");
    });

    rect2.addEventListener("click", () => {
      setP3visibleSection("ConfirmProduction");
    });

    rect3.addEventListener("click", () => {
      setP3visibleSection("TestLinkRecived");
    });

    diam2.addEventListener("click", () => {
      setP3visibleSection("TestLinkMeetExpation");
    });

    rect4.addEventListener("click", () => {
      setP3visibleSection("InitiateCertification");
    });

    diam3.addEventListener("click", () => {
      setP3visibleSection("CertificationVerdict");
    });

    rect5.addEventListener("click", () => {
      setP3visibleSection("FinalSteps");
    });

    rect6.addEventListener("click", () => {
      setP3visibleSection("AdviseDelivey");
    });

    rect7.addEventListener("click", () => {
      setP3visibleSection("PostGoLiveSteps");
    });

    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y = elementToScrollTo.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect8.addEventListener("click", () => {
      handleScrollP2();
    });
    rect9.addEventListener("click", () => {
      handleScrollP2();
    });
    
  }, []);

  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Websites 
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">Websites </h2>
            <p>
              <em>
               For this document and process detail, the term ‘Website’ applies to Lilly owned Websites, Webpages and Web Tools. Each Lilly controlled website, webpage and web tool must have a Lilly employee designated as its Owner. The Website Owner is the accountable person for the website. Please note that a Website Owner is different from a Material Owner. The Website Owner may be a Lilly employee ranging from a brand associate, a marketer, or any other job title, depending on the country. The Material Owner will partner with a Website Custodian. A Website Custodian is the accountable person for the TECH@LILLY requirements of the website, webpage or web tool. The role of a Custodian may be played by different teams, for example the Custodian for Enterprise Web Initiative (EWI) websites is from the global EWI team, the Custodian for locally hosted pages is from the local TECH@LILLY team.   
              </em>
            </p>
            <p><em>A website that is not carefully designed, tested, maintained and assessed poses a serious external risk to Lilly.</em></p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h4 className="comman_hd">Terms Used : </h4>
            <ul className="ul_padding termsContainer">
                <li className="pb-2">
                     <strong>Website Owner </strong> and <strong>Website Custodian </strong> are designated roles that are identified for each website and are listed in the Lilly Inventory Management Platform. Scroll down to understand the roles and responsibilities of a Website Owner and a Website Custodian. 
                </li>
                <li className="pb-2">
                     <strong>Delivery Manager </strong> – Depending on the website/web platforms, there are different teams involved in the production of the asset. The term <strong>‘Delivery Manager’</strong> is used in this document as a generic role to cover individuals/teams that are working on producing the website, webpage or web tool. For example, EWI, TECH@LILLY, or an agency such as TCS could be the support team that helps develop the webpage/website. They are called as Delivery Manager in this document. Scroll down to see who the Delivery Teams/Managers for different website types are. 
                     
                </li>
                <li>
                     <strong>Tickets</strong> – There are different ways to reach different delivery teams. A form needs to be filled in most of the cases, and this is called as creating a ticket. Scroll up to see Website Types, Delivery Teams and Request Forms to access the link to these forms to create a ticket for the desired work.  
                </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active navlinksize"
              data-bs-toggle="tab"
              href="#processPreRequisites"
            >
              Process
              <br />
              Pre-requisites
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navlinksize" data-bs-toggle="tab" href="#vvpmWebsiteStandards">
              VVPM Website Standards
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navlinksize" data-bs-toggle="tab" href="#websiteTypes">
               Website 
               <br />
               Types
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navlinksize" data-bs-toggle="tab" href="#websiteChecklist">
               Website <br />
               Checklist 
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navlinksize" data-bs-toggle="tab" href="#websiteMonitoring ">
               Website 
               <br />
               Monitoring 
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navlinksize" data-bs-toggle="tab" href="#patientSafetyConsiderations">
                Patient Safety Considerations 
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navlinksize" data-bs-toggle="tab" href="#copyrightContent">
                Copyright
                <br />
            Content  
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link navlinksize" data-bs-toggle="tab" href="#metatagsAndSeo">
                Metatags 
                <br />
                And SEO  
            </a>
          </li>
        </ul>
        
        <div id="tabContent" className="tab-content">
           <div id="processPreRequisites" className="tab-pane fade in active tabs_box tabContent">
                <p className="tabsContentHeading">Process Pre-requisites</p>
                <div>
                    <p>Stakeholder involvement – The process requires involving the TECH@LILLY Manager. Additional stakeholders are needed when making major changes either to content or wireframe of the website.</p>
                    <ul className="ul_padding">
                    <li>Review of content and approval.</li>
                    <li>Consider patient safety and reach out to global patient safety (GPS) for review.</li>
                    <li>Review the website checklist by scrolling up.</li>
                    </ul>
                </div>
                
                <div>
                    <ul className="ul_padding">
                      <li>The process requires technical training :</li>
                        <ul className="ul_padding">
                          <li>
                            All individuals working with EWI websites should take the {" "}
                            <a href={webConfig.GCOGlobalWebsiteOwnerTrainingforWebsites} target="_blank">
                                GCO: Global Website Owner Training for Websites, Webpages and Tools COURSE 9204719  
                            </a>{" "}
                            training. For a Website Owner, this is a mandatory training to be taken annually (prompted by global Sustain and Control team).  
                          </li>
                          <li>9214548 rev.1 (EC-PROC: Websites, Webpages and Tools).</li>
                        </ul>  
                      <li>Know about different types of Websites and Webpages – See the details by scrolling up to see Different Website Types, Delivery Teams and Request Forms. </li>
                    </ul>
                </div>
            </div>

            <div id="vvpmWebsiteStandards" className="tab-pane fade tabs_box tabContent">
                <p className="tabsContentHeading">Veeva Approval Standard Practices for Websites </p>
                <p className="tabsContentHeading">Piece:</p>
                <ul>
                <li className="mt-1">
                     <strong className="text-decoration-underline">For UK, IRELAND</strong> – Desktop and mobile site screenshots of all pages including functionality (in the development stage). If tabled view is different from desktop, then we also need to include screenshots for tablet- if tablet view is the same as desktop view, then the job bag metadata purpose field needs to state the desktop view is the same as tablet view.
                      <br />
                      <strong>If final forms are different</strong> (including colours, font styles, animations etc) then a separate 	job bag number is required for desktop and mobile version (or tablet, if applicable). Include a screenshot or word document of the website metadata as part of the Veeva job bag which can be seen in Google by the public (i.e. as part of the website materials, not as an attachment).
                </li>
                <li className="mt-1">
                  Please discuss with your final medical signatory how a specific website should be segmented and approved (e.g framework, pop-ups, specific content pages).
                </li>
                <li className="mt-1">
                  When marking up the artwork, please indicate hyperlinks and respective URLs. 
                </li>
                <li className="mt-2">
                     <strong className= "text-decoration-underline">For Nordics </strong> – Screenshots from desktop version are sufficient (screenshots of mobile version are not needed as the final form for certification is not a requirement).
                </li>
                </ul>
                <p><strong>Purpose Field:</strong></p>
                <ul>
                <li>
                 Explain the purpose.  
                </li>
                <li>
                     Provide link to the test site with a username and password included in the Purpose field. 
                </li>
                <li>
                 Provide Contract ID under Purpose (if applicable). If healthcare professional (HCP) contract ID is added, then PMC documents for Audio-Visual (AV) consent do not need to be added.  Under Details, choose PMC documents attached as ‘No’.   
                </li>
                </ul>
                <p className="tabsContentHeading">Attachments:</p>
                <ul>
                <li>
                     Metatag information, i.e., screenshot of how the website keyword search appears on google.  
                </li>
                <li>
                     <strong className= "text-decoration-underline">FOR UK </strong> - SEO or paid keyword promotion is not permissible.  
                </li>
                <li>
                     <strong className= "text-decoration-underline">FOR NORDICS </strong> - SEO or paid keyword promotion is permissible but must be approved Change the Control Document (if applicable).   
                </li>
                <li>
                     <strong className= "text-decoration-underline">FOR UK AND ROI</strong> – Mobile site and tablet site screenshots (if it is the same as the final form). 
                </li>
                <li>
                     Email from the GPS team confirming review and/or GPS checklist. This is a Lilly Web procedure requirement and hence applicable to all countries.   
                </li>
                <li>
                     If it is a new website or a major functionality change, then the User Accessibility Test (UAT) checklist is needed.  
                </li>
                <li>
                     Material Originators are responsible to check if the content matches the certified final form version from Veeva when the website goes live. This confirmation email must be uploaded on Veeva <strong>immediately</strong> post ‘Go-Live’.  
                </li>
                <li>
                     Method of dissemination details: Please include URL of the website.
                </li>
                </ul>
                <p className="tabsContentHeading">Related Permission Managed Content</p>
                <ul>
                <li>
                     Consent documents for Permission Managed Content (PMC). 
                </li>
                <li>
                     If HCP contract ID is added to Purpose, then no PMC documents need to be added. Under Details, choose PMC documents attached as ‘No’. 
                </li>
                </ul>
                <p className="tabsContentHeading">
                Related Pieces: 
                </p>
                <ul>
                <li>
                     Include existing and related approved assets/materials. 
                </li>
                </ul>
                <p className="tabsContentHeading">Linked Documents:</p>
                <ul>
                <li> <strong className= "text-decoration-underline">FOR UK AND ROI</strong> - Include current PI if PI is on website rather than just a PI hyperlink.</li>
                <li> Content references</li>
                </ul>
                <p className="tabsContentHeading">
                Final Form:  
                </p>
                <ul>
                <li>
                     Working test site with full functionality  
                </li>
                <li>
                     Material Originators are responsible to check if the content matches the certified final form version from Veeva when the website goes live. <strong>The material administrator initiates a withdrawal,</strong> his confirmation email must be uploaded on Veeva post ‘Go-Live’. 
                </li>
                </ul>
            </div>

            <div id="websiteTypes" className="tab-pane fade tabs_box tabContent">
                <p className="tabsContentHeading">
                    Different Website Types, Delivery Teams and Request Forms 
                </p>
                <p>
                    Individuals from different teams will work on different types of websites. The term <strong>‘Delivery Manager’</strong>  is used in this document as a generic role performed by an individual working on technical development of the site. This would be the support team that helps develop the webpage/website. Here is the list of 'responsible' functions for different website types.  
                </p>
                <br />
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Website Type</th>
                        <th>Website Owner</th>
                        <th>Custodian</th>
                        <th>Delivery Team/Agency</th>
                        <th>Link to Request Form (Tickets)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>EWI Site for products</td>
                        <td>Brand or Functional Leader</td>
                        <td>Key Account Manager (KAM) from EWI team</td>
                        <td>EWI</td>
                        <td><a href={webConfig.LillyClarity} target="_blank">Lilly | Clarity</a></td>
                        </tr>
                        <tr>
                        <td>Corporate website</td>
                        <td>Corporate or Country Leader</td>
                        <td>EWI Development Lead</td>
                        <td>TECH@LILLY/local webmasters</td>
                        <td>
                          <a 
                            href={webConfig.NEHubCorporateWebsiteChangeRequest}
                            target="_blank"
                          >
                          NE Hub Corporate Website Change Request (office.com)</a></td>
                        </tr>
                        <tr>
                        <td>Lilly campaign pages </td>
                        <td>Local TECH@LILLY Manager </td>
                        <td>Local TECH@LILLY Champion </td>
                        <td>TECH@LILLY/TCS</td>
                        <td>
                          <a 
                            href={webConfig.ContentHostingRequestForm}
                            target="_blank"
                          >
                          Content Hosting Request Form (office.com)</a></td>
                        </tr>
                        <tr>
                        <td>Digital publishing</td>
                        <td>Brand or Functional Leader</td>
                        <td>Veeva item Owner</td>
                        <td>Veeva item Owner</td>
                        <td>Direct contact to Veeva item Owner </td>
                        </tr>
                        <tr>
                        <td>SFMC Cloud pages</td>
                        <td>Brand or Functional Leader</td>
                        <td>Local TECH@LILLY Champion</td>
                        <td>Digital Solutions Team (DST)/TECH@LILLY </td>
                        <td>Reach out to DST</td>
                        </tr>
                        <tr>
                        <td>Custom Websites/Web Tools</td>
                        <td>Non-IT Lilly Employee</td>
                        <td>IT Lilly Employee</td>
                        <td>Situational</td>
                        <td></td> 
                        </tr>
                    </tbody>
                    </table>
            </div>

            <div id="websiteChecklist" className="tab-pane fade tabs_box tabContent">
                <p className="tabsContentHeading">
                   Website Checklist  
                </p>
                <br />
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Checklist Items</th>
                        <th>Checks to be Made</th>
                        <th>Help Extended by:</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Privacy policy</td>
                        <td>Must be available in each page</td>
                        <td>
                            Consult local TECH@LILLY for most up-to-date policy link.Consult E&C if specific changes to the privacy policy wording are needed 
                        </td>
                        </tr>
                        <tr>
                        <td>Copyright</td>
                        <td>Must be available in each page</td>
                        <td>Consult local TECH@LILLY for most up-to-date policy link.</td>
                        </tr>
                        <tr>
                        <td>Terms of use</td>
                        <td>Must be available in each page. Can be a part of copyright statement.</td>
                        <td>Consult local TECH@LILLY for most up-to-date policy link.</td>
                        </tr>
                        <tr>
                        <td>Age disclaimer</td>
                        <td>Not needed for HCP facing website, but must be available on each page, when applicable</td>
                        <td>Consult local TECH@LILLY for most up-to-date policy link.</td>
                        </tr>
                        <tr>
                        <td>Target Audience disclaimer</td>
                        <td>Must be available at the entry point or landing page of the site</td>
                        <td></td>
                        </tr>
                        <tr>
                        <td>Cookie banner</td>
                        <td>Must be available at the entry point or landing page of the site</td>
                        <td></td>
                        </tr>
                        <tr>
                        <td>GPS review</td>
                        <td>
                             Needed for:  
                            <ul className="ul_list">
                            <li>- major functionality, content and claim changes.</li>
                            <li>- Interactivities such as free text field, chat box, and link to social media sites.</li>
                            </ul>
                        </td>
                        <td>Consult with the GPS Manager</td>
                        </tr>
                        <tr>
                        <td>Site Business Owner’s review</td>
                        <td>
                             Needed for:  
                            <ul className="ul_list">
                            <li>- major functionality, content and claim changes.</li>
                            <li>- Interactivities such as free text field, chat box, and link to social media sites</li>
                            </ul>
                        </td>
                        <td>Consult with the Business Owner of the website</td>
                        </tr>
                        <tr>
                        <td>Monitoring plan</td>
                        <td>Needed when website provides an email address or free text fields and chat box</td>
                        <td>Consult with the GPS Manager</td>
                        </tr>
                        <tr>
                        <td>PIA</td>
                        <td>Needed if PI is being collected from users</td>
                        <td>Consult with E&C and GPO, and complete the assessment form</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div id="websiteMonitoring" className="tab-pane fade tabs_box tabContent">
                <p className="tabsContentHeading">
                   Website Monitoring Activities and Who is Responsible   
                </p>
                <p>Below is the list of all the reviews/submissions related to website monitoring. Website Owner will partner with NE Hub TECH@LILLY to perform the following checks.</p>
                <br />
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Type of Monitoring</th>
                        <th>Frequency</th>
                        <th>Accountability of</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Monitor for adverse events and product complaints (AEs/PCs) if there are Open Text fields, Contact Us fields, and email address</td>
                        <td>Once per business day</td>
                        <td>Website Owner</td>
                        </tr>
                        <tr>
                        <td>Pro-active review of content accuracy and relevancy</td>
                        <td>Once per quarter</td>
                        <td>Website Owner</td>
                        </tr>
                        <tr>
                        <td>Pro-active review of Veeva metadata for website (for example, if all Related Pieces are linked, Inventory Information is up-to-date and Audience is still relevant)  </td>
                        <td>Once per quarter</td>
                        <td>Website Owner</td>
                        </tr>
                        <tr>
                        <td>Pro-active review of functional links and footer policy links</td>
                        <td>Once per quarter</td>
                        <td>Website Custodian/Local TECH@LILLY Manager</td>
                        </tr>
                        <tr>
                        <td>When site is audited by the IBU monitoring team, provide answers and resolutions within defined timelines </td>
                        <td>As and when the site is monitored</td>
                        <td>Website Owner</td>
                        </tr>
                        <tr>
                        <td>Change the website copyright to the current year (provided websites’ content was updated)  </td>
                        <td>Annually</td>
                        <td>Website Custodian/TECH@LILLY Manager</td>
                        </tr>
                        <tr>
                        <td>Annual Sustain and Control Audit (Complete annual training and follow instructions as advised by S&C team) </td>
                        <td>Annually</td>
                        <td>Website Owner and website control</td>
                        </tr>
                        <tr>
                        <td>Submit website Inventory list Pharmacovigilance System Master File (<strong>PSMF</strong>) and details to Pharmacovigilance (PhV) team </td>
                        <td>Quarterly</td>
                        <td>TECH@LILLY Manager</td>
                        </tr>
                        <tr>
                        <td><strong className="text-decoration-underline">FOR NORWAY ONLY</strong> - Local code requires list of all websites and web platforms to be submitted by pharma companies to the authorities every 6 months </td>
                        <td>Bi-annually (typically in April and October)</td>
                        <td>TECH@LILLY Manager creates the list of web inventory; Local Brand Associate submits the list to local authorities</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div id="patientSafetyConsiderations" className="tab-pane fade tabs_box tabContent">
                <p className="tabsContentHeading">Consider Patient Safety   </p>
                <p>Examine if the content update has patient safety considerations. These could be (but not limited to) major content/claim updates, adding new product or indication, products with black triangle, open text fields, interactivity or a new website. </p>
                <p>If the content update has patient safety considerations, then the GPS review will be needed. Depending on the significance of the update, the GPS Manager will decide the level of scrutiny needed for the review. For example, the GPS Manager might decide to complete a checklist that needs to be uploaded on Veeva or might review changes on a call and provide ‘ok’ via email.  </p>
                <p>If the content update has no patient safety considerations, then initiate MLR without involving the GPS. </p>
                <p className="tabsContentHeading">REMEMBER  </p>
                <p>The document must be uploaded to Veeva, whether GPS completes the checklist or provides their approval via email. </p>
            </div>

            <div id="copyrightContent" className="tab-pane fade tabs_box tabContent">
                <p className="tabsContentHeading">Consider Content Subject to Copyright   </p>
                <p>If the site includes any material or content subjected to copyright which is derived from or provided by an external party, obtain (or confirm) permission to use it. </p>
                <p>Any material and/or content that has a copyright and/or is provided by an external party, should be included as supporting documentation in VVPM. Documented consent from any individuals providing testimonials, videos, photos or other recordings must be accessible in VVPM and retrievable in the event of an audit or other discoverable activities.  </p>
                <p>This content may include, but is not limited to    </p>
                <ul>
                    <li>  Images </li>
                    <li>  Graphics  </li>
                    <li>  Audios </li>
                    <li>  Videos </li>
                    <li>  Statements </li>
                </ul>
            </div>

            <div id="metatagsAndSeo" className="tab-pane fade tabs_box tabContent">
                <p className="tabsContentHeading">Metatags and SEO   </p>
                <p>Metatags are on search engines such as Google. These are snippets of text that describe a page's content; the metatags do not appear on the page itself, but only in the page's source code.</p>
                <p>Implement metatags purposefully and get them approved. If the Material Owner does not implement metatags for the site, search engine will formulate them, which could pose risk for both non-promotional and promotional sites. </p>
                <img src="images/metatag_seo.png" className="p-2 web-image" />
                <p>SEO stands for Search Engine Optimisation. In simple terms, it is the process of improving the site to increase its visibility when people search for products or services related to business on Google, Bing and other search engines. Web designers complete SEO to ensure site’s high-ranking placement in the results page of a search engine.     </p>
                <p>Address SEO in the website’s design. SEO is completed by the Global Search Capability SEO team in Lilly. </p>
            </div>
        </div>
      </div>

     
      {/*Add verticle Tab*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
            <button
              className="nav-link"
              id="v-pills-faqs-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-faqs"
              type="button"
              role="tab"
              aria-controls="v-pills-faqs"
              aria-selected="false"
            >
              FAQs
            </button>
          </div>


          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">Websites Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Planning"
                    defaultOpen={true}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-4 planning">
                        <div className="comman_width_e">
                          <Phase1 ref={svgRef1} className="webShape1" />
                        </div>
                      </div>
                      <div className="col-lg-8 planning p_font pt-3 flowsSecondPart">
                        <div
                          id="campaign_listed_cet"
                          style={{
                            display:
                              P1visibleSection === "CET" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">CAMPAIGN LISTED ON CET</p>
                          <p className="flowsContent">
                            Campaigns should be listed on CET as part of IPA
                            process. Check if this material is part of your
                            plans, review the set KPIs and tactic links on CET.{" "}
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "Pre-Veeva"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">PRE-VEEVA MEETING</p>
                          <p className="flowsContent">
                            Planning is everything! The core objectives of a
                            pre-Veeva discussion should not be overlooked. These
                            are to ensure that:
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               The reviewer is never presented with a
                              material in Veeva Vault PromoMats (VVPM) that they have no background
                              information on.
                            </li>
                            <li className="flowsContent">
                               The review team understand its current
                              priorities for review.
                            </li>
                          </ul>
                          <p className="flowsContent">Together these aim to
                              provide a smoother and quicker approval process
                              for materials.</p>
                          <p className="flowsContent">Visit {" "}
                              <a href={webConfig.LillyPlanning} target="_blank">
                               Planning (lilly.com)
                              </a>{" "}
                              to learn more about pre-Veeva meetings.</p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion2" />
                  <Accordion
                    className="accordion2"
                    title="Phase 2: Material Readiness"
                    defaultOpen={false}
                    isOpen={isPhase2Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                        <div className="comman_width">
                          <Phase2 ref={svgRef2} className="webShape2" />
                        </div>
                      </div>
                      <div className="col-lg-7 planning pt-4 flowsSecondPart">
                        <div
                          id="briefing"
                          style={{
                            display:
                              P2visibleSection === "Briefing"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">BRIEFING</p>
                          <p
                            className="pop flowsContent"
                            style={{ marginBottom: "0px" }}
                          >
                            Ensure that the team working on material development
                            fully understands the brief. Often a call is best
                            way to move things forward quickly. A good brief to
                            the agency means that the Material Administrator
                            provides all necessary visuals, texts and links
                            upfront. Briefing could be completed by the Material
                            Administrator. The creative agency should advise on
                            delivery timelines after the briefing is received.
                          </p>
                          <p className="flowsHeading briefingRemember">
                            Remember
                          </p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li className="flowsContent">
                                 <strong>The Key Account Manager (KAM)</strong> from <strong>EWI</strong> team will be the key contact to deliver on the brief. A request needs to be initiated by raising a ticket through the{" "}
                                <a href={webConfig.LillyClarity} target="_blank">{" "}Clarity</a> platform.
                            </li>
                            <li className="flowsContent">
                                 Once the Clarity request is received, <strong>KAM</strong> will be in touch to fully understand the scope and seek clarifications.
                            </li>
                            <li className="flowsContent">
                               If significant updates are required to the EWI site content and wireframe, the Website Owner should be informed. 
                                <ul className="ul_margin_bottom">
                                    <li className="flowsContent">
                                        <strong>Why do this? {" "}</strong>
                                        <br />
                                        Lilly has one designated Owner for each site. A Website Owner receives thorough training on Lilly’s website processes and policies. Critical mistakes have been made in the past as the Tactic Coordinator asked for updates that were not compliant with the procedures. If the brief poses any risk, the Website Owner will help understand it.
                                    </li>
                                </ul>
                            </li>
                            </ul>
                        </div>
                        <div
                          id="artwork_delivered"
                          style={{
                            display:
                              P2visibleSection === "Artwork" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">ARTWORK DELIVERED</p>
                          <p className="flowsContent">
                          It is the responsibility of the Material Administrator to review the artwork provided for accuracy before submitting to Medical, Legal, Regulatory (MLR) for approval. At this stage, it is best practice to align with reviewers before submitting the material to MLR.
                          </p>
                          <p className="flowsHeading briefingRemember">
                            Remember
                          </p>
                          <ul className="ul_padding">
                            <li className="flowsContent">
                              Artwork should be delivered within the existing/agreed wireframe of the website.
                            </li>
                          </ul>
                        </div>
                        <div
                          id="meets_expectations"
                          style={{
                            display:
                              P2visibleSection === "Expectations"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            ARTWORK MEETS EXPECTATIONS?
                          </p>
                          <p className="flowsContent">If the artwork meets expectations, then initiate MLR. If the artwork does not meet expectations, then it is always advised to book a call with the agency to go through the changes required. (Back-and-forth through email communication has proven to be time consuming!)</p>
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  <div id="accordion3" />
                  <Accordion
                    className="accordion3"
                    title="Phase 3: Material Approval & Launch"
                    defaultOpen={false}
                    isOpen={isPhase3Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-8 planning main-content">
                        <Phase3 ref={svgRef3} className="webShape3" />
                      </div>
                      <div className="col-lg-4 planning flowsSecondPart side-content ">
                        <div
                          id="link4"
                          style={{
                            display:
                              P3visibleSection === "Initiate"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">INITIATE MLR APPROVAL</p>
                          <p className="flowsContent">
                            This is the stage where the Veeva Coordinator uploads the material to VVPM for reviewers to provide a verdict on it. Click to refer to the {" "} 
                            <a
                              href={webConfig.PMAPJobAid}
                              target="_blank"
                            >{" "} PMAP job aid.</a>
                             
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Contact the Global Patient Safety team if it includes elements related to patient safety (for example, open text field and interactivity). Scroll up to see Patient Safety Considerations.  
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link5"
                          style={{
                            display:
                              P3visibleSection === "MlrVerdict"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">MLR VERDICT</p>
                          <p className="flowsContent">
                            There are 3 types of verdicts that the material
                            could get:{" "}
                          </p>
                          <p className="flowsContent">
                            <strong>1. Approved for Production</strong> – The document has been blessed by the reviewer. Go ahead and ask for the asset to be developed into HTML or another final format. 
                          </p>
                          <p className="flowsContent">
                            <strong>2. Approved with changes</strong> – The document has some minor errors/updates required (like spelling, grammatical errors). Go ahead and ask for the asset to be developed into HTML or another final format, but remember to incorporate the advised updates/corrections on the document. 
                          </p>
                          <p className="flowsContent">
                            <strong>3. Revise and Resubmit</strong> –  The document has too many problems and the reviewer advises the Material Coordinator to rework on it and submit again. Go ahead and talk to the agency to re-do the artwork.  
                          </p>
                        </div>
                        <div
                          id="link6"
                          style={{
                            display:
                              P3visibleSection === "ConfirmProduction"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Confirm Production</p>
                          <p className="flowsContent">
                             Inform the Delivery Manager that the PDF can now be developed into HTML or to another format that will be live to the Audience. 
                          </p>
                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">
                          The delivery timeline may differ depending on the changes being asked. Agree on timelines in advance to avoid surprises. 
                          </p>
                        </div>
                        <div
                          id="link6"
                          style={{
                            display:
                              P3visibleSection === "TestLinkRecived"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Test Link Received </p>
                          <p className="flowsContent">
                          This is usually the HTML version of the webpage/website, that once certified, will appear live to the Audience. Some applications, such as Veeva Vault may not support HTML text but only plain text or PDF. The Delivery Manager will provide the test link as well as the screenshot package for all pages that are to be uploaded on Veeva for certification.
                          </p>
                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">
                          It is the responsibility of the Material Coordinator/Owner to ensure that the test link not only has the necessary updates but also includes links to important policies within the footer (for example, privacy policy, copyright, accessibility statement, terms of use, etc.). Scroll up to refer to the checklist on website requirements. 
                          </p>
                        </div>
                        <div
                          id="link6"
                          style={{
                            display:
                              P3visibleSection === "TestLinkMeetExpation"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Test Link Meets Expectation   </p>
                          <p className="flowsContent">
                          Whether launching a new website or making changes to an existing one, it must be tested before it goes live or is re-launched.  
                          </p>

                          <p className="flowsContent">Test the website to: </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Review all company-controlled content in all applicable languages, in context with its placement and appearance as it would be in the production-ready version. 
                            </li>
                            <li className="flowsContent">Ensure accuracy. </li>
                            <li className="flowsContent">
                               Ensure that the content renders and functions as expected on all relevant browsers and devices. 
                            </li>
                            <li className="flowsContent">
                               Verify if all website, privacy and compliance requirements have been met.  
                            </li>
                            <li className="flowsContent">
                               Check if all footer links to policy are correct and functional.  
                            </li>
                          </ul>
                          <p className="flowsContent">Additionally:  </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Contact GPS if it includes elements related to patient safety (for example, open text field and interactivity). Scroll up to see Patient Safety Considerations.  
                            </li>
                            <li className="flowsContent">
                               Include metatag and Search Engine Optimisation (SEO) for Veeva approval (if used). Scroll up to see to learn more about Metatags and SEO. 
                            </li>
                          </ul>
                          <p className="flowsHeading">Remember</p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li className="flowsContent">
                               The Website has several items on the checklist.  Scroll up to see Metatags and SEO to review the complete checklist. 
                            </li>
                            <li>
                               Upload the testing and review the checklist on Veeva. 
                            </li>
                          </ul>
                        </div>

                        <div
                          id="link7"
                          style={{
                            display:
                              P3visibleSection === "InitiateCertification"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">INITIATE CERTIFICATION</p>
                          <p className="flowsContent">
                             This is the stage where the Veeva Coordinator uploads the final form of the material in VVPM for Certifiers to provide verdict on the material. Before initiating certification, the Material Administrator must review the elements marked on the checklist dedicated to website. Scroll up to see the website checklist. The checklist does not need to be uploaded on VVPM. 
                          </p>
                        </div>
                        <div
                          id="link8"
                          style={{
                            display:
                              P3visibleSection === "CertificationVerdict"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CERTIFICATION VERDICT</p>
                          <p className="flowsContent">
                            There are 3 types of verdicts you could get:
                          </p>
                          <p className="flowsContent">
                            <strong>1. Certified</strong> – The material is Approved for Distribution now. Go ahead to review/set the Inventory Information in VVPM and set the{" "}
                            <strong>Actual Date of First Use</strong>.{" "}
                          </p>
                          <p className="flowsContent">
                            <strong>2. Revise and Resubmit</strong> (for
                            certification) – The material has errors and/or requires updates. Go ahead and ask the agency/team to make the advised changes, trigger a new test link and re-submit for certification. 
                          </p>
                          <p className="flowsContent">
                            <strong>3. Not Certified</strong> – The material has too many problems and the Certifier advises the Material Administrator to rework on it and start a new MLR workflow. Go ahead and ask the agency to re-do the artwork.
                          </p>
                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">
                            The verdict <strong>‘Certified’</strong> does not
                            mean that the material is now live. Additional steps
                            are needed to move it to the environment where it
                            will be available to the end user.
                          </p>
                        </div>
                        <div
                          id="link9"
                          style={{
                            display:
                              P3visibleSection === "FinalSteps"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            FINAL STEPS BEFORE 'GO-LIVE'
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               <strong>Actual Date of First Use</strong> is set by the Veeva Coordinator and is typically put as the date the material has been certified in VVPM, unless there is a specific date of when the usage of a material will start (for example, launch date, after the salesforce training etc.) 
                            </li>
                            <li className="flowsContent">
                              <strong> Inventory Information</strong> – Veeva Coordinators would have added this earlier to VVPM metadata and it should be checked when the material goes live. This step supports efficient material management and withdrawal.{" "}
                            </li>
                            <li className="flowsContent">
                               <strong>Expiration Date</strong> is usually set for 2 years. However, if the material is a One-time use or for a defined time, set the expiry date accordingly. 
                            </li>
                            <li className="flowsContent">
                               <strong>Related Pieces</strong> -  Veeva Coordinator should check that any relevant Related Pieces are linked to the VVPM job bag.
                            </li>
                          </ul>
                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">
                            <strong>NORDIC COUNTRIES :</strong> Please check the{" "} 
                               <a
                                className="CoreURL"
                                href={webConfig.NordicPMAPGuidance}
                                target="_blank"
                              >
                                Nordic PMAP Guidance
                              </a>
                              {" "}for detailed information on statutory copy submission. 
                          </p>
                        </div>
                        <div
                          id="link9"
                          style={{
                            display:
                              P3visibleSection === "AdviseDelivey"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            Advise Delivery Manager to ‘Go-Live’ 
                          </p>
                          <p className="flowsContent">
                          <strong>For Minor text updates</strong> – advise the team to push the changes to the live site. 
                          </p>
                          <p className="flowsContent">
                          <strong>For Major functionality and system changes </strong> – the Material Administrator must document that the website was tested, reviewed and approved prior to going live. The Global Procedure on Websites, Webpages and Tools highlights this as a mandatory requirement. The Websites, Webpages or Tools testing and review checklist is completed by the System Custodian. Ask them to provide the checklist and upload this on VVPM. 
                          </p>
                        </div>

                        <div
                          id="link9"
                          style={{
                            display:
                              P3visibleSection === "PostGoLiveSteps"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                             Post Go-Live Steps
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Review the content on the live site for accuracy to ensure that the published content exactly matches the approved content. 
                            </li>
                            <li className="flowsContent">
                               Confirm to Delivery Manager that the ticket can be closed. The Veeva Coordinator should upload this confirmation email on VVPM. 
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Accordion>

                  {/*Accordion4*/}
                  <div id="accordion4" />
                  <Accordion
                    className="accordion4"
                    title="Phase 4: Material Management"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning main-content">
                        <Phase4 ref={svgRef4} className="webShape4" />
                      </div>
                      <div className="col-lg-6 planning p-3 flowsSecondPart side-content">
                        <div
                          style={{
                            display:
                              P4visibleSection === "PeriodicWebAssesment"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                             Periodic Website Assessment 
                          </p>
                          <p className="flowsContent">The designated Website Owner is accountable for website maintenance and change.</p>
                          <p className="flowsContent">Lilly websites are periodically reviewed by GCP or Ethics and Compliance (E&C) to confirm that: </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               The Website Owner and the Website Custodian are accurately listed. 
                            </li>
                            <li className="flowsContent">
                               The website content is current and is approved. 
                            </li>
                            <li className="flowsContent">
                               The website meets the performance measurements and objectives.  
                            </li>
                            <li className="flowsContent">
                               The website remains compliant with the Global Procedures on Websites, Webpages, and Tools. 
                            </li>
                          </ul>
                          <p className="flowsContent">
                          Additionally, the TECH@LILLY Manager in NE Hub triggers Controlled Self-Assessment (CSA) advising selected Website Owners to perform a thorough assessment of their website. Scroll up to see Website Monitoring Activities and Who is Responsible to see the list of all monitoring activities across NE Hub. 
                          </p>
                        </div>
                         <div
                          id="link160"
                          style={{
                            display:
                              P4visibleSection === "UpdateOfMaterial"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">UPDATE OF MATERIAL</p>
                          <p className="flowsContent">
                          There may be several reasons (for example PI update, new information to be added) that may need to update the material, as a result of a follow-up of quarterly material review or any other time.  
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Any update (including adding or removing a page) initiates the process right from the beginning, i.e., ‘brief the agency/team’. 
                            </li>
                            <li className="flowsContent">
                               The copyright year needs to be updated by each calendar year, ifyear if the content of the website is being updated.  
                            </li>
                          </ul>
                          <p className="flowsHeading">UK</p>
                          <p className="flowsContent">
                             Veeva job bag will always be updated if the content on the page is being modified. However, if a link such as a PI or policy links on footer (that have their own job bag code) are being updated, the website’s job bag does not need to change.  
                          </p>

                          <p className="flowsHeading">NORDICS </p>
                          <p className="flowsContent">
                          An exception applies for websites where the same job bag could be used. A draft is created, and the old version is superseded. 
                          </p>
                          <p className="flowsHeading">Retire a Webpage </p>
                          <p className="flowsContent">
                            Removal of a single page within the website is classified as 'material update'. At such an instance, please follow ‘Material Management’ phase for instructions. 
                          </p>
                          <p className="flowsHeading">Update of Webpages on Campaign Services   </p>
                          <p className="flowsContent">
                             NE Hub has a platform called campaign services that can be leveraged to host content when the EWI site is not available to host the content for a brand/Therapy Area Campaign service platform can host PDFs or HTML webpages. 
                          </p>
                          <p className="flowsContent">
                            Webpages hosted on the campaign service platform should be temporary and only created for marketing campaigns active for <strong>maximum of 6 months</strong>. This is because these webpages do not have an inventory record on Global Lilly Database called Configuration Management Database (CMDB).  
                          </p>
                          <p className="flowsContent">Rarely, a campaign might require being live for more than 6 months. In such cases, a request for a 3-month extension can be applied through the TECH@LILLY Manager. </p>
                        </div>
                        <div
                          id="link15"
                          style={{
                            display:
                              P4visibleSection === "RetirePage"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">Retire page </p>
                          <p className="flowsContent">If it is removal of a single page within a website, its classified as ‘material update’. Please see Phase 1.  </p>
                        </div>
                      </div>
                    </div>

                  </Accordion>
                  <div id="accordion5" />
                  <Accordion
                    className="accordion5"
                    title="Phase 5: Material Withdrawal"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning p-3">
                           <Phase5 ref={svgRef5} className="webShape5" />
                      </div>
                      <div className="col-lg-6 planning p-3 flowsSecondPart">
                        <div
                          id="withdrawal_phase"
                          style={{
                            display:
                              P5visibleSection === "Gatekeeper"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            MATERIAL WITHDRAWAL PHASE
                          </p>
                          <p className="flowsContent">
                              Material Withdrawal phase starts with the brand team´s decision to discontinue a material and it ends with the completed and documented destruction of the material.{" "}
                          </p>
                          <p className="flowsContent">
                             The materials need to be removed from all storage locations and circulation before initiating the withdrawal in the Withdrawal Tool (wherever applicable). The Inventory Information field in VVPM will help the Material Administrator to capture all storage locations for effective destruction.  {" "}
                          </p>
                          <p className="flowsContent">
                            <strong>Withdrawal of entire website</strong>  means pulling out the whole website so that it is no longer visible externally. The Site Owner has the overall accountability for an effective withdrawal/retirement of the website. When retiring a website, contact TECH@LILLY and/or the System Custodian who will perform the following tasks: 
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               TECH@LILLY ensures that any data and website removal is handled as per policy. 
                            </li>
                            <li className="flowsContent">
                               TECH@LILLY/System Custodian perform a check on orphan pages, SEO, caching and related components so that they are all removed from search engines.
                            </li>
                            <li className="flowsContent">
                               URL Manager is notified so that the website can be removed from the internet. 
                            </li>
                          </ul>
                          <p className="flowsContent">
                            GCO team is notified to make changes in the CMDB. 
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Once the listed actions have been completed, System Custodian advises the Site Owner to retire the job bag on VVPM.  
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            
            {/*End Lifecycle */}
            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <p>Website Owner and Custodians are identified and logged in Lilly’s Website Inventory Database before a site is launched. Please ask the local TECH@LILLY Manager if there is a need to know who the Owner/Custodian for any website is. </p>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Website Owner</th>
                        <th>Website Custodian </th>
                        </tr>
                    </thead>  
                    <tbody>
                        <tr>
                        <td className="tb">
                            <h6>Content accuracy, approvals and compliance </h6>
                        </td>
                        <td className="tb">
                            <p>
                            Website is built, tested and documented according to Information Technology Controls-Standard Operating Principles (ITC-SOP) – Public Facing Website. 
                            </p>
                        </td>
                        </tr>
                        <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                      </tr>
                        <tr>
                        <td className="tb">
                            <h6>Privacy and patient safety compliance </h6>
                        </td>
                        <td className="tb">
                            <p>
                            Functionality works as designed and aligns to Lilly’s TECH@LILLY quality and privacy procedures. 
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td className="tb">
                            <h6>Website requirements </h6>
                        </td>
                        <td className="tb">
                            <p>
                            Configuration Item (CI) record management and website inventory 
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td className="tb">
                            <h6>Completing and delegating required training to individuals working on the website.  </h6>
                        </td>
                        <td className="tb">
                            <p>
                            Implementing key website controls (e.g., prevent caching, no cross-site search [i.e., search functionality that supports searches outside the website domain must be disabled], no orphan content, encryption of data collected/transferred, etc.). 
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td className="tb">
                            <h6>Site measurement and Key Performance Indicators (KPIs) </h6>
                        </td>
                        <td className="tb">
                            <p>
                            Follow all applicable Protect Lilly policies, procedures and process steps. 
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td className="tb">
                            <h6>Informing the Custodian about the new Website Owner if there is a change of role. </h6>
                        </td>
                        <td className="tb">
                            <p>
                            Providing TECH@LILLY documentation (if required) during an audit or a regulatory inspection. 
                            </p>
                        </td>
                        </tr>
                        <tr>
                        <td className="tb"></td>
                        <td className="tb">
                            <p>Ensuring any open text field on a Website, Webpage or Tool has a reasonable number of characters allowed (not unlimited) for the type of information being collected (for example: name, phone number, address). </p>
                        </td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>Expanded</strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                    Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool</td>
                    <td className="tb">Planning and execution tools combine to form the CET. With CET, customer plans can be created and campaigns can be managed in one place.</td>
                  </tr>
                  <tr>
                    <td className="tb">Cloud Page</td>
                    <td className="tb"></td>
                    <td className="tb">Salesforce Marketing Cloud application which allows creation and publishing of HTML pages.</td>
                  </tr>
                  <tr>
                    <td className="tb">Conversion Metric</td>
                    <td className="tb"></td>
                    <td className="tb">Metrics used to measure the number of valuable actions customers take through a digital marketing channel.</td>
                  </tr>
                  <tr>
                    <td className="tb">CSA</td>
                    <td className="tb">Control Self Assessment</td>
                    <td className="tb">A quality check performed by process owners quarterly. Results are shared and discussed with HLT with measures to mitigate identified issues.</td>
                  </tr>
                  <tr>
                    <td className="tb">CTA</td>
                    <td className="tb">Call to Action</td>
                    <td className="tb">The next step a Marketer wants its audience or reader to take. For example, a button or video in an email that the recipient can click on.</td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey</td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>
                  <tr>
                    <td className="tb">Defined text field</td>
                    <td className="tb"></td>
                    <td className="tb">Text entry fields on a website where entries are restricted to a specific character limit.</td>
                  </tr>
                  <tr>
                    <td className="tb">DST</td>
                    <td className="tb">Digital Solutions Team</td>
                    <td className="tb">Team of content developers dedicated to digital asset production in NE Hub.</td>
                  </tr>
                  <tr>
                    <td className="tb">EWI</td>
                    <td className="tb">Enterprise Web Initiative</td>
                    <td className="tb">Term attributed to the Global Team that leads development of Lilly Website.</td>
                  </tr>
                  <tr>
                    <td className="tb">GPS</td>
                    <td className="tb">Global Patient Safety</td>
                    <td className="tb"></td>
                  </tr>
                  <tr>
                    <td className="tb">Hyperlink</td>
                    <td className="tb"></td>
                    <td className="tb">A link within one digital item to another location, activated by clicking on a highlighted word or image.</td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">Interstitial</td>
                    <td className="tb"></td>
                    <td className="tb">An interstitial is a pop-up or webpage that appears when a user attempts to navigate from a Lilly website to an external website. AN interstitial provides notice to the user that the location they are navigating to is not controlled or maintained by Lilly.</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Tech@Lilly</td>
                    <td className="tb"></td>
                    <td className="tb">Local IT team handling web affairs for the NE Hub.</td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">Goals or Metrics set behind a campaign and or tactic.</td>
                  </tr>
                  <tr>
                    <td className="tb">Metatags</td>
                    <td className="tb"></td>
                    <td className="tb">Title, description, and keywords given to a website for search results. Meta tags are on search engines such as Google. These are snippets of text that describe a page's content; the meta tags don't appear on the page itself, but only in the page's source code.</td>
                  </tr>
                  <tr>
                    <td className="tb">Open text fields</td>
                    <td className="tb"></td>
                    <td className="tb">Places on a website where a user can enter data freely</td>
                  </tr>
                  <tr>
                    <td className="tb">PIA</td>
                    <td className="tb">Privacy Impact Assessment</td>
                    <td className="tb">A thorough review and understanding of how Personal data will be processed, stored, managed.</td>
                  </tr>
                  <tr>
                    <td className="tb">PMC</td>
                    <td className="tb">Permissions Managed Content</td>
                    <td className="tb">An image which is owned by an entity other than Lilly and for which permission is given to use under a specifc set of circumstances as defined in a contractural agreement between Lilly and the Content Owner.</td>
                  </tr>
                  <tr>
                    <td className="tb">SEO</td>
                    <td className="tb">Search Engine Optimization</td>
                    <td className="tb">The collection of activities performed to increase the quality and quantity of website traffic by improving a website’s or webpage’s visibility in organic search results on search engine results pages.</td>
                  </tr>
                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"></td>
                    <td className="tb">Tactics are specific to selected channels to drive customers to reach a piece of content. Tactics are associated with estimated KPI metric (goal) and result reports (actuals).</td>
                  </tr>
                  <tr>
                    <td className="tb">Tool <br/>(Web tool)</td>
                    <td className="tb"></td>
                    <td className="tb">A tool created to add functionality to a website or webpage, including plug-ins for web browsers. Examples of tools: registration pages, questionnaires, surveys, polls, social media share buttons, contact us, open text fields, webkeys, web browser plug-ins, page ratings, information kiosks, heatmaps, decision-making algorithms/auto-generators, email a friend, RSS feeds.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Defination*/}

            {/*View Core Doc*/}
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.WebsiteCoreDoc}
                  target="_blank"
                >
                  Website document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/*End Core Doc*/}

            {/*Add Faqs*/}
            <div
              className="tab-pane fade"
              id="v-pills-faqs"
              role="tabpanel"
              aria-labelledby="v-pills-faqs-tab"
            >
              <h4 className="h1-font comman_hd">FAQs</h4>
              <div id="FAQ1">
                <Accordion
                  className="faqAccordion"
                  title="What is the trademark of the website icon/Favicon?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    When the icon (e.g., Favicon) is used to identify the website in the URL string or web browser tab, ensure that it is legally cleared and approved by Lilly’s trademark department.  For additional details, contact the trademark department at <a href={webConfig.trademarkslillycom}>trademarks@lilly.com</a>. While a Favicon is not required, it is the best practice.
                  </p>
                </Accordion>
              </div>
              <div id="FAQ2">
                <Accordion
                  className="faqAccordion"
                  title="What is a privacy statement and why is it needed on the site? "
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    A Privacy statement must be available on every page of the website to specify what personal information is collected and how it is used. It allows you to be transparent with the users about what happens to the personal information they provide, knowingly or unknowingly. Work with your privacy advisor and TECH@LILLY to ensure that your website is linking to the most current Privacy Statement. Follow any additional country-specific requirements for privacy notice and consent such as cookie pop-ups.
                  </p>
                </Accordion>
              </div>
              <div id="FAQ3">
                <Accordion
                  className="faqAccordion"
                  title="What is an accessibility statement and why is it needed on the site?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                  An accessibility statement explains that Lilly creates Websites, Webpages or Tools that are accessible for all people, regardless of ability or technology. The accessibility statement must be available on every page of your site (in footer) to demonstrate Lilly’s commitment to accessibility.  
                  </p>
                </Accordion>
              </div>
              <div id="FAQ4">
                <Accordion
                  className="faqAccordion"
                  title="What is a ‘Terms of Use’ statement and why is it needed on the site? "
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                  A ‘Terms of Use’ statement must be available on every page of the website to specify how a visitor may use the website and its content.  
                  </p>
                </Accordion>
              </div>
              <div id="FAQ5">
                <Accordion
                  className="faqAccordion"
                  title="What is a ‘Copyright’ statement and why is it needed on the site?  "
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                     A ‘Copyright’ statement must be available on every page of the site, to specify how a visitor may use the content with copyright on the site. You may embed this in the ‘Terms of Use’ statement or place it as a stand-alone statement.  
                  </p>
                </Accordion>
              </div>
              <div id="FAQ6">
                <Accordion
                  className="faqAccordion"
                  title="What is a Target Audience disclaimer and why is needed on the site?"
                  defaultOpen={false}
                >
                  <ul className="faqcontent">
                     As per the Lilly’s global website policy, if your website target is a healthcare Professional (HCP) you must provide: 
                    <li>
                        A statement which specifies that a specific Audience is targeted (patient versus HCP). 
                    </li>
                    <li>
                        A way for visitors to confirm that they are a part of your target Audience. 
                    </li>
                    <img src="images/faqs/target_audiance_disclaimer.png" className="p-2 web-image" />
                  </ul>
                </Accordion>
              </div>
              <div id="FAQ7">
                <Accordion
                  className="faqAccordion"
                  title="What is ‘age disclaimer’ and when is it needed? "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                  <p>
                  As per the Lilly’s global website policy, age disclaimers (when applicable) must be accessible on every page of your website. They are <strong>not</strong> required if your target Audience is an HCP.  
                  </p>
                  <img src="images/faqs/age_disclaimer.png" className="p-2 web-image" />
                  </div>
                </Accordion>
              </div>
              <div id="FAQ8">
                <Accordion
                  className="faqAccordion"
                  title=" What are defined and open text fields? "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p><strong>Defined text fields</strong></p>
                    <p >
                        They are used for collecting demographic or contact information such as name, address and phone number. Limit the number of characters allowed to a reasonable number for the type of information being collected. 
                    </p>
                    <p><strong>Open text fields</strong></p>
                    <p>They are used to collect more detailed information such as questions, concerns or feedback. Ensure the following for ‘Open Text Fields’ and ‘Contact Us’:  </p>
                    <ul className="ul_padding">
                        <li>  Prior to launch, there must be a documented plan for monitoring text fields (or any other information collected) at least once every business day for the identification of the AE/PC. </li>
                        <li>  If any personal information is collected within an open text field, ensure that the information is handled in accordance with privacy expectations.  </li>
                    </ul>
                    <p> <strong>Understanding the risk </strong></p>
                    <p>Limiting the number of characters allowed in a defined text field reduces the risk of a customer entering content beyond what you are seeking. A customer can provide feedback in an open text box or a ‘Contact Us’ email anytime—it is necessary to monitor AEs/PCs.  </p>
                    <img src="images/faqs/form_text.png" className="p-2 web-image" />
                  </div>  
                  
                </Accordion>
              </div>
              <div id="FAQ9">
                <Accordion
                  className="faqAccordion"
                  title="Can I include a survey on my website?  "
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                  Follow the ‘Conducting Customer Research Activities’ Procedure prior to beginning a project that includes a survey or a market research.
                  </p>
                </Accordion>
              </div>
              <div id="FAQ10">
                <Accordion
                  className="faqAccordion"
                  title="Can I link my website to another site? "
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p >
                      For the UK, it may be acceptable to link your website to another UK Lilly website or to a UK Lilly-controlled stand-alone transactional webpage, such as registration page or to a corporate site.  
                      <br/>
                      Never link your website to an external page that would in any way give the appearance that it is advocating, promoting or implying an off-label use of any of our products! 
                      <br />
                      If your website appears to be advocating, promoting or implying an off-label use of any of our products, Lilly could be fined.  
                      <br />
                      If you happen to link external content that Lilly does not control, you must provide notice to the user. Use an interstitial for these links.  
                      <br />
                      Please discuss this in advance with your team’s final medical signatory. 
                    </p>
                  </div>
                </Accordion>
              </div>
              <div id="FAQ11">
                <Accordion
                  className="faqAccordion"
                  title="What is an interstitial and when do I need it?"
                  defaultOpen={false}
                >
                <div className="faqcontent">
                    <p>
                    An interstitial is a pop-up or a webpage that appears when a user attempts to navigate from a Lilly website to an external website. An interstitial provides notice to the user that the location they are navigating to is not controlled or maintained by Lilly.
                    </p>
                    <p>An interstitial is <strong>not</strong> required for Lilly-controlled: </p>
                    <ul>
                        <li>  Standalone transactional webpages, such as surveys or registration pages. </li>
                        <li>  Social media plug-ins/sharing/lining. </li>
                    </ul>
                    <p> Interstitial link disclaimer used in the NE Hub is as follows:</p>
                    <em><strong>This link will take you to a site maintained by a Third Party who is solely responsible for the content. Eli Lilly and Company does not control or endorse this website and is not responsible for its privacy policy. We encourage you to read the privacy policy of every website you visit. </strong></em>
                </div>    
                  
                </Accordion>
              </div>
              <div id="FAQ12">
                <Accordion
                  className="faqAccordion"
                  title="What is a monitoring plan? "
                  defaultOpen={false}
                >
                <div className="faqcontent">
                    <ul className="ul_padding">
                      <li> Prior to launch, there must be a documented plan for monitoring text fields (or any other information collected) at least once every business day for the identification of AEs/PCs. </li>
                      <li> Monitoring processes must ensure that reporting occurs within one business day of discovery of an AE/PC. </li>
                      <li> If the monitoring plan is less frequent, obtain documented approval by the local organization responsible for safety and product complaints.    </li>
                      <li> A documented monitoring plan should address: </li>
                    </ul>  
                    <ol>
                        <li>What is being monitored.</li>
                        <li>Who is doing the monitoring.</li>
                        <li>Frequency of monitoring. </li>
                        <li>How to report AEs/PCs and any other relevant findings and to whom. </li>
                        <li>Any other considerations for approval, safety reporting, training, etc. </li>
                        <li>What process approval/documentation is required. </li>
                    </ol>
                    <p><strong>Note: </strong> Defined, character limited fields (e.g., email address, first name, last name, address) do not require a monitoring process.
                    If any personal information is collected within an open text field, ensure that the information is handled in accordance with privacy expectations.  </p>
                </div>
                </Accordion>
              </div>
              <div id="FAQ13">
                <Accordion
                  className="faqAccordion"
                  title="What should be considered when linking a Lilly website to Medical Information?  "
                  defaultOpen={false}
                >
                <div className="faqcontent">
                    <ul>
                        <li> 
                             If adding a link or email address to Medical Information, it must be kept generic/general and not invite members of the public to contact the company for more information about Lilly products.  Another pharma company was found in breach of the Code for soliciting requests about a prescription only medicine and had thus gone beyond the provision of reference information and reactive information in response to a direct, unsolicited request allowed under the Code by stating for more information on product X, ‘please contact [email address for medical information]’. Case 3270/10/19 {" "}
                            <a href={webConfig.SeeCodeCasehere} target="_blank"> See Code Case here. </a> 
                        </li>
                        <br/>
                        <li>
                              Lilly Medical information services may be advertised on Lilly owned promotional therapy area sites such as diabetes or oncology sites. The service can also be advertised in the corporate non-promotional site,{" "}
                             <a 
                              href={webConfig.lillycouk} 
                              target="_blank"> 
                              www.lilly.co.uk.</a>{" "}It is not appropriate to have a banner to raise awareness of medical information when it is on materials promoting a single product. 
                        </li>
                    </ul>
                    <p><strong>IMPORTANT: </strong>  If the website is hosted by EWI and PDFs are being hosted to the site, then it must be hosted directly from the EWI site. If hosting PDFs linked to this asset, use <strong>Veeva Digital Publishing </strong>  (DP). DP automates the publishing of the latest approved version of content to various digital channels and supports automatic withdrawal of expired or obsolete content. The system does this by allowing users to select approved VVPM documents to be published as a digital PDF on an externally accessible location.</p>
                </div>
                </Accordion>
              </div>
              <div id="FAQ14">
                <Accordion
                  className="faqAccordion"
                  title="What are the benefits of Digital Publishing?  "
                  defaultOpen={false}
                >
                <div className="faqcontent">
                    <ul>
                        <li>  When a DP enabled document is Approved for Distribution, Vault will automatically publish the approved version of the file to an externally accessible location and generate a URL to the document.   </li>
                        <li>  When updates to a DP enabled document are approved, Vault will automatically publish the new, approved version without the need to change the URL (i.e., on the website). </li>
                        <li>  When a DP enabled document is expired/retired/withdrawn the document will be removed from the externally accessible location. </li>
                    </ul>
                </div>
                </Accordion>
              </div>
              <div id="FAQ15">
                <Accordion
                  className="faqAccordion"
                  title="Can I just do nothing and wait for my assets to expire automatically?  "
                  defaultOpen={false}
                >
                <div className="faqcontent">
                    <ul>
                        <li>  In short no, this tool gives us greater compliance control over the materials that are available in the live environment. However, it is important that we continue to track all our materials to replace broken links that will occur as we withdraw or expire assets.  </li>
                        <li>  Each brand team must consider the ripple effects of material withdrawal and put mitigation plans in place to swiftly amend links (if applicable) or change content on relevant pages if a campaign is being retired.  </li>
                    </ul>
                </div>
                </Accordion>
              </div>
            </div>
            {/*End Faqs*/}
          </div>
        </div>
      </div>
      {/*Nordics Left cert*/}
      <div id="popup_flight_travlWeb101" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">NORDICS</p>
          <p className="flowsContent">
            If the material gets the verdict <strong>‘Not Certified’</strong>{" "}
            use <strong>‘Create Draft’</strong> function in VVPM to use
            the same job bag code for the updated version of the material.
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>
      {/*Nordics Below cert*/}
      <div id="popup_flight_travlWeb102" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">NORWAY</p>
          <p className="flowsContent">
              The Material Owner uploads the PDF copy of changes on the LMI portal. LMI is a local regulatory authority that requires all pharma companies to advise them of any changes before they go live. No need to wait for an approval from them – it is simply a notification. Create a Word document with a screenshot of the notification sent to LMI and upload it on Veeva.
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>

      {/*Norway Below cert*/}
      <div id="popup_flight_travlWeb103" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">Norway</p>
          <p className="flowsContent">
            The Material Owner uploads the PDF copy of changes on the LMI portal. LMI is a local regulatory authority that requires all pharma companies to advise them of any changes before they go live. No need to wait for an approval from them – it is simply a notification. Create a Word document with a screenshot of the notification sent to LMI and upload it on Veeva. 
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>

      {/*Norway Above cert */}
      <div id="popup_flight_travlWeb104" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">Norway</p>
          <p className="flowsContent">
          Notify LMI and upload confirmation on VVPM.
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>

      {/*Norway Above cert */}
      <div id="popup_flight_travlWeb105" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">SWEDEN </p>
          <p className="flowsContent">
            Notify (via email) to local authorities about updates (within 90 days) if there are significant changes in content that have not been seen by them before (for example, adding a new brand, new indication, new study, video, etc.). This advice is not needed when updating the cookie banner, correcting typos, updating the PI, or changing wordings slightly. Upload confirmation on VVPM. 
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>


    </div>
  );
};


export default Website;
