import React from "react";
import { useState, useRef, useEffect } from "react";
import {webConfig} from '../configs/webConfig'
import { Link,useNavigate } from 'react-router-dom';
import "../css/PMC.css";
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/PMC_Phases_SVG/PMC_Phase_1.svg";



const PMC = () => {
  const navigate = useNavigate();
  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("phase1rect4");


  useEffect(() => {
    const svg = svgRef1.current;
    const rect4 = svg.getElementById("rect4");
    rect4.addEventListener("click", () => {
      setP1visibleSection("phase1rect4");
    });
  }, []);



  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                    Permission (or Consent) Managed Content  (PMC)  
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">Permission (or Consent) Managed Content  </h2>
            <p>
              <em>
                  Utility of some content may need permission or consent from a third party or an individual. This is in instances when Lilly does not have the usage right to the content. Consent applies in the below instances:  
              </em>
            </p>
            <em>
              <ul className="ul_padding">
                <li>
                   When Lilly (or usually Lilly’s creative agency) enters a contract with a person to appear in photos, videos, images, artwork, graphics, illustrations or slides for use in Lilly’s promotional/advertising materials. 
                </li>
                <li>
                   When a healthcare professional (HCP)/Other relevant decision maker (ORDM) allows the use of photos, videos, slides, biography, or quotes in Lilly material.
                </li>
                <li>
                   When customers sign-up to receive promotional communications from Lilly, an agency or a third party. 
                </li>
                <li>
                   When copyright applies
                </li>
              </ul>
            </em>
          </div>
        </div>
      </div>

      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link nav-tab-link active"
              data-bs-toggle="tab"
              href="#ProcessPreRequisites"
            >
              Process 
              <br />
              Pre-requisites 
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-tab-link" data-bs-toggle="tab" href="#PiAndAVAsset" style={{ width: "200px !important" }}>
              Personal Information and Audio-Visual assets  
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-tab-link" data-bs-toggle="tab" href="#TypeOfUsageRights">
              Types of <br/> Usage Rights
            </a>
          </li>
        </ul>
        
        <div id="tabContent" className="tab-content">
            <div id="ProcessPreRequisites" className="tab-pane fade in active tabs_box tabContent">
              <p className='tabContent'>
                Please note the following when uploading a Permission Managed Content (PMC): 
              </p>  
              <ul className="ul_padding tabContent">
                <li> Please ensure that all personal information is <strong>redacted</strong> in the PMC, e.g., address and phone number, before uploading to VVPM.  </li>
                <li> Please note that, <strong>Audio-Visual asset is ONLY for “work product”</strong> (i.e., component created by Lilly or an agency under contract with Lilly). No PMC Summary Form is needed as all components are owned by <strong>Lilly In Perpetuity</strong> with no media use restrictions.   </li>
              </ul>
            </div>
            <div id="PiAndAVAsset" className="tab-pane fade tabs_box tabContent">
               <p className='tabContent'>
                Please ensure that all personal information is redacted in the PMC, e.g., address and phone number, before uploading to VVPM
               </p>
               <p className='tabContent'>
                Note that for Global images (e.g. used in IVAs or campaigns) PMC forms are not required as Global will have obtained the image rights.
               </p>
            </div>
            <div id="TypeOfUsageRights" className="tab-pane fade tabs_box tabContent">
              <p className='tabContent'>
                The following table outlines four types of Usage Rights and suggestions about what to upload on Veeva. Remember to create and link a PMC document where required.
              </p>
              <table className="table table-striped mt-3">
                <thead>
                    <tr>
                      <th>Type of Usage Right </th>
                      <th>Example</th>
                      <th>Upload to Veeva as a PMC</th>
                    </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='tb'>Consent from person </td>
                    <td className='tb'>Lilly or agency enters a contract with a photographer, artist, illustrator, model or talent to appear in photos, videos, images, artwork, graphics or illustrations for use in Lilly materials.</td>
                    <td className='tb'>
                      <p><strong>Photo/Video Consent and Release Form</strong> or <strong>Contract</strong></p> 
                      <p>If the images are global branding images, no requirement to add further consent documents locally.</p>
                    </td>
                  </tr>
                  <tr>
                    <td className='tb'>Customer Photo/ Video/Slide/Quote Consent  </td>
                    <td className='tb'>Customer allows use of their photos, videos, slides, biography, or quotes in the Lilly material. </td>
                    <td className='tb'>
                      <p>If consent is included in the contract documentation, choose PMC = No, <strong>and enter the LCMS contract ID (CNTR reference) in the Title field.</strong></p> 
                      <p>Otherwise attach: </p>
                      <ul className="ul_padding tabContent">
                      <li><strong>Head-Office Meeting Proforma form</strong> (ensure that the Mercury ID is on the form or in the job bag so that documentation can be traced) or </li>
                      <li><strong>Photo/Video Consent and Release form</strong> or  </li>
                      <li><strong>Email from customer confirming consent </strong> (this is required for confirmation of the customer bio).</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td className='tb'>Customer Consent    </td>
                    <td className='tb'>Customer has signed up to receive promotional communication from an agency or a Third Party. </td>
                    <td className='tb'>
                        Copy of the actual consent captures wording that states that a customer has agreed to receive promotional communications. 
                    </td>
                  </tr>
                  <tr>
                    <td className='tb'>Copyright</td>
                    <td className='tb'>Graphs, diagrams, charts and extracts from published sources Citing websites may also fall under copyright – check the wording </td>
                    <td className='tb'>
                      <p className="tabContent">
                        Please check the requirements for using external sources in your materials. In particular pay attention to: 
                      </p>
                      <ol className="tabContent">
                        <li>
                          Storing a reference in Veeva library gives us permission only to use the text, not graphs and images. We'd need explicit permission for those even if we have the reference in Veeva. 
                        </li>
                        <li>Copyright for images/graphs are applicable for internal job bags as well.</li>
                        <li>Usage of restricted websites.</li>
                        <li>
                          Content available on the internet may not always be open source. Check copyright criteria carefully before using any content or images available online.
                        </li>
                      </ol>
                      <p className="tabContent">
                        Consult the {' '}
                        <Link
                          to={webConfig.ArticleRequestsite}
                          target="_blank"
                        >
                          Article Request site 
                        </Link>{' '}for further information.  
                      </p>
                      <p className="tabContent">
                        For an overview of the copyright process please view this {' '}
                        <Link
                          to={webConfig.TrainingDeck}
                          target="_blank"
                        >
                          training deck. 
                        </Link> 
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
      </div>
      
      {/*Add verticle Tab*/}
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            {/*Add Lifecycle */}
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">Permission Managed Content Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Planning"
                    defaultOpen={true}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                         <Phase1 ref={svgRef1} className="soaShape1 main-content" />
                      </div>
                      <div className="col-lg-7 planning p_font pt-3 flowsSecondPart side-content">
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect1" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">PMCs UPLOADED ON VVPM</p>
                          <p className="flowsContent">
                          PMCs are uploaded on VVPM once email or consent has been received from a contractor/ speaker for Lilly owned or created assets 
                          </p> 
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect2"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CREATE AND UPLOAD    </p>
                          <p className="flowsContent">
                          Select ‘Create’ option on VVPM. Select ‘Upload’ and upload the PMC document. 
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect3"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CLASSIFY IN VVPM </p>
                          <p className="flowsContent">
                            Select ‘Classify documents now’. Click the binoculars symbol and select either ‘Audio Visual asset’ or ‘Usage rights/Consent’. e.g. for an image ‘Component &gt; Usage rights/Consent &gt; Photo’. Select the component accordingly. 
                          </p>
                        </div>

                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect4"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">METADATA COMPLETION </p>
                          <p className="flowsContent"><strong>Filling in the metadata</strong></p>
                          <ul className="ul_padding ul_margin_bottom flowsContent">
                            <li> Fill in the <strong>‘Name’</strong> to help identify the PMC e.g. speaker name, consent form, event/meeting, month and year. You can add more details on the PMC in <strong>‘Title’</strong> section</li>
                            <li> In <strong>‘Source Country’</strong>, add Global for global PMCs or UK for UK related PMCs</li>
                            <li> Add all countries in which this PMC can be used in <strong>‘Country Approved for Use’</strong>. If this PMC cannot be used in any other country, then select <strong>‘Geographical Exclusions’</strong> as ‘Yes’ and add all relevant countries in <strong>‘Countries Excluded’</strong>. Generally, this field is ‘No’</li>
                            <li> Select <strong>‘Language’</strong> as English</li>
                            <li> Select <strong>‘Asset License Type’</strong> as <strong>‘Contract associated’</strong> or <strong>‘No contract associated’</strong> (Choose contract associated if there is a contract signed by the HCP/Agency model)</li>
                            <li> Select <strong>‘Approved for all products’</strong> as ‘Yes’ or ‘No’ depending on whether the PMC is specific for one type of products or all. If ‘No’ is selected, then add all the products for which this PMC is relevant</li>
                            <li> Add in the <strong>‘Permission Type’</strong> depending on the type of PMC e.g. for speaker permission, chose ‘consent/release’</li>
                            <li> Is the licence transferable? Usually it is not – add ‘No’ under ‘<strong>Transferable License</strong>’. If not purchased by Lilly, but purchased by agency working on behalf of Lilly, are the usage rights transferrable from the purchaser to Lilly?</li>
                            <li> Select yes license is transferable</li>
                            <li> Select no if license is not transferable to Lilly</li>
                            <li> Fill in the <strong>‘Attribution to Content Provider’</strong> as ‘Yes’ or ‘No’. Select yes if licensee acknowledgement is required near the content. Select no if content can be displayed without acknowledging the content provider</li>
                            <li> Please add in <strong>‘Duration of use’</strong> depending on how long the PMC is viable for. Generally, most PMCs expire after <strong>2 years</strong>. Add in start and end date accordingly. Note – PMC also applies to AV components created by Lilly or by an agency under contract with Lilly. These created components are ‘In Perpetuity’ and can be used in unlimited channels since Lilly owns the content wholly.</li>
                            <li> In <strong>‘Licensed Channels’</strong>, select unlimited if content can be used on all channel types. If content is limited to a specific media type(s), select the media type(s) content it can be used in</li>
                            <li> In <strong>‘Therapeutic Area Restriction(s)’</strong>, select ‘Yes’ or ‘No’ depending on whether the use of the PMC is restricted to any particular TA. Generally, this is selected as ‘No’.</li>
                            <li> In <strong>‘Other Restrictions on Usage’</strong>, select ‘Yes’ if this content can only be used with select therapeutic areas or not used with specifically. Select ‘No’ if this content has no therapeutic area restrictions.</li>
                            
                        </ul>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect5"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">SAVE AND START WORKFLOW    </p>
                          <p className="flowsContent">Save and start ‘Make Available for Use’ workflow.The PMC will be available instantly. </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "phase1rect6"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">ADD PMC TO JOB BAG  </p>
                          <p className="flowsContent">To add this PMC to a job bag, search the ‘document number’ of the PMC in the ‘Related PMC’ section of the job bag and click on the '+' sign.  </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            
            {/*End Lifecycle */}
            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Role </th>
                        <th>Responsibility</th>
                        </tr>
                    </thead>  
                    <tbody>
                      <tr>
                         <td className="tb">
                              <h6>Veeva Owner</h6>
                          </td>
                          <td className='tb'>
                              <p>
                              The Veeva Owner is also known as the Uploader (please see 'Veeva uploader') 
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator</h6>
                          </td>
                          <td>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Having overall responsibility and accountability for the material</li>
                                  <li>2. Ensure that no item is used without the necessary approvals</li>
                                  <li>3. Ensuring timely change and/or withdrawal, where needed</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a>
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Uploader</h6>
                          </td>
                          <td>
                              <p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA
                                  teams, and is assigned the Veeva Owner role.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Uploading the material to Veeva</li>
                                  <li>2. Performing all activities related to upload</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Co-ordinator</h6>
                          </td>
                          <td>
                              <p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager (or similar
                                  role). </p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Reviewing the item before sending for MLR review</li>
                                  <li>2. Managing the document workflow</li>
                                  <li>3. Assigning reviewers</li>
                                  <li>4. Managing expiry or periodic review</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Business Reviewer</h6>
                          </td>
                          <td>
                              <p>Business Reviewer is an experienced commercial employee.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring all promotional materials align with the brand strategy, Lilly Brand Book, Product Brand
                                      Book</li>
                                  <li>2. Ensuring all materials are of good quality, have correct Veeva metadata and adhere to internal
                                      policies and local codes</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Medical Certifier</h6>
                          </td>
                          <td>
                              <p>Registered medical practitioner or UK-registered Pharmacist.</p>
                              <ul className="ul_list">
                                  <li>Responsible for:</li>
                                  <li>1. Ensuring that the material is in accordance with the requirements of the local code</li>
                                  <li>2. Ensuring that the material is not inconsistent with the marketing authorisation/SmPC (if applicable)
                                  </li>
                                  <li>3. Ensuring that the material is a fair and truthful representation of the facts about the medicine (if
                                      applicable)</li>
                                  <li>4. Approving the material in its final form</li>
                                  <li>5. Ensuring the material has the appropriate reviewers and that the Veeva metadata is correct.</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for ensuring that the Veeva metadata is correct and that the PMC
                                  documents are included (if applicable).</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordic Code Approver</h6>
                          </td>
                          <td>
                              <p>Code Approver must be a medical practitioner or a pharmacist (except for in Sweden) and is responsible for
                                  ensuring that the material is in line with the local Codes and that the material being approved has the
                                  appropriate reviewers; and ensuring that the Veeva metadata is correct.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordics Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for reviewing the material from a medical perspective.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Indegene</h6>
                          </td>
                          <td>
                              <p>Responsible for:</p>
                              <ul className="ul_list">
                                  <li>1. Creating artwork as per material originator brief</li>
                                  <li>2. Creating formatted PI PDFs</li>
                              </ul>
                          </td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}

            {/*Add Defination*/}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>What it Stands for </strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                    Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool </td>
                    <td className="tb">Planning and execution tools combine to form the CET. With CET, customer plans can be created and campaigns can be managed in one place. </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey </td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">Goals or Metrics set behind a campaign and or tactic.</td>
                  </tr>
                  <tr>
                    <td>PMC</td>
                    <td>Permissions Managed Content</td>
                    <td>An image which is owned by an entity other than Lilly and for which permission is given to use under a specifc set of circumstances as defined in a contractural agreement between Lilly and the Content Owner. </td>
                  </tr>
                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"> </td>
                    <td className="tb">Tactics are specific to selected channels to drive customers to reach a piece of content. Tactics are associated with estimated KPI metric (goal) and result reports (actuals).  </td>
                  </tr><tr>
                    <td className="tb">VVPM</td>
                    <td className="tb">Veeva Vault PromoMat</td>
                    <td className="tb">Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems. PromoMats is a Vault application to manage the process of creating, reviewing and approving promotional materials, claims and supporting information.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/*End Defination*/}

            {/*View Core Doc*/}
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.PMCCoreDoc}
                  target="_blank"
                >
                  PMC document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/*End Core Doc*/}
          </div>
        </div>
      </div>

    </div>
  );
};


export default PMC;
