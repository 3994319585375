import React from "react";
import { useState, useRef, useEffect } from "react";
import "../css/Iva.css";
import {webConfig} from '../configs/webConfig'
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/IVA_Phases_SVG/IVA_Phase_1.svg";
import { ReactComponent as Phase2 } from "../SVG/IVA_Phases_SVG/IVA_Phase_2.svg";
import { ReactComponent as Phase3 } from "../SVG/IVA_Phases_SVG/IVA_Phase_3.svg";
import { ReactComponent as Phase4 } from "../SVG/IVA_Phases_SVG/IVA_Phase_4.svg";
import { ReactComponent as Phase5 } from "../SVG/IVA_Phases_SVG/IVA_Phase_5.svg";

const IVA = () => {
  const [isPhase3Open, setIsPhase3Open] = useState();

  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("CET");

  const svgRef2 = useRef();
  const [P2visibleSection, setP2visibleSection] = useState("Briefing");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("Initiate");

  const svgRef4 = useRef();
  const [P4visibleSection, setP4visibleSection] = useState("Quarterly");

  const svgRef5 = useRef();
  const [P5visibleSection, setP5visibleSection] = useState("Gatekeeper");

  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");

    rect1.addEventListener("click", () => {
      setP1visibleSection("CET");
    });

    rect2.addEventListener("click", () => {
      setP1visibleSection("Pre-Veeva");
    });
  }, []);

  useEffect(() => {
    const svg = svgRef2.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const diam1 = svg.getElementById("diamond1");
    const rect3 = svg.getElementById("rect3");

    rect1.addEventListener("click", () => {
      setP2visibleSection("Briefing");
    });

    rect2.addEventListener("click", () => {
      setP2visibleSection("Artwork");
    });

    diam1.addEventListener("click", () => {
      setP2visibleSection("Expectations");
    });
    const handleScrollP3 = () => {
      const elementToScrollTo = document.getElementById("accordion3");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect3.addEventListener("click", () => {
      handleScrollP3();
      setIsPhase3Open(true);
    });
  }, [isPhase3Open]);

  useEffect(() => {
    const svg = svgRef3.current;
    const rect1 = svg.getElementById("rect1");
    const diam1 = svg.getElementById("diamond1");
    const rect2 = svg.getElementById("rect2");
    const diam2 = svg.getElementById("diamond2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    const rect8 = svg.getElementById("rect8");
    const rect9 = svg.getElementById("rect9");
    const rect10 = svg.getElementById("rect10");
    rect1.addEventListener("click", () => {
      setP3visibleSection("Initiate");
    });

    diam1.addEventListener("click", () => {
      setP3visibleSection("MlrVerdict");
    });

    rect2.addEventListener("click", () => {
      setP3visibleSection("InitiateCertification");
    });

    diam2.addEventListener("click", () => {
      setP3visibleSection("CertificationVerdict");
    });

    rect3.addEventListener("click", () => {
      setP3visibleSection("FinalSteps");
    });
    rect4.addEventListener("click", () => {
      setP3visibleSection("CLM");
    });
    rect5.addEventListener("click", () => {
      //setP3visibleSection("FORUK");
    });
    rect6.addEventListener("click", () => {
      //setP3visibleSection("Certifier");
    });
    rect7.addEventListener("click", () => {
      setP3visibleSection("Approves");
    });
    rect8.addEventListener("click", () => {
      setP3visibleSection("Inform");
    });
    rect9.addEventListener("click", () => {
      //setP3visibleSection("Modified");
    });
    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect10.addEventListener("click", () => {
      //setIsPhase2Open(true);
      handleScrollP2();
    });
  }, []);

  useEffect(() => {
    const svg = svgRef4.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    rect1.addEventListener("click", () => {
      setP4visibleSection("Quarterly");
    });

    rect2.addEventListener("click", () => {
      setP4visibleSection("UpdateOfMaterial");
    });
    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect3.addEventListener("click", () => {
      //setIsPhase2Open(true);
      handleScrollP2();
    });
    const handleScrollP5 = () => {
      const elementToScrollTo = document.getElementById("accordion5");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect4.addEventListener("click", () => {
      //setIsPhase5Open(true);
      handleScrollP5();
    });
  }, []);

  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Interactive Visual Aids (IVA)
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">Interactive Visual Aids (IVA)</h2>
            <p>
              <em>
              An Interactive Visual Aid (IVA) is used by Customer Facing Team Representatives to interact with healthcare professionals (HCPs) virtually or face to face. IVAs are usually provided to Customer Facing Teams through Veeva CRM (Customer Relationship Manager).
              </em>
            </p>
          </div>
        </div>
      </div>
      <div className="container p-3 mb-5">
        {" "}
        <ul id="tab_panes" className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-bs-toggle="tab"
              href="#processPreRequisites"
            >
              Process
              <br />
              Pre-requisites
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#vvpmJobBag">
              VVPM Job Bag Standards
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#ivaStatuses">
              IVA
              <br />
              Statuses
            </a>
          </li>
        </ul>
        <div id="tabContent" className="tab-content">
          <div
            id="processPreRequisites"
            className="tab-pane fade in active tabs_box"
          >
            <p className="tabsContentHeading">IVA Gatekeeper in Brand Team</p>
            <p className="tabContent">
              Process subject to a named <strong>‘IVA Gatekeeper’</strong> within each brand
              team/each affiliate. At least one individual from the brand team
              (or PRA/Medical function) needs to be assigned as the Gatekeeper.{" "}
              <strong>They must complete</strong>{" "}
              <a
                href={webConfig.VaulttoCLMOwnerandGatekeeperTraining}
                target="_blank"
              >
                Vault to CLM Owner and Gatekeeper Training
              </a>{" "}
              before requesting access using the{" "}
              <a
                href={webConfig.VeevaCLMAccountRequestForm}
                target="_blank"
              >
                Veeva CLM Account Request Form.
              </a>
            </p>

            <p className="tabsContentHeading">Briefing Guide</p>
            <p className="tabContent">
              A briefing guide for the Customer-Facing Team must accompany the
              IVA. This is a requirement for all NE Hub countries. Each brand
              team{" "}
              <strong>
                must have a pre-defined storage location for briefing guides
              </strong>{" "}
              that must be known to Customer-Facing Teams, so that the brief can be accessed as and when needed.
            </p>
          </div>
          <div id="vvpmJobBag" className="tab-pane fade tabs_box">
            <p className="tabsContentHeading">Name:</p>
            <p className="tabContent">
              The naming convention from the{' '} 
              <a
                href={webConfig.PMAPJobAid}
                target="_blank">PMAP Job Aid</a> {' '} section 6.3 (a) must be followed.
            </p>
            <table className="table table-striped tabContent">
              <thead>
                  <tr></tr>
              </thead>  
              <tbody>
                <tr>
                    <td className="tb">
                        <strong>Country Code</strong>
                        <br />
                        (UK, IE, SE etc.) 
                    </td>
                    <td className="tb">
                        <strong> Therapy Area/Product Indication/Function</strong> 
                        <br/>
                        (i.e., Jardiance) 
                    </td>
                    <td className="tb">
                        <strong>Material</strong>
                        <br/>
                        (a brief description) 
                    </td>
                    <td className="tb">
                        <strong>Date</strong>
                        <br/>
                        (month and year of preparation) 
                    </td>
                    <td className="tb">
                        <strong>Job Bag Code</strong>
                        <br/>
                        (Optional) 
                    </td>
                </tr>
              </tbody> 
            </table>      
            <p className="tabContent">For example: <em>‘UKIE Taltz PSA_Patient Brochure_APR23’</em></p>
            <p className="tabsContentHeading">Piece:</p>
            <ul className="ul_padding">
              <li className="tabContent">
                 Design/Screenshot of each slide including pop-ups
              </li>
              <li className="tabContent">
                 Explanation/ Screenshot of any possible functionalities
              </li>
            </ul>

            <p className="tabsContentHeading">Purpose Field:</p>
            <ul className="ul_padding">
              <li className="tabContent">
                 Explain the purpose and clarify that the live version on iPad will be seen for certification.
              </li>
              <li className="tabContent">
                 Ensure that all IVAs have associated PI included whether it is embedded within the IVA itself or hosted separately on iRep/CRM. 
              </li>
            </ul>

            <p className="tabsContentHeading">Attachments:</p>
            <ul className="ul_padding">
              <li className="tabContent">
                 If using an interactive PDF, upload as the final form{" "}
              </li>
              <li className="tabContent">
                 Change Document Template (if applicable)
              </li>
            </ul>

            <p className="tabsContentHeading">Linked Documents:</p>
            <ul className="ul_padding">
              <li className="tabContent"> Current PI</li>
              <li className="tabContent"> Content references</li>
            </ul>

            <p className="tabsContentHeading">
              Related Permissions Managed Content:
            </p>
            <ul className="ul_padding">
              <li className="tabContent">
                 Include required Permission Managed Content (PMC) documents.
              </li>
              <li className="tabContent">
                 PMC is not required for <strong>Lilly product images</strong>.
                Check with Regulatory or QA (Quality Assessment) Teams if it is the correct device image.
              </li>
            </ul>

            <p className="tabsContentHeading">Final Form:</p>
            <ul className="ul_padding"> 
              <li className="tabContent">
                Show fully functional IVA on an iPad to Certifiers or send to the
                Certifiers via Veeva (iRep) sandbox (PF1)
              </li>
            </ul>
            <p className="top_tips">Top Tips!</p>
            <p className="tabContent">
              Obtain an email from the certifier confirming that the final form has
              been reviewed which can then be saved in the Attachments section.
            </p>
          </div>
          <div id="ivaStatuses" className="tab-pane fade tabs_box">
            <p className="tabsContentHeading">Staged IVA:</p>
            <p className="tabContent">
              Allows for viewing of the approved material in Veeva CRM
              sandbox/PF1 (test environment for IVA Gatekeeper approval).
            </p>

            <p className="tabsContentHeading">Approved:</p>
            <p className="tabContent">
            Content has reached ‘Approved’ after IVA Gatekeeper approval. The IVA is now live and available to be pushed live to Sales Representatives alongside the briefing guide in the relevant Teams channel for the Sales Representatives.
            </p>

            <p className="tabsContentHeading">Cancelled:</p>
            <p className="tabContent">
              The Veeva workflow has been cancelled. The material is still
              available in VVPM for it to be copied if needed.
            </p>

            <p className="tabsContentHeading">Withdrawn:</p>
            <p className="tabContent">
              Content that is withdrawn is removed from Production and is not
              available to Customer-Facing Teams. Use{" "}
              <strong>‘Withdraw’</strong> to temporarily remove, make updates
              that do NOT impact the final form of the piece (e.g., amending a broken
              link) and push live again.{" "}
              <strong>
                Updates that require amending of the content/final form will
                require expiring the entire IVA binder
              </strong>{" "}
              and re-initiating the IVA process.
            </p>

            <p className="tabsContentHeading">Expired:</p>
            <p className="tabContent">
              Content that is expired is no longer visible in either staging or
              production environments. Use <strong>‘Expire’</strong> to
              permanently remove an IVA from production.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
            <button
              className="nav-link"
              id="v-pills-faqs-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-faqs"
              type="button"
              role="tab"
              aria-controls="v-pills-faqs"
              aria-selected="false"
            >
              FAQs
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">IVA Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Planning"
                    defaultOpen={true}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-4 planning">
                        <div className="comman_width_e">
                          <Phase1 ref={svgRef1} className="IvaShape1" />
                        </div>
                      </div>
                      <div className="col-lg-8 planning p_font pt-3 flowsSecondPart">
                        <div
                          id="campaign_listed_cet"
                          style={{
                            display:
                              P1visibleSection === "CET" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">CAMPAIGN LISTED ON CET</p>
                          <p className="flowsContent">
                            Campaigns should be listed on CET as part of IPA
                            process. Check if this material is part of your
                            plans, review the set KPIs and tactic links on CET.{" "}
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "Pre-Veeva"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">PRE-VEEVA MEETING</p>
                          <p className="flowsContent">
                            Planning is everything! The core objectives of a
                            pre-Veeva discussion should not be overlooked. These
                            are to ensure that:
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               The reviewer is never presented with a
                              material in Veeva Vault PromoMats (VVPM) that they have no background
                              information on, and{" "}
                            </li>
                            <li className="flowsContent">
                               The review team understand its current
                              priorities for review.
                            </li>
                          </ul>
                          <p className="flowsContent">Together these aim to
                              provide a smoother and quicker approval process
                              for materials.</p>
                          <p className="flowsContent">Visit{" "}
                              <a href={webConfig.LillyPlanning} target="_blank">
                                Planning (lilly.com)
                              </a>{" "}
                              to learn more about pre-Veeva meetings.</p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion2" />
                  <Accordion
                    className="accordion2"
                    title="Phase 2: Material Readiness"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                        <div className="comman_width">
                          <Phase2 ref={svgRef2} className="IvaShape2" />
                        </div>
                      </div>
                      <div className="col-lg-7 planning pt-4 flowsSecondPart">
                        <div
                          id="briefing"
                          style={{
                            display:
                              P2visibleSection === "Briefing"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">BRIEFING</p>
                          <p
                            className="pop flowsContent"
                            style={{ marginBottom: "0px" }}
                          >
                            Ensure that the team working on material development
                            fully understands the brief. Often a call is best
                            way to move things forward quickly. A good brief to
                            the agency means that the Material Administrator
                            provides all necessary visuals, texts and links
                            upfront. Briefing could be completed by the Material
                            Administrator. The creative agency should advise on
                            delivery timelines after the briefing is received.
                          </p>
                          <p className="flowsHeading briefingRemember">
                            Remember
                          </p>
                          <p className="flowsContent">
                            The Digital Solutions team (DST) is briefed on creating the IVA HTML. Briefing
                            must be provided to them using the template given through the{" "}
                            <a
                              href={webConfig.DSTRequestPowerApp}
                              target="_blank"
                            >
                              DST Request PowerApp
                            </a>{" "}. A good brief to the agency means that
                            the Material Coordinator/Owner provides all necessary visuals, texts and links upfront that will be included in the IVA.
                          </p>
                        </div>
                        <div
                          id="artwork_delivered"
                          style={{
                            display:
                              P2visibleSection === "Artwork" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">ARTWORK DELIVERED</p>
                          <p className="flowsContent">
                          It is the responsibility of the Material Administrator to review the artwork provided for accuracy before submitting to Medical, Legal, Regulatory (MLR) for approval. At this stage, it is best practice to align with reviewers before submitting the material to MLR.
                          </p>
                        </div>
                        <div
                          id="meets_expectations"
                          style={{
                            display:
                              P2visibleSection === "Expectations"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            ARTWORK MEETS EXPECTATIONS?
                          </p>
                          <p className="flowsContent">If the artwork meets expectations, then initiate MLR. If the artwork does not meet expectations, then it is always advised to book a call with the agency to go through the changes required. (Back-and-forth through email communication has proven to be time consuming!)</p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion3" />
                  <Accordion
                    className="accordion3"
                    title="Phase 3: Material Approval & Launch"
                    defaultOpen={false}
                    isOpen={isPhase3Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-8 planning main-content">
                        <Phase3 ref={svgRef3} className="IvaShape3" />
                      </div>
                      <div className="col-lg-4 planning flowsSecondPart side-content">
                        <div
                          id="link4"
                          style={{
                            display:
                              P3visibleSection === "Initiate"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">INITIATE MLR APPROVAL</p>
                          <p className="flowsContent">
                          This is the stage where the Veeva Coordinator uploads the material to VVPM for reviewers to provide a verdict on it. Scroll up to see the VVPM Job Bag Standards. 
                          </p>
                          <p className="flowsHeading">Remember</p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Ensure the IVA is uploaded initially as a PDF
                              and later changed to the final format for
                              certification.
                            </li>
                            <li className="flowsContent">
                               A separate approval must be initiated for an internal briefing guide to be added as a supporting document – see the flow for internal documents.
                            </li>
                            <li className="flowsContent">
                               For PRA, the IVA is not hosted on iRep but instead hosted on Veeva for Value Access Managers (VAMs) to access it directly. Therefore, no Gatekeeper or access steps are required.
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link5"
                          style={{
                            display:
                              P3visibleSection === "MlrVerdict"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">MLR VERDICT</p>
                          <p className="flowsContent">
                            There are three types of verdicts that the material
                            could get:{" "}
                          </p>
                          <p className="flowsContent">
                            <strong>1. Approved for Production</strong> – The
                            Material has been blessed by the reviewers. Go ahead
                            and ask for the material to be developed into final
                            format.
                          </p>
                          <p className="flowsContent">
                            <strong>2. Approved with changes</strong> – The
                            material has some minor errors/updates required
                            (like spelling, grammatical errors). Incorporate the
                            advised updates/corrections on the material and ask
                            for the material to be developed into final format.{" "}
                          </p>
                          <p className="flowsContent">
                            <strong>3. Revise and Resubmit</strong> – The material has too many problems and the reviewer advises the Veeva Coordinator to rework on it and submit again. Go ahead and ask the agency to re-do the artwork.
                          </p>
                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">
                            Request that the approvers choose{" "}
                            <strong>‘Approved with Changes’</strong> as a
                            verdict so the IVA can be re-uploaded as a
                            PowerPoint file for certification.
                          </p>
                        </div>
                        <div
                          id="link7"
                          style={{
                            display:
                              P3visibleSection === "InitiateCertification"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">INITIATE CERTIFICATION</p>
                          <p className="flowsContent">
                            This is the stage where the Veeva Coordinator
                            uploads the final form of the material in VVPM
                            for Certifiers to provide a verdict on it.
                          </p>
                        </div>
                        <div
                          id="link8"
                          style={{
                            display:
                              P3visibleSection === "CertificationVerdict"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CERTIFICATION VERDICT</p>
                          <p className="flowsContent">
                            There are three types of verdicts you could get:
                          </p>
                          <p className="flowsContent">
                            <strong>1. Certified</strong> – The material is Approved for Distribution now. Go ahead to review/set the Inventory Information in VVPM and set the{" "}
                            <strong>Actual Date of First Use</strong>.{" "}
                          </p>
                          <p className="flowsContent">
                            <strong>2. Revise and Resubmit</strong> (for
                            certification) – The material has errors and/or requires updates. Go ahead and ask the agency/team to make the advised changes and re-submit for certification.
                          </p>
                          <p className="flowsContent">
                            <strong>3. Not Certified</strong> – The material has too many problems and the Certifier advises the Material Administrator to rework on it and start a new MLR workflow. Go ahead and ask the agency to re-do the artwork.
                            artwork.
                          </p>
                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">
                            The verdict <strong>‘Certified’</strong> does not
                            mean that the material is now live. Additional steps
                            are needed to move it to the environment where it
                            will be available to the end user.
                          </p>
                        </div>
                        <div
                          id="link9"
                          style={{
                            display:
                              P3visibleSection === "FinalSteps"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            FINAL STEPS BEFORE 'GO-LIVE'
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               <strong>Actual Date of First Use</strong> is set by the Veeva Coordinators and is typically put as the date the material has been certified in VVPM, unless there is a specific date for when the usage of a material will start (e.g., launch date, after a salesforce training).
                            </li>
                            <li className="flowsContent">
                              <strong> Inventory Information</strong> – Veeva Coordinators would have added this earlier to VVPM metadata and it should be checked when the material goes live. This step supports efficient material management and withdrawal.{" "}
                            </li>
                            <li className="flowsContent">
                               The <strong>Expiration Date</strong> is usually set for 2 years. This helps with efficient material (inventory) management and withdrawal.
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link9"
                          style={{
                            display:
                              P3visibleSection === "CLM" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            CLM OWNER CREATES THE IVA BINDER
                          </p>
                          <p className="flowsContent">Closed Loop Marketing (CLM) Owner completes the binder classification metadata to create the CLM presentation in Veeva and deploys it for testing.</p>
                          <p className="flowsContent">Follow the{" "}
                              <a
                                href={webConfig.IVAAutoPackagePresentationGuide}
                                target="_blank"
                              >
                                IVA Auto-Package Presentation Guide
                              </a>{" "}
                              for a full step-by-step guide on how to create and
                              publish the binder</p>
                          <p className="flowsHeading">Remember</p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Follow the IVA auto-package presentation guide in 
                              detail (when IVA is HTML format) – there are multiple 
                              steps that must not be skipped and details that must 
                              be provided to ensure that the IVA is correctly published. 
                            </li>
                            <li className="flowsContent">
                               Material Co-Ordinator/Owner should check in the{" "}
                              <strong>Assignment Criteria</strong> in Veeva
                              metadata if Salesforce code is added correctly so
                              that it is only available to the relevant team.
                            </li>
                          </ul>
                          <p className="flowsHeading">Uk</p>
                          <p className="flowsContent">
                            The Certifier checks the final format on iPad and email 
                            confirming if this check has been completed, and this email 
                            is added to the VVPM job bag. This step is not needed when 
                            IVA format is Interactive PDF. 
                          </p>
                        </div>
                        <div
                          id="link11"
                          style={{
                            display:
                              P3visibleSection === "FORUK" ? "block" : "none",
                          }}
                        >
                          <h5>
                            <em>For UK</em>
                          </h5>
                        </div>
                        <div
                          id="link12"
                          style={{
                            display:
                              P3visibleSection === "Certifier"
                                ? "block"
                                : "none",
                          }}
                        >
                          <h5>
                            <em>Certifier</em>
                          </h5>
                        </div>
                        <div
                          id="link12"
                          style={{
                            display:
                              P3visibleSection === "Approves"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            IVA GATEKEEPER APPROVES ON VEEVA
                          </p>
                          <p className="flowsContent">
                            The IVA Gatekeeper must review functionality in
                            Sandbox/PF1.
                            <br />
                            <br />
                            Before completing the approval task in VVPM, IVA
                            Gatekeepers{" "}
                            <strong>
                              must
                            </strong>{" "}
                            check the following:{" "}
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               The salesforce code is correct in Veeva{" "}
                              <strong>Assignment Criteria</strong>.
                            </li>
                            <li className="flowsContent">
                               The Expiration Date matches with the{" "}
                              <strong>Expiration Date</strong> in the certified
                              job bag.{" "}
                            </li>
                            <li className="flowsContent">
                               For all NE Hub countries, the created briefing
                              guide is approved and sent (via email) to the Sales
                              Representatives.
                            </li>
                          </ul>
                          <p className="flowsHeading">Remember</p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               Retire the old IVA multichannel presentation 
                               (HTML/PowerPoint version of the IVA).
                            </li>
                            <li className="flowsContent">
                               The access right required for an IVA Gatekeeper will be different from that for a VAE Gatekeeper.
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link12"
                          style={{
                            display:
                              P3visibleSection === "Inform" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            INFORM THE CUSTOMER FACING TEAM
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                              {" "}
                              <strong>
                                Inform the customer facing team that the IVA and
                                briefing guide is now available for use.
                              </strong>{" "}
                              The IVA will be visible once their iPads have been
                              re-synced. Syncing needs to be manually triggered
                              by the Customer-Facing Team and may take ~1 hour.
                              (The Customer-Facing Team would have been advised to sync daily.)
                            </li>
                            <li className="flowsContent">
                               IVA briefing guides and IVA go-live
                              communications are to be uploaded on the Microsoft
                              Teams channel that the brand team has created for
                              Sales Representatives{" "}
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link12"
                          style={{
                            display:
                              P3visibleSection === "Modified"
                                ? "block"
                                : "none",
                          }}
                        >
                          <h5>
                            <em>Modified</em>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion4" />
                  <Accordion
                    className="accordion4"
                    title="Phase 4: Material Management"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning">
                        <Phase4 ref={svgRef4} className="IvaShape4" />
                      </div>

                      <div className="col-lg-6 planning p-3 flowsSecondPart">
                        <div
                          id="link15"
                          style={{
                            display:
                              P4visibleSection === "Quarterly"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            QUARTERLY INVENTORY REVIEWS BY BRAND TEAM
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               For Operational Excellence in Material
                              Management, the NE Hub has a quarterly Operational
                              Review of Inventory. The Brand Leader/Business
                              Unit Director should lead this exercise with their
                              team to <strong>review the list of material in VVPM</strong> and
                              decide whether to update the material, leave it as
                              it is for now or withdraw it from VVPM. Understand
                              more in the{" "}
                              <a
                                href={webConfig.OperationalQuarterlyReviewGuidance}
                                target="_blank"
                              >
                                Operational Quarterly Review Guidance
                              </a>.
                            </li>
                            <li className="flowsContent">
                               Always <strong>check inventory information</strong> to see if any
                              metadata needs to be updated.
                            </li>
                            <li className="flowsContent">
                               <strong>Review briefing guides</strong> for any update required
                              and storage locations.
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link160"
                          style={{
                            display:
                              P4visibleSection === "UpdateOfMaterial"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">UPDATE OF MATERIAL</p>
                          <p className="flowsContent">
                            An outcome of the quarterly material review (or other reasons such as Prescribing Information [PI] update) might result in a need to update the material. Updates in the material initiates the process right from the beginning, i.e., ‘briefing’ and the withdrawal process of the existing version.
                          </p>
                          <p className="flowsHeading">CET Update</p>
                          <p className="flowsContent">
                            IVAs are not required to be listed on Customer Engagement Tool (CET). 
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion5" />
                  <Accordion
                    className="accordion5"
                    title="Phase 5: Material Withdrawal"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning p-3">
                        <div className="comman_width_e">
                          <Phase5 ref={svgRef5} className="IvaShape5" />
                        </div>
                      </div>
                      <div className="col-lg-6 planning p-3 flowsSecondPart">
                        <div
                          id="withdrawal_phase"
                          style={{
                            display:
                              P5visibleSection === "Gatekeeper"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            MATERIAL WITHDRAWAL PHASE
                          </p>
                          <p className="flowsContent">
                            Material Withdrawal phase starts with the brand
                            team´s decision to discontinue a material and it
                            ends with completed and documented destruction of
                            the material.{" "}
                          </p>
                          <p className="flowsContent">
                           The materials need to be removed from all storage
                           locations and circulation before initiating the 
                           withdrawal in the Withdrawal Tool (wherever applicable). 
                           The <strong>Inventory Information</strong> field in VVPM will help the 
                           Material Administrator to capture all storage 
                           locations for effective destruction.{" "}
                          </p>
                          <p className="flowsHeading">
                            Withdrawal and Retirement 
                          </p>
                          <p className="flowsContent">
                            Material Administrators initiate the retirement process by removing items from being accessed as per the inventory list in VVPM. 
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                                1. <strong>IVA Gatekeeper retires VMC version of the IVA
                                in VVPM.</strong>This action removes the live version
                                from iPads of the Customer-Facing team (after
                                their iPad has been synced).  
                            </li>
                            <li className="flowsContent">
                                2. <strong>The Material Administrator initiates a withdrawal
                                notification to the Customer-Facing team.</strong>
                                The Customer-Facing team acknowledges withdrawal and 
                                removes all digital versions by the set date. 
                                Veeva Coordinator retires the job bag in VVPM.  
                            </li>
                            
                          </ul>
                        </div>
                        <div
                          id="withdraw_briefing_guide"
                          style={{
                            display:
                              P5visibleSection === "Withdraw"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            WITHDRAW ASSOCIATED BRIEFING GUIDE
                          </p>
                          <p className="flowsContent">
                            The materials need to be removed from all storage
                            locations and circulation before initiating the
                            withdrawal in the Withdrawal Tool (wherever
                            applicable). The{" "}
                            <strong>Inventory Information</strong> field in VVPM
                            will help the material administrator to capture all
                            storage locations for effective destruction.
                          </p>
                        </div>
                        <div
                          id="send_notification"
                          style={{
                            display:
                              P5visibleSection === "Send" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            SEND WITHDRAWAL NOTIFICATION
                          </p>
                          <p className="flowsContent">
                            <strong>
                              The material administrator initiates a withdrawal
                              notification to the customer facing team.
                            </strong>{" "}
                            The Customer facing team acknowledges withdrawal and
                            remove all digital versions by the set date.
                          </p>
                        </div>
                        <div
                          id="piece_retires"
                          style={{
                            display:
                              P5visibleSection === "Veeva" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            VEEVA COORDINATOR RETIRES THE MATERIAL IN VVPM
                          </p>
                          <p className="flowsContent">
                            <strong>
                              IVA gatekeeper retires VMC version of IVA in VVPM.
                            </strong>{" "}
                            This action removes the live version from iPads of
                            the customer facing team (after their iPad has been
                            synced). Material administrators initiate the
                            retirement process by removing items from being
                            accessed as per inventory list in VVPM. Veeva
                            coordinator retires the job bag in VVPM.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td className="tb">
                        <h6>Veeva Owner</h6>
                      </td>
                      <td className="tb">
                        <p>
                          The Veeva Owner is also known as the Uploader (please
                          see 'Veeva uploader')
                        </p>
                      </td>
                    </tr>
                    <tr>
                        <td className="tb">
                            <h6>Material Administrator</h6>
                        </td>
                        <td>
                          This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                        </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Material Originator</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:{" "}
                          <ul className="ul_list">
                            <li>
                              1. Having overall responsibility and
                              accountability for the material{" "}
                            </li>
                            <li>
                              2. Ensure that no item is used without the
                              necessary approvals
                            </li>
                            <li>
                              3. Ensuring timely change and/or withdrawal, where
                              needed
                            </li>
                          </ul>
                          For further information, please see{" "}
                          <a
                            href={webConfig.PMAPJobAid}
                            target="_blank"
                          >
                            PMAP Job Aid.
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Veeva Uploader</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Person who uploads the material to Veeva, such as an
                          assigned member from the marketing, medical or PRA
                          teams, and is assigned the Veeva Owner role.
                          <br />
                          Responsible for:
                          <ul className="ul_list">
                            <li>1. Uploading the material to Veeva</li>
                            <li>
                              2. Performing all activities related to upload
                            </li>
                          </ul>
                          For further information, please see{" "}
                          <a
                            href={webConfig.PMAPJobAid}
                            target="_blank"
                          >
                            PMAP Job Aid.
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Veeva Co-ordinator</h6>
                      </td>
                      <td className="tb">
                        <p>
                          The Coordinator is always a Lilly employee. The Veeva
                          Coordinator can only be the Brand Manager (or similar
                          role).
                          <br />
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Reviewing the item before sending for MLR
                              review
                            </li>
                            <li>2. Managing the document workflow</li>
                            <li>3. Assigning reviewers</li>
                            <li>4. Managing expiry or periodic review</li>
                          </ul>
                          For further information, please see{" "}
                          <a
                            href={webConfig.PMAPJobAid}
                            target="_blank"
                          >
                            PMAP Job Aid.
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Business Reviewer</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Business Reviewer is an experienced commercial
                          employee.
                          <br />
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Ensuring all promotional materials align with
                              the brand strategy, Lilly Brand Book, Product
                              Brand Book
                            </li>
                            <li>
                              2. Ensuring all materials are of good quality,
                              have correct Veeva metadata and adhere to internal
                              policies and local codes
                            </li>
                          </ul>
                          For further information, please see{" "}
                          <a
                            href={webConfig.PMAPJobAid}
                            target="_blank"
                          >
                            PMAP Job Aid.
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>UK Medical Certifier</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Registered medical practicioner or UK-registered
                          Pharmacist
                          <br />
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Ensuring that the material is in accordance
                              with the requirements of the local code;
                            </li>
                            <li>
                              2. Ensuring that the material is not inconsistent
                              with the marketing autorisation/SmPC (if
                              applicable);
                            </li>
                            <li>
                              3. Ensuring that the material is a fair and
                              truthful representation of the facts about the
                              medicine (if applicable);
                            </li>
                            <li>4. Approving the material in its final form</li>
                            <li>
                              5. Ensuring the material has the appropriate
                              reviewewers and that the Veeva metadata is
                              correct.
                            </li>
                          </ul>
                          For further information, please see{" "}
                          <a
                            href={webConfig.PMAPJobAid}
                            target="_blank"
                          >
                            PMAP Job Aid.
                          </a>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>UK Technical Medical Reviewer</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Person who is responsible for ensuring that the
                          material contains accurate information in line with
                          the references that have been used and for ensuring
                          that the Veeva metadata is correct and that the PMC
                          documents are included (if applicable).
                          <ul className="ul_list">
                            <li>
                              {" "}
                              For further information, please see{" "}
                              <a
                                href={webConfig.PMAPJobAid}
                                target="_blank"
                              >
                                PMAP Job Aid.
                              </a>
                            </li>
                          </ul>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Nordic Code Approver</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Code Approver must be a medical practitioner or a
                          pharmacist (except for Sweden) and is responsible for:
                          ensuring that the material is in line with the local
                          Codes; ensuring that the material being approved has
                          the appropriate reviewers; and ensuring that the Veeva
                          metadata is correct.
                          <ul className="ul_list">
                            <li>
                              {" "}
                              For further information, please see{" "}
                              <a
                                href={webConfig.PMAPJobAid}
                                target="_blank"
                              >
                                PMAP Job Aid.
                              </a>
                            </li>
                          </ul>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Nordics Technical Medical Reviewer</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Person who is responsible for ensuring that the
                          material contains accurate information in line with
                          the references that have been used and for reviewing
                          the material from a medical perspective.
                          <ul className="ul_list">
                            <li>
                              {" "}
                              For further information, please see{" "}
                              <a
                                href={webConfig.PMAPJobAid}
                                target="_blank"
                              >
                                PMAP Job Aid.
                              </a>
                            </li>
                          </ul>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Indegene</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Creating artwork as per material originator
                              brief
                            </li>
                            <li>
                              2. Creating formatted PI PDFs (for both UK and Nordics)
                            </li>
                          </ul>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Material Originator</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:
                          <ul className="ul_list">
                            <li>1. IVA creation/localisation</li>
                            <li>2. Co-ordinating MLR approval</li>
                            <li>
                              3. Completion of briefing template and defining
                              audience list accurately.
                            </li>
                          </ul>
                          May also be CLM owner and gatekeeper
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Gatekeeper</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Ensuring IVA matches final form and functions
                              correctly
                            </li>
                            <li>
                              2. Withdrawing/retiring binder and individual pages
                              when requested by material originator
                            </li>
                          </ul>
                          May also be material originator and CLM owner.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>CLM Owner</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Creating CLM presentation and accurate
                              completion of CLM metadata
                            </li>
                            <li>2. Deploying for testing</li>
                            <li>
                              3. Pushing asset for gatekeeper approval upon
                              confirmation of successful testing
                            </li>
                          </ul>
                          May also be material originator and gatekeeper.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>DST</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:
                          <ul className="ul_list">
                            <li>
                              1. Testing IVA if requested by material originator
                            </li>
                          </ul>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="tb">
                        <h6>Meeting Owner</h6>
                      </td>
                      <td className="tb">
                        <p>
                          Responsible for:
                          <ul className="ul_list">
                            <li>1. Briefing speaker on requirements</li>
                            <li>2. Liaising regarding slides</li>
                            <li>
                              3. Ensuring speaker contracting, briefing and
                              speaker training are completed
                            </li>
                          </ul>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>

              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>Expanded</strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                      Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CET</td>
                    <td className="tb">Customer Engagement Tool</td>
                    <td className="tb">
                      Planning and execution tools combine to form the CET. 
                      With CET, customer plans can be created and campaigns can 
                      be managed in one place.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CLM Owner</td>
                    <td className="tb">Closed Loop Marketing</td>
                    <td className="tb">
                      Listed owner of a CLM presentation, also referred to as an IVA Gatekeeper. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CLM Presentation</td>
                    <td className="tb">Closed Loop Marketing</td>
                    <td className="tb">
                      Final format of an IVA to be used and deployed to select
                      Sales Representatives
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Conversion Metric </td>
                    <td className="tb"></td>
                    <td className="tb">
                      Metrics used to measure the number of valuable actions 
                      customers take through a digital marketing channel. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey</td>
                    <td className="tb"></td>
                    <td className="tb">
                      The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">GCO</td>
                    <td className="tb">Global Customer Operations</td>
                    <td className="tb">
                      A global team responsible for customer management tools
                      and operations
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">IVA</td>
                    <td className="tb">Interactive Visual Aid</td>
                    <td className="tb">
                      An Interactive Visual Aid used by Customer Facing Team Representative to interact with HCPs virtually or face to face. IVA could be in different formats, i.e. HTML, iPDF or PowerPoint.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">
                    Goals or Metrics set behind a campaign and or tactic.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">PF1</td>
                    <td className="tb">Partner Force Login</td>
                    <td className="tb">
                      Veeva's sandbox environment for testing of VAEs
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">PMC</td>
                    <td className="tb">Permissions Managed Content</td>
                    <td className="tb">
                      An image which is owned by an entity other than Lilly and for which 
                      permission is given to use under a specific set of circumstances as 
                      defined in a contractual agreement between Lilly and the Content Owner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Sandbox</td>
                    <td className="tb"></td>
                    <td className="tb">
                      A testing environment in a computer system in which new or
                      untested software can be run securely
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Tactic</td>
                    <td className="tb"></td>
                    <td className="tb">
                      Tactics are specific to selected channels to drive customers to 
                      reach a piece of content. Tactics are associated with estimated 
                      KPI metric (goal) and result reports (actuals).  
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Veeva CLM</td>
                    <td className="tb">Closed Loop Marketing</td>
                    <td className="tb">
                      Veeva's closed loop marketing (CLM) functionality allows
                      commercial and medical reps to engage with HCPs and key
                      opinion leaders hosted in Veeva Vault. Users can display
                      marketing or informational material during calls and track
                      CLM activity for accounts, allowing marketers to gain
                      insight into their targets and improve the content to
                      maximize relevance and effectiveness.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Veeva CRM</td>
                    <td className="tb">Customer Relationship Manager</td>
                    <td className="tb">
                      Veeva's customer relation manager (CRM) is an application
                      built on the Salesforce platform designed for the
                      pharmaceutical and biotechnology industries. Veeva CRM
                      includes a data model, application logic and tailored user
                      interface controls to support the pharmaceutical and
                      biotechnology industries while leveraging much of the
                      standard Salesforce platform functionality, reporting,
                      configuration and maintenance capabilities.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">VMC</td>
                    <td className="tb">Vault Management Console</td>
                    <td className="tb">
                      An internal tool used to provision domains and Vaults
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">VMC binder/binder</td>
                    <td className="tb">Vault Multi Channel</td>
                    <td className="tb">
                      Binder used to package and distribute content such as IVA
                      slides and videos for on-demand use
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">VVPM</td>
                    <td className="tb">Veeva Vault PromoMat</td>
                    <td className="tb">
                      Vault is a cloud-based Content Management System (CMS) developed by Veeva Systems. 
                      PromoMats is a Vault application to manage the process of creating, reviewing and 
                      approving promotional materials, claims and supporting information. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">iRep</td>
                    <td className="tb">iRepresentative</td>
                    <td className="tb">
                      iPad application used by Sales Representatives to manage
                      their HCP consent, contact profiles, and schedules.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">LiteVA</td>
                    <td className="tb">Lite Visual Aid</td>
                    <td className="tb">
                      Autopackaged presentation created in MS PowerPoint for
                      faster delivery and approvals. No third party needed to
                      create these.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.IVACoreDoc}
                  target="_blank"
                >
                  IVA core document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            {/* <div
              className="tab-pane fade"
              id="v-pills-faqs"
              role="tabpanel"
              aria-labelledby="v-pills-faqs-tab"
            >
              <h4 className="h1-font comman_hd">FAQs</h4>
              <br />
              <p className="faqsQuesHeading">
                What are different file format types for visual aids for use by
                customer facing teams
              </p>
              <p className="flowsContent">A visual aid can be created</p>
              <ol>
                <li className="flowsContent">
                  Simple PDF file – This format is only advised when simple
                  information needs to be provided with quick turnaround time.
                  A watch-out for PDF is the risk of it being downloaded on 
                  local computer and still being used/shared even when the material 
                  has expired. Take care to properly retire the material from all 
                  locations and advise the Customer-Facing team to do the same.{" "}
                </li>
                <li className="flowsContent">
                  Interactive PDFs (or iPDF) – These are PDF files with links within 
                  the file. iPDFs are simple and quicker to create, less expensive 
                  and artwork creation is also simpler. The iPDF provides basic 
                  interactivity. On the other hand, it is not possible to track each 
                  slide, limited in functionality like the ability to jump from slide 
                  to slide​, not screen responsive and does not work well with different 
                  iPad screen size resolutions.
                </li>
                <li className="flowsContent">
                  HTML IVA – These can be made as interactive as one needs, with 
                  a high level of visual detail. It is easy to navigate flow in 
                  HTML IVA, and it is responsive to different screens/browsers. 
                  On the other hand, it is significantly more expensive and resource 
                  intensive to be produced. 
                </li>
              </ol>
            </div> */}
            <div
              className="tab-pane fade"
              id="v-pills-faqs"
              role="tabpanel"
              aria-labelledby="v-pills-faqs-tab"
            >
              <h4 className="h1-font comman_hd">FAQs</h4>
              <div id="FAQ1">
                <Accordion
                  className="faqAccordion"
                  title="What are the different file format types for visual aids for use by Customer-Facing teams?"
                  defaultOpen={false}
                >
                  <div className="faqcontent">
                    <p>A visual aid can be created by: </p>
                    <ol>
                      <li className="flowsContent">
                        Simple PDF file – This format is only advised when simple information 
                        needs to be provided with quick turnaround time. A watch-out for PDF 
                        is the risk of it being downloaded on local computer and still being 
                        used/shared even when the material has expired. Take care to properly 
                        retire the material from all locations and advise the Customer-Facing 
                        team to do the same.  
                      </li>
                      <li className="flowsContent">
                        Interactive PDFs (or iPDF) – These are PDF files with links within 
                        the file. iPDFs are simple and quicker to create, less expensive 
                        and artwork creation is also simpler. The iPDF provides basic 
                        interactivity. On the other hand, it is not possible to track each slide, 
                        limited in functionality like the ability to jump from slide to slide​, 
                        not screen responsive and does not work well with different iPad screen 
                        size resolutions.
                      </li>
                      <li className="flowsContent">
                        HTML IVA – These can be made as interactive as one needs, with a 
                        high level of visual detail. It is easy to navigate flow in HTML IVA, 
                        and it is responsive to different screens/browsers. On the other hand, 
                        it is significantly more expensive and resource intensive to be produced. 
                      </li>
                    </ol>
                  </div>  
                </Accordion>
              </div>
            </div>  
          </div>
        </div>
      </div>

      {/*Nordics Left cert*/}
      <div id="popup_flight_travlDil11" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">NORDICS</p>
          <p className="flowsContent">
            If the material gets the verdict <strong>‘Not Certified’</strong>{" "}
            use <strong>‘Create Draft’</strong> function in VVPM to use
            the same job bag code for the updated version of the material.
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>

      {/*UK Above cert */}
      <div id="popup_flight_travlDil13" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">UK</p>
          <p className="flowsContent">
            Due to the nature of the IVA testing environment, the Certifiers must
            test the IVA once <strong>‘Approved for Distribution’</strong> in
            VVPM and confirm that they have checked the final form via iPad. An
            email should be sent to the Veeva Coordinator confirming this, and
            this email should be uploaded to VVPM.
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>

      {/*Nordics Below cert*/}
      <div id="popup_flight_travlDil14" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">NORDICS</p>
          <p className="flowsContent">
            Please check the{" "}
            <a
              href={webConfig.NordicPMAPGuidance}
              target="_blank"
            >
              Nordic PMAP Guidance
            </a>{" "}
            for detailed information on statutory copy submission.
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>

      {/*UK2 Materials*/}
      <div id="popup_flight_travlDil16" className="overlay_flight_traveldil">
        <div className="popup_flight_travlDil">
          <p className="flowsHeading">UK</p>
          <p className="flowsContent">
          Consider if the original briefing guide for Sales Representatives covers the update made. Material Owners should either re-send the original briefing guide or arrange for the briefing guide to be re-worked, re-approved and re-deployed with the latest version of the material.
          </p>
          <a className="close_flight_travelDl" href="#/">
            &times;
          </a>
        </div>
      </div>
    </div>
  );
};

export default IVA;
