import React from "react";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import "./LendingCard.css";
import PropTypes from "prop-types";

const LendingCard = ({ title, htmlString, links, flexColSize }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className={`${flexColSize} mb-4`}>
      <div className="card">
        <div className="card-body">
          <p className="card-body-title">{parse(title)}</p>
          <div className="card-body-text">
            {parse(htmlString)}
          </div>
        </div>
        <div className="card-body-footer">
          {links.map((link, index) => (
            <a key={index} href={link.url} onClick={scrollToTop}  className="card-links">
              {/* {link.label} */}
              {parse(link.label)}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LendingCard;

LendingCard.propTypes = {
  title: PropTypes.string.isRequired,
  htmlString: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  flexColSize: PropTypes.string.isRequired,
};

