import React from "react";
import "../css/HighLevelProcess.css";
import {webConfig} from '../configs/webConfig'
import Accordion from "../components/Accordion";
import { useState } from "react";
import { ReactComponent as PlanningPrioritisation } from "../SVG/High_Level_Process_SVG/Planning&Prioritisation.svg";
import { ReactComponent as ConceptMeeting } from "../SVG/High_Level_Process_SVG/Concept_Meeting.svg";

const HighLevelProcesses = () => {
  const [isVisible1, setIsVisible1] = useState(true);
  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  High Level Process
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <br />
        <h2 className="h1-font comman_hd">High Level Process</h2>
        <div className="row my-4">
          <img
            className="material_lifecycle_steps"
            src="images/material_lifecycle_phases.png"
            style={{marginLeft:"0px"}}
          />
          {/* <div className="col-lg-4">
            <img
              className="material_lifecycle_flow"
              src="images/material_lifecycle_illustration.png"
            />
          </div> */}
          <div className="col-lg-12 HighLevelProcessesIntro">
            <p>
              <em>
                <p>
                  Campaign execution does not start with a briefing or job bag
                  creation. It must start the year before with Annual Planning
                  and Prioritisation. Risk Assessment is not a one-off activity
                  but a daily practice. A campaign does not end with the
                  Material Launch. Lifecycles continues until the material has
                  been properly withdrawn.
                </p>
                <p>
                  There are 6 Foundational Bricks to the lifecycle of Campaign
                  planning and tactic creation. Expand each of these
                  Foundational bricks below to learn more!{" "}
                </p>
              </em>
            </p>
          </div>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="row">
          <div className="HighLevelProcesses">
            <Accordion
              className="HLPaccordion1"
              title="Planning & Prioritisation"
              defaultOpen={false}
            >
              <div className="HLPContent">
                <p>
                  <PlanningPrioritisation className="PlanningPrioritisation" />
                </p>
                <p>
                  <em>
                    Planning and Prioritisation begins with an Integrated
                    Planning Approach (IPA) process that is typically initiated
                    in June each year and ends in November with an Operational
                    Plan added on Customer Engagement Tool (CET).
                  </em>
                </p>
                <p>
                  <b>Integrated Planning Approach Process</b>
                  <br />
                  The IPA process is usually initiated in June as the Global
                  Brand Plan is rolled out by International Business Unit (IBU)
                  for the subsequent calendar year. Click{" "}
                  <a
                    className="HLPURL"
                    href={webConfig.IPA}
                    target="_blank"
                  >
                    IPA
                  </a>{" "}
                  to learn about different stages of IPA.
                </p>
                <p>
                  <b>Operational Planning</b>
                  <br />
                  Global Customer Solution work should start with a Current
                  Solution Assessment and creation of the Operational Plan.
                  <ul>
                    <li>
                       Before embarking on New Customer Solution work, Global
                      teams should first gather an inventory of all existing
                      Customer Solutions across Global, IBU and Wave 1
                      Affiliates and then assess the effectiveness of those
                      Solutions at meeting customer jobs and Key Player
                      Objectives (KPOs). Keep, refine or stop Solutions based on
                      this review.
                    </li>
                    <li>
                       If gaps in customer jobs still exist, a new Solution
                      ideation can be pursued.
                    </li>
                  </ul>
                </p>
                <p>
                  <b>Execution Data on CET</b>
                  <br />
                  All the Operational Planning needs to be uploaded on CET with
                  clear Strategic Initiatives (SIs), related campaigns/tactics
                  and the Key Performance Indicators (KPIs) for these. Access
                  CET here at{" "}
                  <a
                    className="HLPURL"
                    href={webConfig.CET}
                    target="_blank"
                  >
                    CET.
                  </a>
                </p>
                <p>
                  <b>Plan to Win Submission</b>
                  <br />
                  Planning and Prioritisation for the subsequent calendar year
                  is completed once 'Plan to Win' for each brand is submitted
                  and approved by the local leadership team. Check out more on
                  this at{" "}
                  <a
                    className="HLPURL"
                    href={webConfig.NEMarketing}
                    target="_blank"
                  >
                    NE Marketing.com
                  </a>
                </p>
              </div>
            </Accordion>
            <br />
            <Accordion
              className="HLPaccordion1"
              title="Concept Meeting (If required)"
              defaultOpen={false}
            >
              <div className="HLPContent">
                <p>
                  <ConceptMeeting className="ConceptMeeting" />
                </p>
                <p>
                  The Material Originator should hold a Concept Meeting with the
                  Reviewers and Certifiers for new key materials, materials with
                  major updates or materials based on new publications to
                  discuss the item before it is uploaded into Veeva Vault
                  PromoMats (VVPM).
                </p>
                <p>
                  All campaigns/materials have inherent risk, and it is the
                  responsibility of the Campaign Owner team to ensure that the
                  associated campaign materials and channels involved in the
                  project are fully assessed to mitigate risk. It is vital that
                  this assessment is conducted with involvement of the Code
                  Reviewer.
                </p>
                <p>
                  This is an optional step but is highly recommended if there
                  are significant new claims and/or the Campaign Concept is new
                  for the team.
                </p>
                <p>
                  Campaign Material has new significant claims or Campaign
                  Concept and/or Channel is new for the team?
                  <br />
                  If <b>'YES'</b>, then organise a Concept Meeting.
                  <br />
                  If <b>'NO'</b>, then move to the Material Planning phase.
                </p>
                <p>
                  <b>Cross-Functional Concept Meeting</b>
                  <br />
                  The project kick-off call may lead to the identification of
                  areas that need further discussion on Risk Assessment and to
                  gain a better understanding on the overall concept to ensure
                  mitigation of any risk areas.
                  <br />
                  Concept Meeting is organised specifically to ensure that:
                  <br />
                  <ul>
                    <li> The proposed project has no risk.</li>
                    <li>
                       The Code Reviewers have the right information like new
                      references.
                    </li>
                  </ul>
                  <br />
                  The Concept Meeting must be organised in a scenario where the
                  project material uses new claims and/or when the project team
                  has not undertaken this kind of a project before (New Project
                  Concept). It is vital that this assessment is <br />
                  Representation from other teams may be needed at this stage.
                  For example:
                  <ul>
                    <li>
                       The E&C Manager if compliance aspects beyond the code
                      are identified.
                    </li>
                    <li>
                       The OCE Manager if the project involves digital
                      engagement.
                    </li>
                    <li>
                       The TECH@LILLY Manager if the project involves new
                      technology.
                    </li>
                  </ul>
                  <br />
                  Consensus reached on the way forward?
                  <br />
                  If <b>'YES'</b>, then move to the Material Planning phase.
                  <br />
                  If <b>'NO'</b>, then follow the Escalation Process
                </p>
                <p>
                  <b>Escalation</b>
                  <br />
                  There may be occasions where a clear consensus is not gained
                  to move the project forward. On such rare occasions, the
                  discussion points must be escalated, and approval must be
                  gained. For such escalations, follow these steps:
                </p>
                <p>
                  <b>Escalate to Line Manager </b>– Brand Manager to discuss
                  with the Brand Leader or the Line Manager prior to the
                  discussion at weekly Certifiers/Code Approvers Meeting.
                  <br />
                  <b>Escalate for discussion with:</b>
                  <ul>
                    <li>
                      {" "}
                       Medical Certifier/Code Approver from the Medical Review
                      team or agency{" "}
                    </li>
                    <li>
                       Medical Certifier/Reviewer from the Medical (e.g.,
                      Clinical Research Physician (CRP)/Clinical Research
                      Scientist (CRS)/Medical Lead/Advisor)
                    </li>
                    <li> E&C Officer</li>
                    <li> Brand Lead</li>
                  </ul>
                  <br />
                  <b>Escalate to and gain approval from:</b>
                  <ul>
                    <li>
                       Chief Marketing Officer (CMO) or Business Unit Director
                      or Hub Medical Director
                    </li>
                    <li> Hub E&C Director</li>
                    <li>
                       Chief Operating Officer (COO) or Operations Director
                    </li>
                  </ul>
                  <br />
                  <b>HLT Approval on the Way Forward</b>
                  <br />A senior leader (the Hub General Manager) must be
                  appointed to be responsible for ensuring that the company
                  meets the requirements of code.
                </p>
              </div>
            </Accordion>
            <br />
            <Accordion
              className="HLPaccordion1"
              title="Material Planning"
              defaultOpen={false}
            >
              <div className="HLPContent">
                <p>
                  <em>
                    Planning is everything! It starts with reviewing of campaign
                    on CET and organising a Pre-Veeva Meeting.
                  </em>
                </p>
              </div>
            </Accordion>
            <br />
            <Accordion
              className="HLPaccordion1"
              title="Material Readiness"
              defaultOpen={false}
            >
              <div className="HLPContent">
                <p>
                  <em>
                    Getting the material in a good shape ahead of VVPM
                    submission helps speed up the process and save time. Visit
                    each tactic to understand the key steps and information to
                    get the material ready for VVPM submission.
                  </em>
                </p>
              </div>
            </Accordion>
            <br />
            <Accordion
              className="HLPaccordion1"
              title="Material Approval & Launch​"
              defaultOpen={false}
            >
              <div className="HLPContent">
                <p>
                  <em>
                    Understanding the Veeva Job Bag Standards, all the process
                    pre-requisites and important steps in getting the material
                    ready and approved is vital to going live. Visit each tactic
                    type to understand these steps.
                  </em>
                </p>
              </div>
            </Accordion>
            <br />
            <Accordion
              className="HLPaccordion1"
              title="Material Management​​"
              defaultOpen={false}
            >
              <div className="HLPContent">
                <p>
                  <em>
                    Once the material is live, it needs governance for accuracy
                    and relevancy. Visit the Material Management section within
                    the flow for each material type to understand the steps to
                    effective inventory management of the material(s).
                  </em>
                </p>
              </div>
            </Accordion>
            <br />
            <Accordion
              className="HLPaccordion1"
              title="Material Withdrawal"
              defaultOpen={false}
            >
              <div className="HLPContent">
                <p>
                  <em>
                    This phase starts with the marketing team’s decision to
                    discontinue the material and it ends with the completed and
                    documented destruction or withdrawal of the material.
                  </em>
                </p>
              </div>
            </Accordion>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HighLevelProcesses;
