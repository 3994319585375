import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userData: {}
};

const graphSlice = createSlice({
  name: 'graphSlice',
  initialState,
  reducers: {
    setGraphData: (state, action) => {
        state.userData = action.payload;
    }
  },
});

export const { setGraphData } = graphSlice.actions;
export default graphSlice.reducer;
