import React, { useState, useRef, useEffect } from "react";
import {webConfig} from '../configs/webConfig'
import "../css/BE.css";
import Accordion from "../components/Accordion";
import { ReactComponent as Phase1 } from "../SVG/BE_Phases_SVG/BE_Phase_1.svg";
import { ReactComponent as Phase2 } from "../SVG/BE_Phases_SVG/BE_Phase_2.svg";
import { ReactComponent as Phase3 } from "../SVG/BE_Phases_SVG/BE_Phase_3.svg";
import { ReactComponent as Phase4 } from "../SVG/BE_Phases_SVG/BE_Phase_4.svg";

const BE = () => {
  const [isPhase3Open, setIsPhase3Open] = useState();

  const svgRef1 = useRef();
  const [P1visibleSection, setP1visibleSection] = useState("CET");

  const svgRef2 = useRef();
  const [P2visibleSection, setP2visibleSection] = useState("Briefing");

  const svgRef3 = useRef();
  const [P3visibleSection, setP3visibleSection] = useState("Initiate");

  const svgRef4 = useRef();
  const [P4visibleSection, setP4visibleSection] = useState("Quarterly");

  useEffect(() => {
    const svg = svgRef1.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");

    rect1.addEventListener("click", () => {
      setP1visibleSection("CET");
    });

    rect2.addEventListener("click", () => {
      setP1visibleSection("Pre-Veeva");
    });
  }, []);
  useEffect(() => {
    const svg = svgRef2.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const diam1 = svg.getElementById("diamond1");
    const rect3 = svg.getElementById("rect3");

    rect1.addEventListener("click", () => {
      setP2visibleSection("Briefing");
    });

    rect2.addEventListener("click", () => {
      setP2visibleSection("Artwork");
    });

    diam1.addEventListener("click", () => {
      setP2visibleSection("Expectations");
    });
    const handleScrollP3 = () => {
      const elementToScrollTo = document.getElementById("accordion3");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect3.addEventListener("click", () => {
      handleScrollP3();
      setIsPhase3Open(true);
    });
  }, [isPhase3Open]);
  useEffect(() => {
    const svg = svgRef3.current;
    const rect1 = svg.getElementById("rect1");
    const diam1 = svg.getElementById("diamond1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const diam2 = svg.getElementById("diamond2");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    const rect6 = svg.getElementById("rect6");
    const rect7 = svg.getElementById("rect7");
    const rect8 = svg.getElementById("rect8");
    const rect9 = svg.getElementById("rect9");

    rect1.addEventListener("click", () => {
      setP3visibleSection("Initiate");
    });

    diam1.addEventListener("click", () => {
      setP3visibleSection("MlrVerdict");
    });

    rect2.addEventListener("click", () => {
      setP3visibleSection("DST");
    });

    rect3.addEventListener("click", () => {
      setP3visibleSection("InitiateCertification");
    });

    diam2.addEventListener("click", () => {
      setP3visibleSection("CertificationVerdict");
    });
    rect4.addEventListener("click", () => {
      setP3visibleSection("FinalSteps");
    });
    rect5.addEventListener("click", () => {
      setP3visibleSection("AdviseDST");
    });
    rect6.addEventListener("click", () => {
      setP3visibleSection("Scheduled");
    });
    rect7.addEventListener("click", () => {
      setP3visibleSection("Inform");
    });
    rect8.addEventListener("click", () => {
      //setP3visibleSection("Comments");
    });
    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect9.addEventListener("click", () => {
      handleScrollP2();
    });
  }, []);
  useEffect(() => {
    const svg = svgRef4.current;
    const rect1 = svg.getElementById("rect1");
    const rect2 = svg.getElementById("rect2");
    const rect3 = svg.getElementById("rect3");
    const rect4 = svg.getElementById("rect4");
    const rect5 = svg.getElementById("rect5");
    rect1.addEventListener("click", () => {
      setP4visibleSection("UpdateCET");
    });

    rect2.addEventListener("click", () => {
      setP4visibleSection("Quarterly");
    });

    rect3.addEventListener("click", () => {
      setP4visibleSection("UpdateOfMaterial");
    });
    const handleScrollP2 = () => {
      const elementToScrollTo = document.getElementById("accordion2");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect4.addEventListener("click", () => {
      handleScrollP2();
    });
    const handleScrollP5 = () => {
      const elementToScrollTo = document.getElementById("accordion5");
      if (elementToScrollTo) {
        const y =
          elementToScrollTo.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight / 2;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };
    rect5.addEventListener("click", () => {
      handleScrollP5();
    });
  }, []);

  return (
    <div className="be_wrapper">
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-12 mt-2">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Broadcast Email (BE)
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-4">
            <h2 className="h1-font comman_hd">Broadcast Emails- Single Send</h2>
            <p>
              <em>
                Broadcast Emails (BEs) are sometimes referred to as ‘Mass
                Emails’ or ‘Head Office Emails.’ These are sent centrally from
                the head office with one click to a designated Audience list.
                BEs are sent via a platform called Salesforce Marketing Cloud
                (SFMC) and can only be sent to customers that have given their
                e-consent.
              </em>
            </p>
            <p>
              <em>
                Email is one of the channels we use to keep our customers
                informed of the latest news and events at Lilly. It is one of
                the most cost-effective ways of reaching healthcare
                professionals (HCPs). It also gives the HCPs the benefit of
                receiving/reading the information at a time convenient to them.
              </em>
            </p>
            <p>
              <em>
                BEs can be sent as a single send out or as a journey. The tool
                used to create such journeys is called Journey Builder.
              </em>
            </p>
          </div>
        </div>
        <div className="container p-3 mb-5">
          {" "}
          <ul id="tab_panes" className="nav nav-tabs" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#processPreRequisites"
              >
                Process
                <br />
                Pre-requisites
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-bs-toggle="tab" href="#vvpmJobBag">
                VVPM Job Bag Standards
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#emailvolumeguidance"
              >
                Email Volume Guidance
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#standardHeadersFooters"
              >
                Standard<br/>Footers
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-bs-toggle="tab"
                href="#audienceRequestForm"
              >
                Audience Request Form
              </a>
            </li>
          </ul>
          <div id="tabContent" className="tab-content">
            <div
              id="processPreRequisites"
              className="tab-pane fade in active tabs_box"
            >
              <ul className="ul_padding">
                <li className="tabContent">
                   Process subject to{" "}
                  <a
                    href={webConfig.NEHubEmailVolumeandGuidance}
                    target="_blank"
                  >
                    NE Hub Email Volume and Guidance
                  </a>
                </li>
                <li className="tabContent">
                   Request Audience list for BEs via the{" "}
                  <a
                    href={webConfig.AudienceRequestForm}
                    target="_blank"
                  >
                    Audience Request Form
                  </a>
                </li>
                <li className="tabContent">
                   The standard{" "}
                  <a
                    href={webConfig.StandardFooterTemplate}
                    target="_blank"
                  >
                    Header and Footer Template
                  </a>{" "}
                  for emails must be used.{" "}
                </li>
              </ul>
            </div>
            <div id="vvpmJobBag" className="tab-pane fade tabs_box">
              <p className="tabsContentHeading">Name:</p>
              <p className="tabContent">
                The naming convention from the{' '} 
                <a
                  href={webConfig.PMAPJobAid}
                  target="_blank">PMAP Job Aid</a> {' '} section 6.3 (a) must be followed.
              </p>
              <table className="table table-striped tabContent">
                <thead>
                    <tr></tr>
                </thead>  
                <tbody>
                  <tr>
                      <td className="tb">
                          <strong>Country Code</strong>
                          <br />
                          (UK, IE, SE etc.) 
                      </td>
                      <td className="tb">
                         <strong> Therapy Area/Product Indication/Function</strong> 
                         <br/>
                         (i.e., Jardiance) 
                      </td>
                      <td className="tb">
                          <strong>Material</strong>
                          <br/>
                          (a brief description) 
                      </td>
                      <td className="tb">
                          <strong>Date</strong>
                          <br/>
                          (month and year of preparation) 
                      </td>
                      <td className="tb">
                          <strong>Job Bag Code</strong>
                          <br/>
                          (Optional) 
                      </td>
                  </tr>
                </tbody> 
              </table>      
              <p className="tabContent">For example: <em>‘UKIE Taltz PSA_Patient Brochure_APR23’</em></p>
              <p className="tabsContentHeading">Purpose Field:</p>
              <ul className="ul_padding">
                <li className="tabContent"> Explain purpose </li>
                <li className="tabContent">
                   The purpose field should state the frequency of the email distribution (which has to be in line with the Email volume guidance document) 
                </li>
                <li className="tabContent">
                   Clarify that the HTML version including subject line(s) will
                  be added to the Attachments section at certification.
                </li>
                <li className="tabContent">
                   Test link and log in to view online campaigns (if linking to
                  another page)
                </li>
              </ul>

              <p className="tabsContentHeading">Attachments:</p>
              <ul className="ul_padding">
                <li className="tabContent">
                   Live/HTML version of HTML including subject line(s)
                </li>
                <li className="tabContent">
                   Test link and log in to view online campaigns. (if
                  applicable)
                </li>
              </ul>

              <p className="tabsContentHeading">Linked Documents:</p>
              <ul className="ul_padding">
                <li className="tabContent">
                   Certified version of current PI -{" "}
                  <strong>for UK and ROI</strong>{" "}
                </li>
                <li className="tabContent"> Content references</li>
              </ul>

              <p className="tabsContentHeading">
                Related Permission Managed Content:
              </p>
              <ul className="ul_padding">
                <li className="tabContent">
                   Include required Permission Managed Content (PMC) documents.
                </li>
                <li className="tabContent">
                   PMC is not required for{" "}
                  <strong>Lilly product images</strong>. Check with Regulatory
                  or QA that it is the correct device image.
                </li>
              </ul>

              <p className="tabsContentHeading">Final Form:</p>
              <ul className="ul_padding">
                <li className="tabContent">
                   Live version including subject lines
                </li>
                <li className="tabContent">
                   Ensure that the source file package (HTML, etc.) is saved to the rendition file section of the job bag.  
                </li>
              </ul>

              <p className="tabsContentHeading">Audience:</p>
              <ul className="ul_padding">
                <li className="tabContent">
                  HCP with explicit consent will be the Audience.
                </li>
              </ul>

              <p className="tabsContentHeading">UK</p>
              <p className="tabContent">
                The same email content with different{" "}
                <strong>Subject Line</strong> and/or <strong>Pre-Header</strong>{" "}
                are often used for A/B testing or sending webinar invitations.
                Separate job bags are needed for each distinct subject line and
                pre-header. For MLR approval both texts must be visible within
                the PDF.
              </p>
              

              <p className="tabsContentHeading">FINLAND</p>
              <p className="tabContent">
                Nurses should never be included as part of the Audience for
                promotional communication as they are considered general public.{" "}
              </p>
            </div>
            <div id="emailvolumeguidance" className="tab-pane fade tabs_box">
              <p className="tabContent">
                Email is one of the channels we use to keep our customers
                up-to-date with the latest news and events at Lilly. It is one
                of the most{" "}
                <strong>
                  cost-effective ways of reaching HCPs and one of the key
                  channels to drive them to deep engagement interactions.
                </strong>{" "}
                The HCPs have the benefit of receiving and reading the
                information at a time convenient to them.
              </p>
              <p className="tabContent">
                Whilst there is a clear benefit of sending information through
                emails,{" "}
                <strong>
                  we need to be aware of Local Code, General Data Protection
                  Regulation (GDPR), Data Privacy and Safety Requirements, by
                  not sending an overwhelming number of emails
                </strong>{" "}
                their way.
              </p>
              <p className="tabContent">
                Lilly has some procedures in place to ensure that we operate
                with excellence in this channel.
              </p>
              <p className="tabContent">
                Click to see{" "}
                <a
                  href={webConfig.EmailVolumeGuidance}
                  target="_blank"
                >
                  Email Volume Guidance
                </a>{" "}
                –  applicable for Veeva Approved Email (VAE), BE and Source of Authority (SOA) emails. .{" "}
              </p>
            </div>
            <div id="standardHeadersFooters" className="tab-pane fade tabs_box">
              <p className="tabsContentHeading">Standard Footer for Emails</p>
              <p className="tabContent">
                The NE Hub has{" "}
                <a
                  href={webConfig.StandardFooter}
                  target="_blank"
                >
                  Standard Footer
                </a>{" "}
                for each affiliate within the Hub. These have been translated to
                local languages and reviewed by E&C, Medical and Legal
                reviewers. These footers have been tailored for{" "}
                <strong>
                  use in promotional and non-promotional messages and they apply
                  to BE, VAE and SOA emails
                </strong>
                .
              </p>
              <p className="tabContent">
                These approved wordings and all the advised formatting must be
                used.
              </p>
              <p className="tabContent">
                Please note{" "}
                <a
                  href={webConfig.JardianceFooter}
                  target="_blank"
                >
                  Jardiance Footer
                </a>{" "}
                requirements are different from the standard.
              </p>
            </div>
            <div id="audienceRequestForm" className="tab-pane fade tabs_box">
              <ul className="ul_padding ul_margin_bottom">
                <li className="flowsContent">
                   Submitting the Audience Request Form triggers an email to the Customer Ops team​. 
                </li>
                <li className="flowsContent">
                   Please allow 10 working days for the Audience list to be ready​ 
                </li>
                <li className="flowsContent">
                   The Audience list is NOT shared with the Requestor but is uploaded in ‘red’ classification box folder that is accessible to the DST. ​ 
                </li>
                <li className="flowsContent">
                   Customer Ops will inform the Requestor and the DST once the Audience list has been uploaded. ​ 
                </li>
                <li className="flowsContent">
                   Limit the Audience base to either the Brand Value (BV) or the Sales Force (SF) target base. 
                </li>
                <li className="flowsContent">
                   For any reason a tailored list might be needed, first discuss this with the OCE Manager​. 
                </li>
                <li className="flowsContent">
                   To access the request form, click{" "}
                  <a
                    href={webConfig.AudienceListRequestForm}
                    target="_blank"
                  >
                    Audience List Request Form.
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex align-items-start">
          <div
            className="nav flex-column nav-pills me-3 vertical_tabs"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              className="nav-link active"
              id="v-pills-flows-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-flows"
              type="button"
              role="tab"
              aria-controls="v-pills-flows"
              aria-selected="true"
            >
              Lifecycle
              <br />
              Phases
            </button>
            <button
              className="nav-link"
              id="v-pills-roles-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-roles"
              type="button"
              role="tab"
              aria-controls="v-pills-roles"
              aria-selected="true"
            >
              Roles and Responsibilities
            </button>
            <button
              className="nav-link"
              id="v-pills-definitions-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-definitions"
              type="button"
              role="tab"
              aria-controls="v-pills-definitions"
              aria-selected="false"
            >
              Definitions
            </button>
            <button
              className="nav-link"
              id="v-pills-coredoc-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-coredoc"
              type="button"
              role="tab"
              aria-controls="v-pills-coredoc"
              aria-selected="false"
            >
              View Core Document
            </button>
            <button
              className="nav-link"
              id="v-pills-faqs-tab"
              data-bs-toggle="pill"
              data-bs-target="#v-pills-faqs"
              type="button"
              role="tab"
              aria-controls="v-pills-faqs"
              aria-selected="false"
            >
              FAQs
            </button>
          </div>
          <div className="tab-content" id="v-pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="v-pills-flows"
              role="tabpanel"
              aria-labelledby="v-pills-flows-tab"
            >
              <h4 className="h1-font comman_hd">BE Flow Phases</h4>
              <div className="bg_accordian mt-3">
                <div className="Flows">
                  <div id="accordion1" />
                  <Accordion
                    className="accordion1"
                    title="Phase 1: Planning"
                    defaultOpen={true}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-4 planning">
                        <div className="comman_width_e">
                          <Phase1 ref={svgRef1} className="shape" />
                        </div>
                      </div>
                      <div className="col-lg-8 planning p_font pt-3 flowsSecondPart">
                        <div
                          id="campaign_listed_cet"
                          style={{
                            display:
                              P1visibleSection === "CET" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">CAMPAIGN LISTED ON CET</p>
                          <p className="flowsContent">
                            Campaigns should be listed on CET as part of IPA
                            process. Check if this material is part of your
                            plans, review the set KPIs and tactic links on CET.{" "}
                          </p>
                        </div>
                        <div
                          style={{
                            display:
                              P1visibleSection === "Pre-Veeva"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">PRE-VEEVA MEETING</p>
                          <p className="flowsContent">
                            Planning is everything! The core objectives of a
                            pre-Veeva discussion should not be overlooked. These
                            are to ensure that:
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               The reviewer is never presented with a material in VVPM that they have no background information on.  {" "}
                            </li>
                            <li className="flowsContent">
                               The review team understands its current priorities for review.  
                            </li>
                          </ul>
                          <p className="flowsContent">Together these aim to
                              provide a smoother and quicker approval process
                              for materials.</p>
                          <p className="flowsContent">Visit{" "}
                              <a href={webConfig.LillyPlanning} target="_blank">
                                Planning (lilly.com)
                              </a>{" "}
                              to learn more about pre-Veeva meetings.</p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion2" />
                  <Accordion
                    className="accordion2"
                    title="Phase 2: Material Readiness"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-5 planning">
                        <div className="comman_width">
                          <Phase2 ref={svgRef2} className="shape2" />
                        </div>
                      </div>
                      <div className="col-lg-7 planning pt-4 flowsSecondPart">
                        <div
                          id="briefing"
                          style={{
                            display:
                              P2visibleSection === "Briefing"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">BRIEFING</p>
                          <p className="pop flowsContent ul_margin_bottom">
                            Ensure that the team working on material development
                            fully understands the brief. Often a call is best
                            way to move things forward quickly. A good brief to
                            the creative agency means that the Material Administrator
                            provides all necessary visuals, texts and links
                            upfront. Briefing could be completed by the Material
                            Administrator. The creative agency should advise on
                            delivery timelines after the briefing is received.
                          </p>
                          <ul className="ul_padding briefingRemember">
                            <li className="flowsContent">
                               The Digital Solutions Team (DST) is the
                              preferred partner for working on emails. Briefing
                              must be provided to them using the Request Form
                              given through the{" "}
                              <a
                                href={webConfig.DSTRequestPowerApp}
                                target="_blank"
                              >
                                DST Request PowerApp.
                              </a>{" "}
                            </li>
                            <li className="flowsContent">
                               The NE Hub has a{" "}
                              <a
                                href={webConfig.StandardFooterTemplateLibrary}
                                target="_blank"
                              >
                                {" "}
                                Standard Footer Template Library
                              </a>{" "}
                              already built for each affiliate. When completing
                              a DST’s request, choose the correct footer from
                              the drop-down.
                            </li>
                            <li className="flowsContent">
                               Additionally, request the HCP{" "}
                              <strong>Audience list</strong> via the{" "}
                              <a
                                href={webConfig.AudienceRequestForm}
                                target="_blank"
                              >
                                Audience List Request Form
                              </a>{""}
                              . Ensure that this is done 2 weeks prior to the
                              intended send out date.
                            </li>
                          </ul>
                          <p className="flowsContent">
                            No other agency is trained or approved to develop{" "}
                            <strong>HTML</strong> versions, run test emails or
                            use SFMC for BEs across the NE Hub. On the rare
                            occasion that the asset might need artwork designed,
                            for the design part only, any Lilly-preferred vendor
                            for creative design could be used. The artwork file
                            along with the <strong>source file</strong> must be
                            passed on to the DST for HTML production.
                          </p>
                        </div>
                        <div
                          id="artwork_delivered"
                          style={{
                            display:
                              P2visibleSection === "Artwork" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">ARTWORK DELIVERED</p>
                          <p className="flowsContent">
                            It is the responsibility of the Material
                            Administrator to review the artwork provided for
                            accuracy before submitting it to Medical, Legal,
                            Regulatory (MLR) for approval.
                          </p>
                          <p className="flowsHeading">Remember</p>
                          <ul className="ul_padding">
                            <li className="flowsContent">
                               A <strong>Subject Line</strong> and/or a{" "}
                              <strong>Pre-Header</strong> must be included
                              within the PDF (and not to be added in the Purpose
                              field).
                            </li>
                            <li className="flowsContent">
                               Delivery Timeline – The DST has an agreed
                              timeline for the artwork PDF (4 business days). If
                              there are modifications to be made vs. the
                              original brief, do factor in more days to the
                              expected delivery time.
                            </li>
                          </ul>
                        </div>
                        <div
                          id="meets_expectations"
                          style={{
                            display:
                              P2visibleSection === "Expectations"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            PDF MEETS EXPECTATIONS?
                          </p>
                          <p className="flowsContent">
                            If the PDF meets expectations, then initiate MLR. If
                            the PDF does not meet expectations, then it is
                            always advised to book a call with the agency to go
                            through the changes required. (Back-and-forth
                            through email communication has proven to be time
                            consuming!)
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion3" />
                  <Accordion
                    className="accordion3"
                    title="Phase 3: Material Approval & Launch"
                    defaultOpen={false}
                    isOpen={isPhase3Open}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-8 planning main-content">
                        <Phase3 ref={svgRef3} className="shape3" />
                      </div>
                      <div className="col-lg-4 planning flowsSecondPart side-content">
                        <div
                          id="link4"
                          style={{
                            display:
                              P3visibleSection === "Initiate"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">INITIATE MLR APPROVAL</p>
                          <p className="flowsContent">
                            This is the stage where the Veeva Coordinator
                            uploads the material to VVPM for reviewers to provide a
                            verdict on it.  Scroll up to see the VVPM Job Bag Standards.
                          </p>
                          <p className="flowsHeading">Remember</p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               <strong>Subject Line</strong> and/or{" "}
                              <strong>Pre-Header</strong> must be included within
                              the PDF file for MLR approval.
                            </li>
                            <li className="flowsContent">
                               It is acceptable to allow BEs to{" "}
                              <strong>Auto-Expire</strong>. Remember to check
                              ‘yes’ on One-time use
                            </li>
                            <li className="flowsContent">
                               The <strong>Expiration Date</strong> for emails
                              must be 12 months or less (instead of 2 years).{" "}
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link5"
                          style={{
                            display:
                              P3visibleSection === "MlrVerdict"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">MLR VERDICT</p>
                          <p className="flowsContent">
                            There are three types of verdicts that the material
                            could get:{" "}
                          </p>

                          <p className="flowsContent">
                            <strong>1. Approved for Production</strong> – The
                            material has been blessed by the reviewers. Go ahead
                            and ask for the material to be developed into the final
                            format.
                          </p>
                          <p className="flowsContent">
                            <strong>2. Approved with Changes</strong> – The
                            material has some minor errors/updates required
                            (like spelling, grammatical errors). Incorporate the
                            advised updates/corrections on the material and ask
                            for the material to be developed into the final format.{" "}
                          </p>
                          <p className="flowsContent">
                            <strong>3. Revise and Resubmit</strong> – The material has too many problems and the reviewer advises the Veeva Coordinator to rework on it and submit again. Go ahead and ask the agency to re-do the artwork.
                          </p>
                        </div>
                        <div
                          id="link6"
                          style={{
                            display:
                              P3visibleSection === "DST" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            DST DELIVERS HTML PACKAGE
                          </p>
                          <p className="flowsContent">
                            Note that the DST is the only approved partner for
                            the NE Hub to produce HTML for emails. If the
                            artwork file was created through an agency, now is
                            the time to provide the <strong>source file</strong>{" "}
                            of the artwork to the DST.
                          </p>
                          <p className="flowsContent">
                            Once the HTML is produced and the linking is
                            completed, the DST will send the test email to the
                            Material Coordinator/Owner displaying the{" "}
                            <strong>subject line</strong>, the{" "}
                            <strong>email body</strong>, <strong>header</strong>{" "}
                            and the <strong>footer</strong> as it will be
                            displayed to the recipient. This is the final form
                            that is to be submitted for certification.
                          </p>
                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">
                            Delivery Timeline – The DST has an agreed delivery
                            timeline of 4 working days to produce HTML from PDF
                            content. Plan to avoid delays, especially if changes
                            are to be made.
                          </p>
                        </div>
                        <div
                          id="link7"
                          style={{
                            display:
                              P3visibleSection === "InitiateCertification"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">INITIATE CERTIFICATION</p>
                          <p className="flowsContent">
                            This is the stage where the Veeva Coordinators
                            upload the final form of the material to VVPM for
                            Certifiers to provide a verdict on the material.{" "}
                          </p>
                          <p className="flowsHeading">Remember</p>
                          <p className="flowsContent">
                            The test email should be uploaded on VVPM to{" "}
                            <strong>‘Attachments’</strong>.
                          </p>
                        </div>
                        <div
                          id="link8"
                          style={{
                            display:
                              P3visibleSection === "CertificationVerdict"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">CERTIFICATION VERDICT</p>
                          <p className="flowsContent">
                            There are 3 types of verdicts that the material may get:
                          </p>
                          <p className="flowsContent">
                            <strong>1. Certified</strong> – The material is
                            Approved for Distribution now. Go ahead to
                            review/set Inventory Information in VVPM and set the{" "}
                            <strong>Actual Date of First Use</strong>.{" "}
                          </p>
                          <p className="flowsContent">
                            <strong>2. Revise and Resubmit </strong> (for
                            certification) – The material has errors and/or
                            requires updates. Please request the DST to make the 
                            advised changes, trigger a new test email and re-submit 
                            for certification.
                          </p>
                          <p className="flowsContent">
                            <strong>3. Not Certified</strong> – The material has too many problems and the Certifier advises the Material Administrator to rework on it and start a new MLR workflow.
                          </p>
                        </div>
                        <div
                          id="link9"
                          style={{
                            display:
                              P3visibleSection === "FinalSteps"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            FINAL STEPS BEFORE 'GO-LIVE'
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               <strong>Actual Date of First Use</strong> is set
                              by Veeva Coordinators and is typically put as the
                              date the material has been certified on VVPM,
                              unless there’s a specific date of when the usage of a
                              material will start (e.g., launch date,
                              after the salesforce training, etc.)
                            </li>
                            <li className="flowsContent">
                              <strong> Inventory Information</strong> – Veeva
                              Coordinators would have added this earlier to VVPM
                              metadata and it should be checked when the
                              material goes live. This step will help increase the efficiency of material management and withdrawal.{" "}
                            </li>
                            <li className="flowsContent">
                               The <strong>Expiration Date</strong> is usually set for 2 years. However, if the material is classified in the Veeva metadata as ‘One-time use’ or for a defined time, set the expiration date accordingly.
                            </li>
                            <li className="flowsContent">
                               <strong>Related Pieces</strong> – Veeva Coordinators should check that any relevant related pieces are linked to the VVPM job bag.
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link9"
                          style={{
                            display:
                              P3visibleSection === "AdviseDST"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            Advise the DST to Push Asset for SFMC Approval
                          </p>
                          <p className="flowsContent">
                            1. The DST does not have visibility over VVPM
                            approval status. It is the Material Administrator’s
                            responsibility to inform the DST that an asset has
                            been certified and ask them to push the asset for
                            approval on SFMC.
                          </p>
                          <p className="flowsContent">
                            2. The approver for SFMC is a dedicated individual
                            within the OCE team
                          </p>
                          <p className="flowsContent">
                            3. It is the responsibility of the SFMC approver to
                            ensure the following:
                          </p>
                          <ul className="ul_padding ul_margin_bottom">
                            <li className="flowsContent">
                               The BE is approved in VVPM.
                            </li>
                            <li className="flowsContent">
                               The correct <strong>Audience list</strong> and
                              correct <strong>footer links</strong> have been
                              used.
                            </li>
                            <li className="flowsContent">
                               If email is part of a journey, review the
                              journey logic that has been applied.
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link11"
                          style={{
                            display:
                              P3visibleSection === "Scheduled"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            Scheduled Broadcast Email is Sent Out
                          </p>
                          <p className="flowsContent">
                            Once approved, the DST can schedule a send out on
                            the agreed date(s) and time(s) to the designated
                            Audience.
                          </p>
                        </div>
                        <div
                          id="link12"
                          style={{
                            display:
                              P3visibleSection === "Inform" ? "block" : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            Inform Customer-Facing Teams
                          </p>
                          <p className="flowsContent">
                            It is the best practice to inform the customer-facing team when the material is available.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion4" />
                  <Accordion
                    className="accordion4"
                    title="Phase 4: Material Management"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-lg-6 planning">
                        <Phase4 ref={svgRef4} style={{ paddingLeft: "20px", paddingTop: "20px" }}/>
                      </div>
                      <div className="col-lg-6 planning p-3 flowsSecondPart">
                        <div
                          id="link14"
                          style={{
                            display:
                              P4visibleSection === "UpdateCET"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">UPDATE CET</p>
                          <p className="flowsContent">
                            Ensure that the tactic metrics are updated on Customer Engagement Tool (CET).
                            If the BE tactic is linked appropriately, results will update automatically.
                          </p>
                        </div>
                        <div
                          id="link15"
                          style={{
                            display:
                              P4visibleSection === "Quarterly"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">
                            QUARTERLY INVENTORY REVIEWS BY BRAND TEAM
                          </p>
                          <ul className="ul_padding">
                            <li className="flowsContent">
                               For Operational Excellence in Material
                              Management, the NE Hub has a Quarterly Operational
                              Review of Inventory. The Brand Leader/Business
                              Unit Director should lead this exercise with their
                              team to review the list of materials in VVPM and
                              decide whether to update the material, leave it as
                              it is for now or withdraw it from VVPM. Understand
                              more in the{" "}
                              <a
                                href={webConfig.OperationalQuarterlyReviewGuidance}
                                target="_blank"
                              >
                                Operational Quarterly Review Guidance
                              </a>.
                            </li>
                            <li className="flowsContent">
                               Always{" "}
                              <strong>check the Inventory Information</strong> to
                              see if any metadata needs to be updated.
                            </li>
                            <li className="flowsContent">
                               <strong>Review briefing guides</strong> for any
                              update required and storage locations.
                            </li>
                          </ul>
                        </div>
                        <div
                          id="link160"
                          style={{
                            display:
                              P4visibleSection === "UpdateOfMaterial"
                                ? "block"
                                : "none",
                          }}
                        >
                          <p className="flowsHeading">UPDATE OF MATERIAL</p>
                          <p className="flowsContent">
                            Since BEs are often ‘One-time’ use, they do not
                            usually require updates. Occasionally, if the BE has
                            been approved to be re-used at a later date, note
                            that:
                          </p>
                          <p className="flowsContent">
                            1. Update of the material might be required (e.g.,
                            if PI has changed).
                          </p>
                          <p className="flowsContent">
                            2. Updating the material initiates the process right
                            from the beginning, i.e., ‘briefing’ and the
                            withdrawal process of the existing version.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                  <div id="accordion5" />
                  <Accordion
                    className="accordion5"
                    title="Phase 5: Material Withdrawal"
                    defaultOpen={false}
                  >
                    <div className="row" style={{ margin: "0px" }}>
                      <div className="planning p-5 flowsSecondPart ">
                        <p className="flowsContent">
                          Material withdrawal starts with the brand team´s
                          decision to discontinue a material and it ends with
                          the completed and documented destruction of the
                          material.
                        </p>
                        <p className="flowsContent">
                          The materials need to be removed from all storage
                          locations and circulation before initiating the
                          withdrawal in the Withdrawal Tool (wherever
                          applicable). The <strong>Inventory Information</strong> field in VVPM
                          will help the Material Administrator to capture all
                          storage locations for effective destruction
                        </p>
                        <p className="flowsHeading">
                          WITHDRAWAL AND RETIREMENT
                        </p>
                        <ul className="ul_padding">
                          <li className="p_font">
                             As this material is not distributed by
                            customer-facing teams or a third party, no
                            notification needs to be sent out through the
                            Withdrawal Tool. The BE is archived in SFMC which is
                            annually reviewed by the central team.
                          </li>

                          <li className="p_font">
                             From VVPM, the BE can auto expire if one-time use
                            has been marked as ‘yes’ in the metadata and the{" "}
                            <strong>Expiration Date</strong> has been set to
                            within 12 months of the{" "}
                            <strong>Actual Date of First Use</strong>. If not
                            added as One-time use, the brand team can go into
                            VVPM and retire the piece manually.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
            {/*Add Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-roles"
              role="tabpanel"
              aria-labelledby="v-pills-roles-tab"
            >
              <h4 className="h1-font comman_hd">Roles and Responsibilities</h4>
              <div className="Divider"></div>
              <div className="R&RContent">
                <table className="table table-striped">
                    <thead>
                        <tr>
                        <th>Role </th>
                        <th>Responsibility</th>
                        </tr>
                    </thead>  
                    <tbody>
                      <tr>
                         <td className="tb">
                              <h6>Global Patient Safety (GPS)/phV</h6>
                          </td>
                          <td>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Reviewing material from a pharmacovigilance perspective</li>
                                  <li>2. Must be consulted prior to the item entering Veeva for company-sponsored social media and company sponsored websites/digital platforms</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                         <td className="tb">
                              <h6>Veeva Owner</h6>
                          </td>
                          <td className='tb'>
                              <p>
                              The Veeva Owner is also known as the Uploader (please see 'Veeva uploader') 
                              </p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Administrator</h6>
                          </td>
                          <td>
                            This is a role type assigned to the individual working on producing the tactic.  Role of the Material Administrator may include (but not limited to) uploading job bag on Veeva, briefing the agency, coordinating meetings or discussions and action steps related to the Material approval, managing and withdrawing material. Typically a Veeva coordinator or Owner will play the role of Material Administrator.
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Material Originator</h6>
                          </td>
                          <td>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Having overall responsibility and accountability for the material</li>
                                  <li>2. Ensure that no item is used without the necessary approvals</li>
                                  <li>3. Ensuring timely change and/or withdrawal, where needed</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Uploader</h6>
                          </td>
                          <td>
                              <p>Person who uploads the material to Veeva, such as an assigned member from the marketing, medical or PRA teams, and is assigned the Veeva Owner role.</p>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Uploading the material to Veeva</li>
                                  <li>2. Performing all activities related to upload</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Veeva Co-ordinator</h6>
                          </td>
                          <td>
                              <p>The Coordinator is always a Lilly employee. The Veeva Coordinator can only be the Brand Manager (or similar role).</p>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Reviewing the item before sending for MLR review</li>
                                  <li>2. Managing the document workflow</li>
                                  <li>3. Assigning reviewers</li>
                                  <li>4. Managing expiry or periodic review</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Business Reviewer</h6>
                          </td>
                          <td>
                              <p>Business Reviewer is an experienced commercial employee.</p>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Ensuring all promotional materials align with the brand strategy, Lilly Brand Book, Product Brand Book</li>
                                  <li>2. Ensuring all materials are of good quality, have correct Veeva metadata and adhere to internal policies and local codes</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Medical Certifier</h6>
                          </td>
                          <td>
                              <p>Registered medical practitioner or UK-registered Pharmacist.</p>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Ensuring that the material is in accordance with the requirements of the local code;</li>
                                  <li>2. Ensuring that the material is not inconsistent with the marketing autorisation/SmPC (if applicable);</li>
                                  <li>3. Ensuring that the material is a fair and truthful representation of the facts about the medicine (if applicable);</li>
                                  <li>4. Approving the material in its final form</li>
                                  <li>5. Ensuring the material has the appropriate reviewewers and that the Veeva metadata is correct.</li>
                              </ul>
                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>UK Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for ensuring that the Veeva metadata is correct and that the PMC
                                  documents are included (if applicable).</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordic Code Approver</h6>
                          </td>
                          <td>
                              <p>Code Approver must be a medical practitioner or a pharmacist (except for in Sweden) and is responsible for
                                  ensuring that the material is in line with the local Codes and that the material being approved has the
                                  appropriate reviewers; and ensuring that the Veeva metadata is correct.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Nordics Technical Medical Reviewer</h6>
                          </td>
                          <td>
                              <p>Person who is responsible for ensuring that the material contains accurate information in line with the
                                  references that have been used and for reviewing the material from a medical perspective.</p>

                              <p>For further information, please see <a
                                      href={webConfig.PMAPJobAid}
                                      target="_blank">PMAP Job Aid.</a></p>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Indegene</h6>
                          </td>
                          <td>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Creating artwork as per material originator brief</li>
                                  <li>2. Creating formatted PI PDFs (UK and Nordics as well)</li>
                              </ul>
                          </td>
                      </tr>
                      <tr>
                          <td className="tb">
                              <h6>Gatekeeper</h6>
                          </td>
                          <td>
                            <ul className="ul_list">Responsible for:
                                  <li>1. Ensuring IVA matches final form and functions correctly</li>
                                  <li>2. Withdrawing/retiring binder and individual pages when requested by material originator</li>
                              </ul>
                              <p>May also be material originator and CLM owner.</p>
                          </td>
                      </tr>

                      <tr>
                          <td className="tb">
                              <h6>Material Originator</h6>
                          </td>
                          <td>
                              <ul className="ul_list">Responsible for:
                                  <li>1. Content conceptualisation and brief for DST</li>
                                  <li>2. Managing the approval process including provision of appropriate subject lines</li>
                                  <li>3. Requesting audience list</li>
                              </ul>
                              <p>Material originators may be the Veeva Uploader and the Veeva Co-ordinator or solely the Veeva Co-ordinator</p>
                          </td>
                      </tr>
                      
                    </tbody>
                </table>
              </div>
            </div>
            {/*End Roles & Resp. */}
            <div
              className="tab-pane fade"
              id="v-pills-definitions"
              role="tabpanel"
              aria-labelledby="v-pills-definitions-tab"
            >
              <h4 className="h1-font comman_hd">Definitions</h4>

              <table className="table table-striped">
                <thead>
                  <tr>
                    <td className="tb">
                      <strong>Term</strong>
                    </td>
                    <td className="tb">
                      <strong>Expanded</strong>
                    </td>
                    <td className="tb">
                      <strong>Definition</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="tb">BI</td>
                    <td className="tb">Boehringer Ingelheim</td>
                    <td className="tb">
                      Boehringer Ingelheim and Eli Lilly formed an association in January 2011 in the field of diabetes. This is also referred to as the Alliance. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">BE</td>
                    <td className="tb">Broadcast Email</td>
                    <td className="tb">
                      Approved emails created in HTML form that are sent to a
                      selected HCP audience group via head office on SFMC
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CTA</td>
                    <td className="tb">Call to Action</td>
                    <td className="tb">
                      The next step a Marketer wants its audience or reader to take. For example, a button or video in an email that the recipient can click on.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">CTOR</td>
                    <td className="tb">Click-through-to-open rate</td>
                    <td className="tb">
                      Email conversion metric used to review the effectiveness
                      of the email content. This measures the number of people
                      who clicked on an email link to the total number of people
                      who opened the email.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Customer Journey</td>
                    <td className="tb"></td>
                    <td className="tb">The timing and order of content provided to a customer both face-to-face and digital, throughout a period of time.</td>
                  </tr>
                  <tr>
                    <td className="tb">DST</td>
                    <td className="tb">Digital Solutions Team</td>
                    <td className="tb">Team of content developers dedicated to digital asset production in NE Hub.</td>
                  </tr>
                  <tr>
                    <td className="tb">E-consent</td>
                    <td className="tb">Email consent</td>
                    <td className="tb">
                      Electronic version of consent allowing Lilly to send digital communications to customers who have opted in to receive content.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">IBU</td>
                    <td className="tb">International Business Unit</td>
                    <td className="tb">The International Team that governs Strategy and Operations in different Business Units across all Lilly affiliates (except USA and Canada).</td>
                  </tr>
                  <tr>
                    <td className="tb">Interstitial</td>
                    <td className="tb"></td>
                    <td className="tb">
                      An interstitial is a pop-up or webpage that appears when a
                      user attempts to navigate from a Lilly website to an
                      external website. An interstitial provides notice to the
                      user that the location they are navigating to is not
                      controlled or maintained by Lilly
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">IPA</td>
                    <td className="tb">Intergrated Planning Approach</td>
                    <td className="tb">
                      The primary purpose of a customer plan is to communicate to affiliate leadership the team’s plan to deliver customer experience over the coming year in a compelling, focused, and clear manner. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Journey Builder</td>
                    <td className="tb"></td>
                    <td className="tb">
                      Automated email scheduling tool which allows for emails to
                      be planned with unique triggers as a ‘journey’. These
                      journeys are created by DST and the details within the
                      journey are referred to often as ’journey logic’.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">KPI</td>
                    <td className="tb">Key Performance Indicator</td>
                    <td className="tb">
                    Goals or Metrics set behind a campaign and or tactic.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">OCE</td>
                    <td className="tb">Omnichannel Engagement</td>
                    <td className="tb">
                      Omnichannel Engagement campaigns engage effectively with
                      the right HCPs and patients at the right time with the
                      appropriate channel mix (e.g. SOA, eP2P and VOD).
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Opt-in</td>
                    <td className="tb"></td>
                    <td className="tb">
                      A customer provides informed consent to be contacted by Lilly through email.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Opt-out</td>
                    <td className="tb">Unsubscribe</td>
                    <td className="tb">
                      A customer revokes their informed consent to be contacted by Lilly through the specific channel. 
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">OR</td>
                    <td className="tb">Open rate</td>
                    <td className="tb">
                      Email conversion metric used to review the effectiveness
                      of the email content. This measures the number of people
                      who opened an email to the total number of people the
                      email was delivered to.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Pre-header text</td>
                    <td className="tb"></td>
                    <td className="tb">
                      Limited text field underneath the subject line that can
                      either be a preview of the first line of content or a
                      chosen message. This is only possible for BEs.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Privacy Policy</td>
                    <td className="tb"></td>
                    <td className="tb">
                      A Privacy Policy is a statement or legal document that
                      disclosed the way that Lilly gathers, uses, discloses and
                      manages a customer or client's date. A page containing
                      this information can often be hyperlinked on digital
                      content.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">SFMC</td>
                    <td className="tb">Salesforce Marketing Cloud</td>
                    <td className="tb">
                      A marketing email software for the creation and management
                      of marketing campaigns and customer relationships. The
                      platform allows marketers to plan, segment and optimise
                      various facets of marketing, including: automated customer
                      journeys, multi-channel campaign execution, pre and
                      post-campaign analytics and data management.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Source File/HTML</td>
                    <td className="tb">Hypertext Markup Language file</td>
                    <td className="tb">
                      An HTML file contains hypertext markup language that
                      formats the structure of a webpage or email.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">SSO</td>
                    <td className="tb">Single Sign On</td>
                    <td className="tb">
                      Single Sign-on (SSO) is a process that allows users to
                      access multiple authorized applications without having to
                      log in separately to each application. SSO allows
                      organizations to validate usernames and passwords against
                      a corporate user database. A Lilly SSO solution for HCPs
                      is referred to as HCP Direct.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Subject line</td>
                    <td className="tb"></td>
                    <td className="tb">
                      Limited text field at the top of an email identifying the
                      email intent to the customer before they open.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Touchpoint</td>
                    <td className="tb"></td>
                    <td className="tb">
                      Touchpoints is terminology used to identify the volume and
                      frequency our tactics reach customers. These touchpoints
                      can be deep (often more in-depth pieces of content, more
                      likely to drive behavioural change) or light (may be
                      transactional or driving to 1 specific action or view).
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">URL Builder</td>
                    <td className="tb"></td>
                    <td className="tb">
                      An administrative tool allowing an authorised Lilly user
                      to create a URL or links to embed in digital
                      communications. For example, an MCE Operations Manager
                      will use URL builder to produce HCP Direct links.
                    </td>
                  </tr>
                  <tr>
                    <td className="tb">Veeva Suggestions</td>
                    <td className="tb"></td>
                    <td className="tb">
                      An automated system built into SFMC journey builder to
                      allow Sales Representatives to receive post-event
                      recommendations or other follow-up suggestions based on a
                      customer's actions. Currently available for UK only.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-coredoc"
              role="tabpanel"
              aria-labelledby="v-pills-coredoc-tab"
            >
              <h4 className="h1-font comman_hd">Core Document</h4>
              <br />
              <p>
                View the{" "}
                <a
                  className="CoreURL"
                  href={webConfig.BECoreDoc}
                  target="_blank"
                >
                  BE core document here.{" "}
                </a>
                <br />
                <br />
                Note: Please do not attempt to download or screenshot this
                document, and if needing to be shared, use the URL on the
                website for version control.
              </p>
            </div>
            <div
              className="tab-pane fade"
              id="v-pills-faqs"
              role="tabpanel"
              aria-labelledby="v-pills-faqs-tab"
            >
              <h4 className="h1-font comman_hd">FAQs</h4>
              <div id="FAQ1">
                <Accordion
                  className="faqAccordion"
                  title="Can emails be forwarded by HCPs?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    It is not appropriate for an HCP to forward an email or a
                    calendar invite to their colleagues from Lilly. The
                    following wording for calendar invites is to be added to
                    emails, websites or consoles with the calendar icon as
                    appropriate: “This event has been organised and funded by
                    Eli Lilly and Company. The calendar notification is for the
                    sole use of the intended recipients and should not be
                    forwarded.” All these disclaimers are included in the
                    standard footer created for all affiliates within the Hub.
                  </p>
                </Accordion>
              </div>
              <div id="FAQ2">
                <Accordion
                  className="faqAccordion"
                  title="Should I include PI in the email copy so that it is always
                  visible?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    For Items likely to be viewed online, e.g., emails, you
                    can provide a single click link to a digital PI rather than
                    including the actual PI.
                  </p>
                </Accordion>
              </div>
              <div id="FAQ3">
                <Accordion
                  className="faqAccordion"
                  title="What does opt-out or unsubscribe mean?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    Opt-out and unsubscribe essentially mean the same thing,
                    a user does not want to receive any further email
                    communication from Lilly. When opting out, HCPs can choose
                    their preferences – they can opt-out just from commercial
                    emails and retain their subscription to receive
                    medical/educational consent or vice-versa. HCPs can also
                    choose to completely opt-out from any further email
                    communication from Lilly.
                  </p>
                </Accordion>
              </div>
              <div id="FAQ4">
                <Accordion
                  className="faqAccordion"
                  title="How can I find out the number of HCPs who unsubscribed?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    Visit{" "}
                    <a 
                      href={webConfig.IBU360TableauServer}
                      target="_blank"
                    >
                      IBU360: IBU360 - Tableau Server{" "}
                    </a>{" "}
                    and follow the link to Email 360 dashboard. If you wish to
                    know about how many opt-out through a specific email that
                    was sent, then the DST can provide this data.
                  </p>
                </Accordion>
              </div>
              <div id="FAQ5">
                <Accordion
                  className="faqAccordion"
                  title="An email bounced back, why?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    Bounces occur for a multiple of reasons. The two main
                    types of bounces are hard and soft.
                    <ul>
                      <li>Hard bounce:</li>
                      <li>
                        A hard bounce is when an email comes back as
                        undeliverable – either because the address is no longer
                        valid, or it never existed in the first place.
                      </li>
                      <li>Soft bounce:</li>
                      <li>
                        Speaking of a soft bounce, we imply that something is
                        wrong with the email and not the recipient’s address. We
                        will look at the most common soft bounce reasons in
                        detail – in a nutshell, the most common ones are:
                        <ul>
                          <li>A large attachment inside the email.</li>
                          <li>The recipient’s inbox is full.</li>
                          <li>The email is blocked by SPAM filters.</li>
                        </ul>
                      </li>
                    </ul>
                  </p>
                </Accordion>
              </div>
              <div id="FAQ6">
                <Accordion
                  className="faqAccordion"
                  title="How can I know how my emails have performed?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    Visit IBU360:{" "}
                    <a 
                      href={webConfig.IBU360TableauServer}
                      target="_blank"
                    >
                      IBU360 - Tableau Server
                    </a>{" "}
                    and follow the link to Email 360 dashboard.
                  </p>
                </Accordion>
              </div>
              <div id="FAQ7">
                <Accordion
                  className="faqAccordion"
                  title="How can I identify the best days and times to send my
                  emails?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    Visit{" "}
                    <a 
                      href={webConfig.IBUOCEHeadquarters}
                      target="_blank"
                    >
                      IBU OCE Headquarters
                    </a>{" "}
                    , scroll down to the section on OCE playbooks and find the
                    latest email campaign playbook. Alternatively, ask your OCE
                    Manager.
                  </p>
                </Accordion>
              </div>
              <div id="FAQ8">
                <Accordion
                  className="faqAccordion"
                  title="I want to attach a PDF to my email through a link or call
                  to action. How can I provision this?"
                  defaultOpen={false}
                >
                  <p className="faqcontent">
                    If you are hosting PDFs linked to this asset, you MUST
                    use Veeva Digital Publishing (DP). Digital Publishing
                    automates the publishing of the latest approved version of
                    content to various digital channels and supports automatic
                    withdrawal of expired or obsolete content. The system does
                    this by allowing users to select approved VVPM documents to
                    be published as a digital PDF on an externally accessible
                    location. For full details on the steps to allow DP, please
                    follow this guide{" "}
                    <a href={webConfig.VeevaDigitalPublishingGuidance} target="_blank">
                      Veeva Digital Publishing Guidance_May 2024.pdf.
                    </a>
                  </p>
                </Accordion>
              </div>
            </div>
          </div>
        </div>

        <div id="popup_flight_travlDil11" className="overlay_flight_traveldil">
          <div className="popup_flight_travlDil">
            <p className="flowsHeading">NORDICS</p>
            <p className="flowsContent">
              If the material gets the verdict <strong>‘Not Certified’</strong>{" "}
              use <strong>‘Create Draft’</strong> function in VVPM 
              to use the same job bag code for the updated material.
            </p>
            <a className="close_flight_travelDl" href="#/">
              &times;
            </a>
          </div>
        </div>
        <div id="popup_flight_travlDil12" className="overlay_flight_traveldil">
          <div className="popup_flight_travlDil" >
            <p className="flowsHeading">NORDICS</p>
            <p className="flowsContent">
              Please check the {' '}
              <a
                href={webConfig.NordicPMAPGuidance}
                target="_blank"
              >Nordic PMAP Guidance
              </a>{' '}for detailed information on statutory copy submission.
            </p>
            <a className="close_flight_travelDl" href="#/">&times;</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BE;
